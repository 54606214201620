import { FunctionComponent, ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import SeityButton from "../../_core/components/SeityButton";
import SeityModal from "../../_core/components/SeityModal";

import { CheckListItemProps } from "./types";
import strings from "../../_core/strings/strings";

export const CheckListItem: FunctionComponent<CheckListItemProps> = (props): ReactElement => {
  const history = useHistory();

  const [showAffirmAlert, setShowAffirmAlert] = useState(false);

  const rightImg = props.isLocked
    ? require("../../assets/graphics/today/lock.png").default
    : require("../../assets/graphics/caret_black.svg").default;

  return (
    <div className="checklist-item w-100 flex" key={props.clItem.title + props.clItem.type}>
      <div className={`check-icon mr-lg flex-center ${props.isCompleted ? "complete" : "incomplete"}`}>
        <img src={require("../../assets/graphics/today/today-check.png").default} />
      </div>
      <div
        className={`item-right w-100 h-100 pr-lg pl-lg flex align-items-center ${props.isCompleted ? "complete" : "incomplete"}`}
        onClick={() => {
          if (props.isCompleted) {
            return;
          }
          if (props.clItem.type === "wellbeing") {
            history.push("/checkin/wellbeingCheckIn");
          } else if (props.clItem.type === "corevalue") {
            history.push("/assessment/intro");
          } else if (props.clItem.type === "intention") {
            history.push("/intentions/choose");
          } else if (props.clItem.type === "affirmation") {
            if (props.isLocked) {
              setShowAffirmAlert(true);
            } else {
              history.push("/today/affirmation");
            }
          }
        }}
      >
        <img className="mr-lg" src={require(`../../assets/graphics/today/${props.clItem.icon}`).default} />
        <div>
          <span>{props.clItem.subheader}</span>
          <h3>{props.clItem.title}</h3>
        </div>
        {!props.isCompleted && <img className="ml-auto" src={rightImg} />}
      </div>

      <SeityModal
        isOpen={showAffirmAlert}
        onClose={() => {
          setShowAffirmAlert(false);
        }}
        wrapperClassName="affirmation-warning-modal"
      >
        <div className="affirmation-warning-modal__content">
          <h2>{strings.completeCVFirst}</h2>
          <p>{strings.completeCVFirstDesc}</p>
          <SeityButton
            label={strings.gotIt}
            type="next"
            onClick={() => {
              setShowAffirmAlert(false);
            }}
          />
        </div>
      </SeityModal>
    </div>
  );
};

export default CheckListItem;
