import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "../../../app/rootReducer";
import { clearAccountError } from "../../account/accountSlice";

import EmailVerificationContent from "./components/EmailVerificationContent";
import CheckPassword from "./components/CheckPassword";
import ChangeEmail from "./components/ChangeEmail";
import ConfirmationBanner from "./components/ConfirmationBanner";
import SeityLoader from "../../../_core/components/SeityLoader";
import { SeityAppContext } from "../../../app/SeityAppContext";

import "./styles.scss";
import { ContentType, LocationStateProps } from "./types";

export const EmailVerification = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationStateProps>();
  const appContext = useContext(SeityAppContext);

  const isCheckEmail = location.pathname === "/changeEmail";
  const [showConfirmationBanner, setShowConfirmationBanner] = useState(false);

  const [contentType, setContentType] = useState<ContentType>(isCheckEmail ? ContentType.PwdValidate : ContentType.CodeVerify);

  const { isLoading } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(clearAccountError());
  }, []);

  const RenderContent = useMemo(() => {
    switch(contentType) {
      case ContentType.CodeVerify:
        return (
          <EmailVerificationContent
            setContentType={setContentType}
          />
        );
      case ContentType.UpdateEmail:
        return (
          <ChangeEmail
            setContentType={setContentType}
            setShowConfirmationBanner={setShowConfirmationBanner}
          />
        );
      case ContentType.PwdValidate:
        return (
          <CheckPassword
            setContentType={setContentType}
          />
        );
    }
  }, [contentType]);

  return (
    <div className={`ev-container ${appContext.showNavbar ? "showNav" : ""}`}>
      {RenderContent}
      <ConfirmationBanner showBanner={showConfirmationBanner} setShowBanner={setShowConfirmationBanner} />
      {isLoading ? <SeityLoader showBackgroundMask /> : null}
    </div>
  );
};

export default EmailVerification;
