/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FunctionComponent } from "react";
import "../styles.scss";

interface TriangleIndicatorsProps {
  graphIndex: number;
  triangleClickHandler: Function;
}

export const TriangleIndicators: FunctionComponent<TriangleIndicatorsProps> = ({ graphIndex, triangleClickHandler }) => {
  return (
    <div className="cig-triangle-selection-wrapper">
      <div className="cig-triangle-selection">
        <img
          alt="triangle-indicator"
          src={
            graphIndex === 1
              ? require("../../../../assets/graphics/triangle-active.png").default
              : require("../../../../assets/graphics/triangle-inactive.png").default
          }
          onClick={() => {
            return triangleClickHandler(1);
          }}
        />
        <div className="cig-triangle-selection-spacer" />
        <img
          alt="triangle-indicator"
          src={
            graphIndex === 2
              ? require("../../../../assets/graphics/triangle-active.png").default
              : require("../../../../assets/graphics/triangle-inactive.png").default
          }
          onClick={() => {
            return triangleClickHandler(2);
          }}
        />
        <div className="cig-triangle-selection-spacer" />
        <img
          alt="triangle-indicator"
          src={
            graphIndex === 3
              ? require("../../../../assets/graphics/triangle-active.png").default
              : require("../../../../assets/graphics/triangle-inactive.png").default
          }
          onClick={() => {
            return triangleClickHandler(3);
          }}
        />
        <div className="cig-triangle-selection-spacer" />
        <img
          alt="triangle-indicator"
          src={
            graphIndex === 4
              ? require("../../../../assets/graphics/triangle-active.png").default
              : require("../../../../assets/graphics/triangle-inactive.png").default
          }
          onClick={() => {
            return triangleClickHandler(4);
          }}
        />
        <div className="cig-triangle-selection-spacer" />
        <img
          alt="triangle-indicator"
          src={
            graphIndex === 5
              ? require("../../../../assets/graphics/triangle-active.png").default
              : require("../../../../assets/graphics/triangle-inactive.png").default
          }
          onClick={() => {
            return triangleClickHandler(5);
          }}
        />
      </div>
    </div>
  );
};
