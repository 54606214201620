import { ReactElement } from "react";
import SeityButton from "../../../_core/components/SeityButton";
import SeityModal from "../../../_core/components/SeityModal";

const ToolTipModal = ({ onClose, definition, modalButtonText }): ReactElement => {
  return (
    <SeityModal wrapperClassName="toolTipModal" showCloseBtn={false} isOpen={true} onClose={onClose}>
      <div className="toolTipModal-content">
        <div className="toolTipModal-content-text" dangerouslySetInnerHTML={{ __html: definition }}></div>
        <SeityButton
          className="tooltip-modal-button"
          label={modalButtonText}
          disabled={false}
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </SeityModal>
  );
};

export default ToolTipModal;
