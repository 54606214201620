import React, { useState, useEffect, ReactElement, FunctionComponent } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InputMask from "react-input-mask";

import { RootState } from "../../../../app/rootReducer";
import "./styles.scss";
import strings from "../../../../_core/strings/strings";

import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/es/lib/isEmail";

import { setAccountInfoValues, sendValidatePasswordRequest, clearAccountError } from "../../../../features/account/accountSlice";
import {
  sendPutRegistration02,
  setRegisterError,
  clearError,
  phoneCountryCode,
  sendGetPhoneCountryCodes
} from "../../../register/registerSlice";
import { SeityRegisterPayloadPutResponse } from "../../../../api/register/registerTypes";

import SeityButton from "../../../../_core/components/SeityButton";
import { SeityFormTextInput } from "../../../../_core/components/SeityTextInput/SeityFormTextInput";
import { SeityFormSelectBox } from "../../../../_core/components/SeitySelectBox/SeityFormSelectBox";
import { toastError } from "../../../../app/utils";

export const DefaultCountryCodes = [{ phoneCountryCodeID: 229, countryName: "United States", countryCode: "1" }];
export enum CredentialsType {
  Username = "Username",
  Email = "Email",
  PhoneNumber = "Phone Number"
}

type ModifyAuthenticationProps = {
  credentialsType: CredentialsType;
  updateCreds?: boolean;
  checkPassword?: boolean;
};

const ModifyAuthentication: FunctionComponent = (): ReactElement => {
  const { accountInfo, accountError } = useSelector((state: RootState) => {
    return state.account;
  });
  const { phoneCountryCodes, registerError } = useSelector((state: RootState) => {
    return state.register;
  });

  const { eMailAddress, cellPhone, username, phoneCountryCodeID } = accountInfo;

  const dispatch = useDispatch();
  const history = useHistory();

  const { pathname, state } = useLocation<ModifyAuthenticationProps>();

  if (!state?.credentialsType) {
    history.push("/startup");
    return <></>;
  }

  const { credentialsType } = state;
  const updateCreds = state?.updateCreds ?? false;
  const checkPassword = state?.checkPassword ?? false;

  const [countryCodeFieldDisplay, setCountryCodeFieldDisplay] = useState<string | null>(DefaultCountryCodes[0]?.countryCode);
  const [countryCodeID, setCountryCodeID] = useState(DefaultCountryCodes[0]?.phoneCountryCodeID);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [usernameValue, setUsernameValue] = useState("");
  const [password, setPassword] = useState("");
  const [inputValueInvalid, setInputValueInvalid] = useState(false);

  const getCountryCodeById = (phoneCountryCodeID: any, phoneCountryCodesList: phoneCountryCode[] | null) => {
    // Iterate through the array to find the matching ID
    const matchingCode = phoneCountryCodesList?.find((code) => code.phoneCountryCodeID === phoneCountryCodeID);

    // If a match is found, return the countryCode, otherwise return a message or null
    return matchingCode ? `+${matchingCode.countryCode}` : null;
  };

  const validateUsername = (): { similarToEmail: string | null; lengthInvalid: string | null } => {
    const similarToEmail = isEmail(usernameValue);

    const lengthInvalid = usernameValue.length < 8 || usernameValue.length > 50;
    setInputValueInvalid(similarToEmail || lengthInvalid);

    return {
      similarToEmail: similarToEmail ? strings.userIdFormat : null,
      lengthInvalid: lengthInvalid ? strings.userIdLength : null
    };
  };
  const isMobilePhoneValid = () => {
    return isMobilePhone(phone);
  };
  function validatePhoneNumber(): string | null {
    setInputValueInvalid(isMobilePhoneValid() === false);
    return isMobilePhoneValid() ? null : strings.cellPhoneErrorMessage;
  }
  const isEmailValid = () => {
    return isEmail(email);
  };
  function validateEmail(): string | null {
    setInputValueInvalid(isEmailValid() === false);
    return isEmailValid() ? null : strings.emailError;
  }

  const validateForm = () => {
    let doesFormHaveErrors = false;
    if (credentialsType === CredentialsType.Email) {
      if (email !== "") {
        const emailError = validateEmail();
        if (emailError !== null) {
          doesFormHaveErrors = true;
          dispatch(setRegisterError({ registerError: emailError }));
          return;
        }
      } else {
        setInputValueInvalid(false);
      }
    } else if (credentialsType === CredentialsType.PhoneNumber) {
      if (phone !== "") {
        const mobilePhoneError = validatePhoneNumber();
        if (mobilePhoneError !== null) {
          doesFormHaveErrors = true;
          dispatch(setRegisterError({ registerError: mobilePhoneError }));
          return;
        }
      } else {
        setInputValueInvalid(false);
      }
    } else if (credentialsType === CredentialsType.Username) {
      if (usernameValue !== "") {
        const usernameError = validateUsername();
        if (usernameError.similarToEmail || usernameError.lengthInvalid) {
          doesFormHaveErrors = true;
          dispatch(setRegisterError({ registerError: usernameError.similarToEmail ?? usernameError.lengthInvalid }));
          return;
        }
      } else {
        setInputValueInvalid(false);
      }
    }

    if (!doesFormHaveErrors) {
      dispatch(setRegisterError({ registerError: null }));
    }
  };

  const handlePutRedirection = (success?: boolean | undefined, data?: SeityRegisterPayloadPutResponse | undefined) => {
    if (success) {
      clearAccountError();
      clearError({});
      if (
        (pathname.includes("addCredential") && credentialsType === CredentialsType.Username) ||
        (pathname.includes("changeCredential") && credentialsType === CredentialsType.Username)
      ) {
        dispatch(setAccountInfoValues({ username: usernameValue }));
        history.push({ pathname: "/authentication" });
        return;
      }

      if (data && data.emailValidationRequired) {
        dispatch(setAccountInfoValues({ eMailAddress: email }));
        history.push({ pathname: "/emailVerification", state: { isFromSettings: true } });
      } else if (data && data.smsValidationRequired) {
        dispatch(setAccountInfoValues({ cellPhone: phone, phoneCountryCodeID: countryCodeID }));
        history.push({ pathname: "/phoneVerification", state: { isFromSettings: true } });
      } else {
        if (credentialsType === CredentialsType.PhoneNumber) {
          dispatch(setAccountInfoValues({ cellPhone: phone, phoneCountryCodeID: countryCodeID }));
        } else if (credentialsType === CredentialsType.Email) {
          dispatch(setAccountInfoValues({ eMailAddress: email }));
        }
        history.push({ pathname: "/authentication" });
      }
    } else {
      toastError(registerError || accountError || strings.genericErrorMsg);
    }
  };

  const afterAuthorization = (success: boolean) => {
    setPassword("");
    if (success) {
      dispatch(clearAccountError());
      dispatch(clearError({}));
      switch (credentialsType) {
        case CredentialsType.Username:
          history.push({
            pathname: "/authentication/changeCredential",
            state: { credentialsType: CredentialsType.Username, checkPassword: false, updateCreds: true }
          });
          break;
        case CredentialsType.Email:
          history.push({
            pathname: "/authentication/changeCredential",
            state: { credentialsType: CredentialsType.Email, checkPassword: false, updateCreds: true }
          });
          break;
        case CredentialsType.PhoneNumber:
          history.push({
            pathname: "/authentication/changeCredential",
            state: { credentialsType: CredentialsType.PhoneNumber, checkPassword: false, updateCreds: true }
          });
          break;
        default:
          history.push("/authentication");
          break;
      }
    }
  };

  const handleInputValueChange = async (value: string) => {
    switch (checkPassword) {
      case true:
        setPassword(value);
        break;

      default:
        switch (credentialsType) {
          case "Username":
            setUsernameValue(value);
            break;
          case "Email":
            setEmail(value);
            break;
          case "Phone Number":
            setPhone(value);
            break;

          default:
            break;
        }
    }
  };

  const nextPressed = async () => {
    if (checkPassword) {
      switch (credentialsType) {
        case CredentialsType.Username:
          await dispatch(sendValidatePasswordRequest(username, password, afterAuthorization));
          break;
        case CredentialsType.Email:
          await dispatch(sendValidatePasswordRequest(eMailAddress, password, afterAuthorization));
          break;
        case CredentialsType.PhoneNumber:
          let identifier = username;
          if (!identifier) {
            identifier = eMailAddress;
          }
          await dispatch(sendValidatePasswordRequest(identifier, password, afterAuthorization));
          break;

        default:
          break;
      }
    }

    if (pathname.includes("addCredential") || (pathname.includes("changeCredential") && updateCreds)) {
      switch (credentialsType) {
        case CredentialsType.Username:
          await dispatch(sendPutRegistration02(eMailAddress, cellPhone, phoneCountryCodeID, usernameValue.trim(), handlePutRedirection));
          break;
        case CredentialsType.Email:
          await dispatch(sendPutRegistration02(email, cellPhone, phoneCountryCodeID, username, handlePutRedirection));
          break;
        case CredentialsType.PhoneNumber:
          await dispatch(sendPutRegistration02(eMailAddress, phone, countryCodeID, username, handlePutRedirection));
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    dispatch(sendGetPhoneCountryCodes() as any);
    dispatch(clearAccountError());
    dispatch(clearError({}));

    return () => {
      dispatch(clearAccountError());
      dispatch(clearError({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (email !== "" || phone !== "" || usernameValue !== "") {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phone, usernameValue]);

  const credentialsTypeNoSpace = credentialsType.split(" ").join("");

  const headerString = pathname.includes("addCredential")
    ? `${strings.addCredentials} ${strings[`authentication${credentialsTypeNoSpace}`]}`
    : pathname.includes("changeCredential")
    ? `${strings.changeCredentials} ${strings[`authentication${credentialsTypeNoSpace}`]}`
    : "";

  const descString = pathname.includes("addCredential")
    ? strings[`add${credentialsTypeNoSpace}Description`]
    : checkPassword
    ? `${strings.checkPassword} ${strings[`authentication${credentialsTypeNoSpace}`].toLowerCase()}. `
    : updateCreds
    ? strings[`update${credentialsTypeNoSpace}Description`]
    : "";

  return (
    <div className="authentication-wrapper">
      <div className="authentication-container">
        <h1>{`${headerString}`}</h1>
        <h4>{descString}</h4>
        <form className="textbox-form">
          {credentialsType === CredentialsType.Email && !checkPassword ? (
            <SeityFormTextInput
              inputLabelText={updateCreds ? `New ${strings.emailLabel.toLowerCase()}` : strings.emailLabel}
              controlId="email"
              type="email"
              onChange={(e) => {
                return handleInputValueChange(e.target.value);
              }}
              value={email}
              placeholder={strings.emailLabel}
              isInvalid={inputValueInvalid}
            />
          ) : null}
          {credentialsType === CredentialsType.PhoneNumber && !checkPassword ? (
            <div className="country-code-phone-number">
              <SeityFormSelectBox
                inputLabelText={
                  updateCreds
                    ? `New ${strings.cellPhoneLabel.toLowerCase()}`
                    : strings.cellPhoneLabel.slice(0, 1) + strings.cellPhoneLabel.toLowerCase().slice(1)
                }
                onChange={(e) => {
                  setCountryCodeFieldDisplay(getCountryCodeById(e.target.value, phoneCountryCodes));
                  return setCountryCodeID(parseInt(e.target.value));
                }}
                value={countryCodeFieldDisplay}
              >
                <ul>
                  {phoneCountryCodes?.map((item) => {
                    return (
                      <li key={item.phoneCountryCodeID} value={item.phoneCountryCodeID}>
                        {item.countryName} (+{item.countryCode})
                      </li>
                    );
                  })}
                </ul>
              </SeityFormSelectBox>
              <InputMask
                mask="(999)-999-9999"
                onChange={(e) => {
                  return handleInputValueChange(e.target.value);
                }}
                value={phone}
                placeholder={strings.authenticationPhoneNumber}
              >
                <SeityFormTextInput inputLabelText={""} controlId="cell-phone" />
              </InputMask>
            </div>
          ) : null}
          {credentialsType === CredentialsType.Username && !checkPassword ? (
            <SeityFormTextInput
              inputLabelText={updateCreds ? `New ${strings.authenticationUsername.toLowerCase()}` : strings.authenticationUsername}
              controlId="user-id"
              type="text"
              onChange={(e) => {
                return handleInputValueChange(e.target.value);
              }}
              value={usernameValue}
              placeholder={strings.authenticationUsername}
              maxLength={50}
            />
          ) : null}
          {checkPassword ? (
            <SeityFormTextInput
              inputLabelText={strings.passwordLabel}
              controlId="password"
              type="password"
              placeholder={strings.passwordLabel}
              onChange={(e) => {
                return handleInputValueChange(e.target.value);
              }}
              value={password}
              autoComplete="off"
            />
          ) : null}
        </form>
      </div>
      <div className="authentication-bottom">
        <SeityButton
          onClick={nextPressed}
          type="submit"
          disabled={email === "" && phone === "" && usernameValue === "" && password === ""}
          label={strings.submit}
          className="authentication-next-button"
        />
      </div>
    </div>
  );
};

export default ModifyAuthentication;
