import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  SeityChallengeCompleteResponse,
  SeityChallengeContentListResponse,
  SeityChallengeIntroductionResponse,
  SeityChallengeNextStepResponse,
  SeityChallengeStartResponse,
  SeityChallengeStateResponse,
  SeityChallengeUpdateQuestionResponse
} from "./challengeTypes";

const SECTION = "/Challenge/";

export async function getChallengeState(token: string): Promise<SeityChallengeStateResponse> {
  const url = BASE_URL + SECTION + "State";
  try {
    const challengeResponse = await axios.get<SeityChallengeStateResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getChallengeIntroduction(token: string): Promise<SeityChallengeIntroductionResponse> {
  const url = BASE_URL + SECTION + "Introduction";
  try {
    const challengeResponse = await axios.get<SeityChallengeIntroductionResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getChallengeContent(objectiveID: number, token: string): Promise<SeityChallengeContentListResponse> {
  const url = BASE_URL + SECTION + "ContentList";
  try {
    const challengeResponse = await axios.post<SeityChallengeContentListResponse>(
      url,
      {
        objectiveID
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function postChallengeStart(objectiveID: number, token: string): Promise<SeityChallengeStartResponse> {
  const url = BASE_URL + SECTION + "Start";
  try {
    const challengeResponse = await axios.post<SeityChallengeStartResponse>(
      url,
      {
        objectiveID
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function setNextChallengeStep(objectiveID: number, token: string): Promise<SeityChallengeNextStepResponse> {
  const url = BASE_URL + SECTION + "NextStep";
  try {
    const challengeResponse = await axios.post<SeityChallengeNextStepResponse>(
      url,
      {
        objectiveID
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function completeChallenge(objectiveID: number, token: string): Promise<SeityChallengeCompleteResponse> {
  const url = BASE_URL + SECTION + "Complete";
  try {
    const challengeResponse = await axios.post<SeityChallengeCompleteResponse>(
      url,
      {
        objectiveID
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function updateChallengeQuestionAnswer(
  responseID: number,
  answerListID: number,
  token: string
): Promise<SeityChallengeUpdateQuestionResponse> {
  const url = BASE_URL + SECTION + "UpdateQuestion";
  try {
    const challengeResponse = await axios.post<SeityChallengeUpdateQuestionResponse>(
      url,
      {
        responseID,
        answerListID
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function updateChallengeRating(
  objectiveID: number,
  rating: number,
  token: string
): Promise<SeityChallengeUpdateQuestionResponse> {
  const url = BASE_URL + SECTION + "UpdateRating";
  try {
    const challengeResponse = await axios.post<SeityChallengeUpdateQuestionResponse>(
      url,
      {
        objectiveID,
        rating
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return challengeResponse.data;
  } catch (err) {
    throw err;
  }
}
