/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
import { useState, useEffect, useMemo, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../app/rootReducer";
import {
  sendCoreValueCategoryRequest,
  sendCoreValueCategoryUpdateSelectionRequest,
  sendCoreValueRequest,
  sendCoreValueUpdateSelectionRequest
} from "../../coreValuesSlice";
import { CoreValuesBubble } from "../CoreValuesBubble";
import SeityModal from "../../../../_core/components/SeityModal";
import SeityButton from "../../../../_core/components/SeityButton";
import SeityPrimaryCancelButtons from "../../../../_core/components/SeityNavigationButtons";
import SeityLoader from "../../../../_core/components/SeityLoader";

import { useWindowSize } from "../../../../hooks/Layout";
import { CoreValuesBubbleListProps } from "./types";
import CoreStrings from "../../../../_core/strings/strings";

import "./styles.scss";
import { AssessmentLevel } from "../../../../api/myStory/types/myStoryTypes";

export const CoreValuesBubbleList: FunctionComponent<CoreValuesBubbleListProps> = (props) => {
  const dispatch = useDispatch();
  const {
    coreValueGenerics,
    error: coreValueCategoryError,
    isLoading
  } = useSelector((state: RootState) => {
    return state.assessment.coreValues;
  });

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const isCVRetake = userAppSettings?.assessmentLevelID === AssessmentLevel.FullAssessment && userAppSettings?.isCVRetakeEligible
    || props?.isRetake;


  const size = useWindowSize();
  // Calculate menu item height for the window browser low height
  const itemHeight = useMemo(() => {
    if (size.height < 780 && size.width > 1150) {
      return (size.height - 300) / 2;
    }
    return 215;
  }, [size.width, size.height]);

  const handleSelect = useMemo(() => {
    switch (props.stepId) {
      case 1:
      case 2:
        return (id: number, selected: boolean, canSelect: boolean) => {
          if (canSelect || !selected) {
            dispatch(
              sendCoreValueCategoryUpdateSelectionRequest({
                coreValueCategorySelectionID: id,
                selected,
                topRated: false
              })
            );
          } else {
            setIsShowing4SelectedAlert(true);
          }
        };

      case 3:
        return (id: number, selected: boolean, canSelect: boolean) => {
          if (canSelect || !selected) {
            dispatch(
              sendCoreValueCategoryUpdateSelectionRequest({
                coreValueCategorySelectionID: id,
                selected,
                topRated: true
              })
            );
          } else {
            setIsShowing4SelectedAlert(true);
          }
        };

      case 4:
      case 5:
        return (id: number, selected: boolean, canSelect: boolean) => {
          if (canSelect || !selected) {
            dispatch(
              sendCoreValueUpdateSelectionRequest({
                coreValueSelectionID: id,
                selected,
                topRated: false
              })
            );
          } else {
            setIsShowing4SelectedAlert(true);
          }
        };

      case 6:
      default:
        return (id: number, selected: boolean, canSelect: boolean) => {
          if (canSelect || !selected) {
            dispatch(
              sendCoreValueUpdateSelectionRequest({
                coreValueSelectionID: id,
                selected,
                topRated: true
              })
            );
          } else {
            setIsShowing4SelectedAlert(true);
          }
        };
    }
  }, [dispatch, props.stepId]);

  useEffect(() => {
    const { stepId } = props;

    if (stepId < 1 || stepId > 6 || !stepId) {
      props.stepId = 6;
    }
    switch (stepId) {
      case 1:
      case 2:
      case 3:
        setIsCoreValueCategory(true);
        dispatch(sendCoreValueCategoryRequest(stepId, isCVRetake));
        break;
      case 4:
      case 5:
      case 6:
        setIsCoreValueCategory(false);
        dispatch(sendCoreValueRequest(stepId));
        break;
    }
  }, [props.stepId]);

  const [isShowing4SelectedAlert, setIsShowing4SelectedAlert] = useState(false);
  const [isCoreValueCategory, setIsCoreValueCategory] = useState(false);

  const selectedValues = useMemo(() => {
    return coreValueGenerics.filter((i) => {
      return i.selected === true;
    }).length;
  }, [coreValueGenerics]);

  useEffect(() => {
    if (selectedValues > 4) {
      setIsShowing4SelectedAlert(true);
    }
  }, [selectedValues]);

  if (isLoading) {
    return <SeityLoader showBackgroundMask />;
  }

  if (coreValueGenerics.length > 0) {
    return (
      <div className="bubble-content-wrapper">
        <div
          className={`${
            coreValueGenerics.length === 10
              ? "bubble-list-wrapper-grid6"
              : coreValueGenerics.length === 8
              ? "bubble-list-wrapper-grid4"
              : "bubble-list-wrapper"
          }`}
        >
          {coreValueGenerics.map((item) => {
            return (
              <CoreValuesBubble
                heading={item.heading}
                description={item.description}
                key={item.id}
                id={item.id}
                cvCateId={isCoreValueCategory ? item.cvCateId : item.cvId}
                selected={item.selected}
                canSelect={selectedValues < 4}
                image={item.image}
                height={itemHeight}
                handleSelect={handleSelect}
              />
            );
          })}
        </div>
        <SeityPrimaryCancelButtons
          primaryTitle={CoreStrings.continue}
          primaryAction={() => {
            return props.onNextClicked(props.stepId);
          }}
          cancelTitle={CoreStrings.back}
          cancelAction={() => {
            return props.onBackClicked(props.stepId);
          }}
          cancelButtonType="black"
          primaryDisabled={selectedValues !== 4}
          errorMessage={coreValueCategoryError}
          showCancel={props.stepId !== 1}
          cancelClassName="bubble-content-button"
          primaryClassName="bubble-content-button"
        />
        <SeityModal isOpen={isShowing4SelectedAlert} wrapperClassName="modal-core-value-4-selected">
          <div className="modal-core-value-4-selected-wrapper">
            <span
              dangerouslySetInnerHTML={{
                __html: CoreStrings.fourCoreValuesAlreadySelected
              }}
            />
            <SeityButton
              label={CoreStrings.okay}
              className="bubble-content-button"
              onClick={() => {
                return setIsShowing4SelectedAlert(false);
              }}
            />
          </div>
        </SeityModal>
      </div>
    );
  }
  return <div>Error getting core value generics</div>;
};
