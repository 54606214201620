import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";

import { RootState } from "../../../../../app/rootReducer";
import { clearAccountError, sendChangeEmailRequest, setAccountError } from "../../../../account/accountSlice";

import { SeityFormTextInput } from "../../../../../_core/components/SeityTextInput/SeityFormTextInput";
import SeityButton from "../../../../../_core/components/SeityButton";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";
import { ContentProps, ContentType } from "../../types";

const changeEmailGraphic = require("../../../../../assets/graphics/changeEmail.svg").default;
const mailIcon = require("../../../../../assets/icons/mailIcon.svg").default;
const mailIconError = require("../../../../../assets/icons/mailIconError.svg").default;

type ChangeEmailProps = ContentProps & {
  setShowConfirmationBanner: (bool: boolean) => void;
};

export const ChangeEmail = ({ setContentType, setShowConfirmationBanner }: ChangeEmailProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const { accountError } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(clearAccountError());
  }, []);

  const changeEmailRequestResponseHandler = (response: boolean) => {
    if (response) {
      dispatch(clearAccountError());
      setContentType(ContentType.CodeVerify);
      setShowConfirmationBanner(true);
      setEmail("");
      setConfirmEmail("");
    }
  };

  const onCloseHandler = () => {
    dispatch(clearAccountError());
    setEmail("");
    setConfirmEmail("");
    if (location.pathname === "/changeEmail") {
      history.goBack();
    } else {
      setContentType(ContentType.CodeVerify);
    }
  };

  const onVerifyEmailChangeHandler = () => {
    if (email !== confirmEmail) {
      dispatch(
        setAccountError({
          accountError: strings.evEmailsDontMatch
        })
      );
      return;
    }
    if (!isEmail(email)) {
      dispatch(
        setAccountError({
          accountError: strings.evEmailInvalid
        })
      );
      return;
    }
    dispatch(sendChangeEmailRequest(email, changeEmailRequestResponseHandler));
  };

  return (
    <div className="ce-wrapper">
      <div className="ce-title">{strings.evChangeEmail}</div>
      <img className="ce-graphic" src={changeEmailGraphic} alt="change email" />
      <div className="ce-description">{strings.evChangeEmailDescription}</div>
      <SeityFormTextInput
        inputLabelText={strings.evNewEmail}
        placeholder={strings.email}
        onChange={(e) => {
          return setEmail(e.target.value);
        }}
        value={email}
        leftIcon={accountError !== null ? mailIconError : mailIcon}
        className="ce-codeInput"
        containerClassname="ce-codeInputContainer"
        leftIconClassname="ce-codeInputIcon"
        isInvalid={accountError !== null}
      />
      <SeityFormTextInput
        inputLabelText={strings.evConfirmNewEmail}
        placeholder={strings.confirmEmailLabel}
        onChange={(e) => {
          return setConfirmEmail(e.target.value);
        }}
        value={confirmEmail}
        leftIcon={accountError !== null ? mailIconError : mailIcon}
        className="ce-codeInput"
        containerClassname="ce-codeInputContainer"
        leftIconClassname="ce-codeInputIcon"
        isInvalid={accountError !== null}
      />
      <div className="ce-error">{accountError}</div>
      <SeityButton
        className="ce-verify"
        onClick={onVerifyEmailChangeHandler}
        label={strings.evVerifyEmailBtnText}
        disabled={email.length === 0 || confirmEmail.length === 0}
      />
      <SeityButton type="link" onClick={onCloseHandler} label={strings.cancelButtonLabel} />
    </div>
  );
};

export default ChangeEmail;
