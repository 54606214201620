import React, {FunctionComponent, ReactElement} from "react";
import "./styles.scss";
import {CredentialResponse, GoogleLogin, TokenResponse, useGoogleLogin} from "@react-oauth/google";
import strings from "../../../_core/strings/strings";
import googleImg from "../../../assets/graphics/google.png";
import {SeityGoogleLoginProps} from "./types";

const SeityGoogleLoginButton: FunctionComponent<SeityGoogleLoginProps> = ({useGoogleButton, onSuccess, onFail}): ReactElement => {

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      onSuccess({
        clientId: null,
        jwt: null,
        accessToken: tokenResponse.access_token
      });
    },
    onError: (error) => onFail(error)
  });

  if (useGoogleButton) {
    return (
      <div className={"google-login-container"}>
        <div>
          <GoogleLogin
            onSuccess={(credentialResponse: CredentialResponse) => {
              onSuccess({
                clientId: credentialResponse.clientId,
                jwt: credentialResponse.credential,
                accessToken: null
              });
            }}
            onError={() => {
              onFail();
            }}
            theme="outline"
            shape="circle"
            size={"large"}
            width={"300px"}
          />
        </div>

      </div>
    );
  }

  return (
    <div className={'seity-google-login-button-container'}>
      <button
        onClick={() => {
          loginWithGoogle()
        }}
        className={'seity-google-login-button'}
        data-testid={'googleLoginButton'}
      >
        <div className={'seity-google-login-button-text-container'}>
          <img className={'seity-google-login-button-icon'} src={googleImg} alt="google icon"/>
          <span className={'seity-google-login-button-text'}>{strings.googleLoginButtonText}</span>
        </div>
      </button>
    </div>
  );

};

export default SeityGoogleLoginButton;