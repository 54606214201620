import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CoreValue,
  CoreValueCategorySelection,
  CoreValueCategorySelectionUpdate,
  CoreValueGeneric,
  CoreValueGenericUpdate,
  CoreValueSelection,
  CoreValueSelectionUpdate,
  FourCoreValuesSortOrderUpdate
} from "../../api/assessment/types/coreValueTypes";
import {
  getCoreValueCategorySelection,
  coreValueCategorySelectionUpdateRequest,
  getCoreValueSelection,
  coreValueSelectionUpdateRequest,
  getFourCoreValues,
  fourCoreValuesSortOrderUpdateRequest,
  resetCoreValues,
  coreValueMarkComplete
} from "../../api/assessment/seityHealthAPI-Assessment";
import { AppThunk } from "../../app/store";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../api/authTypes";
import { setRedirectToLogin } from "../auth/authSlice";
import { toast } from "react-toastify";
import CoreStrings from "../../_core/strings/strings";

export enum CoreValueMode {
  CoreValueCategorySelections,
  CoreValueSelections,
  FourCoreValues,
  SortFourCoreValues
}

export interface CoreValueState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly coreValueGenerics: CoreValueGeneric[];
  readonly fourCoreValues: CoreValue[];
}

const coreValueSlice = createSlice({
  name: "assessment-coreValues",
  initialState: {
    error: null,
    isLoading: false,
    coreValueGenerics: [],
    fourCoreValues: []
  } as CoreValueState,
  reducers: {
    coreValueGenericRequest(state, action) {
      state.isLoading = true;
    },
    coreValueGenericRequestSuccess(state, action) {
      const { payload } = action.payload;
      state.coreValueGenerics = payload;
      state.isLoading = false;
    },
    coreValueGenericSelected(state, action) {
      const { id, selected } = action.payload;
      let coreValueGenericSelected = state.coreValueGenerics.find((coreValueGeneric) => coreValueGeneric.id === id);
      if (coreValueGenericSelected) {
        coreValueGenericSelected.selected = selected;
      }
    },
    setCoreValueError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    setFourCoreValues(state, action: PayloadAction<CoreValue[]>) {
      state.fourCoreValues = action.payload;
    },
    apiError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    }
  }
});

export const {
  coreValueGenericRequestSuccess,
  coreValueGenericRequest,
  coreValueGenericSelected,
  apiError,
  clearApiError,
  setIsLoading,
  setCoreValueError,
  setFourCoreValues
} = coreValueSlice.actions;
export default coreValueSlice.reducer;

export const getFourCoreValuesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const fourCoreValuesResponse = await getFourCoreValues(token);
    if (fourCoreValuesResponse.success) {
      const data: any = fourCoreValuesResponse.data;
      const { fourCoreValues } = data;
      dispatch(setFourCoreValues(fourCoreValues));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};

export const sendCoreValueCategoryRequest =
  (level: number, isRetake: boolean, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));
      dispatch(coreValueGenericRequest({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }

      const coreValueCategoryResponse = await getCoreValueCategorySelection(token, level, isRetake);

      const data: any = coreValueCategoryResponse.data;
      const { coreValueCategorySelections } = data;
      const genericCoreValues = coreValueCategorySelections.map((item: CoreValueCategorySelection) => ({
        id: item.coreValueCategorySelectionID,
        cvCateId: item.coreValueCategoryID,
        heading: item.heading,
        description: item.description,
        selected: item.selected,
        sortOrder: item.sortOrder,
        image: item.image
      }));

      let payload = { payload: genericCoreValues };

      dispatch(coreValueGenericRequestSuccess(payload));

      if (callback) {
        callback();
      }
    } catch (err: any) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendCoreValueCategoryUpdateSelectionRequest =
  (coreValueCategorySelectionUpdate: CoreValueCategorySelectionUpdate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      console.log("Updating core value category selection...");

      const coreValueCategoryUpdateResponse = await coreValueCategorySelectionUpdateRequest(token, coreValueCategorySelectionUpdate);
      if (coreValueCategoryUpdateResponse.success) {
        const genericUpdate = new CoreValueGenericUpdate(
          coreValueCategorySelectionUpdate.coreValueCategorySelectionID,
          coreValueCategorySelectionUpdate.selected,
          coreValueCategorySelectionUpdate.topRated
        );

        console.log("Completed updating core value category selection");
        dispatch(
          coreValueGenericSelected({
            id: genericUpdate.id,
            selected: genericUpdate.selected
          })
        );
      } else {
        dispatch(apiError({ error: "Error updating core value category selection" })); // put in strings.ts
      }
    } catch (err: any) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendCoreValueUpdateSelectionRequest =
  (coreValueSelectionUpdate: CoreValueSelectionUpdate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }

      console.log("Updating core value selection...");

      const coreValueUpdateResponse = await coreValueSelectionUpdateRequest(token, coreValueSelectionUpdate);
      if (coreValueUpdateResponse.success) {
        const genericUpdate = new CoreValueGenericUpdate(
          coreValueSelectionUpdate.coreValueSelectionID,
          coreValueSelectionUpdate.selected,
          coreValueSelectionUpdate.topRated
        );
        dispatch(
          coreValueGenericSelected({
            id: genericUpdate.id,
            selected: genericUpdate.selected
          })
        );
        console.log("Completed updating core value selection");
      } else {
        dispatch(apiError({ error: "Error updating core value selection" })); // put in strings.ts
        console.log("Error updating core value selection. \nMessage: " + coreValueUpdateResponse.message);
      }
    } catch (err: any) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendCoreValueRequest =
  (level: number, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));
      dispatch(coreValueGenericRequest({}));
      console.log("Getting core value selections...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }

      const coreValueResponse = await getCoreValueSelection(token, level);
      const data: any = coreValueResponse.data;
      const { coreValueSelections } = data;
      const genericCoreValues = coreValueSelections.map((item: CoreValueSelection) => ({
        id: item.coreValueSelectionID,
        cvId: item.coreValueID,
        heading: item.coreValue,
        description: item.coreValueText,
        selected: item.selected,
        image: item.image
      }));

      let payload = { payload: genericCoreValues };
      dispatch(coreValueGenericRequestSuccess(payload));
      console.log("Recieved core value selections.");

      if (callback) {
        callback();
      }
    } catch (err: any) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendFourCoreValuesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));

    console.log("Getting four core values...");
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const fourCoreValuesResponse = await getFourCoreValues(token);
    if (fourCoreValuesResponse.success) {
      const data: any = fourCoreValuesResponse.data;
      const { fourCoreValues } = data;
      dispatch(setFourCoreValues(fourCoreValues));

      console.log("Received four core values...");
    } else {
      dispatch(apiError({ error: "Error Recieving four Core VValues" }));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

export const sendCoreValueSortOrderUpdateRequest =
  (sortedFourCoreValues: CoreValue[], isRetake: boolean, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const fourCoreValuesSortOrderUpdate = new FourCoreValuesSortOrderUpdate(
        sortedFourCoreValues[0].coreValueID,
        sortedFourCoreValues[1].coreValueID,
        sortedFourCoreValues[2].coreValueID,
        sortedFourCoreValues[3].coreValueID,
        isRetake
      );

      const coreValueSortOrderUpdateResponse = await fourCoreValuesSortOrderUpdateRequest(token, fourCoreValuesSortOrderUpdate);
      if (coreValueSortOrderUpdateResponse.success) {
        if (callback) {
          callback();
        } else {
          throw new Error("Sorting core value update API unsuccessful");
        }
      }
    } catch (err: any) {
      console.error(err);
      dispatch(setCoreValueError({ error: err.toString() }));
    }
  };

export const sendResetCoreValuesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response = await resetCoreValues(token);
    if (response.success) {
      dispatch(setFourCoreValues([]));
      return true;
    } else {
      toast.error(CoreStrings.resetCoreValueError, {
        position: "top-center",
        containerId: "general-noti",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return false;
    }
  } catch (err: any) {
    dispatch(apiError({ error: err.toString() }));
    return false;
  }
};

export const postCoreValueMarkComplete = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const res = await coreValueMarkComplete(token);
    console.log("postCoreValueMarkComplete:", res);
    if (res.success) {
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueError({ error: err.toString() }));
  }
};
