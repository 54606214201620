import { FunctionComponent } from "react";
import { TSAssetItemProp } from "./types";

import "./styles.scss";
import { ASSET1_IMG_URL } from "../../../../../api/apiConstants";

export const TSAssetItem: FunctionComponent<TSAssetItemProp> = ({ item, onClick }) => {
  return (
    <div className={`ts-asset-item`} onClick={onClick}>
      <img className="post-list-item-image" src={`${ASSET1_IMG_URL + item.coverImageName}`} alt="background" />
      <div className="post-list-item-bottom">
        <h2>{item.name}</h2>
      </div>
    </div>
  );
};
