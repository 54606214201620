import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserAppSettingsState {
  readonly error: string | null;
}

const setInitialState = {
  error: null
} as unknown as UserAppSettingsState;

const commonAlertSlice = createSlice({
  name: "CommonAlert",
  initialState: setInitialState,
  reducers: {
    setCommonError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearCommonError(state) {
      state.error = null;
    }
  }
});

export const { setCommonError, clearCommonError } = commonAlertSlice.actions;

export default commonAlertSlice.reducer;
