/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FunctionComponent, useMemo } from "react";
import { DragonText } from "../../../api/account/types/practiceScoresTypes";
import RequireSeityImage from "../../utils/graphicUtils/localGraphic";
import SeityProgressBar from "../SeityProgressBar";

import strings from "../../strings/strings";
import { capitalize } from "../../utils/stringUtils/formats";
import { SeityDragonProps } from "./types";

import "./styles.scss";

export const SeityDragon: FunctionComponent<SeityDragonProps> = ({ practiceDetail, onToggleSeeAll, onToggleLearn }) => {
  const { header, subtitle, seeALlDragons, learnAdvantages, caret } = useMemo(() => {
    let seeALlDragons = "";
    let learnAdvantages = "";
    const caret = require("../../../assets/graphics/btn-caret2.png").default;

    if (practiceDetail?.dragonText === DragonText.RAGING) {
      seeALlDragons = strings.seeALlRaginDragons;
      learnAdvantages = strings.learnAdvantages;
    } else if (practiceDetail?.dragonText === DragonText.AWAKE) {
      seeALlDragons = strings.seeALlAwakeDragons;
      learnAdvantages = strings.learnAdvantages;
    } else {
      seeALlDragons = strings.seeALlDragons;
      learnAdvantages = strings.learnAdvantagesHighScore;
    }

    return {
      header: practiceDetail.dragonHeader,
      subtitle: practiceDetail.dragonDescription,
      seeALlDragons,
      learnAdvantages,
      caret
    };
  }, [practiceDetail.dragonDescription, practiceDetail.dragonHeader, practiceDetail?.dragonText]);

  if (practiceDetail.dragonText) {
    return (
      <div className="dragon-foreground-wrapper">
        <div className="header-practice">
          <img src={RequireSeityImage(practiceDetail.dragonLogo).default} alt={practiceDetail.dragonText} />
          <div className="dragon-information">
            <div className="dragon-score-container">
              <span>{practiceDetail.score}%</span>
              <SeityProgressBar
                progress={practiceDetail.score}
                trackColor="rgba(49, 73, 71, 1)"
                color="rgba(255, 248, 235, 1)"
                height={6}
                containerStyle="pd-container-background-progress-bar"
              />
            </div>
            <h3 className="dragon-name">{capitalize(practiceDetail.dragonText)} Dragon</h3>
          </div>
        </div>

        <div className="information-container">
          <h2>{header}</h2>

          <p>{subtitle}</p>

          <div className="dragon-see-more-score dragon-see-more-score-white" onClick={onToggleLearn}>
            <span>{learnAdvantages}</span>
            <img src={caret} alt="Caret" />
          </div>

          <div className="dragon-see-more-score dragon-see-more-score-white" onClick={onToggleSeeAll}>
            <span>{seeALlDragons}</span>
            <img src={caret} alt="Caret" />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default SeityDragon;
