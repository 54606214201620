import { CoreValueType } from "../../../_core/components/SeityCVSelection";

export const convertCorevals = (coreVals: Array<CoreValueType>) => {
  if (coreVals.length === 4) {
    return {
      coreValueID1: coreVals[0].id,
      coreValueSelected1: coreVals[0].selected,
      coreValueID2: coreVals[1].id,
      coreValueSelected2: coreVals[1].selected,
      coreValueID3: coreVals[2].id,
      coreValueSelected3: coreVals[2].selected,
      coreValueID4: coreVals[3].id,
      coreValueSelected4: coreVals[3].selected
    };
  }
  return null;
};
