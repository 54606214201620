import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import strings from "../../../_core/strings/strings";

export type WellbeingProgressBarProps = {
  progressBarPercentage: number | null;
};

export const WellbeingProgressBar: FunctionComponent<WellbeingProgressBarProps> = ({ progressBarPercentage }): ReactElement => {
  return (
    <div className="chart-body">
      {/* @ts-ignore  - because dynamicChartPercentage can be null */}
      <div className="circular-progress" style={{ background: `conic-gradient(#FCBD31 ${progressBarPercentage * 3.6}deg, #e1ecec 0deg)` }}>
        <div className="circle-inner"></div>
        <div className="content">
          <img src={require("../../../assets/graphics/wellbeing/atom.png").default} />
          <p className="progress-value">{`${progressBarPercentage}%`}</p>
          <p>{strings.wellBeing}</p>
        </div>
      </div>
    </div>
  );
};
