import TemporaryPassword from "../features/account/maintenance/TemporaryPassword";
import { LockedAccount } from "../features/auth/LockedAccount";
import EmailVerification from "../features/auth/emailVerification";
import ForgotPassword from "../features/auth/forgotPassword/ForgotPassword";
import ForgotPasswordUsername from "../features/auth/forgotPassword/ForgotPasswordUsername";

import Confirmation from "../features/auth/forgotPassword/confirmation/Confirmation";
import Login from "../features/auth/login/Login";
import { RegisterFive } from "../features/register/RegistrationScreens/RegistrationFive";
import { RegisterFour } from "../features/register/RegistrationScreens/RegistrationFour";
import { RegisterLangSelect } from "../features/register/RegistrationScreens/RegistrationLangSelect";
import RegisterOne from "../features/register/RegistrationScreens/RegistrationOne";
import { RegisterSeven } from "../features/register/RegistrationScreens/RegistrationSeven";
import { RegisterSix } from "../features/register/RegistrationScreens/RegistrationSix";
import { RegisterThree } from "../features/register/RegistrationScreens/RegistrationThree";
import { RegisterTwo } from "../features/register/RegistrationScreens/RegistrationTwo";

export const AUTH_ROUTES = [
  {
    name: "/login",
    component: Login
  },
  {
    name: "/forgot-password",
    component: ForgotPassword
  },
  {
    name: "/forgot-password-username",
    component: ForgotPasswordUsername
  },
  {
    name: "/confirmation",
    component: Confirmation
  },
  {
    name: "/temporary-password",
    component: TemporaryPassword
  },
  {
    name: "/register/registerLangSelect",
    component: RegisterLangSelect
  },
  {
    name: "/register/registerOne",
    component: RegisterOne
  },
  {
    name: "/register/registerTwo",
    component: RegisterTwo
  },
  {
    name: "/register/registerThree",
    component: RegisterThree
  },
  {
    name: "/register/registerFour",
    component: RegisterFour
  },
  {
    name: "/register/registerFive",
    component: RegisterFive
  },
  {
    name: "/register/registerSix",
    component: RegisterSix
  },
  {
    name: "/register/registerSeven",
    component: RegisterSeven
  },
  {
    name: "/lockedAccount",
    component: LockedAccount
  },
  {
    name: "/emailVerification",
    component: EmailVerification
  },
  {
    name: "/phoneVerification",
    component: EmailVerification
  }
];
