import { FunctionComponent, ReactElement } from "react";

import CoreStrings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";

import "./styles.scss";

export type FSWelcomeProps = {
  onClose: () => void;
  onContinue: () => void;
};

const FSWelcome: FunctionComponent<FSWelcomeProps> = ({
  onClose,
  onContinue
}): ReactElement => {

  const introItems = [
    {
      imgName: "icon-explore.png",
      title: CoreStrings.exploreTogether,
      desc: CoreStrings.discoverNewContent
    },
    {
      imgName: "icon-shared.png",
      title: CoreStrings.sharedInsights,
      desc: CoreStrings.viewEachOther
    },
    {
      imgName: "icon-connect.png",
      title: CoreStrings.connect,
      desc: CoreStrings.createFamilyGroups
    }
  ];

  return (
    <div className="fsWelcome">
      <button
        className="close-button"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <img src={require("../../../assets/graphics/journals/close.png").default} alt="close button" />
      </button>
      <img src={require("../../../assets/graphics/familysharing/icon-familysharing.png").default} alt="family sharing" width={72} />
      <h1>{CoreStrings.fsWelcome}</h1>
      <div className="fsWelcome-items">
        {introItems.map((item, index) => {
          return (
            <div key={index}>
              <img src={require(`../../../assets/graphics/familysharing/${item.imgName}`).default} alt="intro img" />
              <div>
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
      <SeityButton className="fsWelcome-continue" label={CoreStrings.fsGetStarted} onClick={onContinue} />
    </div>
  );
};

export default FSWelcome;
