import { FunctionComponent, ReactElement } from "react";
import { ServiceResourceHeader } from "../../../../api/resource/resourceTypes";

import ResourceDetail from "../ResourceDetail";

import "./styles.scss";

type ResourceProps = {
  serviceResource: ServiceResourceHeader;
};

export const Resource: FunctionComponent<ResourceProps> = ({ serviceResource }): ReactElement => {
  return (
    <div key={serviceResource.serviceResourceHeaderID} className={serviceResource.emergencyFlag ? "emergency-bubble" : "normal-bubble"}>
      {serviceResource.header !== "" && <div className="resource-header w-100 ta-c">{serviceResource.header}</div>}
      {serviceResource.serviceResourceDetail.map((serviceResourceDetail) => {
        return <ResourceDetail key={serviceResourceDetail.serviceResourceDetailID} serviceResourceDetail={serviceResourceDetail} />;
      })}
      {serviceResource.footer !== "" && <div className="resource-footer">{serviceResource.footer}</div>}
    </div>
  );
};

export default Resource;
