import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DomPurify from "dompurify";

import { getAppContentByLanguageRequest } from "../../../policy/appContentSlice";

import RegisterWrapper from "../../RegisterWrapper";
import SeityButton from "../../../../_core/components/SeityButton";
import NoAccessModal from "../../../account/EULA/components/NoAccessModal";
import NoAccessModalLoop from "../../../account/EULA/components/NoAccessLoopModal";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";

import strings from "../../../../_core/strings/strings";
import "./style.scss";
import { RootState } from "../../../../app/rootReducer";

export const RegisterThree = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [showNoAccessModalLoop, setShowNoAccessModalLoop] = useState(false);
  const [eulaChecked, setEulaChecked] = useState(false);
  const [EULAContent, setEULAContent] = useState("");

  const { current } = useSelector((state: RootState) => {
    return state.register;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAppContentByLanguageRequest(5, current.languageID, (data) => {
        setEULAContent(data[0] ? DomPurify.sanitize(data[0].contentText) : "<h1>Unable to load EULA</h1>");
      })
    );
  }, []);

  const handleAgreePressed = () => {
    history.push("/register/registerFour");
  };

  const handleDisagreePressed = () => {
    if (eulaChecked) {
      setShowNoAccessModalLoop(true);
      return;
    }
    setEulaChecked(true);
    setShowNoAccessModal(true);
  };

  return (
    <RegisterWrapper>
      <div className="register-three-container">
        <h1>{strings.eulaHeader}</h1>
        <SeityIndicator currentStep={3} numberOfSteps={4} />
        <form>
          <section>
            <div
              dangerouslySetInnerHTML={{
                __html: EULAContent
              }}
            />
          </section>
        </form>
        <div className="register-three-btns">
          <SeityButton label={strings.agree} onClick={handleAgreePressed} style={{ width: "100%" }} />
          <SeityButton label={strings.disagree} style={{ width: "100%", marginTop: 15 }} onClick={handleDisagreePressed} type="cancel" />
        </div>
        <NoAccessModal
          isOpen={showNoAccessModal}
          onClose={() => {
            return setShowNoAccessModal(false);
          }}
        />
        <NoAccessModalLoop
          isOpen={showNoAccessModalLoop}
          onClose={() => {
            return setShowNoAccessModalLoop(false);
          }}
        />
        {/* {isLoading && <SeityLoader hideBackgroundContent />} */}
      </div>
    </RegisterWrapper>
  );
};
