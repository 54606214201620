import React, { MouseEventHandler } from "react";
import Lottie from "react-lottie-player";

import Modal from "react-modal";
import SeityButton from "../../../../../_core/components/SeityButton";

import infiniteLoop from "../../../../../assets/json/infiniteLoop.json";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";

interface Props {
  isOpen: boolean;
  onClose: MouseEventHandler;
}

export const NoAccessModalLoop = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} className="naml-cream" overlayClassName="naml-overlay-dark" contentLabel="Seity Modal">
      <div className="naml-wrapper">
        <div className="naml-wrapper-loop">
          <Lottie play animationData={infiniteLoop} />
        </div>
        <h2>{strings.noAccessModalLoopHeader}</h2>
        <h4>{strings.noAccessModalLoopDescription}</h4>
        <SeityButton onClick={onClose} label={strings.goBackBtnText} style={{ width: "100%" }} />
      </div>
    </Modal>
  );
};

export default NoAccessModalLoop;
