import { FunctionComponent, useState } from "react";
import Modal from "react-modal";
import strings from "../../strings/strings";

import "./styles.scss";
import { useHistory } from "react-router-dom";
import SeityButton from "../SeityButton";
import SeityCheckbox from "../SeityCheckbox";
import { setToggles } from "../../../api/coreValueDirectory/seityHealthAPI-CoreValueDirectory";
import { getUserAppSettingsRequest } from "../../../slices/userAppSettingsSlice";
import { useDispatch } from "react-redux";
type SeityCoreValuePermissionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SeityCoreValuePermissionsModal: FunctionComponent<SeityCoreValuePermissionsModalProps> = ({ isOpen, onClose }) => {
  Modal.setAppElement("#root");
  const history = useHistory();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const handleSubmit = () => {
    if (isChecked) {
      setToggles(
        {
          coreValueDirectoryCoreValuesEnabled: null,
          coreValueDirectoryDisplayNameEnabled: null,
          doNotShowCoreValueDirectoryModalPromptClicked: true
        })
        .then(() => {
          dispatch(getUserAppSettingsRequest());
        })
    }
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="seity-permissions-modal" overlayClassName="seity-permissions-overlay">
        <div className="mt-4 ml-4 flex-col permission-modal-body">
          <h3 style={{ height: "30px" }}>{strings.permissionModalTitle}</h3>
          <h4 className="mt-4 mb-3">{strings.permissionModalBodyGoTo}{" "}<a className="permission-modal-link" onClick={() => history.push('/permissions/coreValueDirectory')}>{strings.permissionModalBodyLinkText}</a>
          {" "}{strings.permissionModalBodyText}</h4>        
          <SeityCheckbox 
              checked={isChecked}
              onChange={(checked: boolean) => setIsChecked(checked)}
              label={strings.permissionModalCheckBoxText} />
          <SeityButton onClick={handleSubmit} label={strings.okay} className="mt-3"/>
        </div>
    </Modal>
  );
};

export default SeityCoreValuePermissionsModal;
