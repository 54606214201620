import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import { sendProgressDenominatorRequest } from "../../assessmentProgressSlice";
import "./styles.scss";

type AssessmentQuestionProgressProps = {
  denominator: number;
  answeredPracticeSelections: number[];
  answeredOutcomeSelections: number[];
};

const AssessmentQuestionProgress: FunctionComponent<AssessmentQuestionProgressProps> = ({
  denominator,
  answeredPracticeSelections,
  answeredOutcomeSelections
}) => {
  const progress = denominator === 0 ? 0 : (answeredPracticeSelections.length + answeredOutcomeSelections.length) / denominator;
  return (
    <div className="assessment-progressbar">
      <div className="assessment-progressbar__backbar">
        <div className="assessment-progressbar__progression" style={{ width: `${Math.round(progress * 100)}%` }} />
      </div>
      <span>{`${Math.round(progress * 100)}%`}</span>
    </div>
  );
};

export default AssessmentQuestionProgress;
