/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { FinishSurveyLaterModalProps } from "./types";
import SeityModal from "../../../../_core/components/SeityModal";
import SeityButton from "../../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";

import strings from "../../../../_core/strings/strings";

import "./styles.scss";

export const FinishSurveyLaterModal: FunctionComponent<FinishSurveyLaterModalProps> = ({ visible = false, onToggle = () => {} }) => {
  const history = useHistory();

  return (
    <SeityModal isOpen={visible} onClose={onToggle} wrapperClassName="seity-modal-survey">
      <div className="finish-survey-later d-flex flex-column align-items-center">
        <div className="finish-survey-later-wrapper d-flex flex-column align-items-center">
          <h3>{strings.wait}</h3>
          <div className="finish-survey-later-title-wrapper">
            <p className="finish-survey-later__title">{strings.finishSurveyLaterText}</p>
          </div>
        </div>
        <div className="finish-survey-buttons">
          <SeityButton label={strings.stay} onClick={onToggle} className="finish-survey-button" />
          <SeityHyperText
            type="discard"
            title={strings.finishLater}
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
      </div>
    </SeityModal>
  );
};

export default FinishSurveyLaterModal;
