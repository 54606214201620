import { FunctionComponent, useContext } from "react";
import FinishAssessmentLaterModal from "../base/FinishAssessmentLaterModal";

import { AssessWrapProps } from "./types";
import strings from "../../../_core/strings/strings";

import "./styles.scss";
import { SeityAppContext } from "../../../app/SeityAppContext";

export const AssessWrap: FunctionComponent<AssessWrapProps> = ({
  stepNum,
  children,
  showFinishLater,
  isCVOnly = false,
  setShowFinishLater,
  label,
  subHeader
}) => {
  const appContext = useContext(SeityAppContext);

  return (
    <div className={`d-flex flex-column assess-container ${stepNum && subHeader ? "assess-top" : "assess-top-subheader"}`}>
      {stepNum && (
        <div className={`aw-header ${appContext.showNavbar ? "showNavbar" : "hideNavbar"}`}>
          {!isCVOnly && (
            <div className="aw-header__top">
              <div className="aw-header__steps">
                <img src={require(`../../../assets/graphics/step${stepNum}@2x.png`).default} />
              </div>
              <span className="aw-header__title">{label || strings.assessment}</span>
            </div>
          )}
          {subHeader}
        </div>
      )}
      {children}
      <FinishAssessmentLaterModal
        visible={showFinishLater || false}
        onToggle={() => {
          setShowFinishLater && setShowFinishLater(false);
        }}
      />
    </div>
  );
};

export default AssessWrap;
