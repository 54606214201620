import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";


interface PermissionCardItemProps {
  icon: string;
  text: string;
  route: string;
}

const PermissionCardItem: FunctionComponent<PermissionCardItemProps> = ({ icon, text, route}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(route);
  };

  return (
     <div className="permission-item-card" onClick={handleClick}>
      <div>
        <img src={require(`../../assets/graphics/${icon}`).default} alt={text} />
        <p>{text}</p>
      </div>
   </div>
  );
};

export default PermissionCardItem;