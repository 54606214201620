import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import CoreStrings from "../../../../_core/strings/strings";
import { PersonalListAccountDetails } from "../../types";
import PLMemberProfile from "../PLMemberProfile";

type PLMemberSidebarProps = {
  memberData: PersonalListAccountDetails;
  onClose: () => void;
};

const PLMemberSidebar: FunctionComponent<PLMemberSidebarProps> = ({ memberData, onClose }): ReactElement => {
  return (
    <div className="fs-member-sidebar-container">
      <div className="header">
        <p>{CoreStrings.coreCard}</p>
        <button onClick={onClose}>
          <img src={require("../../../../assets/graphics/today/close.png").default} />
        </button>
      </div>
      <PLMemberProfile memberData={memberData} />
    </div>
  );
};

export default PLMemberSidebar;
