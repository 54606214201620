import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

import "./../styles.scss";
import { graphData } from "../types/types";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export interface CheckInLineChartProps {
  containerId: string;
  chartData: graphData;
  width: number;
  aspectRatio: number;
  pointsRendered: number[];
  pointFocused: number;
  dataLabelColor: string;
  title: string;
  iconPath?: string;
}

export const CheckInLineChart = ({
  containerId,
  chartData,
  width,
  aspectRatio,
  pointsRendered,
  pointFocused,
  dataLabelColor,
  title,
  iconPath
}: CheckInLineChartProps) => {
  const data = {
    labels: [0, 1, 2, 3, 4, 5],
    datasets: [
      {
        title: "check in line chart",
        data: chartData[0].chartValues,
        borderColor: chartData[0].color,
        pointBackgroundColor: chartData[0].color,
        pointRadius: pointsRendered,
        borderWidth: 2,
        hoverRadius: 0
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        min: -30,
        max: 150,
        display: false
      }
    },
    layout: {
      padding: {
        right: 80,
        top: 35,
        bottom: 0
      }
    },
    aspectRatio: aspectRatio
  };

  return (
    <div id={containerId} className="seity-chart" style={{ width: width, height: 100 }}>
      <div className="seity-chart-header">
        <img width="24px" height="24px" style={{ marginLeft: "15px" }} src={iconPath} alt="chart icon" />
        <div className="seity-chart-title">{title}</div>
      </div>{" "}
      <Line data={data} options={options} />
      <div
        className="seity-chart-data-label"
        style={{
          backgroundColor: chartData[0].color,
          color: dataLabelColor
        }}
      >
        {chartData[0].chartValues[pointFocused]}%
      </div>
    </div>
  );
};
