import { FunctionComponent, useEffect, useState } from "react";
import { SeityFormTextInput } from "../../../_core/components/SeityTextInput/SeityFormTextInput";
import { SeityFormSelectBox } from "../../../_core/components/SeitySelectBox/SeityFormSelectBox";
import { SeityDatePickerDropdown } from "../../../_core/components/SeityDatePickerDropdown";
import SeityButton from "../../../_core/components/SeityButton";
import "./styles.scss";
import { validEmailAddress } from "../../../_core/utils/accountUtils/accountUtils";
import moment from "moment";
import { toastError, toastSuccess } from "../../../app/utils";
import CoreStrings from "../../../_core/strings/strings";
import { FSHtmlModal } from "../FSHtmlModal";
import { RootState } from "../../../app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { postFSCoppaAccept, postFamilyMemberInvite } from "../familySharingSlice";
import { useFSHtmlContent } from "../hooks";
import SeityLoader from "../../../_core/components/SeityLoader";
import { FSRelationship } from "../types";

const DEFAULT_DOB = "01/01/2000";

type FSInviteFormProps = {
  groupID: number;
  onClose: (sentInvite?: boolean) => void;
};

export const FSInviteForm: FunctionComponent<FSInviteFormProps> = ({ groupID, onClose }) => {
  const dispatch = useDispatch();

  const { coppa, isLoading } = useFSHtmlContent();
  const { fsRelationshipList } = useSelector((state: RootState) => state.familySharingSlice);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [relationship, setRelationship] = useState<FSRelationship>();
  const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date(DEFAULT_DOB));
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [coppaStatus, setCoppaStatus] = useState({
    showModal: false,
    checked: false
  });

  const checkFormValid = () => {
    const formValid = firstName != "" && lastName != "" && relationship && email != "" && error === "";

    return formValid;
  };

  const addMemberFormSubmit = () => {
    if (checkFormValid()) {
      if (!coppaStatus.checked) {
        const ageDiff = moment().diff(dateOfBirth, "years", true);
        if (ageDiff < 13) {
          setCoppaStatus({ ...coppaStatus, showModal: true });
          return;
        }
      }

      //API call and get response. Check response and show success/ error toast
      dispatch(
        postFamilyMemberInvite(
          {
            groupId: groupID,
            firstName: firstName,
            lastName: lastName,
            relationship: relationship?.relationshipID,
            dob: dateOfBirth,
            email: email
          },
          (success) => {
            if (success) {
              toastSuccess(`${CoreStrings.fsiToastSuccess} ${email}`);
              onClose(true);
            }
          }
        )
      );
    } else {
      toastError(CoreStrings.fsiToastFormValidationError);
    }
  };

  function validateEmail() {
    if (email === "") {
      setError("");
      return;
    }

    let isEmailValid = validEmailAddress(email);
    if (!isEmailValid) {
      setError(CoreStrings.fsiEmailValidationError);
    } else {
      setError("");
    }
  }

  function clearForm() {
    setFirstName("");
    setLastName("");
    setRelationship(undefined);
    setDateOfBirth(new Date());
    setEmail("");
    setCoppaStatus({ showModal: false, checked: false });
    setError("");
  }

  useEffect(() => {
    validateEmail();
  }, [email]);

  return (
    <div className="fsi-invite-wrapper">
      {isLoading && <SeityLoader />}
      <div className="title-container">
        <h1 className="title">{CoreStrings.fsiTitle}</h1>
        <button
          className="close-button"
          onClick={(e) => {
            e.preventDefault();
            onClose(false);
          }}
        >
          <img src={require("../../../assets/graphics/journals/close.png").default} alt="close button" />
        </button>
      </div>
      <div className="invite-form-container">
        <h5 className="subtitle">
          {CoreStrings.fsiInviteFormSubtitle} <strong>{CoreStrings.fsiInviteFormSubtitleStrong}</strong>
        </h5>

        <div className="invite-form">
          <div className="form-inputs">
            <SeityFormTextInput
              inputLabelText={CoreStrings.firstNameLabel}
              placeholder={CoreStrings.firstNameLabel}
              formStyle={{ margin: "0" }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <SeityFormTextInput
              inputLabelText={CoreStrings.lastNameLabel}
              placeholder={CoreStrings.lastNameLabel}
              formStyle={{ margin: "0" }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <SeityFormSelectBox
              inputLabelText={CoreStrings.fsiRealtionshipLabel}
              className="form-inputs-select"
              value={relationship?.description}
              onChange={(e) => {
                setRelationship(fsRelationshipList[e.target.value ?? 0]);
              }}
            >
              <ul>
                {fsRelationshipList.map((relationship, index) => (
                  <li key={relationship.relationshipID} value={index}>
                    {relationship.description}
                  </li>
                ))}
              </ul>
            </SeityFormSelectBox>
            <div className="invite-date-picker">
              <label className="invite-date-picker-label">{CoreStrings.fsiDateOfBirthLabel}</label>
              <SeityDatePickerDropdown retriveDate={setDateOfBirth} date={dateOfBirth} className="form-inputs-datepicker" />
              <img src={require("../../../assets/icons/calendar.png").default} />
            </div>
            <SeityFormTextInput
              inputLabelText={CoreStrings.email}
              placeholder={CoreStrings.email}
              formStyle={{ margin: "0" }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onBlur={validateEmail}
            />

            {!!error && <h5 className="validation-error">{error}</h5>}
          </div>

          <SeityButton
            onClick={addMemberFormSubmit}
            type="next"
            disabled={!checkFormValid()}
            label={CoreStrings.fsiInviteButtonLabel}
            className="invite-button"
          />
        </div>
      </div>
      {coppaStatus.showModal && (
        <FSHtmlModal
          content={coppa}
          onClose={() => {
            setCoppaStatus({ checked:false, showModal: false });
          }}
          onContinue={() => {
            dispatch(postFSCoppaAccept(email));
            setCoppaStatus({ checked: true, showModal: false });
            dispatch(
              postFamilyMemberInvite(
                {
                  groupId: groupID,
                  firstName: firstName,
                  lastName: lastName,
                  relationship: relationship?.relationshipID,
                  dob: dateOfBirth,
                  email: email
                },
                (success) => {
                  if (success) {
                    toastSuccess(`${CoreStrings.fsiToastSuccess} ${email}`);
                    onClose(true);
                  }
                }
              )
            );
          }}
        />
      )}
    </div>
  );
};
