import { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

type ChoiceListProps = {
  isStory: boolean;
  options: Array<ChoiceOption>;
  selVal: number;
  correctAnswer?: number;
  onSelect: (sel: number) => void;
};

export type ChoiceOption = {
  name: string;
  value: number;
};

const ChoiceList: FunctionComponent<ChoiceListProps> = ({ isStory, options, selVal, correctAnswer, onSelect }): ReactElement => {
  const answerClass = (option) => {
    if (isStory && correctAnswer) {
      // this is only for Story
      if (selVal === option) {
        return selVal === correctAnswer ? "selected" : "incorrect";
      } else {
        return selVal === 0 ? "" : "disabled";
      }
    }
    return selVal === option ? "selected" : selVal === 0 || selVal === 1 ? "" : "disabled";
  };

  return (
    <>
      {options.map((o, i) => {
        return (
          <button key={i} className={`${answerClass(o.value)}`} onClick={() => onSelect(o.value)}>
            {o.name}
          </button>
        );
      })}
    </>
  );
};

export default ChoiceList;
