import stringsCommon from "../../../_core/strings/strings";

export enum OutcomeCategory {
  World = 1,
  Body = 2,
  Mind = 3,
  Spirit = 4,
  Wellbeing = 10
}

export interface AssessPracticesSteps {
  id: number;
  header: {
    title: string;
    subTitle: string | null;
  } | null;
  selection: {
    cateID: number;
    subCateID: number;
  } | null;
  buttonsDirectionRow?: boolean;
  options: Array<{
    value: string;
    buttonType: "next" | "black" | "discard";
    action: {
      type: string;
      value: string;
    };
  }>;
}

const useAssessPracticeSteps = (): Array<AssessPracticesSteps> => {
  return [
    {
      id: -1,
      header: null,
      selection: null,
      options: [
        {
          value: stringsCommon.start,
          buttonType: "next",
          action: { type: "next", value: "0" }
        },
        {
          value: stringsCommon.finishLater,
          action: { type: "finishLater", value: "0" },
          buttonType: "discard"
        }
      ]
    },
    /* Explore your World */
    {
      id: 0,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: -1 },
      options: [
        {
          value: stringsCommon.nextButtonText,
          buttonType: "next",
          action: { type: "next", value: "1" }
        }
      ]
    },
    {
      id: 1,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 1 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "0" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "2" }
        }
      ]
    },
    {
      id: 2,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 2 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "1" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "3" }
        }
      ]
    },
    {
      id: 3,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 3 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "2" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "4" }
        }
      ]
    },
    {
      id: 4,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 4 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "3" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "5" }
        }
      ]
    },
    {
      id: 5,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 5 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "4" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "6" }
        }
      ]
    },
    {
      id: 6,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 6 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "5" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "7" }
        }
      ]
    },
    {
      id: 7,
      header: null,
      selection: { cateID: OutcomeCategory.World, subCateID: 0 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "6" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "8" }
        }
      ]
    },
    /* Explore your Body */
    {
      id: 8,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: -1 },
      options: [
        {
          value: stringsCommon.nextButtonText,
          buttonType: "next",
          action: { type: "next", value: "9" }
        }
      ]
    },
    {
      id: 9,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: 1 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "8" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "10" }
        }
      ]
    },
    {
      id: 10,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: 2 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "9" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "11" }
        }
      ]
    },
    {
      id: 11,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: 3 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "10" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "12" }
        }
      ]
    },
    {
      id: 12,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: 4 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "11" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "13" }
        }
      ]
    },
    {
      id: 13,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: 5 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "12" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "14" }
        }
      ]
    },
    {
      id: 14,
      header: null,
      selection: { cateID: OutcomeCategory.Body, subCateID: 0 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "13" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "15" }
        }
      ]
    },
    /* Explore your Mind */
    {
      id: 15,
      header: null,
      selection: { cateID: OutcomeCategory.Mind, subCateID: -1 },
      options: [
        {
          value: stringsCommon.nextButtonText,
          buttonType: "next",
          action: { type: "next", value: "16" }
        }
      ]
    },
    {
      id: 16,
      header: null,
      selection: { cateID: OutcomeCategory.Mind, subCateID: 1 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "15" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "17" }
        }
      ]
    },
    {
      id: 17,
      header: null,
      selection: { cateID: OutcomeCategory.Mind, subCateID: 2 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "16" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "18" }
        }
      ]
    },
    {
      id: 18,
      header: null,
      selection: { cateID: OutcomeCategory.Mind, subCateID: 3 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "17" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "19" }
        }
      ]
    },
    {
      id: 19,
      header: null,
      selection: { cateID: OutcomeCategory.Mind, subCateID: 4 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "18" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "20" }
        }
      ]
    },
    {
      id: 20,
      header: null,
      selection: { cateID: OutcomeCategory.Mind, subCateID: 0 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "19" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "21" }
        }
      ]
    },
    /* Explore your Spirit */
    {
      id: 21,
      header: null,
      selection: { cateID: OutcomeCategory.Spirit, subCateID: -1 },
      options: [
        {
          value: stringsCommon.nextButtonText,
          buttonType: "next",
          action: { type: "next", value: "22" }
        }
      ]
    },
    {
      id: 22,
      header: null,
      selection: { cateID: OutcomeCategory.Spirit, subCateID: 1 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "21" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "23" }
        }
      ]
    },
    {
      id: 23,
      header: null,
      selection: { cateID: OutcomeCategory.Spirit, subCateID: 2 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "22" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "24" }
        }
      ]
    },
    {
      id: 24,
      header: null,
      selection: { cateID: OutcomeCategory.Spirit, subCateID: 3 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "23" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "25" }
        }
      ]
    },
    {
      id: 25,
      header: null,
      selection: { cateID: OutcomeCategory.Spirit, subCateID: 0 },
      buttonsDirectionRow: true,
      options: [
        {
          value: stringsCommon.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "24" }
        },
        {
          value: stringsCommon.continue,
          buttonType: "next",
          action: { type: "next", value: "26" }
        }
      ]
    },
    /* --- END --- */
    {
      id: 26,
      header: null,
      selection: null,
      options: [
        {
          value: stringsCommon.nextButtonText,
          buttonType: "next",
          action: { type: "navigate", value: "/assessment/Outcomes" }
        }
      ]
    }
  ];
};

export default useAssessPracticeSteps;
