import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import { AssessmentOutcomesProps } from "./types";
import { AssessmentCompletionState } from "../../../api/myStory/types/myStoryTypes";

import { AssessmentCoreValues } from "./components/AssessmentCoreValues";
import { AssessmentPracticeOutcomes } from "./components/AssessmentPracticeOutcomes";
import { AssessmentWholeHealthOutcomes } from "./components/AssessmentWholeHealthOutcomes";
import SeityButton from "../../../_core/components/SeityButton";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import { CVMode } from "../../../api/account/types/accountTypes";
import { NoAssessment } from "../ArtOfYou/components/NoAssessment";

export const AssessmentOutcomes: FunctionComponent<AssessmentOutcomesProps> = ({ characterization, coreValuesMode }) => {
  const history = useHistory();

  const assessmentImageArray: any[] = [];
  const assessmentLabelArray: any[] = [];
  const assessmentScoreArray: any[] = [];
  const assessmentTextEngArray: any[] = [];

  if (characterization != null && characterization.assessmentSummary.length === 5) {
    // @ts-ignore
    assessmentImageArray.splice(
      0,
      0,
      characterization.assessmentSummary[0].assessmentImage !== undefined ? characterization.assessmentSummary[0].assessmentImage : ""
    );
    // @ts-ignore
    assessmentLabelArray.splice(
      0,
      0,
      characterization.assessmentSummary[0].assessmentText !== undefined ? characterization.assessmentSummary[0].assessmentText : ""
    );
    // @ts-ignore
    assessmentScoreArray.splice(
      0,
      0,
      characterization.assessmentSummary[0].assessmentScore !== undefined ? characterization.assessmentSummary[0].assessmentScore : ""
    );
    // @ts-ignore
    assessmentTextEngArray.splice(
      0,
      0,
      characterization.assessmentSummary[0].assessmentTextEnglish !== undefined
        ? characterization.assessmentSummary[0].assessmentTextEnglish
        : ""
    );
    // @ts-ignore
    assessmentImageArray.splice(
      1,
      0,
      characterization.assessmentSummary[1].assessmentImage !== undefined ? characterization.assessmentSummary[1].assessmentImage : ""
    );
    // @ts-ignore
    assessmentLabelArray.splice(
      1,
      0,
      characterization.assessmentSummary[1].assessmentText !== undefined ? characterization.assessmentSummary[1].assessmentText : ""
    );
    // @ts-ignore
    assessmentScoreArray.splice(
      1,
      0,
      characterization.assessmentSummary[1].assessmentScore !== undefined ? characterization.assessmentSummary[1].assessmentScore : ""
    );
    // @ts-ignore
    assessmentTextEngArray.splice(
      1,
      0,
      characterization.assessmentSummary[1].assessmentTextEnglish !== undefined
        ? characterization.assessmentSummary[1].assessmentTextEnglish
        : ""
    );
    // @ts-ignore
    assessmentImageArray.splice(
      2,
      0,
      characterization.assessmentSummary[2].assessmentImage !== undefined ? characterization.assessmentSummary[2].assessmentImage : ""
    );
    // @ts-ignore
    assessmentLabelArray.splice(
      2,
      0,
      characterization.assessmentSummary[2].assessmentText !== undefined ? characterization.assessmentSummary[2].assessmentText : ""
    );
    // @ts-ignore
    assessmentScoreArray.splice(
      2,
      0,
      characterization.assessmentSummary[2].assessmentScore !== undefined ? characterization.assessmentSummary[2].assessmentScore : ""
    );
    // @ts-ignore
    assessmentTextEngArray.splice(
      2,
      0,
      characterization.assessmentSummary[2].assessmentTextEnglish !== undefined
        ? characterization.assessmentSummary[2].assessmentTextEnglish
        : ""
    );
    // @ts-ignore
    assessmentImageArray.splice(
      3,
      0,
      characterization.assessmentSummary[3].assessmentImage !== undefined ? characterization.assessmentSummary[3].assessmentImage : ""
    );
    // @ts-ignore
    assessmentLabelArray.splice(
      3,
      0,
      characterization.assessmentSummary[3].assessmentText !== undefined ? characterization.assessmentSummary[3].assessmentText : ""
    );
    // @ts-ignore
    assessmentScoreArray.splice(
      3,
      0,
      characterization.assessmentSummary[3].assessmentScore !== undefined ? characterization.assessmentSummary[3].assessmentScore : ""
    );
    // @ts-ignore
    assessmentTextEngArray.splice(
      3,
      0,
      characterization.assessmentSummary[3].assessmentTextEnglish !== undefined
        ? characterization.assessmentSummary[3].assessmentTextEnglish
        : ""
    );
    // @ts-ignore
    assessmentImageArray.splice(
      4,
      0,
      characterization.assessmentSummary[4].assessmentImage !== undefined ? characterization.assessmentSummary[4].assessmentImage : ""
    );
    // @ts-ignore
    assessmentLabelArray.splice(
      4,
      0,
      characterization.assessmentSummary[4].assessmentText !== undefined ? characterization.assessmentSummary[4].assessmentText : ""
    );
    // @ts-ignore
    assessmentScoreArray.splice(
      4,
      0,
      characterization.assessmentSummary[4].assessmentScore !== undefined ? characterization.assessmentSummary[4].assessmentScore : ""
    );
    // @ts-ignore
    assessmentTextEngArray.splice(
      4,
      0,
      characterization.assessmentSummary[4].assessmentTextEnglish !== undefined
        ? characterization.assessmentSummary[4].assessmentTextEnglish
        : ""
    );
  } else {
    // @ts-ignore
    assessmentImageArray.splice(0, 0, "corevalue01.png");
    // @ts-ignore
    assessmentLabelArray.splice(0, 0, "Wellbeing");
    // @ts-ignore
    assessmentScoreArray.splice(0, 0, "0");
    // @ts-ignore
    assessmentTextEngArray.splice(0, 0, "Wellbeing");
    // @ts-ignore
    assessmentImageArray.splice(1, 0, "corevalue01.png");
    // @ts-ignore
    assessmentLabelArray.splice(1, 0, "World");
    // @ts-ignore
    assessmentScoreArray.splice(1, 0, "0");
    // @ts-ignore
    assessmentTextEngArray.splice(1, 0, "World");
    // @ts-ignore
    assessmentImageArray.splice(2, 0, "corevalue01.png");
    // @ts-ignore
    assessmentLabelArray.splice(2, 0, "Body");
    // @ts-ignore
    assessmentScoreArray.splice(2, 0, "0");
    // @ts-ignore
    assessmentTextEngArray.splice(2, 0, "Body");
    // @ts-ignore
    assessmentImageArray.splice(3, 0, "corevalue01.png");
    // @ts-ignore
    assessmentLabelArray.splice(3, 0, "Mind");
    // @ts-ignore
    assessmentScoreArray.splice(3, 0, "0");
    // @ts-ignore
    assessmentTextEngArray.splice(3, 0, "Mind");
    // @ts-ignore
    assessmentImageArray.splice(4, 0, "corevalue01.png");
    // @ts-ignore
    assessmentLabelArray.splice(4, 0, "Spirit");
    // @ts-ignore
    assessmentScoreArray.splice(4, 0, "0");
    // @ts-ignore
    assessmentTextEngArray.splice(4, 0, "Spirit");
  }

  let assessmentNavigationText: string;

  if (coreValuesMode === CVMode.Update) {
    assessmentNavigationText = strings.retakeAssessment;
  } else if (characterization.assessmentState === AssessmentCompletionState.AssessmentComplete) {
    assessmentNavigationText = strings.takeAssessment;
  } else {
    assessmentNavigationText = strings.continueAssessment;
  }

  const goToCoreValues = () => {
    history.push("/coreValues/myCoreValues");
  };

  const goToPractices = (practiceNum: number) => {
    history.push(`/practices/myPractices/${practiceNum}`);
  };

  const goToOutcomes = () => {
    history.push("/outcomes/wholeHealthOutcomes");
  };

  const goToAssessment = () => {
    history.push("/assessment/intro");
  };

  if (characterization.assessmentState == AssessmentCompletionState.NoAssessment) {
    // no assessment or assessment no started
    return (
      <div>
        <NoAssessment assessmentStarted={characterization.assessmentStarted} />
      </div>
    );
  } else {
    // has assessment and has completed at least one level of the assesment
    return (
      <div className={characterization.assessmentDue ? "ao-container-due" : "ao-container"}>
        <div className="ao-reflect-wrapper">
          <div className="ao-reflect-wrapper__header">
            <h1>{strings.wholeHealthAssessment}</h1>
            <h4>{characterization.assessmentDate}</h4>
          </div>
          {characterization.assessmentState >= AssessmentCompletionState.CompletedToFourCoreValues && (
            <AssessmentCoreValues navigationFunction={goToCoreValues} />
          )}
          {characterization.assessmentState >= AssessmentCompletionState.CompletedToPracitceQuestions && (
            <AssessmentPracticeOutcomes
              navigationFunction={goToPractices}
              assessmentImageArray={assessmentImageArray}
              assessmentScoreArray={assessmentScoreArray}
              assessmentLabelArray={assessmentLabelArray}
              assessmentTextEngArray={assessmentTextEngArray}
            />
          )}
          {characterization.assessmentState >= AssessmentCompletionState.AssessmentComplete && (
            <AssessmentWholeHealthOutcomes
              navigationFunction={goToOutcomes}
              assessmentImageArray={assessmentImageArray}
              assessmentScoreArray={assessmentScoreArray}
              assessmentLabelArray={assessmentLabelArray}
              assessmentTextEngArray={assessmentTextEngArray}
            />
          )}
          {(characterization.assessmentDue || coreValuesMode !== CVMode.Selected) && (
            <SeityButton className="ao-reflect-wrapper-button" onClick={goToAssessment} label={assessmentNavigationText} />
          )}
        </div>
      </div>
    );
  }
};
