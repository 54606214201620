import { FunctionComponent, ReactElement, useState } from "react";

import CoreStrings from "../../../../_core/strings/strings";

export type FSShortMenuProps = {
  type: "invite" | "member" | "personal";
  onClickMenu: (idx: number) => void;
};

const FSShortMenu: FunctionComponent<FSShortMenuProps> = ({ type, onClickMenu }): ReactElement => {
  const menuItems = () => {
    if (type === "invite") {
      return [
        {
          icon: require("../../../../assets/icons/icon-send.svg"),
          text: CoreStrings.resendInvite,
          color: "#343B43"
        },
        {
          icon: require("../../../../assets/icons/icon-pen.svg"),
          text: CoreStrings.editInvite,
          color: "#343B43"
        },
        {
          icon: require("../../../../assets/icons/icon-recycle.svg"),
          text: CoreStrings.deleteInvite,
          color: "#F75A3B"
        }
      ];
    } else {
      return [
        {
          icon: require("../../../../assets/icons/icon-person.svg"),
          text: type === "personal" ? CoreStrings.viewCoreCard : CoreStrings.viewMemberProfile,
          color: "#343B43"
        },
        {
          icon: require("../../../../assets/icons/icon-recycle.svg"),
          text: CoreStrings.removeMember,
          color: "#F75A3B"
        }
      ];
    }
  };

  return (
    <div className="fsShortMenu">
      {menuItems().map((item, index) => {
        return (
          <button key={index} onClick={() => onClickMenu(index)}>
            <img src={item.icon.default} width={24} />
            <span style={{ color: item.color }}>{item.text}</span>
          </button>
        );
      })}
    </div>
  );
};

export default FSShortMenu;
