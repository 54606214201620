import React from 'react';
import maxLimitIcon from "../../../assets/graphics/journals/icon-max-limit.png";
import "./styles.scss";
import strings from "../../strings/strings";

const MaxLimitError = ({ isVisible, maxLength }) => {
  if (!isVisible) return null;

  return (
    <div className="seity-max-limit-error">
      <img
        className="ml-auto mr-md"
        src={maxLimitIcon}
        width={20}
        height={20}
        alt="Max limit"
      />
      <span>{strings.formatString(strings.errorMaximumWithLimit, maxLength)}</span>

    </div>
  );
};

export default MaxLimitError;
