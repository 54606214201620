import { phoneCountryCode } from "../../../features/register/registerSlice";

const getCountryCodeById = (phoneCountryCodeID: number, phoneCountryCodes: phoneCountryCode[]): string => {
  if (!phoneCountryCodes) {
    return "";
  }
  // Iterate through the array to find the matching ID
  const matchingCode = phoneCountryCodes.find((code) => code.phoneCountryCodeID === phoneCountryCodeID);

  // If a match is found, return the countryCode, otherwise return a message or null
  return matchingCode ? `+${matchingCode.countryCode}` : "";
};

export default getCountryCodeById;
