import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";

import animationData from "./commit-success.json";
import strings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";

import "./styles.scss";
import { RootState } from "../../../app/rootReducer";

type CommitSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CommitSuccessModal: FunctionComponent<CommitSuccessModalProps> = ({ isOpen, onClose }): ReactElement => {
  const history = useHistory();

  const { commitMsg } = useSelector((state: RootState) => {
    return state.intentions;
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="intention-commit-success-modal" overlayClassName="intention-commit-success-overlay">
      <Lottie options={defaultOptions} height={262} width={262} />
      <div className="mtb-xl flex-col flex-ai-c">
        <h3>{commitMsg ? commitMsg.header : strings.yay}</h3>
        <p className="intention-commit-success-modal__title">{commitMsg ? commitMsg.contentText : strings.commitSuccessDesc}</p>
        <p className="intention-commit-success-modal__subtitle">{strings.commitSuccessSubDesc}</p>
      </div>
      <SeityButton
        className="intention-button"
        label="View Intentions"
        onClick={() => {
          history.push("/intentions");
        }}
      />
      <button
        className="btn-notnow"
        onClick={() => {
          onClose();
        }}
      >
        {strings.notNow}
      </button>
    </Modal>
  );
};

export default CommitSuccessModal;
