/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState, useContext, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import { apiError, clearApiError, sendTemporaryPasswordRequest } from "../authSlice";
import { SeityTextInput } from "../../../_core/components/SeityTextInput/SeityTextInput";
import { SeityButton } from "../../../_core/components/SeityButton";
import { SeityAppContext } from "../../../app/SeityAppContext";
import { SeityModal } from "../../../_core/components/SeityModal";
import strings from "../../../_core/strings/strings";

import "./styles.scss";
import SeityPrimaryCancelButtons from "../../../_core/components/SeityNavigationButtons";

export const ForgotPassword: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appContext = useContext(SeityAppContext);

  const { authError, resetPasswordSuccess } = useSelector((state: RootState) => {
    return state.auth;
  });

  const [emailOne, setEmailOne] = useState("");

  // Clears any message when leaving the
  // component
  useEffect(() => {
    appContext.setShowNavMenuItems(false);
    appContext.setShowTodo(false);
    appContext.setNavigationMenuButtonText("< Back");
    appContext.setOnNavigationMenuButtonPress(handleForgotPasswordCancelClick);
    return () => {
      dispatch(clearApiError({}));

      appContext.setShowNavMenuItems(true);
      appContext.setShowTodo(true);
      appContext.setNavigationMenuButtonText(strings.finishLater);
      appContext.setOnNavigationMenuButtonPress(() => {});
    };
  }, []);

  const handleForgotPasswordCancelClick = () => {
    history.push("/login");
  };
  const handleForgotPasswordClicked = () => {
    sendPressed();
  };

  const sendPressed = () => {
    // Validate email
    if (emailOne === "") {
      dispatch(apiError({ authError: strings.resetPassword }));
      return;
    }
    dispatch(sendTemporaryPasswordRequest(emailOne));
  };

  const successModal = (
    <div className="fp-succuess-modal">
      <div className="fp-succuess-modal d-flex flex-column justify-content-center align-items-center modal-container">
        <p>{strings.success}</p>
        <div className="mt-2 align-self-center">
          <SeityButton label="Ok" type="next" onClick={handleForgotPasswordCancelClick} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="fp-container">
      <h1>{strings.forgotPassword}</h1>
      <p style={{ fontSize: 20 }}>{strings.forgotPasswordDirections}</p>
      <h5>{strings.forgotPasswordTemporary}</h5>
      <div className="fp-email">
        <SeityTextInput
          type="email"
          onChange={(e) => {
            return setEmailOne(e.target.value);
          }}
          value={emailOne}
          isInvalid={false}
          leftIcon={require("../../../assets/web-icons/mail_icon.png").default}
          placeholder="Your email address"
        />
      </div>
      <SeityPrimaryCancelButtons
        primaryTitle={strings.forgotPasswordBtnText}
        primaryAction={handleForgotPasswordClicked}
        errorMessage={authError}
        showCancel={false}
      />
      <div className="modal-container">
        <SeityModal
          isOpen={resetPasswordSuccess}
          onClose={handleForgotPasswordCancelClick}
          // eslint-disable-next-line react/no-children-prop
          children={successModal}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
