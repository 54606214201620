import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../api/authTypes";
import { getAppContent, getAppContentByKeys, getAppContentByLanguage } from "../../api/policy/seityHealthAPI-Privacy";
import { AppContentByKeys, AppContentLine } from "../../api/policy/types/AppContent";

export interface AppContentState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly appContentLines: AppContentLine[];
  readonly appContentByKeys: AppContentByKeys[];
}

const privacySlice = createSlice({
  name: "appContent",
  initialState: {
    error: null,
    isLoading: false,
    appContentLines: [],
    appContentByKeys: []
  } as AppContentState,
  reducers: {
    setAppContentLines(state, action: PayloadAction<AppContentLine[]>) {
      state.appContentLines = action.payload;
      state.isLoading = false;
    },
    setAppContentByKeys(state, action: PayloadAction<AppContentByKeys[]>) {
      state.appContentByKeys = action.payload;
      state.isLoading = false;
    },
    setAppContentError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    }
  }
});

export const { setAppContentLines, setAppContentByKeys, setAppContentError, clearApiError, setIsLoading } = privacySlice.actions;
export default privacySlice.reducer;

export const getAppContentRequest =
  (appContentTypeID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      const privacyResponse = await getAppContent(appContentTypeID, token);
      if (privacyResponse.success) {
        const data = privacyResponse.data.appContentLines;
        dispatch(setAppContentLines(data));
      }
    } catch (err) {
      console.error(err);
      dispatch(setAppContentError({ error: err.toString() }));
    }
  };

export const getAppContentByLanguageRequest =
  (appContentTypeID: number, languageID: number, callback?: (data: AppContentLine[]) => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const contentResponse = await getAppContentByLanguage(appContentTypeID, languageID);
      if (contentResponse.success) {
        const data = contentResponse.data.appContentLines;
        dispatch(setAppContentLines(data));
        if (callback) {
          callback(data);
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(setAppContentError({ error: err }));
    }
  };

export const getAppContentByKeysRequest =
  (contentKeys: Array<string>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      const contentResponse = await getAppContentByKeys(contentKeys, token);
      if (contentResponse.success) {
        const data = contentResponse.data;
        dispatch(setAppContentByKeys(data));
      }
    } catch (err) {
      console.error(err);
      dispatch(setAppContentError({ error: err }));
    }
  };
