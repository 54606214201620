import Compressor from "compressorjs";

//Converts base64 bytes to array buffers
export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);

  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

//Saves a PDF file from array buffers
export function savePDFFile(reportName, byte) {
  var blob = new Blob([byte], { type: "application/pdf" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}

export function savePNGFile(fileName: string, byte: ArrayBuffer): void {
  const blob = new Blob([new Uint8Array(byte)], { type: "image/png" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

export const getBase64FromFile = (file) => {
  return new Promise((resolve) => {
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsBinaryString(file);
    // on reader load somthing...
    reader.onload = (e) => {
      // Make a fileInfo Object
      const base64Str = btoa(e.target?.result as string);
      resolve(base64Str);
    };
  });
};

export const compressImageFileToBase64 = (file: File | Blob) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.4,
      success(file) {
        getBase64FromFile(file)
          .then((result) => {
            resolve(result as string)
          })
          .catch((err) => {
            reject(err);
          });
      },
      error(err) {
        reject(err);
      }
    });
  });
};
