import { CoreValue } from "../../../api/assessment/types/coreValueTypes";
import { IntentionSelected } from "../../../api/dailyIntention/intentionTypes";
import { Journal } from "../../../api/individualJournal/journalTypes";
import { CoreValueType } from "../../../_core/components/SeityCVSelection";

export const getUpdatedJournalsOnLike = (journalId: number, journals: Array<Journal>) => {
  // Returns JournalArray updated with Favourite date
  const ret = [...journals];
  const idx = ret.findIndex((t) => t.individualJournalID === journalId);
  const temp = { ...ret[idx] };
  temp.favorite = !temp.favorite;
  ret[idx] = temp;

  return ret;
};

export const makeUpCoreValueData = (journal: Journal | IntentionSelected): Array<CoreValueType> => {
  if (journal.coreValueID1 === 0 || journal.coreValueID2 === 0 || journal.coreValueID3 === 0 || journal.coreValueID4 === 0) {
    return [];
  }
  const corevals = [
    {
      id: journal.coreValueID1,
      selected: journal.coreValueSelected1,
      name: journal.coreValueName1
    },
    {
      id: journal.coreValueID2,
      selected: journal.coreValueSelected2,
      name: journal.coreValueName2
    },
    {
      id: journal.coreValueID3,
      selected: journal.coreValueSelected3,
      name: journal.coreValueName3
    },
    {
      id: journal.coreValueID4,
      selected: journal.coreValueSelected4,
      name: journal.coreValueName4
    }
  ];

  return corevals;
};

export const makeUpCoreValueDataWithFour = (cvData: Array<CoreValue>): Array<CoreValueType> => {
  const ret = cvData.map((c) => {
    return {
      id: c.coreValueID,
      name: c.coreValue,
      selected: false
    };
  });

  return ret;
};
