import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { RootState } from "../../../app/rootReducer";
import { deleteIndividualJournalRequest, putIndividualJournalRequest } from "../journalsSlice";

import { makeUpCoreValueData } from "./helpers";
import { ListItemProps } from "./types";

import SeityCVImage from "../../../_core/components/SeityCVImage";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";

const ListItem: FunctionComponent<ListItemProps> = (props): ReactElement => {
  const { handleMenu, journal, popupMenu, onDelete, onFavourite } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLiked, setLiked] = useState(journal.favorite);
  const [coreVals, setCoreVals] = useState<Array<any>>();

  const { isLoading } = useSelector((state: RootState) => {
    return state.journals;
  });

  useEffect(() => {
    setLiked(journal.favorite);

    const corevals = makeUpCoreValueData(journal);
    const vals = corevals.filter((c) => {
      return c.id !== 0 && c.selected;
    });
    setCoreVals(vals);
  }, [journal]);

  const createDate = moment.utc(`${journal.createDate} ${journal.createTime}`, "MM-DD-YYYY HH:mm");

  return (
    <li key={`${journal.individualJournalID}-listitem`}>
      <div className="flex">
        <div
          className="listitem-top flex-ai-c"
          onClick={() => {
            history.push({
              pathname: "/journals/entry",
              search: `?id=${journal.individualJournalID}&readOnly=true`
            });
          }}
        >
          <span>
            {createDate.format("DD")}
            <br />
            {createDate.format("MMM").toUpperCase()}
          </span>
          <div className="flex-col ml-lg">
            <div className="w-100 text-title">{journal.title}</div>
            <span>{createDate.fromNow()}</span>
          </div>
        </div>
        <button
          className="ml-auto pl-xl"
          onClick={(e) => {
            handleMenu(e);
          }}
        >
          <img src={require("../../../assets/graphics/journals/hamburger.png").default} alt="3 dots button" />
        </button>
      </div>
      {journal.individualJournalImages &&
        journal.individualJournalImages.length > 0 &&
        journal.individualJournalImages[0].base64String.length > 0 && (
          <img
            className="journal-entry__attach mt-xxl w-100"
            src={`data:image/png;base64,${journal.individualJournalImages[0].base64String}`}
            alt="attach"
          />
        )}
      <div className="listitem-bottom flex-ai-c mt-xxl">
        {coreVals && coreVals.length > 0 && (
          <div className="listitem-coreval pr-lg">
            {coreVals.map((c, i) => {
              return <SeityCVImage classname="listitem-coreval-img" cvId={c.id} key={i.toString()} size={32} />;
            })}
          </div>
        )}
        <div className="listitem-indivi pl-lg">
          <img
            src={require("../../../assets/graphics/journals/group.png").default}
            alt="individual"
            width={18.5}
            height={24}
            className="mr-sm"
          />
          <span>{CoreStrings.individual}</span>
        </div>
        <button
          className="ml-auto"
          disabled={isLoading}
          onClick={() => {
            const temp = { ...journal };
            temp.favorite = !isLiked;
            dispatch(
              putIndividualJournalRequest(temp, () => {
                setLiked(!isLiked);
                onFavourite();
              })
            );
          }}
        >
          {/* eslint-disable-next-line max-len */}
          <img
            src={
              isLiked
                ? require("../../../assets/graphics/journals/favorite-sel.png").default
                : require("../../../assets/graphics/journals/favorite.png").default
            }
            alt="favourite button"
            width={32}
          />
        </button>
      </div>
      {popupMenu.individualJournalId === journal.individualJournalID && (
        <div className="journals__popup flex-col" style={{ left: popupMenu.x, top: popupMenu.y }}>
          <button
            onClick={() => {
              dispatch(
                deleteIndividualJournalRequest(journal.individualJournalID, () => {
                  onDelete();
                })
              );
            }}
          >
            {CoreStrings.deleteJournalEntry}
          </button>
          <button
            onClick={() => {
              history.push({
                pathname: "/journals/entry",
                search: `?id=${journal.individualJournalID}`
              });
            }}
          >
            {CoreStrings.editJournalEntry}
          </button>
        </div>
      )}
    </li>
  );
};

export default ListItem;
