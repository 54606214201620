import { useState, useEffect, FunctionComponent, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SurveyWrap from "./SurveyWrap";
import SeityQuestion from "../../_core/components/SeityQuestion";
import SurveyQuestionProgress from "./base/SurveyQuestionProgress";
import SeityAlert from "../../_core/components/SeityAlert";
import { SeityLoader } from "../../_core/components/SeityLoader";
import { SeityModal } from "../../_core/components/SeityModal";
import { SeityButton } from "../../_core/components/SeityButton";
import { SeityHyperText } from "../../_core/components/SeityHyperText";

import { RootState } from "../../app/rootReducer";
import {
  clearSurveyQuestions,
  pushToAnsweredSurveySelections,
  sendSurveyWizardQuestionsRequest,
  sendSurveyWizardUpdateRequest,
  setQuestionAnswered
} from "./SurveySlice";

import strings from "../../_core/strings/strings";
import { SurveyParamTypes, SurveySelectionProps } from "./types";

import "./styles.scss";

export const SurveySelection: FunctionComponent<SurveySelectionProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<SurveyParamTypes>();
  // eslint-disable-next-line radix
  const messageKeyID: number = parseInt(params.messageKeyID);
  const [showModalComplete, setshowModalComplete] = useState<boolean>(false);
  const [needsToBeAnswered, setNeedsToBeAnswered] = useState(false);
  const [showFinishLater, setShowFinishLater] = useState(false);
  const [showUnansweredQuestionsModal, setShowUnansweredQuestionsModal] = useState(false);

  const { isLoading, questionAndAnswers, title } = useSelector((state: RootState) => {
    return state.survey;
  });

  useEffect(() => {
    dispatch(sendSurveyWizardQuestionsRequest(messageKeyID));
    setNeedsToBeAnswered(false);
  }, []);

  const seityHeader = document.getElementsByClassName("seity-header")[0];
  const allSelected = useMemo(() => {
    return !questionAndAnswers.some((i) => {
      return i.selectedAnswerLevel === 0;
    });
  }, [questionAndAnswers]);

  const answeredQuestions = useMemo(
    () =>
      questionAndAnswers.filter((q) => {
        return q.selectedAnswerLevel !== 0;
      }),
    [questionAndAnswers]
  );

  useEffect(() => {
    answeredQuestions.forEach((q) => {
      return dispatch(pushToAnsweredSurveySelections(q.surveyWizardSelectionID));
    });
  }, [answeredQuestions]);

  const onPressOption = () => {
    if (!allSelected) {
      setShowUnansweredQuestionsModal(true);
    } else if (showModalComplete) {
      dispatch(clearSurveyQuestions());
      history.push("/");
    } else {
      setshowModalComplete(true);
    }
  };

  const onQuestionChange = (id: number, answerLevel: number) => {
    dispatch(
      setQuestionAnswered({
        id,
        answerLevel
      })
    );

    dispatch(
      sendSurveyWizardUpdateRequest({
        surveyWizardSelectionID: id,
        answerLevel
      })
    );

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    scrollToNextQuestion(id);
  };

  const scrollToNextQuestion = (id: number) => {
    const nextQuestionIndex =
      questionAndAnswers.findIndex((question) => {
        return question.surveyWizardSelectionID === id;
      }) + 1;
    const nextQuestion = questionAndAnswers[nextQuestionIndex];
    if (nextQuestion) {
      const nextQuestionElement = document.getElementById(nextQuestion.surveyWizardSelectionID.toString());
      if (nextQuestionElement) {
        const topOfNextQuestion = nextQuestionElement.offsetTop - seityHeader.clientHeight - 4;
        window.scroll({ top: topOfNextQuestion, behavior: "smooth" });
      }
    }
  };

  const scrollToUnansweredQuestion = () => {
    const firstNotAnsweredIndex = questionAndAnswers.findIndex((i) => {
      return i.selectedAnswerLevel === 0;
    });
    const firstNotAnswered = questionAndAnswers[firstNotAnsweredIndex];
    if (firstNotAnswered) {
      const nextQuestionElement = document.getElementById(firstNotAnswered.surveyWizardSelectionID.toString());
      if (nextQuestionElement) {
        const topOfNextQuestion = nextQuestionElement.offsetTop - seityHeader.clientHeight - 4;
        window.scroll({ top: topOfNextQuestion, behavior: "smooth" });
      }
    }
  };

  if (isLoading) return <SeityLoader />;

  const SurveyHeader = () => (
    <div className="survey-wrap-header-wrapper">
      <div className="survey-wrap-header">
        <div className="survey-wrap-header-button">
          <SeityHyperText
            onClick={() => {
              return setShowFinishLater(true);
            }}
            title={strings.finishLater}
          />
        </div>
        <SurveyQuestionProgress />
      </div>
    </div>
  );

  const getContent = () => {
    return (
      <div className="question-section-container">
        {questionAndAnswers.map((question) => {
          return (
            <SeityQuestion
              id={question.surveyWizardSelectionID}
              needsToBeAnswered={needsToBeAnswered}
              questionTypeId={null}
              questionText={question.question}
              answerLevel={question.selectedAnswerLevel}
              handleSelect={onQuestionChange}
              key={question.surveyWizardSelectionID.toString()}
              answerSelections={question?.answers}
            />
          );
        })}
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const SurveyFooter = ({ onPressOption }) => {
    return (
      <div className="practices-footer">
        <SeityButton label={strings.submit} onClick={onPressOption} className="seity-button-survey" />
      </div>
    );
  };

  return (
    <SurveyWrap showFinishLater={showFinishLater} setShowFinishLater={setShowFinishLater} label={title} subHeader={<SurveyHeader />}>
      <>
        {getContent()}

        <SurveyFooter onPressOption={onPressOption} />

        <SeityModal isOpen={showModalComplete} onClose={onPressOption}>
          <div className="maintenance-modal">
            <h2>{strings.surveyCompleteText}</h2>
            <SeityButton label={strings.ok} type="next" onClick={onPressOption} />
          </div>
        </SeityModal>

        <SeityAlert
          title={strings.oops}
          visible={showUnansweredQuestionsModal}
          onToggle={() => {
            scrollToUnansweredQuestion();
            setNeedsToBeAnswered(true);
            setShowUnansweredQuestionsModal(false);
          }}
          subTitle={strings.unansweredQuestionsAlert}
        />
      </>
    </SurveyWrap>
  );
};
