import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { Sync } from "../../../api/sync/types/syncTypes";
import { getSyncList } from "../../../api/sync/seityHealthAPI-Sync";
import { formatImagePath } from "../../../api/utils";
import { IMAGE_URL } from "../../../api/apiConstants";

export interface SyncListState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly syncList: Array<Sync>;
}

const initSyncList = () => {
  return {
    syncTitles: [],
    descriptionText: ""
  };
};

const setInitialState = {
  error: null,
  isLoading: false,
  syncList: []
} as SyncListState;

const storySlice = createSlice({
  name: "synclist-synclist",
  initialState: setInitialState,
  reducers: {
    setSyncList(state, action: PayloadAction<Array<Sync>>) {
      state.syncList = action.payload;
      state.isLoading = false;
    },
    setSyncListError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    resetSyncListSlice: () => setInitialState
  }
});

export const { setSyncList, setSyncListError, clearApiError, setIsLoading, resetSyncListSlice } = storySlice.actions;
export default storySlice.reducer;

export const getSyncListRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const syncListResponse = await getSyncList(token);
    if (syncListResponse.success) {
      const ret = syncListResponse.data?.map((i) => {
        i.image = formatImagePath(i.image, "~/images/", IMAGE_URL);

        return i;
      });
      dispatch(setSyncList(ret));
      dispatch(setIsLoading({ isLoading: false }));
    }
  } catch (err) {
    console.error(err);
    dispatch(setSyncListError({ error: err.toString() }));
  }
};
