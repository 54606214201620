import { FunctionComponent, ReactElement, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import strings from "../../../_core/strings/strings";
import { ListItemProps } from "./types";

import "./styles.scss";
import SeityLoader from "../../../_core/components/SeityLoader";
import { PutIntentionType } from "../../../api/dailyIntention/intentionTypes";
import { putIntentionRequest } from "../intentionsSlice";
import CompleteSuccessModal from "../CompleteModal";

const ListItem: FunctionComponent<ListItemProps> = (props): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { onDelete, onComplete, intention } = props;

  const [checked, setChecked] = useState(intention.isCompleted);
  const [isLoading, setLoading] = useState(false);
  const [showComplete, setShowComplete] = useState(false);

  const createDate = moment(intention.selectedDate, "MM-DD-YYYY");
  const isToday = moment().diff(createDate, "days") === 0;

  return (
    <li key={`${intention.dailyIntentionID}-listitem`}>
      <p className="date-text">
        {`${isToday ? "Today, " : ""}${createDate.format("dddd")}, ${createDate.format("MMMM")} ${createDate.format("DD")}`}
      </p>
      <div className="listitem-content flex-col flex-ai-c w-100 mt-lg">
        <img className="background-img" src={intention.imageFileName + "h.png"} alt="background img" />
        <div className="background-mask" />
        <div
          className="listitem-content-wrap w-100"
          onClick={() => {
            history.push({ pathname: "/intentions/card", state: { intention } });
          }}
        >
          <div className="content-top pr-xl pt-xl mb-xxl">
            <img className="mr-lg" src={intention.outcomeCategoryImage} alt="category img" width={32} height={32} />
            <span>{intention.outcomeCategory}</span>
          </div>
          <p>
            {strings.todayIWill}
            <br />
            <br />
            {intention.text}
          </p>
        </div>

        <div className="listitem-overlay w-100 h-100 flex pt-xl pl-xl">
          <button
            className={`btn-check flex-center ${checked ? "checked" : ""}`}
            onClick={() => {
              setLoading(true);
              const param: PutIntentionType = {
                dailyIntentionAccountID: intention.dailyIntentionAccountID,
                coreValueID1: intention.coreValueID1,
                coreValueSelected1: intention.coreValueSelected1,
                coreValueID2: intention.coreValueID2,
                coreValueSelected2: intention.coreValueSelected2,
                coreValueID3: intention.coreValueID3,
                coreValueSelected3: intention.coreValueSelected3,
                coreValueID4: intention.coreValueID4,
                coreValueSelected4: intention.coreValueSelected4,
                isCompleted: !checked
              };
              dispatch(
                putIntentionRequest(param, () => {
                  setLoading(false);
                  if (!checked) {
                    setShowComplete(true);
                  }
                  onComplete(!checked);
                  setChecked(!checked);
                })
              );
            }}
          >
            <img src={require("../../../assets/graphics/intentions/check.png").default} alt="check icon" width={17} />
          </button>
          <button
            className="btn-delete ml-lg"
            onClick={() => {
              onDelete();
            }}
          >
            <img
              src={require("../../../assets/graphics/intentions/intentions-delete.png").default}
              alt="delete button"
              width={32}
              height={32}
              color="red"
            />
          </button>
          <div
            className="w-100 h-100"
            onClick={() => {
              history.push({ pathname: "/intentions/card", state: { intention: { ...intention, isCompleted: checked } } });
            }}
          />
        </div>
      </div>
      {isLoading && <SeityLoader />}
      {showComplete && (
        <CompleteSuccessModal
          isOpen
          onClose={() => {
            setShowComplete(false);
          }}
        />
      )}
    </li>
  );
};

export default ListItem;
