import { FunctionComponent, ReactElement, useState } from "react";

import CoreStrings from "../../../_core/strings/strings";
import SeityModal from "../../../_core/components/SeityModal";
import { SeityTextInput } from "../../../_core/components/SeityTextInput/SeityTextInput";
import SeityButton from "../../../_core/components/SeityButton";

import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { RootState } from "../../../app/rootReducer";
import { postPersonalListCreate } from "../slice";
import { useHistory } from "react-router-dom";

export type NewPersonalListModalProps = {
  onClose: () => void;
};

const NewPersonalListModal: FunctionComponent<NewPersonalListModalProps> = ({ onClose }): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.personalListSlice);
  const [plName, setPlname] = useState("");
  const [charLimitReached, setCharLimitReached] = useState(false);

  const listCreationSuccess = (listID: number) => {
    onClose();
    history.push(`/personalList/${listID}`);
  };

  return (
    <SeityModal wrapperClassName="newPLModal" title={CoreStrings.newPersonList} showCloseBtn={!isLoading} isOpen={true} onClose={onClose}>
      <div className="newPLModal-content">
        <SeityTextInput
          className={charLimitReached ? "error-outline" : ""}
          autoFocus={true}
          value={plName}
          onChange={(e) => {
            if (e.target.value.length < 100) {
              setCharLimitReached(false);
              setPlname(e.target.value);
            } else {
              setCharLimitReached(true);
            }
          }}
        />
        {charLimitReached && (
          <div className="char-limit-reached">
            <div>
              <img src={require("../../../assets/icons/error-info.svg").default} />
              <p>{CoreStrings.reachedCharacterLimit}</p>
            </div>
            <p>100/100</p>
          </div>
        )}
        <SeityButton
          label={CoreStrings.create}
          disabled={plName.length < 1 || charLimitReached}
          onClick={() => {
            dispatch(postPersonalListCreate(plName, listCreationSuccess));
          }}
        />
      </div>
    </SeityModal>
  );
};

export default NewPersonalListModal;
