/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import { useEffect, useState, FunctionComponent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { RootState } from "../../../../app/rootReducer";
import { sendCheckInDueRequest, sendCheckInResults, sendIncentiveInfoRequest } from "../../checkInSlice";
import { sendGetAccountInfoRequest, sendMessageCenterListRequest, setIsCheckInReirected } from "../../../account/accountSlice";
import { SeityOutcomeCategory } from "../../../../api/baseTypes";

import { OutcomeItem } from "../OutcomeItemContainer";
import { GetHelpModal } from "../../getHelpModal";

import faceOne from "../../../../assets/graphics/face1.png";
import faceTwo from "../../../../assets/graphics/face2.png";
import faceThree from "../../../../assets/graphics/face3.png";
import faceFour from "../../../../assets/graphics/face4.png";
import faceFive from "../../../../assets/graphics/face5.png";
import oneSelected from "../../../../assets/graphics/face1-select.png";
import twoSelected from "../../../../assets/graphics/face2-select.png";
import threeSelected from "../../../../assets/graphics/face3-select.png";
import fourSelected from "../../../../assets/graphics/face4-select.png";
import fiveSelected from "../../../../assets/graphics/face5-select.png";

import FinishCheckInLaterModal from "../../FinishCheckInLaterModal";
import SeityButton from "../../../../_core/components/SeityButton";
import SeityModal from "../../../../_core/components/SeityModal";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";

import strings from "../../../../_core/strings/strings";
import "./styles.scss";
import SeityLoader from "../../../../_core/components/SeityLoader";
import Incentives from "../../Incentives";
import { CheckInAction } from "../../../../api/checkInTypes";
import { isPlotlineInjectionCompleted } from "../../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../../api/plotlineTypes";

interface CheckInProps {
  onFallBack?: () => void;
}

export const CheckIn: FunctionComponent<CheckInProps> = ({ onFallBack }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, dueResponse } = useSelector((state: RootState) => {
    return state.checkIn;
  });
  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });
  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });
  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  const [energyValue, setEnergyValue] = useState(-1);
  const [directionValue, setDirectionValue] = useState(-1);
  const [belongingValue, setBelongingValue] = useState(-1);
  const [joyValue, setJoyValue] = useState(-1);
  const [isGetHelpModalVisible, setIsGetHelpModalVisible] = useState(false);
  const [showFinishLater, setShowFinishLater] = useState(false);
  const [isCheckInError, setIsCheckInError] = useState(false);
  const [showIncentive, setShowIncentive] = useState(false);
  const [isIncentiveActive, setIsIncentiveActive] = useState(false);
  const [checkinResult, setCheckinResult] = useState({
    message: "",
    action: CheckInAction.NoAction
  });
  const [checkinProgress, setCheckinProgress] = useState(false);

  useEffect(() => {
    if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.Wellbeing)) {
      history.push("/plotline/" + PlotlineInjectionPointID.Wellbeing);
    } else {
      dispatch(setIsCheckInReirected(true));
      dispatch(sendGetAccountInfoRequest());
      dispatch(sendCheckInDueRequest());
    }
  }, []);

  const getHelpModal = useMemo(() => {
    return (
      <GetHelpModal
        isOpen={isGetHelpModalVisible}
        message={checkinResult.message}
        action={checkinResult.action}
        onClose={() => {
          setIsGetHelpModalVisible(false);

          if (isIncentiveActive) {
            setShowIncentive(true);
          } else {
            navigateAfterAction();
          }

        }}
        setIsGetHelpModalVisible={setIsGetHelpModalVisible}
        firstName={accountInfo.firstName}
      />
    );
  }, [isGetHelpModalVisible, checkinResult, isIncentiveActive, onFallBack, userAppSettings]);

  const cancelClicked = () => {
    setIsCheckInError(false);
  };

  const images = [faceOne, faceTwo, faceThree, faceFour, faceFive];
  const selectedImages = [oneSelected, twoSelected, threeSelected, fourSelected, fiveSelected];

  const navigateAfterAction = () => {
    if (onFallBack) {
      onFallBack();
    } else if (userAppSettings.isWellbeingOnly) {
      history.push("/wellbeing");
    } else if (!userAppSettings.todayPageEnabled) {
      history.push("/coreValues");
    } else {
      history.push("/today");
    }
  };

  const submitHandler = () => {
    if (belongingValue === -1 || directionValue === -1 || energyValue === -1 || joyValue === -1) {
      setIsCheckInError(true);
    } else {
      setCheckinProgress(true);

      dispatch(
        sendCheckInResults(energyValue * 100, directionValue * 100, belongingValue * 100, joyValue * 100, (data) => {
          if (!data) {
            console.log("sendCheckInResults failed with api error");
            return;
          }

          setIsIncentiveActive(data?.incentiveInformation?.incentiveActive ?? false);

          setCheckinResult({
            message: data?.message ?? "",
            action: data?.action ?? CheckInAction.NoAction
          });

          dispatch(sendIncentiveInfoRequest());
          dispatch(sendCheckInDueRequest());
          dispatch(sendMessageCenterListRequest());

          if (data?.action === CheckInAction.NoAction) {
            if (data?.incentiveInformation.incentiveActive) {
              setShowIncentive(true);
              return;
            }
            navigateAfterAction();
          } else {
            setIsGetHelpModalVisible(true);
          }
        })
      );
    }
  };

  if ((dueResponse && !dueResponse.due && !checkinProgress && !userAppSettings.multipleWellbeingCheckInEnabled) || showIncentive) {
    if (onFallBack) {
      return <Incentives onFallBack={() => onFallBack()} />;
    }
    return <Incentives />;
  }
  if ((dueResponse && dueResponse.due) || checkinProgress || userAppSettings.multipleWellbeingCheckInEnabled) {
    return (
      <div className="checkin-container" style={{ flex: 1 }}>
        <h1>{strings.checkInTitle}</h1>
        <div className="outcome-container">
          <OutcomeItem
            id="outcomeItem1"
            category={SeityOutcomeCategory.Energy}
            value={energyValue}
            images={images}
            selectedImages={selectedImages}
            valueChanged={setEnergyValue}
          />
          <OutcomeItem
            id="outcomeItem2"
            category={SeityOutcomeCategory.Direction}
            value={directionValue}
            images={images}
            selectedImages={selectedImages}
            valueChanged={setDirectionValue}
          />
          <OutcomeItem
            id="outcomeItem3"
            category={SeityOutcomeCategory.Belonging}
            value={belongingValue}
            images={images}
            selectedImages={selectedImages}
            valueChanged={setBelongingValue}
          />
          <OutcomeItem
            id="outcomeItem4"
            category={SeityOutcomeCategory.Joy}
            value={joyValue}
            images={images}
            selectedImages={selectedImages}
            valueChanged={setJoyValue}
          />
          <div className={`checkin-start-button ${onFallBack ? "journal-modal" : ""}`}>
            <SeityButton
              label={strings.submit}
              onClick={submitHandler}
              type="next"
              testId="submitButton"
              className="checkin-submit-button"
            />
            <SeityHyperText
              title={strings.checkinLater}
              onClick={() => {
                return setShowFinishLater(true);
              }}
            />
          </div>
          {getHelpModal}
          <FinishCheckInLaterModal
            onFallBack={onFallBack}
            visible={showFinishLater}
            onToggle={() => {
              setShowFinishLater(false);
            }}
          />
          <SeityModal
            wrapperClassName="check-in-error-modal"
            overlayClassName="check-in-error-overlay"
            isOpen={isCheckInError}
            onClose={cancelClicked}
          >
            <div className="check-in-error-content flex-col flex-ai-c">
              <img src={require("../../../../assets/icons/alert.svg").default} alt="Alert icon" width={64} />
              <h2 className="mt-xl">{strings.checkInErrorTitle}</h2>
              <SeityButton className="mt-lg" label={strings.gotIt} onClick={cancelClicked} />
            </div>
          </SeityModal>
        </div>
      </div>
    );
  }
  return <SeityLoader />;
};

export default CheckIn;
