import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import strings from "../../../_core/strings/strings";
import SeityModal from "../../../_core/components/SeityModal";
import SeityButton from "../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../_core/components/SeityHyperText";
import formatPhoneNumber from "../../../_core/utils/countryCode/formatPhoneNumber";

import { CredentialsType } from "../../account/maintenance/ModifyAuthentication";
import { RootState } from "../../../app/rootReducer";
import { CredentialVerificationProps } from "../types";

import "./styles.scss";
import { sendSmsVerificationCodeRequest } from "../../account/accountSlice";
import { setPhoneVerificationShown } from "../../../slices/userAppSettingsSlice";

export const CredentialVerification: FunctionComponent<CredentialVerificationProps> = (props): ReactElement => {
  const { isVisible, setVisible, credentialType, phoneNumber, countryCodeId } = props;
  const { phoneCountryCodes } = useSelector((state: RootState) => {
    return state.register;
  });

  const history = useHistory();
  const dispatch = useDispatch();

  let title,
    subTitle,
    credential,
    changeCredential = "";

  if (credentialType === CredentialsType.PhoneNumber) {
    title = strings.verifyPhoneNumber;
    subTitle = strings.yourPhoneNumber;
    changeCredential = strings.changePhoneNumber;
    credential = formatPhoneNumber(phoneNumber, countryCodeId, phoneCountryCodes);
  }

  const onCancel = () => {
    setVisible(false);
    dispatch(setPhoneVerificationShown(true));
  };

  const onChange = () => {
    setVisible(false);
    history.push({
      pathname: "/authentication/changeCredential",
      state: { credentialsType: CredentialsType.PhoneNumber, checkPassword: true }
    });
  };

  const onConfirm = () => {
    dispatch(setPhoneVerificationShown(true));
    dispatch(sendSmsVerificationCodeRequest());
    history.push({ pathname: "/phoneVerification", state: { isFromSettings: false } });
  };

  return (
    <SeityModal
      isOpen={isVisible}
      onClose={() => {
        setVisible(false);
      }}
      wrapperClassName="credential-verification-modal"
    >
      <div className="credential-verification-modal__content">
        <h2>{title}</h2>
        <h4>{subTitle}</h4>
        <h4 style={{ fontWeight: "bold" }}>{credential}</h4>
        <div className="credential-verification-modal__hypertext">
          <SeityHyperText type="underline" title={changeCredential} onClick={onChange} />
        </div>
        <SeityButton label={strings.sure} onClick={onConfirm} type="inline" />
        <SeityButton label={strings.notNow} onClick={onCancel} type="inline-cancel" />
      </div>
    </SeityModal>
  );
};
