import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import { useHistory } from "react-router-dom";
import FSGroupAavatar from "./components/FSGroupAavatar";
import FSProfileCard from "../FSProfileCard";
import SeityModal from "../../../_core/components/SeityModal";
import FSMemberSidebar from "../FSMemberSidebar";
import { useDispatch, useSelector } from "react-redux";
import { sendGetSeityCoreValues } from "../../grow/learn/guideSlice";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";
import { useFSGroupDetail, useFSMemberList } from "../hooks";
import { putLeaveFSGroup } from "../familySharingSlice";
import { logOut } from "../../auth/authSlice";

type FSGroupDetailMemberProps = {
  groupID: number;
};

const FSGroupDetailMember: FunctionComponent<FSGroupDetailMemberProps> = ({ groupID }): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading: memberListLoading, memberList } = useFSMemberList(groupID);
  const { groupData } = useFSGroupDetail(groupID);
  const { isLoading, coreValues } = useSelector((state: RootState) => state.guide);
  const { isLoading: fsLoading, fsStatusList, fsRelationshipList } = useSelector((state: RootState) => state.familySharingSlice);
  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  const [selMemberIdx, setSelMemberIdx] = useState(-1);
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const [showMemberProfile, setShowMemberProfile] = useState(false);

  useEffect(() => {
    if (coreValues === null || coreValues?.length < 1) {
      dispatch(sendGetSeityCoreValues());
    }
  }, []);

  useEffect(() => {
    if (showMemberProfile) {
      if (typeof window !== "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [selMemberIdx, showMemberProfile]);

  const accountHolder = useMemo(() => {
    return memberList.find(member => member.accountID === accountInfo.accountID);
  }, [memberList])

  const handleLeaveGroupModal = (actionIdx: number) => {
    setShowLeaveGroupModal(false);
    if (actionIdx === 0) {
      dispatch(putLeaveFSGroup(groupID, () => {
        if (accountHolder?.willLoseSeityAccess) {
          dispatch(logOut());
          history.push("/login");
        } else {
          history.push("/familyGroups");
        }
      }));
    }
  };

  return (
    <div className="fsGroupsDetail">
      {(isLoading || fsLoading || memberListLoading) && <SeityLoader />}
      <div className="fsGroupsDetail-header">
        <FSGroupAavatar imgStr={groupData.profilePicture} readonly={true} />
        <div className="fsGroupsDetail-header__name">
          <h1>{groupData.name}</h1>
        </div>
      </div>
      <div className="fsGroupsDetail-list">
        <h5>{CoreStrings.organizer}</h5>
        <div className="fsGroupsDetail-list__item">
          <FSProfileCard firstName={groupData.organizer.firstName} lastName={groupData.organizer.lastName} coreValues={groupData.organizer.coreValues} profileImg={groupData.organizer.profilePicture} onClickProfile={() => { setShowMemberProfile(true); }} />
        </div>
        <h5>{CoreStrings.members}</h5>
        {memberList.map((m, index) => {
          return (
            <div className="fsGroupsDetail-list__item" key={index}>
              <FSProfileCard firstName={m.firstName} lastName={m.lastName} coreValues={m.coreValues} profileImg={m.profilePicture} inviteStatus={m.familySharingStatusID} onClickProfile={() => { setSelMemberIdx(index); setShowMemberProfile(true); }} />
            </div>
          );
        })}
      </div>
      <button className="fsGroupsDetail-delete" onClick={() => { setShowLeaveGroupModal(true); }}>
        <img src={require("../../../assets/icons/icon-leavegroup.svg").default} width={16} />
        <span>{CoreStrings.leaveGroup}</span>
      </button>
      {showLeaveGroupModal && <SeityModal
        wrapperClassName="fsRemoveMemberModal" title={`${CoreStrings.leave} ${groupData.name}`}
        text={accountHolder?.willLoseSeityAccess ? CoreStrings.leaveGroupDescLoseAccess : CoreStrings.leaveGroupDesc} showCloseBtn={true} isOpen={true} onClose={() => {
          setShowLeaveGroupModal(false);
        }}
        actionButtons={[
          { type: "submit", label: CoreStrings.leave },
          { type: "cancel", label: CoreStrings.cancelButtonLabel }
        ]}
        onClickActions={handleLeaveGroupModal}
      />}
      {showMemberProfile && (
        <FSMemberSidebar
          memberData={selMemberIdx === -1 ? groupData.organizer : memberList[selMemberIdx]}
          organizerID={groupData.organizer.accountID}
          onClose={() => {
            setShowMemberProfile(false);
            setSelMemberIdx(-1);
          }}
        />
      )}
    </div>
  );
};

export default FSGroupDetailMember;
