import { FunctionComponent, ReactElement, useEffect, useRef } from "react";

import autosize from "autosize";

type LimitTextAreaProps = {
  maxLength: number;
  needAutoSize?: boolean;
  disabled: boolean;
  className?: string;
  value: string;
  rows?: number;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const LimitTextArea: FunctionComponent<LimitTextAreaProps> = (props): ReactElement => {
  const { maxLength, needAutoSize = false, disabled, placeholder, className, value, rows, onChange } = props;

  const ref = useRef<any>();

  useEffect(() => {
    if (needAutoSize) {
      autosize(ref.current);
    }
  }, []);

  const isReachedLimit = value.length === maxLength && !disabled;
  return (
    <>
      <textarea
        ref={ref}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        className={`${className} ${isReachedLimit ? "max-limit" : ""}`}
        value={value}
        rows={rows}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <div className="flex-row flex-ai-c flex-jc-fe mt-sm">
        {isReachedLimit && (
          <>
            <img
              className="ml-auto mr-md"
              src={require("../../../assets/graphics/journals/icon-max-limit.png").default}
              width={20}
              height={20}
              alt="max limit"
            />
            <span className="mr-xxl max-limit">Reached maximum characters </span>
          </>
        )}
        <span className={isReachedLimit ? "max-limit" : ""}>
          {value.length}/{maxLength}
        </span>
      </div>
    </>
  );
};

export default LimitTextArea;
