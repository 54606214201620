import { FunctionComponent, useState } from "react";
import './styles.scss';

interface SeityToggleProps {
  initial?: boolean;
  onChange?: (newState: boolean) => void;
  disabled?: boolean;
}

export const SeityToggle: FunctionComponent<SeityToggleProps> = ({ initial = false, onChange, disabled = false }) => {
  const [isOn, setIsOn] = useState(initial);

  const handleToggle = () => {
    if (disabled){
      return;
    }

    setIsOn(!isOn);
    if (onChange) {
      onChange(!isOn);
    }
  };

  return (
    <div className={`toggle-switch ${isOn ? 'on' : 'off'} ${disabled ? 'disabled' : ''}`} onClick={handleToggle}>
      <div className="toggle-handle" />
    </div>
  );
};
