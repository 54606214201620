export enum ContentType {
  CodeVerify,
  UpdateEmail,
  PwdValidate
}

export type LocationStateProps = {
  isFromRegistration: boolean;
  isFromSettings: boolean;
};

export type ContentProps = {
  setContentType: (type: ContentType) => void;
};
