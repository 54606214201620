import { FunctionComponent, ReactElement } from "react";
import Modal from "react-modal";
import Lottie from "react-lottie";

import animationData from "./sync-complete.json";
import strings from "../../../../../_core/strings/strings";

import "./styles.scss";

type CompleteSuccessModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const CompleteSuccessModal: FunctionComponent<CompleteSuccessModalProps> = ({ isOpen, onClose }): ReactElement => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="complete-success-modal" overlayClassName="complete-success-overlay">
      <Lottie options={defaultOptions} height={262} width={262} />
      <div className="mt-xl flex-col flex-ai-c">
        <h3>{strings.completeSuccessHeader}</h3>
        <p className="complete-success-modal__title">{strings.youCompletedSync}</p>
      </div>
    </Modal>
  );
};

export default CompleteSuccessModal;
