import { useState, useEffect, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DomPurify from "dompurify";

import { SeityAppContext } from "../../../app/SeityAppContext";
import { RootState } from "../../../app/rootReducer";
import { putApproveEULARequest } from "../../../slices/userAppSettingsSlice";
import { getAppContentByKeysRequest, getAppContentRequest } from "../../policy/appContentSlice";

import NoAccessModal from "./components/NoAccessModal";
import SeityButton from "../../../_core/components/SeityButton";
import SeityLoader from "../../../_core/components/SeityLoader";
import NoAccessModalLoop from "./components/NoAccessLoopModal";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import SeityModal from "../../../_core/components/SeityModal";

type EULAStateProps = {
  isRevised: boolean;
};

const EULA_CONTENT_KEY_TITLE = "NewEULATitle";
const EULA_CONTENT_KEY_CONTENT = "NotifyNewEULA";

export const EULA = () => {
  const appContext = useContext(SeityAppContext);
  const location = useLocation<EULAStateProps>();
  const history = useHistory();
  const dispatch = useDispatch();

  const isRevised = location?.state?.isRevised ?? false;

  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [showNoAccessModalLoop, setShowNoAccessModalLoop] = useState(false);
  const [eulaChecked, setEulaChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { isLoading: apiLoading, appContentLines, appContentByKeys } = useSelector((state: RootState) => {
    return state.appContent;
  });

  useEffect(() => {
    if (appContext) {
      appContext.setShowTodo(false);
      appContext.setShowNavMenuItems(false);
    }

    if (isRevised) {
      dispatch(getAppContentByKeysRequest(["contentKeys="+EULA_CONTENT_KEY_TITLE, "contentKeys="+EULA_CONTENT_KEY_CONTENT]));
    } else {
      dispatch(getAppContentRequest(5));
    }

    return () => {
      if (appContext) {
        appContext.setShowTodo(true);
        appContext.setNavigationMenuButtonText("");
        appContext.setShowNavMenuItems(true);
      }
    };
  }, []);

  const handleAgreePressed = () => {
    setLoading(true);
    dispatch(
      putApproveEULARequest(isRevised, () => {
        setLoading(false);
        history.push("/");
      })
    );
  };

  const handleDisagreePressed = () => {
    if (eulaChecked) {
      setShowNoAccessModalLoop(true);
      return;
    }
    setEulaChecked(true);
    setShowNoAccessModal(true);
  };

  const EULAContent = useMemo(() => {
    let ret;
    if (isRevised) {
      const title = appContentByKeys.find((a) => a.key === EULA_CONTENT_KEY_TITLE);
      const content = appContentByKeys.find((a) => a.key === EULA_CONTENT_KEY_CONTENT);
      ret = {
        title: title ? title.content : strings.eulaHeader,
        content: content ? DomPurify.sanitize(content.content) : "<h1>Unable to load EULA</h1>"
      };
    } else {
      ret = {
        title: strings.eulaHeader,
        content: appContentLines[0] ? DomPurify.sanitize(appContentLines[0].contentText) : "<h1>Unable to load EULA</h1>"
      };
    }

    return (
      <>
        <h1>{ret.title}</h1>
        <div className="eula-content">
          <div dangerouslySetInnerHTML={{__html: ret.content}} />
        </div>
        <div className="eula-btns" style={{ maxWidth: isRevised ? "100%" : "284px" }}>
          <SeityButton label={isRevised ? strings.okay : strings.agree} onClick={handleAgreePressed} />
          {!isRevised && <SeityButton label={strings.disagree} onClick={handleDisagreePressed} type="cancel" />}
        </div>
      </>
    );
  }, [appContentByKeys, appContentLines]);

  return (
    <div className="eula-container">
      {isRevised ? (
        <SeityModal isOpen={true}>
          {EULAContent}
        </SeityModal>
      ) : (
        <>
          {EULAContent}
        </>
      )}
      <NoAccessModal
        isOpen={showNoAccessModal}
        onClose={() => {
          return setShowNoAccessModal(false);
        }}
      />
      <NoAccessModalLoop
        isOpen={showNoAccessModalLoop}
        onClose={() => {
          return setShowNoAccessModalLoop(false);
        }}
      />
      {(isLoading || apiLoading) && <SeityLoader hideBackgroundContent />}
    </div>
  );
};
