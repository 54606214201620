/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { SeityButton } from "../SeityButton";
import { SeityNetworkAlertProps } from "./types";
import strings from "../../strings/strings";

import "./styles.scss";

export const SeityNetworkAlert: FunctionComponent<SeityNetworkAlertProps> = ({ pageTitle }) => {
  const history = useHistory();
  return (
    <div className="seity-network-alert">
      <img src={require("../../../assets/graphics/network-alert.png").default} />
      <h1 className="seity-network-alert-title">{strings.networkAlertTitle}</h1>
      <h2 className="seity-network-alert-subtitle">{strings.formatString(strings.networkAlertSubtitle, pageTitle)}</h2>
      <SeityButton label={strings.goBackBtnText} onClick={() => history.goBack()} className="seity-network-alert-button" />
    </div>
  );
};

export default SeityNetworkAlert;
