import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { RootState } from "../../../app/rootReducer";
import { resetChallengeValues, sendCompleteChallenge, sendGetChallengeIntroduction, sendGetChallengeState } from "../challengeSlice";

import SeityButton from "../../../_core/components/SeityButton";

import strings from "../../../_core/strings/strings";
import "../styles.scss";

export const ChallengeComplete = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentObjectiveID, loadedChallengeContent } = useSelector((state: RootState) => {
    return state.challenge;
  });

  useEffect(() => {
    dispatch(sendGetChallengeState());
    dispatch(sendGetChallengeIntroduction());
  }, []);

  const closePressed = () => {
    if (currentObjectiveID === null) {
      history.push("/");
    } else {
      dispatch(sendCompleteChallenge(currentObjectiveID));
      dispatch(resetChallengeValues({}));
      history.push("/");
    }
  };

  let contentResponse;
  let header = "";

  if (loadedChallengeContent.length > 0) {
    contentResponse = loadedChallengeContent[0].content;
    header = contentResponse.header;
  }
  if (header.includes("Challenge")) {
    header = header.replace("Challenge", "");
  } else {
    header += " ";
  }

  const apiMessage = (header = "" ? "current" : header);
  const completionMessage = `You've completed the ${apiMessage} challenge.`;

  return (
    <div className="challenge-status">
      <h1>{strings.congratulations}</h1>
      <h4>{completionMessage}</h4>
      <div className="challenge-status-button">
        <SeityButton label={strings.close} onClick={closePressed} />
      </div>
    </div>
  );
};

export default ChallengeComplete;
