import { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { PostItem } from "../../Slider/PostItem";
import { SeityBackButton } from "../../../../../_core/components/SeityBackButton";
import { Gallery } from "../../Galery";
import { RootState } from "../../../../../app/rootReducer";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";

export const SeriesList: FunctionComponent = () => {
  const history = useHistory();
  const { goodLifeSeries } = useSelector((state: RootState) => state.guide);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderItem = (item: any, index: number) => {
    return (
      <PostItem
        key={index}
        title={item.title}
        className="good-life-item"
        subtitle={item.subtitle}
        cmsID={item.cmsID}
        imageUrl={item.imageUrl}
        onClick={() => history.push(`/learn/post/${item.cmsID}`)}
      />
    );
  };

  return (
    <div className="see-all-container-wrapper">
      <SeityBackButton wrapperClassName="msl-back-button" />
      <h1>{strings.theGoodLifeSeries}</h1>
      <div className="series-gallery-wrapper">
        <Gallery items={goodLifeSeries} renderItem={renderItem} />
      </div>
    </div>
  );
};
