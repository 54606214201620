import { FunctionComponent, ReactElement } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MessageType } from "../../../api/account/types/messageCenterListTypes";
import { RootState } from "../../../app/rootReducer";
import { SeityTodoItemProps } from "./types";
import strings from "../../../_core/strings/strings";

export const SeityTodoItem: FunctionComponent<SeityTodoItemProps> = ({ keyStr, item, configuration, onClose }): ReactElement => {
  const history = useHistory();

  const { isCVOnly } = useSelector((state: RootState) => {
    return state.userAppSettings.userAppSettings;
  });

  if (item.messageTypeID === MessageType.UpToDate || !configuration)
    return (
      <div key={keyStr} className="up-to-date">
        {item.message}
      </div>
    );
  return (
    <div
      key={keyStr}
      className="todo-item-container"
      style={{
        backgroundColor: configuration.background
      }}
    >
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          history.push(configuration.navigationPath(item.messageKeyID));
          onClose();
        }}
      >
        <img className="todo-item-container__icon" src={configuration.img} />
        <p style={{ color: configuration.color }} className="todo-item-container__title">
          {item.messageTypeID === MessageType.SelectCoreValues && isCVOnly ? strings.discoverCoreValues : item.message}
        </p>
      </a>
    </div>
  );
};
