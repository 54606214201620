import { FunctionComponent, useState } from "react";
import "./styles.scss";
import { RankingsTable } from "../RankingsTable";
import { OverallStanding } from "../types";
import { IMAGE_URL } from "../../../api/apiConstants";
import strings from "../../../_core/strings/strings";

type OverallStandingsProps = {
  overallStandings: OverallStanding;
};

export const OverallStandings: FunctionComponent<OverallStandingsProps> = ({ overallStandings }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const prevSlide = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const nextSlide = () => {
    if (activeIndex < overallStandings?.incentives.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const tagColors = ["#ffe093", "#fec0b3", "#cbe3e4"];

  return (
    <div className="overall-standings-container">
      <div className={overallStandings?.incentives?.length > 0 ? "" : "d-flex"}>
        <div style={overallStandings?.incentives?.length > 0 ? {} : { maxWidth: "50%" }}>
          {overallStandings?.title && (
            <div className="header">
              <h3>{overallStandings.title}</h3>
              <img className="more-info" src={require("../../../assets/graphics/more-info.png").default} />
            </div>
          )}
          <h5>{overallStandings?.detailDescription}</h5>
        </div>
        <div className="meta-content">
          {overallStandings?.incentives?.length > 0 && (
            <div className="prize-carousel">
              <div className="prize-image-container">
                <img src={`${IMAGE_URL + overallStandings?.incentives[activeIndex]?.imageName}`} />
                {overallStandings?.incentives[activeIndex]?.placeTitle ? (
                  <div className="rank-tag" style={{ background: tagColors[activeIndex % 3] }}>
                    <p>{overallStandings?.incentives[activeIndex]?.placeTitle}</p>
                  </div>
                ) : null}
                <div className="prize-info">
                  <p className="prize-name">{overallStandings?.incentives[activeIndex]?.prizeName}</p>
                  <p className="sponsor">{overallStandings?.incentives[activeIndex]?.sponsor}</p>
                </div>
              </div>
              <div className="prize-carousel-nav">
                <div className="slides-indicator">
                  {overallStandings?.incentives.map((incentive, index) => (
                    <div className={"bar" + (index === activeIndex ? " active" : "")} key={incentive?.place} />
                  ))}
                </div>
                {overallStandings?.incentives?.length > 1 ? (
                  <div className="nav-buttons">
                    <img src={require("../../../assets/icons/chevron-left.svg").default} onClick={() => prevSlide()} />
                    <img src={require("../../../assets/icons/chevron-right.svg").default} onClick={() => nextSlide()} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div className={overallStandings?.incentives?.length > 0 ? "meta-info" : "meta-info-row"}>
            <div className="meta-info-box">
              <img className="more-info" src={require("../../../assets/graphics/timer.png").default} />
              <div className="content">
                <div>
                  <p className="label">{strings.daysLeft}</p>
                  <p className="value">{overallStandings?.daysLeft}</p>
                </div>
              </div>
            </div>
            <div className="meta-info-box">
              <img className="more-info" src={require("../../../assets/graphics/calendar.png").default} />
              <div className="content">
                <div>
                  <p className="label">{strings.startDate}</p>
                  <p className="value">{overallStandings?.startDate.split(" ")[0]}</p>
                </div>
                <div>
                  <p className="label">{strings.endDate}</p>
                  <p className="value">{overallStandings?.endDate.split(" ")[0]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RankingsTable type={2} schoolList={overallStandings?.overallStandingsData} columnHeaders={overallStandings?.columnHeaders} />
    </div>
  );
};
