import { SeityResponse } from "./baseTypes";

export class SeityCheckInDueResponse extends SeityResponse {
  data: SeityCheckInDuePayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityCheckInDuePayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityCheckInDuePayloadResponse {
  result: boolean;
  due: boolean;
  incentiveActive: boolean;
  dailyCheckInSurveyActive: boolean;
  surveyCompanyScheduleID: number;
  title: string;
  optional: boolean;
  optionalMessage: string;
  lotteryEntry: boolean;

  constructor(
    result: boolean,
    due: boolean,
    incentiveActive: boolean,
    dailyCheckInSurveyActive: boolean,
    surveyCompanyScheduleID: number,
    title: string,
    optional: boolean,
    optionalMessage: string,
    lotteryEntry: boolean
  ) {
    this.result = result;
    this.due = due;
    this.incentiveActive = incentiveActive;
    this.dailyCheckInSurveyActive = dailyCheckInSurveyActive;
    this.surveyCompanyScheduleID = surveyCompanyScheduleID;
    this.title = title;
    this.optional = optional;
    this.optionalMessage = optionalMessage;
    this.lotteryEntry = lotteryEntry;
  }
}

export class SeityCheckInInsertResponse extends SeityResponse {
  data: SeityCheckInInsertPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityCheckInInsertPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityCheckInInsertPayloadResponse {
  operationMessage: string;
  dailyCheckInSurveyActive: boolean;
  surveyCompanyScheduleID: number;
  title: string;
  optional: boolean;
  optionalMessage: string | null;
  lotteryEntry: boolean;
  incentiveActive: boolean;

  constructor(
    operationMessage: string,
    dailyCheckInSurveyActive: boolean,
    surveyCompanyScheduleID: number,
    title: string,
    optional: boolean,
    optionalMessage: string | null,
    lotteryEntry: boolean,
    incentiveActive
  ) {
    this.operationMessage = operationMessage;
    this.dailyCheckInSurveyActive = dailyCheckInSurveyActive;
    this.title = title;
    this.surveyCompanyScheduleID = surveyCompanyScheduleID;
    this.optional = optional;
    this.optionalMessage = optionalMessage;
    this.lotteryEntry = lotteryEntry;
    this.incentiveActive = incentiveActive;
  }
}

export class SeityCheckInIncentiveResponse extends SeityResponse {
  data: SeityCheckInIncentivePayloadResponse;

  constructor(success: boolean, message: string | null, data: SeityCheckInIncentivePayloadResponse) {
    super(success, message);
    this.data = data;
  }
}

export class SeityCheckInIncentivePayloadResponse {
  incentiveActive: boolean;
  checkInScheduleID: number;
  title: string;
  image: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  prizeName: string;
  entries: number;
  totalEntriesAccount: number;
  totalEntriesCompany: number;
  crisisHotlineActive: boolean;
  checkInScore: number;

  constructor(
    incentiveActive: boolean,
    checkInScheduleID: number,
    title: string,
    image: string,
    line1: string,
    line2: string,
    line3: string,
    line4: string,
    prizeName: string,
    entries: number,
    totalEntriesAccount: number,
    totalEntriesCompany: number,
    crisisHotlineActive: boolean,
    checkInScore: number
  ) {
    this.incentiveActive = incentiveActive;
    this.checkInScheduleID = checkInScheduleID;
    this.title = title;
    this.image = image;
    this.line1 = line1;
    this.line2 = line2;
    this.line3 = line3;
    this.line4 = line4;
    this.prizeName = prizeName;
    this.entries = entries;
    this.totalEntriesAccount = totalEntriesAccount;
    this.totalEntriesCompany = totalEntriesCompany;
    this.crisisHotlineActive = crisisHotlineActive;
    this.checkInScore = checkInScore;
  }
}

export class SeityCheckInCrisisInformationResponse extends SeityResponse {
  data: SeityCheckInCrisisInformationPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityCheckInCrisisInformationPayloadResponse) {
    super(success, message);
    this.data = data;
  }
}

export class SeityCheckInCrisisInformationPayloadResponse {
  firstName: string;
  crisisHotlineActive: boolean;
  checkInScore: number;
  crisisHotlinePhoneNumber: string | null;

  constructor(firstName: string, crisisHotlineActive: boolean, checkInScore: number, crisisHotlinePhoneNumber: string | null) {
    this.firstName = firstName;
    this.crisisHotlineActive = crisisHotlineActive;
    this.checkInScore = checkInScore;
    this.crisisHotlinePhoneNumber = crisisHotlinePhoneNumber;
  }
}

export enum CheckInAction {
  NoAction,
  ShowWellnessNavigator,
  ShowEncouragement
}

export type SeityCheckInPostPayloadResponse = {
  message: string;
  action: CheckInAction;
  incentiveInformation: {
    incentiveActive: boolean;
  };
};

export class SeityCheckInPostResponse extends SeityResponse {
  data: SeityCheckInPostPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityCheckInPostPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}
