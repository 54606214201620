import { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../../app/rootReducer";
import { sendLoginRequest, clearApiError, setRedirectToLogin, sendGoogleLoginRequest, logOut } from "../authSlice";
import { resetRegisterSlice } from "../../register/registerSlice";
import { getLanguagesRequest, resetAccountSlice } from "../../account/accountSlice";

import LanguagesSelectionModal from "./LanguagesSelectionModal";
import { LockedAccount } from "../LockedAccount";
import { SeityTextInput } from "../../../_core/components/SeityTextInput/SeityTextInput";
import { SeityButton } from "../../../_core/components/SeityButton";

import SeityLogo from "../../../assets/web-icons/seity_logo.png";
import copyright from "../../../assets/web-icons/copyright.svg";
import languages from "../../../assets/web-icons/languages.svg";
import support from "../../../assets/web-icons/support.svg";
import divider from "../../../assets/web-icons/divider.svg";

import strings from "../../../_core/strings/strings";
import "./Login.scss";
import SeityGoogleLoginButton from "../../../_core/components/SeityGoogleLoginButton";
import { SeityGoogleLoginResponse } from "../../../_core/components/SeityGoogleLoginButton/types";
import SeityGoogleLoginFailedAlert from "../../../_core/components/SeityGoogleLoginFailedAlert";
import { removeTokens } from "../RemoveToken";

export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showGoogleLoginError, setShowGoogleLoginError] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [autofilled, setAutofilled] = useState(false);
  const [isCapsOn, setCapsOn] = useState(false);
  const [selLangID, setSelLangID] = useState(-1);

  const { accountID, token, authError, shouldRedirectToLogin, shouldRedirectToLockedAccount, showLockedAccount, googleAuthError } =
    useSelector((state: RootState) => {
      return state.auth;
    });

  const { languagesList, supportEmail } = useSelector((state: RootState) => state.account);

  useEffect(() => {
    if (shouldRedirectToLogin) {
      // Reset this to avoid a endless loop after login
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: false }));
    }

    // detects chrome autocorrect
    const detectAutofill = (id: string) => {
      if (document.querySelector(id) === null) {
        return;
      }
      return window.getComputedStyle(document.querySelector(id)!, null).getPropertyValue("appearance") === "menulist-button";
    };

    // detects if login input are prefilled by autocorrect
    setTimeout(() => {
      if (detectAutofill("#usernameInput") && detectAutofill("#passwordInput")) {
        setAutofilled(true);
      }
    }, 500);
  });

  // Clears any message when leaving the component gets language list
  useEffect(() => {
    const getLanguages = async () => {
      await dispatch(getLanguagesRequest());
    };
    getLanguages();

    // Reset tokens and reducer to prevent unnecessary api calls with existing token on unauthed screens
    dispatch(logOut);

    return () => {
      dispatch(clearApiError({}));
    };
  }, []);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        loginClicked(event);
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [password, username]);

  useEffect(() => {
    if (googleAuthError) {
      setShowGoogleLoginError(true);
      dispatch(clearApiError({}));
    }
  }, [googleAuthError]);

  const loginClicked = (e: any) => {
    e.preventDefault();
    dispatch(sendLoginRequest(username, password, selLangID));
  };

  const googleLoginSuccess = (response: SeityGoogleLoginResponse) => {
    dispatch(sendGoogleLoginRequest(response, selLangID));
  };

  const googleLoginFail = (response: any) => {
    // todo
  };

  const loginDisabled = () => {
    if (autofilled) {
      return false;
    }
    if (username && password) {
      return false;
    }
    return true;
  };

  const forgotPasswordClicked = () => {
    history.push({
      pathname: "/forgot-password-username",
      state: {flow: "password" }
    });
  };

  const forgotUsernameClicked = () => {
    history.push({
      pathname: "/forgot-password-username",
      state: {flow: "username" }
    });
  };

  const signupClicked = () => {
    dispatch(resetRegisterSlice());
    dispatch(resetAccountSlice());
    history.push("register/registerLangSelect");
  };

  const onKeyDown = (e) => {
    if (e.getModifierState("CapsLock")) {
      setCapsOn(true);
    } else {
      setCapsOn(false);
    }
  };

  const loginError = (
    <div style={{ color: "#DC2F02" }} data-testid="error-message">
      <h1>{strings.tryAgain}</h1>
      <h2>{isCapsOn ? strings.capsMessage : strings.typoMessage}</h2>
    </div>
  );

  const loginDefaultText = (
    <div>
      <h1>{strings.getStarted}</h1>
      <h2>{strings.signInEmailOrUsername}</h2>
    </div>
  );

  const isTextInvalid = authError !== null;

  if (token !== "" && accountID !== -1) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: "/login" }
        }}
      />
    );
  }

  if (shouldRedirectToLockedAccount) {
    return <Redirect to="/lockedAccount" />;
  }

  return (
    <div className="login-container">
      <SeityGoogleLoginFailedAlert
        visible={showGoogleLoginError}
        onClose={() => {
          setShowGoogleLoginError(false);
        }}
      />
      <div className="login-bubble-wrap">
        {showLockedAccount ? (
          <LockedAccount />
        ) : (
          <>
            <div className="login-bubble-wrap__top">
              <img src={SeityLogo} alt="seity-logo" className="login-bubble-logo" />
              {authError ? loginError : loginDefaultText}
              <div className="login-bubble-wrap__top__signup">
                <div className="d-flex flex-direction-row mb-3">
                  <span>{strings.newToSeity}</span>
                  <span className="ml-2" onClick={signupClicked}>
                    <u>{strings.signUp}</u>
                  </span>
                </div>
              </div>
            </div>
            <div className="login-bubble-wrap__down">
              <SeityTextInput
                id="usernameInput"
                value={username}
                onChange={(e) => {
                  return setUsername(e.target.value);
                }}
                placeholder={strings.emailOrUsername}
                isInvalid={isTextInvalid && !googleAuthError}
                leftIcon={require("../../../assets/web-icons/mail_icon.png").default}
                containerClassname="login-bubble-input-container"
                className="login-bubble-input"
                leftIconClassname="login-bubble-left-icon"
              />
              <a onClick={forgotUsernameClicked} className="login-bubble-forget-password">
                {strings.forgotUsernameLink}
              </a>
              <div className="mt-3" style={{ width: "100%" }}>
                <SeityTextInput
                  value={password}
                  type="password"
                  onChange={(e) => {
                    return setPassword(e.target.value);
                  }}
                  onKeyDown={onKeyDown}
                  placeholder={strings.passwordLabel}
                  containerClassname="login-bubble-input-container"
                  className="login-bubble-input"
                  leftIconClassname="login-bubble-left-icon"
                  rightIconClassname="login-bubble-right-icon"
                  leftIcon={require("../../../assets/web-icons/lock_icon.png").default}
                  isInvalid={isTextInvalid && !googleAuthError}
                  id="passwordInput"
                />
              </div>
              <a onClick={forgotPasswordClicked} className="login-bubble-forget-password">
                {strings.forgotPWLink}
              </a>
              <div className="login-bubble-button">
                <SeityButton
                  className="login-bubble-button-width"
                  type="submit"
                  disabled={loginDisabled()}
                  label={strings.login}
                  onClick={loginClicked}
                  testId="loginButton"
                />
                <SeityGoogleLoginButton useGoogleButton={false} onSuccess={googleLoginSuccess} onFail={googleLoginFail} />
                <div className="login-signup">
                  <span>{strings.newToSeity}</span>
                  <span onClick={signupClicked}>
                    <u>{strings.signUp}</u>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <footer className="login-footer-wrapper">
        <button onClick={() => setShowLanguages(true)}>
          <img height={18} width={18} src={languages} alt="languages icon" />
          <span>{strings.languages}</span>
        </button>
        <img height={18} width={18} src={divider} alt="divider" />
        <a type="button" href={`mailto:${supportEmail}`}>
          <img height={18} width={18} src={support} alt="support icon" />
          <span>{strings.support}</span>
        </a>
        <img height={18} width={18} src={divider} alt="divider" />
        <span>
          <img height={12} width={12} src={copyright} alt="copyright icon" />
          <span className="login-footer-copyright">
            {new Date().getFullYear()} {strings.SeityCopyright}
          </span>
        </span>
      </footer>
      <LanguagesSelectionModal
        isOpen={showLanguages}
        onClose={(langID) => {
          setShowLanguages(false);
          setSelLangID(langID);
        }}
        languages={languagesList}
      />
    </div>
  );
};

export default Login;
