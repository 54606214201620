import { FunctionComponent, ReactElement, useCallback, useState } from "react";
import "./styles.scss";
import SeityAvatar from "../../../../_core/components/SeityAvatar";
import SeityCVImage from "../../../../_core/components/SeityCVImage";
import strings from "../../../../_core/strings/strings";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import SeityCVModal from "../../../../_core/components/SeityCVModal";
import { CoreValue } from "../../../../api/referenceGuide/referenceGuideTypes";
import { PersonalListAccountDetails } from "../../types";

type PLMemberProfileProps = {
  memberData: PersonalListAccountDetails;
  isRemoveButtonEnabled?: boolean;
};

const PLMemberProfile: FunctionComponent<PLMemberProfileProps> = ({ memberData, isRemoveButtonEnabled }): ReactElement => {
  const { coreValues } = useSelector((state: RootState) => state.guide);

  const [selCVData, setSelCVData] = useState<CoreValue | null>(null);

  const user = {
    firstName: memberData.firstName,
    lastName: memberData.lastName,
    profilePicture: memberData.profilePicture
  };

  const handleCVSelection = useCallback(
    (cvId) => {
      if (cvId > 0 && coreValues && coreValues?.length > 0) {
        const cv = coreValues.find((c) => c.coreValueID === cvId);
        if (cv) {
          setSelCVData(cv);
        }
      }
    },
    [coreValues]
  );

  return (
    <div className="fs-member-profile-container">
      <div className="member-avatar">
        <SeityAvatar size={200} content={user} fontSize={60} />
      </div>
      <p className="member-name">
        {memberData.firstName} {memberData.lastName}
      </p>
      {memberData.coreValues && memberData.coreValues?.length > 0 && (
        <div className="member-core-values">
          <p>
            {memberData.firstName}'s {strings.coreValues}
          </p>
          {memberData.coreValues?.map((cv, index) => (
            <div key={index} className="cv-item-card" onClick={() => handleCVSelection(cv.coreValueID)}>
              <div>
                <SeityCVImage cvId={cv.coreValueID} size={40} />
                <p>{cv.coreValue}</p>
              </div>
              <img src={require("../../../../assets/icons/right-chevron.svg").default} />
            </div>
          ))}
        </div>
      )}
      {isRemoveButtonEnabled && (
        <div className="remove-member">
          <img src={require("../../../../assets/icons/delete.png").default} />
          <p>{strings.removeMember}</p>
        </div>
      )}
      {selCVData && <SeityCVModal cvData={selCVData} onClose={() => setSelCVData(null)} />}
    </div>
  );
};

export default PLMemberProfile;
