import { FunctionComponent } from "react";
import SeityCVImage from "../../../../_core/components/SeityCVImage";

import { ArtOfYouCoreValueProps } from "../types";
import "../styles.scss";

export const ArtOfYouCoreValue: FunctionComponent<ArtOfYouCoreValueProps> = ({ coreValue, coreValueID }) => {
  return (
    <div className="aoy-core-value" key={coreValue}>
      <h1> {coreValue}</h1>
      <SeityCVImage classname="aoy-core-value-img" cvId={coreValueID} size={110} />
    </div>
  );
};
