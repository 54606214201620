import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";
import { useHistory } from "react-router-dom";
import "./styles.scss"

interface SeityOnDemandPlotlineIconProps {
  injectionType: PlotlineInjectionPointID;
}

const SeityOnDemandPlotlineIcon: React.FC<SeityOnDemandPlotlineIconProps> = ({ injectionType }) => {
  const history = useHistory();
  return (<img
    className="more-info"
    src={require("../../../assets/graphics/more-info.png").default}
    onClick={() =>
      history.push({
        pathname: "/plotline/" + injectionType,
        state: {
          onDemand: true
        }
      })
    }
  />)
}

export default SeityOnDemandPlotlineIcon;
