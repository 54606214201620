import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./styles.scss";
import { PlotlineTemplateData, PlotlineTemplateID } from "../../../api/plotlineTypes";
import SeityButton from "../../../_core/components/SeityButton";
import PlotlineTemplateProgressBar from "../components/PlotlineTemplateProgressBar";
import PlotlineTemplateHeader from "../components/PlotlineTemplateHeader";
import SeityModal from "../../../_core/components/SeityModal";
import PlotlineTemplateQuiz from "../components/PlotlineTemplateQuiz";
import { IMAGE_URL } from "../../../api/apiConstants";
import PlotlineTemplateVideo from "../components/PlotlineTemplateVideo";
import PlotlineTemplateImage from "../components/PlotlineTemplateImage";

import { useWizard } from "react-use-wizard";
import { getTemplateLayout, getWellbeingIdx, updateWellbeingLevels } from "../helpers";
import PlotlineTemplateChart from "../components/PlotlineTemplateChart";
import PlotlineTemplateWellbeing from "../PlotlineTemplateWellbeing";

export type PlotlineTemplateStepProps = {
  stepData: PlotlineTemplateData;
  checkinScore: number;
  wbLevels: Array<number>;
  quizAns: Array<number>;
  showBackBtn: boolean;
  setWbLevels: (e: Array<number>) => void;
  setQuizAns: (e: Array<number>) => void;
  onNext: any;
  onBack: any;
};

export const PlotlineTemplateStep: FunctionComponent<PlotlineTemplateStepProps> = (props): ReactElement => {
  const { stepData, checkinScore, wbLevels, quizAns, showBackBtn, setWbLevels, setQuizAns, onNext, onBack } = props;

  const { isHeaderTop, fullPage } = getTemplateLayout(stepData.plotlineTemplateID);
  const hasModal = stepData.modal && (stepData.modal.triggerText ?? "").length > 0;

  const { handleStep, previousStep, nextStep } = useWizard();
  const [showModal, setShowModal] = useState(false);

  const BodyContent = useMemo(() => {
    const getTemplate = () => {
      switch (stepData.plotlineTemplateID) {
        case PlotlineTemplateID.FullPageContentBottomAlignedVideo01:
        case PlotlineTemplateID.FullPageContentTopAlignedVideo01:
          return <PlotlineTemplateVideo videoHorizontal={stepData.videoHorizontal} videoVertical={stepData.videoVertical} />;
        case PlotlineTemplateID.Questionnaire01:
          return (
            <PlotlineTemplateQuiz
              question={stepData.question}
              onSelAnswers={(selAns) => {
                setQuizAns(selAns);
              }}
            />
          );
        case PlotlineTemplateID.WellbeingCheckIn01:
          return (
            <PlotlineTemplateWellbeing
              selectionImages={stepData.selectionImages}
              selIdx={wbLevels[getWellbeingIdx(stepData.outcomeCategoryID!)]}
              title={stepData.title2}
              onSelLevels={(level) => {
                setWbLevels(updateWellbeingLevels(stepData.outcomeCategoryID!, wbLevels, level));
              }}
            />
          );
        case PlotlineTemplateID.CircularGraph01:
          return <PlotlineTemplateChart dynamicChartPercentage={checkinScore} />;
        case PlotlineTemplateID.FullPageContentBottomAlignedImage01:
        case PlotlineTemplateID.FullPageContentTopAlignedImage01:
        case PlotlineTemplateID.MultiConetntHalfPageImage01:
          return (
            <PlotlineTemplateImage
              webImageHorizontal={stepData.webImageHorizontal}
              webImageVertical={stepData.webImageVertical}
              mobileImageVertical={stepData.mobileImageVertical}
            />
          );
        default:
          return <></>;
      }
    };

    return (
      <div
        className={`plotline__body flex flex-column flex-ai-c ${fullPage && "fullpage"} ${isHeaderTop ? "bottom" : "top"} template-id-${
          stepData.plotlineTemplateID
        }`}
      >
        <div className={`plotline__body-content ${fullPage && "fullpage"}`}>{getTemplate()}</div>
      </div>
    );
  }, [stepData, isHeaderTop, fullPage, wbLevels]);

  const actionBtnEnabled = useCallback(() => {
    if (stepData.plotlineTemplateID === PlotlineTemplateID.Questionnaire01 && stepData.question?.isQuestionRequired) {
      return quizAns?.length > 0;
    }
    if (stepData.plotlineTemplateID === PlotlineTemplateID.WellbeingCheckIn01) {
      const idx = getWellbeingIdx(stepData.outcomeCategoryID!);
      return wbLevels[idx] > -1;
    }
    return true;
  }, [quizAns, wbLevels]);

  return (
    <div className={`plotline ${fullPage ? "fullpage" : "halfpage"} bg-${stepData?.plotlineTemplateID} ${hasModal && "hasModal"}`}>
      {stepData.buttonText.length > 0 && (
        <div className="plotline-actionBtn-wrap">
          <SeityButton
            disabled={!actionBtnEnabled()}
            className="plotline-actionBtn"
            label={stepData.buttonText}
            onClick={() => {
              onNext(nextStep);
            }}
          />
        </div>
      )}
      <div
        className={`plotline-content bg-${stepData?.plotlineTemplateID} ${isHeaderTop ? "headerTop" : "headerBottom"} ${
          hasModal && "hasModal"
        }`}
      >
        {!isHeaderTop && BodyContent}
        <div className={`plotline__header flex flex-column flex-ai-c ${isHeaderTop ? "top" : "bottom"} ${fullPage && "fullpage"}`}>
          <div className="plotline__header-content">
            {stepData.progressBarActive && (
              <PlotlineTemplateProgressBar
                progressBarPercentage={stepData.progressBarPercentage}
                showBackButton={showBackBtn}
                plotlineTemplateID={stepData.plotlineTemplateID}
                onBack={() => {
                  onBack(previousStep);
                }}
              />
            )}
            <PlotlineTemplateHeader
              plotlineTemplateID={stepData.plotlineTemplateID}
              iconImage={stepData.icon}
              subHeader={stepData.subtitle}
              header={stepData.title}
              title2={stepData.title2}
              text={stepData.text}
            />
          </div>
        </div>
        {isHeaderTop && BodyContent}
        {stepData.modal && hasModal && (
          <>
            <SeityButton
              className="plotline__modalTrigger"
              label={stepData.modal.triggerText ?? ""}
              image={IMAGE_URL + stepData.modal.triggerImage ?? ""}
              type="black"
              onClick={() => {
                setShowModal(true);
              }}
            />
            <SeityModal
              isOpen={showModal}
              title={stepData.modal.title ?? ""}
              text={stepData.modal.text ?? ""}
              showCloseBtn={true}
              imgCard={
                stepData.modal.imageHorizontal && stepData.modal.imageHorizontal.length > 0
                  ? IMAGE_URL + stepData.modal.imageHorizontal
                  : undefined
              }
              actionButtons={(stepData.modal.screenButtonText ?? "").length > 0 ? [{ type: "submit", label: "Action" }] : []}
              onClickActions={(idx) => {
                console.log("onclick:", idx);
              }}
              onClose={() => {
                setShowModal(false);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PlotlineTemplateStep;
