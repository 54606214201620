import { FunctionComponent, ReactElement } from "react";
import QuoteParser from "./QuoteParser";
import SeityCVImage from "../../../_core/components/SeityCVImage";

import "./styles.scss";

export type YourCoreValueProps = {
  coreValueID: number;
  coreValue: string;
  descriptionText: string;
  quote: string;
};

export const YourCoreValue: FunctionComponent<YourCoreValueProps> = ({ coreValueID, coreValue, quote, descriptionText }): ReactElement => {
  return (
    <div className="mcv-core-value" key={coreValue}>
      <div className="mcv-core-value-title">{coreValue}</div>
      <SeityCVImage cvId={coreValueID} size={104} />
      <QuoteParser quote={quote} descriptionText={descriptionText} />
    </div>
  );
};

export default YourCoreValue;
