/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent, ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../app/rootReducer";
import {
  sendDepartmentListRequest,
  sendJobPositionListRequest,
  sendGetAccountInfoRequest,
  clearAccountError,
  sendGetAccountListsRequest,
  sendAccountMaintenanceUpdateRequest
} from "../accountSlice";
import { getFullDate } from "../../../_core/utils/dateUtils/dateUtils";
import { useAccountLists } from "../../../hooks/AccountList";
import { getDepartmentValue, getJobPositionValue, getValueByList } from "../../../_core/utils/accountUtils/accountUtils";
import { getBase64FromFile } from "../../../_core/utils/fileUtils/byteArrayProcessing";
import { ProfileProps } from "./types";

import { SeityFormTextInput } from "../../../_core/components/SeityTextInput/SeityFormTextInput";
import { SeityDatePickerDropdown } from "../../../_core/components/SeityDatePickerDropdown";
import { SeityFormSelectBox } from "../../../_core/components/SeitySelectBox/SeityFormSelectBox";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import SeityAvatar from "../../../_core/components/SeityAvatar";
import { sendPostProfilePicture, sendPutProfilePicture } from "../../../slices/userAppSettingsSlice";

export type AttachImageType = {
  imageName: string;
  base64String: string;
};

export const Profile: FunctionComponent<ProfileProps> = (): ReactElement => {
  const dispatch = useDispatch();

  const { accountInfo, departmentResponse, jobPositionResponse } = useSelector((state: RootState) => {
    return state.account;
  });
  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const { occupationList, maritalList, genderList, accountStatusList, statusList, educationList } = useAccountLists();

  const [firstName, setFirstName] = useState<string>(accountInfo?.firstName || "");
  const [lastName, setLastName] = useState<string>(accountInfo?.lastName || "");
  const [eMailAddress, setEmailAddress] = useState<string>(accountInfo?.eMailAddress || "");
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(accountInfo?.dateOfBirth ? new Date(accountInfo?.dateOfBirth) : null);
  const [tmpDateOfBirth, setTmpDateOfBirth] = useState<Date | null>(accountInfo?.dateOfBirth ? new Date(accountInfo?.dateOfBirth) : null);
  const [genderID, setGenderID] = useState<number>(accountInfo?.genderID);
  const [maritalStatusID, setMaritalStatusID] = useState<number>(accountInfo?.maritalStatusID);
  const [occupationID, setOccupationID] = useState<number>(accountInfo?.occupationID);
  const [accountStatusId, setAccountStatusId] = useState<number>(accountInfo?.accountStatusID);
  const [statusId, setStatusId] = useState<number>(accountInfo?.statusID);
  const [educationID, setEducationID] = useState<number>(accountInfo?.educationID);
  const [departmentID, setDepartmentID] = useState<number | null>(accountInfo?.departmentID);
  const [jobPositionID, setJobPositionID] = useState<number | null>(accountInfo?.jobPositionID);
  const [zipCode, setZipCode] = useState<string>(accountInfo?.zipcode);

  const [isZipCodeInvalid, setIsZipCodeInvalid] = useState<boolean>(false);
  const [showPhotoActions, setShowPhotoActions] = useState<boolean>(false);

  useEffect(() => {
    clearAccountError();
    dispatch(sendGetAccountInfoRequest());
    dispatch(sendGetAccountListsRequest(true, true, true, true, true, true));
  }, []);

  useEffect(() => {
    if (!departmentResponse) {
      dispatch(sendDepartmentListRequest());
    }
  }, [departmentResponse, dispatch]);

  useEffect(() => {
    if (!jobPositionResponse) {
      dispatch(sendJobPositionListRequest());
    }
  }, [jobPositionResponse, dispatch]);


  const onZipCodeChanged = (zipCode: string) => {
    const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (zipRegExp.test(zipCode)) {
      setIsZipCodeInvalid(false);
    } else {
      setIsZipCodeInvalid(true);
    }
    setZipCode(zipCode);
  };

  const updateAccountInfo = useCallback(() => {
    const isLastNameChanged = lastName && accountInfo?.lastName !== lastName;
    const isFirstNameChanged = firstName && accountInfo?.firstName !== firstName;
    const isEmailAddressChanged = eMailAddress && accountInfo?.eMailAddress !== eMailAddress;
    const isAccountStatusChanged = accountStatusId && accountInfo?.accountStatusID !== accountStatusId;
    const isStatusIDChanged = statusId && accountInfo?.statusID !== statusId;
    const isOccupationIDChanged = occupationID && accountInfo?.occupationID !== occupationID;
    const isEducationIDChanged = educationID && accountInfo?.educationID !== educationID;
    const isGenderIDChanged = genderID && accountInfo?.genderID !== genderID;
    const isMaritalStatusIDChanged = maritalStatusID && accountInfo?.maritalStatusID !== maritalStatusID;
    const isZipCodeChanged = !isZipCodeInvalid && zipCode && accountInfo?.zipcode !== zipCode;
    const isDepartmentIDChanged =
      departmentID && departmentResponse?.departmentEnabled ? accountInfo?.departmentID !== departmentID : false;
    const isJobPositionIDChanged =
      jobPositionID && jobPositionResponse?.jobPositionEnabled ? accountInfo?.jobPositionID !== jobPositionID : false;
    const isDateOfBirthChanged = dateOfBirth && getFullDate(new Date(dateOfBirth)) !== getFullDate(new Date(accountInfo?.dateOfBirth));

    const profileUpdated =
      isLastNameChanged ||
      isFirstNameChanged ||
      isEmailAddressChanged ||
      isAccountStatusChanged ||
      isDateOfBirthChanged ||
      isStatusIDChanged ||
      isOccupationIDChanged ||
      isEducationIDChanged ||
      isGenderIDChanged ||
      isMaritalStatusIDChanged ||
      isZipCodeChanged ||
      isDepartmentIDChanged ||
      isJobPositionIDChanged;

    if (profileUpdated) {
      dispatch(
        sendAccountMaintenanceUpdateRequest({
          firstName: isFirstNameChanged ? firstName : null,
          lastName: isLastNameChanged ? lastName : null,
          eMailAddress: isEmailAddressChanged ? eMailAddress : null,
          accountStatusID: isAccountStatusChanged ? accountStatusId : null,
          dateOfBirth: isDateOfBirthChanged ? dateOfBirth && getFullDate(new Date(dateOfBirth)) : null,
          statusID: isStatusIDChanged ? statusId : null,
          educationID: isEducationIDChanged ? educationID : null,
          occupationID: isOccupationIDChanged ? occupationID : null,
          genderID: isGenderIDChanged ? genderID : null,
          maritalStatusID: isMaritalStatusIDChanged ? maritalStatusID : null,
          zipcode: isZipCodeChanged && !isZipCodeInvalid ? zipCode : null,
          cellPhone: null,
          departmentID: isDepartmentIDChanged ? departmentID : null,
          jobPositionID: isJobPositionIDChanged ? jobPositionID : null,
          languageID: null
        }, true)
      );
    }
  }, [
    accountStatusId,
    dateOfBirth,
    departmentID,
    jobPositionID,
    dispatch,
    eMailAddress,
    educationID,
    firstName,
    genderID,
    isZipCodeInvalid,
    lastName,
    maritalStatusID,
    occupationID,
    statusId,
    zipCode,
    accountInfo
  ]);

  useEffect(() => {
    updateAccountInfo();
  }, [accountStatusId, dateOfBirth, statusId, educationID, occupationID, genderID, maritalStatusID, departmentID, jobPositionID]);

  const uploadPhoto = (name, result) => {
    dispatch(sendPostProfilePicture(name, result as string));
    setShowPhotoActions(false);
  };

  const removePhoto = () => {
    dispatch(sendPutProfilePicture("", ""));
    setShowPhotoActions(false);
  };

  if (accountInfo) {
    return (
      <div className="profile-container">
        <div className="profile-avatar-container">
          <SeityAvatar size={110} fontSize={48} />
          <div className="photo-action-button" onClick={() => setShowPhotoActions(!showPhotoActions)}>
            <img src={require("../../../assets/icons/camera.png").default} />
          </div>
          {showPhotoActions && (
            <div className="photo-action-drawer">
              <div
                onClick={() => {
                  // @ts-ignore
                  document.getElementById("photo-upload-input").click();
                }}
              >
                <img src={require("../../../assets/icons/upload.png").default} />
                <p>{strings.uploadPhoto}</p>
                <input
                  type="file"
                  id="photo-upload-input"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      const img = event.target.files[0];
                      getBase64FromFile(img)
                        .then((result) => {
                          uploadPhoto(img.name, result);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                />
              </div>
              {userAppSettings?.profilePictureBase64 && (
                <div onClick={removePhoto}>
                  <img src={require("../../../assets/icons/delete.png").default} />
                  <p>{strings.removePhoto}</p>
                </div>
              )}
            </div>
          )}
        </div>
        <form>
          <SeityFormTextInput
            inputLabelText={strings.firstNameLabel}
            type="text"
            inputStyle={{ color: "#314947" }}
            onChange={(e) => {
              return setFirstName(e.target.value);
            }}
            value={firstName || accountInfo?.firstName}
            isInvalid={firstName ? firstName.length === 0 : false}
            onBlur={updateAccountInfo}
          />
          <SeityFormTextInput
            inputLabelText={strings.lastNameLabel}
            value={lastName || accountInfo?.lastName}
            controlId="last-name"
            inputStyle={{ color: "#314947" }}
            onChange={(e) => {
              return setLastName(e.target.value);
            }}
            type="text"
            isInvalid={lastName ? lastName.length === 0 : false}
            onBlur={updateAccountInfo}
          />

          <div className="profile-date-picker">
            <label>{strings.dateOfBirthLabel}</label>
            <SeityDatePickerDropdown
              retriveDate={setTmpDateOfBirth}
              date={tmpDateOfBirth || new Date(accountInfo?.dateOfBirth)}
              onBlur={() => {
                setDateOfBirth(tmpDateOfBirth);
              }}
            />
            <img src={require("../../../assets/icons/calendar.png").default} />
          </div>

          <SeityFormSelectBox
            isInvalid={genderID ? genderID <= 0 : false}
            controlId="gender"
            inputLabelText={strings.genderLabel}
            onChange={(e) => {
              return setGenderID(parseInt(e.target.value));
            }}
            value={getValueByList(genderList, genderID || accountInfo?.genderID)}
          >
            <ul>
              {genderList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            isInvalid={maritalStatusID ? maritalStatusID <= 0 : false}
            inputLabelText={strings.maritalStatusLabel}
            onChange={(e) => {
              return setMaritalStatusID(parseInt(e.target.value));
            }}
            value={getValueByList(maritalList, maritalStatusID || accountInfo?.maritalStatusID)}
          >
            <ul>
              {maritalList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormTextInput
            isInvalid={isZipCodeInvalid}
            inputLabelText={strings.zipCodeLabel}
            inputStyle={{ color: "#314947" }}
            controlId="zipcode"
            onChange={(e) => {
              return onZipCodeChanged(e.target.value);
            }}
            value={zipCode || accountInfo?.zipcode}
            onBlur={updateAccountInfo}
          />

          <SeityFormSelectBox
            isInvalid={statusId ? statusId <= 0 : false}
            inputLabelText={strings.statusLabel}
            onChange={(e) => {
              return setStatusId(parseInt(e.target.value));
            }}
            value={getValueByList(statusList, statusId || accountInfo?.statusID)}
          >
            <ul>
              {statusList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            isInvalid={educationID ? educationID <= 0 : false}
            inputLabelText={strings.educationLabel}
            onChange={(e) => {
              return setEducationID(parseInt(e.target.value));
            }}
            value={getValueByList(educationList, educationID || accountInfo?.educationID)}
          >
            <ul>
              {educationList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            isInvalid={occupationID ? occupationID <= 0 : false}
            inputLabelText={strings.occupationLabel}
            onChange={(e) => {
              return setOccupationID(parseInt(e.target.value));
            }}
            value={getValueByList(occupationList, occupationID || accountInfo?.occupationID)}
          >
            <ul>
              {occupationList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          {departmentResponse?.departmentEnabled && (
            <SeityFormSelectBox
              isInvalid={departmentID ? !departmentID : !accountInfo?.departmentID}
              controlId="department-id"
              inputLabelText={departmentResponse.departmentName ?? strings.department}
              value={getDepartmentValue(departmentResponse?.departments, departmentID || accountInfo?.departmentID)}
              onChange={(e) => {
                return setDepartmentID(parseInt(e.target.value));
              }}
            >
              <ul>
                {departmentResponse?.departments?.map((item) => {
                  return (
                    <li key={item.departmentID} value={item.departmentID}>
                      {item.departmentName}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
          )}
          {jobPositionResponse?.jobPositionEnabled && (
            <SeityFormSelectBox
              isInvalid={jobPositionID ? !jobPositionID : !accountInfo?.jobPositionID}
              controlId="job-position-id"
              inputLabelText={jobPositionResponse.jobPositionName ?? strings.jobPosition}
              value={getJobPositionValue(jobPositionResponse?.jobPositions, jobPositionID || accountInfo?.jobPositionID)}
              onChange={(e) => {
                return setJobPositionID(parseInt(e.target.value));
              }}
            >
              <ul>
                {jobPositionResponse?.jobPositions?.map((item) => {
                  return (
                    <li key={item.jobPositionID} value={item.jobPositionID}>
                      {item.jobPositionName}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
          )}
          <SeityFormSelectBox
            isInvalid={accountStatusId ? accountStatusId <= 0 : false}
            inputLabelText={strings.accountStatusLabel}
            onChange={(e) => {
              return setAccountStatusId(parseInt(e.target.value));
            }}
            value={getValueByList(accountStatusList, accountStatusId || accountInfo?.accountStatusID)}
          >
            <ul>
              {accountStatusList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
        </form>
      </div>
    );
  }
  return <></>;
};
