import { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { PostItem } from "../../Slider/PostItem";
import { SeityBackButton } from "../../../../../_core/components/SeityBackButton";
import { Gallery } from "../../Galery";
import { useReferenceGuideList } from "../../data";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";

export const GuidesList: FunctionComponent = () => {
  const history = useHistory();
  const seityReferenceGuideList = useReferenceGuideList();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderItem = (item: any, index: number) => {
    return (
      <PostItem
        key={index}
        title={item.title}
        className="post-list"
        subtitle={item.subtitle}
        imageUrl={item.imageUrl}
        onClick={() => history.push(`/learn/guide/${item.categoryID}`)}
        previewImageName={item.previewImageName}
      />
    );
  };

  return (
    <div className="see-all-container-wrapper">
      <SeityBackButton wrapperClassName="msl-back-button" />
      <h1>{strings.seityReferenceGuide}</h1>
      <div className="guides-gallery-wrapper">
        <Gallery items={seityReferenceGuideList} renderItem={renderItem} />
      </div>
    </div>
  );
};
