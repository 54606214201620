import axios from "axios";
import { BASE_URL } from "../apiConstants";
// import { CharacterizationResponse } from '../story/types/storyTypes';
// import { SeityResponse } from '../baseTypes';
import { StoryListResponse, StoryPutResponse } from "./types/storyTypes";
import { StoryDetailResponse } from "./types/storyTypes";

const SECTION = "/Story/";

export async function getStoryList(token: string): Promise<StoryListResponse> {
  const url = BASE_URL + SECTION;
  try {
    const storyListResponse = await axios.get<StoryListResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return storyListResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getStoryDetail(token: string, objectiveID: number): Promise<StoryDetailResponse> {
  const url = BASE_URL + SECTION + objectiveID;
  try {
    const storyDetailResponse = await axios.get<StoryDetailResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return storyDetailResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function putStory(
  token: string,
  param: {
    storyID: number;
    isCompleted: boolean | null;
    selectedEmotions: Array<{ emotionID: number }> | null;
  }
): Promise<StoryPutResponse> {
  const url = BASE_URL + SECTION;
  try {
    const storyDetailResponse = await axios.put<StoryPutResponse>(url, param, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return storyDetailResponse.data;
  } catch (err) {
    throw err;
  }
}
