import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { RootState } from "../../app/rootReducer";
import SeityLoader from "../../_core/components/SeityLoader";
import "./styles.scss";
import strings from "../../_core/strings/strings";
import SeityCVImage from "../../_core/components/SeityCVImage";
import { getSortedFourCoreValuesRequest, sendDownloadCVBackgroundRequest, sendDownloadPDFRequest, sendDownloadPDFInstructionsRequest } from "./coreValuesSlice";
import { getCoreValuesModeRequest } from "../account/accountSlice";
import { useCoreValueHistory } from "./hooks/useCoreValueHistory";
import SeityButton from "../../_core/components/SeityButton";
import { CVMode } from "../../api/account/types/accountTypes";
import { CoreValue } from "../../api/referenceGuide/referenceGuideTypes";
import SeityCVModal from "../../_core/components/SeityCVModal";
import { sendGetSeityAssets } from "../grow/learn/guideSlice";
import { ASSET1_IMG_URL } from "../../api/apiConstants";
import resourcesImage from "../../assets/graphics/cv-page-definition.png";
import downloadsImage from "../../assets/graphics/cv-page-downloads.png";
import { PlotlineInjectionPointID } from "../../api/plotlineTypes";
import SeityOnDemandPlotlineIcon from "../../_core/components/SeityOnDemandPlotlineIcon";
import CompleteAssessmentModal from "./CompleteAssessmentModal";
import { getUserAppSettingsRequest } from "../../slices/userAppSettingsSlice";
import SeityCoreValuePermissionsModal from "../../_core/components/SeityCoreValuePermissionsModal";

const CV_ASSET_TYPE = 3;

export const CoreValues: FunctionComponent = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { userAppSettings } = useSelector((state: RootState) => state.userAppSettings);
  const { isCheckInRedirected, coreValuesMode, isLoading: isAccountLoading } = useSelector((state: RootState) => state.account);
  const { today } = useSelector((state: RootState) => state.today);
  const { sortedFourCoreValues } = useSelector((state: RootState) => state.fourCoreValues);
  const [selCVData, setSelCVData] = useState<CoreValue | null>(null);
  const { seityScienceAssets: toolsAssetsList } = useSelector((state: RootState) => state.guide);
  const [showElipsisMenu, setShowElipsisMenu] = useState(false);
  const [showCompleteAssessmentModal, setShowCompleteAssessmentModal] = useState(false);
  const hasCompletedCoreValues = userAppSettings.coreValuesComplete;
  const [showDirectoryPermissionsModal, setShowDirectoryPermissionsModal] = useState(false);

  const handleCVDirectoryButtonClick = () => {
    const showModal = userAppSettings.doNotShowCoreValueDirectoryModalPromptClicked === false && 
       (userAppSettings.coreValueDirectoryCoreValuesEnabled === false || 
        userAppSettings.coreValueDirectoryDisplayNameEnabled === false);

    setShowDirectoryPermissionsModal(showModal);

    if (!showModal){
      history.push("/cvdList");
    }
  }

  const dispatch = useDispatch();

  const handleCVSelection = useCallback(
    (cv) => {
      if (cv) {
        setSelCVData({
          coreValueID: cv.coreValueID,
          coreValue: cv.coreValue,
          definition: cv.coreValueText,
          description: cv.descriptionText,
          quote: cv.quote,
          video: cv.video
        });
      }
    },
    [sortedFourCoreValues]
  );

  useEffect(() => {
    const fetchCoreValuesData = async () => {
      await Promise.all([
        dispatch(getSortedFourCoreValuesRequest()),
        dispatch(getCoreValuesModeRequest()),
        dispatch(getUserAppSettingsRequest()),
        dispatch(sendGetSeityAssets(CV_ASSET_TYPE))
      ]);
      setIsLoading(false);
    };

    fetchCoreValuesData();
  }, [dispatch]);

  const { coreValueHistoryCount } = useCoreValueHistory(10);

  if (today.checkInDue && !isCheckInRedirected) {
    return <Redirect to="/checkin/wellbeingCheckIn" />;
  }

  const resources = useMemo(() => {
    let tempArr = [
      {
        image: downloadsImage,
        name: strings.downloads,
        link: "/coreValues/downloads"
      },
      {
        image: resourcesImage,
        name: strings.definitions,
        link: "/learn/guide/0"
      }
    ];
    if(userAppSettings.growEnabled)
    toolsAssetsList.map((item) => {
      tempArr.push({
        image: ASSET1_IMG_URL + item.coverImageName,
        name: item.name ?? "",
        link: `/asset/${item.assetID}`
      });
    });

    return tempArr;
  }, [toolsAssetsList]);

  return (
    <>
      {isLoading || isAccountLoading ? (
        <SeityLoader />
      ) : (
        <div className="core-values-only-container seity-main-fullwidth">
          <SeityCoreValuePermissionsModal isOpen={showDirectoryPermissionsModal} onClose={() => history.push("/cvdList")} />
          {userAppSettings?.coreValueDirectoryEnabled && (
            <div className="directory-cta">
              <p>{strings.coreValueDirectory}</p>
              <SeityButton testId="CVDButton" onClick={handleCVDirectoryButtonClick} type="next" label={strings.view} />
            </div>
          )}
          <div className="member-core-values">
            <div className="member-core-values__header">
              <p>
                {strings.yourCoreValuesTitle}
                <SeityOnDemandPlotlineIcon injectionType={PlotlineInjectionPointID.CoreValues}/>
              </p>
              {coreValuesMode?.mode !== CVMode.Discover && (
                <>
                  <button
                    onClick={() => {
                      setShowElipsisMenu(!showElipsisMenu)
                    }}
                  >
                    <img src={require("../../assets/graphics/horizontal_elipsis.png").default} alt="elips menu"/>
                  </button>
                  {showElipsisMenu && (
                    <div className="ellipsis-menu p-lg">
                      {userAppSettings?.isCVRetakeEligible && <>
                        <button
                          className="ellipsis-menu__button"
                          onClick={() => history.push("/assessment/corevalues", { isRetake: true })}
                        >
                          {strings.retakecorevalues} <img className="icon-right" src={require("../../assets/icons/retakeCoreValues.png").default} alt="retake icon"/>
                        </button>
                        <hr/>
                      </>}
                      <button
                        className="ellipsis-menu__button"
                        onClick={() => {
                          dispatch(sendDownloadCVBackgroundRequest());
                        }}
                      >
                        {strings.virtualbackground} <img className="icon-right" src={require("../../assets/icons/download.png").default} alt="download icon"/>
                      </button>
                      <a href="#" className="ellipsis-menu__link"
                        onClick={() => {
                          dispatch(sendDownloadPDFInstructionsRequest());
                        }} >{strings.virtualbackgroundsetuplink}</a>
                      <hr/>
                      <button
                        className="ellipsis-menu__button"
                        onClick={() => {
                          dispatch(sendDownloadPDFRequest());
                        }}
                      >
                        <span className="button-text">{strings.corevaluesdeskkit}</span> <img src={require("../../assets/icons/download.png").default} alt="download icon"/>
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            {coreValuesMode?.mode === CVMode.Selected ? (
              <>
                {sortedFourCoreValues.map((cv) => (
                  <div key={cv.coreValueID} className="cv-item-card" onClick={() => handleCVSelection(cv)}>
                    <div>
                      <SeityCVImage cvId={cv.coreValueID} size={40} />
                      <p>{cv.coreValue}</p>
                    </div>
                    <img src={require("../../assets/icons/right-chevron.svg").default} />
                  </div>
                ))}
              </>
            ) : (
              <div className="core-values-cta">
                <div className="banner-image" />
                <div className="content">
                  <p className="title">{coreValuesMode?.title}</p>
                  <p className="subtitle">{coreValuesMode?.description}</p>
                  {coreValuesMode?.mode === CVMode.Discover ? (
                    <p className="time-commitment">{coreValuesMode?.subTitle}</p>
                  ) : (
                    <div className="current-core-values">
                      <p>{strings.currentCoreValues}</p>
                      <div className="cv-icons">
                        {sortedFourCoreValues.map((cv) => (
                          <SeityCVImage key={cv.coreValueID} cvId={cv.coreValueID} size={32} />
                        ))}
                      </div>
                    </div>
                  )}
                  <SeityButton
                    testId="discoverButton"
                    label={coreValuesMode?.buttonText}
                    onClick={() => history.push("/assessment/corevalues")}
                  />
                </div>
              </div>
            )}
          </div>

          {coreValueHistoryCount > 1? (
            <SeityButton className="p-0" onClick={() => history.push("/coreValues/history")} type="link" label={strings.viewCoreValueHistory} />
          ): null}

          <div className="resources">
            <p className="resources-title">{strings.resources}</p>
            <div className="resources-list">
              {resources.map((item, index) => (
                <div key={index} onClick={() => {
                  if (item.name === strings.downloads && !hasCompletedCoreValues) {
                    setShowCompleteAssessmentModal(true);
                  } else {
                    history.push(item.link);
                  }
                }}>
                  <img className="resources-image" src={item.image}/>
                  <p className="resources-subtitle">{item.name}</p>
                </div>
              ))}
            </div>
          </div>
          {selCVData && <SeityCVModal cvData={selCVData} onClose={() => setSelCVData(null)}/>}
          <CompleteAssessmentModal isOpen={showCompleteAssessmentModal} onClose={() => setShowCompleteAssessmentModal(false)} />
        </div>
      )}
    </>
  );
};