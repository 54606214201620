import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { AppContentByKeysResponse, AppContentResponse } from "./types/AppContent";

const SECTION = "/Content/";

export async function getAppContent(appContentTypeID: number, token: string): Promise<AppContentResponse> {
  const url = BASE_URL + SECTION + `AppContent?AppContentTypeID=${appContentTypeID}`;
  try {
    const appContentResponse = await axios.get<AppContentResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return appContentResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getAppContentByLanguage(appContentTypeID: number, languageID: number): Promise<AppContentResponse> {
  const url = BASE_URL + SECTION + `AppContentByLanguageID?AppContentTypeID=${appContentTypeID}&LanguageID=${languageID}`;
  try {
    const appContentResponse = await axios.get<AppContentResponse>(url, {});
    return appContentResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getAppContentByKeys(contentKeys: Array<string>, token: string): Promise<AppContentByKeysResponse> {
  const url = BASE_URL + SECTION + `AppContentByKeys?${contentKeys.join("&")}`;
  try {
    const res = await axios.get<AppContentByKeysResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return res.data;
  } catch (err) {
    throw err;
  }
}
