import {FunctionComponent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";

import {sendMessageCenterListRequest} from "../account/accountSlice";
import {sendIncentiveInfoRequest} from "../wellbeingCheckIn/checkInSlice";
import {getCharacterizationRequest} from "../myStory/myStorySlice";
import {getAffirmationRequest, getTodayRequest} from "./todaySlice";
import moment from "moment";
import {Redirect, useHistory} from "react-router-dom";

import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import CheckListItem from "./CheckListItem";

import {CLItem} from "./types";
import strings from "../../_core/strings/strings";
import SeityLoader from "../../_core/components/SeityLoader";

import { getAffirmationContent } from "./ChooseAffirmation/helpers";
import { getUserAppSettingsRequest } from "../../slices/userAppSettingsSlice";
import SeityOnDemandPlotlineIcon from "../../_core/components/SeityOnDemandPlotlineIcon";

import "./styles.scss";
import { CredentialVerification } from "./CredentialVerification";
import { CredentialsType } from "../account/maintenance/ModifyAuthentication";
import { sendGetPhoneCountryCodes } from "../register/registerSlice";
import { PlotlineInjectionPointID } from "../../api/plotlineTypes";

export const Today: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isApiLoading, setApiLoading] = useState(true);
  const [isCredentialVerificationModalVisible, setIsCredentialVerificationModalVisible] = useState(false);
  const [verificationCredentialType, setVerificationCredentialType] = useState<CredentialsType | null>(null);
  let apiTimer;
  const checkinRetakeImg = require("../../assets/icons/sync.svg").default;

  const CHECKLIST_ITEM_LIST: Array<CLItem> = [
    {
      type: "wellbeing",
      title: strings.wellbeingCheckInTitle,
      subheader: strings.measure,
      icon: "today-wellbeing.png"
    },
    {
      type: "corevalue",
      title: strings.discoverCoreValues,
      subheader: strings.understand,
      icon: "today-cv.png"
    },
    {
      type: "affirmation",
      title: strings.daillyAffirmation,
      subheader: strings.understand,
      icon: "affirmation.png"
    },
    {
      type: "intention",
      title: strings.dailyIntention,
      subheader: strings.improve,
      icon: "today-intentions.png"
    }
  ];

  useEffect(() => {
    const runApis = async () => {
      setApiLoading(true);
      dispatch(getUserAppSettingsRequest());
      dispatch(getCharacterizationRequest());
      await dispatch(getTodayRequest());
      dispatch(sendMessageCenterListRequest());
      dispatch(sendIncentiveInfoRequest());
      dispatch(getAffirmationRequest());
      setApiLoading(false);
    };

    runApis();
    dispatch(sendGetPhoneCountryCodes());

    if (!apiTimer) {
      apiTimer = setInterval(() => {
        runApis();
      }, today.refreshInMilliseconds ?? 1000 * 3600 * 4);
    }
    return () => {
      clearInterval(apiTimer);
    };
  }, []);

  const {isCheckInRedirected} = useSelector((state: RootState) => {
    return state.account;
  });

  const {
    today,
    isLoading,
    cvAffirmation
  } = useSelector((state: RootState) => {
    return state.today;
  });

  const {
    cellPhone,
    phoneCountryCodeID
  } = useSelector((state: RootState) => {
    return state.account.accountInfo;
  });
  const {
    userAppSettings,
    phoneVerificationShown
  } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const checkVerifications = () => {
    if (userAppSettings.smsVerificationNeeded && cellPhone !== null && cellPhone.trim() !== "") {
      if (!isCredentialVerificationModalVisible && !phoneVerificationShown) {
        setIsCredentialVerificationModalVisible(true);
        setVerificationCredentialType(CredentialsType.PhoneNumber);
      }
    }
  };

  const handleCheckInAgainClick = () => {
    if (!today.checkInDue && userAppSettings.multipleWellbeingCheckInEnabled) {
      history.push("/checkin/wellbeingCheckIn");
    }
  };

  useEffect(() => {
    checkVerifications();
  }, [today.checkInDue]);

  const {plotlineInjections} = useSelector((state: RootState) => {
    return state.plotline;
  });

  if (isLoading || isApiLoading) {
    return <SeityLoader/>;
  }

  if (today.forceNewPassword) {
    return <Redirect to="/temporary-password"/>;
  }

  if (today.checkInDue && !isCheckInRedirected) {
    return <Redirect to="/checkin/wellbeingCheckIn"/>;
  }

  if (today.eulaNeeded) {
    return <Redirect to="/eula"/>;
  }

  const cvComplete = today.coreValuesComplete;
  let hideCv = false;
  if (cvComplete) {
    hideCv = moment()
      .format("MM/DD/Y") !== today.coreValuesCompleteDate;
  }

  return (
    <div className="today-container flex flex-column">
      <h3>{today.firstName}</h3>
      <h5>{strings.letsMakeItToday}</h5>
      {today.dailyCheckinScore !== null && today.dailyCheckinScore >= 0 && (
        <div className={`wellbeing-checkin complete-box flex mt-xxl ${!today.checkInDue && userAppSettings.multipleWellbeingCheckInEnabled ? 'pointer' : ''}`} onClick={handleCheckInAgainClick}>
          <img className="mr-lg" src={require("../../assets/graphics/today/today-wellbeing.png").default}/>
          <div className="complete-content flex flex-column">
            <span>{strings.checkInTitle}</span>
            <h3 className="m-0">{userAppSettings.multipleWellbeingCheckInEnabled ? strings.yourWellbeingScore : strings.yourDailyScore}</h3>
            {!today.checkInDue && userAppSettings.multipleWellbeingCheckInEnabled &&
              <span className="check-in-again-btn">{strings.checkInAgainQ}</span>
            }
          </div>
          <div className="flex-center ml-auto">
            {today.dailyCheckinScore !== null && today.dailyCheckinScore >= 0 && (
              <div className="progressbar ml-auto">
                <CircularProgressbar
                  value={today.dailyCheckinScore}
                  text={`${today.dailyCheckinScore}%`}
                  styles={buildStyles({
                    textColor: "#313131",
                    trailColor: "#fde8b9",
                    pathColor: "#fcbd31"
                  })}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {cvAffirmation.selectedCoreValueAffirmationID > 0 && (
        <div className="complete-box flex mt-lg">
          <img className="mr-lg" src={require("../../assets/graphics/today/affirmation.png").default}/>
          <div className="complete-content flex flex-column">
            <span>{strings.daillyAffirmation}</span>
            <h5 className="m-0">{getAffirmationContent(cvAffirmation)}</h5>
          </div>
          <button
            className="ml-auto flex"
            onClick={() => {
              history.push("/today/affirmation");
            }}
          >
            <img src={require("../../assets/graphics/today/today-pen.png").default}/>
          </button>
        </div>
      )}
      {today.dailyIntention && (
        <div className="complete-box flex mt-lg">
          <img className="mr-lg" src={require("../../assets/graphics/today/today-world.png").default}/>
          <div className="complete-content flex flex-column">
            <span>{strings.dailyIntention}</span>
            <h3 className="m-0">{strings.todayIWill}</h3>
            <h5 className="m-0">{today.dailyIntention}</h5>
          </div>
        </div>
      )}
      <div className="checklist-wrap mt-xxl flex flex-column">
        <h1>{strings.wellnessChecklist}</h1>
        <h4>
              {strings.wellbeingchecklistsubheader}
              <SeityOnDemandPlotlineIcon injectionType={PlotlineInjectionPointID.WellbeingHeaderIcon}/>
        </h4>
        <div className="checklist-content">
          <div className="checklist-liner"/>
          {CHECKLIST_ITEM_LIST.map((item) => {
            let isCompleted = false;
            if (item.type === "wellbeing") {
              isCompleted = !today.checkInDue;
              if (!today.isCheckinActive) {
                return <></>;
              }
            } else if (item.type === "corevalue") {
              isCompleted = cvComplete;
              if (hideCv) {
                return <></>;
              }
            } else if (item.type === "intention") {
              if (today.intentionsEnabled) {
                isCompleted = today.dailyIntention !== null;
              } else {
                return <></>;
              }
            } else if (item.type === "affirmation") {
              if (today.affirmationsEnabled) {
                isCompleted = cvAffirmation.selectedCoreValueAffirmationID > 0;
              } else {
                return <></>;
              }
            }
            return (
              <CheckListItem
                key={item.title}
                clItem={item}
                isCompleted={isCompleted}
                isLocked={item.type === "affirmation" && !cvComplete ? true : false}
              />
            );
          })}
        </div>
      </div>
      <CredentialVerification
        isVisible={isCredentialVerificationModalVisible}
        setVisible={(visible) => {
          setIsCredentialVerificationModalVisible(visible);
        }}
        credentialType={verificationCredentialType}
        phoneNumber={cellPhone}
        countryCodeId={phoneCountryCodeID}
      />
    </div>
  );
};

export default Today;
