import { FunctionComponent } from "react";

import { Answers } from "./Answers";
import { SeityQuestionProps } from "./types";

import "./styles.scss";

const SeityQuestion: FunctionComponent<SeityQuestionProps> = (props) => {
  const { questionTypeId, answerLevel, id, handleSelect, answerSelections, questionText, needsToBeAnswered, containerStyle } = props;

  return (
    <div
      id={id.toString()}
      style={containerStyle}
      className={`question-container ${needsToBeAnswered && answerLevel === 0 && "question-not-answered"}`}
    >
      <div className="question-text">{questionText}</div>
      <Answers
        questionTypeId={questionTypeId}
        answerLevel={answerLevel}
        questionId={id}
        onChange={handleSelect}
        answerSelections={answerSelections}
      />
    </div>
  );
};

export default SeityQuestion;
