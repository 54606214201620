import axios from "axios";
import {
  CoreValueCategorySelectionUpdate,
  CoreValueCategorySelectionResponse,
  CoreValueSelectionResponse,
  CoreValueSelectionUpdate,
  FourCoreValuesResponse,
  FourCoreValuesSortOrderUpdate
} from "./types/coreValueTypes";
import {
  OutcomeScoresResponse,
  OutcomeSelectionResponse,
  OutcomeSelectionUpdate,
  PracticeSelectionResponse,
  PracticeSelectionUpdate,
  PracticeScoresResponse
} from "./types/questionTypes";
import { SeityResponse } from "../baseTypes";
import { BASE_URL } from "../apiConstants";
import { CoreValuesIncentiveResponse } from "./types/coreValuesIncentive";

export async function getCoreValueCategorySelection(token: string, level: number, isRetake: boolean): Promise<CoreValueCategorySelectionResponse> {
  const url = BASE_URL + `/Assessment/CoreValueCategorySelection?Level=${level.toString()}&isRetake=${isRetake}`;
  try {
    const coreValueCateogryResponse = await axios.get<CoreValueCategorySelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function coreValueCategorySelectionUpdateRequest(
  token: string,
  coreValueCategorySelectionUpdate: CoreValueCategorySelectionUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/CoreValueCategorySelectionUpdate";
  try {
    const coreValueCategorySelectionUpdateResponse = await axios.post<SeityResponse>(url, coreValueCategorySelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return coreValueCategorySelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getCoreValueSelection(token: string, level: number): Promise<CoreValueSelectionResponse> {
  const url = BASE_URL + `/Assessment/CoreValueSelection?Level=${level.toString()}`;
  try {
    const coreValueCateogryResponse = await axios.get<CoreValueSelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function coreValueSelectionUpdateRequest(
  token: string,
  coreValueSelectionUpdate: CoreValueSelectionUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/CoreValueSelectionUpdate";
  try {
    const coreValueCategorySelectionUpdateResponse = await axios.post<SeityResponse>(url, coreValueSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return coreValueCategorySelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getFourCoreValues(token: string): Promise<FourCoreValuesResponse> {
  const url = BASE_URL + "/Account/FourCoreValues";
  try {
    const coreValueCateogryResponse = await axios.get<FourCoreValuesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getSortedFourCoreValues(token: string): Promise<FourCoreValuesResponse> {
  const url = BASE_URL + "/Account/SortedFourCoreValues";
  try {
    const coreValueCateogryResponse = await axios.get<FourCoreValuesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeSelection(
  token: string,
  outcomeCategoryID: number,
  sortOrder: number
): Promise<PracticeSelectionResponse> {
  const url = BASE_URL + "/Assessment/PracticeSelection?OutcomeCategoryID=" + outcomeCategoryID.toString() + "&SortOrder=" + sortOrder;
  try {
    const practiceSelectionResponse = await axios.get<PracticeSelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return practiceSelectionResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function practiceSelectionUpdateRequest(
  token: string,
  practiceSelectionUpdate: PracticeSelectionUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/PracticeSelectionUpdate";
  try {
    const practiceSelectionUpdateResponse = await axios.post<SeityResponse>(url, practiceSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return practiceSelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeScores(token: string): Promise<PracticeScoresResponse> {
  const url = BASE_URL + "/Account/PracticeScores";
  try {
    const coreValueCateogryResponse = await axios.get<PracticeScoresResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return coreValueCateogryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getOutcomeSelection(token: string, outcomeCategoryID: number): Promise<OutcomeSelectionResponse> {
  const url = BASE_URL + "/Assessment/OutcomeSelection?OutcomeCategoryID=" + outcomeCategoryID.toString();
  try {
    const outcomeSelectionResponse = await axios.get<OutcomeSelectionResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return outcomeSelectionResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getOutcomeScores(token: string): Promise<OutcomeScoresResponse> {
  const url = BASE_URL + "/Account/OutcomeScores";
  try {
    const outcomeSelectionResponse = await axios.get<OutcomeScoresResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return outcomeSelectionResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function outcomeSelectionUpdateRequest(token: string, outcomeSelectionUpdate: OutcomeSelectionUpdate): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/OutcomeSelectionUpdate";
  try {
    const outcomeSelectionUpdateResponse = await axios.post<SeityResponse>(url, outcomeSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return outcomeSelectionUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function fourCoreValuesSortOrderUpdateRequest(
  token: string,
  fourCoreValuesSortOrderUpdate: FourCoreValuesSortOrderUpdate
): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/FourCoreValuesSortOrderUpdate";
  try {
    const fourCoreValuesSortOrderUpdateResponse = await axios.post<SeityResponse>(url, fourCoreValuesSortOrderUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return fourCoreValuesSortOrderUpdateResponse.data;
  } catch (err) {
    throw err;
  }
}

// add to use fetch because axios was always 401
export async function practiceMarkCompleteRequest(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/PracticeMarkComplete";
  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + token }
  };
  return await fetch(url, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.warn(error));
}

export async function outcomeMarkCompleteRequest(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/OutcomeMarkComplete";
  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + token }
  };
  return await fetch(url, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.warn(error));
}

export async function resetCoreValues(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/ResetCoreValueSelections";
  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + token }
  };
  return await fetch(url, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.warn(error));
}

export async function coreValueMarkComplete(token: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Assessment/CoreValueMarkComplete";
  try {
    const response = await axios.post<SeityResponse>(url, null, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getCVIncentive(token): Promise<CoreValuesIncentiveResponse> {
  const url = BASE_URL + "/IncentiveSchedule/CoreValuesIncentive";
  try {
    const cvIncentiveResponse = await axios.get<CoreValuesIncentiveResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return cvIncentiveResponse.data;
  } catch (err) {
    throw err;
  }
}
