/* eslint-disable @typescript-eslint/no-unused-vars */
import { FunctionComponent, ReactElement } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";

import strings from "../../../_core/strings/strings";
import { ListItemProps } from "./types";
import { getColorData, getDragonImg } from "./helpers";

import "./styles.scss";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";

const ListItem: FunctionComponent<ListItemProps> = (props): ReactElement => {
  const { intention } = props;
  const history = useHistory();

  const { isCVOnly } = useSelector((state: RootState) => {
    return state.userAppSettings.userAppSettings;
  });

  const createDate = moment("08-30-2022", "MM-DD-YYYY");
  const isToday = moment().diff(createDate, "days") === 0;

  return (
    <li key={`${intention.dailyIntentionID}-listitem`} style={{ backgroundColor: getColorData(intention.outcomeCategoryID).bg }}>
      <div className="listitem-top mb-md">
        <img
          className="mr-lg"
          src={RequireSeityImage(`outcomecategoryid-${intention.outcomeCategoryID}`).default}
          alt="category img"
          width={40}
          height={40}
        />
        <span style={{ color: getColorData(intention.outcomeCategoryID).text }}>{intention.outcomeCategory}</span>
      </div>
      <div className="listitem-middle flex-col flex-center w-100 p-sm">
        <img className="background-img" src={intention.imageFileName + "v.png"} alt="background img" />
        <div
          className="listitem-overlay flex-col-center w-100 h-100 flex"
          onClick={() => {
            history.push({ pathname: "/intentionSelected", state: { intention } });
          }}
        >
          <p>
            {strings.todayIWill}
            <br />
            <br />
            {intention.text}
          </p>
        </div>
      </div>
      <div className={`listitem-bottom flex-ai-c mt-md ${isCVOnly ? "flex-center" : ""}`}>
        <img
          className="mr-md"
          src={RequireSeityImage(intention.outcomeSubcategoryImageName).default}
          alt="subcategory img"
          width={32}
          height={32}
        />
        <span className={`${!isCVOnly ? "mr-auto" : ""}`} style={{ color: getColorData(intention.outcomeCategoryID).text }}>
          {intention.outcomeSubcategory}
        </span>
        {!isCVOnly && intention.dragonType && intention.dragonType.length > 0 && (
          <img src={getDragonImg(intention.dragonType)} alt="dragon" width={32} height={32} />
        )}
      </div>
    </li>
  );
};

export default ListItem;
