import { FunctionComponent, ReactElement } from "react";
import classnames from "classnames";
import { alphabet } from "./data";
import "./styles.scss";
import { AlphabeticalSeekBarProps } from "./types";

export const AlphabeticalSeekBar: FunctionComponent<AlphabeticalSeekBarProps> = ({
  activeChar,
  setActiveChar,
  groupedValues,
  onCharClick,
  containerId,
  categorySuffix
  
}): ReactElement => {
  const scrollToChar = (char: string) => {
    const getMeTo = document.getElementById(`${char}-${categorySuffix}`);   
    const container =  document.getElementById(`${containerId}`);
    if (activeChar === char) {
      setActiveChar("");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    setActiveChar(char);
    if (getMeTo) {
      const elementPosition = getMeTo.offsetTop;
      if(container) {
        container?.scrollTo({
          top: elementPosition,
          behavior: "smooth"
        })
      } else {
        window.scrollTo({
        top: elementPosition - 220,
        behavior: "smooth"
      });
      } 
    }
  };

  return (
    <div className="letters-nav-bar">
      {alphabet.map((char, index) => {
        return (
          <h2
            onClick={onCharClick ? () => onCharClick(char) : () => scrollToChar(char)}
            key={`char-${index}`}
            className={classnames({
              "disabled-char": !groupedValues[char]?.length,
              "active-char": activeChar === char
            })}
          >
            {char}
          </h2>
        );
      })}
    </div>
  );
};

export default AlphabeticalSeekBarProps;
