import { configureStore, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./rootReducer";

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppThunkReturn<R> = ThunkAction<R, RootState, unknown, Action<string>>;

const store = configureStore({
  reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;

export default store;
