/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-case */
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../app/rootReducer";
import { sendPracticeScoresRequest } from "../../practiceSelectionsSlice";
import { sendOutcomeScoresRequest } from "../../outcomeSelectionsSlice";
import SeityProgressBar from "../../../../_core/components/SeityProgressBar";

import { AssessmentCategoryScoreCardProps } from "./types";

import strings from "../../../../_core/strings/strings";

import "./styles.scss";

const AssessmentCategoryScoreCard: FunctionComponent<AssessmentCategoryScoreCardProps> = (props) => {
  const dispatch = useDispatch();

  const { worldScore, bodyScore, mindScore, spiritScore, worldCategory, bodyCategory, mindCategory, spiritCategory } = useSelector(
    (state: RootState) => {
      return state.assessment.practiceSelections;
    }
  );

  const { wholeHealthCategory, wholeHealthScore } = useSelector((state: RootState) => {
    return state.assessment.outcomes;
  });

  useEffect(() => {
    dispatch(sendPracticeScoresRequest(props.outcomeCategory));
    dispatch(sendOutcomeScoresRequest());
  }, []);

  const { background, image, fontColor, heading, score } = useMemo(() => {
    let background: string = "";
    let image: string = require("../../../../assets/graphics/corevalue01.png");
    let fontColor: string = "";
    let heading: string | any = "";
    let score: number = 0;

    switch (props.outcomeCategory) {
      case 1:
        background = "#2f696c";
        image = require("../../../../assets/graphics/world.svg").default;
        fontColor = "#fff8eb";
        heading = `${strings.world} ${strings.score}`;
        score = worldScore;
        break;
      case 2:
        background = "#56a3a6";
        image = require("../../../../assets/graphics/body.svg").default;
        fontColor = "#fff8eb";
        heading = `${strings.body} ${strings.score}`;
        score = bodyScore;
        break;
      case 3:
        background = "#9ac8ca";
        image = require("../../../../assets/graphics/mind.svg").default;
        fontColor = "#314947";
        heading = `${strings.mind} ${strings.score}`;
        score = mindScore;
        break;
      case 4:
        background = "#bbdadb";
        image = require("../../../../assets/graphics/spirit.svg").default;
        fontColor = "#314947";
        heading = `${strings.spirit} ${strings.score}`;
        score = spiritScore;
        break;
      case 5:
        background = "#f08080";
        image = require("../../../../assets/graphics/wholehealth.svg").default;
        fontColor = "#fff";
        heading = `${strings.outcomesIntroHeader} ${strings.score}`;
        score = wholeHealthScore;
        break;
    }
    return {
      background,
      image,
      fontColor,
      heading,
      score
    };
  }, [
    bodyCategory,
    bodyScore,
    mindCategory,
    mindScore,
    props.outcomeCategory,
    spiritCategory,
    spiritScore,
    wholeHealthCategory,
    wholeHealthScore,
    worldCategory,
    worldScore
  ]);

  return (
    <div className="pcc-wrapper-page">
      <h2>{props.mode === "outcomes" ? strings.yourLifeStyleOutcomesResults : strings.yourLifestylePracticeResults}</h2>

      <div className="pcc-wrapper" style={{ background }}>
        <img src={image} alt="category icon" />
        <h3 style={{ color: fontColor }}>{heading}</h3>
        <h1 style={{ color: fontColor }}>{score}%</h1>
        <SeityProgressBar
          progress={score}
          trackColor="rgba(49, 73, 71, 1)"
          color="rgba(255, 248, 235, 1)"
          height={16}
          containerStyle="pcc-progress-bar"
        />
      </div>
    </div>
  );
};

export default AssessmentCategoryScoreCard;
