import { createSlice } from "@reduxjs/toolkit";
import { AccountItemType, CvDirectoryIndexType, CvDirectoryPayloadRequest, CvDirectoryResponse } from "./types";
import apiClientWithAuth from "../../api/apiClient";

export interface DirectoryState {
  readonly isLoading: boolean;
  readonly directoryError: string | null;
  readonly accounts: Array<AccountItemType> | [];
  readonly coreValueDirectoryIndex: CvDirectoryIndexType | null;
  readonly totalResultCount: number;
  readonly totalNumResults: number;
}

const setInitialState = {
  isLoading: false,
  directoryError: null,
  accounts: [],
  coreValueDirectoryIndex: null,
  totalResultCount: 0,
  totalNumResults: 0
} as DirectoryState;

const directorySlice = createSlice({
  name: "directory",
  initialState: setInitialState,
  reducers: {
    setDirectoryResponse(state, action) {
      state.accounts = action.payload.accounts;
      state.coreValueDirectoryIndex = action.payload.coreValueDirectoryIndex;
      state.totalResultCount = action.payload.totalResultCount;
      state.totalNumResults = action.payload.totalNumResults;
      state.isLoading = false;
      state.directoryError = null;
    },
    // If a value is not passed, it's assumed
    // to be true
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setDirectoryError(state, action) {
      state.directoryError = action.payload.directoryError;
      state.isLoading = false;
    },
    resetDirectorySlice: () => setInitialState,
    clearDirectoryError(state) {
      state.directoryError = null;
    }
  }
});

export const { setIsLoading, setDirectoryError, setDirectoryResponse, resetDirectorySlice, clearDirectoryError } = directorySlice.actions;
export default directorySlice.reducer;

export const sendCoreValueDirectoryRequest =
  (cvDirectoryRequestPayload?: CvDirectoryPayloadRequest, callback?: () => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({ isLoading: true }));
      const res = await apiClientWithAuth.post<CvDirectoryResponse>("/CoreValueDirectoryV2", { ...cvDirectoryRequestPayload });
      dispatch(setIsLoading({ isLoading: false }));
      if (res.data.success) {
        dispatch(
          setDirectoryResponse({
            accounts: res.data.data?.accounts,
            coreValueDirectoryIndex: res.data.data?.coreValueDirectoryIndex,
            totalResultCount: res.data.data?.totalResultCount,
            totalNumResults: res.data.data?.totalNumResults
          })
        );
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };

function handleError(err: any, dispatch: any) {
  const errString = err.toString();
  const editiedErrString = errString.replace("Error:", "");
  dispatch(setIsLoading({ isLoading: false }));
  dispatch(setDirectoryError({ accountError: editiedErrString }));
}
