import { useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import "./styles.scss";

const SurveyQuestionProgress = () => {
  const { answeredSurveySelections, questionAndAnswers } = useSelector((state: RootState) => {
    return state.survey;
  });

  const progress = answeredSurveySelections.length / questionAndAnswers.length;
  return (
    <div className="survey-progressbar">
      <div className="survey-progressbar__backbar">
        <div className="survey-progressbar__progression" style={{ width: `${Math.round(progress * 100)}%` }} />
      </div>
      <span>
        {" "}
        {answeredSurveySelections.length} / {questionAndAnswers.length}
      </span>
    </div>
  );
};

export default SurveyQuestionProgress;
