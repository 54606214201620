import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  LearnSeityCoreValuesResponse,
  LearnSeityGuideByCategoryIdResponse,
  TeachSeityDetailResponse,
  TeachSeityResponse,
  WellBeingCheckInCategoriesResponse
} from "./referenceGuideTypes";

const SECTION = "/ReferenceGuide/";

export async function getLearnSeityGuideByCategoryId(
  outcomeCategoryID: string,
  token: string
): Promise<LearnSeityGuideByCategoryIdResponse> {
  const url = BASE_URL + SECTION + "OutcomeSubcategories";
  try {
    const postResponse = await axios.get<LearnSeityGuideByCategoryIdResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: {
        outcomeCategoryID
      }
    });
    return postResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getWellBeingCheckInCategories(token: string): Promise<WellBeingCheckInCategoriesResponse> {
  const url = BASE_URL + SECTION + "WellBeingCheckInCategories";
  try {
    const postResponse = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    const res = postResponse.data.data.map((p) => {
      return {
        subcategory: p.category,
        description: p.description
      };
    });
    return { ...postResponse.data, data: res };
  } catch (err) {
    throw err;
  }
}

export async function getSeityCoreValues(token: string): Promise<LearnSeityCoreValuesResponse> {
  const url = BASE_URL + SECTION + "CoreValues";
  try {
    const postResponse = await axios.get<LearnSeityCoreValuesResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return postResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getGoodLifeSeries(token: string): Promise<LearnSeityCoreValuesResponse> {
  const url = BASE_URL + SECTION + "GoodLifeSeries";
  try {
    const postResponse = await axios.get<LearnSeityCoreValuesResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return postResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getTeachSeityAssets(token: string): Promise<TeachSeityResponse> {
  const url = BASE_URL + "/Assets/" + "TeachSeity";
  try {
    const response = await axios.get<TeachSeityResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getTeachSeityAssetDetail(token: string, assetId: number): Promise<TeachSeityDetailResponse> {
  const url = BASE_URL + "/Assets/" + assetId;
  try {
    const response = await axios.get<TeachSeityDetailResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getSeityAssets(token: string, assetCategoryID: number, assetTypeID?: number): Promise<TeachSeityResponse> {
  const url = BASE_URL + "/Assets";
  try {
    const response = await axios.get<TeachSeityResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: {
        assetCategoryID,
        assetTypeID
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
