/* eslint-disable radix */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAccountLists } from "../../../../hooks/AccountList";
import {
  sendGetAccountListsRequest,
  sendGetAccountInfoRequest,
  clearAccountError,
  sendAccountMaintenanceUpdateRequest
} from "../../../account/accountSlice";
import { setCurrentInfo } from "../../registerSlice";
import { RootState } from "../../../../app/rootReducer";
import RegisterWrapper from "../../RegisterWrapper";
import { SeityFormSelectBox } from "../../../../_core/components/SeitySelectBox/SeityFormSelectBox";
import { SeityFormTextInput } from "../../../../_core/components/SeityTextInput/SeityFormTextInput";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";
import SeityButton from "../../../../_core/components/SeityButton";
import strings from "../../../../_core/strings/strings";
import "./style.scss";

export const RegisterSeven = () => {
  const dispatch = useDispatch();

  const { isAccountUpdateSuccessful, accountError } = useSelector((state: RootState) => {
    return state.account;
  });

  const { current } = useSelector((state: RootState) => {
    return state.register;
  });

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [genderID, setGenderID] = useState(0);
  const [maritalStatusID, setMaritalStatusID] = useState(0);
  const [occupationID, setOccupationID] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [validationError, setValidationError] = useState("");

  const { occupationList, maritalList, genderList } = useAccountLists();

  useEffect(() => {
    clearAccountError();
    dispatch(sendGetAccountListsRequest(false, false, false, true, true, true));
    dispatch(sendGetAccountInfoRequest());
  }, []);

  const onZipCodeChanged = (zipCode: string) => {
    setZipCode(zipCode);
  };

  const onHandleChange = (setStatusHandler: (statusChange: number) => void, statusChange: number) => {
    setStatusHandler(statusChange);
  };

  const isFormValid = () => {
    if (genderID <= 0 || maritalStatusID <= 0 || occupationID <= 0) {
      setIsFormInvalid(true);
      return false;
    }
    setIsFormInvalid(false);
    return true;
  };

  const finishPressed = () => {
    if (isFormValid()) {
      dispatch(
        setCurrentInfo({
          zipCode,
          genderID,
          maritalStatusID,
          occupationID
        })
      );
    } else {
      setValidationError(strings.allFieldsErrorMessage);
    }
    dispatch(
      sendAccountMaintenanceUpdateRequest(
        {
          firstName: current.firstName,
          lastName: current.lastName,
          eMailAddress: null,
          accountStatusID: Number(current.accountStatusId),
          dateOfBirth: current.dateOfBirth, // need to make sure this is formatted as MM/dd/YYYY
          statusID: Number(current.statusId),
          educationID: Number(current.educationID),
          occupationID: Number(occupationID),
          genderID: Number(genderID),
          maritalStatusID: Number(maritalStatusID),
          zipcode: zipCode,
          cellPhone: current.cellPhone,
          departmentID: Number(current.departmentID) ?? null,
          jobPositionID: Number(current.jobPositionID) ?? null,
          languageID: Number(current.languageID) ?? null
        },
        false
      )
    );
  };

  if (isAccountUpdateSuccessful) {
    return <Redirect to="/" />;
  }

  return (
    <RegisterWrapper>
      <div className="register-seven-container">
        <div>
          <h1>{strings.setUp}</h1>
          <SeityIndicator currentStep={2} numberOfSteps={3} />
          <div>
            <h5>{strings.allFields}</h5>
            <h6>{strings.administratorAccess}</h6>
          </div>
          <form className="textbox-form">
            <SeityFormSelectBox
              isInvalid={isFormInvalid && occupationID <= 0}
              inputLabelText={strings.occupationLabel}
              onChange={(e) => {
                return onHandleChange(setOccupationID, parseInt(e.target.value));
              }}
            >
              <ul>
                {occupationList.map((item) => {
                  return (
                    <li key={item.listID} value={item.listID}>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
            <SeityFormSelectBox
              isInvalid={isFormInvalid && genderID <= 0}
              controlId="gender"
              inputLabelText={strings.genderLabel}
              onChange={(e) => {
                return onHandleChange(setGenderID, parseInt(e.target.value));
              }}
            >
              <ul>
                {genderList.map((item) => {
                  return (
                    <li key={item.listID} value={item.listID}>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
            <SeityFormSelectBox
              isInvalid={isFormInvalid && maritalStatusID <= 0}
              inputLabelText={strings.maritalStatusLabel}
              onChange={(e) => {
                return onHandleChange(setMaritalStatusID, parseInt(e.target.value));
              }}
            >
              <ul>
                {maritalList.map((item) => {
                  return (
                    <li key={item.listID} value={item.listID}>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
            <SeityFormTextInput
              inputLabelText={strings.zipCodeLabel}
              controlId="zipcode"
              onChange={(e) => {
                return onZipCodeChanged(e.target.value);
              }}
              value={zipCode}
            />
          </form>
          <div className="register-seven-container-error">
            {validationError}
            {accountError}
          </div>
        </div>

        <SeityButton
          onClick={finishPressed}
          type="next"
          disabled={false}
          label={strings.nextButtonText}
          className="register-seven-next-button"
        />
      </div>
    </RegisterWrapper>
  );
};
