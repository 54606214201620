import { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import apiClientWithAuth from "../../api/apiClient";
import { useDispatch } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { AppContentResponse } from "../../api/policy/types/AppContent";
import { getPersonalList } from "./slice";
import { getFSGroupList } from "../familySharing/familySharingSlice";

export const useCVDirectoryData = () => {
  const { userAppSettings } = useSelector((state: RootState) => state.userAppSettings);
  const [modalTextObject, setModalTextObject] = useState<{ [key: string]: string }>({});
  const [isfetchLoading, setFetchLoading] = useState(true);
  const { fsGroupList, isLoading: fsGroupLoading } = useSelector((state: RootState) => state.familySharingSlice);
  const { personalList, isLoading: isPLLoading } = useSelector((state: RootState) => state.personalListSlice);
  const dispatch = useDispatch();
  const { hasFamilySharingFullAccess, personalListEnabled, familySharingEnabled } = userAppSettings;

  // Failsafe in case group organizers are deleted but the group still exists
  // const familyGroupsWithoutNullOrganizer = hasFamilySharingFullAccess
  //   ? fsGroupList.filter((familyGroup) => familyGroup.organizer !== null)
  //   : fsGroupList.filter((familyGroup) => familyGroup.organizer !== null && !familyGroup.isOrganizer);

  const isLoading = fsGroupLoading || isPLLoading || isfetchLoading;

  useLayoutEffect(() => {
    const fetchModalText = async () => {
      setFetchLoading(true);
      const res = await apiClientWithAuth.get<AppContentResponse>("/Content/AppContent?AppContentTypeID=20");

      if (res.data.success) {
        const modalText = {};
        res.data.data?.appContentLines?.forEach((element) => {
          modalText[element.appContentTextKey] = element.contentText;
        });
        setModalTextObject(modalText);
      }

      setFetchLoading(false);
    };
    if (fsGroupList.length === 0 && familySharingEnabled) {
      dispatch(getFSGroupList());
    }
    if (personalList.length === 0 && personalListEnabled) {
      dispatch(getPersonalList());
    }
    fetchModalText();
  }, []);

  return {
    hasFamilySharingFullAccess,
    isLoading,
    modalTextObject,
    personalList
  };
};
