import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { SecurityAndProtectionItem } from "./SecurityAndProtectionItem";
import { SeityBackButton } from "../../_core/components/SeityBackButton";

import strings from "../../_core/strings/strings";
import "./styles.scss";

const accountSecurity = require("../../assets/web-icons/account-security.png").default;

export const SecurityAndProtection: FunctionComponent = (): ReactElement => {
  const history = useHistory();

  const goToPolicyPage = (policyPage: string) => {
    history.push(`/policy/${policyPage}`);
  };

  const SecurityAndProtectionItemsList = [
    { id: 1, title: strings.privacyPolicy, navigationPath: "privacy" },
    { id: 2, title: strings.coppaPolicy, navigationPath: "coppa" },
    { id: 3, title: strings.californiaPolicy, navigationPath: "california" },
    { id: 4, title: strings.userPolicy, navigationPath: "userAgreement" }
  ];

  return (
    <div className="sap-wrapper">
      <SeityBackButton wrapperClassName="sd-back-button" />

      <div className="sap-title">{strings.securityAndProtection}</div>
      <div className="sap">
        <div className="sap-header">
          <h1>{strings.howSeityProtectsTitle}</h1>
          <img src={accountSecurity} alt="account security icon" width={160} height={160} />
          <h2>{strings.howSeityProtectsPageDescription}</h2>
        </div>
        <div className="sap-content">
          {SecurityAndProtectionItemsList.map(({ id, title, navigationPath }) => {
            return (
              <SecurityAndProtectionItem
                key={id}
                title={title}
                onClick={() => {
                  return goToPolicyPage(navigationPath);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
