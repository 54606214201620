import { FunctionComponent, ReactElement, useState } from "react";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";
import { postFSGroup } from "../familySharingSlice";
import { useFSHtmlContent } from "../hooks";
import { postFSDisclosureAccept } from "../familySharingSlice";
import { FSHtmlModal } from "../FSHtmlModal";

export type FSFamilyNameProps = {
  onClose: () => void;
  onContinue: () => void;
};

const FSFamilyName: FunctionComponent<FSFamilyNameProps> = ({
  onClose,
  onContinue
}): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { familySharingDisclosureAccepted } = useSelector((state: RootState) => state.userAppSettings.userAppSettings);

  const { disclosure, isLoading: isDisclosureLoading } = useFSHtmlContent();
  const [disclosureStatus, setDisclosureStatus] = useState({
    showModal: false,
    checked: familySharingDisclosureAccepted,
  });

  const { isLoading } = useSelector((state: RootState) => {
    return state.familySharingSlice;
  });

  const [name, setName] = useState("");

  return (
    
    <div className="fsFamilyName">
       {disclosureStatus.showModal && !isDisclosureLoading && (
        <FSHtmlModal
          content={disclosure}
          onClose={() => {
            setDisclosureStatus({ ...disclosureStatus, showModal: false });
          }}
          onContinue={() => {
            dispatch(postFSDisclosureAccept());
            setDisclosureStatus({ checked: true, showModal: false });
            dispatch(postFSGroup(name, (groupId) => {
              history.push({
                pathname: `familyGroups/${groupId}`,
                state: {
                  showInvite: true,
                  isOrganizer: true
                }
              });
            }));
          }}
        />
      )}
      {isLoading && <SeityLoader />}
      <button
        className="close-button"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <img src={require("../../../assets/graphics/journals/close.png").default} alt="close button" />
      </button>
      <img src={require("../../../assets/graphics/familysharing/icon-familysharing.png").default} alt="family sharing" width={72} />
      <h1>{CoreStrings.enterFamilyName}</h1>
      <input placeholder={CoreStrings.familyName} value={name} onChange={(e) => {
        setName(e.target.value);
      }} />
      
      <SeityButton className="fsWelcome-continue" label={CoreStrings.continue} onClick={() => {
       if (!familySharingDisclosureAccepted) {
        setDisclosureStatus({showModal: true, checked: false});
       } else {
        dispatch(postFSGroup(name, (groupId) => {
          history.push({
            pathname: `familyGroups/${groupId}`,
            state: {
              showInvite: true,
              isOrganizer: true
            }
          });
        }));
       }
      }} disabled={name.length < 1} />
    </div>
  );
};

export default FSFamilyName;
