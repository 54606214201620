import { FunctionComponent, MouseEventHandler } from "react";
import { AssessmentItem } from "./AssessmentItem";

import strings from "../../../../_core/strings/strings";
import "../styles.scss";

const coreValues = require("../../../../assets/graphics/coreValues.svg").default;

export interface AssessmentCoreValuesProps {
  navigationFunction: MouseEventHandler;
}

export const AssessmentCoreValues: FunctionComponent<AssessmentCoreValuesProps> = ({ navigationFunction }) => {
  return (
    <AssessmentItem
      navigationFunction={navigationFunction}
      assessmentScore="-1"
      assessmentImage={coreValues}
      assessmentLabel={strings.coreValues}
      assessmentTextEng="Core Values"
    />
  );
};
