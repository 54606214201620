/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, FunctionComponent, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  sendPracticeMarkCompleteRequestRequest,
  sendPracticeQuestionsRequest,
  sendPracticeSelectionUpdateRequest
} from "../practiceSelectionsSlice";
import { pushToAnsweredPracticeSelections, sendProgressDenominatorRequest } from "../assessmentProgressSlice";
import { RootState } from "../../../app/rootReducer";
import useAssessPracticeSteps, { AssessPracticesSteps, OutcomeCategory } from "./data";

import AssessWrap from "../AssessWrap";
import AssessmentCategoryScoreCard from "../base/AssessmentCategoryScoreCard";
import AssessmentQuestionProgress from "../base/AssessmentQuestionProgress";
import { AssessmentSectionIntro } from "../base/AssessmentSectionIntro";
import SeityLoader from "../../../_core/components/SeityLoader";
import SeityButton from "../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../_core/components/SeityHyperText";
import SeityQuestion from "../../../_core/components/SeityQuestion/index";
import YourPracticeScores from "./YourPracticeScores";
import SeityAlert from "../../../_core/components/SeityAlert";

import { AssessPracticesProps } from "./types";
import strings from "../../../_core/strings/strings";

import "./styles.scss";
import { PrivacyPolicyModal } from "../base/PrivacyPolicyModal";

export const AssessPractices: FunctionComponent<AssessPracticesProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ASSESS_PRACTICES_STEPS = useAssessPracticeSteps();
  const [stepId, setStepId] = useState(-1);
  const [showFinishLater, setShowFinishLater] = useState(false);
  const [needsToBeAnswered, setNeedsToBeAnswered] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showUnansweredQuestionsModal, setShowUnansweredQuestionsModal] = useState(false);
  const [practicesStep, setPracticesStep] = useState<AssessPracticesSteps>(ASSESS_PRACTICES_STEPS[0]);

  const { isLoading, questions } = useSelector((state: RootState) => {
    return state.assessment.practiceSelections;
  });
  const { denominator, answeredPracticeSelections, answeredOutcomeSelections } = useSelector((state: RootState) => {
    return state.assessment.assessmentProgress;
  });

  const getCurrentPracticeStep = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _practiceStep = [...ASSESS_PRACTICES_STEPS].find((c) => {
      return c.id == stepId;
    });
    return _practiceStep;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _practiceStep = getCurrentPracticeStep();
    if (_practiceStep) {
      setPracticesStep(_practiceStep);
    }
  }, [stepId]);

  useEffect(() => {
    if (practicesStep && practicesStep!.selection !== null && practicesStep!.selection?.subCateID > 0) {
      dispatch(sendPracticeQuestionsRequest(practicesStep?.selection?.cateID!, practicesStep?.selection?.subCateID!));
      dispatch(sendProgressDenominatorRequest());
      setNeedsToBeAnswered(false);
    }
  }, [practicesStep]);

  const assessHeader = document.getElementsByClassName("aw-header")[0];
  const seityHeader = document.getElementsByClassName("seity-header")[0];
  const allSelected = !questions.some((i) => {
    return i.answerLevel === 0;
  });
  const answeredQuestions = questions.filter((q) => {
    return q.answerLevel !== 0;
  });

  const practicesStart = (cateId: OutcomeCategory) => {
    let imageSrc = require("../../../assets/graphics/world.svg").default;
    let title = strings.exploreYourWorld;
    switch (cateId) {
      case OutcomeCategory.World:
        imageSrc = require("../../../assets/graphics/world.svg").default;
        title = strings.exploreYourWorld;
        break;
      case OutcomeCategory.Body:
        imageSrc = require("../../../assets/graphics/body.svg").default;
        title = strings.exploreYourBody;
        break;
      case OutcomeCategory.Mind:
        imageSrc = require("../../../assets/graphics/mind.svg").default;
        title = strings.exploreYourMind;
        break;
      case OutcomeCategory.Spirit:
        imageSrc = require("../../../assets/graphics/spirit.svg").default;
        title = strings.exploreYourSpirit;
        break;
      default:
        break;
    }

    return (
      <div className="practice-start-intro">
        <img src={imageSrc} className="practice-image-intro" />
        <p className="practices-explore-text text-center mt-3">{title}</p>
      </div>
    );
  };

  const practicesHeader = (
    practicesStep: AssessPracticesSteps,
    onPressOption,
    denominator,
    answeredOutcomeSelections,
    answeredPracticeSelections
  ) => {
    if (practicesStep.selection && practicesStep.selection?.subCateID > 0) {
      return (
        <div className="assess-practices-header-wrapper">
          <div className="assess-practices-header">
            <div className="assess-practices-header-button">
              <SeityHyperText
                onClick={() => {
                  return onPressOption({ action: { type: "finishLater", value: "0" } });
                }}
                title={strings.finishLater}
              />
            </div>
            <AssessmentQuestionProgress
              denominator={denominator}
              answeredOutcomeSelections={answeredOutcomeSelections}
              answeredPracticeSelections={answeredPracticeSelections}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const practicesFooter = (practicesStep: AssessPracticesSteps, onPressOption) => {
    return (
      <div className="assess-practices-footer">
        <div className={`assess-practices-footer__options ${practicesStep?.buttonsDirectionRow && "assess-practices-footer__options-row"}`}>
          {practicesStep.options.map((option, index) => {
            return (
              <SeityButton
                key={index}
                className={`practices-action-button ${option?.buttonType === "discard" && "practices-assess-green-title"}`}
                onClick={() => {
                  return onPressOption(option);
                }}
                label={option.value}
                type={option?.buttonType}
              />
            );
          })}
        </div>
      </div>
    );
  };

  answeredQuestions.forEach((q) => {
    return dispatch(pushToAnsweredPracticeSelections(q.id));
  });

  const onPressOption = (option) => {
    const { value, type } = option.action;
    switch (type) {
      case "next":
        if (stepId === -1) {
          setShowPrivacyModal(true);
          return;
        } else if (practicesStep?.selection === null) {
          setShowUnansweredQuestionsModal(false);
        } else if (
          practicesStep.selection != null &&
          practicesStep?.selection?.subCateID! !== 0 &&
          practicesStep?.selection?.subCateID! !== -1 &&
          !allSelected
        ) {
          setShowUnansweredQuestionsModal(true);
          return;
        }

        window.scrollTo(0, 0);

        setStepId(value);
        break;
      case "back":
        return setStepId(value);
      case "finishLater":
        return setShowFinishLater(true);
      case "navigate":
        dispatch(sendPracticeMarkCompleteRequestRequest());
        return history.push(value);
      default:
        return () => {};
    }
  };

  const onQuestionChange = (id: number, answerLevel: number) => {
    dispatch(
      sendPracticeSelectionUpdateRequest({
        practiceSelectionID: id,
        answerLevel
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    scrollToNextQuestion(id);
  };

  const scrollToNextQuestion = (id: number) => {
    const nextQuestionIndex =
      questions.findIndex((question) => {
        return question.id === id;
      }) + 1;
    const nextQuestion = questions[nextQuestionIndex];
    if (nextQuestion) {
      const nextQuestionElement = document.getElementById(nextQuestion.id.toString());
      if (nextQuestionElement) {
        const topOfNextQuestion = nextQuestionElement.offsetTop - seityHeader.clientHeight - assessHeader.clientHeight - 4;
        window.scroll({ top: topOfNextQuestion, behavior: "smooth" });
      }
    } else if (!nextQuestion && nextQuestionIndex === questions.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  const scrollToUnansweredQuestion = () => {
    const firstNotAnsweredIndex = questions.findIndex((i) => {
      return i.answerLevel === 0;
    });
    const firstNotAnswered = questions[firstNotAnsweredIndex];
    if (firstNotAnswered) {
      const nextQuestionElement = document.getElementById(firstNotAnswered.id.toString());
      if (nextQuestionElement) {
        const topOfNextQuestion = nextQuestionElement.offsetTop - seityHeader.clientHeight - assessHeader.clientHeight - 4;
        window.scroll({ top: topOfNextQuestion, behavior: "smooth" });
      }
    }
  };

  const getContent = useMemo(() => {
    const step = practicesStep;
    if (step!.id === -1) {
      return <AssessmentSectionIntro mode="practices" />;
    }
    if (step!.selection?.subCateID === -1) {
      return practicesStart(step!.selection.cateID);
    }
    if (step!.selection && step!.selection?.subCateID > 0 && step!.selection?.subCateID < 26) {
      // need a better way of capturing last step
      // return map right here
      return (
        <div className="question-section-container">
          {questions.map((question, index) => {
            return (
              <SeityQuestion
                id={question.id}
                needsToBeAnswered={needsToBeAnswered}
                questionTypeId={question.questionTypeId}
                questionText={`${index + 1}. ${question.questionText}`}
                answerLevel={question.answerLevel}
                handleSelect={onQuestionChange}
                key={question.id.toString()}
                answerSelections={question.answerSelections}
              />
            );
          })}
        </div>
      );
    }
    if (step!.selection?.subCateID === 0) {
      return <AssessmentCategoryScoreCard outcomeCategory={step!.selection?.cateID} />;
    }
    if (step!.id === 26) {
      return <YourPracticeScores />;
    }
  }, [questions, practicesStep]);

  return (
    <AssessWrap
      stepNum={practicesStep.id === -1 ? undefined : 2}
      showFinishLater={showFinishLater}
      setShowFinishLater={setShowFinishLater}
      label={strings.practices}
      subHeader={practicesHeader(practicesStep, onPressOption, denominator, answeredOutcomeSelections, answeredPracticeSelections)}
    >
      {practicesStep && (
        <>
          {isLoading ? <SeityLoader showBackgroundMask /> : null}
          {getContent}
          {practicesFooter(practicesStep, onPressOption)}
          <SeityAlert
            title={strings.oops}
            visible={showUnansweredQuestionsModal}
            onToggle={() => {
              scrollToUnansweredQuestion();
              setShowUnansweredQuestionsModal(false);
              setNeedsToBeAnswered(true);
            }}
            subTitle={strings.unansweredQuestionsAlert}
          />
          <PrivacyPolicyModal
            visible={showPrivacyModal}
            onToggle={() => {
              return setShowPrivacyModal(false);
            }}
            onNext={() => {
              setStepId(0);
            }}
            onFinish={() => {
              setShowPrivacyModal(false);
              setShowFinishLater(true);
            }}
          />
        </>
      )}
    </AssessWrap>
  );
};
