import React, { useState } from "react";
import strings from "../_core/strings/strings";

export type SeityAppContextType = {
  showNavbar: boolean;
  setShowNavbar: (value: boolean) => void;
  backButtonPath: string;
  setBackButtonPath: (path: string) => void;
  showNavMenuItems: boolean;
  setShowNavMenuItems: (value: boolean) => void;
  navigationMenuButtonText: string;
  setNavigationMenuButtonText: (value: string) => void;
  onNavigationMenuButtonPress: any;
  setOnNavigationMenuButtonPress: (value: Function) => void;
  showTodo: boolean;
  setShowTodo: (value: boolean) => void;
  wellbeingScreenEnabled: boolean;
  setWellbeingScreenEnabled: (value: boolean) => void;
};

export const SeityAppContext = React.createContext<SeityAppContextType>(null as any);

const SeityAppContextProvider = ({ children }) => {
  function useFunctionAsState(fn: any) {
    const [val, setVal] = useState(() => {
      return fn;
    });
    function setFunc(fnc: any) {
      setVal(() => {
        return fnc;
      });
    }
    return [val, setFunc];
  }

  const [showNavbar, setShowNavbar] = useState(false);
  const [backButtonPath, setBackButtonPath] = useState("/");
  const [showNavMenuItems, setShowNavMenuItems] = useState(true);
  const [navigationMenuButtonText, setNavigationMenuButtonText] = useState("");
  const [onNavigationMenuButtonPress, setOnNavigationMenuButtonPress] = useFunctionAsState(() => {});
  const [showTodo, setShowTodo] = useState(true);
  const [wellbeingScreenEnabled, setWellbeingScreenEnabled] = useState(false);

  return (
    <SeityAppContext.Provider
      value={{
        showNavbar,
        setShowNavbar,
        backButtonPath,
        setBackButtonPath,
        navigationMenuButtonText,
        setNavigationMenuButtonText,
        showNavMenuItems,
        setShowNavMenuItems,
        onNavigationMenuButtonPress,
        setOnNavigationMenuButtonPress,
        showTodo,
        setShowTodo,
        wellbeingScreenEnabled,
        setWellbeingScreenEnabled
      }}
    >
      {children}
    </SeityAppContext.Provider>
  );
};

export default SeityAppContextProvider;
