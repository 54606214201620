/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useMemo, FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";

import { RootState } from "../../../../app/rootReducer";
import { getSyncDetailRequest, putSyncRequest } from "../syncDetailSlice";
import { getStoryDetailRequest, putStoryRequest } from "../storyDetailSlice";
import { ASSET_IMG_URL } from "../../../../api/apiConstants";

import { SeityLoader } from "../../../../_core/components/SeityLoader";
import { SeityBackButton } from "../../../../_core/components/SeityBackButton";

import { SyncStoriesParamTypes, SyncStoriesDetailTypes } from "./types";
import strings from "../../../../_core/strings/strings";
import "./styles.scss";
import moment from "moment";
import AnswerModal from "./AnswerModal";
import { JournalEmotion } from "../../../../api/individualJournal/journalTypes";
import SeityEmotionsPopup from "../../../../_core/components/SeityEmotionsPopup";
import JournalEntryModal from "../../../journals/JournalEntryModal";
import ChoiceList, { ChoiceOption } from "./ChoiceList";
import { getJournalEmotionsRequest } from "../../../journals/journalsSlice";
import { SyncDetail } from "../../../../api/sync/types/syncTypes";
import { toastSuccess } from "../../../../app/utils";
import { resetSyncDetailSlice } from "../syncDetailSlice";
import { resetStoryDetailSlice } from "../storyDetailSlice";

const STORY_CHOICE_DATA: Array<ChoiceOption> = [
  {
    name: "True",
    value: 1
  },
  {
    name: "False",
    value: 2
  }
];

export const SyncStoriesDetail: FunctionComponent<SyncStoriesDetailTypes> = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const params = useParams<SyncStoriesParamTypes>();
  const objectiveID: number = parseInt(params.objectiveID);

  const [answer, setAnswer] = useState(0);
  const [showEmotionPopup, setShowEmotionPopup] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [showJournalEntry, setShowJournalEntry] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [emotionData, setEmotionData] = useState<{
    emotions: Array<JournalEmotion>;
    added: Array<JournalEmotion>;
  }>({
    emotions: [],
    added: []
  });

  const SYNC_CHOICE_DATA: Array<ChoiceOption> = [
    {
      name: strings.letsDoThis,
      value: 3
    },
    {
      name: strings.alreadyDidIt,
      value: 4
    },
    {
      name: strings.notForMe,
      value: 5
    }
  ];

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { isLoading: isLoadingEmotion, emotionsData } = useSelector((state: RootState) => {
    return state.journals;
  });

  const { isLoading: isLoadingSyncs, syncDetail } = useSelector((state: RootState) => {
    return state.syncDetail;
  });

  const { isLoading: isLoadingStories, storyDetail } = useSelector((state: RootState) => {
    return state.storyDetail;
  });

  const isStories = useMemo(() => {
    return location.pathname.includes("/myStories");
  }, [location.pathname]);

  const { details, contentField } = useMemo(() => {
    const details = isStories ? storyDetail : syncDetail;
    const contentField = isStories ? "journalContents" : "syncContents";

    return {
      details,
      contentField
    };
  }, [isStories, storyDetail, syncDetail]);

  const isLoading = isLoadingStories || isLoadingSyncs || isLoadingEmotion;

  const loadDetailData = () => {
    if (isStories) {
      dispatch(getStoryDetailRequest(objectiveID));
    } else {
      dispatch(getSyncDetailRequest(objectiveID));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (emotionsData.length === 0) {
      dispatch(getJournalEmotionsRequest());
    }
    loadDetailData();
    return () => {
      if (isStories) {
        dispatch(resetStoryDetailSlice());
      } else {
        dispatch(resetSyncDetailSlice());
      }
    };
  }, []);

  useEffect(() => {
    if (emotionsData.length > 0 && details) {
      let added: Array<JournalEmotion> = [];
      const emos = emotionsData.map((e) => {
        let temp: JournalEmotion = { ...e, selected: false };
        if (details.userSelectedEmotions) {
          temp.selected = details.userSelectedEmotions.filter((s) => s.emotionID === e.emotionID)?.length > 0;
          if (temp.selected) {
            added.push(temp);
          }
        }
        return temp;
      });
      setEmotionData({ emotions: emos, added: added });
    }
    if (details) {
      setIsCompleted(details.isCompleted);
      if (isStories && details.knowledgeCheckAnswers && details.knowledgeCheckAnswers.length > 0 && details.isCompleted) {
        const correctAnswer = details.knowledgeCheckAnswers.findIndex((k) => k.isCorrectAnswer) + 1;
        setAnswer(correctAnswer);
      }
      if (!isStories) {
        setAnswer((details as SyncDetail).statusID);
      }
    }
  }, [emotionsData, details]);

  const content = useMemo(() => {
    return details.content;
  }, [contentField, details]);

  const references = useMemo(() => {
    if (showReferences) {
      return <div dangerouslySetInnerHTML={{ __html: details.references }} />;
    } else {
      return <></>;
    }
  }, [showReferences]);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  const handleAnswer = (option, correctAnswer?: number) => {
    if (answer === option) {
      setAnswer(0);
    } else if (answer === 0 || answer === 1) {
      setAnswer(option);
      const selEmotions = emotionData.added.map((e) => {
        return { emotionID: e.emotionID };
      });
      if (option !== 5 && !isStories) {
        // Sync and not a `Not For Me`
        setShowAnswerModal(true);
        setTimeout(() => setShowAnswerModal(false), 2000);
      } else if (isStories && option === correctAnswer) {
        setShowAnswerModal(true);
        setTimeout(() => setShowAnswerModal(false), 2000);
        //TODO: call put api to set the story complete
        setIsCompleted(true);

        dispatch(putStoryRequest(details.objectiveID, true, selEmotions));
      }
      if (!isStories) {
        dispatch(putSyncRequest(details.objectiveID, false, option, selEmotions));
      }
    }
  };

  const getChoice = useMemo(() => {
    if (isStories && !details.knowledgeCheckAnswers) {
      return null;
    }
    if (isStories) {
      const options = details.knowledgeCheckAnswers!.map((k, i) => {
        return { name: k.answerText, value: i + 1 };
      });
      const correctAnswer = details.knowledgeCheckAnswers!.findIndex((k) => k.isCorrectAnswer) + 1;
      return {
        question: (
          <>
            <p>{strings.chooseOneBelow}</p>
            <ChoiceList
              isStory={isStories}
              options={options}
              selVal={answer}
              correctAnswer={correctAnswer}
              onSelect={(val) => {
                if (!isCompleted) {
                  handleAnswer(val, correctAnswer);
                }
              }}
            />
          </>
        ),
        answer: (
          <div className="mt-xl mr-auto">
            {answer === correctAnswer ? <span>🎉 {strings.correct}</span> : answer !== 0 && <span>❌ {strings.inCorrect}</span>}
          </div>
        )
      };
    } else {
      return {
        question: (
          <>
            <p>{strings.chooseOneBelow}</p>
            <ChoiceList
              isStory={isStories}
              options={SYNC_CHOICE_DATA}
              selVal={answer}
              onSelect={(val) => {
                handleAnswer(val);
              }}
            />
          </>
        )
      };
    }
  }, [answer, details]);

  if (!details.content || !details.content.length) {
    return <SeityLoader showBackgroundMask />;
  }

  return (
    <div className="sd-container-wrapper flex-column flex-ai-c">
      {isLoading && <SeityLoader showBackgroundMask />}
      <div className="sd-container">
        <SeityBackButton wrapperClassName="sd-back-button" />
        <div className="sd-foreground">
          <div className="sd-background w-100 h-100" style={{ backgroundImage: `url(${details.image})` }} />
          <div className="sd-mask w-100 h-100">
            <h1>{details.title}</h1>
            <div>
              {details.topEmotionsImages.map((e, i) => {
                return <img key={i + e} src={`${ASSET_IMG_URL}${e.replace("01", "selected01")}`} alt="emotion" width={16} />;
              })}
              {details.totalEmotionSelectionCount > 0 && <span className="ml-sm">{details.totalEmotionSelectionCount}</span>}
              {isCompleted && (
                <img
                  className="check-icon ml-auto"
                  src={require("../../../../assets/graphics/intentions/check.png").default}
                  alt="check icon"
                  width={17}
                />
              )}
            </div>
          </div>
        </div>
        <div className="sd-description mt-xl" dangerouslySetInnerHTML={{ __html: content }} />
        {isStories && details.questionText && (
          <div className="sd-story-choose w-100 mt-xl">
            <img src={require("../../../../assets/graphics/accountability/icon-knowledge.png").default} width={34} />
            <h3>{strings.knowledgeReview}</h3>
            <h5>{details.questionText}</h5>
            {getChoice?.question}
            {getChoice?.answer}
          </div>
        )}
        {!isStories && (
          <div className="sd-sync-choose w-100 mt-xl">
            {getChoice?.question}
            {isCompleted && (
              <div className="completed-sync mt-xl w-100 flex-column flex-center">
                <img
                  className="check-icon"
                  src={require("../../../../assets/graphics/intentions/check.png").default}
                  alt="check icon"
                  width={17}
                />
                <span className="mt-xl">{strings.youCompletedSync}</span>
              </div>
            )}
          </div>
        )}
        {details.numberOfUsersDoingObjective > 99 && (
          <p className="sd-numberOfUsers">
            {details.numberOfUsersDoingObjective} {strings.seityUsersDoingThis}
          </p>
        )}
        {details.references && (
          <div className="sd-reference mt-xl pt-xl">
            <button
              onClick={() => {
                setShowReferences(!showReferences);
              }}
            >
              {strings.references}
              <img src={require("../../../../assets/graphics/accountability/reference-arrow.png").default} alt="arrow icon" width={24} />
            </button>
            {references}
          </div>
        )}
        <div className="sd-actionBtn w-100 flex mt-xl">
          <button
            className="flex-center mr-xl"
            onClick={() => {
              setShowEmotionPopup(true);
            }}
          >
            {emotionData.added.length > 0 ? (
              <>
                <span className="mr-md">{strings.iFeel}</span>
                {emotionData.added.slice(0, 3).map((e) => {
                  return <img key={e.emotionID} src={`${ASSET_IMG_URL}${e.icon02}`} alt="emotion" width={24} />;
                })}
              </>
            ) : (
              <>
                <img src={require("../../../../assets/graphics/accountability/happy.png").default} alt="emotion" width={24} />
                <span className="ml-md">{strings.iFeel}...</span>
              </>
            )}
          </button>
          <button
            className="flex-center"
            onClick={() => {
              setShowJournalEntry(true);
            }}
          >
            <img src={require("../../../../assets/graphics/stories.png").default} width={21} />
          </button>
        </div>
      </div>
      {showAnswerModal && <AnswerModal correctAnswer={isStories} isOpen={showAnswerModal} option={answer} />}
      {showEmotionPopup && emotionData.emotions?.length > 0 && (
        <>
          <div
            className="emotionsPopup-wrap"
            onClick={() => {
              setShowEmotionPopup(false);
              const selEmotions = emotionData.added.map((e) => {
                return { emotionID: e.emotionID };
              });
              if (isStories) {
                dispatch(
                  putStoryRequest(details.objectiveID, false, selEmotions, (success) => {
                    if (success) {
                      loadDetailData();
                    }
                  })
                );
              } else {
                dispatch(
                  putSyncRequest(details.objectiveID, false, 1, selEmotions, (success) => {
                    if (success) {
                      loadDetailData();
                    }
                  })
                );
              }
            }}
          />
          <SeityEmotionsPopup
            emotions={emotionData.emotions}
            fullScreen={false}
            handleEmotionSel={(idx, selected) => {
              const temp = [...emotionData.emotions];
              const sel = { ...temp[idx] };
              sel.selected = selected;
              temp[idx] = sel;
              const added = temp.filter((t) => {
                return t.selected === true;
              });
              setEmotionData({ emotions: temp, added });
            }}
          />
        </>
      )}
      {showJournalEntry && (
        <div className="journal-entry-modal-overlay">
          <div className="journal-entry-modal-container">
            <JournalEntryModal
              type={isStories ? "story" : "sync"}
              title={details.title}
              objectiveID={details.objectiveID}
              journal={details.journal}
              onClose={(success: boolean) => {
                setShowJournalEntry(false);
                if (success) {
                  toastSuccess(strings.successNewEntryCreated);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
