import React, { FunctionComponent, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useCoreValueHistory } from '../hooks/useCoreValueHistory'; // Import the custom hook
import './styles.scss';
import SeityLoader from "../../../_core/components/SeityLoader";
import SeityCVImage from '../../../_core/components/SeityCVImage';
import BackCaret from "../../../assets/graphics/back_btn.png";

export const CoreValueHistory: FunctionComponent = (): ReactElement => {
  const history = useHistory();
  const pageSize = 10;

  const {
    isLoading,
    coreValueHistoryGroups,
    hasMore,
    loadMoreData
  } = useCoreValueHistory(pageSize); // Use the custom hook

  return (
    <div className="seity-main-medium core-value-history">
      <div className="core-value-history__header">
        <button className='core-value-history__back-button' onClick={() => { history.push("/coreValues") }}>
          <img src={BackCaret} alt="Back" />
        </button>
        <h1 className="core-value-history__title">Core Value History</h1>
      </div>
      <InfiniteScroll
        dataLength={coreValueHistoryGroups.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<SeityLoader />}
        scrollableTarget="scrollableDiv"
      >
        {coreValueHistoryGroups.map((group, index) => (
          <div key={`${index}-corevalue-group`} className="core-value-history__main">
            <div className="core-value-history__group">
              <h3 className="core-value-history__date">{new Date(group.items[0].recordCreate).toDateString()}</h3>
              <div className="core-value-history__images">
                {group.items.map((cvItem, cvIndex) => (
                  <SeityCVImage key={`${cvIndex}-corevalue-image`} cvId={cvItem.coreValueID} size={30} />
                ))}
              </div>
              <div className="core-value-history__descriptions">
                {group.items.map((cvItem, cvIndex) => (
                  <p key={`${cvIndex}-corevalue-text`} className="core-value-history__description">{cvItem.coreValue}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default CoreValueHistory;
