import { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../app/rootReducer";
import { setCurrentInfo, setRegisterError } from "../../registerSlice";

import RegisterWrapper from "../../RegisterWrapper";
import SeityButton from "../../../../_core/components/SeityButton";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";
import { SeityFormTextInput } from "../../../../_core/components/SeityTextInput/SeityFormTextInput";

import strings from "../../../../_core/strings/strings";
import "./style.scss";

export const RegisterTwo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { current } = useSelector((state: RootState) => {
    return state.register;
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const {
    current: { companyName }
  } = useSelector((state: RootState) => {
    return state.register;
  });

  const disabled = useMemo(() => {
    return firstName.length === 0 || lastName.length === 0;
  }, [firstName.length, lastName.length]);

  const validateName = (name: string, isLastName: boolean) => {
    if (name.length > 0) {
      if (isLastName) {
        setLastName(name);
      } else {
        setFirstName(name);
      }
      dispatch(setRegisterError({ registerError: "" }));
    } else {
      dispatch(
        setRegisterError({
          registerError: isLastName ? strings.lastNameMissing : strings.firstNameMissing
        })
      );
      if (isLastName) {
        setLastName("");
      } else {
        setFirstName("");
      }
    }
  };

  const nextPressed = () => {
    dispatch(
      setCurrentInfo({
        ...current,
        firstName,
        lastName
      })
    );
    history.push("/register/registerThree");
  };

  return (
    <RegisterWrapper>
      <div className="register-two-container">
        <div>
          <h1>{strings.heading}</h1>
          <SeityIndicator currentStep={2} numberOfSteps={4} />
          <h5>
            {strings.helloInvited} {companyName}
            {strings.helloInvited2}
          </h5>
          <form className="textbox-form">
            <SeityFormTextInput
              inputLabelText={strings.firstNameLabel}
              controlId="first-name"
              type="text"
              onChange={(e) => {
                return validateName(e.target.value, false);
              }}
              testID="first-name-input"
              value={firstName}
            />
            <SeityFormTextInput
              inputLabelText={strings.lastNameLabel}
              controlId="last-name"
              type="text"
              onChange={(e) => {
                return validateName(e.target.value, true);
              }}
              testID="last-name-input"
              value={lastName}
            />
          </form>
        </div>
        <div className="register-two-container-bottom">
          <SeityButton
            onClick={nextPressed}
            type="next"
            disabled={disabled}
            label={strings.nextButtonText}
            className="register-two-next-button"
            testId="next-button"
          />
        </div>
      </div>
    </RegisterWrapper>
  );
};
