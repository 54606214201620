import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreValueHistoryGroup } from './types/coreValueHistoryTypes';
import { AppThunk } from '../../../app/store';
import { setRedirectToLogin } from '../../auth/authSlice';
import { SeityAuthenticationError } from '../../../api/authTypes';
import { getCoreValueHistory } from './coreValueHistoryAPI';
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";

export interface CoreValueHistoryState {
  error: string | null;
  isLoading: boolean;
  coreValueHistoryGroups: CoreValueHistoryGroup[];
  hasMore: boolean;
}

const initialState: CoreValueHistoryState = {
  error: null,
  isLoading: false,
  coreValueHistoryGroups: [],
  hasMore: true,
};

const coreValueHistorySlice = createSlice({
  name: 'coreValueHistory',
  initialState,
  reducers: {
    appendCoreValueHistoryGroups(state, action: PayloadAction<CoreValueHistoryGroup[]>) {
      state.isLoading = false;
      state.coreValueHistoryGroups = [...state.coreValueHistoryGroups, ...action.payload];
      if (action.payload.length === 0) {
        state.hasMore = false;
      }
    },
    clearCoreValueHistoryGroups(state) {
      state.coreValueHistoryGroups = [];
      state.hasMore = true;
    },
    setCoreValueHistoryError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  }
});

export const {
  appendCoreValueHistoryGroups,
  clearCoreValueHistoryGroups,
  setCoreValueHistoryError,
  clearApiError,
  setIsLoading,
} = coreValueHistorySlice.actions;
export default coreValueHistorySlice.reducer;

export const fetchCoreValueHistory = (pageSize: number, pageNumber: number): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError());
    dispatch(setIsLoading(true));

    const { token } = await getRefreshedToken();

    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const response = await getCoreValueHistory(token, pageSize, pageNumber);

    if (response.success) {
      dispatch(appendCoreValueHistoryGroups(response.data.coreValueHistoryGroups));
    } else {
      dispatch(setCoreValueHistoryError({ error: response.message }));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setCoreValueHistoryError({ error: err.toString() }));
  }
};
