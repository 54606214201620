import { FunctionComponent } from "react";
import SeityModal from "../../../_core/components/SeityModal";
import SeityButton from "../../../_core/components/SeityButton";
import strings from "../../../_core/strings/strings";
import "./styles.scss";

type CompleteAssessmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CompleteAssessmentModal: FunctionComponent<CompleteAssessmentModalProps> = ({ isOpen, onClose }) => {
  return (
    <SeityModal isOpen={isOpen} onClose={onClose} wrapperClassName="complete-assessment-modal-wrapper">
      <div className="complete-assessment-modal-content">
        <h1 className="complete-assessment-modal-title">{strings.completeCVAssessment}</h1>
        <p className="complete-assessment-modal-subTitle">{strings.completeCVAssessmentFirstNotice}</p>
        <div className="complete-assessment-modal-buttonWrapper">
          <SeityButton onClick={onClose} label={strings.gotItWithoutPunctuation} className="complete-assessment-modal-buttonContainer" />
        </div>
      </div>
    </SeityModal>
  );
};

export default CompleteAssessmentModal;
