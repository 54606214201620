import {BASE_URL, PLATFORM_ID} from "../apiConstants";
import {SeityResponse} from "../baseTypes";
import {CreateSupportTicketRequest, CreateSupportTicketResponse} from "./types/supportTicketTypes";
import apiClientWithAuth from "../../api/apiClient";
import platform from 'platform';

export async function createSupportTicketRequest(
  {subject, description}: CreateSupportTicketRequest
): Promise<CreateSupportTicketResponse> {
  const url = BASE_URL + "/SupportTicket/Create";
  try {

    const browserInfo = platform.parse(navigator.userAgent);

    const platformDetails = `Browser Name: ${browserInfo.name}\n` +
      `Version: ${browserInfo.version}\n` +
      `OS: ${browserInfo.os.family} ${browserInfo.os.version}\n` +
      `Layout Engine: ${browserInfo.layout}\n` +
      `Product: ${browserInfo.product}\n` +
      `Manufacturer: ${browserInfo.manufacturer}\n` +
      `User Agent: ${navigator.userAgent}\n` +
      `Description: ${browserInfo.description}`;

    const createSupportTicketResponse = await apiClientWithAuth.post(url, {
      subject,
      description,
      devicePlatformID: PLATFORM_ID,
      platformDetails: platformDetails
    });

    return createSupportTicketResponse.data;

  } catch (err) {
    throw err;
  }
}