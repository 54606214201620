import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/rootReducer';
import {clearCoreValueHistoryGroups, fetchCoreValueHistory, clearApiError} from "../CoreValueHistory/coreValueHistorySlice";

export const useCoreValueHistory = (pageSize: number) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [coreValueHistoryCount, setCoreValueHistoryCount] = useState(0);
  const { isLoading, coreValueHistoryGroups, hasMore, error } = useSelector((state: RootState) => state.coreValueHistory);

  useEffect(() => {
    dispatch(clearCoreValueHistoryGroups());
    dispatch(clearApiError());
    dispatch(fetchCoreValueHistory(pageSize, 1));
    setPageNumber(1);
  }, [dispatch]);

  const loadMoreData = () => {
    if (!isLoading && hasMore) {
      setPageNumber(prevPageNumber => prevPageNumber + 1);
      dispatch(fetchCoreValueHistory(pageSize, pageNumber + 1));
    }
  };

  useEffect(() => {
    if (coreValueHistoryGroups) {
      setCoreValueHistoryCount(coreValueHistoryGroups.length);
    }
  }, [coreValueHistoryGroups]);

  return {
    isLoading,
    coreValueHistoryGroups,
    hasMore,
    error,
    loadMoreData,
    setPageNumber,
    coreValueHistoryCount
  };
};
