export const BASE_URL = process.env.REACT_APP_SEITYRIVER_API || "https://ase-seityriver-dev-westus2.azurewebsites.net";
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "1234567890-abc123def456.apps.googleusercontent.com";

export const PLATFORM_ID = 7;

export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL || "https://cdne-shcdn-global-001.azureedge.net/image/";
export const GRAPHICS_URL = process.env.REACT_APP_GRAPHICS_URL || "https://cdne-shcdn-global-001.azureedge.net/graphics/";
export const VIDEO_URL = process.env.REACT_APP_VIDEO_URL || "https://cdne-shcdn-global-001.azureedge.net/video/";
export const DOCUMENTS_URL = process.env.REACT_APP_DOCUMENTS_URL || "https://cdne-shcdn-global-001.azureedge.net/document/";
export const ASSET_IMG_URL = process.env.REACT_APP_ASSET_IMG_URL || "https://stshprodassets01.blob.core.windows.net/graphics/";
export const ASSET1_IMG_URL = process.env.REACT_APP_ASSET1_IMG_URL || "https://stshprodassets01.blob.core.windows.net/image/";
export const INTENTION_IMG_URL = process.env.REACT_APP_INTENTION_IMG_URL || "https://cdne-shcdn-global-001.azureedge.net/image/";
