/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent, ReactElement } from "react";
import { ServiceResourceDetail } from "../../../../api/resource/resourceTypes";
import { isValidHttpUrl } from "../../../../app/utils";

import "./styles.scss";
import { GRAPHICS_URL } from "../../../../api/apiConstants";

type ResourceDetailProps = {
  serviceResourceDetail: ServiceResourceDetail;
};

export const ResourceDetail: FunctionComponent<ResourceDetailProps> = ({ serviceResourceDetail }): ReactElement => {
  return (
    <div className="detail-wrapper">
      {serviceResourceDetail.header !== "" && <div className="detail-header">{serviceResourceDetail.header}</div>}
      <div className="detail-item">
        <div className="image-container">
          <img
            className="resource-icon"
            src={GRAPHICS_URL + serviceResourceDetail.imageName}
            alt="icon"
          />
        </div>
        <div className="detail-container">
          {serviceResourceDetail.serviceResourceTypeID === 4 && isValidHttpUrl(serviceResourceDetail.actionText) ? (
            <a
              className="resource-detail-text link"
              onClick={() => {
                window.open(serviceResourceDetail.actionText, "_blank");
              }}
            >
              {serviceResourceDetail.text}
            </a>
          ) : (
            <div className={serviceResourceDetail.imageName === "police01.png" ? "emergency-detail-text" : "resource-detail-text"}>
              {serviceResourceDetail.text}
            </div>
          )}
        </div>
      </div>
      {serviceResourceDetail.footer !== "" && <div className="detail-footer">{serviceResourceDetail.footer}</div>}
    </div>
  );
};

export default ResourceDetail;
