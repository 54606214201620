import axios from "axios";
import {BASE_URL, PLATFORM_ID} from "./apiConstants";
import {SeityAuthRefreshResponse, SeityLoginResponse, SeitySignupResponse} from "./authTypes";
import {SeityResponse} from "./baseTypes";
import {DateTime} from "luxon";
import {SeityGoogleLoginResponse} from "../_core/components/SeityGoogleLoginButton/types";

export async function login(username: string, password: string): Promise<SeityLoginResponse> {
  const url = BASE_URL + "/Auth/login";
  const clientTimeOffset = DateTime.local().offset / 60;
  try {
    const loginResponse = await axios.post<SeityLoginResponse>(url, {
      emailAddress: username,
      password,
      devicePlatformId: PLATFORM_ID,
      clientTimeOffset: clientTimeOffset
    });
    // DON'T LEAK CREDENTIALS TO LOGS/CONSOLE
    // console.log(loginResponse);
    return loginResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function forgotPassword(email: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Auth/ForgotPassword";
  try {
    const response = await axios.post<SeityResponse>(url, {
      eMailAddress: email
    });
    return response.data;
  } catch (err) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function sendTemporaryPassword(email: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Account/TemporaryPassword";
  try {
    const response = await axios.post<SeityResponse>(url, {
      email
    });
    return response.data;
  } catch (err) {
    if (err.response.data.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}

export async function sendTemporaryPassword2(email: string, phoneNumber: string, dateOfBirth: string): Promise<SeityResponse> {
  const url = BASE_URL + '/Account/TemporaryPassword';
  try {
    const response = await axios.post<SeityResponse>(url, {
      email,
      phoneNumber,
      dateOfBirth
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}

export async function sendForgotUsername(emailAddress: string, phoneNumber: string, dateOfBirth: string): Promise<SeityResponse> {
  const url = BASE_URL + '/Auth/ForgotUserID';
  try {
    const response = await axios.post<SeityResponse>(url, {
      emailAddress,
      phoneNumber,
      dateOfBirth
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.userMessage) {
      err.message = err.response.data.userMessage;
    }
    throw err;
  }
}

export async function signup(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  groupActivationCode: string
): Promise<SeitySignupResponse> {
  const url = BASE_URL + "/Auth/Register";
  try {
    const response = await axios.post<SeitySignupResponse>(url, {
      emailAddress: email,
      password,
      firstName,
      lastName,
      groupActivationCode
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getNewToken(refreshKey: string, token: string = ""): Promise<SeityAuthRefreshResponse> {
  const url = BASE_URL + "/Auth/Refresh";
  const clientTimeOffset = DateTime.local().offset / 60;
  try {
    const response = await axios.post<SeityAuthRefreshResponse>(url, {
      refreshKey,
      token,
      devicePlatformId: PLATFORM_ID,
      clientTimeOffset: clientTimeOffset
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function googleLogin(
  googleLoginResponse: SeityGoogleLoginResponse
): Promise<SeityLoginResponse> {
  const url = BASE_URL + '/Auth/GoogleLogin';
  const currentDate = new Date();
  const clientTimeOffset = -(currentDate.getTimezoneOffset() / 60);
  try {
    const loginResponse = await axios.post<SeityLoginResponse>(url, {
      clientId: googleLoginResponse.clientId,
      jwt: googleLoginResponse.jwt,
      accessToken: googleLoginResponse.accessToken,
      devicePlatformId: PLATFORM_ID,
      clientTimeOffset: clientTimeOffset,
    });

    return loginResponse.data;
  } catch (err) {
    throw err;
  }
}
