import React, { FunctionComponent, ReactElement, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import { getResourcesRequest } from "../resourceSlice";
import { ResourceGroup } from "../components/ResourceGroup";
import SeityLoader from "../../../_core/components/SeityLoader";

import "./styles.scss";

export const Resources: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();

  const { error, isLoading, serviceResourceGroups } = useSelector((state: RootState) => {
    return state.resources;
  });

  useEffect(() => {
    dispatch(getResourcesRequest());

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="help-recources-container">
      {isLoading && <SeityLoader />}
      {serviceResourceGroups.map((resourceGroup, index) => {
        return <ResourceGroup key={index} serviceResourceGroup={resourceGroup} />;
      })}
    </div>
  );
};

export default Resources;
