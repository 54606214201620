import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CoreStrings from "../../../_core/strings/strings";
import SeityModal from "../../../_core/components/SeityModal";

import "./styles.scss";
import { CoreValue } from "../../../api/referenceGuide/referenceGuideTypes";
import SeityCVImage from "../SeityCVImage";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import { sendGetSeityCoreValues } from "../../../features/grow/learn/guideSlice";

export type SeityCVModalProps = {
  cvData: CoreValue;
  onClose: () => void;
};

const SeityCVModal: FunctionComponent<SeityCVModalProps> = ({ cvData, onClose }): ReactElement => {
  const dispatch = useDispatch();
  const { coreValues } = useSelector((state: RootState) => state.guide);
  const [videoURL, setVideoURL] = useState<string | undefined>(cvData?.video);

  if (!videoURL && coreValues && coreValues.length > 0) {
    const foundCV = coreValues.find((cv) => {
      return cv.coreValueID === cvData.coreValueID;
    });
    if (foundCV?.video) {
      setVideoURL(foundCV.video);
    }
  }
  useEffect(() => {
    if (coreValues === null || coreValues.length < 1) {
      dispatch(sendGetSeityCoreValues());
    }
  }, []);

  return (
    <SeityModal wrapperClassName="seityCVModal" showCloseBtn={true} isOpen={true} onClose={onClose}>
      <div className="seityCVModal-content">
        <div className="seityCVModal-video">
          {videoURL ? (
            <video autoPlay loop muted controls>
              <source src={videoURL} type="video/mp4" />
            </video>
          ) : null}
        </div>
        <div className="seityCVModal-desc">
          <div className="seityCVModal-desc-header">
            <SeityCVImage classname="cv-img" size={48} cvId={cvData.coreValueID} />
            <h1>{cvData.coreValue}</h1>
          </div>
          <h4>{CoreStrings.definition}</h4>
          <p>{cvData.definition}</p>
          <div className="seityCVModal-desc-divider" />
          <h4>{CoreStrings.explanation}</h4>
          <p>{cvData.description}</p>
          <h4>{CoreStrings.quote}</h4>
          <p>{cvData.quote}</p>
        </div>
      </div>
    </SeityModal>
  );
};

export default SeityCVModal;
