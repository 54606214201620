import { FunctionComponent } from 'react';
import PermissionHeader from '../PermissionHeader';
import '../styles.scss'
import strings from '../../../_core/strings/strings';
import { SeityToggle } from '../../../_core/components/SeityToggle';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import { setToggles } from '../../../api/coreValueDirectory/seityHealthAPI-CoreValueDirectory';
import { toastSuccess, toastError } from "../../../app/utils";
import { getUserAppSettingsRequest } from '../../../slices/userAppSettingsSlice';

const CoreValueDirectoryPermission: FunctionComponent = () => {
  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const dispatch = useDispatch();

  const sendRequest = (request: any) => {
    setToggles(request)
      .then(() => {
        toastSuccess(strings.permissionUpdateSuccess);
        dispatch(getUserAppSettingsRequest());
      })
      .catch((error: any) => {
        toastError(strings.permissionUpdateFailure);
        console.error("An error occurred:", error);
      });
  }

  const handleDisplayNameToggle = (newState: boolean) => {
    sendRequest({
      coreValueDirectoryDisplayNameEnabled: newState,
      coreValueDirectoryCoreValuesEnabled: null,
      doNotShowCoreValueDirectoryModalPromptClicked: null
    });
  }

  const handleCoreValuesToggle = (newState: boolean) => {
    sendRequest({
      coreValueDirectoryDisplayNameEnabled: null,
      coreValueDirectoryCoreValuesEnabled: newState,
      doNotShowCoreValueDirectoryModalPromptClicked: null
    });
  }

  return (
    <div className="sap-wrapper">
      <PermissionHeader />
      <div className="cvPermissionTextContainer">
        <h3>{strings.coreValueDirectory}</h3>
        <h4>{strings.coreValueDirectoryPermissionDescription}</h4>
        <div className="permission-list">
          <div className="permission-list-item">
            <h4><strong>{strings.displayName}</strong></h4>
            <SeityToggle
              initial={userAppSettings.coreValueDirectoryDisplayNameEnabled}
              onChange={handleDisplayNameToggle}
            />
          </div>
          <div className="permission-list-item">
            <h4><strong>{strings.displayCoreValues}</strong></h4>
            <SeityToggle
              initial={userAppSettings.coreValueDirectoryCoreValuesEnabled}
              onChange={handleCoreValuesToggle}
              disabled={!userAppSettings.coreValueDirectoryDisplayNameEnabled} // Disable if display name is off
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreValueDirectoryPermission;