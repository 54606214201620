/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unused-prop-types */
import "./styles.scss";

interface OutcomeValueSelectionProps {
  selectionChanged: (value: number) => void;
  images: Array<string>;
  selectedImages: Array<string>;
  value: number;
  id: string;
}

export const OutcomeValueSelection = ({ selectionChanged, images, selectedImages, value }: OutcomeValueSelectionProps) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handler = (value: number) => {
    selectionChanged(value);
  };

  const finalImages: Array<string> = [];
  if (images) {
    images.forEach((image, index) => {
      finalImages[index] = image;
      if (
        (value === 0 && index === 0) ||
        (value === 0.25 && index === 1) ||
        (value === 0.5 && index === 2) ||
        (value === 0.75 && index === 3) ||
        (value === 1 && index === 4)
      ) {
        finalImages[index] = selectedImages[index];
      }
    });
  }

  return (
    <div className="outcome-value-selection-container">
      <img
        alt="check in icon"
        src={`${finalImages[0]}`}
        onClick={() => {
          return handler(0);
        }}
      />
      <img
        alt="check in icon"
        onClick={() => {
          return handler(0.25);
        }}
        src={`${finalImages[1]}`}
      />
      <img
        alt="check in icon"
        onClick={() => {
          return handler(0.5);
        }}
        src={`${finalImages[2]}`}
      />
      <img
        alt="check in icon"
        onClick={() => {
          return handler(0.75);
        }}
        src={`${finalImages[3]}`}
      />
      <img
        alt="check in icon"
        onClick={() => {
          return handler(1);
        }}
        src={`${finalImages[4]}`}
      />
    </div>
  );
};
