/* eslint-disable react/require-default-props */
import React, { FunctionComponent, ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import spinnerGif from "../../../assets/gifs/lockIconSpinner.gif";
import "./styles.scss";
import { SeityAvatarProps } from "./types";
import { GRAPHICS_URL } from "../../../api/apiConstants";

export const SeityAvatar: FunctionComponent<SeityAvatarProps> = ({ size, fontSize, onClick, content, initials }): ReactElement => {
  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });
  const { userAppSettings, profilePictureLoading } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const returnAvatarContent = () => {
    const profilePicture = content ? content.profilePicture : userAppSettings.profilePictureBase64;
    const firstName = content?.firstName ?? accountInfo?.firstName ?? "";
    const lastName = content?.lastName ?? accountInfo?.lastName ?? "";
    let profilePictureSrc = "";
    if (
      profilePicture?.split(".")[1] === "png" ||
      profilePicture?.split(".")[1] === "jpg" ||
      profilePicture?.split(".")[1] === "jpeg" ||
      profilePicture?.split(".")[1] === "heic"
    ) {
      profilePictureSrc = GRAPHICS_URL + profilePicture;
    } else {
      profilePictureSrc = `data:image/png;base64, ${profilePicture}`;
    }
    return profilePicture ? (
      <img src={profilePictureSrc} alt={`${firstName} ${lastName}`} />
    ) : (
      <p style={fontSize ? { fontSize: fontSize } : {}}>
        {initials ?? (firstName.length > 0 ? firstName.charAt(0) : "") + (lastName.length > 0 ? lastName.charAt(0) : "")}
      </p>
    );
  };

  return (
    <div className="avatar-container" id="avatar" style={{ height: size, width: size }} onClick={onClick}>
      {profilePictureLoading ? <img src={spinnerGif} alt="profile picture loading" /> : returnAvatarContent()}
    </div>
  );
};

export default SeityAvatar;
