import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {Provider} from "react-redux";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import "bootstrap/dist/css/bootstrap.css";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GOOGLE_CLIENT_ID} from "./api/apiConstants";

// eslint-disable-next-line import/order
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <App/>
        </Provider>
      </Router>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
