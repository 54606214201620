/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
/* eslint-disable radix */
import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { RootState } from "../../../app/rootReducer";
import { getPracticeScoresRequest } from "../practicesSlice";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";

import SeityProgressBar from "../../../_core/components/SeityProgressBar";
import { SeityAppContext } from "../../../app/SeityAppContext";

import "./styles.scss";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface paramTypes {
  outcomeCategoryID: string;
  outcomeSubcategoryID: string;
}

export const PracticeDetail = () => {
  const appContext = useContext(SeityAppContext);
  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  const dispatch = useDispatch();

  const params = useParams<paramTypes>();
  let outcomeCategoryID: Number = parseInt(params.outcomeCategoryID);
  let outcomeSubcategoryID: Number = parseInt(params.outcomeSubcategoryID);
  let navigationTest = "Back";

  switch (outcomeCategoryID) {
    case 1:
      navigationTest = "Back";
      break;
    default:
      break;
  }

  const { practiceScores } = useSelector((state: RootState) => {
    return state.practiceScores;
  });

  useEffect(() => {
    dispatch(getPracticeScoresRequest());
  }, []);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  if (outcomeCategoryID < 1 || outcomeCategoryID > 4) {
    outcomeCategoryID = 1;
  }
  if (outcomeSubcategoryID < 1 || outcomeSubcategoryID > 18) {
    outcomeSubcategoryID = 1;
  }

  let foregroundColor: string = "";
  let backgroundColor: string = "";
  let fontColor: string = "";
  let title: string = "";
  let score: number = 0;
  let para1: string = "";
  let para2: string = "";
  let logoImage;

  switch (outcomeCategoryID) {
    case 1:
      backgroundColor = "rgba(47, 105, 108, 0.3)";
      foregroundColor = "rgba(47, 105, 108, 1)";
      fontColor = "rgba(255, 248, 235, 1)";
      navigationTest = practiceScores.worldCategory ?? "Back";
      break;
    case 2:
      backgroundColor = "rgba(86, 163, 166, 0.4)";
      foregroundColor = "rgba(86, 163, 166, 1)";
      fontColor = "rgba(255, 248, 235, 1)";
      navigationTest = practiceScores.bodyCategory ?? "Back";
      break;
    case 3:
      backgroundColor = "rgba(154, 200, 202, 0.4)";
      foregroundColor = "rgba(154, 200, 202, 1)";
      fontColor = "rgba(49, 73, 71, 1)";
      navigationTest = practiceScores.mindCategory ?? "Back";
      break;
    case 4:
      backgroundColor = "rgba(187, 218, 219, 0.4)";
      foregroundColor = "rgba(187, 218, 219, 1)";
      fontColor = "rgba(49, 73, 71, 1)";
      navigationTest = practiceScores.spiritCategory ?? "Back";
      break;
  }

  switch (outcomeSubcategoryID) {
    case 1:
      title = practiceScores.worldEcosystemCategory !== undefined ? practiceScores.worldEcosystemCategory : "";
      logoImage = practiceScores.worldEcosystemLogoURL !== undefined ? practiceScores.worldEcosystemLogoURL : "";
      score = practiceScores.worldEcosystem !== undefined ? practiceScores.worldEcosystem : 0;
      para1 = practiceScores.worldEcosystemPracticeDescription !== undefined ? practiceScores.worldEcosystemPracticeDescription : "";
      para2 = practiceScores.worldEcosystemDragonDescription !== undefined ? practiceScores.worldEcosystemDragonDescription : "";
      break;
    case 2:
      title = practiceScores.worldSafetyCategory !== undefined ? practiceScores.worldSafetyCategory : "";
      logoImage = practiceScores.worldSafetyLogoURL !== undefined ? practiceScores.worldSafetyLogoURL : "";
      score = practiceScores.worldSafety !== undefined ? practiceScores.worldSafety : 0;
      para1 = practiceScores.worldSafetyPracticeDescription !== undefined ? practiceScores.worldSafetyPracticeDescription : "";
      para2 = practiceScores.worldSafetyDragonDescription !== undefined ? practiceScores.worldSafetyDragonDescription : "";
      break;
    case 3:
      title = practiceScores.worldWorkCategory !== undefined ? practiceScores.worldWorkCategory : "";
      logoImage = practiceScores.worldWorkLogoURL !== undefined ? practiceScores.worldWorkLogoURL : "";
      score = practiceScores.worldWork !== undefined ? practiceScores.worldWork : 0;
      para1 = practiceScores.worldWorkPracticeDescription !== undefined ? practiceScores.worldWorkPracticeDescription : "";
      para2 = practiceScores.worldWorkDragonDescription !== undefined ? practiceScores.worldWorkDragonDescription : "";
      break;
    case 4:
      title = practiceScores.worldSocialTiesCategory !== undefined ? practiceScores.worldSocialTiesCategory : "";
      logoImage = practiceScores.worldSocialTiesLogoURL !== undefined ? practiceScores.worldSocialTiesLogoURL : "";
      score = practiceScores.worldSocialTies !== undefined ? practiceScores.worldSocialTies : 0;
      para1 = practiceScores.worldSocialTiesPracticeDescription !== undefined ? practiceScores.worldSocialTiesPracticeDescription : "";
      para2 = practiceScores.worldSocialTiesDragonDescription !== undefined ? practiceScores.worldSocialTiesDragonDescription : "";
      break;
    case 5:
      title = practiceScores.worldRelationshipsCategory !== undefined ? practiceScores.worldRelationshipsCategory : "";
      logoImage = practiceScores.worldRelationshipsLogoURL !== undefined ? practiceScores.worldRelationshipsLogoURL : "";
      score = practiceScores.worldRelationships !== undefined ? practiceScores.worldRelationships : 0;
      para1 =
        practiceScores.worldRelationshipsPracticeDescription !== undefined ? practiceScores.worldRelationshipsPracticeDescription : "";
      para2 = practiceScores.worldRelationshipsDragonDescription !== undefined ? practiceScores.worldRelationshipsDragonDescription : "";
      break;
    case 6:
      title = practiceScores.worldFinancesCategory !== undefined ? practiceScores.worldFinancesCategory : "";
      logoImage = practiceScores.worldFinancesLogoURL !== undefined ? practiceScores.worldFinancesLogoURL : "";
      score = practiceScores.worldFinances !== undefined ? practiceScores.worldFinances : 0;
      para1 = practiceScores.worldFinancesPracticeDescription !== undefined ? practiceScores.worldFinancesPracticeDescription : "";
      para2 = practiceScores.worldFinancesDragonDescription !== undefined ? practiceScores.worldFinancesDragonDescription : "";
      break;
    case 7:
      title = practiceScores.bodyHealthcareCategory !== undefined ? practiceScores.bodyHealthcareCategory : "";
      logoImage = practiceScores.bodyHealthcareLogoURL !== undefined ? practiceScores.bodyHealthcareLogoURL : "";
      score = practiceScores.bodyHealthcare !== undefined ? practiceScores.bodyHealthcare : 0;
      para1 = practiceScores.bodyHealthcarePracticeDescription !== undefined ? practiceScores.bodyHealthcarePracticeDescription : "";
      para2 = practiceScores.bodyHealthcareDragonDescription !== undefined ? practiceScores.bodyHealthcareDragonDescription : "";
      break;
    case 8:
      title = practiceScores.bodyAddictionPreventionCategory !== undefined ? practiceScores.bodyAddictionPreventionCategory : "";
      logoImage = practiceScores.bodyAddictionPreventionLogoURL !== undefined ? practiceScores.bodyAddictionPreventionLogoURL : "";
      score = practiceScores.bodyAddictionPrevention !== undefined ? practiceScores.bodyAddictionPrevention : 0;
      para1 =
        practiceScores.bodyAddictionPreventionPracticeDescription !== undefined
          ? practiceScores.bodyAddictionPreventionPracticeDescription
          : "";
      para2 =
        practiceScores.bodyAddictionPreventionDragonDescription !== undefined
          ? practiceScores.bodyAddictionPreventionDragonDescription
          : "";
      break;
    case 9:
      title = practiceScores.bodyExerciseCategory !== undefined ? practiceScores.bodyExerciseCategory : "";
      logoImage = practiceScores.bodyExerciseLogoURL !== undefined ? practiceScores.bodyExerciseLogoURL : "";
      score = practiceScores.bodyExercise !== undefined ? practiceScores.bodyExercise : 0;
      para1 = practiceScores.bodyExercisePracticeDescription !== undefined ? practiceScores.bodyExercisePracticeDescription : "";
      para2 = practiceScores.bodyExerciseDragonDescription !== undefined ? practiceScores.bodyExerciseDragonDescription : "";
      break;
    case 10:
      title = practiceScores.bodyNutritionCategory !== undefined ? practiceScores.bodyNutritionCategory : "";
      logoImage = practiceScores.bodyNutritionLogoURL !== undefined ? practiceScores.bodyNutritionLogoURL : "";
      score = practiceScores.bodyNutrition !== undefined ? practiceScores.bodyNutrition : 0;
      para1 = practiceScores.bodyNutritionPracticeDescription !== undefined ? practiceScores.bodyNutritionPracticeDescription : "";
      para2 = practiceScores.bodyNutritionDragonDescription !== undefined ? practiceScores.bodyNutritionDragonDescription : "";
      break;
    case 11:
      title = practiceScores.bodySleepCategory !== undefined ? practiceScores.bodySleepCategory : "";
      logoImage = practiceScores.bodySleepLogoURL !== undefined ? practiceScores.bodySleepLogoURL : "";
      score = practiceScores.bodySleep !== undefined ? practiceScores.bodySleep : 0;
      para1 = practiceScores.bodySleepPracticeDescription !== undefined ? practiceScores.bodySleepPracticeDescription : "";
      para2 = practiceScores.bodySleepDragonDescription !== undefined ? practiceScores.bodySleepDragonDescription : "";
      break;
    case 12:
      title = practiceScores.mindThoughtsCategory !== undefined ? practiceScores.mindThoughtsCategory : "";
      logoImage = practiceScores.mindThoughtsLogoURL !== undefined ? practiceScores.mindThoughtsLogoURL : "";
      score = practiceScores.mindThoughts !== undefined ? practiceScores.mindThoughts : 0;
      para1 = practiceScores.mindThoughtsPracticeDescription !== undefined ? practiceScores.mindThoughtsPracticeDescription : "";
      para2 = practiceScores.mindThoughtsDragonDescription !== undefined ? practiceScores.mindThoughtsDragonDescription : "";
      break;
    case 13:
      title = practiceScores.mindFeelingsCategory !== undefined ? practiceScores.mindFeelingsCategory : "";
      logoImage = practiceScores.mindFeelingsLogoURL !== undefined ? practiceScores.mindFeelingsLogoURL : "";
      score = practiceScores.mindFeelings !== undefined ? practiceScores.mindFeelings : 0;
      para1 = practiceScores.mindFeelingsPracticeDescription !== undefined ? practiceScores.mindFeelingsPracticeDescription : "";
      para2 = practiceScores.mindFeelingsDragonDescription !== undefined ? practiceScores.mindFeelingsDragonDescription : "";
      break;
    case 14:
      title = practiceScores.mindMemoriesCategory !== undefined ? practiceScores.mindMemoriesCategory : "";
      logoImage = practiceScores.mindMemoriesLogoURL !== undefined ? practiceScores.mindMemoriesLogoURL : "";
      score = practiceScores.mindMemories !== undefined ? practiceScores.mindMemories : 0;
      para1 = practiceScores.mindMemoriesPracticeDescription !== undefined ? practiceScores.mindMemoriesPracticeDescription : "";
      para2 = practiceScores.mindMemoriesDragonDescription !== undefined ? practiceScores.mindMemoriesDragonDescription : "";
      break;
    case 15:
      title = practiceScores.mindKnowledgeCategory !== undefined ? practiceScores.mindKnowledgeCategory : "";
      logoImage = practiceScores.mindKnowledgeLogoURL !== undefined ? practiceScores.mindKnowledgeLogoURL : "";
      score = practiceScores.mindKnowledge !== undefined ? practiceScores.mindKnowledge : 0;
      para1 = practiceScores.mindKnowledgePracticeDescription !== undefined ? practiceScores.mindKnowledgePracticeDescription : "";
      para2 = practiceScores.mindKnowledgeDragonDescription !== undefined ? practiceScores.mindKnowledgeDragonDescription : "";
      break;
    case 16:
      title = practiceScores.spiritCoreValueConnectionsCategory !== undefined ? practiceScores.spiritCoreValueConnectionsCategory : "";
      logoImage = practiceScores.spiritCoreValueConnectionsLogoURL !== undefined ? practiceScores.spiritCoreValueConnectionsLogoURL : "";
      score = practiceScores.spiritCoreValueConnections !== undefined ? practiceScores.spiritCoreValueConnections : 0;
      para1 =
        practiceScores.spiritCoreValueConnectionsPracticeDescription !== undefined
          ? practiceScores.spiritCoreValueConnectionsPracticeDescription
          : "";
      para2 =
        practiceScores.spiritCoreValueConnectionsDragonDescription !== undefined
          ? practiceScores.spiritCoreValueConnectionsDragonDescription
          : "";
      break;
    case 17:
      title = practiceScores.spiritSpiritualConnectionsCategory !== undefined ? practiceScores.spiritSpiritualConnectionsCategory : "";
      logoImage = practiceScores.spiritSpiritualConnectionsLogoURL !== undefined ? practiceScores.spiritSpiritualConnectionsLogoURL : "";
      score = practiceScores.spiritSpiritualConnections !== undefined ? practiceScores.spiritSpiritualConnections : 0;
      para1 =
        practiceScores.spiritSpiritualConnectionsPracticeDescription !== undefined
          ? practiceScores.spiritSpiritualConnectionsPracticeDescription
          : "";
      para2 =
        practiceScores.spiritSpiritualConnectionsDragonDescription !== undefined
          ? practiceScores.spiritSpiritualConnectionsDragonDescription
          : "";
      break;
    case 18:
      title = practiceScores.spiritSpiritualTransactionsCategory !== undefined ? practiceScores.spiritSpiritualTransactionsCategory : "";
      logoImage = practiceScores.spiritSpiritualTransactionsLogoURL !== undefined ? practiceScores.spiritSpiritualTransactionsLogoURL : "";
      score = practiceScores.spiritSpiritualTransactions !== undefined ? practiceScores.spiritSpiritualTransactions : 0;
      para1 =
        practiceScores.spiritSpiritualTransactionsPracticeDescription !== undefined
          ? practiceScores.spiritSpiritualTransactionsPracticeDescription
          : "";
      para2 =
        practiceScores.spiritSpiritualTransactionsDragonDescription !== undefined
          ? practiceScores.spiritSpiritualTransactionsDragonDescription
          : "";
      break;
  }
  appContext.setNavigationMenuButtonText(navigationTest);

  if (practiceScores !== null) {
    return (
      <div className="pd-container">
        <h1>{title}</h1>
        <img src={RequireSeityImage(logoImage).default} alt="practice detail logo" />
        <div style={{ background: backgroundColor }} className="pd-container-background">
          <div className="pd-container-background-score">
            {score}%
            <SeityProgressBar
              progress={score}
              trackColor="rgba(49, 73, 71, 1)"
              color="rgba(255, 248, 235, 1)"
              height={10}
              containerStyle="pd-container-background-progress-bar"
            />
          </div>
          <div style={{ background: foregroundColor, color: fontColor }} className="pd-container-foreground">
            <h6>{para1}</h6>
            <b>Improve Your Score</b>
            <h6>{para2}</h6>
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};
