import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import { PlotlineTemplateID } from "../../../../api/plotlineTypes";

export type PlotlineTemplateProgressBarProps = {
  plotlineTemplateID: PlotlineTemplateID | null;
  showBackButton: boolean;
  progressBarPercentage: number;
  onBack?: () => void;
};

export const PlotlineTemplateProgressBar: FunctionComponent<PlotlineTemplateProgressBarProps> = (props): ReactElement => {
  const { showBackButton, progressBarPercentage, plotlineTemplateID, onBack } = props;

  const imgSrc = [
    PlotlineTemplateID.FullPageContentBottomAlignedImage01,
    PlotlineTemplateID.FullPageContentBottomAlignedVideo01,
    PlotlineTemplateID.FullPageContentTopAlignedImage01,
    PlotlineTemplateID.FullPageContentTopAlignedVideo01
  ].includes(plotlineTemplateID ?? 1)
    ? require("../../../../assets/graphics/back-arrow-white.png").default
    : require("../../../../assets/graphics/back-arrow-black.png").default;

  return (
    <div className={`progress-bar-body progress-bar-body-${plotlineTemplateID}`}>
      {showBackButton && (
        <button onClick={onBack}>
          <img className="back-arrow" src={imgSrc} />
        </button>
      )}
      <div className="progress-bar-outer">
        <div className="progress-bar-inner" style={{ width: `${progressBarPercentage}%` }} />
      </div>
      <p className="percentage">{progressBarPercentage}%</p>
    </div>
  );
};

export default PlotlineTemplateProgressBar;
