import moment from "moment";

import strings from "../../../../_core/strings/strings";
import "./../styles.scss";

interface CheckInDateProps {
  graphIndex: number;
  graphDateArray: string[];
}

export const CheckInDate = ({ graphIndex, graphDateArray }: CheckInDateProps) => {
  const dateString = graphDateArray[graphIndex];
  const checkInDate = new Date(dateString);
  let displayDate = moment(checkInDate).format("MMM Do h:mm A");
  if (!dateString) {
    displayDate = "No Check-in";
  }

  return (
    <div className="cig-check-in">
      <div>{strings.selectedCheckIn}</div>
      <div className="cig-check-in-date">
        <div>{displayDate}</div>
      </div>
    </div>
  );
};
