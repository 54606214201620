import { FunctionComponent } from "react";
import Modal from "react-modal";
import Carousel from "react-elastic-carousel";

import { getNewLineText } from "../../../app/utils";
import strings from "../../strings/strings";

import "./styles.scss";

type SeityIdkModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SeityIdkModal: FunctionComponent<SeityIdkModalProps> = ({ isOpen, onClose }) => {
  Modal.setAppElement("#root");

  const renderArrow = ({ type, onClick, isEdge }) => {
    if (isEdge) {
      return <></>;
    }
    return (
      <button className="btn-next flex-center" onClick={onClick} disabled={isEdge}>
        {/* eslint-disable-next-line max-len */}
        <img
          src={
            type === "PREV"
              ? require("../../../assets/graphics/journals/icon-forward.png").default
              : require("../../../assets/graphics/journals/icon-forward.png").default
          }
          width={20}
          alt="forward btn"
        />
      </button>
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="seity-idk-modal" overlayClassName="seity-idk-overlay">
      <button
        className="close-button"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <img src={require("../../../assets/graphics/journals/close.png").default} alt="close button" />
      </button>
      <Carousel pagination={false} className="seity-idk-carousel" itemPadding={[0, 0]} renderArrow={renderArrow} isRTL={false}>
        <div className="h-100 flex-col">
          <p style={{ height: "30px" }}>{strings.idkDescStep1}</p>
          <img src={require("../../../assets/graphics/journals/idk-1.png").default} width={415} alt="idk step 1" />
        </div>
        <div className="h-100 flex-col">
          <h5>{getNewLineText(strings.idkDescStep2)}</h5>
          <img src={require("../../../assets/graphics/journals/idk-2.png").default} width={415} alt="idk step 2" />
        </div>
        <div className="h-100 flex-col">
          <p>{getNewLineText(strings.idkDescStep3)}</p>
          <img src={require("../../../assets/graphics/journals/idk-3.png").default} width={415} alt="idk step 3" />
        </div>
      </Carousel>
    </Modal>
  );
};

export default SeityIdkModal;
