import Modal from "react-modal";
import { FunctionComponent, ReactElement } from "react";
import classNames from "classnames";

import "./styles.scss";
import SeityButton from "../SeityButton";

export type SeityModalProps = {
  isOpen: boolean;
  showCloseBtn?: boolean;
  imgCard?: string;
  title?: string;
  text?: string;
  style?: object;
  children?: ReactElement;
  overlayClassName?: string;
  wrapperClassName?: string;
  actionButtons?: Array<{ type: "cancel" | "submit" | "plain-text"; label: string }>;
  afterOpenModal?: () => void;
  onClose?: () => void;
  onClickActions?: (idx: number) => void;
};

export const SeityModal: FunctionComponent<SeityModalProps> = ({
  isOpen = false,
  showCloseBtn = false,
  imgCard,
  title = "",
  text = "",
  style,
  children,
  overlayClassName = "",
  wrapperClassName = "",
  actionButtons,
  afterOpenModal = () => {},
  onClose = () => {},
  onClickActions
}): ReactElement => {
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      className={classNames("seity-modal", {
        [wrapperClassName]: wrapperClassName
      })}
      overlayClassName={classNames("seity-modal-overlay", {
        [overlayClassName]: overlayClassName
      })}
      contentLabel="Seity Modal"
      style={style && { content: { ...style } }}
    >
      {showCloseBtn && (
        <button
          className="ml-auto mb-xl"
          onClick={(e) => {
            onClose();
          }}
        >
          <img src={require("../../../assets/graphics/today/close.png").default} width={24} height={24} />
        </button>
      )}
      {imgCard && <img className="seity-modal__imgCard mb-xl" src={imgCard} />}
      {title?.length > 0 && <h2>{title}</h2>}
      {text?.length > 0 && <p>{text}</p>}
      {actionButtons && actionButtons.length > 0 && (
        <div className="seity-modal__btnWrap">
          {actionButtons.map((btn, idx) => (
            <SeityButton
              key={idx}
              label={btn.label}
              type={btn.type}
              onClick={() => {
                if (onClickActions) {
                  onClickActions(idx);
                }
              }}
            />
          ))}
        </div>
      )}
      {children}
    </Modal>
  );
};

export default SeityModal;
