import React from "react";
import "./styles.scss";
export interface ButtonProps {
  disabled?: boolean;
  type?:
    | "submit"
    | "reset"
    | "button"
    | "black"
    | "next"
    | "cancel"
    | "dark"
    | "link"
    | "red"
    | "discard"
    | "plain-text"
    | "inline"
    | "outline"
    | "inline-cancel";
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  label?: string;
  testId?: string;
  title?: string;
  style?: object;
  image?: string;
  className?: string;
}

export const SeityButton = (props: ButtonProps) => {
  const { disabled, type, onClick, label, testId, title, image, style, className = "" } = props;
  let buttonClassName;

  switch (type) {
    case "red":
      buttonClassName = "seity-button__red";
      break;
    case "cancel":
      buttonClassName = "seity-button__cancel ";
      break;
    case "dark":
      buttonClassName = "seity-button__dark";
      break;
    case "link":
      buttonClassName = "seity-button__link";
      break;
    case "discard":
      buttonClassName = "seity-button__discard";
      break;
    case "black":
      buttonClassName = "seity-button__black";
      break;
    case "plain-text":
      buttonClassName = "seity-button__plain-text";
      break;
    case "inline":
      buttonClassName = "seity-button__inline";
      break;
    case "inline-cancel":
      buttonClassName = "seity-button__inline-cancel";
      break;
    case "outline":
      buttonClassName = "seity-button__outline";
      break;
    default:
      buttonClassName = "seity-button";
      break;
  }

  return (
    <button style={style} disabled={disabled} title={title} onClick={onClick} className={`${buttonClassName} ${className}`} id={testId}>
      {image && image.length > 0 && <img src={image} />}
      {label}
    </button>
  );
};

export default SeityButton;
