import { FunctionComponent } from "react";
import Modal from "react-modal";

import strings from "../../../_core/strings/strings";

import "./styles.scss";

type ConfirmDeleteModalProps = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export const ConfirmDeleteModal: FunctionComponent<ConfirmDeleteModalProps> = ({ isOpen, onDelete, onCancel, onClose }) => {
  Modal.setAppElement("#root");
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="intentions-delete-modal" overlayClassName="intentions-delete-overlay">
      <h3>{strings.deleteIntention}</h3>
      <p>{strings.deleteConfirmDesc}</p>
      <div className="mt-auto ml-auto">
        <button onClick={onCancel}>{strings.cancelButtonLabel}</button>
        <button onClick={onDelete}>{strings.delete}</button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
