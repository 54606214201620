export default {
  coreOrange: "#F75A3B",
  seityWhite01: "#FFFFFF",
  white: "#FFFFFF",
  black: "black",

  //red
  red: "#DC2F02",
  red02100: "rgba(206,81,72,1.0)",
  red02100g: (opacity = 1) => `rgba(206, 81, 72, ${opacity})`,
  persimmon: "#F86B4F",

  //yellow
  seityYellow: "#FDCB5D",
  yellow01100: "rgba(253, 203, 93, 1.0)", // same as seityYellow

  //blue
  blue01100: "rgba(101, 177, 224, 1.0)",
  blue01100g: (opacity = 1) => `rgba(101, 177, 224, ${opacity})`,

  //green
  darkGreen: "#41625E",
  blackGreen: "#314947",
  seityDarkGreen: "#314947",
  seityDarkGreen01: "#2F696C",
  green01100: "rgba(65, 98, 94, 1.0)", // same as darkGreen
  green02100: "rgba(49, 73, 71, 1.0)", // same as seityDarkGreen
  green02030: "rgba(49, 73, 71, 0.3)",
  green04100: "rgba(144, 194, 105, 1.0)",
  green04100g: (opacity = 1) => `rgba(144, 194, 105, ${opacity})`,

  //cream
  cream: "#FFF8EB",
  cream01100: "rgba(255, 248, 235, 1.0)", // same as cream

  //teal
  teal: "#56A3A6",
  teal02100: "rgba(47, 105, 108, 1.0)",
  teal02030: "rgba(47, 105, 108, 0.3)",
  teal03100: "rgba(86, 163, 166, 1.0)",
  teal03040: "rgba(86, 163, 166, 0.4)",
  teal04100: "rgba(154, 200, 202, 1.0)",
  teal04040: "rgba(154, 200, 202, 0.4)",
  teal05100: "rgba(187, 218, 219, 1.0)",
  teal05040: "rgba(187, 218, 219, 0.4)",

  //grey
  gray: "#808080",
  gray01: "#c1cdc5",
  grey03050: "rgba(190, 191, 186, 0.5)",
  grey01050: "rgba(183,181,172, 0.5)",
  dragonGrey: "#5a6b6a",
  dragonLightGrey: "#7c8b89",
  dragonDarkGrey: "#424c4a",

  //pink
  pink01100: "rgba(243, 143, 194, 1.0)",
  pink01100g: (opacity = 1) => `rgba(243, 143, 194, ${opacity})`
};

// export const seityCream = '#000000'; //cream color for bubble background

// export const seityTeal = '#56A3A6'; //teal color of button

// export const seityYellow = '#FDCB5D';

// export const errorRed = '#DC2F02'; //red for errors
