import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { PracticeScoreSubCategory } from "../../../../api/account/types/practiceScoresTypes";

import "../styles.scss";

const dragonItemImage = require("../../../../assets/graphics/caret_white.png").default;

interface DragonItemProps {
  dragon: PracticeScoreSubCategory[];
  dragonImage: string;
  dragonName: string;
  backgroundColor: string;
  dragonCount: number;
  dragonDescription: string;
  navigationPath: string;
}

export const DragonItem: FunctionComponent<DragonItemProps> = ({
  dragon,
  dragonImage,
  dragonName,
  backgroundColor,
  dragonCount,
  dragonDescription,
  navigationPath
}) => {
  const history = useHistory();

  const handleNavigation = () => {
    history.push(navigationPath);
  };

  if (dragon !== undefined) {
    return (
      <div className="tyd-item" style={{ backgroundColor }} onClick={handleNavigation}>
        <div className="tyd-item-container">
          <img className="tyd-item-icon" src={dragonImage} alt="Item icon" />
          <div className="tyd-item-content">
            <div>{dragonName}s</div>
            <div className="tyd-item-content-score">
              <b>{dragonCount}</b>
              <div>{dragonDescription}</div>
            </div>
          </div>
        </div>
        <img className="tyd-item-caret" src={dragonItemImage} alt="caret" />
      </div>
    );
  }
  return <></>;
};
