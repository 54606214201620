import { FunctionComponent, ReactElement, useMemo } from "react";

import { CoreValuesBubbleProps } from "./types";

import "./styles.scss";
import SeityCVImage from "../../../../_core/components/SeityCVImage";

export const CoreValuesBubble: FunctionComponent<CoreValuesBubbleProps> = (props): ReactElement => {
  const { canSelect, selected, height, heading, description } = props;
  const opacity = useMemo(() => {
    return canSelect || selected ? "100" : "50%";
  }, [selected, canSelect]);
  const background = useMemo(() => {
    return selected ? "#2f696c" : "rgba(190, 191, 186, 0.5)";
  }, [selected]);

  const image = useMemo(() => {
    return <SeityCVImage cvId={props.cvCateId} size={height / 2} />;
  }, [props.cvCateId]);

  const fontSize = useMemo(() => {
    return 16 * (height / 215);
  }, [height]);

  const handleClick = () => {
    props.handleSelect(props.id, !props.selected, props.canSelect);
  };

  return (
    <div className="bubble-wrapper" onClick={handleClick} style={{ height }}>
      <div className="bubble-content" style={{ opacity, background }}>
        {!selected && (
          <div className="bubble-wrapper-not-clicked">
            {image}
            <h4 style={{ fontSize: fontSize + 3 }}>{heading}</h4>
          </div>
        )}
        {selected && (
          <div className="bubble-wrapper-clicked">
            <div>
              <p style={{ fontSize }}>{description}</p>
            </div>
            <h4 style={{ fontSize: fontSize + 3 }}>{heading}</h4>
            <img src={require("../../../../assets/graphics/checkmark.png").default} alt="core value" style={{ height: height * 45 / 205 }} />
          </div>
        )}
      </div>
    </div>
  );
};
