import { CVAffirmation } from "../../../api/today/types/todayTypes";
import { Affirmation } from "../types";

export const formatAffirmationData = (apiData: CVAffirmation) => {
  const result: Array<Affirmation> = [];

  result.push(
    ...[
      {
        coreValueID: apiData.coreValue1ID,
        coreValue: apiData.coreValue1,
        coreValueAffirmationID: apiData.coreValueAffirmation1ID,
        coreValueAffirmation: apiData.coreValueAffirmation1
      },
      {
        coreValueID: apiData.coreValue2ID,
        coreValue: apiData.coreValue2,
        coreValueAffirmationID: apiData.coreValueAffirmation2ID,
        coreValueAffirmation: apiData.coreValueAffirmation2
      },
      {
        coreValueID: apiData.coreValue3ID,
        coreValue: apiData.coreValue3,
        coreValueAffirmationID: apiData.coreValueAffirmation3ID,
        coreValueAffirmation: apiData.coreValueAffirmation3
      },
      {
        coreValueID: apiData.coreValue4ID,
        coreValue: apiData.coreValue4,
        coreValueAffirmationID: apiData.coreValueAffirmation4ID,
        coreValueAffirmation: apiData.coreValueAffirmation4
      }
    ]
  );

  return result;
};

export const getAffirmationContent = (apiData: CVAffirmation) => {
  const items = formatAffirmationData(apiData);
  const selItem = items.find((i) => i.coreValueAffirmationID === apiData.selectedCoreValueAffirmationID);

  return selItem?.coreValueAffirmation;
};
