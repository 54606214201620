/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent } from "react";

import { AnswersProps, RadioButtonProps } from "./types";

import strings from "../../strings/strings";

export const Answers: FunctionComponent<AnswersProps> = ({ questionTypeId, answerLevel, questionId, onChange, answerSelections }) => {
  const isChecked = (answerLevel: number, value: number): boolean => {
    return answerLevel === value;
  };

  const setId = (questionId: number, value: string): string => {
    return `${questionId.toString()}-${value}`;
  };

  const RadioButton: FunctionComponent<RadioButtonProps> = ({ id, label }) => {
    return (
      <div className="input-div">
        <input
          className="radio-btn"
          type="radio"
          id={setId(questionId, `${id}`)}
          name={questionId.toString()}
          value={id}
          defaultChecked={isChecked(answerLevel, id)}
          onChange={() => {
            return onChange(questionId, id);
          }}
        />
        <label htmlFor={setId(questionId, `${id}`)}>{label}</label>
      </div>
    );
  };

  if (answerSelections?.length) {
    return (
      <div>
        {answerSelections.map((answerSelection) => {
          return <RadioButton id={answerSelection.answerLevel} label={answerSelection.answerText} />;
        })}
      </div>
    );
  }

  switch (questionTypeId) {
    case 3:
      return (
        <div>
          <RadioButton id={1} label={strings["0day"]} />
          <RadioButton id={2} label={strings["1day"]} />
          <RadioButton id={3} label={strings["2days"]} />
          <RadioButton id={4} label={strings["3days"]} />
          <RadioButton id={5} label={strings["4days"]} />
          <RadioButton id={6} label={strings["5days"]} />
          <RadioButton id={7} label={strings["6days"]} />
          <RadioButton id={8} label={strings["7days"]} />
        </div>
      );
    case 5:
      return (
        <div>
          <RadioButton id={1} label={strings.never} />
          <RadioButton id={2} label={strings.notVeryOften} />
          <RadioButton id={3} label={strings.sometimes} />
          <RadioButton id={4} label={strings.often} />
          <RadioButton id={5} label={strings.usually} />
          <RadioButton id={6} label={strings.always} />
        </div>
      );
    case 6:
      return (
        <div>
          <RadioButton id={1} label={strings.never} />
          <RadioButton id={2} label={strings.oneDay} />
          <RadioButton id={3} label={strings.aFewDays} />
          <RadioButton id={4} label={strings.halfTheDays} />
          <RadioButton id={5} label={strings.almostDaily} />
          <RadioButton id={6} label={strings.daily} />
        </div>
      );
    case 7:
    case 11:
      return (
        <div>
          <RadioButton id={1} label={strings.true} />
          <RadioButton id={2} label={strings.false} />
        </div>
      );
    case 9:
      return (
        <div>
          <RadioButton id={1} label={strings.none} />
          <RadioButton id={2} label={strings.One} />
          <RadioButton id={3} label={strings.Two} />
          <RadioButton id={4} label={strings.Three} />
          <RadioButton id={5} label={strings.Four} />
          <RadioButton id={6} label={strings.Five} />
          <RadioButton id={7} label={strings.Six} />
          <RadioButton id={8} label={strings.sevenOrMore} />
        </div>
      );
    case 10:
      return (
        <div>
          <RadioButton id={1} label={strings.none} />
          <RadioButton id={2} label={strings.Day1} />
          <RadioButton id={3} label={strings.twoDays} />
          <RadioButton id={4} label={strings.threeDays} />
          <RadioButton id={5} label={strings.fourDays} />
          <RadioButton id={6} label={strings.fiveDays} />
          <RadioButton id={7} label={strings.sixDays} />
          <RadioButton id={8} label={strings.sevenOrMoreDays} />
        </div>
      );
    default:
      return <div className="no-answers-text">{strings.noAnswersFound}</div>;
  }
};
