import { FunctionComponent, useState } from "react";
import SeityButton from "../../../_core/components/SeityButton";
import "./styles.scss";
import SeityModal from "../../../_core/components/SeityModal";
import CoreStrings from "../../../_core/strings/strings";
import { DisclosureModalContent } from "../familySharingSlice";

type FSHtmlModalProps = {
  content: DisclosureModalContent;
  onClose: () => void;
  onContinue: () => void;
};

export const FSHtmlModal: FunctionComponent<FSHtmlModalProps> = ({ onClose, onContinue, content }) => {
  const [checked, setChecked] = useState(false);

  return (
    <SeityModal isOpen={true} showCloseBtn={true} onClose={onClose} overlayClassName="fsi-modal-overlay">
      <div className="fsi-modal-text-container">
        <h2>{content.header}</h2>
        <div className="fsi-modal-policy-container" dangerouslySetInnerHTML={{ __html: content.content }} />
        <div className="fsi-modal-button-container">
          <label className="fsi-modal-checkbox">
            <input type="checkbox" name="myCheckbox" checked={checked} onChange={() => setChecked(!checked)} /> {content.checkboxCopy}
          </label>
          <SeityButton className="fsi-modal-button" onClick={onContinue} type="next" disabled={!checked} label={CoreStrings.continue} />
        </div>
      </div>
    </SeityModal>
  );
};
