import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { getRefreshedToken } from "../../features/auth/UseSessionStorageToken";

const SECTION = "/CoreValueDirectory/";

interface UpdateToggleRequest {
  coreValueDirectoryDisplayNameEnabled: boolean | null;
  coreValueDirectoryCoreValuesEnabled: boolean | null;
  doNotShowCoreValueDirectoryModalPromptClicked: boolean | null;
}

export async function setToggles(request: UpdateToggleRequest) {
  const url = BASE_URL + SECTION + "Toggles";
  const { token } = await getRefreshedToken();
  const postCategoryResponse = await axios.put(url, request, {
    headers: {
      Authorization: "Bearer " + token
    }
  });

  return postCategoryResponse.data;
}