import { createSlice } from "@reduxjs/toolkit";
import {
  CongratData,
  CongratulationsGetResponse,
  DICongratulationsType,
  Intention,
  IntentionListResponse,
  IntentionSelListResponse,
  IntentionSelected,
  PutIntentionType
} from "../../api/dailyIntention/intentionTypes";
import { ASSET_IMG_URL, INTENTION_IMG_URL } from "../../api/apiConstants";
import { formatImagePath } from "../../api/utils";
import apiClientWithAuth from "../../api/apiClient";

export interface IntentionsState {
  readonly error: string | null;
  readonly isLoading: boolean;
  intentionSelList: Array<IntentionSelected>;
  intentionList: Array<Intention>;
  commitMsg: CongratData | null;
  completeMsg: CongratData | null;
}

const setInitalState = {
  error: null,
  isLoading: false,
  intentionSelList: [],
  intentionList: [],
  commitMsg: null,
  completeMsg: null
} as IntentionsState;

const intentionsSlice = createSlice({
  name: "practices-practices",
  initialState: setInitalState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIntentionSelList(state, action) {
      if (action.payload.isReload) {
        state.intentionSelList = action.payload.list;
      } else {
        state.intentionSelList = [...state.intentionSelList, ...action.payload.list];
      }
    },
    setIntentionList(state, action) {
      if (action.payload.isReload) {
        state.intentionList = action.payload.list;
      } else {
        state.intentionList = [...state.intentionList, ...action.payload.list];
      }
    },
    setCommitMsg(state, action) {
      state.commitMsg = action.payload;
    },
    setCompleteMsg(state, action) {
      state.completeMsg = action.payload;
    },
    resetIntentionsSlice: () => setInitalState
  }
});

export const { clearError, setIsLoading, setError, setIntentionSelList, setIntentionList, setCompleteMsg, setCommitMsg } =
  intentionsSlice.actions;

export default intentionsSlice.reducer;

const handleError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setError({ error: err.toString() }));
};

export const getIntentionListRequest = (callback?: (data: Array<Intention>) => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));

    const res = await apiClientWithAuth.get<IntentionListResponse>("DailyIntentions");
    dispatch(setIsLoading(false));
    if (res.data.success) {
      const ret = res.data.data?.map((i) => {
        i.imageFileName = INTENTION_IMG_URL + i.imageFileName;
        i.outcomeCategoryImage = formatImagePath(i.outcomeCategoryImage, "~/graphics/", ASSET_IMG_URL);
        i.outcomeSubcategoryImage = formatImagePath(i.outcomeSubcategoryImage, "~/graphics/", ASSET_IMG_URL);

        return i;
      });

      dispatch(setIntentionList({ list: ret }));
      if (callback) {
        callback(ret);
      }
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getIntentionSelListRequest =
  (range?: { start: number; end: number }, callback?: (data: Array<IntentionSelected>) => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const rg = range ? `?start=${range?.start}&end=${range?.end}` : "";
      const res = await apiClientWithAuth.get<IntentionSelListResponse>("DailyIntentions/Selected" + rg);
      dispatch(setIsLoading(false));
      let ret: IntentionSelected[] = [];
      if (res.data.success) {
        ret = res.data.data?.map((i) => {
          i.imageFileName = INTENTION_IMG_URL + i.imageFileName;
          i.outcomeCategoryImage = formatImagePath(i.outcomeCategoryImage, "~/graphics/", ASSET_IMG_URL);
          i.outcomeSubcategoryImage = formatImagePath(i.outcomeSubcategoryImage, "~/graphics/", ASSET_IMG_URL);

          return i;
        });

        dispatch(setIntentionSelList({ list: ret, isReload: range?.start === 0 }));
      }
      if (callback) {
        callback(ret);
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

// Intention Card Select Post/Put/Detete API
export const postIntentionRequest = (param: any, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.post("DailyIntentions", param);
    dispatch(setIsLoading(false));
    if (!res.data.success) {
      handleError("Intention post failed", dispatch);
    }
    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const putIntentionRequest = (param: PutIntentionType, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.put("DailyIntentions", param);
    dispatch(setIsLoading(false));
    if (!res.data.success) {
      handleError("Intention put failed", dispatch);
    }
    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const deleteIntentionRequest = (dailyIntentionAccountId: number, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete("DailyIntentions", {
      data: { dailyIntentionAccountId }
    });
    dispatch(setIsLoading(false));
    if (!res.data.success) {
      handleError("Intention delete failed", dispatch);
    }
    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

// Congratulations API
export const getCongratulationsRequest = (congratulationsType: DICongratulationsType) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get<CongratulationsGetResponse>(
      "DailyIntentions/Congratulations" + "?congratulationsType=" + congratulationsType
    );
    dispatch(setIsLoading(false));
    if (res.data.success) {
      if (congratulationsType === DICongratulationsType.Commit) {
        dispatch(setCommitMsg(res.data.data));
      } else {
        dispatch(setCompleteMsg(res.data.data));
      }
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};
