import { FunctionComponent } from "react";
import "./styles.scss";
import { RankingsTable } from "../RankingsTable";
import { DailyCheckInParticipation, OverallStanding } from "../types";
import { OverallStandings } from "../OverallStandings";
import CoreStrings from "../../../_core/strings/strings";

type ParticipationWidgetProps = {
  participationData: null | DailyCheckInParticipation;
  overallStandings: null | OverallStanding;
  lastUpdated: string | null;
  refreshFunction: any;
  isLoading: boolean;
};

const RefreshData = ({ lastUpdated, refreshFunction, isLoading }) => {
  return (
    <div className="refresh-data">
      {lastUpdated && <p className="last-updated">{isLoading ? "Refreshing..." : `${CoreStrings.lastUpdated} ${lastUpdated}`}</p>}
      <div onClick={isLoading ? null : refreshFunction}>
        <img className={isLoading ? "rotating" : ""} src={require("../../../assets/icons/refresh.svg").default} alt="refresh" />
      </div>
    </div>
  );
};

export const ParticipationWidget: FunctionComponent<ParticipationWidgetProps> = ({
  participationData,
  overallStandings,
  lastUpdated,
  refreshFunction,
  isLoading
}) => {
  return (
    <div className="participation-widget-container">
      <div className="header">
        <h1>{CoreStrings.rankings}</h1>
        <RefreshData lastUpdated={lastUpdated} refreshFunction={refreshFunction} isLoading={isLoading} />
      </div>
      {participationData && (
        <div className="daily-checkin">
          <div className="daily-checkin-header">
            <h3>{participationData?.title}</h3>
            <img className="more-info" src={require("../../../assets/graphics/more-info.png").default} />
          </div>
          <h5>{participationData?.fullDescription}</h5>
          <RankingsTable columnHeaders={participationData?.columnHeaders} schoolList={participationData?.participationData} />
        </div>
      )}
      {participationData && overallStandings && <hr />}
      {overallStandings && <OverallStandings overallStandings={overallStandings} />}
    </div>
  );
};
