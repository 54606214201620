/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent } from "react";
import SeityModal from "../SeityModal";
import { SeityButton } from "../SeityButton";

import strings from "../../strings/strings";
import { SeityAlertProps } from "./types";

import "./styles.scss";

export const SeityAlert: FunctionComponent<SeityAlertProps> = (props) => {
  const { visible = false, title = "Alert", subTitle = "", onToggle } = props;
  return (
    <SeityModal isOpen={visible} onClose={onToggle} wrapperClassName="seity-alert-modal">
      <div className="seity-alert d-flex flex-column align-items-center">
        <p className="seity-alert__title">{title}</p>
        <p className="seity-alert__subtitle">{subTitle}</p>
        <SeityButton label={strings.okay} onClick={onToggle} className="seity-alert-button" />
      </div>
    </SeityModal>
  );
};

export default SeityAlert;
