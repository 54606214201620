import React from "react";

import { getRefreshedToken } from "../features/auth/UseSessionStorageToken";
import { setRedirectToLogin } from "../features/auth/authSlice";
import { SeityAuthenticationError } from "../api/authTypes";
import { toast } from "react-toastify";
import moment from "moment";
import strings from "../_core/strings/strings";

export const validateToken = async (dispatch: any) => {
  const { token } = await getRefreshedToken();
  if (token === null) {
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    // the function will not return token since it is blocked here and redirect to the login page.
    throw new SeityAuthenticationError();
  }
  return token;
};

export const getResourceUrl = (url: string) => {};

export const toastError = (msg?: string) => {
  toast.error(msg ?? strings.genericErrorMsg, {
    position: "top-center",
    containerId: "general-noti",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
    // progress: 1,
  });
};

export const toastSuccess = (msg: string) => {
  toast.success(msg, {
    position: "top-center",
    containerId: "general-noti",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
    // progress: 1,
  });
};


const CustomToastContent = ({header = "Default Header", body = "Default Body"}) => {
  return (
    <div>
      <strong>{header}</strong>
      <p>{body}</p>
    </div>
  );
}

export const toastSuccessWithDetails = (header, body) => {
  toast.success(
    <CustomToastContent header={header} body={body}/>, {
      position: "top-center",
      containerId: "general-noti",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
};

export const toastErrorWithDetails = (header, body) => {
  toast.error(<CustomToastContent header={header} body={body}/>, {
    position: "top-center",
    containerId: "general-noti",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const toastCVConnection = (msg: string, cvId: number) => {
  const icon = React.createElement("img", { src: require(`../assets/corevalues/${cvId}_off.png`).default, width: 45, height: 45 }, null);
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    containerId: "cv-noti",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    // progress: 1,
    closeButton: false,
    theme: "colored",
    icon: icon
  });
};

export const getNewLineText = (str: string) => {
  return str.split("\n").map((s) => {
    return React.createElement("p", null, s);
  });
};

export const getLanguageStr = (code: number) => {
  return code === 1 ? "en-US" : "es";
};

export const switchLanguage = (code: number) => {
  const langStr = getLanguageStr(code);
  window.localStorage.setItem("language", langStr);
  strings.setLanguage(langStr);
  moment.locale(langStr === "en-US" ? "en" : langStr);
};

export const isValidHttpUrl = (val) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(val);
};
