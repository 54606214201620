/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import { FunctionComponent, ReactElement } from "react";

import { ASSET_IMG_URL } from "../../../api/apiConstants";
import { JournalEmotion } from "../../../api/individualJournal/journalTypes";
import CoreStrings from "../../../_core/strings/strings";

type EmotionButtonProps = {
  emotions: Array<JournalEmotion>;
  visibleCount?: number;
  handleButtonClick: () => void;
};

export const EmotionButton: FunctionComponent<EmotionButtonProps> = (props): ReactElement => {
  const { emotions, visibleCount = 4, handleButtonClick } = props;

  if (emotions.length > 0) {
    return (
      <button
        className="journal-entry__feelBtn flex-ai-c"
        onClick={() => {
          handleButtonClick();
        }}
      >
        <span className="mr-md">{CoreStrings.iFeel}</span>
        {emotions.slice(0, visibleCount).map((e) => {
          return <img key={e.emotionID} className="mr-md" src={`${ASSET_IMG_URL}${e.icon02}`} alt="emotion" width={40} />;
        })}
        {emotions.length > 3 && <span>...</span>}
      </button>
    );
  }
  return (
    <button
      className="journal-entry__feelBtn flex-ai-c"
      onClick={() => {
        handleButtonClick();
      }}
    >
      <img src={require("../../../assets/graphics/journals/happy.png").default} alt="Check in button" />
      <span className="ml-md">{CoreStrings.iFeel}</span>
    </button>
  );
};

export default EmotionButton;
