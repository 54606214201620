import { SeityDepartment, SeityJobPosition } from "../../../api/account/types/accountTypes";
import { SeityAccountListsItemResponse } from "../../../api/account/types/maintenanceTypes";
import strings from "../../../_core/strings/strings";

export function validPassword(password: string): boolean {
  if (!/\d/.test(password) || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || password.length < 8) {
    return false;
  }

  return true;
}

/*
at least one character before "@" symbol
at least one character between "@" and "."
at least one character after "."
*/
export function validEmailAddress(emailAddress: string) {
  if (!/(.)+@(.)+\.(.)/.test(emailAddress)) {
    return false;
  }

  return true;
}

export const getValueByList = (list: SeityAccountListsItemResponse[], id: number) => list?.find((x) => x.listID === id)?.description;

export const getDepartmentValue = (list: SeityDepartment[], id: number | null) =>
  id && id > 0 ? list.find((x) => x.departmentID === id)?.departmentName : strings.choose;

export const getJobPositionValue = (list: SeityJobPosition[], id: number | null) =>
  id && id > 0 ? list.find((x) => x.jobPositionID === id)?.jobPositionName : strings.choose;
