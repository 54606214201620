import { createSlice } from "@reduxjs/toolkit";
import { WellbeingResponse, WellbeingResponseData } from "./types";
import apiClientWithAuth from "../../api/apiClient";

export interface WellbeingState {
  readonly isLoading: boolean;
  readonly wellbeingError: string | null;
  readonly data: WellbeingResponseData;
}

const setInitialState = {
  isLoading: false,
  wellbeingError: null,
  data: {}
} as WellbeingState;

const wellbeingSlice = createSlice({
  name: "wellbeing",
  initialState: setInitialState,
  reducers: {
    setWellbeingResponse(state, action) {
      state.data = action.payload.data;
      state.isLoading = false;
      state.wellbeingError = null;
    },
    // If a value is not passed, it's assumed
    // to be true
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setWellbeingError(state, action) {
      state.wellbeingError = action.payload.wellbeingError;
      state.isLoading = false;
    },
    resetWellbeingSlice: () => setInitialState,
    clearWellbeingError(state) {
      state.wellbeingError = null;
    }
  }
});

export const { setIsLoading, setWellbeingError, setWellbeingResponse, resetWellbeingSlice, clearWellbeingError } = wellbeingSlice.actions;
export default wellbeingSlice.reducer;

export const sendGetWellbeingOnlyRequest = (callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({ isLoading: true }));
    const res = await apiClientWithAuth.get<WellbeingResponse>("/WellbeingOnly");
    dispatch(setIsLoading({ isLoading: false }));
    if (res.data.success) {
      dispatch(
        setWellbeingResponse({
          data: res.data.data
        })
      );
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

function handleError(err: any, dispatch: any) {
  const errString = err.toString();
  const editiedErrString = errString.replace("Error:", "");
  dispatch(setIsLoading({ isLoading: false }));
  dispatch(setWellbeingError({ wellbeingError: editiedErrString }));
}
