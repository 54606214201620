import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import { VIDEO_URL } from "../../../../api/apiConstants";

export type PlotlineTemplateVideoProps = {
  videoHorizontal: string | null;
  videoVertical: string | null;
};

const PlotlineTemplateVideo: FunctionComponent<PlotlineTemplateVideoProps> = (props): ReactElement => {
  const { videoHorizontal, videoVertical } = props;
  const isMobile = window.innerWidth < 768;

  return (
    <div className={"plotline-video-body"}>
      <video width="100%" height="100%" autoPlay loop controlsList="nodownload" disablePictureInPicture>
        <source src={VIDEO_URL + (isMobile ? videoVertical : videoHorizontal)} type="video/mp4" />
      </video>
    </div>
  );
};

export default PlotlineTemplateVideo;
