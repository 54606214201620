import { SeityResponse } from "./baseTypes";

export class SeityAuthenticationError extends Error {
  message = "Please login again to continue.";
}

export class SeityLoginResponse extends SeityResponse {
  data: SeityLoginPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityLoginPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityLoginPayloadResponse {
  token: string;
  refreshKey: string;
  accountID: number;
  expirationTime: number;

  constructor(token: string, refreshKey: string, accountID: number, expirationTime: number) {
    this.token = token;
    this.refreshKey = refreshKey;
    this.accountID = accountID;
    this.expirationTime = expirationTime;
  }
}

export class SeitySignupResponse extends SeityResponse {
  data: SeitySignupPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeitySignupPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeitySignupPayloadResponse {
  returnCode: number;
  token: string;
  refreshKey: string;
  accountId: string;
  expirationTime: number;
  firstName: string;
  companyName: string;
  usePoints: boolean;

  constructor(
    returnCode: number,
    token: string,
    refreshKey: string,
    accountId: string,
    expirationTime: number,
    firstName: string,
    companyName: string,
    usePoints: boolean
  ) {
    this.returnCode = returnCode;
    this.token = token;
    this.refreshKey = refreshKey;
    this.accountId = accountId;
    this.expirationTime = expirationTime;
    this.firstName = firstName;
    this.companyName = companyName;
    this.usePoints = usePoints;
  }
}

export class SeityAuthRefreshResponse extends SeityResponse {
  data: SeityAuthRefreshPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityAuthRefreshPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityAuthRefreshPayloadResponse {
  token: string;
  refreshKey: string;
  expirationTime: number;

  constructor(token: string, refreshKey: string, expirationTime: number) {
    this.token = token;
    this.refreshKey = refreshKey;
    this.expirationTime = expirationTime;
  }
}
