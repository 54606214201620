/* eslint-disable consistent-return */
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../app/rootReducer";
import { SeityAppContext } from "../../../app/SeityAppContext";

import { getSavedToken } from "../../auth/authSlice";
import { sendCrisisInfoRequest } from "../checkInSlice";

import "./styles.scss";
import strings from "../../../_core/strings/strings";
import { SeityButton } from "../../../_core/components/SeityButton";

interface TriageProps {}

// eslint-disable-next-line no-empty-pattern
export const Triage = ({}: TriageProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appContext = useContext(SeityAppContext);
  const { crisisInfoResponse } = useSelector((state: RootState) => {
    return state.checkIn;
  });
  const { token } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    appContext.setShowNavMenuItems(false);
    appContext.setShowTodo(false);
    if (token === "") {
      dispatch(
        getSavedToken(() => {
          dispatch(sendCrisisInfoRequest());
        })
      );
      return;
    }
    dispatch(sendCrisisInfoRequest());
    return () => {
      appContext.setShowNavMenuItems(true);
      appContext.setShowTodo(true);
    };
  }, []);

  // if (shouldRedirectToLogin) {
  //     return (
  //         <Redirect
  //             to={{
  //                 pathname: "/login",
  //                 state: {
  //                     from: "/"
  //                 }
  //             }} />
  //     )
  // }

  // if (checkInError) {
  //     return (
  //         <div>{checkInError}</div>
  //     )
  // }

  if (crisisInfoResponse === null) {
    return <div />; // need content for this
  }

  const firstName = crisisInfoResponse.firstName ?? "";
  let greeting = "Hey,";
  if (firstName !== "") {
    greeting = `Hey ${firstName},`;
  }

  const resourcesPressed = () => {
    history.push("/checkIn/resources");
  };

  const iDontWantHelpPressed = () => {
    history.push("/");
  };

  return (
    <div className="content-container">
      <h4 className="heading-label-triage">{greeting}</h4>
      <div className="triage-div">
        <p className="subheading-label-triage">{strings.introMessage}</p>
      </div>
      <div className="triage-div">
        <h4 className="heading-label-triage">{strings.needHelpMessage}</h4>
        <SeityButton type="submit" label={strings.crisisButtonTitle} onClick={resourcesPressed} />
      </div>
      <div className="triage-message-div">
        <p className="triage-message">{strings.message}</p>
      </div>
      <div className="triage-div">
        <p className="footer-text">{strings.salutation}</p>
        <SeityButton type="cancel" onClick={iDontWantHelpPressed} label={strings.iDontWantHelp} />
      </div>
    </div>
  );
};

export default Triage;
