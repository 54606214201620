/* eslint-disable react/require-default-props */
import React from "react";

import spinnerGif from "../../../assets/graphics/spinner.gif";

import "./styles.scss";

interface SeityLoaderProps {
  showBackgroundMask?: boolean;
  hideBackgroundContent?: boolean;
}

export const SeityLoader = ({ showBackgroundMask, hideBackgroundContent }: SeityLoaderProps) => {
  return (
    <div className="loader-container">
      {showBackgroundMask && <div className="loader-mask" />}
      {hideBackgroundContent && <div className="loader-content-hidden" />}
      <div className="loader-content">
        <img src={spinnerGif} width={50} height={50} alt="spinner loader" />
      </div>
    </div>
  );
};

export default SeityLoader;
