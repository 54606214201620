import strings from "../../../_core/strings/strings";

export interface AssessOutcomesSteps {
  id: number;
  header: {
    title: string;
    subTitle: string | null;
  } | null;
  outcomeCateID: number | null;
  buttonsDirectionRow?: boolean;
  options: Array<{
    value: string;
    buttonType: "next" | "black" | "discard";
    action: {
      type: string;
      value: string;
    };
  }>;
}

const useAssessOutcomesSteps = (): Array<AssessOutcomesSteps> => {
  return [
    {
      id: -1,
      header: {
        title: strings.outcomesIntroHeader,
        subTitle: null
      },
      outcomeCateID: null,
      options: [
        {
          value: strings.start,
          buttonType: "next",
          action: { type: "next", value: "0" }
        },
        {
          value: strings.finishLater,
          action: { type: "finishLater", value: "0" },
          buttonType: "discard"
        }
      ]
    },
    /* Outcomes Selection Begin */
    {
      id: 0,
      header: null,
      outcomeCateID: 5,
      buttonsDirectionRow: true,
      options: [
        {
          value: strings.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "-1" }
        },
        {
          value: strings.continue,
          buttonType: "next",
          action: { type: "next", value: "1" }
        }
      ]
    },
    {
      id: 1,
      header: null,
      outcomeCateID: 6,
      buttonsDirectionRow: true,
      options: [
        {
          value: strings.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "0" }
        },
        {
          value: strings.continue,
          buttonType: "next",
          action: { type: "next", value: "2" }
        }
      ]
    },
    {
      id: 2,
      header: null,
      outcomeCateID: 7,
      buttonsDirectionRow: true,
      options: [
        {
          value: strings.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "1" }
        },
        {
          value: strings.continue,
          buttonType: "next",
          action: { type: "next", value: "3" }
        }
      ]
    },
    {
      id: 3,
      header: null,
      outcomeCateID: 8,
      buttonsDirectionRow: true,
      options: [
        {
          value: strings.backButtonText,
          buttonType: "black",
          action: { type: "back", value: "2" }
        },
        {
          value: strings.continue,
          buttonType: "next",
          action: { type: "next", value: "4" }
        }
      ]
    },
    /* --- END --- */
    {
      id: 4,
      header: null,
      outcomeCateID: null,
      options: [
        {
          value: strings.submit,
          buttonType: "next",
          action: { type: "next", value: "5" }
        }
      ]
    },
    {
      id: 5,
      header: {
        title: strings.assessmentCompleteHeader,
        subTitle: strings.assessmentCompleteText
      },
      outcomeCateID: null,
      options: [
        {
          value: strings.doneButtonTitle,
          buttonType: "next",
          action: { type: "navigate", value: "/wholeHealth" }
        }
      ]
    }
  ];
};

export default useAssessOutcomesSteps;
