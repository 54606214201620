/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import Modal from "react-modal";
import { SeityButton } from "../../../_core/components/SeityButton";
import "./styles.scss";

import strings from "../../../_core/strings/strings";
import animationData from "./commit-success.json";
import Lottie from "react-lottie";
import { CheckInAction } from "../../../api/checkInTypes";

export type GetHelpModalProps = {
  firstName: string;
  message: string;
  action: CheckInAction;
  setIsGetHelpModalVisible: (value: boolean) => void;
  isOpen: boolean;
  onClose: () => void;
};

export const GetHelpModal: FunctionComponent<GetHelpModalProps> = ({
  isOpen = false,
  setIsGetHelpModalVisible,
  firstName = "",
  message = "",
  action = CheckInAction.NoAction,
  onClose = () => {}
}): ReactElement => {
  Modal.setAppElement("#root");

  const history = useHistory();

  const onClickTop = () => {
    onClose();
    if (action === CheckInAction.ShowWellnessNavigator) {
      history.push("/checkIn/resources");
    } else {
      history.push("/journals/entry");
    }
  };

  const onClickBottom = () => {
    onClose();
    setIsGetHelpModalVisible(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={() => {}}
      onRequestClose={onClose}
      className="get-help-modal"
      overlayClassName="get-help-overlay"
      contentLabel="Seity Modal"
    >
      {action === CheckInAction.ShowEncouragement ? ( // ShowEncourgement
        <Lottie options={defaultOptions} height={262} width={262} />
      ) : (
        <img className="get-help-img" src={require("../../../assets/graphics/people-drink-coffee.png").default} alt="people drink coffee" />
      )}
      <button
        className="close-button"
        onClick={(e) => {
          e.preventDefault();
          onClickBottom();
        }}
      >
        <img src={require("../../../assets/graphics/today/close.png").default} alt="close button" />
      </button>
      <div className="get-help-modal-container">
        <div className="mb-xl" dangerouslySetInnerHTML={{ __html: message }} />
        <SeityButton
          className="top-button"
          label={action === CheckInAction.ShowWellnessNavigator ? strings.seeSupportOptions : strings.goToJournal}
          onClick={onClickTop}
        />
        <SeityButton
          label={action === CheckInAction.ShowWellnessNavigator ? strings.iFeelCapableOf : strings.noThanks}
          className="down-button"
          onClick={onClickBottom}
        />
      </div>
    </Modal>
  );
};

export default GetHelpModal;
