/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from "react";

import classNames from "classnames";

import { SeityVideoPlayer } from "../../../../_core/components/SeityVideoPlayer";

import strings from "../../../../_core/strings/strings";
import "../styles.scss";

export interface ArtOfYouCarouselProps {
  firstName: string;
}

enum SliderPositions {
  Left,
  Right
}

export const ArtOfYouCarousel = ({ firstName }: ArtOfYouCarouselProps) => {
  const [sliderPosition, setSliderPosition] = useState("0%");
  const [carouselSlide, setCarouselSlide] = useState<SliderPositions>(SliderPositions.Left);

  const slideRight = () => {
    setSliderPosition("100%");
    setCarouselSlide(SliderPositions.Right);
  };

  const slideLeft = () => {
    setSliderPosition("0%");
    setCarouselSlide(SliderPositions.Left);
  };

  return (
    <div className="aoy-header">
      <div className="aoy-header-slider">
        <div className="aoy-header-slider-inner" style={{ right: sliderPosition }}>
          <div className="aoy-header-slider-left">
            <div className="aoy-img-container">
              <div className="aoy-welcome-message-container">
                <p className="aoy-message-small">{strings.the}</p>
                <p className="aoy-message-large">{strings.art}</p>
                <p className="aoy-message-small">{strings.of}</p>
                <p className="aoy-message-large">{firstName}</p>
              </div>
            </div>
          </div>
          <div className="aoy-header-slider-right">
            <div className="aoy-video-container">
              <h5>{strings.watchArtOfYou}</h5>
              <SeityVideoPlayer videoFile="theartofyoumain.mp4" pictureFile="challenge202105i01" />
            </div>
          </div>
        </div>
      </div>
      <div className="aoy-circle-button-wrapper">
        <div
          onClick={slideLeft}
          className={classNames("aoy-circle-button", {
            active: carouselSlide === SliderPositions.Left
          })}
        />
        <div
          onClick={slideRight}
          className={classNames("aoy-circle-button", {
            active: carouselSlide === SliderPositions.Right
          })}
        />
      </div>
    </div>
  );
};
