import { FunctionComponent, useMemo, useState } from "react";
import "./styles.scss";
import { ContactListItem } from "../ContactListItem";
import FSMemberSidebar from "../../familySharing/FSMemberSidebar";
import { FSGroupAccount } from "../../familySharing/types";
import { RootState } from "../../../app/rootReducer";
import { useSelector } from "react-redux";

type UserType = {
  accountID: number;
  firstName: string;
  lastName: string;
  profilePicture: string;
  emailAddress: string;
  coreValues: any;
  occupation: string;
};

type ContactListProps = {
  alphabetArray: Array<string>;
  groupedValues: { [key: string]: Array<UserType> };
  searchValue: string;
  isPersonalListMode: boolean;
  selectedAccounts: Array<number>;
  setSelectedAccounts: (value: Array<number> | []) => void;
};

export const ContactList: FunctionComponent<ContactListProps> = ({
  alphabetArray,
  groupedValues,
  searchValue,
  isPersonalListMode,
  selectedAccounts,
  setSelectedAccounts
}) => {
  const [showMemberProfile, setShowMemberProfile] = useState(false);
  const [activeUser, setActiveUser] = useState<FSGroupAccount | null>(null);

  // Personal List Mode
  const { personalListAccounts } = useSelector((state: RootState) => state.personalListSlice);

  const personalListAccountIDs = useMemo(() => {
    if (personalListAccounts) {
      return personalListAccounts.map((account) => account.accountID);
    }
    return [];
  }, [personalListAccounts]);

  const toggleSelect = (id) => {
    if (selectedAccounts.includes(id)) {
      // Deselect
      setSelectedAccounts(selectedAccounts.filter((accountId) => accountId !== id));
    } else {
      setSelectedAccounts([...selectedAccounts, id]);
    }
  };
  // Personal List Mode

  const characterArray = Object.keys(groupedValues).filter((char) => {
    const users = groupedValues[char];
    return !isPersonalListMode || users.some((user) => !personalListAccountIDs.includes(user.accountID));
  });
 
  return (
    <div className={"contact-list"}>
      {characterArray.map((char) => {
        const filteredUsers = isPersonalListMode
        ? groupedValues[char].filter(
            (user) => !personalListAccountIDs.includes(user.accountID)
          )
        : groupedValues[char];
        return (
          filteredUsers.length > 0 && (
            <div id={`${char}-contact-list`} key={`val-${char}`} className="contact-list-char-group">
              <h2>{char}</h2>
              {filteredUsers.map((user) => (
                <div
                  key={`child-${user.accountID}`}
                  className={
                    selectedAccounts.includes(user.accountID) ? "selected-account" : "unselected-account"
                  }
                  onClick={() => {
                    if (isPersonalListMode) {
                      toggleSelect(user.accountID);
                      return;
                    }
                    setShowMemberProfile(true);
                    setActiveUser({
                      accountID: user.accountID,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      profilePicture: user.profilePicture,
                      dob: "",
                      email: user.emailAddress,
                      coreValues: user.coreValues,
                      occupation: user.occupation
                    });
                  }}
                >
                  <ContactListItem
                    coreValues={user.coreValues}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    profilePicture={user.profilePicture}
                    searchValue={searchValue}
                  />
                </div>
              ))}
            </div>
          )
        );
      })}
      {showMemberProfile && activeUser && (
        <FSMemberSidebar
          memberData={activeUser}
          onClose={() => {
            setShowMemberProfile(false);
            setActiveUser(null);
          }}
          isDirectoryMode={true}
        />
      )}
    </div>
  );
};
