/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";

import BackCaret from "../../../assets/icons/close-black.png";
import { SeityCloseButtonProps } from "./types";

import "./styles.scss";

export const SeityCloseButton: FunctionComponent<SeityCloseButtonProps> = (props): ReactElement | null => {
  const history = useHistory();

  const goBackonCaretClick = () => {
    history.goBack();
  };

  return (
    <button
      className={`close-button-container close-button-container-header'
      } ${props.wrapperClassName || ""}`}
      onClick={goBackonCaretClick}
    >
      <img src={BackCaret} />
    </button>
  );
};
