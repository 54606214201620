import { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";

import strings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";

import "./styles.scss";

const NoIntention: FunctionComponent = (): ReactElement => {
  const history = useHistory();

  return (
    <div className="no-intentions flex-col flex-ai-c">
      <img src={require("../../../assets/graphics/intentions/img-nointention.png").default} alt="nointentions img" />
      <div className="mt-xl flex-col flex-ai-c">
        <h3>{strings.ohno}</h3>
        <p>{strings.noIntentionsDesc}</p>
      </div>
      <SeityButton
        className="intention-button mtb-xxl w-100"
        label="Go back"
        onClick={() => {
          history.goBack();
        }}
      />
    </div>
  );
};

export default NoIntention;
