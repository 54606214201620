/* eslint-disable @typescript-eslint/no-use-before-define */
import { FunctionComponent } from "react";
import SeityCVImage from "../../../../_core/components/SeityCVImage";

type CoreValueProps = {
  userSortOrder: number;
  coreValueID: number;
  coreValue: string;
  descriptionText: string;
  quote: string;
};

export const CoreValue: FunctionComponent<CoreValueProps> = (props) => {
  const { userSortOrder, coreValueID, coreValue, descriptionText, quote } = props;

  return (
    <div className={`mcv-core-value-assess ${userSortOrder === 4 && "mcv-core-value-assess-without-border"}`} key={coreValue}>
      <div className="mcv-core-value-assess-title">{coreValue}</div>
      <SeityCVImage cvId={coreValueID} size={104} />
      <div className="mcv-core-value-assess-description">{descriptionText}</div>
      {QuoteParser({ quote })}
    </div>
  );
};

type QuoteParserProps = {
  quote: string;
};

export const QuoteParser: FunctionComponent<QuoteParserProps> = (props) => {
  const { quote } = props;
  const quoteIndex = quote.indexOf(" -");
  const quoteString = quote.slice(0, quoteIndex);
  const authorString = quote.slice(quoteIndex);

  if (quoteIndex !== -1) {
    return (
      <div>
        <div className="mcv-core-value-assess-quote">"{quoteString}"</div>
        <div className="mcv-core-value-assess-quote-author">{authorString}</div>
      </div>
    );
  }
  return (
    <div>
      <div className="mcv-core-value-assess-quote">{quote}</div>
    </div>
  );
};
