/* eslint-disable radix */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../../app/rootReducer";
import {
  sendGetAccountListsRequest,
  sendGetAccountInfoRequest,
  clearAccountError,
  sendDepartmentListRequest,
  sendJobPositionListRequest
} from "../../../account/accountSlice";
import { setCurrentInfo } from "../../registerSlice";
import { useAccountLists } from "../../../../hooks/AccountList";

import RegisterWrapper from "../../RegisterWrapper";
import { SeityFormSelectBox } from "../../../../_core/components/SeitySelectBox/SeityFormSelectBox";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";
import SeityButton from "../../../../_core/components/SeityButton";

import strings from "../../../../_core/strings/strings";
import "./style.scss";

export const RegisterSix = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { departmentResponse, jobPositionResponse } = useSelector((state: RootState) => {
    return state.account;
  });

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [accountStatusId, setAccountStatusId] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const [educationID, setEducationID] = useState(0);
  const [departmentID, setDepartmentID] = useState(0);
  const [jobPositionID, setJobPositionID] = useState(0);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    clearAccountError();
    dispatch(sendGetAccountListsRequest(true, true, true, false, false, false));
    dispatch(sendGetAccountInfoRequest());
  }, []);

  useEffect(() => {
    if (!departmentResponse) {
      dispatch(sendDepartmentListRequest());
    }
  }, [departmentResponse, dispatch]);

  useEffect(() => {
    if (!jobPositionResponse) {
      dispatch(sendJobPositionListRequest());
    }
  }, [jobPositionResponse, dispatch]);

  const { accountStatusList, statusList, educationList } = useAccountLists();

  const isFormValid = () => {
    if (accountStatusId <= 0 || statusId <= 0 || educationID <= 0 || (departmentResponse?.departmentEnabled && departmentID <= 0)) {
      setIsFormInvalid(true);
      return false;
    }
    setIsFormInvalid(false);
    return true;
  };

  const onHandleChange = (setStatusHandler: (statusChange: number) => void, statusChange: number) => {
    setStatusHandler(statusChange);
  };

  const nextPressed = () => {
    if (isFormValid()) {
      dispatch(
        setCurrentInfo({
          departmentID,
          jobPositionID,
          accountStatusId,
          statusId,
          educationID
        })
      );
      history.push("/register/registerSeven");
    } else {
      setValidationError(strings.allFieldsErrorMessage);
    }
  };

  return (
    <RegisterWrapper>
      <div className="register-six-container">
        <div className="register-six-container__content">
          <h1>{strings.setUp}</h1>
          <SeityIndicator currentStep={1} numberOfSteps={3} />
          <h5>{strings.allFields}</h5>
          <form className="textbox-form">
            <SeityFormSelectBox
              isInvalid={isFormInvalid && accountStatusId <= 0}
              inputLabelText={strings.accountStatusLabel}
              onChange={(e) => {
                return onHandleChange(setAccountStatusId, parseInt(e.target.value));
              }}
            >
              <ul>
                {accountStatusList.map((item) => {
                  return (
                    <li key={item.listID} value={item.listID}>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
            <SeityFormSelectBox
              isInvalid={isFormInvalid && statusId <= 0}
              inputLabelText={strings.statusLabel}
              onChange={(e) => {
                return onHandleChange(setStatusId, parseInt(e.target.value));
              }}
            >
              <ul>
                {statusList.map((item) => {
                  return (
                    <li key={item.listID} value={item.listID}>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
            <SeityFormSelectBox
              isInvalid={isFormInvalid && educationID <= 0}
              inputLabelText={strings.educationLabel}
              onChange={(e) => {
                return onHandleChange(setEducationID, parseInt(e.target.value));
              }}
            >
              <ul>
                {educationList.map((item) => {
                  return (
                    <li key={item.listID} value={item.listID}>
                      {item.description}
                    </li>
                  );
                })}
              </ul>
            </SeityFormSelectBox>
            {departmentResponse?.departmentEnabled && (
              <SeityFormSelectBox
                isInvalid={isFormInvalid && departmentID <= 0}
                controlId="department-id"
                inputLabelText={strings.department}
                value={
                  departmentID > 0
                    ? departmentResponse?.departments?.find((x) => {
                        return x.departmentID === departmentID;
                      })?.departmentName
                    : strings.choose
                }
                onChange={(e) => {
                  return onHandleChange(setDepartmentID, parseInt(e.target.value));
                }}
              >
                <ul>
                  {departmentResponse?.departments?.map((item) => {
                    return (
                      <li key={item.departmentID} value={item.departmentID}>
                        {item.departmentName}
                      </li>
                    );
                  })}
                </ul>
              </SeityFormSelectBox>
            )}
            {jobPositionResponse?.jobPositionEnabled && (
              <SeityFormSelectBox
                isInvalid={isFormInvalid && jobPositionID <= 0}
                controlId="job-position-id"
                inputLabelText={strings.jobPosition}
                value={
                  jobPositionID > 0
                    ? jobPositionResponse?.jobPositions?.find((x) => {
                        return x.jobPositionID === jobPositionID;
                      })?.jobPositionName
                    : strings.choose
                }
                onChange={(e) => {
                  return onHandleChange(setJobPositionID, parseInt(e.target.value));
                }}
              >
                <ul>
                  {jobPositionResponse?.jobPositions?.map((item) => {
                    return (
                      <li key={item.jobPositionID} value={item.jobPositionID}>
                        {item.jobPositionName}
                      </li>
                    );
                  })}
                </ul>
              </SeityFormSelectBox>
            )}
          </form>
          <div className="register-six-container-error">{validationError}</div>
        </div>

        <SeityButton
          onClick={nextPressed}
          type="next"
          disabled={false}
          label={strings.nextButtonText}
          className="register-six-next-button"
        />
      </div>
    </RegisterWrapper>
  );
};
