import { SeityResponse } from "../../baseTypes";

export class SeityAccountMessageCenterListResponse extends SeityResponse {
  data: SeityAccountMessageCenterList | null;

  constructor(success: boolean, message: string | null, data: SeityAccountMessageCenterList | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityAccountMessageCenterList {
  messageCenterList: [SeityAccountMessageCenter] | null;

  constructor(messageCenterList: [SeityAccountMessageCenter]) {
    this.messageCenterList = messageCenterList;
  }
}

export class SeityAccountMessageCenter {
  messageID: number;
  message: string;
  messageTypeID: MessageType;
  messageKeyID: number;

  constructor(messageId: number, message: string, messageTypeID: MessageType, messageKeyID: number) {
    this.messageID = messageId;
    this.message = message;
    this.messageTypeID = messageTypeID;
    this.messageKeyID = messageKeyID;
  }
}

export enum MessageType {
  MobileAppMessageCenter = 1,
  AssessmentFull = 2,
  DailyCheckIn = 3,
  Challenge = 4,
  UpToDate = 5,
  Survey = 6,
  AccountMaintenance = 7,
  DailyIntention = 8,
  SelectCoreValues = 10
}
