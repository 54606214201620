import { FunctionComponent } from "react";
import "./styles.scss";
import SeityAvatar from "../../../_core/components/SeityAvatar";
import { ParticipationData } from "../types";
import colors from "../../../_core/styles/SeityColors";

type RankingsTableProps = {
  type?: number;
  columnHeaders: Array<String>;
  schoolList: Array<ParticipationData>;
};

export const RankingsTable: FunctionComponent<RankingsTableProps> = ({ type, columnHeaders, schoolList }) => {
  return (
    <div className="school-rankings-table">
      {columnHeaders && (
        <div className="rankings-table-row">
          <div className="rank">
            <p className="rankings-table-row-heading">{columnHeaders[0]}</p>
          </div>
          <div className={type === 2 ? "school-extended" : "school"}>
            <p className="rankings-table-row-heading">{columnHeaders[1]}</p>
          </div>
          {type !== 2 && (
            <div className="participation">
              <p className="rankings-table-row-heading">{columnHeaders[2]}</p>
            </div>
          )}
          <div className="points">
            <p className="rankings-table-row-heading">{type === 2 ? columnHeaders[2] : columnHeaders[3]}</p>
          </div>
        </div>
      )}
      {schoolList?.map((school) => (
        <div className={"rankings-table-row bordered-box-" + (school.isHighlighted ? "orange" : "gray")}>
          <div className="rank">
            <p>{school.rank}</p>
          </div>
          <div className={type === 2 ? "school-extended" : "school"}>
            {school.companyInitials && (
              <SeityAvatar
                size={40}
                content={{
                  profilePicture: school.logo,
                  //@ts-ignore
                  firstName: school.companyInitials[0],
                  //@ts-ignore
                  lastName: school.companyInitials[1]
                }}
              />
            )}
            <p>{school.company}</p>
          </div>
          {type !== 2 && (
            <div className="participation">
              <div className="progress-outer">
                {(school.percentage ?? 0) <= 20 && (
                  <p className={`${school?.isHighlighted && (school.percentage ?? 0) > 20 ? "white" : "black"} `}>{`${
                    school.percentage ?? "-"
                  }%`}</p>
                )}
                <div
                  className="progress-inner"
                  style={{
                    width: `${school.percentage}%`,
                    background: school.isHighlighted ? colors.persimmon : colors.seityYellow,
                    ...((school.percentage ?? 0) < 10 ? { borderRadius: 0 } : {})
                  }}
                >
                  {(school.percentage ?? 0) > 20 && (
                    <p className={`${school?.isHighlighted && (school.percentage ?? 0) >= 20 ? "white" : "black"}`}>{`${
                      school.percentage ?? "-"
                    }%`}</p>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="points">
            <p>{school.points}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
