import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { SyncListResponse, SyncPutResponse } from "./types/syncTypes";
import { SyncDetailResponse } from "./types/syncTypes";

const SECTION = "/Sync/";
const AUTH_SECTION = "/Auth/";

export async function getSyncList(token: string): Promise<SyncListResponse> {
  const url = BASE_URL + SECTION;
  try {
    const syncListResponse = await axios.get<SyncListResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return syncListResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getSyncDetail(token: string, objectiveID: number): Promise<SyncDetailResponse> {
  const url = BASE_URL + SECTION + objectiveID;
  try {
    const syncDetailResponse = await axios.get<SyncDetailResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return syncDetailResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function putSync(
  token: string,
  param: {
    syncID: number;
    isCompleted: boolean | null;
    objectiveStatus: number | null;
    selectedEmotions: Array<{ emotionID: number }> | null;
  }
): Promise<SyncPutResponse> {
  const url = BASE_URL + SECTION;
  try {
    const storyDetailResponse = await axios.put<SyncPutResponse>(url, param, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return storyDetailResponse.data;
  } catch (err) {
    throw err;
  }
}
