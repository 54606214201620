import { FunctionComponent, ReactElement, useState } from "react";
import "./styles.scss";
import { PlotlineWellbeingImagesData } from "../../../api/plotlineTypes";
import { GRAPHICS_URL } from "../../../api/apiConstants";

export type PlotlineTemplateWellbeingProps = {
  selectionImages: PlotlineWellbeingImagesData | null;
  selIdx: number;
  title?: string | null;
  onSelLevels: (level: number) => void;
};

const PlotlineTemplateWellbeing: FunctionComponent<PlotlineTemplateWellbeingProps> = (props): ReactElement => {
  const { selectionImages, selIdx = -1, title, onSelLevels } = props;

  const emotionImgs = [
    { on: selectionImages?.imageOn01, off: selectionImages?.imageOff01 },
    { on: selectionImages?.imageOn02, off: selectionImages?.imageOff02 },
    { on: selectionImages?.imageOn03, off: selectionImages?.imageOff03 },
    { on: selectionImages?.imageOn04, off: selectionImages?.imageOff04 },
    { on: selectionImages?.imageOn05, off: selectionImages?.imageOff05 }
  ];

  return (
    <div className="plotline-wellbeing-body">
      <p className="title-2">{title}</p>
      <div className="icon-grid">
        {emotionImgs.map((imageObj, id) => (
          <button
            key={id}
            onClick={() => {
              onSelLevels(id);
            }}
          >
            <img src={`${GRAPHICS_URL + (selIdx === id ? imageObj.on : imageObj.off)}`} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default PlotlineTemplateWellbeing;
