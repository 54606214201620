import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import SeityButton from "../../../_core/components/SeityButton";
import ListItem from "./ListItem";

import { getJournalListRequest } from "../journalsSlice";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";
import { Journal } from "../../../api/individualJournal/journalTypes";

import { getUpdatedJournalsOnLike } from "./helpers";
import { JournalsProps } from "./types";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";

const COUNT_OF_CHUNK = 5;

const Journals: FunctionComponent<JournalsProps> = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: RootState) => {
    return state.journals;
  });

  const [journalList, setJournalList] = useState<{
    all: Array<Journal> | null;
    fav: Array<Journal> | null;
  }>();
  const [showFav, setShowFav] = useState(false);
  const [popupMenu, setPopupMenu] = useState({
    individualJournalId: -1,
    show: false,
    x: 0,
    y: 0
  });
  const [scrollPos, setScrollPos] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getJournals = (jList: Array<Journal> | null) => {
    const pos = jList?.length ?? 0;
    const range = {
      start: pos,
      end: pos + COUNT_OF_CHUNK - 1
    };
    dispatch(
      getJournalListRequest(range, (journals) => {
        const all = [...(jList ?? []), ...journals];
        setJournalList({
          all,
          fav: all.filter((a) => {
            return a.favorite === true;
          })
        });
      })
    );
  };

  const handleScroll = () => {
    const pos = window.pageYOffset;
    if (isLoading || showFav) {
      setScrollPos(pos);
      return;
    }

    if (pos > scrollPos && document.body.scrollHeight - pos - window.innerHeight < 50) {
      getJournals(journalList?.all ?? null);
    }
    setScrollPos(pos);
  };

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [journalList, scrollPos]);

  useEffect(() => {
    getJournals(journalList?.all ?? null);
  }, []);

  const removeJournal = (journalId: number) => {
    const temp = [...(journalList?.all ?? [])];
    const all = temp.filter((t) => {
      return t.individualJournalID !== journalId;
    });
    setJournalList({
      all,
      fav: all.filter((a) => {
        return a.favorite === true;
      })
    });
  };

  const list = showFav ? journalList?.fav : journalList?.all;

  return (
    <div className="journals flex-col flex-ai-c w-100">
      <div className="journals__header flex-col flex-ai-c pt-xl">
        <div className="header-wrap flex-col flex-ai-c h-100">
          <div className="flex-ai-c w-100">
            <button
              className="header-back-button"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={require("../../../assets/graphics/journals/back-arrow.png").default} alt="Individual" />
            </button>
            <h3 className="">{CoreStrings.journals}</h3>
            <SeityButton
              className="rounded-lg"
              label={`+${windowWidth > 500 ? " " + CoreStrings.newJournalEntry: ''}`}
              onClick={() => {
                history.push("/journals/entry");
              }}
            />
          </div>
          <div className="journals-tabmenu flex-ai-c mt-auto">
            <button
              className={showFav ? "" : "active"}
              onClick={() => {
                setShowFav(false);
              }}
            >
              {CoreStrings.myJournals}
            </button>
            <button
              className={showFav ? "active" : ""}
              onClick={() => {
                setShowFav(true);
              }}
            >
              {CoreStrings.favorites}
            </button>
          </div>
        </div>
      </div>
      <ul className="journals__list w-100">
        {list?.map((item, i) => {
          return (
            <ListItem
              key={`${item.individualJournalID}-${i}-${item.favorite ? "fav" : ""}-listitem`}
              popupMenu={popupMenu}
              handleMenu={(e) => {
                setPopupMenu({
                  individualJournalId: item.individualJournalID,
                  show: true,
                  x: e.clientX,
                  y: e.clientY + window.scrollY
                });
              }}
              journal={item}
              onDelete={() => {
                removeJournal(item.individualJournalID);
              }}
              onFavourite={() => {
                const updatedJ = getUpdatedJournalsOnLike(item.individualJournalID, journalList!.all!);

                setJournalList({
                  all: updatedJ,
                  fav: updatedJ.filter((a) => {
                    return a.favorite === true;
                  })
                });
              }}
            />
          );
        })}
      </ul>
      {isLoading && <SeityLoader />}
    </div>
  );
};

export default Journals;
