import { FunctionComponent } from "react";
import { IndicatorProps, SeityIndicatorProps } from "./types";

import "./styles.scss";

const Indicator: FunctionComponent<IndicatorProps> = ({ active }) => {
  return <div className={active ? "seity-indicator-active" : "seity-indicator-inactive"} />;
};

export const SeityIndicator: FunctionComponent<SeityIndicatorProps> = ({ numberOfSteps, currentStep }) => {
  const indicators = new Array(numberOfSteps).fill(null);

  return (
    <div className="seity-indicator-container">
      {indicators.map((_, index) => {
        return <Indicator key={index} active={currentStep >= index + 1} />;
      })}
    </div>
  );
};
