import { FunctionComponent, ReactElement, useState } from "react";
import Modal from "react-modal";

import SeityCVSelection, { CoreValueType } from "../../../_core/components/SeityCVSelection";

import strings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";

import "./styles.scss";

type CVSelectionModalProps = {
  isOpen: boolean;
  cVals: Array<CoreValueType>;
  onClose: () => void;
  onSave: (vals: Array<CoreValueType>) => void;
  onIdk: () => void;
};

const CVSelectionModal: FunctionComponent<CVSelectionModalProps> = ({ isOpen, cVals, onSave, onClose, onIdk }): ReactElement => {
  const [coreVals, setCoreVals] = useState<Array<CoreValueType>>(cVals);

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="cv-selection-modal" overlayClassName="cv-selection-overlay">
      <p className="cv-selection-modal__title">{strings.cvSelectionModalTitle}</p>
      <SeityCVSelection
        coreVals={coreVals}
        isReadOnly={false}
        size={64}
        onChange={(vals) => {
          setCoreVals(vals);
        }}
      />
      <button
        className="btn-idk"
        onClick={() => {
          onClose();
        }}
      >
        {strings.cancelButtonLabel}
      </button>
      <SeityButton
        className="intention-button mtb-xxl"
        label={strings.saveButtonText}
        onClick={() => {
          onSave(coreVals);
        }}
      />
      <button
        className="btn-idk"
        onClick={() => {
          onIdk();
        }}
      >
        {strings.idk}
      </button>
    </Modal>
  );
};

export default CVSelectionModal;
