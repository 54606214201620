import { FunctionComponent, ReactElement } from "react";
import { ServiceResourceGroup } from "../../../../api/resource/resourceTypes";
import Resource from "../Resource";

import "./styles.scss";

type ResourceGroupProps = {
  serviceResourceGroup: ServiceResourceGroup;
};

export const ResourceGroup: FunctionComponent<ResourceGroupProps> = ({ serviceResourceGroup }): ReactElement => {
  return (
    <div className="service-resource-group-wrapper" key={serviceResourceGroup.serviceResourceGroupID}>
      {serviceResourceGroup.header !== "" && <div className="service-resource-group-header">{serviceResourceGroup.header}</div>}
      {serviceResourceGroup.serviceResourceHeader.map((serviceResource) => {
        return <Resource key={serviceResource.serviceResourceHeaderID} serviceResource={serviceResource} />;
      })}
    </div>
  );
};
