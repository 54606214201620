import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import SeityButton from "../../../_core/components/SeityButton";
import SeityLoader from "../../../_core/components/SeityLoader";

import strings from "../../../_core/strings/strings";
import ShowAffirmation from "../ShowAffirmation";
import { getAffirmationRequest } from "../todaySlice";
import { Affirmation } from "../types";
import AffirmationItem from "./AffirmationItem";
import { formatAffirmationData } from "./helpers";
import "./styles.scss";
import { isPlotlineInjectionCompleted } from "../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";

export const ChooseAffirmation: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selCVID, setSelCVID] = useState(-1);
  const [showAffirm, setShowAffirm] = useState(false);
  const [caItems, setCaItems] = useState<Array<Affirmation>>();

  const { cvAffirmation, isLoading } = useSelector((state: RootState) => {
    return state.today;
  });

  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  useEffect(() => {
    if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.OnDailyAffirmation)) {
      history.push("/plotline/" + PlotlineInjectionPointID.OnDailyAffirmation);
    } else {
      dispatch(getAffirmationRequest());
    }
  }, []);

  useEffect(() => {
    if (cvAffirmation) {
      const items = formatAffirmationData(cvAffirmation);
      setCaItems(items);

      if (cvAffirmation && cvAffirmation.selectedCoreValueAffirmationID) {
        const selItem = items.find((c) => c.coreValueAffirmationID === cvAffirmation.selectedCoreValueAffirmationID);
        if (selItem) {
          setSelCVID(selItem.coreValueID);
        }
      }
    }
  }, [cvAffirmation]);

  const getCVAffirmation = useMemo(() => {
    if (!cvAffirmation || !caItems) {
      return <></>;
    }

    if (showAffirm) {
      const affirmData = caItems.find((c) => c.coreValueID === selCVID);
      if (affirmData) {
        return (
          <ShowAffirmation
            affirmData={affirmData}
            onClose={() => {
              setShowAffirm(false);
              dispatch(getAffirmationRequest());
            }}
          />
        );
      }
      return <></>;
    } else {
      return (
        <>
          {caItems.map((item, index) => {
            return (
              <AffirmationItem
                key={index}
                coreValue={item.coreValue}
                coreValueID={item.coreValueID}
                isSelected={item.coreValueID === selCVID}
                onClickItem={() => {
                  if (item.coreValueID === selCVID) {
                    setSelCVID(-1);
                  } else {
                    setSelCVID(item.coreValueID);
                  }
                }}
              />
            );
          })}
        </>
      );
    }
  }, [cvAffirmation, selCVID, showAffirm, caItems]);

  if (isLoading) {
    return <SeityLoader />;
  }

  if (showAffirm) {
    return getCVAffirmation;
  }

  return (
    <div className="choose-affirmation flex flex-column">
      <div className="ca-header">
        <button
          onClick={() => {
            history.push("/today");
          }}
        >
          <img src={require("../../../assets/graphics/today/close.png").default} />
        </button>
        <div>
          <img className="mr-sm" src={require("../../../assets/graphics/today/icon-affirmation.png").default} width={12} height={12} />
          <span>{strings.daillyAffirmation}</span>
        </div>
        <img
          className="more-info"
          src={require("../../../assets/graphics/more-info.png").default}
          onClick={() =>
            history.push({
              pathname: "/plotline/" + PlotlineInjectionPointID.OnDailyAffirmation,
              state: {
                onDemand: true
              }
            })
          }
        />
      </div>
      <h3>{strings.chooseACoreValue}</h3>
      <div className="ca-content mt-xl">{getCVAffirmation}</div>
      <SeityButton
        disabled={selCVID === -1}
        label={strings.seeAffirmation}
        type="next"
        onClick={() => {
          setShowAffirm(true);
        }}
      />
    </div>
  );
};

export default ChooseAffirmation;
