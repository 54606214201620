import { FunctionComponent, ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";

export type ExploreItemProps = {
  visible: boolean;
  image: string;
  heading: string;
  description: string;
  className: string;
  navigationPath: string;
  contentKey: string;
  onClick: () => void;
};

export const ExploreItem: FunctionComponent<Omit<ExploreItemProps, "navigationPath">> = ({
  visible,
  image,
  heading,
  description,
  className,
  contentKey,
  onClick
}): ReactElement => {
  const { appContentByKeys } = useSelector((state: RootState) => {
    return state.appContent;
  });

  const content = appContentByKeys.find((a) => a.key === contentKey);
  const desc = content ? content.content : description;

  return !visible ? (
    <></>
  ) : (
    <div onClick={onClick} className={`sj-item ${className}`}>
      <img src={image} alt="item icon" className="grow-icon" />
      <div className="sj-content">
        <div className="sj-item-text">
          <h2>{heading}</h2>
          <h4>{desc}</h4>
        </div>
        <img src={require("../../assets/graphics/caret_white_large.png").default} alt="caret" className="sj-item-caret" />
      </div>
    </div>
  );
};
