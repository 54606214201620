import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router";

import AssessWrap from "../../AssessWrap";
import { SeityButton } from "../../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";

import MainIntroImage from "../../../../assets/graphics/img-assessment-web.png";
import CVOnlyIntroImage from "../../../../assets/graphics/img-cvonly-intro.png";
import CVOnlyIntroImageMb from "../../../../assets/graphics/img-cvonly-intro-mb.png";
import { AssessmentMainIntroProps } from "./types";

import strings from "../../../../_core/strings/strings";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import { useLocation } from "react-router-dom";
import { isPlotlineInjectionCompleted } from "../../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../../api/plotlineTypes";
import { AssessmentLevel } from "../../../../api/myStory/types/myStoryTypes";

type StateProps = {
  fromPlotline: boolean;
};

export const AssessmentMainIntro: FunctionComponent<AssessmentMainIntroProps> = () => {
  const [showFinishLater, setShowFinishLater] = useState(false);
  const history = useHistory();
  const location = useLocation<StateProps>();

  const fromPlotline = location?.state?.fromPlotline ?? false;

  let { isCVOnly } = useSelector((state: RootState) => {
    return state.userAppSettings.userAppSettings;
  });

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  if (fromPlotline) {
    isCVOnly = true;
  }

  const nextPressed = () => {
    history.push({
      pathname: "/assessment/breathingExercise",
      state: { fromPlotline: isCVOnly }
    });
  };

  const cvOnlyData = [strings.itemTitleEnergy, strings.itemTitleDirection, strings.itemTitleBelonging, strings.itemTitleJoy];

  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  useEffect(() => {
    if (
      userAppSettings.assessmentLevelID !== AssessmentLevel.FullAssessment &&
      !isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.CoreValues)
    ) {
      history.push("/plotline/" + PlotlineInjectionPointID.CoreValues);
    }
  }, []);

  return (
    <AssessWrap showFinishLater={showFinishLater} setShowFinishLater={setShowFinishLater}>
      <div className="assessment-intro-header">
        {isCVOnly ? (
          <div className="assessment-intro-cvonly-wrapper">
            <img className="intro-img mobile" src={CVOnlyIntroImageMb} alt={strings.assessmentIntroHeader} />
            <img className="intro-img" src={CVOnlyIntroImage} alt={strings.assessmentIntroHeader} />
            <h1>{strings.discoverYourCoreValues}!</h1>
          </div>
        ) : (
          <div className="assessment-intro-wrapper">
            <h1>{strings.assessmentIntroHeader}</h1>
            <p>{strings.assessmentIntroText}</p>
            <img className="intro-img" src={MainIntroImage} alt={strings.assessmentIntroHeader} />
          </div>
        )}
        <div className="assessment-intro-buttons">
          <SeityButton onClick={nextPressed} label={strings.start} className="assessment-intro-button" testId="startAssessmentButton" />
          {!isCVOnly && (
            <SeityHyperText
              title={strings.finishLater}
              onClick={() => {
                return setShowFinishLater(true);
              }}
            />
          )}
        </div>
      </div>
    </AssessWrap>
  );
};

export default AssessmentMainIntro;
