import { createSlice } from "@reduxjs/toolkit";
import { PlotlineInjectionInfo, PlotlineInjectionsResponse } from "../../api/plotlineTypes";
import apiClientWithAuth from "../../api/apiClient";
import { getFirstPlotlineInjectionId } from "./helpers";

export interface PlotlineState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly plotlineInjections: Array<PlotlineInjectionInfo>;
  readonly firstIncompletePLInjectionId: number | null;
}

const setInitalState = {
  error: null,
  isLoading: false,
  plotlineInjections: [],
  firstIncompletePLInjectionId: null
} as PlotlineState;

const plotlineSlice = createSlice({
  name: "practices-practices",
  initialState: setInitalState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPlotlineInjections(state, action) {
      state.plotlineInjections = action.payload;
    },
    setFirstIncompletePLInjectionId(state, action) {
      state.firstIncompletePLInjectionId = action.payload
    },
    resetPlotlineSlice: () => setInitalState
  }
});

export const { clearError, setIsLoading, setError, setPlotlineInjections, setFirstIncompletePLInjectionId, resetPlotlineSlice } = plotlineSlice.actions;

export default plotlineSlice.reducer;

const handleError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setError({ error: err.toString() }));
};

export const getPlotlineInjections = (callback?: (isCompleted: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get<PlotlineInjectionsResponse>("/Plotline/plotlineInjections");
    let firstIncompletePLInjectionId: number | null = null;
    if (res.data.success) {
      dispatch(setPlotlineInjections(res.data.data));
      firstIncompletePLInjectionId = getFirstPlotlineInjectionId(res.data.data);
      dispatch(setFirstIncompletePLInjectionId(firstIncompletePLInjectionId));
    }
    dispatch(setIsLoading(false));

    if (callback) {
      callback(firstIncompletePLInjectionId === null);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};
