/* eslint-disable prefer-const */
import { FunctionComponent, useEffect, useMemo } from "react";
import RequireSeityImage from "../../utils/graphicUtils/localGraphic";

import { capitalize } from "../../utils/stringUtils/formats";
import { SeityDragonListProps } from "./types";
import strings from "../../strings/strings";

import "./styles.scss";

const SeityDragonList: FunctionComponent<SeityDragonListProps> = (props) => {
  const { onToggleDragonItem, subCategoryData, emptyDataTitle, dragonTitle } = props;

  const caret = require("../../../assets/graphics/caret_white.png").default;

  const data = useMemo(() => {
    return subCategoryData.slice().sort((a, b) => {
      return b.subcategoryScore - a.subcategoryScore;
    });
  }, [subCategoryData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="dragons-title-container">
        <h3>
          {strings.my} {dragonTitle}s
        </h3>
        <span>{strings.scoreFromAssessment}</span>
      </div>
      {data && data.length > 0 ? (
        data.map((subCategory) => {
          return (
            <div
              onClick={() => {
                onToggleDragonItem(subCategory);
              }}
              key={subCategory.subcategoryID}
              className="dragon-detail"
            >
              <img
                className="dragon-detail-icon"
                src={RequireSeityImage(`${subCategory.subcategoryLogoURL}-t`).default}
                alt={subCategory.dragonText}
              />
              <h4>{subCategory.subcategoryName}</h4>
              <h5>{subCategory.subcategoryScore}%</h5>
              <img className="dragon-detail-caret" src={caret} alt="caret" />
            </div>
          );
        })
      ) : (
        <h3 className="dragon-detail-empty">{emptyDataTitle}</h3>
      )}
    </>
  );
};

export default SeityDragonList;
