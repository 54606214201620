import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPracticeSelection } from "../../api/assessment/seityHealthAPI-Assessment";
import { SeityAuthenticationError } from "../../api/authTypes";
import { AppThunk } from "../../app/store";
import { setRedirectToLogin } from "../auth/authSlice";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";

export interface AssessmentPrgoressState {
  readonly isLoading: boolean;
  readonly error: string | null;
  readonly denominator: number;
  readonly numerator: number;
  readonly answeredPracticeSelections: number[];
  readonly answeredOutcomeSelections: number[];
}

const assessmentProgressSlice = createSlice({
  name: "assessment-progress",
  initialState: {
    isLoading: false,
    error: null,
    denominator: 0,
    progressPercentage: 0,
    answeredPracticeSelections: [],
    answeredOutcomeSelections: [],
    numerator: 0
  } as AssessmentPrgoressState,
  reducers: {
    pushToAnsweredPracticeSelections(state, action: PayloadAction<number>) {
      if (state.answeredPracticeSelections.indexOf(action.payload) === -1) {
        state.answeredPracticeSelections.push(action.payload);
      }
    },
    pushToAnsweredOutcomeSelections(state, action: PayloadAction<number>) {
      if (state.answeredOutcomeSelections.indexOf(action.payload) === -1) {
        state.answeredOutcomeSelections.push(action.payload);
      }
    },
    setDenominator(state: { denominator: number }, action: PayloadAction<number>) {
      state.denominator = action.payload;
    },
    setNumerator(state: { numerator: number }, action: PayloadAction<number>) {
      state.numerator = action.payload;
    },
    apiError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    }
  }
});

export const { pushToAnsweredPracticeSelections, pushToAnsweredOutcomeSelections, setDenominator, setNumerator, apiError } =
  assessmentProgressSlice.actions;
export default assessmentProgressSlice.reducer;

export const sendProgressDenominatorRequest = (): AppThunk => async (dispatch) => {
  try {
    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }
    console.log("Getting assessment progress denominator...");

    const practiceSelectionResponse = await getPracticeSelection(token, 1, 1);
    const data: any = practiceSelectionResponse.data;
    const { practiceSelections } = data;

    const firstPracticeSelection = practiceSelections[0];
    let denominator = 0;

    denominator += firstPracticeSelection.worldEcosystemCount;
    denominator += firstPracticeSelection.worldFinancesCount;
    denominator += firstPracticeSelection.worldSafetyCount;
    denominator += firstPracticeSelection.worldRelationshipsCount;
    denominator += firstPracticeSelection.worldSocialTiesCount;
    denominator += firstPracticeSelection.worldWorkCount;

    denominator += firstPracticeSelection.bodyExerciseCount;
    denominator += firstPracticeSelection.bodyHealthcareCount;
    denominator += firstPracticeSelection.bodyNutritionCount;
    denominator += firstPracticeSelection.bodySleepCount;
    denominator += firstPracticeSelection.bodyAddictionPreventionCount;

    denominator += firstPracticeSelection.mindFeelingsCount;
    denominator += firstPracticeSelection.mindKnowledgeCount;
    denominator += firstPracticeSelection.mindMemoriesCount;
    denominator += firstPracticeSelection.mindThoughtsCount;

    denominator += firstPracticeSelection.spiritSpiritualTransactionsCount;
    denominator += firstPracticeSelection.spiritSpiritualConnectionsCount;
    denominator += firstPracticeSelection.spiritSpiritualTransactionsCount;

    denominator += firstPracticeSelection.outcomeQuestionCount;

    dispatch(setDenominator(denominator));
    console.log("Recieved assessment progress.");
  } catch (err) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};
