import { useHistory } from "react-router";

import SeityButton from "../../../_core/components/SeityButton";

import strings from "../../../_core/strings/strings";
import "../styles.scss";

export const ChallengeUnavailable = () => {
  const history = useHistory();

  const closePressed = () => {
    history.push("/");
  };

  return (
    <div className="challenge-status">
      <h1>{strings.challengeUnavailableHeader}</h1>
      <h4>{strings.challengeUnavailableMessage}</h4>
      <div className="challenge-status-button">
        <SeityButton label={strings.close} onClick={closePressed} />
      </div>
    </div>
  );
};
