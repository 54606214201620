import { FunctionComponent, MouseEventHandler } from "react";
import { AssessmentItem } from "./AssessmentItem";

import "../styles.scss";

const wholeHealth = require("../../../../assets/graphics/wholehealth.svg").default;

export interface AssessmentWholeHealthOutcomesProps {
  navigationFunction: MouseEventHandler;
  assessmentImageArray: string[];
  assessmentLabelArray: string[];
  assessmentScoreArray: string[];
  assessmentTextEngArray: string[];
}

export const AssessmentWholeHealthOutcomes: FunctionComponent<AssessmentWholeHealthOutcomesProps> = ({
  navigationFunction,
  assessmentImageArray,
  assessmentLabelArray,
  assessmentScoreArray,
  assessmentTextEngArray
}) => {
  return (
    <AssessmentItem
      assessmentScore={assessmentScoreArray.slice(0, 1).toString()}
      assessmentImage={wholeHealth}
      assessmentLabel={assessmentLabelArray.slice(0, 1).toString()}
      navigationFunction={navigationFunction}
      assessmentTextEng={assessmentTextEngArray.slice(0, 1).toString()}
    />
  );
};
