/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import { useAppContent } from "../../../hooks/useAppContent";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";

import "../styles.scss";

export const Coppa: FunctionComponent = (): ReactElement => {
  const { appContentLines, printContentText } = useAppContent(2);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });
  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  if (appContentLines.length >= 47) {
    return (
      <div className="pol-container">
        <SeityBackButton wrapperClassName="sd-back-button" />
        {/* heading */}
        <div className="pol-header">
          <h1>{printContentText(0)}</h1>
          <h3>{printContentText(1)}</h3>
        </div>
        {/* initial blurb */}
        <div className="pol-bubble-grey">
          <div className="margin-style-5">
            <p style={{ marginTop: 30 }}>{printContentText(2)}</p>
          </div>
          <div className="margin-style-1">
            <p>{printContentText(3)}</p>
          </div>
          <p className="mb-3">{printContentText(4)}</p>
        </div>
        {/* 1. Information we collect */}
        <div className="pol-content">
          <div className="pol-bubble-blue">
            <h4 className="ml-2">{printContentText(5)}</h4>
            <h5>{printContentText(6)}</h5>
            <p className="mb-0">{printContentText(7)}</p>
            <div className="margin-style-4">
              <p>{printContentText(8)}</p>
            </div>
            <h5>{printContentText(9)}</h5>
            <p>{printContentText(10)}</p>
            <div className="margin-style-4">
              <p>{printContentText(11)}</p>
            </div>
            <div className="margin-style-6">
              <p>{printContentText(12)}</p>
            </div>
          </div>
          {/* 2. How we use your information */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(13)}</h4>
            <div className="margin-style-6">
              <p>{printContentText(14)}</p>
            </div>
            <div className="pol-list-inner-alphabet margin-style-3">
              <ul>
                <li>{printContentText(15)}</li>
                <li>{printContentText(16)}</li>
                <li>{printContentText(17)}</li>
                <li>{printContentText(18)}</li>
                <li>{printContentText(19)}</li>
                <li>{printContentText(20)}</li>
                <li>{printContentText(21)}</li>
                <li>{printContentText(22)}</li>
                <li>{printContentText(23)}</li>
                <li>{printContentText(24)}</li>
              </ul>
            </div>
          </div>
          {/* 3.Disclosure of personal information to third parties */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(25)}</h4>
            <p>{printContentText(26)}</p>
            <div className="margin-style-3">
              <p>{printContentText(27)}</p>
            </div>
            <p>{printContentText(28)}</p>
            <div className="margin-style-3">
              <p>{printContentText(29)}</p>
            </div>
          </div>
          {/* 4. How we protect your information */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(30)}</h4>
            <p>{printContentText(31)}</p>
          </div>
          {/* 5. Accessing and Modify Account Information  */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(32)}</h4>
            <p>{printContentText(33)}</p>
            <div className="margin-style-3">
              <p>{printContentText(34)}</p>
            </div>
          </div>
          {/* 6. Your choices */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(35)}</h4>
            <p>{printContentText(36)}</p>
          </div>
          {/* 7. Links */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(37)}</h4>
            <p>{printContentText(38)}</p>
          </div>
          {/* 8. Important notice to users outside the US */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(39)}</h4>
            <p>{printContentText(40)}</p>
          </div>
          {/* 9. Changes to this privacy policy */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(41)}</h4>
            <p>{printContentText(42)}</p>
          </div>
          {/* 10. How to contact us */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(43)}</h4>
            <p>{printContentText(44)}</p>
            <div>
              <p>{printContentText(45)}</p>
            </div>
            <p>{printContentText(46)}</p>
            <div className="mb-3">
              <p>{printContentText(47)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
