import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sendAccountMaintenanceUpdateRequest, sendMessageCenterListRequest } from "../accountSlice";

import SeityLoader from "../../../_core/components/SeityLoader";
import { RootState } from "../../../app/rootReducer";
import { setLanguageID } from "../../myStory/myStorySlice";
import { switchLanguage } from "../../../app/utils";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import "moment/locale/es";

export const Language: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const [isLoading, setLoading] = useState(false);
  const [langId, setLangId] = useState(userAppSettings.languageID);
  const activeRadio = require("../../../assets/icons/active-radio.png").default;
  const inactiveRadio = require("../../../assets/icons/inactive-radio.png").default;

  const [languages, setLanguages] = useState<any>([]);

  const changeLanguage = (id: string, seityId: number) => {
    setLoading(true);
    dispatch(
      sendAccountMaintenanceUpdateRequest(
        {
          firstName: null,
          lastName: null,
          eMailAddress: null,
          accountStatusID: null,
          dateOfBirth: null,
          statusID: null,
          educationID: null,
          occupationID: null,
          genderID: null,
          maritalStatusID: null,
          zipcode: null,
          cellPhone: null,
          departmentID: null,
          jobPositionID: null,
          languageID: seityId
        }, true,
        () => {
          setLoading(false);

          setLangId(seityId);
          switchLanguage(seityId);

          dispatch(sendMessageCenterListRequest());
          dispatch(setLanguageID({ langID: seityId }));
        }
      )
    );
  };

  useEffect(() => {
    setLangId(userAppSettings.languageID);
  }, [userAppSettings.languageID]);

  useEffect(() => {
    // Resolve locale bug when refresh the browser: https://dev.azure.com/SeityHealthVision/Seity%20Health%20Essence/_workitems/edit/2971/
    setLanguages([
      {
        seityId: 1,
        id: "en-US",
        name: strings.english
      },
      {
        seityId: 2,
        id: "es",
        name: strings.spanish
      }
    ]);
  }, [langId]);

  return (
    <div className="language-container">
      {isLoading && <SeityLoader />}
      <h1>{strings.languagePreferences}</h1>
      <div className="language-list">
        {languages.map((lg, index) => {
          return (
            <div className="language-list-item" key={index} onClick={() => changeLanguage(lg.id, lg.seityId)}>
              <h2>{lg.name}</h2>
              <img className="checkbox language-list-radio" src={langId === lg.seityId ? activeRadio : inactiveRadio} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
