import { FunctionComponent, useState } from "react";
import Modal from "react-modal";

import strings from "../../strings/strings";

import "./styles.scss";

type SeityMoreInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SeityMoreInfoModal: FunctionComponent<SeityMoreInfoModalProps> = ({ isOpen, onClose }) => {
  Modal.setAppElement("#root");

  const [menuOpen, setMenuOpen] = useState({
    layers: false,
    lifestyle: false,
    dragon: false
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="seity-mi-modal" overlayClassName="seity-mi-overlay">
      <div className="flex flex-ai-c">
        <img src={require("../../../assets/graphics/intentions/intentions-info.png").default} alt="Individual" width={24} height={24} />
        <span className="ml-md mr-auto">{strings.moreInfo}</span>
        <button
          className="close-button"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <img src={require("../../../assets/graphics/journals/close.png").default} alt="close button" />
        </button>
      </div>
      <div className={`flex-col ${menuOpen.layers && "open"}`}>
        <button
          className="flex flex-ai-c w-100"
          onClick={(e) => {
            e.preventDefault();
            setMenuOpen({ ...menuOpen, layers: !menuOpen.layers });
          }}
        >
          <h3>{strings.layers}</h3>
          <img
            className={`flex-col ${menuOpen.layers ? "open" : ""}`}
            src={require("../../../assets/graphics/intentions/arrow-down.png").default}
            alt="arrow btn"
          />
        </button>
        {menuOpen.layers && (
          <>
            <p>{strings.moreInfoLayersDesc}</p>
            <div className="flex mt-md">
              {["world", "body", "mind", "spirit"].map((l) => {
                return (
                  <div className="flex-col flex-ai-c m-lg">
                    <img src={require(`../../../assets/graphics/${l}.png`).default} alt="layers" width={32} />
                    <span>{strings[l]}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className={`flex-col ${menuOpen.lifestyle && "open"}`}>
        <button
          className="flex flex-ai-c w-100"
          onClick={(e) => {
            e.preventDefault();
            setMenuOpen({ ...menuOpen, lifestyle: !menuOpen.lifestyle });
          }}
        >
          <h3>{strings.lifestyleElements}</h3>
          <img
            className={`flex-col ${menuOpen.lifestyle ? "open" : ""}`}
            src={require("../../../assets/graphics/intentions/arrow-down.png").default}
            alt="arrow btn"
          />
        </button>
        {menuOpen.lifestyle && (
          <>
            <p>{strings.moreInfoLifestyleDesc}</p>
            <div className="flex mt-md">
              {["world", "body", "mind", "spirit"].map((l) => {
                return (
                  <div className="flex-col flex-ai-c m-lg">
                    <img src={require(`../../../assets/graphics/intentions/lifestyle-${l}.png`).default} alt="lifestyle" width={65} />
                    <span>{strings[l]}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className={`flex-col ${menuOpen.dragon && "open"}`}>
        <button
          className="flex flex-ai-c w-100"
          onClick={(e) => {
            e.preventDefault();
            setMenuOpen({ ...menuOpen, dragon: !menuOpen.dragon });
          }}
        >
          <h3>{strings.dragonStatus}</h3>
          <img
            className={`flex-col ${menuOpen.dragon ? "open" : ""}`}
            src={require("../../../assets/graphics/intentions/arrow-down.png").default}
            alt="arrow btn"
          />
        </button>
        {menuOpen.dragon && (
          <>
            <p>{strings.moreInfoDragonsDesc}</p>
            <div className="flex-col mt-md">
              {["Raging", "Awake", "Tame"].map((d) => {
                return (
                  <div className="flex-col mt-lg p-lg">
                    <div>
                      <img
                        className="mr-md"
                        src={require(`../../../assets/graphics/dragon${d.toLowerCase()}01.png`).default}
                        alt="dragon"
                        width={32}
                      />
                      <span>{strings[`${d.toLowerCase()}Dragon`]}</span>
                    </div>
                    <p>{strings[`dragon${d}Desc`]}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SeityMoreInfoModal;
