/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent, ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";
import strings from "../../../_core/strings/strings";
import "../Settings/styles.scss";
import { RootState } from "../../../app/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { CredentialsType } from "../maintenance/ModifyAuthentication";
import formatPhoneNumber from "../../../_core/utils/countryCode/formatPhoneNumber";
import { sendGetPhoneCountryCodes } from "../../register/registerSlice";

export const Authentication: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });
  const history = useHistory();

  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });
  const { phoneCountryCodes } = useSelector((state: RootState) => {
    return state.register;
  });

  const { username, eMailAddress, cellPhone, phoneCountryCodeID } = accountInfo;

  const sections = [
    {
      title: strings.authenticationEmail,
      content: eMailAddress,
      imageName: "mail-icon",
      onClick: () => {
        if (!eMailAddress || eMailAddress.length === 0) {
          history.push({
            pathname: "/authentication/addCredential",
            state: { credentialsType: CredentialsType.Email }
          });
        } else {
          history.push({
            pathname: "/authentication/changeCredential",
            state: { credentialsType: CredentialsType.Email, checkPassword: true }
          });
        }
      },
      hidden: false,
      id: "email"
    },
    {
      title: strings.authenticationPhoneNumber,
      content: cellPhone && cellPhone.trim() !== "" ? formatPhoneNumber(cellPhone, phoneCountryCodeID, phoneCountryCodes) : null,
      imageName: "phone",
      onClick: () => {
        if (!cellPhone || cellPhone.length === 0) {
          history.push({ pathname: "/authentication/addCredential", state: { credentialsType: CredentialsType.PhoneNumber } });
        } else {
          history.push({
            pathname: "/authentication/changeCredential",
            state: { credentialsType: CredentialsType.PhoneNumber, checkPassword: true }
          });
        }
      },
      hidden: false,
      id: "phone"
    },
    {
      title: strings.authenticationUsername,
      imageName: "profile",
      content: username,
      onClick: () => {
        if (!username || username.length === 0) {
          history.push({ pathname: "/authentication/addCredential", state: { credentialsType: CredentialsType.Username } });
        } else {
          history.push({
            pathname: "/authentication/changeCredential",
            state: { credentialsType: CredentialsType.Username, checkPassword: true }
          });
        }
      },
      hidden: userAppSettings.doNotAllowPasswordChange,
      id: "username"
    },
    {
      title: strings.changePassword,
      imageName: "key",
      onClick: () => history.push("/changePassword"),
      hidden: userAppSettings.doNotAllowPasswordChange,
      id: "password"
    }
  ];

  useEffect(() => {
    if (!phoneCountryCodes) {
      dispatch(sendGetPhoneCountryCodes());
    }
  }, []);

  return (
    <div className="settings-container">
      <div className="section-list">
        {sections.map((section, index) => {
          return section.hidden ? (
            <></>
          ) : (
            <div className="section-list-item" key={index} onClick={section.onClick} id={section?.id}>
              <div className="item-container">
                <div className="item-header">
                  <img src={RequireSeityImage(section.imageName).default} />
                  <h2>{section.title}</h2>
                </div>
                {section.content?.length ? (
                  <div className="item-content">
                    <p>{section.content}</p>
                  </div>
                ) : section.title === strings.changePassword ? null : (
                  <div className="item-content">
                    <p className="add-text">{`+ ${strings.addCredentials} ${section.title.toLowerCase()}`}</p>
                  </div>
                )}
              </div>
              {section.content?.length ? (
                <img className="edit" src={require("../../../assets/graphics/intentions/edit.png").default} />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
