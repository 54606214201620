/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent, ReactElement } from "react";
import { LockedAccountProps } from "./types";
import Lock from "../../../assets/web-icons/lock@2x.png";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";

export const LockedAccount: FunctionComponent<LockedAccountProps> = (): ReactElement => {
  const { supportEmail } = useSelector((state: RootState) => {
    return state.account;
  });

  return (
    <div className="locked-account-wrapper">
      <img src={Lock} className="locked-account-lock" />
      <div className="locked-account-description-wrapper">
        <h1>{strings.lockOutHeader}</h1>
        <h5>{strings.lockOutDescription}</h5>
        <div className="locked-account-support-wrapper">
          {strings.lockOutContactp1}{" "}
          <a className="locked-account-support" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{" "}
          {strings.lockOutContactp2}
        </div>
        <span className="locked-account-signature">{strings.lockOutSignaturep1}</span>
        <span className="locked-account-signature">{strings.lockOutSignaturep2}</span>
        <span
          className="
          locked-account-signature"
        >
          {strings.lockOutSignaturep3}
        </span>
      </div>
    </div>
  );
};
