import { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

const caretBlack = require("../../../assets/graphics/caret-black.png").default;

export interface SecurityAndProtectionItemProps {
  title: string;
  onClick: () => void;
}

export const SecurityAndProtectionItem: FunctionComponent<SecurityAndProtectionItemProps> = ({ title, onClick }): ReactElement => {
  return (
    <div className="sap-item-container" onClick={onClick}>
      <h1>{title}</h1>
      <img src={caretBlack} alt="caret" width={10} height={18} />
    </div>
  );
};
