import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { SeityButton } from "../../_core/components/SeityButton";
import strings from "../../_core/strings/strings";

import "./styles.scss";

export const ResourceLanding = () => {
  const history = useHistory();
  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  if (shouldRedirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: "/"
          }
        }}
      />
    );
  }

  return (
    <div className="rl-container">
      <h1>{strings.getHelp.toUpperCase()}</h1>
      <div className="rl-crisis">
        <h2>{strings.inCrisisMessage}</h2>
        <SeityButton
          onClick={() => {
            history.push({
              pathname: "/checkIn/resources",
              state: { returnTo: "/" }
            });
          }}
          label={strings.inCrisisButtonText}
          style={{ width: "60%" }}
        />
      </div>
      <div className="rl-tech-support">
        <h3>{strings.techSupportHeading}</h3>
        <h5>{strings.techSupportDescription}</h5>
        <a href="mailto:support@seityhealth.com">
          <SeityButton label={strings.techSupportButtonText} type="cancel" />
        </a>
      </div>
    </div>
  );
};

export default ResourceLanding;
