/* eslint-disable default-case */
import { FunctionComponent } from "react";
import { SeityOutcomeCategory } from "../../../../api/baseTypes";
import { OutcomeValueSelection } from "../OutcomeValueSelectionContainer";

import strings from "../../../../_core/strings/strings";

import "./styles.scss";

interface OutcomeItemProps {
  category: SeityOutcomeCategory;
  value: number;
  images: Array<string>;
  selectedImages: Array<string>;
  id: string;
  valueChanged: (value: number) => void;
}

export const OutcomeItem: FunctionComponent<OutcomeItemProps> = ({ category, value, images, selectedImages, valueChanged, id }) => {
  const titlePrefix = strings.itemTitlePrefix;
  let titleBold = "";
  const titleSuffix = strings.itemTitleSuffix;
  switch (category) {
    case SeityOutcomeCategory.Belonging:
      titleBold = strings.itemTitleBelonging;
      break;
    case SeityOutcomeCategory.Direction:
      titleBold = strings.itemTitleDirection;
      break;
    case SeityOutcomeCategory.Energy:
      titleBold = strings.itemTitleEnergy;
      break;
    case SeityOutcomeCategory.Joy:
      titleBold = strings.itemTitleJoy;
      break;
  }

  return (
    <div className="outcome-item-container">
      <div className="outcome-item-inner-container">
        <p className="outcome-item-text outcome-first-item-text">{titlePrefix} </p>
        <p className="outcome-item-text outcome-item-text-bold">{titleBold}</p>
        <p className="outcome-item-text">{titleSuffix}</p>
      </div>
      <OutcomeValueSelection id={id} value={value} images={images} selectedImages={selectedImages} selectionChanged={valueChanged} />
    </div>
  );
};
