import { FunctionComponent, ReactElement, useState } from "react";

import CoreStrings from "../../../../_core/strings/strings";
import SeityModal from "../../../../_core/components/SeityModal";
import { SeityTextInput } from "../../../../_core/components/SeityTextInput/SeityTextInput";
import SeityButton from "../../../../_core/components/SeityButton";

export type FSEditNameModalProps = {
  name: string;
  onClose: (groupName?: string) => void;
  isPersonalList?: boolean;
};

const FSEditNameModal: FunctionComponent<FSEditNameModalProps> = ({ name, onClose, isPersonalList }): ReactElement => {
  const [groupName, setGroupName] = useState(name);

  return (
    <SeityModal
      wrapperClassName="fsNewFGModal"
      title={isPersonalList ? CoreStrings.editListName : CoreStrings.editFamilyName}
      showCloseBtn={true}
      isOpen={true}
      onClose={onClose}
    >
      <div className="fsNewFGModal-content">
        <SeityTextInput
          autoFocus={true}
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
          }}
        />
        <SeityButton
          label={isPersonalList ? CoreStrings.doneButtonTitle : CoreStrings.saveButtonText}
          disabled={groupName.length < 1}
          onClick={() => {
            onClose(groupName);
          }}
        />
        {!isPersonalList && <SeityButton type="cancel" label={CoreStrings.cancelButtonLabel} onClick={() => onClose()} />}
      </div>
    </SeityModal>
  );
};

export default FSEditNameModal;
