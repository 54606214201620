import { setCommonError } from "../slices/commonAlertSlice";

export const formatImagePath = (path: string, str: string, rootPath: string) => {
  // str: '~/graphics', '~/images'
  let ret = path;
  if (path.includes(str)) {
    ret = path.toLowerCase().replace(str, rootPath);
  } else {
    ret = rootPath + path;
  }

  return ret;
};

export const handleApiResponse = (dispatch: any, updateStore: any, response: any) => {
  if (response.data.success) {
    dispatch(updateStore(response.data.data));
  } else {
    dispatch(
      setCommonError(
        response.data.message && response.data.message.length > 0 ? response.data.message : "Hey this is api error. Try again!"
      )
    );
  }
};
