/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAppContent } from "../../../hooks/useAppContent";
import { RootState } from "../../../app/rootReducer";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";

import "../styles.scss";

export const Privacy: FunctionComponent = (): ReactElement => {
  const { appContentLines, printContentText } = useAppContent(3);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });
  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  if (appContentLines.length >= 54) {
    return (
      <div className="pol-container">
        <SeityBackButton wrapperClassName="sd-back-button" />
        {/* Heading */}
        <div className="pol-header">
          <h1>{printContentText(0)}</h1>
          <h3>{printContentText(1)}</h3>
        </div>
        {/* Purpose */}
        <div className="pol-content">
          <div className="pol-bubble-blue">
            <h4>{printContentText(2)}</h4>
            <p>{printContentText(3)}</p>
          </div>
          {/* Data Privacy Summary */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(4)}</h4>
            <div className="pol-list">
              <ul>
                <li>{printContentText(5)}</li>
                <li>{printContentText(6)}</li>
                <li>{printContentText(7)}</li>
                <li>{printContentText(8)}</li>
                <li>{printContentText(9)}</li>
              </ul>
            </div>
          </div>
          {/* Collection and user of personal information */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(10)}</h4>
            <p>{printContentText(11)}</p>
          </div>
          {/* What personal information we collect */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(12)}</h4>
            <p>{printContentText(13)}</p>
            <div className="pol-list-inner margin-style-1">
              <ul>
                <li>{printContentText(14)}</li>
                <li>{printContentText(15)}</li>
                <li>{printContentText(16)}</li>
                <li>{printContentText(17)}</li>
                <li>{printContentText(18)}</li>
                <li>{printContentText(19)}</li>
              </ul>
            </div>
            <p className="mb-3">{printContentText(20)}</p>
            <div className="pol-list-inner margin-style-1">
              <ul>
                <li>{printContentText(21)}</li>
                <li>{printContentText(22)}</li>
              </ul>
            </div>
            <p>{printContentText(23)}</p>
          </div>
          {/* How we use your personal information */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(24)}</h4>
            <p>{printContentText(25)}</p>
            <div className="pol-list-inner margin-style-2">
              <ul>
                <li>{printContentText(26)}</li>
                <li>{printContentText(27)}</li>
                <li>{printContentText(28)}</li>
                <li>{printContentText(29)}</li>
                <li>{printContentText(30)}</li>
                <li>{printContentText(31)}</li>
                <li>{printContentText(32)}</li>
                <li>{printContentText(33)}</li>
                <li>{printContentText(34)}</li>
              </ul>
            </div>
          </div>
          {/* Cookies and similar technologies used */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(35)}</h4>
            <p>{printContentText(36)}</p>
            <div className="margin-style-3">
              <p>{printContentText(37)}</p>
            </div>
          </div>
          {/* Disclosure to third parties */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(38)}</h4>
            <p>{printContentText(39)}</p>
            <div className="margin-style-3">
              <p>{printContentText(40)}</p>
            </div>
          </div>
          {/* Safeguarding personal information */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(41)}</h4>
            <p>{printContentText(42)}</p>
            <div className="margin-style-4">
              <p>{printContentText(43)}</p>
            </div>
            <p>{printContentText(44)}</p>
          </div>
          {/* Your controls and choices */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(45)}</h4>
            <p>{printContentText(46)}</p>
            <div className="pol-list-inner margin-style-4">
              <ul>
                <li>{printContentText(47)}</li>
                <li>{printContentText(48)}</li>
                <li>{printContentText(49)}</li>
                <li>{printContentText(50)}</li>
                <li>{printContentText(51)}</li>
                <li>{printContentText(52)}</li>
              </ul>
            </div>
            <p>{printContentText(53)}</p>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
