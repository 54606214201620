import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import SeityLoader from "../../_core/components/SeityLoader";
import { CoreValuesList } from "../grow/learn/CoreValuesList";
import "./styles.scss";
import { SeityTextInput } from "../../_core/components/SeityTextInput/SeityTextInput";
import { alphabet } from "./data";
import { AlphabeticalSeekBar } from "../grow/learn/CoreValuesList/AlphabeticalSeekBar";
import { sendGetCoreValuesCategorieDetails, sendGetSeityCoreValues } from "../grow/learn/guideSlice";
import { resetDirectorySlice, sendCoreValueDirectoryRequest } from "./directorySlice";
import { useDebounce } from "use-debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { ContactList } from "./ContactList";
import { useHistory, useLocation } from "react-router-dom";
import CoreStrings from "../../_core/strings/strings";
import SeityButton from "../../_core/components/SeityButton";
import { postPersonalListAddAccounts } from "../cvDirectory/slice";
import strings from "../../_core/languages/en.json";

export type DirectoryLocationProps = {
  isPersonalListMode?: boolean;
  personalListID?: number;
};

const initialPageCount = 1;
const initalApiParams = {
  alpha: undefined,
  search: undefined,
  page: initialPageCount,
  limit: undefined,
  coreValueIDs: []
};

export const Directory: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState<any>([]);
  const [groupedValues, setGroupedValues] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showFilters, setShowFilters] = useState(false);
  const [activeChar, setActiveChar] = useState<string>("");
  const [filtersList, setFiltersList] = useState<Array<number>>([]);
  const [currentRecordsCount, setCurrentRecordsCount] = useState(0);
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);
  const [currentPage, setCurrentPage] = useState(initialPageCount);
  const [resetSearchData, setResetSearchData] = useState(false);
  const { isLoading, accounts, coreValueDirectoryIndex, totalResultCount } = useSelector((state: RootState) => {
    return state.directory;
  });

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings
  })

  // Personal List Mode
  const { isLoading: plLoading } = useSelector((state: RootState) => state.personalListSlice);
  const location = useLocation<DirectoryLocationProps>();
  const history = useHistory();
  const [selectedAccounts, setSelectedAccounts] = useState<Array<number>>([]);
  let isPersonalListMode = false;
  let personalListID;

  if (location.state) {
    if (location.state.isPersonalListMode === true) {
      isPersonalListMode = true;
      personalListID = location.state.personalListID;
    }
  }

  const addSelectedAccountsToPersonalList = () => {
    dispatch(postPersonalListAddAccounts(selectedAccounts, personalListID, () => history.push(`/personalList/${personalListID}`)));
  };
  // Personal List Mode

  /** Get Directory */
  const getDirectory = () => {
    console.log(isPersonalListMode)
    const params = {
      ...initalApiParams,
      alpha: activeChar.toString() || undefined,
      search: debouncedSearchValue.toString() || undefined,
      coreValueIDs: filtersList,
      isPersonalListMode: isPersonalListMode,
      personalListID: personalListID
      
    };
    setResetSearchData(true);
    setCurrentPage(params.page);
    dispatch(sendCoreValueDirectoryRequest(params));
  };

  /** Load More Directory */
  const loadMoreDirectory = async () => {
    console.log(isPersonalListMode)

    const params = {
      ...initalApiParams,
      alpha: activeChar.toString() || undefined,
      search: debouncedSearchValue.toString() || undefined,
      page: currentPage + 1,
      coreValueIDs: filtersList,
      isPersonalListMode: isPersonalListMode,
      personalListID: personalListID
    };
    setCurrentPage(params.page);
    dispatch(sendCoreValueDirectoryRequest(params));
  };

  const getDirectoryIndexes = () => {
    /** Reset the API */
    const params = {
      ...initalApiParams,
      isPersonalListMode: isPersonalListMode,
      personalListID: personalListID
    }
    dispatch(sendCoreValueDirectoryRequest(params));
    setCurrentPage(initialPageCount);
  };

  /** Initial API calls */
  useEffect(() => {
    dispatch(resetDirectorySlice());
    //getDirectoryIndexes();
    dispatch(sendGetCoreValuesCategorieDetails());
    dispatch(sendGetSeityCoreValues());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      if (activeChar !== "" || debouncedSearchValue !== "" || filtersList.length > 0) {
        getDirectory();
      } else {
        getDirectoryIndexes();
      }
    }
  }, [activeChar, debouncedSearchValue, filtersList.length]);

  const updateData = async () => {
    let contacts = [...accounts];
    /** Append API response data to previous */
    if (!resetSearchData) {
      const existingData = data.flatMap((group) => {
        return group.data;
      });
      contacts = [...existingData, ...accounts];

      const uniqueArray = Array.from(
        new Set(
          contacts.map((obj) => {
            return obj.accountID;
          })
        )
      ).map((accountID) => {
        return contacts.find((obj) => {
          return obj.accountID === accountID;
        });
      });
      //@ts-ignore
      contacts = uniqueArray.sort((a, b) => {
        //@ts-ignore
        return a.lastName.localeCompare(b.lastName);
      });
    }

    let v = [];
    const sectionListData = Object.entries(
      contacts.reduce((result, item) => {
        const firstLetter = item.lastName.charAt(0).toLowerCase();
        if (!result[firstLetter]) {
          result[firstLetter] = [];
        }
        result[firstLetter].push(item);
        return result;
      }, {})
    ).map(([title, data]) => {
      v[title.toUpperCase()] = data;
      return { title: title.toUpperCase(), data };
    });

    setResetSearchData(false);
    await setGroupedValues(v);
    await setData(sectionListData);
    await setCurrentRecordsCount(
      sectionListData.flatMap((group) => {
        return group.data;
      }).length
    );
  };

  /** Format the response data */
  useEffect(() => {
    updateData();
  }, [accounts, coreValueDirectoryIndex]);

  const cvIndexArray = useMemo(() => {
    if (coreValueDirectoryIndex) {
      let v = [];
      const tempKeysArr = Object.keys(coreValueDirectoryIndex);
      tempKeysArr.forEach((char) => {
        v[char.toUpperCase()] = new Array(coreValueDirectoryIndex[char]);
      });
      return v;
    }
    return [];
  }, [coreValueDirectoryIndex]);

  return (
    <div className="directory-container seity-main-fullwidth">
      <div className="fixed-header">
        <div className="cta-row">
          <SeityTextInput
            type="text"
            className="search-input"
            containerClassname="search-input-container"
            leftIconClassname="search-input-icon"
            onChange={(e) => {
              return setSearchValue(e.target.value);
            }}
            value={searchValue}
            leftIcon={require("../../assets/icons/search.png").default}
            placeholder="Search"
          />
          <div className="filter-button" style={{ backgroundColor: filtersList.length ? "#bbdadb" : "" }}>
            {filtersList.length > 0 && (
              <div className="filter-count">
                <p>({filtersList.length})</p>
              </div>
            )}
            <img src={require("../../assets/icons/filter.png").default} alt="filters" onClick={() => setShowFilters(true)} />
          </div>
        </div>
        {(userAppSettings.coreValueDirectoryDisplayNameEnabled === false || userAppSettings.coreValueDirectoryCoreValuesEnabled === false) &&<p className="mt-4 mb-3 permission-text">{strings.permissionModalBodyGoTo}{" "}<a className="permission-modal-link" onClick={() => history.push('/permissions/coreValueDirectory')}>{strings.permissionModalBodyLinkText}</a>
        {" "}{strings.permissionModalBodyText}</p>}       
         <AlphabeticalSeekBar
          activeChar={activeChar}
          setActiveChar={setActiveChar}
          groupedValues={cvIndexArray}
          categorySuffix="contact-list"
        />
      </div>

      <div className="scroll-container">
        <InfiniteScroll
          dataLength={currentRecordsCount}
          next={loadMoreDirectory}
          hasMore={totalResultCount > currentRecordsCount}
          loader={(isLoading || plLoading) && <SeityLoader />}
          scrollThreshold={1}
        >
          <ContactList
            alphabetArray={alphabet}
            groupedValues={groupedValues}
            searchValue={searchValue}
            isPersonalListMode={isPersonalListMode}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
          />
        </InfiniteScroll>
      </div>      
      {showFilters && (
        <div className="filters-container" id="filters-container">
          <CoreValuesList
            filtersMode={true}
            filtersCloseFunc={() => setShowFilters(false)}
            setFiltersList={setFiltersList}
            filtersList={filtersList}
            containerId="filters-container"
          />
        </div>
      )}
      {isPersonalListMode && (
        <div className="done-button-container">
          <SeityButton
            label={CoreStrings.doneButtonTitle}
            disabled={selectedAccounts.length === 0}
            onClick={addSelectedAccountsToPersonalList}
            className="personal-list-done-button"
          />
        </div>
      )}
    </div>
  );
};
