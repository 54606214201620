import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import "./styles.scss";
import { PlotlineQuestionData } from "../../../../api/plotlineTypes";
import { GRAPHICS_URL } from "../../../../api/apiConstants";

export type PlotlineTemplateQuizProps = {
  question: PlotlineQuestionData | null;
  onSelAnswers: (selAns: Array<number>) => void;
};

const PlotlineTemplateQuiz: FunctionComponent<PlotlineTemplateQuizProps> = (props): ReactElement => {
  const [selectedAnswers, setSelectedAnswers] = useState<Array<number>>([]);
  const { question, onSelAnswers } = props;

  const toggleSelect = (id) => {
    if (selectedAnswers.includes(id)) {
      // Deselect
      if (question?.isQuestionMultiselect) {
        const index = selectedAnswers.indexOf(id);
        let tempArr = [...selectedAnswers];
        tempArr.splice(index, 1);
        setSelectedAnswers(tempArr);
      } else {
        setSelectedAnswers([]);
      }
    } else {
      // Select
      if (question?.isQuestionMultiselect) {
        setSelectedAnswers([...selectedAnswers, id]);
      } else {
        setSelectedAnswers([id]);
      }
    }
  };

  useEffect(() => {
    onSelAnswers(selectedAnswers);
  }, [selectedAnswers]);

  return (
    <div className="plotline-quiz-body">
      {/* <p className="question">{question?.questionText}</p> */}
      <div className="answer-grid">
        {question?.answers.map((answer) => (
          <button
            key={answer.answerID}
            className={selectedAnswers.includes(answer.answerID) ? "answer-card selected" : "answer-card"}
            onClick={() => toggleSelect(answer.answerID)}
          >
            {answer.image?.length > 0 && <img src={`${GRAPHICS_URL + answer.image}`} />}
            <p>{answer.text}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default PlotlineTemplateQuiz;
