/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import Image from "react-bootstrap/Image";

import { AssessmentSectionIntroProps } from "./types";

import coreValIntroStep from "../../../../assets/graphics/icn-step-core.png";
import outcomesIntroStep from "../../../../assets/graphics/icn-step-outcomes.png";
import practicesIntroStep from "../../../../assets/graphics/icn-step-practices.png";
import coreValIntroImg from "../../../../assets/graphics/img-core-web.png";
import practicesIntroImg from "../../../../assets/graphics/img-practices-web.png";
import outcomesIntroImg from "../../../../assets/graphics/img-outcome-web.png";

import strings from "../../../../_core/strings/strings";

import "./styles.scss";
import { RootState } from "../../../../app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import SeityLoader from "../../../../_core/components/SeityLoader";
import CVIncentiveModal from "../../coreValues/CoreValuesIncentiveModal";
import { getCVIncentiveRequest } from "../../cvIncentiveSlice";

export const CORE_VALUE_KEY = "coreValue";
export const PRACTICES_KEY = "practices";
export const OUTCOMES_KEY = "outcomes";

export const AssessmentSectionIntro: FunctionComponent<AssessmentSectionIntroProps> = (props) => {
  const dispatch = useDispatch();
  const [isShowIncentive, setShowIncentive] = useState(false);
  const { isLoading: loadingCVIncentive, data: cvIncentiveData } = useSelector((state: RootState) => state.coreValuesIncentive);
  const { introTitle, pageNumberImage, pageText, pageIconGroupImage } = useMemo(() => {
    let introTitle = strings.coreValuesIntroHeader;
    let pageNumberImage = coreValIntroStep;
    let pageText = strings.coreValuesIntroText;
    let pageIconGroupImage = coreValIntroImg;

    if (props.mode === PRACTICES_KEY) {
      introTitle = strings.practices;
      pageNumberImage = practicesIntroStep;
      pageText = strings.practicesIntroText;
      pageIconGroupImage = practicesIntroImg;
    } else if (props.mode === OUTCOMES_KEY) {
      introTitle = strings.outcomesIntroHeader;
      pageNumberImage = outcomesIntroStep;
      pageText = strings.outcomesIntroText;
      pageIconGroupImage = outcomesIntroImg;
    }

    return {
      introTitle,
      pageNumberImage,
      pageText,
      pageIconGroupImage
    };
  }, [props.mode]);

  useEffect(() => {
    if (cvIncentiveData && cvIncentiveData.isActive) {
      if (props.mode === PRACTICES_KEY) {
        setShowIncentive(true);
      }
    }
  }, [cvIncentiveData]);

  useEffect(() => {
    if (props.mode === PRACTICES_KEY) {
      dispatch(getCVIncentiveRequest());
    }
  }, []);

  // go back and add global strings
  if (!loadingCVIncentive) {
    return (
      <div className="assess-section-intro-container">
        <h1>{introTitle}</h1>
        <Image className="number-img" src={pageNumberImage} />
        <p className="assess-section-intro-text">{pageText}</p>
        <Image className="assess-intro-img" src={pageIconGroupImage} />
        <CVIncentiveModal isVisible={isShowIncentive} setVisible={setShowIncentive} onCloseParent={() => {}} data={cvIncentiveData} />
      </div>
    );
  }

  return <SeityLoader showBackgroundMask />;
};
