/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { useState } from "react";
import FinishSurveyLaterModal from "../base/FinishSurveytLaterModal";

import "./styles.scss";

export const SurveyWrap = ({ children, showFinishLater, setShowFinishLater, label, subHeader }) => {
  return (
    <div className="d-flex flex-column assess-container survey-top">
      <div className="sw-header">
        <div className="sw-header__top">
          <span className="sw-header__title">{label}</span>
        </div>
        {subHeader}
      </div>
      {children}
      <FinishSurveyLaterModal
        visible={showFinishLater}
        onToggle={() => {
          setShowFinishLater(false);
        }}
      />
    </div>
  );
};

export default SurveyWrap;
