/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/require-default-props */
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import MoreInfo from "../../../assets/web-icons/More_info_icon.png";
import CloseIcon from "../../../assets/web-icons/close.png";
import MoreInfoWhite from "../../../assets/web-icons/More_info_icon_white.png";
import "./styles.css";

interface InfoIconProps {
  text?: string;
  imageStyle?: {} | undefined;
}

const iconClicked = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
  e.preventDefault();
  document.body.click();
};

export const MoreInfoIcon = (props: InfoIconProps) => {
  const imageStyle = props.imageStyle ?? {};

  return (
    <OverlayTrigger
      trigger="click"
      key="info-text"
      placement="top"
      rootClose
      overlay={
        <Popover id="popover-basic">
          <Popover.Content>
            <div className="text-right">
              <img
                className="info-close-icon"
                src={CloseIcon}
                onClick={(e) => {
                  return iconClicked(e);
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <img src={MoreInfoWhite} />
            </div>
            <div className="d-flex justify-content-center info-text">{props.text}</div>
          </Popover.Content>
        </Popover>
      }
    >
      <img className="more-info-icon" src={MoreInfo} style={imageStyle} />
    </OverlayTrigger>
  );
};
