export default function RequireCVImage(imageName: string) {
  let formattedString: string = String(imageName).toLowerCase().replace(/\s/g, "").replace(/-/g, "");
  if (formattedString.length > 4) {
    if (formattedString.includes(".png")) {
      formattedString = formattedString.substring(0, formattedString.length - 4);
    }
  }

  switch (formattedString) {
    case "resourceful":
    case "resourcefulness":
      formattedString = "resourceful";
      break;
    case "self-direction":
    case "selfdirection":
      formattedString = "selfdirection";
      break;
    case "self-discipline":
    case "selfdiscipline":
      formattedString = "selfdiscipline";
      break;
    case "adaptability":
      formattedString = "adaptation";
      break;
    default:
      break;
  }

  return require(`../../../assets/corevalues/${formattedString}_off.png`).default;

  // switch (formattedString) {
  //   //core values
  //   case 'achievement':
  //     returnValue = require('../../../assets/icons/achievement.png');
  //     break;
  //   case 'adaptability':
  //     returnValue = require('../../../assets/icons/adaptability.png');
  //     break;
  //   case 'adaptation':
  //     returnValue = require('../../../assets/icons/adaptation.png');
  //     break;
  //   case 'adventure':
  //     returnValue = require('../../../assets/icons/adventure.png');
  //     break;
  //   case 'aliveness':
  //     returnValue = require('../../../assets/icons/aliveness.png');
  //     break;
  //   case 'authenticity':
  //     returnValue = require('../../../assets/icons/authenticity.png');
  //     break;
  //   case 'battle':
  //     returnValue = require('../../../assets/icons/battle.png');
  //     break;
  //   case 'beauty':
  //     returnValue = require('../../../assets/icons/beauty.png');
  //     break;
  //   case 'belonging':
  //     returnValue = require('../../../assets/icons/belonging.png');
  //     break;
  //   case 'change':
  //     returnValue = require('../../../assets/icons/change.png');
  //     break;
  //   case 'civility':
  //     returnValue = require('../../../assets/icons/civility.png');
  //     break;
  //   case 'collaboration':
  //     returnValue = require('../../../assets/icons/collaboration.png');
  //     break;
  //   case 'compassion':
  //     returnValue = require('../../../assets/icons/compassion.png');
  //     break;
  //   case 'competition':
  //     returnValue = require('../../../assets/icons/competition.png');
  //     break;
  //   case 'confidence':
  //     returnValue = require('../../../assets/icons/confidence.png');
  //     break;
  //   case 'conformity':
  //     returnValue = require('../../../assets/icons/conformity.png');
  //     break;
  //   case 'connection':
  //     returnValue = require('../../../assets/icons/connection.png');
  //     break;
  //   case 'courage':
  //     returnValue = require('../../../assets/icons/courage.png');
  //     break;
  //   case 'creativity':
  //     returnValue = require('../../../assets/icons/creativity.png');
  //     break;
  //   case 'curiosity':
  //     returnValue = require('../../../assets/icons/curiosity.png');
  //     break;
  //   case 'dependability':
  //     returnValue = require('../../../assets/icons/dependability.png');
  //     break;
  //   case 'details':
  //     returnValue = require('../../../assets/icons/details.png');
  //     break;
  //   case 'determination':
  //     returnValue = require('../../../assets/icons/determination.png');
  //     break;
  //   case 'diversity':
  //     returnValue = require('../../../assets/icons/diversity.png');
  //     break;
  //   case 'encouragement':
  //     returnValue = require('../../../assets/icons/encouragement.png');
  //     break;
  //   case 'enlightenment':
  //     returnValue = require('../../../assets/icons/enlightenment.png');
  //     break;
  //   case 'equality':
  //     returnValue = require('../../../assets/icons/equality.png');
  //     break;
  //   case 'faith':
  //     returnValue = require('../../../assets/icons/faith.png');
  //     break;
  //   case 'flexibility':
  //     returnValue = require('../../../assets/icons/flexibility.png');
  //     break;
  //   case 'freedom':
  //     returnValue = require('../../../assets/icons/freedom.png');
  //     break;
  //   case 'fun':
  //     returnValue = require('../../../assets/icons/fun.png');
  //     break;
  //   case 'generosity':
  //     returnValue = require('../../../assets/icons/generosity.png');
  //     break;
  //   case 'grace':
  //     returnValue = require('../../../assets/icons/grace.png');
  //     break;
  //   case 'growth':
  //     returnValue = require('../../../assets/icons/growth.png');
  //     break;
  //   case 'harmony':
  //     returnValue = require('../../../assets/icons/harmony.png');
  //     break;
  //   case 'honor':
  //     returnValue = require('../../../assets/icons/honor.png');
  //     break;
  //   case 'hope':
  //     returnValue = require('../../../assets/icons/hope.png');
  //     break;
  //   case 'humility':
  //     returnValue = require('../../../assets/icons/humility.png');
  //     break;
  //   case 'humor':
  //     returnValue = require('../../../assets/icons/humor.png');
  //     break;
  //   case 'imagination':
  //     returnValue = require('../../../assets/icons/imagination.png');
  //     break;
  //   case 'impact':
  //     returnValue = require('../../../assets/icons/impact.png');
  //     break;
  //   case 'independence':
  //     returnValue = require('../../../assets/icons/independence.png');
  //     break;
  //   case 'individuality':
  //     returnValue = require('../../../assets/icons/individuality.png');
  //     break;
  //   case 'innovation':
  //     returnValue = require('../../../assets/icons/innovation.png');
  //     break;
  //   case 'integrity':
  //     returnValue = require('../../../assets/icons/integrity.png');
  //     break;
  //   case 'intuition':
  //     returnValue = require('../../../assets/icons/intuition.png');
  //     break;
  //   case 'kindness':
  //     returnValue = require('../../../assets/icons/kindness.png');
  //     break;
  //   case 'learning':
  //     returnValue = require('../../../assets/icons/learning.png');
  //     break;
  //   case 'loyalty':
  //     returnValue = require('../../../assets/icons/loyalty.png');
  //     break;
  //   case 'mastery':
  //     returnValue = require('../../../assets/icons/mastery.png');
  //     break;
  //   case 'movement':
  //     returnValue = require('../../../assets/icons/movement.png');
  //     break;
  //   case 'nature':
  //     returnValue = require('../../../assets/icons/nature.png');
  //     break;
  //   case 'optimism':
  //     returnValue = require('../../../assets/icons/optimism.png');
  //     break;
  //   case 'organization':
  //     returnValue = require('../../../assets/icons/organization.png');
  //     break;
  //   case 'peace':
  //     returnValue = require('../../../assets/icons/peace.png');
  //     break;
  //   case 'plans':
  //     returnValue = require('../../../assets/icons/plans.png');
  //     break;
  //   case 'playfulness':
  //     returnValue = require('../../../assets/icons/playfulness.png');
  //     break;
  //   case 'preservation':
  //     returnValue = require('../../../assets/icons/preservation.png');
  //     break;
  //   case 'protection':
  //     returnValue = require('../../../assets/icons/protection.png');
  //     break;
  //   case 'reasoning':
  //     returnValue = require('../../../assets/icons/reasoning.png');
  //     break;
  //   case 'resilience':
  //     returnValue = require('../../../assets/icons/resilience.png');
  //     break;
  //   case 'resourceful':
  //   case 'resourcefulness':
  //     returnValue = require('../../../assets/icons/resourcefulness.png');
  //     break;
  //   case 'results':
  //     returnValue = require('../../../assets/icons/results.png');
  //     break;
  //   case 'respect':
  //     returnValue = require('../../../assets/icons/respect.png');
  //     break;
  //   case 'responsibility':
  //     returnValue = require('../../../assets/icons/responsibility.png');
  //     break;
  //   case 'reward':
  //     returnValue = require('../../../assets/icons/reward.png');
  //     break;
  //   case 'risk-taking':
  //   case 'risktaking':
  //     returnValue = require('../../../assets/icons/riskTaking.png');
  //     break;
  //   case 'safety':
  //     returnValue = require('../../../assets/icons/safety.png');
  //     break;
  //   case 'security':
  //     returnValue = require('../../../assets/icons/security.png');
  //     break;
  //   case 'selfcontrol':
  //     returnValue = require('../../../assets/icons/selfcontrol.png');
  //     break;
  //   case 'self-direction':
  //   case 'selfdirection':
  //     returnValue = require('../../../assets/icons/selfdirection.png');
  //     break;
  //   case 'self-discipline':
  //   case 'selfdiscipline':
  //     returnValue = require('../../../assets/icons/selfdiscipline.png');
  //     break;
  //   case 'service':
  //     returnValue = require('../../../assets/icons/service.png');
  //     break;
  //   case 'socializing':
  //     returnValue = require('../../../assets/icons/socializing.png');
  //     break;
  //   case 'spirituality':
  //     returnValue = require('../../../assets/icons/spirituality.png');
  //     break;
  //   case 'spontaneity':
  //     returnValue = require('../../../assets/icons/spontaneity.png');
  //     break;
  //   case 'stability':
  //     returnValue = require('../../../assets/icons/stability.png');
  //     break;
  //   case 'strategy':
  //     returnValue = require('../../../assets/icons/strategy.png');
  //     break;
  //   case 'strength':
  //     returnValue = require('../../../assets/icons/strength.png');
  //     break;
  //   case 'synergy':
  //     returnValue = require('../../../assets/icons/synergy.png');
  //     break;
  //   case 'teamwork':
  //     returnValue = require('../../../assets/icons/teamwork.png');
  //     break;
  //   case 'tradition':
  //     returnValue = require('../../../assets/icons/tradition.png');
  //     break;
  //   case 'trustworthy':
  //     returnValue = require('../../../assets/icons/trustworthy.png');
  //     break;
  //   case 'truth':
  //     returnValue = require('../../../assets/icons/truth.png');
  //     break;
  //   case 'vision':
  //     returnValue = require('../../../assets/icons/vision.png');
  //     break;
  //   case 'wisdom':
  //     returnValue = require('../../../assets/icons/wisdom.png');
  //     break;
  //   case 'wholehealth01':
  //     returnValue = require('../../../assets/graphics/wellbeing02.png');
  //     break;
  //   default:
  //     returnValue = require('../../../assets/graphics/corevalue02.png');
  //     break;
  // }
}
