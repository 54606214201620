/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import { RootState } from "../../../../app/rootReducer";

import { SeityFormTextInput } from "../../../../_core/components/SeityTextInput/SeityFormTextInput";
import RegisterWrapper from "../../RegisterWrapper";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";
import SeityButton from "../../../../_core/components/SeityButton";
import InputMask from "react-input-mask";
import strings from "../../../../_core/strings/strings";
import "./style.scss";
import { sendGetPhoneCountryCodes, sendPostCheckCredentialsAvailable, sendPutRegistration02, setCurrentInfo } from "../../registerSlice";
import { SeityFormSelectBox } from "../../../../_core/components/SeitySelectBox/SeityFormSelectBox";

const US_CC_ID = 229;

export const RegisterFour = () => {
  const dispatch = useDispatch();

  const { registerError, emailRequired, phoneCountryCodes, current, emailValidationRequired } = useSelector((state: RootState) => {
    return state.register;
  });
  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });
  const history = useHistory();

  const getCountryCodeById = (phoneCountryCodeID, phoneCountryCodesList) => {
    // Iterate through the array to find the matching ID
    const matchingCode = phoneCountryCodesList.find((code) => code.phoneCountryCodeID === phoneCountryCodeID);

    // If a match is found, return the countryCode, otherwise return a message or null
    return matchingCode ? `+${matchingCode.countryCode}` : null;
  };

  const [email, setEmail] = useState(current?.emailAddress ?? "");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [countryCodeID, setCountryCodeID] = useState(0);
  const [cellPhone, setCellPhone] = useState(current?.cellPhone ?? "");
  const [userId, setUserId] = useState(current?.userId ?? "");
  const [countryCodeFieldDisplay, setCountryCodeFieldDisplay] = useState<string | null>("");

  useEffect(() => {
    dispatch(sendGetPhoneCountryCodes());
  }, []);

  useEffect(() => {
    if (phoneCountryCodes) {
      if (current.phoneCountryCodeID) {
        setCountryCodeID(current.phoneCountryCodeID);
        setCountryCodeFieldDisplay(getCountryCodeById(current.phoneCountryCodeID, phoneCountryCodes));
      } else {
        setCountryCodeID(US_CC_ID);
        setCountryCodeFieldDisplay("+1");
      }
    }
  }, [phoneCountryCodes]);

  const nextPressed = () => {
    if (emailRequired && !isEmail(email)) {
      setValidationError(strings.emailInvalid);
      return;
    }
    if (userId && (userId.length < 8 || userId.length > 50)) {
      setValidationError(strings.userIdLength);
      return;
    }
    if (isEmail(userId)) {
      setValidationError(strings.userIdFormat);
      return;
    }
    if (cellPhone && !isMobilePhone(cellPhone)) {
      setValidationError(strings.cellPhoneInvalid);
      return;
    }
    dispatch(
      sendPostCheckCredentialsAvailable(email, userId, () => {
        dispatch(
          setCurrentInfo({
            emailAddress: email,
            cellPhone: cellPhone,
            userId: userId.trim(),
            phoneCountryCodeID: countryCodeID
          })
        );
        if (accountInfo.accountID) {
          const redirectUrl = emailValidationRequired ? "/emailVerification" : "/phoneVerification";
          dispatch(
            sendPutRegistration02(email, cellPhone, countryCodeID, userId.trim(), () =>
              history.push({
                pathname: redirectUrl,
                state: {
                  isFromRegistration: true
                }
              })
            )
          );
        }
        setValidationError("");
        history.push("/register/registerFive");
      })
    );
  };

  const onEmailChange = (email) => {
    setEmail(email);
    if (isEmail(email)) {
      setEmailInvalid(false);
    } else {
      setEmailInvalid(true);
    }
  };

  const onCellPhoneChanged = (cellPhone: string) => {
    setCellPhone(cellPhone.replace(/[-\s\(\)]/g, ""));
  };

  const onUserIdChange = (userId) => {
    setUserId(userId);
  };

  const onHandleChange = (setStatusHandler: (statusChange: number) => void, statusChange: number) => {
    setStatusHandler(statusChange);
  };

  const isNextDisabled = emailRequired ? email === "" : email === "" && (cellPhone === "" || userId === "");

  return (
    <RegisterWrapper>
      <div className="register-four-container">
        <div>
          <h1>{strings.heading}</h1>
          <SeityIndicator currentStep={4} numberOfSteps={4} />
          <h5>{strings.stepFourSubHeading}</h5>
          <form className="textbox-form">
            <SeityFormTextInput
              inputLabelText={strings.emailLabel}
              testID="email"
              type="email"
              onChange={(e) => {
                return onEmailChange(e.target.value);
              }}
              value={email}
              isInvalid={emailInvalid}
              isRequired={emailRequired}
            />
            <div className="country-code-phone-number">
              <SeityFormSelectBox
                id="country-code-id"
                inputLabelText={strings.cellPhoneLabel}
                onChange={(e) => {
                  setCountryCodeFieldDisplay(getCountryCodeById(e.target.value, phoneCountryCodes));
                  return onHandleChange(setCountryCodeID, parseInt(e.target.value));
                }}
                value={countryCodeFieldDisplay}
              >
                <ul>
                  {phoneCountryCodes?.map((item) => {
                    return (
                      <li key={item.phoneCountryCodeID} value={item.phoneCountryCodeID}>
                        {item.countryName} (+{item.countryCode})
                      </li>
                    );
                  })}
                </ul>
              </SeityFormSelectBox>
              <InputMask
                mask="(999)-999-9999"
                onChange={(e) => {
                  return onCellPhoneChanged(e.target.value);
                }}
                value={cellPhone}
              >
                <SeityFormTextInput inputLabelText={""} testID="cell-phone" />
              </InputMask>
            </div>
            <SeityFormTextInput
              inputLabelText={strings.authenticationUsername}
              testID="user-id"
              type="text"
              onChange={(e) => {
                return onUserIdChange(e.target.value);
              }}
              value={userId}
              maxLength={50}
            />
          </form>
          <div className="register-four-container-error">{registerError || validationError}</div>
        </div>
        <div className="register-two-container-bottom">
          <SeityButton
            onClick={nextPressed}
            type="next"
            disabled={isNextDisabled}
            label={strings.nextButtonText}
            className="register-four-next-button"
            testId="next-button"
          />
        </div>
      </div>
    </RegisterWrapper>
  );
};
