import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import { GRAPHICS_URL } from "../../../../api/apiConstants";
import { getLayoutProps } from "../../helpers";
import { PlotlineTemplateID } from "../../../../api/plotlineTypes";

export type PlotlineTemplateHeaderProps = {
  plotlineTemplateID: PlotlineTemplateID | null;
  iconImage?: string | null;
  subHeader?: string | null;
  header?: string | null;
  text?: string | null;
  title2?: string | null;
};

export const PlotlineTemplateHeader: FunctionComponent<PlotlineTemplateHeaderProps> = (props): ReactElement => {
  const { plotlineTemplateID: id, iconImage, subHeader, header, text, title2 } = props;

  const { displayTag, displayIcon, iconSize } = getLayoutProps(id);

  return (
    <div className={`plotline-header-body plotline-header-mt-${id}`}>
      {displayTag && (iconImage ?? "").length > 0 && (
        <div className="tag-body">
          <img src={GRAPHICS_URL + iconImage} />
        </div>
      )}
      <div className={`copy-text-container-${id}`}>
        <p className={`subtitle subtitle-${id}`}>{subHeader}</p>
        <div className={`icon-heading-${id} mt-lg mb-lg`}>
          {id === PlotlineTemplateID.FullPageContentTopAlignedVideo01 && <p className="title2">{title2}</p>}
          {displayIcon && (iconImage ?? "").length > 0 && <img className={`icon-${iconSize}`} src={GRAPHICS_URL + iconImage} />}
          <p className="title">{header}</p>
        </div>
        {id !== 8 && (text ?? "").length > 0 && (
          <div
            className="text-item"
            dangerouslySetInnerHTML={{
              __html: text!
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PlotlineTemplateHeader;
