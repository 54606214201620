/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";
import { getDragonRoute } from "./index";

import { PracticeDetailProps } from "./types";

export const PracticeDetail: FunctionComponent<PracticeDetailProps> = ({
  image,
  text,
  score,
  caret,
  categoryID,
  subcategoryID,
  dragonText
}) => {
  const history = useHistory();

  const toPracticeDetail = (subcategoryID: number) => {
    history.push(`/dragonList/${getDragonRoute(dragonText)}/${subcategoryID}/${categoryID}`);
  };

  if (text !== "") {
    return (
      <div
        onClick={() => {
          toPracticeDetail(subcategoryID);
        }}
        className="mp-detail"
      >
        <img className="mp-detail-icon" src={RequireSeityImage(image).default} alt={text} />
        <h4>{text}</h4>
        <h5>{score}%</h5>
        <img className="mp-detail-caret" src={caret} alt="caret" />
      </div>
    );
  }
  return <></>;
};
