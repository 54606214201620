import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import { IMAGE_URL } from "../../../../api/apiConstants";

export type PlotlineTemplateImageProps = {
  webImageHorizontal: string | null;
  webImageVertical: string | null;
  mobileImageVertical: string | null;
};

const PlotlineTemplateImage: FunctionComponent<PlotlineTemplateImageProps> = (props): ReactElement => {
  const { webImageHorizontal, webImageVertical, mobileImageVertical } = props;
  const isMobile = window.innerWidth < 768;

  return (
    <div className="plotline-image-body">
      {(webImageVertical || webImageHorizontal || "").length > 0 && (
        <img src={IMAGE_URL + (isMobile ? mobileImageVertical : webImageHorizontal)} />
      )}
    </div>
  );
};

export default PlotlineTemplateImage;
