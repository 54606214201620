/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import Carousel from "react-elastic-carousel";

import SeityPrimaryCancelButtons from "../../../../_core/components/SeityNavigationButtons";
import { CoreValue } from "./CoreValue";

import CoreStrings from "../../../../_core/strings/strings";
import { sendFourCoreValuesRequest } from "../../coreValuesSlice";
import { CoreValuesYourCoreValuesProps } from "./types";

import "./styles.scss";

export const CoreValuesYourCoreValues: FunctionComponent<CoreValuesYourCoreValuesProps> = (props) => {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const [showPrevArrow, setShowPrevArrow] = useState(false);

  const { fourCoreValues, error: coreVlaueCategoryErrory } = useSelector((state: RootState) => {
    return state.assessment.coreValues;
  });
  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(sendFourCoreValuesRequest());
  }, []);

  return (
    <div className="mcv-main-wrapper">
      <h1>
        {accountInfo.firstName} {CoreStrings.is}
      </h1>
      <div className="mcv-container">
        <Carousel
          ref={ref}
          className="mcv-carousel"
          breakPoints={[
            { width: 1, itemsToShow: 1 },
            { width: 500, itemsToShow: 1, itemsToScroll: 1 },
            { width: 850, itemsToShow: 1.5 },
            { width: 1150, itemsToShow: 2, itemsToScroll: 1 },
            { width: 1450, itemsToShow: 2.5 },
            { width: 1750, itemsToShow: 4 }
          ]}
          itemsToShow={4}
          pagination={false}
          itemPadding={[0, 0]}
          isRTL={false}
          onChange={(_currentItemObject, currentPageIndex) => {
            setShowPrevArrow(currentPageIndex !== 0);
          }}
          renderArrow={({ type, onClick, isEdge }) => {
            if (type === "PREV" && !showPrevArrow) {
              return <></>;
            }
            if (isEdge && type === "NEXT") {
              return <></>;
            }
            return (
              <>
                <button
                  className={`${type === "NEXT" ? "forward" : "backward"}-button flex-center`}
                  onClick={() => {
                    onClick();
                  }}
                >
                  <img
                    src={require(`../../../../assets/graphics/intentions/icon-${type === "NEXT" ? "forward" : "backward"}.png`).default}
                    width={20}
                    alt="forward btn"
                  />
                </button>
              </>
            );
          }}
        >
          {fourCoreValues?.map((coreValue) => {
            return CoreValue({
              userSortOrder: coreValue.userSortOrder,
              coreValueID: coreValue.coreValueID,
              coreValue: coreValue.coreValue,
              descriptionText: coreValue.coreValueText,
              quote: coreValue.quote
            });
          })}
        </Carousel>
      </div>
      <SeityPrimaryCancelButtons
        primaryTitle={props.isCVOnly ? CoreStrings.doneButtonTitle : CoreStrings.next}
        primaryAction={() => {
          return props.onNextClicked(props.stepId);
        }}
        primaryDisabled={false}
        errorMessage={coreVlaueCategoryErrory}
        showCancel={false}
        primaryClassName="mcv-main-wrapper-button"
      />
    </div>
  );
};
