import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import apiClientWithAuth from "../../api/apiClient";
import { CVAffirmation, CVAffirmationResponse, Today, TodayResponse } from "../../api/today/types/todayTypes";

export interface TodayState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly today: Today;
  readonly cvAffirmation: CVAffirmation;
}

const initToday = () => {
  return {
    firstName: "",
    dailyCheckinScore: null,
    dailyIntention: null,
    coreValuesComplete: false,
    coreValuesCompleteDate: ""
  };
};

const initCVAffirmation = () => {
  return {
    oreValue1ID: 0,
    coreValue1: "",
    coreValue2ID: 0,
    coreValue2: "",
    coreValue3ID: 0,
    coreValue3: "",
    coreValue4ID: 0,
    coreValue4: "",
    coreValueAffirmation1ID: 0,
    coreValueAffirmation1: "",
    coreValueAffirmation2ID: 0,
    coreValueAffirmation2: "",
    coreValueAffirmation3ID: 0,
    coreValueAffirmation3: "",
    coreValueAffirmation4ID: 0,
    coreValueAffirmation4: "",
    selectedCoreValueAffirmationID: 0
  };
};

const setInitialState = {
  error: null,
  isLoading: false,
  today: initToday(),
  cvAffirmation: initCVAffirmation()
} as unknown as TodayState;

const todaySlice = createSlice({
  name: "today",
  initialState: setInitialState,
  reducers: {
    setToday(state, action: PayloadAction<Today>) {
      state.today = action.payload;
      state.isLoading = false;
    },
    setCVAffirmation(state, action: PayloadAction<CVAffirmation>) {
      state.cvAffirmation = action.payload;
      state.isLoading = false;
    },
    setTodayError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    resetTodaySlice: () => setInitialState
  }
});

export const { setToday, setCVAffirmation, setTodayError, clearApiError, setIsLoading, resetTodaySlice } = todaySlice.actions;
export default todaySlice.reducer;

export const getTodayRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const response = await apiClientWithAuth.get<TodayResponse>("Today");
    if (response.data.success) {
      dispatch(setToday(response.data.data));
    }
    dispatch(setIsLoading({ isLoading: false }));
  } catch (err) {
    console.error(err);
    dispatch(setTodayError({ error: err.toString() }));
  }
};

export const getAffirmationRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const response = await apiClientWithAuth.get<CVAffirmationResponse>("CoreValueAffirmation");
    if (response.data.success) {
      dispatch(setCVAffirmation(response.data.data));
    }
    dispatch(setIsLoading({ isLoading: false }));
  } catch (err) {
    console.error(err);
    dispatch(setTodayError({ error: err.toString() }));
  }
};

export const putAffirmationRequest =
  (selCVAffirmationID: number, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const response = await apiClientWithAuth.put<CVAffirmationResponse>("CoreValueAffirmation", {
        selectedCoreValueAffirmationID: selCVAffirmationID
      });
      dispatch(setIsLoading({ isLoading: false }));
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      dispatch(setTodayError({ error: err.toString() }));
    }
  };
