/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import { FunctionComponent, useState } from "react";

import { GoodLifeEpisode } from "../../../api/referenceGuide/referenceGuideTypes";
import { SeityBackButton } from "../SeityBackButton";
import SeityButton from "../SeityButton";
import SeityModal from "../SeityModal";

import "./styles.scss";

export type SeityPostProps = {
  post: GoodLifeEpisode;
};

export const SeityPost: FunctionComponent<SeityPostProps> = ({ post }) => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  const resizeIframe = (e) => {
    e.target.style.height = e.target.contentWindow.document.documentElement.scrollHeight + "px";
  };

  return (
    <div className="post-container">
      <SeityBackButton wrapperClassName="msl-back-button" />
      <h1>{post.subtitle}</h1>
      <div className="default-container">
        {post.videoUrl && (
          <div
            style={{
              backgroundImage: `url(${post.imageUrl})`,
              backgroundSize: "cover"
            }}
            className="video-container"
            onClick={() => {
              return setShowVideoPlayer(true);
            }}
          >
            <img className="mt-2" src={require("../../../assets/graphics/play-circle.png").default} width="20px" alt="play button" />
          </div>
        )}
        <div className="video-text-container">
          <h4>{post.title}</h4>
          <iframe
            srcDoc={post.description}
            className="iframe-content"
            name="iframe_a"
            frameBorder="0"
            scrolling="no"
            title="web-content"
            onLoad={(e) => {
              return resizeIframe(e);
            }}
          ></iframe>
        </div>
      </div>
      <SeityModal
        isOpen={showVideoPlayer}
        onClose={() => {
          return setShowVideoPlayer(false);
        }}
      >
        <div className="maintenance-modal">
          <video width="400" autoPlay={true} controls>
            <source src={post.videoUrl} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
          <SeityButton
            style={{ marginTop: 10 }}
            label="OK"
            type="next"
            onClick={() => {
              return setShowVideoPlayer(false);
            }}
          />
        </div>
      </SeityModal>
    </div>
  );
};
