import strings from "../../../_core/strings/strings";

export const useReferenceGuideList = () => {
  return [
    {
      title: strings.coreValues,
      img: "",
      categoryID: 0,
      previewImageName: "core-values-guide-preview"
    },
    {
      title: strings.world,
      img: "",
      categoryID: 1,
      previewImageName: "world-guide-preview"
    },
    {
      title: strings.body,
      img: "",
      categoryID: 2,
      previewImageName: "body-guide-preview"
    },
    {
      title: strings.mind,
      img: "",
      categoryID: 3,
      previewImageName: "mind-guide-preview"
    },
    {
      title: strings.spirit,
      img: "",
      categoryID: 4,
      previewImageName: "spirit-guide-preview"
    },
    {
      title: strings.wellBeing,
      img: "",
      categoryID: 5,
      previewImageName: "wellbeing-guide-preview"
    },
    {
      title: strings.wholeHealth,
      img: "",
      categoryID: 6,
      previewImageName: "whole-health-guide-preview"
    },
    {
      title: strings.dragons,
      img: "",
      categoryID: 7,
      previewImageName: "dragons-guide-preview"
    }
  ];
};

export const outcomeCategoriesIds = ["1", "2", "3", "4"];
export const welbeingCategoriesIds = ["5", "6"];

export const coreValueCategory = () => {
  return {
    outcomeCategory: {
      outcomeCategoryID: 0,
      category: strings.coreValuesIntroHeader,
      description: strings.coreValueDescription
    }
  }
};

export const wellbeingCategories = () => {
  return {
    "5": {
      outcomeCategory: {
        outcomeCategoryID: 0,
        category: strings.wellBeing,
        description: strings.wellbeingIntroduction
      }
    },
    "6": {
      outcomeCategory: {
        outcomeCategoryID: 0,
        category: strings.wholeHealth,
        description:
          strings.wholeHealthDescription
      }
    }
  };
};
