import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { LearnSeityByCategoryResponse, PostCategoryResponse, PostResponse, SeityCategoryResponse } from "./types/learnTypes";

const SECTION = "/LearnSeity/";

export async function getSeityCategories(token: string) {
  const url = BASE_URL + SECTION + "LearnSeityCategories";
  try {
    const categoriesResponse = await axios.get<SeityCategoryResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });

    return categoriesResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPostByCategory(categoryId: number, token: string) {
  const url = BASE_URL + SECTION + "LearnSeityByCategory";

  try {
    const postCategoryResponse = await axios.get<PostCategoryResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: {
        categoryId
      }
    });

    return postCategoryResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPost(cmsID: string, token: string): Promise<PostResponse> {
  const url = BASE_URL + SECTION + "LearnSeity";
  try {
    const postResponse = await axios.get<PostResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: {
        cmsID
      }
    });
    return postResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getLearnSeityByCategoryParent(parentCategoryId: number, token: string): Promise<LearnSeityByCategoryResponse> {
  const url = BASE_URL + SECTION + "LearnSeityCategoriesByParent";
  try {
    const postResponse = await axios.get<LearnSeityByCategoryResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: {
        parentCategoryId
      }
    });
    return postResponse.data;
  } catch (err) {
    throw err;
  }
}
