import { FunctionComponent } from "react";
import "./styles.scss";
import { Incentive as IncentiveData } from "../types";
import { IMAGE_URL } from "../../../api/apiConstants";
import strings from "../../../_core/strings/strings";
import {getImageUrl} from "../../../_core/utils/imageUtils/imageUtils";

type IncentiveProps = {
  incentive: IncentiveData;
};

export const Incentive: FunctionComponent<IncentiveProps> = ({ incentive }) => {
  // const noOfEntries = strings.noOfEntries.replace("{noOfEntries}", incentive?.entries);
  return (
    <div className="incentive-container">
      <p className="prize-name">{incentive?.prizeName}</p>
      <img src={getImageUrl(incentive.imageName)} />
      <p className="entries">{incentive?.entries}</p>
      <p className="sponsor">{incentive?.sponsor}</p>
    </div>
  );
};
