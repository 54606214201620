import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import "./styles.scss";
import strings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";
import { Incentive } from "../types";
import {getImageUrl} from "../../../_core/utils/imageUtils/imageUtils";

type CheckInBannerProps = {
  incentive: Incentive;
  description: string | null;
};

export const CheckInBanner: FunctionComponent<CheckInBannerProps> = ({ incentive, description }) => {
  const history = useHistory();

  return (
    <div className="check-in-banner-container">
      <div className={incentive.active ? "content" : "content-2"}>
        <div>
          <h3>{strings.wellbeingCheckInTitle}</h3>
          <h5>{description ?? ""}</h5>
        </div>
        <SeityButton label={strings.checkInNow} className="check-in-button" onClick={() => history.push("/checkin/wellbeingCheckIn")} />
      </div>
      {incentive.active && (
        <div className="incentive">
          <p>{incentive.title}</p>
          <img src={getImageUrl(incentive.imageName)} />
          <p>{incentive.prizeName}</p>
        </div>
      )}
    </div>
  );
};
