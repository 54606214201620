import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../app/store";
import { UserAppSettings } from "../api/settings/types/settingsTypes";
import apiClientWithAuth from "../api/apiClient";

export interface UserAppSettingsState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly profilePictureLoading: boolean;
  readonly userAppSettings: UserAppSettings;
  readonly phoneVerificationShown: boolean;
}

const initSettings = () => {
  return {
    profilePictureFileName: "",
    profilePictureBase64: "",
    profilePictureLoading: false,
    assessmentLevelID: 0,
    syncsEnabled: false,
    storiesEnabled: false,
    journalsEnabled: false,
    intentionsEnabled: false,
    languageID: 0,
    eulaNeeded: false,
    revisedEULANeedToNotify: false,
    familySharingDisclosureAccepted: false,
    familySharingEnabled: false,
    familySharingPrimaryAccount: false,
    canCreateFamilySharing: false,
    hasFamilySharingFullAccess: false,
    forceNewPassword: false,
    emailVerificationNeeded: false,
    hasCompletedIntentionWalkthrough: false,
    hasCompletedSynchsWalkthrough: false,
    hasCompletedStoriesWalkthrough: false,
    coreValuesComplete: false,
    translationEnabled: false,
    affirmationsEnabled: false,
    teachSeityEnabled: false,
    isTeachSeityNew: false,
    growEnabled: false,
    settingsEnabled: false,
    getHelpEnabled: false,
    isCVOnly: false,
    isWellbeingOnly: false,
    doNotAllowPasswordChange: false,
    wellbeingScreenEnabled: false,
    smsVerificationNeeded: false,
    multipleWellbeingCheckInEnabled: false,
    todayPageEnabled: false
  };
};

const setInitialState = {
  error: null,
  isLoading: false,
  userAppSettings: initSettings(),
  phoneVerificationShown: false
} as unknown as UserAppSettingsState;

const settingsSlice = createSlice({
  name: "settings",
  initialState: setInitialState,
  reducers: {
    setSettings(state, action: PayloadAction<UserAppSettings>) {
      const payload = action.payload;
      state.userAppSettings = {
        ...payload,
        isCVOnly: payload.assessmentLevelID === 2,
        isWellbeingOnly: payload.assessmentLevelID === 3,
        hasFamilySharingFullAccess: payload.familySharingEnabled && payload.familySharingPrimaryAccount && payload.canCreateFamilySharing
      };
      state.isLoading = false;
    },
    setSettingsError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setEULANeeded(state) {
      state.userAppSettings.eulaNeeded = false;
      state.userAppSettings.revisedEULANeedToNotify = false;
    },
    setEmailVerificationNeeded(state) {
      state.userAppSettings.emailVerificationNeeded = false;
    },
    setForceNewPassword(state) {
      state.userAppSettings.forceNewPassword = false;
    },
    setProfilePictureLoading(state, action) {
      state.profilePictureLoading = action.payload.profilePictureLoading;
    },
    setFSDisclosureAccepted(state) {
      state.userAppSettings.familySharingDisclosureAccepted = true;
    },
    setLanguageID(state, action) {
      state.userAppSettings.languageID = action.payload.languageID;
    },
    setSmsVerificationNeeded(state) {
      state.userAppSettings.smsVerificationNeeded = false;
    },
    setPhoneVerificationShown(state, action) {
      state.phoneVerificationShown = action.payload;
    },
    resetSettingsSlice: () => setInitialState
  }
});

export const {
  setSettings,
  setSettingsError,
  clearApiError,
  setIsLoading,
  setProfilePictureLoading,
  resetSettingsSlice,
  setEmailVerificationNeeded,
  setForceNewPassword,
  setFSDisclosureAccepted,
  setEULANeeded,
  setLanguageID,
  setSmsVerificationNeeded,
  setPhoneVerificationShown
} = settingsSlice.actions;
export default settingsSlice.reducer;

export const getUserAppSettingsRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    dispatch(setIsLoading({}));
    const res = await apiClientWithAuth.get("/UserAppSettings/");
    if (res.data.success) {
      const data: any = res.data.data;
      dispatch(setSettings(data));
      dispatch(setIsLoading({ isLoading: false }));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setSettingsError({ error: err.toString() }));
  }
};

export const sendPostProfilePicture = (fileName: string, base64String: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(setProfilePictureLoading({ profilePictureLoading: true }));
    const res = await apiClientWithAuth.post("/Profile/profilePicture", {
      fileName,
      base64String
    });
    if (res.data) {
      dispatch(getUserAppSettingsRequest());
      dispatch(setProfilePictureLoading({ profilePictureLoading: false }));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setSettingsError({ error: err.toString() }));
  }
};

export const sendPutProfilePicture = (fileName: string, base64String: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(setProfilePictureLoading({ profilePictureLoading: true }));
    const res = await apiClientWithAuth.put("/Profile/profilePicture", {
      fileName,
      base64String
    });
    if (res.data) {
      dispatch(getUserAppSettingsRequest());
      dispatch(setProfilePictureLoading({ profilePictureLoading: false }));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setSettingsError({ error: err.toString() }));
  }
};

export const putApproveEULARequest = (isRevised?: boolean, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.put(isRevised ? "Account/NewEulaNotificationAccepted" : "Account/EULAAccepted");
    if (res.data.success) {
      dispatch(setEULANeeded());
      if (callback) {
        callback();
      }
    }
  } catch (err: any) {
    console.error(err);
  }
};
