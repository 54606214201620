import React, { useEffect } from "react";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";

const bannerCheckMark = require("../../../../../assets/icons/bannerCheckMark.svg").default;

// eslint-disable-next-line @typescript-eslint/naming-convention
interface props {
  showBanner: boolean;
  setShowBanner: (bool: boolean) => void;
}

export const ConfirmationBanner = ({ showBanner, setShowBanner }: props) => {
  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        setShowBanner(false);
      }, 3000);
    }
  }, [showBanner]);

  return (
    <div className={`cb-wrapper ${showBanner ? "cb-slideDown" : "cb-slideUp"}`}>
      <img src={bannerCheckMark} alt="checkMark" />
      <div className="cb-description">{strings.evEmailSuccessfullyChanged}</div>
    </div>
  );
};

export default ConfirmationBanner;
