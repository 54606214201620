import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { RootState } from "../../../app/rootReducer";
import { sendIncentiveInfoRequest } from "../checkInSlice";
import { IMAGE_URL } from "../../../api/apiConstants";

import { SeityButton } from "../../../_core/components/SeityButton";
import SeityLoader from "../../../_core/components/SeityLoader";

import prizeImg from "../../../assets/graphics/seity_large_logo.png";
import wellBeingImg from "../../../assets/graphics/wellbeing01.png";

import strings from "../../../_core/strings/strings";
import "./styles.scss";

type IncentivesProps = {
  // eslint-disable-next-line react/require-default-props
  onFallBack?: () => void;
};

export const Incentives = ({ onFallBack }: IncentivesProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, incentiveResponse } = useSelector((state: RootState) => {
    return state.checkIn;
  });

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const [progressDashOffset, setProgressDashOffset] = useState(198);
  const [remotePrizeImage, setRemotePrizeImage] = useState(prizeImg);

  const donePressed = () => {
    if (onFallBack) {
      onFallBack();
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    dispatch(sendIncentiveInfoRequest());
  }, []);

  useEffect(() => {
    if (incentiveResponse?.checkInScore) {
      setTimeout(() => {
        setProgressDashOffset(198 * (1 - incentiveResponse.checkInScore / 100) + 10);
      }, 500);
    }
    if (incentiveResponse && incentiveResponse.image) {
      let img = IMAGE_URL + incentiveResponse.image;
      if (img.includes(".png") === false) {
        img += ".png";
      }
      setRemotePrizeImage(img);
    }
  }, [incentiveResponse]);

  if (isLoading) {
    return <SeityLoader />;
  }

  return (
    <div className="checkin-complete-container">
      <div className="checkin">
        <div className="checkin-title">
          <h1>{strings.headerScoreLabel}</h1>
        </div>
        <div className="checkin-progress">
          <div id="checkin-progress-bar">
            <svg viewBox="10 20 100 80">
              <path className="lightYellow" d="M0,90 A40,40 0 1,1 120,90" fill="none" />
              <path
                id="yellow"
                fill="none"
                className="yellow"
                d="M0,90 A40,40 0 1,1 120,90"
                style={{
                  strokeDashoffset: progressDashOffset
                }}
              />
            </svg>
          </div>
          <img className="checkin-progress-icon" src={wellBeingImg} alt="wellbeing icon" />
        </div>
        <div className="checkin-score">
          <h3>{`${incentiveResponse?.checkInScore}%`}</h3>
          <p>{strings.scoreText}</p>
        </div>

        <div className="checkin-content-container">
          <div className="checkin-content">
            <h1>{incentiveResponse?.line3}</h1>
            <p>{incentiveResponse?.line4}</p>
            <img className="checkin-content-prizeImg" src={remotePrizeImage} alt="prize img" />
            <h3>{incentiveResponse?.prizeName}</h3>
            {userAppSettings.multipleWellbeingCheckInEnabled && incentiveResponse && (
              <h4>
                {incentiveResponse.entries <= 1
                  ? strings.singleEntryPerDayMessage
                  : strings.formatString(strings.multipleEntriesPerDayMessage, incentiveResponse.entries)}
              </h4>
            )}
          </div>
        </div>
        <SeityButton className="checkin-button" label={strings.doneButtonTitle} onClick={donePressed} testId="checkinDoneButton" />
      </div>
    </div>
  );
};

export default Incentives;
