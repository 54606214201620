/* eslint-disable default-case */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import { getPracticeScoresRequest } from "../practicesSlice";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";
import SeityProgressBar from "../../../_core/components/SeityProgressBar";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";
import { PracticeDetail } from "./PracticeDetail";

import { DragonType } from "../../../consts";
import { PracticeScoreCategory, PracticeScoreSubCategory } from "../../../api/account/types/practiceScoresTypes";
import { MyPracticesParams, MyPracticesProps } from "./types";

import "./styles.scss";

export const getDragonRoute = (dragonText: string) => {
  switch (dragonText) {
    case "RAGING":
      return DragonType.RangingDragons;
    case "AWAKE":
      return DragonType.AwakeDragons;
    case "TAME":
      return DragonType.TameDragons;
    default:
      return DragonType.RangingDragons;
  }
};

export const MyPractices: FunctionComponent<MyPracticesProps> = () => {
  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });
  const dispatch = useDispatch();
  const params = useParams<MyPracticesParams>();

  const { error, practiceScoresList } = useSelector((state: RootState) => {
    return state.practiceScores;
  });

  useEffect(() => {
    dispatch(getPracticeScoresRequest());
    window.scrollTo(0, 0);
  }, []);

  const { logoImage, title, score, foregroundColor, fontColor, imageCaret, subCategoryData } = useMemo(() => {
    let logoImage;
    let outcomeCategoryID: Number = parseInt(params.outcomeCategoryID);
    let title: string | undefined = "";
    let score: number | undefined = 0;
    let foregroundColor: string = "";
    let fontColor: string = "";
    let imageCaret: string = "";
    let categoryData: PracticeScoreCategory | undefined;
    let subCategoryData: PracticeScoreSubCategory[] | null = null;

    if (practiceScoresList) {
      categoryData = practiceScoresList.find((c) => {
        return c.categoryID === outcomeCategoryID;
      });
      if (categoryData) {
        title = categoryData?.categoryName;
        score = categoryData?.categoryScore;
        logoImage = RequireSeityImage(categoryData.categoryLogoURL).default;
      }
    }

    if (!error && categoryData) {
      switch (outcomeCategoryID) {
        case 1:
          foregroundColor = "rgba(47, 105, 108, 1)";
          fontColor = "rgba(255, 248, 235, 1)";
          imageCaret = require("../../../assets/graphics/caret_white.png").default;
          break;

        case 2:
          foregroundColor = "rgba(86, 163, 166, 1)";
          fontColor = "rgba(255, 248, 235, 1)";
          imageCaret = require("../../../assets/graphics/caret_white.png").default;
          break;

        case 3:
          foregroundColor = "rgba(154, 200, 202, 1)";
          fontColor = "rgba(49, 73, 71, 1)";
          imageCaret = require("../../../assets/graphics/caret_seity_green.png").default;
          break;

        case 4:
          foregroundColor = "rgba(187, 218, 219, 1)";
          fontColor = "rgba(49, 73, 71, 1)";
          imageCaret = require("../../../assets/graphics/caret_seity_green.png").default;
          break;
      }
    }

    if (outcomeCategoryID < 1 || outcomeCategoryID > 4) {
      outcomeCategoryID = 1;
    }

    if (!error && categoryData != null) {
      subCategoryData = categoryData.subcategories.slice().sort((a, b) => {
        return b.subcategoryScore - a.subcategoryScore;
      });
    }

    return {
      logoImage,
      title,
      score,
      foregroundColor,
      outcomeCategoryID,
      fontColor,
      imageCaret,
      subCategoryData
    };
  }, [error, params.outcomeCategoryID, practiceScoresList]);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  if (!error && subCategoryData != null) {
    return (
      <div className="mp-main-container">
        <SeityBackButton wrapperClassName="mp-back-button" />
        <h1>{title}</h1>
        <img className="mp-logoImage" src={logoImage} alt={title} />
        <div className="mp-background-wrapper">
          <div className="mp-total-score">
            <h3>{score}%</h3>
            <SeityProgressBar progress={score} trackColor="#2f696c" color="#fbf9f7" height={8} containerStyle="mp-progress-bar-container" />
          </div>
          <div style={{ background: foregroundColor, color: fontColor }} className="mp-foreground-wrapper">
            {subCategoryData.map((practiceDetail) => {
              return (
                <PracticeDetail
                  image={practiceDetail.subcategoryLogoURL}
                  text={practiceDetail.subcategoryName}
                  score={practiceDetail.subcategoryScore}
                  caret={imageCaret}
                  categoryID={practiceDetail.categoryID}
                  subcategoryID={practiceDetail.subcategoryID}
                  dragonText={practiceDetail.dragonText}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
