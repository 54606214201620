import { FunctionComponent, ReactElement } from "react";
import Modal from "react-modal";
import Lottie from "react-lottie";

import animationData from "./commit-success.json";
import thumbsUp from "./thumbs-up.json";
import crtAnswer from "./correct-answer.json";
import strings from "../../../../../_core/strings/strings";

import "./styles.scss";

type AnswerModalProps = {
  option: number;
  correctAnswer?: boolean;
  isOpen: boolean;
  onClose?: () => void;
};

const AnswerModal: FunctionComponent<AnswerModalProps> = ({ option, correctAnswer, isOpen, onClose }): ReactElement => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: correctAnswer ? crtAnswer : option === 3 ? animationData : thumbsUp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="answer-modal" overlayClassName="answer-overlay">
      <Lottie options={defaultOptions} height={262} width={262} />
      <div className="mt-xl flex-col flex-ai-c">
        {correctAnswer ? (
          <>
            <h3>{strings.correctAnswer}</h3>
            <p className="answer-modal__title" style={{ fontStyle: "italic" }}>
              "{strings.theBeautifulThing}"
            </p>
            <p className="answer-modal__title">{strings.bbKing}</p>
          </>
        ) : (
          <>
            <h3>{option === 3 ? strings.completeSuccessHeader : strings.greatJob}</h3>
            <p className="answer-modal__title">{option === 1 ? strings.smallStepsLead : strings.youAheadOfGame}</p>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AnswerModal;
