import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../api/authTypes";
import { CoreValuesIncentive } from "../../api/assessment/types/coreValuesIncentive";
import { getCVIncentive } from "../../api/assessment/seityHealthAPI-Assessment";

export interface CVIncentiveState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly data: CoreValuesIncentive | null;
}

const initCVIncentive = () => {
  return null;
};

const setInitialState = {
  error: null,
  isLoading: false,
  data: initCVIncentive()
} as CVIncentiveState;

const cvIncentiveSlice = createSlice({
  name: "cvincentive",
  initialState: setInitialState,
  reducers: {
    setCVIncentive(state, action: PayloadAction<CoreValuesIncentive>) {
      state.data = action.payload;
      state.isLoading = false;
    },
    setCVIncentiveError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    resetCVIncentive: () => setInitialState
  }
});

export const { setCVIncentive, setCVIncentiveError, clearApiError, setIsLoading, resetCVIncentive } = cvIncentiveSlice.actions;
export default cvIncentiveSlice.reducer;

export const getCVIncentiveRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const cvIncentiveResponse = await getCVIncentive(token);
    if (cvIncentiveResponse.success) {
      const data: any = cvIncentiveResponse.data;
      dispatch(setCVIncentive(data));
    }
  } catch (err: any) {
    console.error("error", err);
    dispatch(setCVIncentiveError({ error: err.toString() }));
  }
};
