/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent, useState } from "react";

import classNames from "classnames";
import ShowEye from "../../../assets/web-icons/eye_show.png";
import CloseEye from "../../../assets/web-icons/eye_hide.png";

import "./styles.scss";
import React from "react/index";
import MaxLimitError from "../SeityMaxLimitError";

export interface SeityTextInputProps {
  testID?: string;
  type?: "password" | "text" | "number" | "date" | "email" | "tel";
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  placeholder?: string;
  inputStyle?: {};
  containerClassname?: string;
  leftIconClassname?: string;
  rightIconClassname?: string;
  value?: string | number;
  autoComplete?: "on" | "off";
  className?: string;
  leftIcon?: string;
  isInvalid?: boolean;
  id?: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  maxLength?: number;
  isMaxLimitReached?: boolean;
}

export const SeityTextInput: FunctionComponent<SeityTextInputProps> = (props) => {
  const {
    onChange,
    onKeyDown,
    placeholder,
    inputStyle,
    value,
    className,
    leftIcon,
    autoComplete,
    containerClassname,
    isInvalid,
    id,
    leftIconClassname = "",
    rightIconClassname,
    onBlur,
    onFocus,
    autoFocus,
    maxLength,
    isMaxLimitReached,
    testID
  } = props;
  const { type } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState({ type });

  const handleEyeClick = () => {
    if (!showPassword) {
      setInputType({ type: "text" });
    } else {
      setInputType({ type: "password" });
    }
    setShowPassword(!showPassword);
  };

  const hasRightIcon = type === "password";
  const hasLeftIcon = leftIcon;

  const inputClasses = classNames({
    "seity-input": true,
    "seity-input-padding-left": hasLeftIcon,
    "seity-input-padding-right": hasRightIcon,
    "seity-input-error": isInvalid
  });

  return (
    <div className={`${"seity-input-wrapper" + " "}${containerClassname}`}>
      {hasLeftIcon && <img className={`${"seity-input-left-icon" + " "}${leftIconClassname}`} src={leftIcon} />}
      <input
        autoFocus={autoFocus}
        id={id ? id : testID}
        value={value}
        autoComplete={autoComplete === "off" ? "new-password" : "on"}
        autoCapitalize={type === "email" || type === "password" ? "off" : "sentences"}
        placeholder={placeholder}
        className={`${className} ${inputClasses}`}
        type={inputType.type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={inputStyle}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={maxLength}
      />
      {hasRightIcon && (
        <img
          className={`seity-input-right-icon ${rightIconClassname} ${showPassword && "seity-input-right-icon-close"}`}
          onClick={handleEyeClick}
          src={showPassword ? ShowEye : CloseEye}
        />
      )}

      <MaxLimitError maxLength={maxLength} isVisible={isMaxLimitReached} />

    </div>
  );
};
