import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import SeityAvatar from "../../../_core/components/SeityAvatar";

type CVListItemProps = {
  keyIdx: string;
  profilePicture: string | null;
  initials?: string;
  title: string;
  subTitle: string;
  onClick: () => void;
  isPersonalList?: boolean;
};

const CVListItem: FunctionComponent<CVListItemProps> = ({
  keyIdx,
  profilePicture,
  initials,
  title,
  subTitle,
  onClick,
  isPersonalList = false
}): ReactElement => {
  let profileImg = require(isPersonalList
    ? "../../../assets/graphics/img-pl-avatar.png"
    : "../../../assets/graphics/familysharing/img-profile-default.png").default;

  if (profilePicture !== null && profilePicture?.length > 0) {
    profileImg = `data:image/png;base64,${profilePicture}`;
  }
  if (profilePicture?.includes("https")) {
    profileImg = profilePicture;
  }

  return (
    <div className="cvList-list__item" key={keyIdx} onClick={onClick}>
      {profilePicture === null ? (
        <div className="cvList-list__item-initials">
          <SeityAvatar
            initials={initials}
            content={{
              firstName: title.split(" ")[0],
              lastName: title.split(" ")[1],
              profilePicture: null
            }}
            size={48}
          />
        </div>
      ) : (
        <img className="cvList-list__item-img" src={profileImg} alt="Profile" />
      )}
      <div>
        <span>{title}</span>
        <span>{subTitle}</span>
      </div>
      <img src={require("../../../assets/graphics/caret-black.png").default} width={8} alt="Caret" />
    </div>
  );
};

export default CVListItem;
