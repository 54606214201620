import axios from 'axios';
import { ServiceResponse, CoreValueHistoryResponse } from './types/coreValueHistoryTypes';
import {BASE_URL} from "../../../api/apiConstants";

export async function getCoreValueHistory(token: string, pageSize: number, pageNumber: number): Promise<ServiceResponse<CoreValueHistoryResponse>> {
  const url = `${BASE_URL}/CoreValueHistory?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  try {
    const response = await axios.get<ServiceResponse<CoreValueHistoryResponse>>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
