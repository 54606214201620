import { createSlice } from "@reduxjs/toolkit";
import apiClientWithAuth from "../../api/apiClient";
import { FSGroup, FSRelationship, FSStatus } from "./types";
import { setFSDisclosureAccepted } from "../../slices/userAppSettingsSlice";
import { FSGroupReponse } from "../../api/familySharingTypes";

export interface DisclosureModalContent {
  header: string;
  content: string;
  checkboxCopy: string;
}

export interface FamilySharingState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly disclosure: DisclosureModalContent;
  readonly coppa: DisclosureModalContent;
  readonly fsStatusList: Array<FSStatus>;
  readonly fsRelationshipList: Array<FSRelationship>;
  readonly fsGroupList: Array<FSGroup>;
}

const intitialModalData = {
  header: "",
  content: "",
  checkboxCopy: ""
};

const setInitalState = {
  error: null,
  isLoading: false,
  disclosure: intitialModalData,
  coppa: intitialModalData,
  fsStatusList: [],
  fsRelationshipList: [],
  fsGroupList: []
} as FamilySharingState;

const familySharingSlice = createSlice({
  name: "family-sharing",
  initialState: setInitalState,
  reducers: {
    setDisclosure(state, action) {
      state.disclosure = action.payload;
    },
    setCoppa(state, action) {
      state.coppa = action.payload;
    },
    setFSStatusList(state, action) {
      state.fsStatusList = action.payload;
    },
    setFSRelationshipList(state, action) {
      state.fsRelationshipList = action.payload;
    },
    setFSGroupList(state, action) {
      state.fsGroupList = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    resetFamilySharingSlice: () => setInitalState
  }
});

export const {
  setDisclosure,
  setCoppa,
  setFSGroupList,
  setFSStatusList,
  setFSRelationshipList,
  clearError,
  setIsLoading,
  setError,
  resetFamilySharingSlice
} = familySharingSlice.actions;

export default familySharingSlice.reducer;

const handleError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setError({ error: err.toString() }));
};

export const postFSDisclosureAccept = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.post("/FamilySharing/AcceptDisclosure");
    dispatch(setFSDisclosureAccepted());
    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const postFSCoppaAccept = (email: string) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await apiClientWithAuth.post(`/FamilySharing/AcceptCOPPA?email=${email}`);
    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const postFSGroup = (groupName: string, callback?: (groupId: number) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.post("/FamilySharing", {
      groupName
    });
    dispatch(setIsLoading(false));
    if (callback && res.data.success) {
      callback(res.data.data.groupID);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const putFSGroup =
  (gropuID: number, groupName: string | null, imgData: string | null, callback?: () => void) => async (dispatch) => {
    try {
      const param = {
        groupID: gropuID,
        groupName: groupName,
        profileImg: {
          imageName: gropuID + ".png",
          base64String: imgData
        }
      };
      dispatch(setIsLoading(true));
      const res = await apiClientWithAuth.put("/FamilySharing", param);
      dispatch(setIsLoading(false));

      if (callback) {
        callback();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

export const putLeaveFSGroup = (groupID: number, callback?: () => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await apiClientWithAuth.put("/FamilySharing/FamilyMembers/Leave", {
      groupID
    });
    dispatch(setIsLoading(false));

    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const deleteFamilyMember = (groupID: number, memberID: number, callback?: () => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await apiClientWithAuth.delete("/FamilySharing/FamilyMembers", {
      data: {
        groupID,
        memberID
      }
    });
    dispatch(setIsLoading(false));

    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getFSDisclosure = (callback?: (disclosure: DisclosureModalContent) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("/FamilySharing/Disclosure");

    let apiModalData;

    if (res.data.success) {
      apiModalData = {
        header: res.data.data.header,
        content: res.data.data.content,
        checkboxCopy: res.data.data.checkboxCopy
      };
      dispatch(setDisclosure(apiModalData));
    }
    if (callback) {
      callback(apiModalData ?? intitialModalData);
    }

    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getFSCoppa = (callback?: (coppa: DisclosureModalContent) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("/FamilySharing/COPPA");

    let apiModalData;

    if (res.data.success) {
      apiModalData = {
        header: res.data.data.header,
        content: res.data.data.content,
        checkboxCopy: res.data.data.checkboxCopy
      };
      dispatch(setCoppa(apiModalData));
    }
    if (callback) {
      callback(apiModalData ?? intitialModalData);
    }

    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const postFamilyMemberInvite = (data: any, callback?: (success: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.post("/FamilySharing/FamilyMembers/Invite", data);

    dispatch(setIsLoading(false));

    if (callback) {
      callback(res.data.success);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const deleteFamilyMemberInvite = (memberID: number, groupID: number, callback?: (success: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete("/FamilySharing/FamilyMembers/Invite", {
      data: {
        memberID,
        groupID
      }
    });
    dispatch(setIsLoading(false));

    if (callback) {
      callback(res.data.success);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const putFamilyMemberInvite = (data: any, callback?: (success: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.put("/FamilySharing/FamilyMembers/Invite", data);

    dispatch(setIsLoading(false));

    if (callback) {
      callback(res.data.success);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const deleteFamilyGroup = (groupID: number, callback?: (success: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete("/FamilySharing", {
      data: {
        groupID
      }
    });
    dispatch(setIsLoading(false));

    if (callback) {
      callback(res.data.success);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getFSGroupList = (callback?: (groupList: Array<FSGroup>) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get<FSGroupReponse>("/FamilySharing");
    if (res.data.success) {
      dispatch(setFSGroupList(res.data.data));
    }

    if (callback) {
      callback(res.data.data ?? []);
    }

    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getFSStatuses = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("/FamilySharing/Statuses");

    if (res.data.success) {
      dispatch(setFSStatusList(res.data.data));
    }

    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getFSRelationshipList = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("/FamilySharing/RelationshipList");

    if (res.data.success) {
      dispatch(setFSRelationshipList(res.data.data));
    }

    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};
