import { FunctionComponent, ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import { toastCVConnection } from "../../../app/utils";

import strings from "../../strings/strings";
import SeityCVImage from "../SeityCVImage";

import "./styles.scss";

export type CoreValueType = {
  // CoreValue Image Type
  id: number;
  name: string;
  selected: boolean;
};

type SeityCVSelectionProps = {
  coreVals: Array<CoreValueType>;
  isReadOnly: boolean;
  size?: number;
  onChange: (coreVals: Array<CoreValueType>) => void;
};

const SeityCVSelection: FunctionComponent<SeityCVSelectionProps> = ({ coreVals, isReadOnly, size = 64, onChange }): ReactElement => {
  const data = isReadOnly
    ? coreVals.filter((c) => {
        return c.selected;
      })
    : coreVals;

  return (
    <div className="flex-ai-c mtb-xl">
      {/* eslint-disable-next-line no-nested-ternary */}
      {data.length > 0 ? (
        data.map((c, index) => {
          if (c.name.length === 0) {
            return <></>;
          }
          return (
            <button
              disabled={isReadOnly}
              className={`corevalue-button ${c.selected ? "selected" : ""}`}
              style={{ width: size, height: size, borderRadius: size / 2 }}
              onClick={() => {
                if (!c.selected) {
                  toastCVConnection(`${strings.connectedTo} ${c.name}`, c.id);
                }
                const temp = [...coreVals];
                temp[index].selected = !c.selected;
                onChange(temp);
              }}
              key={c.name}
            >
              <SeityCVImage cvId={c.id} size={size - 5} bgColor="transparent" />
            </button>
          );
        })
      ) : isReadOnly ? (
        <span className="no-corevalue-text">{strings.selectCV}</span>
      ) : (
        <img src={require("../../../assets/graphics/journals/no-coreval-img.png").default} alt="attach" />
      )}
    </div>
  );
};

export default SeityCVSelection;
