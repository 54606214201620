export class CoreValueCategorySelectionResponse {
  success: boolean;
  message: string | null;
  data: CoreValueCategorySelection[];

  constructor(success: boolean, message: string | null, data: CoreValueCategorySelection[]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class CoreValueCategorySelection {
  coreValueCategoryID: number;
  coreValueCategorySelectionID: number;
  heading: string;
  description: string;
  selected: boolean;
  sortOrder: string;
  image: string;

  constructor(
    coreValueCategoryID: number,
    coreValueCategorySelectionID: number,
    heading: string,
    description: string,
    selected: boolean,
    sortOrder: string,
    image: string
  ) {
    this.coreValueCategoryID = coreValueCategoryID;
    this.coreValueCategorySelectionID = coreValueCategorySelectionID;
    this.heading = heading;
    this.description = description;
    this.selected = selected;
    this.sortOrder = sortOrder;
    this.image = image;
  }
}

export class CoreValueCategorySelectionUpdate {
  coreValueCategorySelectionID: number;
  selected: boolean;
  topRated: boolean;

  constructor(coreValueCategorySelectionID: number, selected: boolean, topRated: boolean) {
    this.coreValueCategorySelectionID = coreValueCategorySelectionID;
    this.selected = selected;
    this.topRated = topRated;
  }
}

export class CoreValueSelectionResponse {
  success: boolean;
  message: string | null;
  data: CoreValueSelection[];

  constructor(success: boolean, message: string | null, data: CoreValueSelection[]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class CoreValueSelection {
  coreValueSelectionID: number;
  coreValueID: number;
  coreValue: string;
  coreValueText: string;
  selected: boolean;
  image: string;

  constructor(coreValueSelectionID: number, coreValueID: number, heading: string, description: string, selected: boolean, image: string) {
    this.coreValueSelectionID = coreValueSelectionID;
    this.coreValueID = coreValueID;
    this.coreValue = heading;
    this.coreValueText = description;
    this.selected = selected;
    this.image = image;
  }
}

export class CoreValueSelectionUpdate {
  coreValueSelectionID: number;
  selected: boolean;
  topRated: boolean;

  constructor(coreValueCategorySelectionID: number, selected: boolean, topRated: boolean) {
    this.coreValueSelectionID = coreValueCategorySelectionID;
    this.selected = selected;
    this.topRated = topRated;
  }
}

export class CoreValueGeneric {
  id: number;
  cvCateId: number;
  cvId: number;
  heading: string;
  description: string;
  selected: boolean;
  sortOrder: string;
  image: string;

  constructor(
    id: number,
    cvCateId: number,
    cvId: number,
    heading: string,
    description: string,
    selected: boolean,
    sortOrder: string,
    image: string
  ) {
    this.id = id;
    this.cvCateId = cvCateId;
    this.cvId = cvId;
    this.heading = heading;
    this.description = description;
    this.selected = selected;
    this.sortOrder = sortOrder;
    this.image = image;
  }
}

export class CoreValueGenericUpdate {
  id: number;
  selected: boolean;
  topRated: boolean;

  constructor(id: number, selected: boolean, topRated: boolean) {
    this.id = id;
    this.selected = selected;
    this.topRated = topRated;
  }
}

export class CoreValue {
  coreValueID: number;
  coreValue: string;
  coreValueText: string;
  description: string;
  descriptionText: string;
  quote: string;
  coreValueImage: string;
  coreValueCategoryID: number;
  coreValueCategory: string;
  coreValueCategoryText: string;
  coreValueCategoryImage: string;
  userSortOrder: number;
  video: string;

  constructor(
    coreValueID: number,
    coreValue: string,
    coreValueText: string,
    description: string,
    descriptionText: string,
    quote: string,
    coreValueImage: string,
    coreValueCategoryID: number,
    coreValueCategory: string,
    coreValueCategoryText: string,
    coreValueCategoryImage: string,
    userSortOrder: number,
    video: string
  ) {
    this.coreValueID = coreValueID;
    this.coreValue = coreValue;
    this.coreValueText = coreValueText;
    this.description = description;
    this.descriptionText = descriptionText;
    this.quote = quote;
    this.coreValueImage = coreValueImage;
    this.coreValueCategoryID = coreValueCategoryID;
    this.coreValueCategory = coreValueCategory;
    this.coreValueCategoryText = coreValueCategoryText;
    this.coreValueCategoryImage = coreValueCategoryImage;
    this.userSortOrder = userSortOrder;
    this.video = video;
  }
}

export class FourCoreValuesResponse {
  success: boolean;
  message: string | null;
  data: CoreValue[];

  constructor(success: boolean, message: string | null, data: CoreValue[]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class FourCoreValuesSortOrderUpdate {
  coreValueID01: number;
  coreValueID02: number;
  coreValueID03: number;
  coreValueID04: number;
  isRetake: boolean;

  constructor(coreValueID01: number, coreValueID02: number, coreValueID03: number, coreValueID04: number,
    isRetake: boolean
  ) {
    this.coreValueID01 = coreValueID01;
    this.coreValueID02 = coreValueID02;
    this.coreValueID03 = coreValueID03;
    this.coreValueID04 = coreValueID04;
    this.isRetake = isRetake;
  }
}
