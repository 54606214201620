//all commented out code in this file was commented out to avoid compile errors and will need to be put back in once LS or Cookie is
//implemented

import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  forgotPassword,
  signup,
  getNewToken,
  sendTemporaryPassword,
  googleLogin,
  sendForgotUsername,
  sendTemporaryPassword2
} from "../../api/seityHealthAPI-Auth";
import { removeTokens } from "./RemoveToken";
import { getExpirationTime, getRefreshKey, getToken, saveExpiration, saveRefreshKey, cacheToken, getRefreshedToken } from "./UseSessionStorageToken";

import { resetCoreValueSlice } from "../coreValues/coreValuesSlice";
import { resetOutcomeSlice } from "../outcomes/outcomesSlice";
import { resetPracticeSlice } from "../practices/practicesSlice";
import { resetStoryListSlice } from "../grow/StoriesSyncs/storyListSlice";
import { resetStoryDetailSlice } from "../grow/StoriesSyncs/storyDetailSlice";
import { resetSyncListSlice } from "../grow/StoriesSyncs/syncListSlice";
import { resetSyncDetailSlice } from "../grow/StoriesSyncs/syncDetailSlice";
import { resetChallengeSlice } from "../challenges/challengeSlice";
import { resetCheckInSlice } from "../wellbeingCheckIn/checkInSlice";
import { resetRegisterSlice } from "../register/registerSlice";
import { resetAccountSlice, sendLangUpdateRequest } from "../account/accountSlice";
import { resetCharacterizationSlice } from "../myStory/myStorySlice";
import { LOCKED_ACCOUNT_ID } from "../../consts";
import {SeityLoginResponse} from "../../api/authTypes";
import {SeityGoogleLoginResponse} from "../../_core/components/SeityGoogleLoginButton/types";
import { resetPlotlineSlice } from "../plotline/plotlineSlice";
import { resetDirectorySlice } from "../directory/directorySlice";
import { resetFamilySharingSlice } from "../familySharing/familySharingSlice";
import { resetSettingsSlice } from "../../slices/userAppSettingsSlice";

export interface AuthState {
  readonly authError: string | null;
  readonly googleAuthError: boolean;
  readonly isLoading: boolean;
  readonly token: string;
  readonly refreshKey: string;
  readonly accountID: number;
  readonly expirationTime: number;
  readonly resetPasswordSuccess: boolean;
  readonly shouldRedirectToLogin: boolean;
  readonly shouldRedirectToLockedAccount: boolean;
  readonly showLockedAccount: boolean;
}

const setInitialState = {
  authError: null,
  googleAuthError: false,
  isLoading: false,
  token: "",
  refreshKey: "",
  accountID: -1,
  expirationTime: -1,
  resetPasswordSuccess: false,
  shouldRedirectToLogin: false,
  shouldRedirectToLockedAccount: false,
  showLockedAccount: false
} as AuthState;

const authSlice = createSlice({
  name: "auth",
  initialState: setInitialState,
  reducers: {
    loginRequest(state, action) {
      const { token, refreshKey, accountID, expirationTime } = action.payload;
      state.accountID = accountID;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
      state.isLoading = false;
      state.authError = null;
    },
    logoutRequest(state, action) {
      state.token = "";
      state.refreshKey = "";
      state.accountID = -1;
      state.expirationTime = -1;
    },
    forgotPasswordRequestSuccess(state) {
      state.isLoading = false;
      state.authError = null;
      state.resetPasswordSuccess = true;
    },
    sendTemporaryPassword(state) {
      state.isLoading = false;
      state.authError = null;
      state.resetPasswordSuccess = true;
    },
    setTokens(state, action) {
      const { token, refreshKey, expirationTime } = action.payload;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
      state.isLoading = false;
    },
    refreshToken(state, action) {
      const { token, refreshKey, expirationTime } = action.payload;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
    },
    setRedirectToLogin(state, action) {
      state.isLoading = false;
      state.shouldRedirectToLogin = action.payload.shouldRedirectToLogin;
    },
    setRedirectToLockedAccount(state, action) {
      state.isLoading = false;
      state.shouldRedirectToLockedAccount = action.payload.shouldRedirectToLockedAccount;
    },
    setShowLockedAccount(state, action) {
      state.isLoading = false;
      state.showLockedAccount = action.payload.showLockedAccount;
    },
    signupRequest(state, action) {
      const { token, refreshKey, accountID, expirationTime } = action.payload;
      state.accountID = accountID;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
      state.isLoading = false;
      state.authError = null;
    },
    apiError(state, action) {
      const { authError, googleAuthError } = action.payload;
      state.authError = authError;
      state.googleAuthError = googleAuthError || false;
      state.isLoading = false;
      state.resetPasswordSuccess = false;
    },
    clearApiError(state, action) {
      state.authError = null;
      state.googleAuthError = false;
      state.resetPasswordSuccess = false;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    resetAuthSlice: () => setInitialState
  }
});
// Be sure to add new slices here to
// be able to access them directly
export const {
  loginRequest,
  apiError,
  clearApiError,
  setIsLoading,
  forgotPasswordRequestSuccess,
  signupRequest,
  setTokens,
  refreshToken,
  setRedirectToLogin,
  logoutRequest,
  resetAuthSlice,
  setRedirectToLockedAccount,
  setShowLockedAccount
} = authSlice.actions;
export default authSlice.reducer;

// THUNK FUNCTIONS
export const sendLoginRequest = (username: string, password: string, langID: number, googleLoginResponse?: SeityGoogleLoginResponse | null) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));

    let loginResponse: SeityLoginResponse;
    if (googleLoginResponse) {
      loginResponse = await googleLogin(googleLoginResponse);
    } else {
      loginResponse = await login(username, password);
    }

    if (loginResponse === null || loginResponse.data === null) {
      dispatch(
        apiError({
          authError: "There was an error logging in, please try again."
        })
      );
      return;
    }
    if (loginResponse.data && loginResponse.data.token) {
      console.log("Logged in: ", loginResponse.data.expirationTime);
      cacheToken(loginResponse.data.token);
      saveRefreshKey(loginResponse.data.refreshKey);
      saveExpiration(loginResponse.data.expirationTime);
    }
    await dispatch(loginRequest(loginResponse.data));
    if (langID !== -1) {
      dispatch(sendLangUpdateRequest(langID!, false));
    }
  } catch (err) {
    if (err.response?.data?.endPointMessageID === LOCKED_ACCOUNT_ID) {
      dispatch(setShowLockedAccount({ showLockedAccount: true }));
    }
    handleError(err, dispatch, !!googleLoginResponse);
  }
};

export const sendGoogleLoginRequest = (
  googleLoginResponse: SeityGoogleLoginResponse,
  langID: number
) => async (dispatch: any) => {

  dispatch(sendLoginRequest('', '', langID, googleLoginResponse));
};

export const sendLogoutRequest = () => (dispatch: any) => {
  removeTokens();
  dispatch(logoutRequest({}));
};

export const sendForgotPasswordRequest = (email: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const forgotResponse = await forgotPassword(email);
    if (forgotResponse === null || forgotResponse.success === false) {
      dispatch(
        apiError({
          authError: "There was an error sending your forgot password request, please try again."
        })
      );
      return;
    }
    dispatch(forgotPasswordRequestSuccess());
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendTemporaryPasswordRequest = (email: string, callback?: Function) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const forgotResponse = await sendTemporaryPassword(email);
    if (forgotResponse === null || forgotResponse.success === false) {
      dispatch(
        apiError({
          authError: "There was an error sending your forgot password request, please try again."
        })
      );
      return;
    }
    if (forgotResponse.success) {
      if (callback) {
        callback();
      }
    }
    dispatch(forgotPasswordRequestSuccess());
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendTemporaryPasswordRequest2 =
  (email: string, phoneNumber: string, dateOfBirth: string, callback?: Function) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearApiError({}));
      const forgotResponse = await sendTemporaryPassword2(email, phoneNumber, dateOfBirth);
      if (forgotResponse === null || forgotResponse.success === false) {
          dispatch(
            apiError({
              authError: 'There was an error sending your forgot password request, please try again.'
            })
          );
        return;
      }
      if (forgotResponse.success) {
        if (callback) {
          callback();
        }
      }
      dispatch(forgotPasswordRequestSuccess());
    } catch (err: any) {
      dispatch(apiError({ authError: err.message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const sendForgotUsernameRequest =
  (email: string, phoneNumber: string, dateOfBirth: string, callback?: Function) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearApiError({}));
      const forgotResponse = await sendForgotUsername(email, phoneNumber, dateOfBirth);
      if (forgotResponse === null || forgotResponse.success === false) {
          dispatch(
            apiError({
              authError: 'There was an error sending your forgot username request, please try again.'
            })
          );
        return;
      }
      if (forgotResponse.success) {
        if (callback) {
          callback();
        }
      }
      dispatch(forgotPasswordRequestSuccess());
    } catch (err: any) {
      dispatch(apiError({ authError: err.message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const sendSignupRequest =
  (email: string, password: string, firstName: string, lastName: string, groupActivationCode: string) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const signupResponse = await signup(email, password, firstName, lastName, groupActivationCode);
      if (signupResponse === null || signupResponse.success === false || signupResponse.message) {
        dispatch(
          apiError({
            authError: "There was an error signing you up, please try again."
          })
        );
        return;
      }
      if (signupResponse.data && signupResponse.data.returnCode === 1) {
        dispatch(apiError({ authError: "The email address is already registered." }));
        return;
      }
      if (signupResponse.data && signupResponse.data.returnCode === 2) {
        dispatch(apiError({ authError: "The activation code entered is invalid." }));
        return;
      }
      if (signupResponse.data && signupResponse.data.token) {
        console.log("Signup success.");
        cacheToken(signupResponse.data.token);
        saveRefreshKey(signupResponse.data.refreshKey);
        saveExpiration(signupResponse.data.expirationTime);
      }
      dispatch(signupRequest(signupResponse.data));
    } catch (err) {
      console.error(JSON.stringify(err));
      handleError(err, dispatch);
    }
  };

export const getSavedToken = (callback?: () => void) => async (dispatch: any) => {
  dispatch(setIsLoading({}));
  const token = await getToken();
  const refreshKey = await getRefreshKey();
  const expirationTime = await getExpirationTime();

  if (token && expirationTime) {
    try {
      const expirationDate = new Date(expirationTime * 1000);
      if (new Date(new Date().getTime() + 30 * 60 * 1000) > expirationDate) {
        console.log("Saved token expired, attempting to refresh token...");
        dispatch(sendRefreshToken());
        return;
      }
      dispatch(setTokens({ token, refreshKey, expirationTime }));
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    }
  } else {
    console.log("Token or expiration time not found.");
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
  }
};

export const sendRefreshToken = () => async (dispatch: any) => {
  dispatch(setIsLoading({}));
  const refreshKey = await getRefreshKey();
  const token = await getToken();
  if (!refreshKey) {
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    return;
  }
  try {
    const response = await getNewToken(refreshKey, token ?? "");
    if (response.success === false) {
      removeTokens();
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      return;
    }
    if (response.data && response.data.token) {
      console.log("Token refreshed successfully.");
      cacheToken(response.data.token);
      saveRefreshKey(response.data.refreshKey);
      saveExpiration(response.data.expirationTime);
      dispatch(refreshToken(response.data));
    } else {
      removeTokens();
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    }
  } catch (err) {
    console.error(err);
    removeTokens();
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
  }
};

export const checkToken = () => async (dispatch: any) => {
  const { token } = await getRefreshedToken();
  if (token === null) {
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
  }
};

export const logOut = () => async (dispatch: any) => {
  removeTokens();
  dispatch(resetRegisterSlice());
  dispatch(resetCoreValueSlice());
  dispatch(resetOutcomeSlice());
  dispatch(resetPracticeSlice());
  dispatch(resetStoryListSlice());
  dispatch(resetStoryDetailSlice());
  dispatch(resetSyncListSlice());
  dispatch(resetSyncDetailSlice());
  dispatch(resetChallengeSlice());
  dispatch(resetCharacterizationSlice());
  dispatch(resetCheckInSlice());
  dispatch(resetAuthSlice());
  dispatch(resetAccountSlice());
  dispatch(resetPlotlineSlice());
  dispatch(resetDirectorySlice());
  dispatch(resetFamilySharingSlice());
  dispatch(resetSettingsSlice());
  dispatch(logoutRequest({}));
  dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
};

function handleError(err: Error, dispatch: any, googleAuthError: boolean = false) {
  console.error(err);
  dispatch(apiError({ googleAuthError: googleAuthError, authError: err.message }));
}
