import { FunctionComponent, ReactElement, useState } from "react";

import CoreStrings from "../../../_core/strings/strings";
import SeityModal from "../../../_core/components/SeityModal";
import { SeityTextInput } from "../../../_core/components/SeityTextInput/SeityTextInput";
import SeityButton from "../../../_core/components/SeityButton";

import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { postFSGroup } from "../familySharingSlice";
import SeityLoader from "../../../_core/components/SeityLoader";
import { RootState } from "../../../app/rootReducer";

export type FSNewFGModalProps = {
  onClose: (groupId?: number) => void;
}

const FSNewFGModal: FunctionComponent<FSNewFGModalProps> = ({ onClose }): ReactElement => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => {
    return state.familySharingSlice;
  });

  const [groupName, setGroupName] = useState("");

  return (
    <SeityModal wrapperClassName="fsNewFGModal" title={CoreStrings.newFamilyGroup} showCloseBtn={true} isOpen={true} onClose={onClose}>
      <div className="fsNewFGModal-content">
        {isLoading && <SeityLoader />}
        <SeityTextInput autoFocus={true} value={groupName} onChange={(e) => {
          setGroupName(e.target.value);
        }} />
        <SeityButton label={CoreStrings.create} disabled={groupName.length < 1} onClick={() => {
          // api call
          dispatch(postFSGroup(groupName, (groupId) => {
            onClose(groupId);
          }));
        }} />
        <SeityButton type="cancel" label={CoreStrings.cancelButtonLabel} onClick={() => onClose()} />
      </div>
    </SeityModal>
  );
};

export default FSNewFGModal;
