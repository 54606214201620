import { FunctionComponent, useRef, useState } from "react";
import { SliderProps } from "./types";

import strings from "../../../../_core/strings/strings";
import "./styles.scss";

export const Slider: FunctionComponent<SliderProps> = ({ info, title, items, renderItem, goToGallery }) => {
  const [isScrolledEnd, setIsScrolledEnd] = useState(false);
  const [isScrolledStart, setIsScrolledStart] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const swiperRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  const scroll = (scrollOffset: number) => {
    if (swiperRef.current !== null) {
      swiperRef.current.scrollLeft += scrollOffset;
      setIsScrolledEnd(swiperRef.current.clientWidth + swiperRef.current.scrollLeft + scrollOffset >= swiperRef.current.scrollWidth);
      setIsScrolledStart(swiperRef.current.scrollLeft + scrollOffset <= 0);
    }
  };

  return (
    <div className="post-wrapper">
      <div className="post-head">
        <div className="post-head-title">
          <h2>{title}</h2>
          <img alt="info" src={require("../../../../assets/graphics/info.png").default} onClick={() => setIsInfoVisible(!isInfoVisible)} />
          {isInfoVisible && (
            <div className="post-head-title-info-modal">
              <img
                className="close-info"
                src={require("../../../../assets/icons/close-black.png").default}
                onClick={() => setIsInfoVisible(!isInfoVisible)}
              />
              <h2>{title}</h2>
              <h4>{info}</h4>
            </div>
          )}
        </div>

        <div className="post-head-buttons">
          <input
            type="image"
            onClick={
              // @ts-ignore
              () => scroll(-itemRef.current.offsetWidth)
            }
            src={require("../../../../assets/graphics/back-teal-arrow.png").default}
            alt="back"
            disabled={isScrolledStart}
          />
          <input
            type="image"
            onClick={
              // @ts-ignore
              () => scroll(itemRef.current.offsetWidth)
            }
            src={require("../../../../assets/graphics/forward-teal-arrow.png").default}
            alt="next"
            disabled={isScrolledEnd || items.length < 2}
          />
          <h4
            className={items.length < 2 ? "disabled" : ""}
            onClick={() => {
              if (goToGallery) {
                goToGallery();
              }
            }}
          >
            {strings.seeAll}
          </h4>
        </div>
      </div>
      <div className="swiper" ref={swiperRef}>
        {items.map((post, index) => (
          <div key={index} ref={itemRef}>
            {renderItem(post, index)}
          </div>
        ))}
      </div>
    </div>
  );
};
