import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import SeityCVImage from "../../../_core/components/SeityCVImage";
import SeityLoader from "../../../_core/components/SeityLoader";

import strings from "../../../_core/strings/strings";
import { putAffirmationRequest } from "../todaySlice";
import { ShowAffirmationProps } from "../types";
import "./styles.scss";
import { isPlotlineInjectionCompleted } from "../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";

export const ShowAffirmation: FunctionComponent<ShowAffirmationProps> = ({ affirmData, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading } = useSelector((state: RootState) => {
    return state.today;
  });
  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  return (
    <div className="show-affirmation flex flex-column">
      {isLoading && <SeityLoader />}
      <div className="sa-header">
        <SeityCVImage cvId={affirmData.coreValueID} bgColor={"#f75a3b"} size={56} />
        <div className="ml-lg">
          <h5>{affirmData.coreValue}</h5>
          <span>{strings.daillyAffirmation}</span>
        </div>
      </div>
      <div className="sa-content pt-xl pb-xl">{affirmData.coreValueAffirmation}</div>
      <div className="sa-footer">
        <button className="mr-sm" onClick={onClose}>
          {strings.back}
        </button>
        <button
          className="ml-sm"
          onClick={() => {
            dispatch(
              putAffirmationRequest(affirmData.coreValueAffirmationID, () => {
                if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.OnDailyAffirmation)) {
                  history.push("/plotline/" + PlotlineInjectionPointID.OnDailyAffirmation);
                } else {
                  history.push("/");
                }
              })
            );
          }}
        >
          <img className="mr-sm" src={require("../../../assets/graphics/today/pin-icon.png").default} />
          {strings.pin}
        </button>
      </div>
    </div>
  );
};

export default ShowAffirmation;
