/* eslint-disable no-param-reassign */
import "./styles.css";

export interface SeityProgressBarProps {
  progress: number | undefined;
  color?: string;
  containerStyle?: string;
  trackStyle?: string;
  testID?: string;
  showValue?: boolean;
  height?: number;
  trackColor?: string;
}

const SeityProgressBar = ({ progress, color, trackStyle, testID, containerStyle, height, trackColor }: SeityProgressBarProps) => {
  progress = progress && progress > 100 ? 100 : progress;
  progress = progress && progress < 0 ? 0 : progress;

  const borderRadius = height ? height / 2 : undefined;
  const containerClassName = `seity-progress-container ${containerStyle}`;
  const trackClassName = `seity-progress-track ${trackStyle}`;

  return (
    <div
      data-testid={testID}
      className={containerClassName}
      style={{
        height,
        borderRadius,
        backgroundColor: trackColor
      }}
    >
      <div
        className={trackClassName}
        style={{
          width: `${progress}%`,
          backgroundColor: color,
          borderRadius
        }}
      />
    </div>
  );
};

export default SeityProgressBar;
