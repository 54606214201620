import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../../app/rootReducer";
import { validPassword } from "../../../../_core/utils/accountUtils/accountUtils";
import { sendChangePasswordRequest, setAccountError, clearAccountError, setChangePasswordSuccessful } from "../../accountSlice";

import { SeityButton } from "../../../../_core/components/SeityButton";
import { SeityFormTextInput } from "../../../../_core/components/SeityTextInputPassword/SeityFormTextInput";
import { SeityModal } from "../../../../_core/components/SeityModal";

import strings from "../../../../_core/strings/strings";
import "./styles.scss";
import { setForceNewPasswordFalse } from "../../../myStory/myStorySlice";
import { setForceNewPassword } from "../../../../slices/userAppSettingsSlice";

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountError, isPasswordChangeSuccessful } = useSelector((state: RootState) => {
    return state.account;
  });
  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);

  const isPasswordEmpty = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentPassword === "" ? setCurrentPasswordError(true) : setCurrentPasswordError(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    newPassword === "" ? setNewPasswordError(true) : setNewPasswordError(false);
  };

  const cancelClicked = () => {
    dispatch(setChangePasswordSuccessful({ passwordChangeSuccessful: false }));
    dispatch(setForceNewPasswordFalse());
    dispatch(setForceNewPassword());
    history.push("/");
  };
  const changePasswordClicked = () => {
    isPasswordEmpty();
    if (!validPassword(newPassword) || newPassword === "") {
      setNewPasswordError(true);
      dispatch(setAccountError({ accountError: strings.passwordCriteria }));
    } else if (currentPassword === newPassword) {
      setNewPasswordError(true);
      dispatch(setAccountError({ accountError: strings.samePassword }));
    } else {
      dispatch(sendChangePasswordRequest(currentPassword, newPassword));
    }
  };

  useEffect(() => {
    dispatch(clearAccountError());
  }, []);

  return (
    <div className="tempPwd-container">
      <h1>{strings.createNewPassword}</h1>
      <h4 style={{ width: "300px", textAlign: "center", fontSize: 20 }}>{strings.timeToChange}</h4>
      <form>
        <SeityFormTextInput
          inputLabelText={userAppSettings.forceNewPassword ? strings.temporaryPassword : strings.currentPassword}
          value={currentPassword}
          onChange={(e) => {
            return setCurrentPassword(e.target.value);
          }}
          type="password"
          inputStyle={{ borderColor: currentPasswordError ? "red" : "#c1c7cd" }}
        />
        <SeityFormTextInput
          inputLabelText={strings.newPassword}
          value={newPassword}
          onChange={(e) => {
            return setNewPassword(e.target.value);
          }}
          type="password"
          moreInfoText={strings.passwordCriteria}
          formStyle={{ marginTop: "20px" }}
          inputStyle={{ borderColor: newPasswordError ? "red" : "#c1c7cd" }}
        />
      </form>
      <div style={{ width: "390px", textAlign: "center", marginTop: 20 }}>
        <span style={{ color: "red" }}>{accountError !== null ? accountError : ""}</span>
      </div>
      <SeityModal isOpen={isPasswordChangeSuccessful} onClose={cancelClicked}>
        <div className="tempPwd-modal">
          <h2>{strings.changePasswordModalHeading}</h2>
          <SeityButton label={strings.ok} type="next" onClick={cancelClicked} />
        </div>
      </SeityModal>
      <SeityButton
        label={strings.changePassword}
        type="next"
        onClick={changePasswordClicked}
        style={{
          height: 60,
          width: 310,
          marginTop: "50px",
          borderRadius: 56,
          paddingVertical: 3,
          marginBottom: 50
        }}
      />
    </div>
  );
};

export default ChangePassword;
