import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import accountReducer from "../features/account/accountSlice";
import checkInReducer from "../features/wellbeingCheckIn/checkInSlice";
import registerReducer from "../features/register/registerSlice";
import assessmentReducer from "../features/assessment/assessmentReducer";
import fourCoreValuesReducer from "../features/coreValues/coreValuesSlice";
import outcomeScoresReducer from "../features/outcomes/outcomesSlice";
import practiceScoresReducer from "../features/practices/practicesSlice";
import coreValuesIncentiveReducer from "../features/assessment/cvIncentiveSlice";
import characterizationReducer from "../features/myStory/myStorySlice";
import challengeReducer from "../features/challenges/challengeSlice";
import surveyReducer from "../features/survey/SurveySlice";

import storyListReducer from "../features/grow/StoriesSyncs/storyListSlice";
import storyDetailReducer from "../features/grow/StoriesSyncs/storyDetailSlice";
import syncListReducer from "../features/grow/StoriesSyncs/syncListSlice";
import syncDetailReducer from "../features/grow/StoriesSyncs/syncDetailSlice";
import learnReducer from "../features/grow/learn/learnSlice";
import guideReducer from "../features/grow/learn/guideSlice";

import resourcesReducer from "../features/resources/resourceSlice";
import appContentReducer from "../features/policy/appContentSlice";
import journalsSlice from "../features/journals/journalsSlice";
import intentionsSlice from "../features/intentions/intentionsSlice";
import todaySlice from "../features/today/todaySlice";
import userAppSettingsSlice from "../slices/userAppSettingsSlice";
import commonAlertSlice from "../slices/commonAlertSlice";

import plotlineSlice from "../features/plotline/plotlineSlice";
import directorySlice from "../features/directory/directorySlice";
import familySharingSlice from "../features/familySharing/familySharingSlice";
import wellbeingSlice from "../features/wellbeing/wellbeingSlice";
import personalListSlice from "../features/cvDirectory/slice";
import coreValueHistorySlice from "../features/coreValues/CoreValueHistory/coreValueHistorySlice";

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  checkIn: checkInReducer,
  register: registerReducer,
  assessment: assessmentReducer,
  coreValuesIncentive: coreValuesIncentiveReducer,
  fourCoreValues: fourCoreValuesReducer,
  outcomeScores: outcomeScoresReducer,
  practiceScores: practiceScoresReducer,
  characterization: characterizationReducer,
  challenge: challengeReducer,
  storyList: storyListReducer,
  storyDetail: storyDetailReducer,
  syncList: syncListReducer,
  syncDetail: syncDetailReducer,
  appContent: appContentReducer,
  resources: resourcesReducer,
  survey: surveyReducer,
  learn: learnReducer,
  journals: journalsSlice,
  guide: guideReducer,
  intentions: intentionsSlice,
  today: todaySlice,
  userAppSettings: userAppSettingsSlice,
  commonAlertSlice: commonAlertSlice,
  plotline: plotlineSlice,
  directory: directorySlice,
  familySharingSlice: familySharingSlice,
  wellbeing: wellbeingSlice,
  personalListSlice: personalListSlice,
  coreValueHistory: coreValueHistorySlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
