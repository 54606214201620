import { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

export type QuoteParserProps = {
  quote: string;
  descriptionText: string;
};

export const QuoteParser: FunctionComponent<QuoteParserProps> = ({ quote, descriptionText }): ReactElement => {
  const quoteIndex = quote.indexOf(" -");
  const quoteString = quote.slice(0, quoteIndex);
  const authorString = quote.slice(quoteIndex);

  return quoteIndex !== -1 ? (
    <>
      <div className="mcv-core-value-text-wrapper">
        <div className="mcv-core-value-description">{descriptionText}</div>
        <div className="mcv-core-value-quote">{quoteString}</div>
      </div>
      <div className="mcv-core-value-quote-author">{authorString}</div>
    </>
  ) : (
    <div>
      <div className="mcv-core-value-quote">{quote}</div>
    </div>
  );
};

export default QuoteParser;
