import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DICongratulationsType, IntentionSelected, PutIntentionType } from "../../../api/dailyIntention/intentionTypes";
import { RootState } from "../../../app/rootReducer";
import SeityCVSelection, { CoreValueType } from "../../../_core/components/SeityCVSelection";
import SeityIdkModal from "../../../_core/components/SeityIdkModal";
import SeityLoader from "../../../_core/components/SeityLoader";
import strings from "../../../_core/strings/strings";
import { getSortedFourCoreValuesRequest } from "../../coreValues/coreValuesSlice";
import { makeUpCoreValueData, makeUpCoreValueDataWithFour } from "../../journals/Journals/helpers";
import CompleteSuccessModal from "../CompleteModal";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import CVSelectionModal from "../CVSelectionModal";
import { convertCorevals } from "../IntentionSelected/helpers";
import { deleteIntentionRequest, getCongratulationsRequest, putIntentionRequest } from "../intentionsSlice";

import "./styles.scss";

type LocationStateProps = {
  intention: IntentionSelected;
};

const IntentionCard: FunctionComponent = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sortedFourCoreValues } = useSelector((state: RootState) => {
    return state.fourCoreValues;
  });

  const location = useLocation<LocationStateProps>();
  const { intention } = location?.state;

  const [showDelModal, setShowDelModal] = useState(false);
  const [coreVals, setCoreVals] = useState<Array<CoreValueType>>([]);
  const [showCVModal, setShowCVModal] = useState(false);
  const [showIdkModal, setShowIdkModal] = useState(false);
  const [showElipsMenu, setShowElipsMenu] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [intentionData, setIntentionData] = useState(intention);

  useEffect(() => {
    dispatch(getSortedFourCoreValuesRequest());
    dispatch(getCongratulationsRequest(DICongratulationsType.Complete));
  }, []);

  useEffect(() => {
    const cv = makeUpCoreValueData(intention);
    if (cv.length === 0 && sortedFourCoreValues.length === 4) {
      setCoreVals(makeUpCoreValueDataWithFour(sortedFourCoreValues));
    } else {
      setCoreVals(cv);
    }
  }, [sortedFourCoreValues]);

  const handleCommit = (vals: Array<CoreValueType>, isCompleted: boolean) => {
    const cv = convertCorevals(vals);
    if (cv) {
      setLoading(true);
      const param: PutIntentionType = {
        dailyIntentionAccountID: intentionData.dailyIntentionAccountID,
        ...cv,
        isCompleted
      };
      dispatch(
        putIntentionRequest(param, () => {
          setLoading(false);
          if (isCompleted) {
            setShowComplete(true);

            // setTimeout(() => {
            //   setShowComplete(false);
            // }, 3000);
          }
          const temp = { ...intentionData };
          temp.isCompleted = isCompleted;

          setIntentionData(temp);
        })
      );
    } else {
      // Need more action when corevalues are empty
    }
  };

  return (
    <div className="intention-card flex-col flex-ai-c">
      <div className="intention-card__header flex-col flex-ai-c">
        <div className="header-background" style={{ backgroundImage: `url(${intentionData.imageFileName + "h.png"})` }} />
        <div className="header-background-overlay" />
        <div className="header-wrap plr-md flex-col w-100 h-100">
          <div className="flex-ai-c w-100 mt-xxl mb-xl">
            <button
              className="header-back-button mr-auto"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={require("../../../assets/graphics/intentions/btn-back-white.png").default} alt="back btn" width={60} height={32} />
            </button>
            <button
              onClick={() => {
                setShowElipsMenu(!showElipsMenu);
              }}
            >
              <img src={require("../../../assets/graphics/intentions/ellips.png").default} alt="elips menu" width={60} height={37} />
            </button>
            {showElipsMenu && (
              <div className="ellips-menu p-lg">
                <button
                  className="ellips-menu__button"
                  onClick={() => {
                    setShowDelModal(true);
                  }}
                >
                  {strings.deleteIntention}
                </button>
              </div>
            )}
          </div>
          <p>
            {strings.todayIWill}
            <br />
            {intentionData.text}
          </p>
          {intentionData.isCompleted && (
            <div className="check-icon flex-center">
              <img src={require("../../../assets/graphics/intentions/check.png").default} alt="check icon" width={17} />
            </div>
          )}
        </div>
      </div>
      <div className="intention-card__body w-100 mt-lg flex-col p-lg flex-ai-c">
        <span className="w-100">{strings.coreValues}</span>
        {coreVals.length > 0 ? (
          <div
            className={`cv-selection w-100 flex flex-ai-c ${intentionData.isCompleted ? "disabled" : ""}`}
            onClick={() => {
              setShowCVModal(true);
            }}
          >
            {coreVals.length > 0 ? (
              <SeityCVSelection
                coreVals={coreVals}
                isReadOnly
                size={32}
                onChange={(vals) => {
                  setCoreVals(vals);
                }}
              />
            ) : (
              <span>{strings.selectCV}</span>
            )}
            <img
              className="ml-auto"
              src={require("../../../assets/graphics/intentions/edit.png").default}
              alt="edit"
              width={25}
              height={24}
            />
          </div>
        ) : (
          <img className="mt-xl mb-xl" src={require("../../../assets/graphics/journals/no-coreval-img.png").default} alt="attach" />
        )}

        <button
          className={`intention-button ${intentionData.isCompleted ? "completed" : ""}`}
          onClick={() => {
            handleCommit(coreVals, !intentionData.isCompleted);
          }}
        >
          {intentionData.isCompleted && (
            <img
              className="mr-lg"
              src={require("../../../assets/graphics/intentions/check.png").default}
              alt="check icon"
              width={17}
              height={16}
            />
          )}
          {intentionData.isCompleted ? strings.completed : strings.complete}
        </button>
      </div>
      <ConfirmDeleteModal
        isOpen={showDelModal}
        onDelete={() => {
          setLoading(true);
          dispatch(
            deleteIntentionRequest(intention.dailyIntentionAccountID, () => {
              setLoading(false);
              setShowDelModal(false);
              history.goBack();
            })
          );
        }}
        onClose={() => {
          setShowDelModal(false);
        }}
        onCancel={() => {
          setShowDelModal(false);
        }}
      />
      {showCVModal && (
        <CVSelectionModal
          isOpen={showCVModal}
          cVals={coreVals}
          onClose={() => {
            setShowCVModal(false);
          }}
          onSave={(vals) => {
            setShowCVModal(false);
            setCoreVals(vals);

            handleCommit(vals, false);
          }}
          onIdk={() => {
            setShowCVModal(false);
            setShowIdkModal(true);
          }}
        />
      )}
      {showIdkModal && (
        <SeityIdkModal
          isOpen={showIdkModal}
          onClose={() => {
            setShowIdkModal(false);
          }}
        />
      )}
      {isLoading && <SeityLoader />}
      {showComplete && (
        <CompleteSuccessModal
          isOpen
          onClose={() => {
            setShowComplete(false);
          }}
        />
      )}
    </div>
  );
};

export default IntentionCard;
