import { FunctionComponent } from "react";
import { SeityHyperTextProps } from "./types";

import "./styles.scss";

export const SeityHyperText: FunctionComponent<SeityHyperTextProps> = ({ title, onClick, type }) => {
  return (
    <div className={`seity-hyper-text-container${type ? `-${type}` : ""}`} onClick={onClick}>
      {title}
    </div>
  );
};
