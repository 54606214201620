import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";

export type PlotlineTemplateChartProps = {
  dynamicChartPercentage: number | null;
};

const PlotlineTemplateChart: FunctionComponent<PlotlineTemplateChartProps> = (props): ReactElement => {
  const { dynamicChartPercentage } = props;

  return (
    <div className="plotline-chart-body">
      {/* @ts-ignore  - because dynamicChartPercentage can be null */}
      <div className="circular-progress" style={{ background: `conic-gradient(#67acaf ${dynamicChartPercentage * 3.6}deg, #e1ecec 0deg)` }}>
        <div className="circle-inner"></div>
        <span className="progress-value">{dynamicChartPercentage}%</span>
      </div>
    </div>
  );
};

export default PlotlineTemplateChart;
