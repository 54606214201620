import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { sendGetChallengeIntroduction, sendGetChallengeState } from "../challengeSlice";
import { RootState } from "../../../app/rootReducer";
import { IMAGE_URL } from "../../../api/apiConstants";
import { SeityChallengeStateIndex } from "../../../api/challenge/challengeTypes";

import SeityButton from "../../../_core/components/SeityButton";

import strings from "../../../_core/strings/strings";
import "../styles.scss";

const ChallengeAcceptance = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { challengeState, challengeIntroduction } = useSelector((state: RootState) => {
    return state.challenge;
  });

  useEffect(() => {
    dispatch(sendGetChallengeState());
  }, []);

  useEffect(() => {
    if (!challengeState) {
      return;
    }

    if (challengeState.challengeState === SeityChallengeStateIndex.START || challengeState.objectiveTypeID === 16) {
      dispatch(sendGetChallengeIntroduction());
    } else if (
      challengeState.challengeState === SeityChallengeStateIndex.NOT_ACTIVE ||
      challengeState.challengeState === SeityChallengeStateIndex.FUTURE
    ) {
      history.push("/challenge/notAvailable");
    } else if (
      challengeState.challengeState === SeityChallengeStateIndex.CONTINUE_CHALLENGE ||
      challengeState.challengeState === SeityChallengeStateIndex.BONUS
    ) {
      history.push("/challenge/content");
    }
  }, [challengeState]);

  const toChallengeIntro = () => {
    history.push("/challenge/content");
  };

  if (challengeIntroduction) {
    return (
      <div className="challenge-container">
        <h1>{challengeIntroduction?.header}</h1>
        <div className="challenge-upper-wrapper">
          <img className="challenge-image" alt="challenge poster" src={`${IMAGE_URL + challengeIntroduction?.pictureFileName}.png`} />
        </div>
        <div className="challenge-lower-wrapper">
          <div
            className="challenge-lower-wrapper-description"
            dangerouslySetInnerHTML={{
              __html: challengeIntroduction?.startIntroduction
            }}
          />
          <div className="challenge-lower-wrapper-button">
            <SeityButton className="challenge-lower-wrapper-button" label={strings.accept} onClick={toChallengeIntro} />
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default ChallengeAcceptance;
