import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { RootState } from "../../../app/rootReducer";
import { DOCUMENTS_URL, VIDEO_URL } from "../../../api/apiConstants";
import { SeityChallengeStateIndex } from "../../../api/challenge/challengeTypes";
import {
  resetChallengeValues,
  sendGetChallengeContent,
  sendGetChallengeState,
  sendSetNextChallengeStep,
  sendUpdateChallengeQuestionAnswer,
  sendUpdateChallengeRating
} from "../challengeSlice";
import { sendMessageCenterListRequest } from "../../account/accountSlice";

import SeityButton from "../../../_core/components/SeityButton";
import AssessmentQuestion from "../../../_core/components/SeityQuestion";
import SeityRatingBar, { SeityRatingValue } from "../../../_core/components/SeityRatingBar";
import SeityLoader from "../../../_core/components/SeityLoader";

import strings from "../../../_core/strings/strings";
import "../styles.scss";

export const Challenge = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, loadedChallengeContent, challengeState, currentObjectiveID, challengeContent, nextObjectiveID } = useSelector(
    (state: RootState) => {
      return state.challenge;
    }
  );

  const [selectedChallengeAnswerIndex, setSelectedChallengeAnswerIndex] = useState(-1);
  const [selectedRating, setSelectedRating] = useState<SeityRatingValue>(0);

  useEffect(() => {
    dispatch(resetChallengeValues({}));
    dispatch(sendGetChallengeState());
    dispatch(sendMessageCenterListRequest());
  }, []);

  // updates content after challenge state is updated
  useEffect(() => {
    if (challengeState === null) {
      return;
    }
    if (
      challengeState.challengeState === SeityChallengeStateIndex.NOT_ACTIVE ||
      challengeState.challengeState === SeityChallengeStateIndex.FUTURE
    ) {
      history.push("/challenge/complete");
    } else if (challengeState.challengeState === SeityChallengeStateIndex.CONTINUE_CHALLENGE && currentObjectiveID) {
      setSelectedRating(0);
      setSelectedChallengeAnswerIndex(-1);
      dispatch(sendGetChallengeContent(currentObjectiveID));
    }
  }, [challengeState]);

  // on completetion will get next step and updated challenge state
  useEffect(() => {
    dispatch(sendGetChallengeState());
  }, [nextObjectiveID]);

  // functions
  const onCompletionPressed = () => {
    if (
      challengeContent === null ||
      challengeContent.responseID === null ||
      challengeContent.answerList === null ||
      currentObjectiveID === null
    ) {
      // eslint-disable-next-line max-len
      console.error(
        `Unable to send answer missing data: ${selectedChallengeAnswerIndex} ${challengeContent?.responseID} ${JSON.stringify(
          challengeContent?.answerList
        )} ${JSON.stringify(challengeContent)}`
      );
      return;
    }

    //const selectedAnswer = challengeContent.answerList[selectedChallengeAnswerIndex - 1];
    dispatch(sendUpdateChallengeQuestionAnswer(challengeContent.responseID, selectedChallengeAnswerIndex));

    if (currentObjectiveID) {
      dispatch(sendUpdateChallengeRating(currentObjectiveID, selectedRating));
    }
    dispatch(sendSetNextChallengeStep(currentObjectiveID));
    setSelectedChallengeAnswerIndex(-1);
    setSelectedRating(0);
  };

  const onCompletionBonusPressed = () => {
    if (currentObjectiveID === null) {
      console.error("Missing currentObjectiveID to complete bonus step.");
      return;
    }

    dispatch(sendSetNextChallengeStep(currentObjectiveID));
  };

  const sendHome = () => {
    history.push("/");
  };

  const checkCompletionComplete = () => {
    if (selectedChallengeAnswerIndex === 0 || selectedChallengeAnswerIndex === -1 || selectedRating === 0) {
      return true;
    }
    return false;
  };

  const onQuestionAnswerChanged = (id: number, answerLevel: number) => {
    setSelectedChallengeAnswerIndex(answerLevel);
  };

  const onRatingValueChanged = (value: SeityRatingValue) => {
    setSelectedRating(value);
  };

  // initialize content elements
  let contentResponse;
  let videoFileName = "";
  let header = "";
  let subheader = "";
  let stepInformation = "";
  let description = "";
  let pdfElement: React.ReactNode | null = null;
  let completionComponent: React.ReactNode | null = null;

  // fill in content elements
  if (loadedChallengeContent.length > 0) {
    contentResponse = loadedChallengeContent[0].content;
    videoFileName = contentResponse.videoFileName;
    stepInformation = contentResponse.subHeader;
    header = contentResponse.header;
    subheader = contentResponse.header;

    // formats subheader
    if (subheader.includes("Challenge")) {
      subheader = subheader.replace("Challenge", "");
    } else {
      subheader += " ";
    }

    // builds description
    description = "";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    loadedChallengeContent.forEach((challengeContent) => {
      if (challengeContent.content && challengeContent.content.content) {
        if (description.length === 0) {
          description = challengeContent.content.content;
        } else {
          description += "\n";
          description += challengeContent.content.content;
        }
      }
    });

    // builds pdf component if challenge has one
    if (contentResponse.printPDFFileName) {
      const pdfLink = DOCUMENTS_URL + contentResponse.printPDFFileName;
      const checkPdfLink = () => {
        if (pdfLink !== null) {
          window.open(pdfLink, "_blank");
        }
      };
      pdfElement = (
        <SeityButton
          className="challenge-completion-button"
          onClick={checkPdfLink}
          type="cancel"
          label={contentResponse.printPDFButtonText}
        />
      );
    }

    // builds challenge completition component or displays
    // default component if not present
    if (contentResponse.waitComplete) {
      // handle content (not bonus)
      if (challengeContent && challengeContent.questionTypeID !== 0) {
        completionComponent = (
          <div className="challenge-completion">
            <hr />
            <AssessmentQuestion
              id={challengeContent.questionID}
              questionTypeId={challengeContent?.questionTypeID}
              questionText={challengeContent.questionText}
              answerLevel={selectedChallengeAnswerIndex}
              handleSelect={onQuestionAnswerChanged}
              needsToBeAnswered
              key={challengeContent.questionID.toString()}
              containerStyle={{ width: "100%", maxWidth: "none" }}
              answerSelections={challengeContent.answerSelections}
            />
            <hr></hr>
            <div className="challenge-completion-rating">
              {strings.challengeRatingHeader}
              <SeityRatingBar value={selectedRating} onValueChange={onRatingValueChanged} />
            </div>
            <hr />
            <SeityButton
              className="challenge-completion-button"
              label={strings.complete}
              disabled={checkCompletionComplete()}
              onClick={onCompletionPressed}
            />
          </div>
        );
      } else if (challengeContent?.questionID === 0) {
        // handle bonus content (no question or rating bar)
        completionComponent = (
          <div className="challenge-completion">
            <SeityButton className="challenge-completion-button" label={strings.next} onClick={onCompletionBonusPressed} />
          </div>
        );
      }
    } else {
      // handle content not available yet
      completionComponent = (
        <div>
          <div className="challenge-completion-wait">
            {`${strings.comeBackChallengeText1} 
            ${contentResponse.completionDate} 
            ${strings.comeBackChallengeText2}`}
          </div>
          <SeityButton className="challenge-lower-wrapper-button" label={strings.doneButtonTitle} onClick={sendHome} />
        </div>
      );
    }
  }

  if (loadedChallengeContent.length !== 0 && challengeContent && !isLoading) {
    return (
      <div className="challenge-container">
        <h1>{header}</h1>
        <div className="challenge-upper-wrapper">
          <video width="100%" controls muted controlsList="nodownload" disablePictureInPicture>
            <source src={VIDEO_URL + videoFileName} type="video/mp4" />
          </video>
        </div>
        <div className="challenge-lower-wrapper">
          {stepInformation !== "" && <h5>{stepInformation}</h5>}
          <div className="challenge-lower-wrapper-description">{description}</div>
          {pdfElement}
          {completionComponent}
        </div>
      </div>
    );
  }
  return <SeityLoader hideBackgroundContent />;
};
