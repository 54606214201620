import { Journal, JournalEmotion, JournalEntry, Weather } from "../../../api/individualJournal/journalTypes";
import { AttachImageType } from "./types";

export const updateWithEmotionSelection = () => {};

export const makeJournalEntryObj = (
  title: string,
  favorite: boolean,
  content: string,
  coreVals: Array<any>,
  emotions: Array<JournalEmotion>,
  images: Array<AttachImageType>,
  weatherData: Weather | null
): JournalEntry => {
  const ret: JournalEntry = {
    objectiveID: 0,
    title: title,
    favorite: favorite,
    weatherTemperature: weatherData ? weatherData.temperature.toString() : "",
    weatherTypeID: weatherData ? weatherData.weatherTypeId : 0,
    airQualityIndex: weatherData ? weatherData.airQualityIndex.toString() : "",
    entryText: content,
    coreValueID1: 0,
    coreValueSelected1: false,
    coreValueID2: 0,
    coreValueSelected2: false,
    coreValueID3: 0,
    coreValueSelected3: false,
    coreValueID4: 0,
    coreValueSelected4: false,
    coreValueName1: "",
    coreValueName2: "",
    coreValueName3: "",
    coreValueName4: "",
    individualJournalEmotions: emotions,
    images: []
  };

  if (coreVals.length === 4) {
    ret.coreValueID1 = coreVals[0].id;
    ret.coreValueSelected1 = coreVals[0].selected;
    ret.coreValueName1 = coreVals[0].name;

    ret.coreValueID2 = coreVals[1].id;
    ret.coreValueSelected2 = coreVals[1].selected;
    ret.coreValueName2 = coreVals[1].name;

    ret.coreValueID3 = coreVals[2].id;
    ret.coreValueSelected3 = coreVals[2].selected;
    ret.coreValueName3 = coreVals[2].name;

    ret.coreValueID4 = coreVals[3].id;
    ret.coreValueSelected4 = coreVals[3].selected;
    ret.coreValueName4 = coreVals[3].name;
  }

  ret.images = images;

  return ret;
};

export const makeJournalObj = (
  title: string,
  favorite: boolean,
  content: string,
  coreVals: Array<any>,
  emotions: Array<JournalEmotion>,
  images: Array<AttachImageType>,
  journal: Journal,
  weatherData: Weather | null
): Journal => {
  // `images` copy doesn't work for some reason. So use Object.assign for cloning journal object
  const ret = Object.assign({}, journal);

  ret.title = title;
  ret.favorite = favorite;
  ret.entryText = content;
  ret.individualJournalEmotions = emotions;
  ret.images = images;
  ret.individualJournalImages = images;
  ret.weatherTemperature = weatherData ? weatherData.temperature.toString() : "";
  ret.weatherTypeID = weatherData ? weatherData.weatherTypeId : 0;
  ret.airQualityIndex = weatherData ? weatherData.airQualityIndex.toString() : "";

  if (coreVals.length === 4) {
    ret.coreValueID1 = coreVals[0].id;
    ret.coreValueSelected1 = coreVals[0].selected;
    ret.coreValueName1 = coreVals[0].name;

    ret.coreValueID2 = coreVals[1].id;
    ret.coreValueSelected2 = coreVals[1].selected;
    ret.coreValueName2 = coreVals[1].name;

    ret.coreValueID3 = coreVals[2].id;
    ret.coreValueSelected3 = coreVals[2].selected;
    ret.coreValueName3 = coreVals[2].name;

    ret.coreValueID4 = coreVals[3].id;
    ret.coreValueSelected4 = coreVals[3].selected;
    ret.coreValueName4 = coreVals[3].name;
  }

  return ret;
};

export const makeEmotionsObj = (emotions: Array<JournalEmotion>, added: Array<JournalEmotion>) => {
  emotions.forEach((e) => {
    const addE = added.find((a) => a.emotionID === e.emotionID);
    const temp = { ...e };
    temp.selected = addE ? addE.selected : false;
    e = temp;
  });

  return emotions;
};

export const coreValueImageName = (str: string) => str.toLocaleLowerCase().replace(/\s/g, "").replace(/-/g, "");
