import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ASSET1_IMG_URL } from "../../../../../api/apiConstants";

import "./styles.scss";
import { useDispatch } from "react-redux";
import { sendGetTeachSeityAssetDetail } from "../../guideSlice";
import SeityLoader from "../../../../../_core/components/SeityLoader";
import { SeityScienceAsset } from "../../../../../api/referenceGuide/referenceGuideTypes";
import { useHistory, useLocation } from "react-router-dom";
import SeityButton from "../../../../../_core/components/SeityButton";
import strings from "../../../../../_core/strings/strings";

type paramTypes = {
  assetID: string;
};

export const SCAssetDetail: FunctionComponent = () => {
  const { assetID } = useParams<paramTypes>();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const showPdfView = queryParam.get("pdfView");
  const isAssetView = location.pathname.includes("asset");

  const dispatch = useDispatch();
  const history = useHistory();

  const [assetData, setAssetData] = useState<SeityScienceAsset>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(
      sendGetTeachSeityAssetDetail(parseInt(assetID), (data) => {
        setAssetData(data);
        setIsLoading(false);
      })
    );
  }, [assetID]);

  if (isLoading) {
    return <SeityLoader />;
  }

  return (
    <div className="sc-detail seity-main-fullwidth">
      {showPdfView ? (
        <div className="sc-detail__pdfview flex flex-column">
          <span>{assetData?.creationDate}</span>
          <h1>{assetData?.name}</h1>
          {!isAssetView && <h5>{assetData?.authors}</h5>}
          <SeityButton
            label={strings.viewPdf}
            onClick={() => {
              window.open(assetData?.url, "_blank");
            }}
            type="next"
            className="mt-xl"
          />
          <h3 className="mt-xl">{strings.summary}</h3>
          <div>{assetData?.description}</div>
        </div>
      ) : (
        <>
          <h1>{assetData?.name}</h1>
          <div className="sc-detail__content flex flex-column">
            {assetData?.childrenAssets?.map((asset, index) => {
              return (
                <div
                  className="sc-detail__item flex"
                  key={index}
                  onClick={() => {
                    setIsLoading(true);
                    history.push(isAssetView ? `/asset/${asset.assetID}?pdfView=true` : `/learn/science/${asset.assetID}?pdfView=true`);
                  }}
                >
                  <div className="flex flex-column mr-auto">
                    <h3>{asset.name}</h3>
                    <span className="mb-sm">{asset.creationDate}</span>
                    {!isAssetView && <h5>{asset.authors}</h5>}
                  </div>
                  <img className="ml-xxl" src={`${ASSET1_IMG_URL + asset?.coverImageName}`} alt="TeachSeity Cover Image" />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
