import React, { MouseEventHandler } from "react";

import Modal from "react-modal";
import SeityButton from "../../../../../_core/components/SeityButton";

import noAccessModalImg from "../../../../../assets/graphics/noAccessModal.svg";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";

interface Props {
  isOpen: boolean;
  onClose: MouseEventHandler;
}

export const NoAccessModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} className="nam-cream" overlayClassName="nam-overlay-dark">
      <div className="nam-wrapper">
        <div className="nam-wrapper-img">
          <img src={noAccessModalImg} alt="no access" style={{ display: window.innerHeight < 600 ? "none" : "flex" }} />
        </div>
        <h2>{strings.noAccessModalHeader}</h2>
        <div className="nam-content">
          <div className="nam-content-text">
            <h4>{strings.noAccessModalDescription}</h4>
            <SeityButton onClick={onClose} label={strings.goBackBtnText} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NoAccessModal;
