import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";

import SeityModal from "../../_core/components/SeityModal";
import SeityButton from "../../_core/components/SeityButton";
import SeityLoader from "../../_core/components/SeityLoader";

import { getCharacterizationRequest } from "../myStory/myStorySlice";
import { sendGetSeityCategoriesRequest } from "./learn/learnSlice";
import { getStoryListRequest } from "./StoriesSyncs/storyListSlice";
import { getSyncListRequest } from "./StoriesSyncs/syncListSlice";
import { ExploreItem } from "./ExploreItem";
import { ExploreItemsList } from "./data";

import strings from "../../_core/strings/strings";
import "./styles.scss";
import { getAppContentByKeysRequest } from "../policy/appContentSlice";

export const Grow: FunctionComponent = (): ReactElement => {
  const [showModal, setShowModal] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const { storyList, isLoading: isLoadingSync } = useSelector((state: RootState) => {
    return state.storyList;
  });
  const { syncList, isLoading: isLoadingStories } = useSelector((state: RootState) => {
    return state.syncList;
  });

  const { characterization, isLoading: isLoadingCharacterization } = useSelector((state: RootState) => {
    return state.characterization;
  });

  const isLoading = isLoadingSync || isLoadingStories || isLoadingCharacterization;

  useEffect(() => {
    if (!characterization?.information) {
      dispatch(getCharacterizationRequest());
    }
  }, [characterization?.information, dispatch]);

  const exploreItems = ExploreItemsList(characterization);

  useEffect(() => {
    const contentKeys = exploreItems.map((e) => "contentKeys=" + e.contentKey);
    dispatch(sendGetSeityCategoriesRequest());
    dispatch(getAppContentByKeysRequest(contentKeys));
    dispatch(getStoryListRequest());
    dispatch(getSyncListRequest());
  }, [dispatch]);

  if (!isLoading) {
    return (
      <div className="sj-container">
        <div className="sj-wrapper-grow">
          <h1>{strings.growPageTitle}</h1>
          {exploreItems.map(({ visible, image, heading, description, className, navigationPath, contentKey }, i) => {
            return (
              <ExploreItem
                visible={visible}
                image={image}
                heading={heading}
                description={description}
                className={className}
                contentKey={contentKey}
                onClick={() => {
                  if (heading === strings.Stories && storyList.length === 0) {
                    setShowModal(strings.Stories);
                  } else if (heading === strings.Syncs && syncList.length === 0) setShowModal(strings.Syncs);
                  else history.push(navigationPath);
                }}
                key={i}
              />
            );
          })}

          <SeityModal isOpen={!!showModal} wrapperClassName="seity-syncs-story-modal-wrapper">
            <div className="seity-syncs-story-modal">
              <img src={require("../../assets/graphics/error_content.png").default} alt="header icon" />
              <h1>{strings.syncAndStoryErrorHeader}</h1>
              <h3>{strings.formatString(strings.syncAndStoryErrorSummaryP1, showModal)} </h3>
              <h3>{strings.syncAndStoryErrorSummaryP2}</h3>
              <h4>{strings.syncAndStoryErrorCheckBack}</h4>
              <SeityButton
                className="seity-syncs-story-button"
                label={strings.okay}
                onClick={() => {
                  return setShowModal("");
                }}
              />
            </div>
          </SeityModal>
        </div>
      </div>
    );
  }
  return <SeityLoader showBackgroundMask />;
};

export default Grow;
