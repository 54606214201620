/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { SeityAppContext } from "../../../app/SeityAppContext";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";

import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";

export const RegistrationWrapper = ({ children }) => {
  const appContext = useContext(SeityAppContext);
  const { isLoading } = useSelector((state: RootState) => {
    return state.register;
  });

  useEffect(() => {
    if (appContext) {
      appContext.setShowTodo(false);
      appContext.setShowNavMenuItems(false);
    }

    return () => {
      // Reset SeityAppContext
      if (appContext) {
        appContext.setShowTodo(true);
        appContext.setNavigationMenuButtonText("");
        appContext.setShowNavMenuItems(true);
      }
    };
  }, []);

  return (
    <div className="registration-wrapper">
      {isLoading && <SeityLoader />}
      <SeityBackButton />
      {children}
    </div>
  );
};

export default RegistrationWrapper;

RegistrationWrapper.propTypes = {
  children: PropTypes.element,
  stepNum: PropTypes.number
};
