import LocalizedStrings from "react-localization";
import en from "../languages/en.json";
import es from "../languages/es.json";

export const CoreStrings = new LocalizedStrings({
  "en-US": en,
  "es": es
});

export default CoreStrings;
