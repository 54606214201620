import { FunctionComponent, ReactElement } from "react";

import "./styles.scss";
import SeityAvatar from "../../../_core/components/SeityAvatar";
import SeityCVImage from "../../../_core/components/SeityCVImage";
import { FSInviteStatus } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";

export type FSProfileCardProps = {
  firstName: string;
  lastName: string;
  profileImg: string;
  coreValues: any[] | null; // Todo: update with the type from api
  inviteStatus?: FSInviteStatus;
  onClickProfile: () => void;
};

const FSProfileCard: FunctionComponent<FSProfileCardProps> = ({
  firstName,
  lastName,
  profileImg,
  coreValues,
  inviteStatus,
  onClickProfile
}): ReactElement => {
  const { fsStatusList } = useSelector((state: RootState) => state.familySharingSlice);

  const fourCVstring = () => {
    return coreValues?.map((cv, index) => cv?.coreValue + (index < 3 ? " | " : ""));
  };

  const user = {
    firstName,
    lastName,
    profilePicture: profileImg
  };

  const getInviteStatus = () => {
    if (inviteStatus === FSInviteStatus.Accept) {
      return "";
    }
    return fsStatusList.find(s => s.familySharingStatusID === inviteStatus)?.description;
  };

  const status = getInviteStatus();

  return (
    <div className="fsProfileCard" onClick={onClickProfile}>
      <SeityAvatar size={64} content={user} />
      <div className="user-details">
        <p className="user-name">
          {firstName} <b>{lastName}</b>
        </p>
        {inviteStatus && (inviteStatus !== FSInviteStatus.Accept) ? (
          <span className={`inviteStatus ${status?.replace(/\s/g,'')}`}>{status}</span>
        ) : (
          <>
            <div className="user-cv-images">
              {coreValues?.map((cv, index) => (
                <SeityCVImage key={index} keyId={`${cv?.coreValueID}-${index}`} cvId={cv?.coreValueID} size={16} />
              ))}
            </div>
            <p className="user-cv-names">{fourCVstring()}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default FSProfileCard;
