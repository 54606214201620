import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutcomeScores } from "../../api/account/types/accountTypes";
import { getOutcomeScores } from "../../api/account/seityHealthAPI-account";
import { AppThunk } from "../../app/store";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../api/authTypes";

export interface OutcomeScoresState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly outcomeScores: OutcomeScores;
}

const initOutcomeScores = () => {
  return {
    energy: 0,
    energyCategory: "",
    energyLogoURL: "",
    energyText: "",
    direction: 0,
    directionCategory: "",
    directionLogoURL: "",
    directionText: "",
    belonging: 0,
    belongingCategory: "",
    belongingLogoURL: "",
    belongingText: "",
    joy: 0,
    joyCategory: "",
    joyLogoURL: "",
    joyText: "",
    wholeHealth: 0,
    wholeHealthCategory: "",
    wholeHealthLogoURL: "",
    wholeHealthText: ""
  };
};

const setInitalState = {
  error: null,
  isLoading: false,
  outcomeScores: initOutcomeScores()
} as OutcomeScoresState;

const outcomesSlice = createSlice({
  name: "outcomes-outcomes",
  initialState: setInitalState,
  reducers: {
    setOutcomeScores(state, action: PayloadAction<OutcomeScores>) {
      state.outcomeScores = action.payload;
    },
    setOutcomeScoresError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    resetOutcomeSlice: () => setInitalState
  }
});

export const { setOutcomeScores, setOutcomeScoresError, clearApiError, setIsLoading, resetOutcomeSlice } = outcomesSlice.actions;
export default outcomesSlice.reducer;

export const getOutcomeScoresRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const outcomeScoresResponse = await getOutcomeScores(token);
    if (outcomeScoresResponse.success) {
      const data: any = outcomeScoresResponse.data;
      dispatch(setOutcomeScores(data));
    }
  } catch (err: any) {
    console.error(err);
    dispatch(setOutcomeScoresError({ error: err.toString() }));
  }
};
