import { SeityResponse } from "./baseTypes";

export type PlotlineWellbeingImagesData = {
  imageOff01: string | null;
  imageOff02: string | null;
  imageOff03: string | null;
  imageOff04: string | null;
  imageOff05: string | null;
  imageOn01: string | null;
  imageOn02: string | null;
  imageOn03: string | null;
  imageOn04: string | null;
  imageOn05: string | null;
};

export type PlotlineModalData = {
  triggerImage: string | null;
  imageVertical: string | null;
  imageHorizontal: string | null;
  triggerText: string | null;
  title: string | null;
  text: string | null;
  screenButtonText: string | null;
};

/*
PlotlineTemplateID    Name
1    Image Modal 01
2    Multi-content Half Page Image 01
3    Multi-content Half Page Video 01
4    Full Page Content Bottom Aligned Image 01
5    Full Page Content Bottom Aligned Video 01
6    Questionnaire 01
7    Circular Graph 01
8    Full Page Content Top Aligned Image 01
9    Full Page Content Top Aligned Video 01
10   Wellbeing Check In 01
*/

export enum PlotlineTemplateID {
  ImageModal = 1,
  MultiConetntHalfPageImage01,
  MultiContentHalfPageVideo01,
  FullPageContentBottomAlignedImage01,
  FullPageContentBottomAlignedVideo01,
  Questionnaire01,
  CircularGraph01,
  FullPageContentTopAlignedImage01,
  FullPageContentTopAlignedVideo01,
  WellbeingCheckIn01
}

export enum PlotlineGoToActionID {
  InitialLanding = 0,
  CVOnlyAssessment,
  DailyAffirmations,
  DailyIntentions,
  TodayPage,
  Wellbeing,
  CoreValuesHome,
  CVDirectory
}

export enum PlotlineInjectionPointID {
  AfterLogin = 1,
  // After pressing pin button on ShowAffirmation or before landing on ChooseIntention screen
  OnDailyAffirmation = 3,
  // After commit intention on IntentionSelected
  OnDailyIntention = 4,
  // Wellbeing
  Wellbeing = 5,
  CoreValues = 7,
  CVDirectory = 8,
  WellbeingHeaderIcon = 9
}

export type PlotlineAnswerData = {
  text: string;
  answerID: number;
  image: string;
};

export type PlotlineQuestionData = {
  questionID: number;
  questionText: string;
  answers: Array<PlotlineAnswerData>;
  isQuestionRequired: boolean | null;
  isQuestionMultiselect: boolean | null;
};

export type PlotlineTemplateData = {
  plotlineID: number;
  plotlineStepID: number | null;
  plotlineTemplateID: PlotlineTemplateID | null;
  webImageVertical: string | null;
  webImageHorizontal: string | null;
  mobileImageHorizontal: string | null;
  mobileImageVertical: string | null;
  icon: string | null;
  videoVertical: string | null;
  videoHorizontal: string | null;
  progressBarActive: boolean | null;
  progressBarPercentage: number;
  question: PlotlineQuestionData | null;
  plotlineDynamicChartTypeId: number | null;
  dynamicChartPercentage: number | null;
  outcomeCategoryID: number | null;
  selectionImages: PlotlineWellbeingImagesData | null;
  modal: PlotlineModalData | null;
  title: string | null;
  title2: string | null;
  subtitle: string | null;
  text: string | null;
  buttonText: string;
};

export type PlotlineInjectionData = {
  plotlineID: number;
  completed: boolean;
  plotlineScheduleID: number;
  description: string;
  plotlineGoToActionID: PlotlineGoToActionID;
  plotlineGoToActionDescription: string;
  steps: Array<PlotlineTemplateData>;
};

export type PlotlineInjectionReponse = SeityResponse & {
  data: PlotlineInjectionData;
};

export type PlotlineInjectionInfo = {
  plotlineInjectionID: number;
  completed: boolean;
};

export type PlotlineInjectionsResponse = SeityResponse & {
  data: Array<PlotlineInjectionInfo>;
};
