export const getColorData = (categoryId: number) => {
  switch (categoryId) {
    case 1:
      return { bg: "#2f696c", text: "#fff", border: "#9bc6c7" };
    case 2:
      return { bg: "#539fa3", text: "#fff", border: "#2f696c" };
    case 3:
      return { bg: "#9bc6c7", text: "#343b43", border: "#2f696c" };
    case 4:
      return { bg: "#bbdadb", text: "#343b43", border: "#539fa3" };
    default:
      return { bg: "#2f696c", text: "#fff" };
  }
};

export const getDragonImg = (dragonType: string) => {
  if (dragonType === "dragonraging") {
    return require("../../../assets/graphics/dragonraging01.png").default;
  }
  if (dragonType === "dragontame") {
    return require("../../../assets/graphics/dragontame01.png").default;
  }
  if (dragonType === "dragonawake") {
    return require("../../../assets/graphics/dragonawake01.png").default;
  }
  return require("../../../assets/graphics/spirit.png").default;
};
