/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { RootState } from "../../../../app/rootReducer";
import { sendValidateRegistrationRequest, setCurrentInfo, setRegisterError } from "../../registerSlice";

import RegisterWrapper from "../../RegisterWrapper";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";
import { SeityDatePickerDropdown } from "../../../../_core/components/SeityDatePickerDropdown";
import { SeityFormTextInput } from "../../../../_core/components/SeityTextInput/SeityFormTextInput";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";
import SeityButton from "../../../../_core/components/SeityButton";

import strings from "../../../../_core/strings/strings";
import "./style.scss";

const DEFAULT_DOB = "01/01/2000";
const DATE_FORMAT = "MM/DD/YYYY";

export const RegisterOne = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { registerError } = useSelector((state: RootState) => {
    return state.register;
  });

  const [invitationCode, setInvitationCode] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date(DEFAULT_DOB));

  const nextPressed = () => {
    const date = new Date(dateOfBirth);
    if (!isMinimumAge(date)) {
      return;
    }
    dispatch(
      sendValidateRegistrationRequest(invitationCode, date?.toLocaleDateString("en-US"), "", "", () => {
        return navigateToRegisterTwo();
      })
    );
  };

  const navigateToRegisterTwo = () => {
    dispatch(
      setCurrentInfo({
        dateOfBirth: moment(dateOfBirth).format(DATE_FORMAT)
      })
    );
    history.push("/register/registerTwo");
  };

  function validateInvitationCode(invitationCode: string) {
    if (invitationCode.length > 0) {
      setInvitationCode(invitationCode);
      dispatch(setRegisterError({ registerError: "" }));
    } else {
      dispatch(setRegisterError({ registerError: strings.invitationCodeMissing }));
      setInvitationCode("");
    }
  }

  function isMinimumAge(dateOfBirth: Date): boolean {
    const age = Math.floor(moment(new Date()).diff(moment(dateOfBirth, DATE_FORMAT), "years", true));
    if (age < 13) {
      console.log("User is not minimum age");
      dispatch(setRegisterError({ registerError: strings.minimumAge }));
      return false;
    }
    setDateOfBirth(dateOfBirth);
    return true;
  }

  const openTerms = () => {
    window.open("https://www.seityhealth.com/privacy-policy", "_blank");
  };

  const nextDisabled = useMemo(() => {
    return invitationCode.length === 0 || !dateOfBirth;
  }, [dateOfBirth, invitationCode.length]);

  return (
    <RegisterWrapper>
      <div className="register-container">
        <div>
          <h1>{strings.heading}</h1>
          <SeityIndicator currentStep={1} numberOfSteps={4} />
          <h5>{strings.stepOneSubHeading}</h5>
          <form className="textbox-form">
            <SeityFormTextInput
              inputLabelText={strings.invitationCodeLabel}
              testID="invitation-code"
              type="text"
              onChange={(e) => {
                return validateInvitationCode(e.target.value);
              }}
              value={invitationCode}
              moreInfoText={strings.groupActivationMessage}
            />
            <div className="register-dropdown-wrapper">
              <label>{strings.dateOfBirthLabel}</label>
              <SeityDatePickerDropdown retriveDate={setDateOfBirth} date={dateOfBirth} />
            </div>
          </form>
          <div className="register-container-error">{registerError !== null ? registerError : ""}</div>
        </div>
        <div className="register-container-bottom">
          <SeityHyperText title={strings.terms} onClick={openTerms} />
          <SeityButton
            onClick={nextPressed}
            type="next"
            disabled={nextDisabled}
            label={strings.nextButtonText}
            className="register-next-button"
            testId="next-button"
          />
        </div>
      </div>
    </RegisterWrapper>
  );
};

export default RegisterOne;
