/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from "react";

import { VIDEO_URL, IMAGE_URL } from "../../../api/apiConstants";

import "./styles.scss";

export interface SeityVideoPlayerProps {
  pictureFile: string;
  videoFile: string;
}

export const SeityVideoPlayer = (props: SeityVideoPlayerProps) => {
  const { pictureFile, videoFile } = props;

  const [playVideo, setPlayVideo] = useState(false);

  return (
    <div className="svp-container">
      {!playVideo && (
        <div
          className="svp-picture-wrapper"
          onClick={() => {
            return setPlayVideo(!playVideo);
          }}
        >
          <img className="svp-picture-wrapper-background" src={`${IMAGE_URL + pictureFile}.png`} alt="background" />
          <img
            className="svp-picture-wrapper-play-button"
            src={require("../../../assets/graphics/seity-play-button.png").default}
            alt="play button"
          />
        </div>
      )}
      {playVideo && (
        <div className="svp-video-wrapper">
          <video width="100%" autoPlay controls controlsList="nodownload" disablePictureInPicture>
            <source src={VIDEO_URL + videoFile} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  );
};
