import { FunctionComponent } from "react";
import ModernDatepicker from "react-modern-datepicker";

import { SeityDatePickerDropdownProps } from "./types";
import moment from "moment";

import "./styles.scss";
const DEFAULT_DOB = "01/01/2000";

export const SeityDatePickerDropdown: FunctionComponent<SeityDatePickerDropdownProps> = (props) => {

  const { date = new Date(DEFAULT_DOB), retriveDate, dateFormat = "MM/DD/YYYY" , className = "", onBlur, placeholder = null} = props;

  const handleChange = (date) => {
    retriveDate(date);
  };

  return (
    <ModernDatepicker
      date={date}
      format={dateFormat}
      showBorder
      onChange={(date) => handleChange(date)}
      placeholder={placeholder ?? moment(date).format(dateFormat)}
      allowEdit
      primaryColor={"#56a3a6"}
      className={`seity-input seity-date-picker ${className}`}
      onBlur={onBlur}
    />
  );
};
