/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/button-has-type */
import { FunctionComponent, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import autosize from "autosize";

import { JournalEmotion } from "../../../api/individualJournal/journalTypes";
import { RootState } from "../../../app/rootReducer";
import SeityButton from "../../../_core/components/SeityButton";
import SeityLoader from "../../../_core/components/SeityLoader";
import { getBase64FromFile } from "../../../_core/utils/fileUtils/byteArrayProcessing";

import { sendCheckInDueRequest, sendIncentiveInfoRequest } from "../../wellbeingCheckIn/checkInSlice";
import { sendMessageCenterListRequest } from "../../account/accountSlice";

import CheckIn from "../../wellbeingCheckIn/CheckIn/CheckinMain";
import { makeUpCoreValueData } from "../Journals/helpers";
import { getJournalEmotionsRequest, getWeatherRequest, postIndividualJournalRequest, putIndividualJournalRequest } from "../journalsSlice";
import EmotionButton from "./EmotionButton";
import { makeEmotionsObj, makeJournalEntryObj, makeJournalObj } from "./helpers";

import { AttachImageType, JournalEntryProps } from "./types";

import SeityIdkModal from "../../../_core/components/SeityIdkModal";

import "./styles.scss";
import LimitTextArea from "./LimitTextArea";
import SeityCVSelection, { CoreValueType } from "../../../_core/components/SeityCVSelection";
import Incentives from "../../wellbeingCheckIn/Incentives";
import SeityEmotionsPopup from "../../../_core/components/SeityEmotionsPopup";

import CoreStrings from "../../../_core/strings/strings";
import { getSortedFourCoreValuesRequest } from "../../coreValues/coreValuesSlice";

const MAX_BODY_LENGTH = 8000;
const MAX_TITLE_LENGTH = 60;

const JournalEntry: FunctionComponent<JournalEntryProps> = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const titleInputRef = useRef<any>();

  const { isLoading, journalList, weatherData } = useSelector((state: RootState) => {
    return state.journals;
  });

  const { incentiveResponse, dueResponse } = useSelector((state: RootState) => {
    return state.checkIn;
  });
  const { sortedFourCoreValues } = useSelector((state: RootState) => {
    return state.fourCoreValues;
  });

  const [dateObj, setDateObj] = useState<{ date: string; time: string }>();
  const [isLiked, setLiked] = useState(false);
  const [showEmotionPopup, setShowEmotionPopup] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showIdkModal, setShowIdkModal] = useState(false);
  const [showIncentive, setShowIncentive] = useState(false);

  const [attachImg, setAttachImg] = useState<AttachImageType | null>(null);
  const [coreVals, setCoreVals] = useState<Array<CoreValueType>>([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [emotionData, setEmotionData] = useState<{
    emotions: Array<JournalEmotion>;
    added: Array<JournalEmotion>;
  }>({
    emotions: [],
    added: []
  });

  // Check Journal Id from search query to determine where it is create or edit
  const id = Number(new URLSearchParams(location.search).get("id"));
  const isReadOnly = Boolean(new URLSearchParams(location.search).get("readOnly"));
  const journalData = journalList.find((j) => {
    return j.individualJournalID === id;
  });

  useEffect(() => {
    autosize(titleInputRef.current);
    dispatch(sendCheckInDueRequest());
    dispatch(getSortedFourCoreValuesRequest());
    if (id) {
      if (journalData === undefined || !journalData) {
        // Journal doesn't exist in the reducer, need to back to Journals page!
        history.push("/journals");
        return;
      }

      setTitle(journalData!.title);
      setContent(journalData!.entryText);
      setLiked(journalData!.favorite);

      if (journalData!.individualJournalImages?.length > 0) {
        const image = journalData!.individualJournalImages[0];
        setAttachImg(image);
      }
      setDateObj({
        date: moment.utc(journalData!.createDate, "MM-DD-Y").format("MMMM DD, Y"),
        time: moment.utc(journalData!.createTime, "H:mm").format("h:mm A")
      });

      const temp = [...journalData?.individualJournalEmotions];
      const added = temp.filter((t) => {
        return t.selected;
      });
      setEmotionData({ emotions: temp, added: added ?? [] });
      const tempCoreVals = [...makeUpCoreValueData(journalData)];
      setCoreVals(tempCoreVals);
    } else {
      const today = new Date();
      setDateObj({
        date: moment(today).format("MMMM DD, Y"),
        time: moment(today).format("h:mm A")
      });

      dispatch(
        getJournalEmotionsRequest((data) => {
          setEmotionData({ emotions: data, added: [] });
        })
      );
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(getWeatherRequest(position.coords.latitude, position.coords.longitude));
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (sortedFourCoreValues.length === 4 && !id) {
      const vals = sortedFourCoreValues.map((c) => {
        return {
          id: c.coreValueID,
          name: c.coreValue,
          selected: false
        };
      });
      setCoreVals(vals);
    }

    if (!dueResponse?.due && !incentiveResponse) {
      dispatch(sendIncentiveInfoRequest());
    }
  }, [dueResponse, id, sortedFourCoreValues]);

  const handleSave = () => {
    if (isReadOnly) {
      history.push({
        pathname: "/journals/entry",
        search: `?id=${id}`
      });
      return;
    }
    const emotions = makeEmotionsObj(emotionData.emotions, emotionData.added);
    if (id) {
      const param = makeJournalObj(title, isLiked, content, coreVals, emotions, attachImg ? [attachImg] : [], journalData!, weatherData);

      dispatch(
        putIndividualJournalRequest(param, () => {
          history.push("/journals");
        })
      );
    } else {
      const param = makeJournalEntryObj(title, isLiked, content, coreVals, emotions, attachImg ? [attachImg] : [], weatherData);

      dispatch(
        postIndividualJournalRequest(param, () => {
          history.push("/journals");
        })
      );
    }
  };

  const renderCoreVals = useMemo(() => {
    return (
      <SeityCVSelection
        coreVals={coreVals}
        isReadOnly={false}
        onChange={(vals) => {
          setCoreVals(vals);
        }}
      />
    );
  }, [coreVals, isReadOnly]);

  const isValidContent = title.replace(/\s/g, "").length > 0 && content.replace(/\s/g, "").length > 0;

  return (
    <div className="journal-entry flex-col mt-xl">
      <div className="journal-entry__individual">
        <img src={require("../../../assets/graphics/journals/group.png").default} alt="Individual" />
        <span className="ml-sm">Individual</span>
      </div>
      <div className="flex-row flex-ai-fs mtb-lg">
        <div className="flex-col w-100 mr-xxl">
          <LimitTextArea
            maxLength={MAX_TITLE_LENGTH}
            needAutoSize
            disabled={isReadOnly}
            placeholder={CoreStrings.addJournalTitle}
            className="journal-entry__titleInput mr-auto"
            value={title}
            rows={1}
            onChange={(val) => {
              setTitle(val);
            }}
          />
        </div>
        <SeityButton
          className="rounded-lg"
          label={isReadOnly ? CoreStrings.edit : CoreStrings.doneButtonTitle}
          onClick={handleSave}
          disabled={!isValidContent}
        />
      </div>
      <div className={`journal-entry__toolbar flex-row flex-ai-c mt-lg ${isReadOnly ? "" : "mb-xxl"} pb-xl`}>
        {dateObj && (
          <p className="mr-auto">
            {dateObj.date} <span>{dateObj.time}</span>
          </p>
        )}
        <div className="journal-entry__center flex-ai-c">
          {weatherData ? (
            <span className="flex-ai-c plr-xl">{`${weatherData.temperature}°F`}</span>
          ) : (
            <img className="flex-ai-c plr-xl" src={require("../../../assets/graphics/journals/4dots.png").default} alt="button" />
          )}
          {dueResponse?.due ? (
            <button
              className={`journal-entry__outcomesBtn flex-ai-c plr-xl ${isReadOnly ? "disabled" : ""}`}
              disabled={isReadOnly}
              onClick={() => {
                setShowCheckIn(true);
              }}
            >
              <img src={require("../../../assets/graphics/journals/outcomes.png").default} alt="Check in button" />
              <span className="ml-md">{CoreStrings.checkInNow}</span>
            </button>
          ) : (
            <span className="journal-entry__outcomesBtn flex-ai-c plr-xl">
              <img className="mr-md" src={require("../../../assets/graphics/journals/outcomes.png").default} alt="Check in button" />
              {incentiveResponse && `${incentiveResponse?.checkInScore}%`}
            </span>
          )}
          <div className={`ml-xl journal-entry__emotionBtnWrap  ${isReadOnly ? "disabled" : ""}`}>
            <EmotionButton
              emotions={emotionData.added}
              handleButtonClick={() => {
                setShowEmotionPopup(!isReadOnly && !showEmotionPopup);
              }}
            />
          </div>
        </div>
        <div className={`ml-auto journal-entry__actionBtnWrap ${isReadOnly ? "disabled" : ""}`}>
          <input
            disabled={isReadOnly}
            type="file"
            id="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                const img = event.target.files[0];
                getBase64FromFile(img)
                  .then((result) => {
                    setAttachImg({
                      individualJournalContentID: 0, // Put 0 is considered as a new image in the backend
                      imageName: img.name,
                      base64String: result as string
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          />
          <label htmlFor="file" />
          <button
            disabled={isReadOnly}
            className="journal-entry__favBtn ml-md"
            onClick={() => {
              setLiked(!isLiked);
            }}
          >
            {/* eslint-disable-next-line max-len */}
            <img
              src={
                isLiked
                  ? require("../../../assets/graphics/journals/favorite-sel.png").default
                  : require("../../../assets/graphics/journals/favorite.png").default
              }
              alt="favourite button"
              width={56}
            />
          </button>
        </div>
        {/* <div className="journal-entry__sep mb-xxl" /> */}
      </div>
      {isReadOnly && renderCoreVals}
      {attachImg && (
        <div>
          <img className="journal-entry__attach" src={`data:image/png;base64,${attachImg.base64String}`} alt="attach" />
          {!isReadOnly && (
            <button
              className="btn-delete h-100"
              onClick={() => {
                setAttachImg(null);
              }}
            >
              <img
                src={require("../../../assets/graphics/intentions/intentions-delete.png").default}
                alt="delete button"
                width={32}
                height={32}
                color="red"
              />
            </button>
          )}
        </div>
      )}
      <div className="body-text-wrap h-100 w-100 mtb-xxl">
        <LimitTextArea
          maxLength={MAX_BODY_LENGTH}
          disabled={isReadOnly}
          className="body-text w-100 h-100"
          value={content}
          onChange={(val) => {
            setContent(val);
          }}
        />
      </div>
      {!isReadOnly && (
        <div className="journal-entry__footer flex-col-center">
          <p>{coreVals.length > 0 ? CoreStrings.cvSelectionModalTitle : CoreStrings.coreValueConnect}</p>
          {coreVals.length > 0 ? (
            renderCoreVals
          ) : (
            <img src={require("../../../assets/graphics/journals/no-coreval-img.png").default} alt="attach" />
          )}
          <button
            className="intention-ancho btn-idk"
            onClick={() => {
              setShowIdkModal(true);
            }}
          >
            {CoreStrings.idk}
          </button>
        </div>
      )}
      {showEmotionPopup && emotionData.emotions?.length > 0 && (
        <>
          <div
            className="emotionsPopup-wrap"
            onClick={() => {
              setShowEmotionPopup(false);
            }}
          />
          <SeityEmotionsPopup
            emotions={emotionData.emotions}
            fullScreen={false}
            handleEmotionSel={(idx, selected) => {
              const temp = [...emotionData.emotions];
              const sel = { ...temp[idx] };
              sel.selected = selected;
              temp[idx] = sel;
              const added = temp.filter((t) => {
                return t.selected === true;
              });
              setEmotionData({ emotions: temp, added });
            }}
          />
        </>
      )}
      {showCheckIn && (
        <div className="journal-checkin-container">
          <CheckIn
            onFallBack={() => {
              setShowCheckIn(false);
            }}
          />
        </div>
      )}
      {isLoading && <SeityLoader />}
      {showIdkModal && (
        <SeityIdkModal
          isOpen={showIdkModal}
          onClose={() => {
            setShowIdkModal(false);
          }}
        />
      )}
    </div>
  );
};

export default JournalEntry;
