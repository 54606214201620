import { FunctionComponent } from "react"
import strings from "../../_core/strings/strings";
import { SeityBackButton } from "../../_core/components/SeityBackButton";
import "./styles.scss";

const PermissionHeader: FunctionComponent = () => {
  return (<>
        <SeityBackButton wrapperClassName="sd-back-button" />
        <div className="sap-title">{strings.permissions}</div>
  </>)
}

export default PermissionHeader;