/* eslint-disable radix */
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { getPracticeDetailRequest, resetPracticeDetail } from "../practicesSlice";
import { RootState } from "../../../app/rootReducer";

import SeityLoader from "../../../_core/components/SeityLoader";
import SeityDragon from "../../../_core/components/SeityDragon";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";

import { PracticeDetail01Props, PracticeDetailParamTypes } from "./types";
import strings from "../../../_core/strings/strings";

import "./styles.scss";

export const PracticeDetail01: FunctionComponent<PracticeDetail01Props> = () => {
  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });
  const history = useHistory();

  const params = useParams<PracticeDetailParamTypes>();
  const dispatch = useDispatch();
  const { dragonType, subcategoryID, categoryID } = params;

  const { isLoading, practiceDetail } = useSelector((state: RootState) => {
    return state.practiceScores;
  });

  const onToggleDragonItem = () => {
    history.push("/DragonLearnMore");
  };

  const onToggleSeeAll = () => {
    history.push(`/dragonList/${dragonType}`);
  };

  useEffect(() => {
    dispatch(
      getPracticeDetailRequest({
        outcomeCategoryID: parseInt(categoryID),
        outcomeSubcategoryID: parseInt(subcategoryID)
      })
    );

    return () => {
      dispatch(resetPracticeDetail());
    };
  }, []);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  if (isLoading) return <SeityLoader />;

  if (practiceDetail) {
    return (
      <div className="dragon-main-container">
        <SeityBackButton wrapperClassName="dragon-main-back-button" />
        <h1>{practiceDetail.subcategory}</h1>
        <img
          className="dragon-logoImage"
          src={RequireSeityImage(practiceDetail?.logoURL || "").default}
          alt={practiceDetail.subcategory || ""}
        />
        <div className="dragon-background-wrapper-01">
          <div className="dragon-descripntion-practice">
            <h3>{practiceDetail.practiceDescription}</h3>
            <div
              className="dragon-see-more-score"
              onClick={() => {
                return history.push(`/practices/myPractices/${categoryID}`);
              }}
            >
              <span>{strings.formatString(strings.seeMoreScore, practiceDetail.category)}</span>
              <img src={require("../../../assets/graphics/caret_seity_green.png").default} alt="Caret" className="see-more-caret" />
            </div>
          </div>
          <SeityDragon
            practiceDetail={practiceDetail}
            onToggleLearn={() => {
              return onToggleDragonItem();
            }}
            onToggleSeeAll={() => {
              return onToggleSeeAll();
            }}
          />
        </div>
      </div>
    );
  }
  return <></>;
};
