/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from "react";

import { SeityVideoPlayer } from "../../../../_core/components/SeityVideoPlayer";

import strings from "../../../../_core/strings/strings";
import "../styles.scss";

export interface ArtOfYouSimpleProps {
  firstName: string;
}

export const ArtOfYouSimple = ({ firstName }: ArtOfYouSimpleProps) => {
  const [sliderPosition, setSliderPosition] = useState("0%");
  const [carouselSlide, setCarouselSlide] = useState(1);

  const slideRight = () => {
    setSliderPosition("100%");
    setCarouselSlide(2);
  };

  const slideLeft = () => {
    setSliderPosition("0%");
    setCarouselSlide(1);
  };

  return (
    <div className="aoys-container">
      <div className="aoys-intro-wrapper">
        <div className="aoys-header">
          <div className="aoys-header-slider">
            <div className="aoys-header-slider-inner" style={{ right: sliderPosition }}>
              <div className="aoys-header-slider-left">
                <div className="aoys-img-container">
                  <div className="aoys-welcome-message-container">
                    <p className="aoys-message-small">{strings.the}</p>
                    <p className="aoys-message-large">{strings.art}</p>
                    <p className="aoys-message-small">{strings.of}</p>
                    <p className="aoys-message-large">{firstName}</p>
                  </div>
                </div>
                <img
                  className="aoys-caret"
                  onClick={slideRight}
                  src={require("../../../../assets/graphics/caret_white.png").default}
                  alt="caret"
                />
              </div>
              <div className="aoys-header-slider-right">
                <img
                  className="aoys-caret"
                  onClick={slideLeft}
                  src={require("../../../../assets/graphics/caret_white.png").default}
                  alt="caret"
                  style={{ transform: "rotate(180deg)", paddingRight: "20px" }}
                />
                <div className="aoys-video-container">
                  <h5>{strings.watchArtOfYou}</h5>
                  <SeityVideoPlayer videoFile="theartofyoumain.mp4" pictureFile="challenge202105i01" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              className="aoys-carousel"
              src={require(`../../../../assets/graphics/seity-carousel-${carouselSlide}of2.png`).default}
              alt="carousel slide indicator"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
