/* eslint-disable react/require-default-props */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SeityLoader from "../SeityLoader";

import "./styles.scss";

const LOADING_DURATION = 4000;

export const SeityContentWrap = ({ isBackgroundLoading = false, showBackgroundMask = true, loading, children, onDismiss }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading && isBackgroundLoading) {
      setTimeout(() => {
        setIsLoading(false);
        onDismiss();
      }, LOADING_DURATION);
    }
  }, [isLoading]);

  return (
    <>
      {((isBackgroundLoading && isLoading) || (!isBackgroundLoading && loading)) && <SeityLoader showBackgroundMask={showBackgroundMask} />}
      {children}
    </>
  );
};

SeityContentWrap.propTypes = {
  // Self loading, revoke the loading animation after 3 seconds
  isBackgroundLoading: PropTypes.bool,
  // This is valid only if the isBackgroundLoading is `false`, revoke the loading animation when this is updated to `false`
  showBackgroundMask: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.element,
  onDismiss: PropTypes.func
};
