import { useState, useEffect, FunctionComponent } from "react";
import { useHistory } from "react-router";

import { Characterization } from "../../../api/myStory/types/myStoryTypes";
import TransformGraphData from "./utils/TransformGraphData";

import { TriangleIndicators } from "./components/TriangleIndicators";
import { CheckInLineChart } from "./components/CheckInLineChart";
import { CheckInDate } from "./components/CheckInDate";
import SeityButton from "../../../_core/components/SeityButton";

import strings from "../../../_core/strings/strings";
import "./styles.scss";

const wellbeingIcon = require("../../../assets/icons/wellbeingIcon.png").default;
const energyIcon = require("../../../assets/icons/energyIcon.png").default;
const directionIcon = require("../../../assets/icons/directionIcon.png").default;
const belongingIcon = require("../../../assets/icons/belongingIcon.png").default;
const joyIcon = require("../../../assets/icons/joyIcon.png").default;

export interface CheckInGraphsProps {
  characterization: Characterization;
}

export const CheckInGraphs: FunctionComponent<CheckInGraphsProps> = ({ characterization }) => {
  const history = useHistory();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [hideWellbeingGraphPoints, setHideWellbeingGraphPoints] = useState([0, 0, 0, 0, 0, 7]);
  const [wellbeingGraphIndex, setWellbeingGraphIndex] = useState(5);
  const [hideOtherGraphsPoints, setHideOtherGraphsPoints] = useState([0, 0, 0, 0, 0, 7]);
  const [otherGraphsIndex, setOtherGraphsIndex] = useState(5);
  const chartWidth = Number(document.querySelector(".cig-container")?.clientWidth) * 0.95;
  let wellbeingGraphData;
  let energyGraphData;
  let directionGraphData;
  let belongingGraphData;
  let joyGraphData;

  if (characterization != null && characterization.assessmentSummary.length === 5) {
    wellbeingGraphData = TransformGraphData(characterization.wellbeingGraph, "#fdcb5d", "Wellbeing");
    energyGraphData = TransformGraphData(characterization.energyGraph, "#c25043", "Energy");
    directionGraphData = TransformGraphData(characterization.directionGraph, "#90c269", "Direction");
    belongingGraphData = TransformGraphData(characterization.belongingGraph, "#65b1e0", "Belonging");
    joyGraphData = TransformGraphData(characterization.joyGraph, "#f38fc2", "Joy");
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const handleWellbeingGraphTriangleClick = (index: number) => {
    // if different index clicked change index and change hidden points arrary
    if (wellbeingGraphIndex !== index) {
      setWellbeingGraphIndex(index);
      const fullArray = Array(6).fill(0);
      fullArray[index] = 7;
      setHideWellbeingGraphPoints(fullArray);
    }
  };

  const handleOtherGraphsTriangleClick = (index: number) => {
    // if different index clicked change index and change hidden points arrary
    if (otherGraphsIndex !== index) {
      setOtherGraphsIndex(index);
      const fullArray = Array(6).fill(0);
      fullArray[index] = 7;
      setHideOtherGraphsPoints(fullArray);
    }
  };

  const toCheckIn = () => {
    history.push("/checkin/wellbeingCheckIn");
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const chartWid: number = width <= 768 ? width / 2 + 100 : Math.min(width - 120, 1100);
  let chartAspectRatio: number = width < 768 ? 3.2 : width < 1152 ? 5 : 6.35;
  if (characterization.displayWellbeingData) {
    // if user has completed a wellbeing check in show check in graphs
    return (
      <div className={characterization.checkInDue ? "cig-due" : "cig"}>
        <div className="cig-container">
          {/* ------------------------- header and description -----------------------*/}

          <h1>Wellbeing History</h1>
          <div className="cig-container-wellbeing">
            <div className="cig-container-wellbeing-header">
              <div className="cig-container-wellbeing-header-description">{strings.wellbeingCheckInDescription}</div>
            </div>
            {/* --------------------------- wellbeing graph --------------------------- */}
            <CheckInLineChart
              chartData={wellbeingGraphData}
              containerId="cig-container-wellbeing-graph"
              width={chartWidth}
              aspectRatio={chartAspectRatio}
              pointsRendered={hideWellbeingGraphPoints}
              pointFocused={wellbeingGraphIndex}
              dataLabelColor="#314947"
              title={strings.wellBeing}
              iconPath={wellbeingIcon}
            />
            <TriangleIndicators graphIndex={wellbeingGraphIndex} triangleClickHandler={handleWellbeingGraphTriangleClick} />
            <CheckInDate graphIndex={wellbeingGraphIndex} graphDateArray={characterization.dateTimeGraph} />
          </div>

          {/* --------------------------- other four graphs ----------------------------*/}

          <div className="cig-container-other">
            <CheckInLineChart
              chartData={energyGraphData}
              containerId="cig-container-other-graph"
              width={chartWidth}
              aspectRatio={chartAspectRatio}
              pointsRendered={hideOtherGraphsPoints}
              pointFocused={otherGraphsIndex}
              dataLabelColor="#ffffff"
              title={strings.itemTitleEnergy}
              iconPath={energyIcon}
            />
            <CheckInLineChart
              chartData={directionGraphData}
              containerId="cig-container-other-graph"
              width={chartWidth}
              aspectRatio={chartAspectRatio}
              pointsRendered={hideOtherGraphsPoints}
              pointFocused={otherGraphsIndex}
              dataLabelColor="#000000"
              title={strings.itemTitleDirection}
              iconPath={directionIcon}
            />
            <CheckInLineChart
              chartData={belongingGraphData}
              containerId="cig-container-other-graph"
              width={chartWidth}
              aspectRatio={chartAspectRatio}
              pointsRendered={hideOtherGraphsPoints}
              pointFocused={otherGraphsIndex}
              dataLabelColor="#000000"
              title={strings.itemTitleBelonging}
              iconPath={belongingIcon}
            />
            <CheckInLineChart
              chartData={joyGraphData}
              containerId="cig-container-other-graph"
              width={chartWidth}
              aspectRatio={chartAspectRatio}
              pointsRendered={hideOtherGraphsPoints}
              pointFocused={otherGraphsIndex}
              dataLabelColor="#000000"
              title={strings.itemTitleJoy}
              iconPath={joyIcon}
            />
            <TriangleIndicators graphIndex={otherGraphsIndex} triangleClickHandler={handleOtherGraphsTriangleClick} />
            <CheckInDate graphIndex={otherGraphsIndex} graphDateArray={characterization.dateTimeGraph} />
          </div>
          {characterization.checkInDue && <SeityButton className="cig-container-button" label={strings.checkInNow} onClick={toCheckIn} />}
        </div>
      </div>
    );
  }
  return <></>;
};
