import { FunctionComponent } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import classNames from "classnames";
import HorizontLine from "../../../assets/web-icons/horizont_line.svg";
import { HeaderMenuProps } from "./types";
import "./styles.scss";
import useHeaderMenuData from "./useHeaderMenuData";

export const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
  showBurger,
  setVisible,
  setShowTodo,
  activeMenuIdx,
  setActiveMenuIdx,
  submenuData
}) => {
  const headerMenuData = useHeaderMenuData();
  const history = useHistory();

  const handleSubmenuToggle = (i) => {
    if (activeMenuIdx) {
      setActiveMenuIdx(null);
    } else {
      setActiveMenuIdx(i);
    }
  };

  const isPathHighlighted = (linkUrl: string, relatedPaths: string[] = []): boolean => {
    const currentPath = history?.location?.pathname;
    return (
      currentPath === linkUrl ||
      (linkUrl === headerMenuData[0].linkUrl && currentPath === "/") ||
      relatedPaths.includes(currentPath)
    );
  };

  return showBurger ? (
    <ul>
      {headerMenuData.map((link, i) => {
        return (
          !link?.hidden && (
            <li key={i}>
              {link?.submenu ? (
                <a onClick={() => handleSubmenuToggle(i)} className="highlighted-menu-item">
                  {link.title}
                  <img src={require(`../../../assets/icons/${activeMenuIdx === i ? "arrow-up" : "arrow-down"}.svg`).default} />
                </a>
              ) : (
                <NavLink
                  className={classNames({
                    "highlighted-menu-item":
                      history?.location?.pathname === link.linkUrl ||
                      (link.linkUrl === headerMenuData[0].linkUrl && history?.location?.pathname === "/"),
                    "disabled": !link.enabled
                  })}
                  onClick={() => {
                    return setVisible(false);
                  }}
                  id="first-menu-item"
                  to={link.linkUrl}
                >
                  {link.title}
                </NavLink>
              )}
              {/* mobile submenu */}
              {activeMenuIdx === i && link?.submenu && (
                <div className="grow-submenu">
                  {submenuData.map((item, i) => {
                    return (
                      !item?.hidden && (
                        <div
                          key={i}
                          className={"submenu-item"}
                          onClick={() => {
                            history.push(item.linkUrl);
                            setActiveMenuIdx(null);
                          }}
                        >
                          <div>
                            <img src={require(`../../../assets/graphics/submenu/${item.icon}.svg`).default} />
                            <p>{item.title}</p>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </li>
          )
        );
      })}
    </ul>
  ) : (
    <ul>
      {headerMenuData.map((link, i) => {
        return (
          !link?.hidden && (
            <li key={i}>
              {link?.submenu ? (
                <a onClick={() => handleSubmenuToggle(i)} className="submenu-parent" id={link.title}>
                  <div className={"center-menu-items-wrapper"}>{link.title}</div>
                  <img src={require(`../../../assets/icons/${activeMenuIdx === i ? "arrow-up" : "arrow-down"}.svg`).default} />
                </a>
              ) : (
                <Link
                  to={link.linkUrl}
                  className={`${link.enabled ? "" : "disabled"}`}
                  onClick={() => {
                    setActiveMenuIdx(null);
                    return setShowTodo(false);
                  }}
                >
                  <div
                    className={classNames("center-menu-items-wrapper", {
                      "center-menu-items-wrapper-highlited": isPathHighlighted(link.linkUrl, link.relatedPaths)
                    })}
                  >
                    {link.title}
                    {isPathHighlighted(link.linkUrl, link.relatedPaths) && (
                      <img className="horizont-line" src={HorizontLine} alt="Horizont Line"/>
                    )}
                  </div>
                </Link>
              )}
            </li>
          )
        );
      })}
    </ul>
  );
};

export default HeaderMenu;
