import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PracticeScores } from "../../api/account/types/accountTypes";
import {
  PracticeScoreCategory,
  PracticeScoreSubCategory,
  DRAGON_ID_AWAKE,
  DRAGON_ID_RAGING,
  DRAGON_ID_TAME,
  PracticeDetail,
  PracticeDetailPayloadRequest
} from "../../api/account/types/practiceScoresTypes";
import { getPracticeDetail, getPracticeScores, getPracticeScoresList } from "../../api/account/seityHealthAPI-account";
import { AppThunk } from "../../app/store";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../api/authTypes";
import { setRedirectToLogin } from "../auth/authSlice";

export interface PracticeScoresState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly practiceScores: PracticeScores;
  readonly practiceScoresList: [PracticeScoreCategory] | null;
  readonly ragingDragons: PracticeScoreSubCategory[] | [];
  readonly awakeDragons: PracticeScoreSubCategory[] | [];
  readonly tameDragons: PracticeScoreSubCategory[] | [];
  readonly practiceDetail: PracticeDetail | null;
}

const setInitalState = {
  error: null,
  isLoading: false,
  practiceScoresList: null,
  awakeDragons: [],
  ragingDragons: [],
  tameDragons: [],
  practiceDetail: null
} as PracticeScoresState;

const practicesSlice = createSlice({
  name: "practices-practices",
  initialState: setInitalState,
  reducers: {
    setPracticeScores(state, action: PayloadAction<PracticeScores>) {
      state.practiceScores = action.payload;
      state.isLoading = false;
    },
    setPracticeScoresError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    setPracticeScoresList(state, action: PayloadAction<[PracticeScoreCategory]>) {
      state.practiceScoresList = action.payload;
      const ragingDragons: PracticeScoreSubCategory[] = [];
      const awakeDragons: PracticeScoreSubCategory[] = [];
      const tameDragons: PracticeScoreSubCategory[] = [];
      if (state.practiceScoresList) {
        state.practiceScoresList.forEach((element) => {
          let dragons = element.subcategories.filter((p) => p.dragonID === DRAGON_ID_RAGING);
          ragingDragons.push(...dragons);
          dragons = element.subcategories.filter((p) => p.dragonID === DRAGON_ID_AWAKE);
          awakeDragons.push(...dragons);
          dragons = element.subcategories.filter((p) => p.dragonID === DRAGON_ID_TAME);
          tameDragons.push(...dragons);
        });
        state.ragingDragons = ragingDragons;
        state.awakeDragons = awakeDragons;
        state.tameDragons = tameDragons;
      }
    },
    setPracticeDetail(state, action: PayloadAction<PracticeDetail>) {
      state.practiceDetail = action.payload;
      state.isLoading = false;
    },
    resetPracticeDetail(state) {
      state.practiceDetail = null;
    },
    resetPracticeSlice: () => setInitalState
  }
});

export const {
  setPracticeScores,
  setPracticeScoresList,
  setPracticeScoresError,
  setPracticeDetail,
  clearApiError,
  setIsLoading,
  resetPracticeSlice,
  resetPracticeDetail
} = practicesSlice.actions;
export default practicesSlice.reducer;

export const getPracticeScoresRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const practiceScoresResponse = await getPracticeScores(token);
    if (practiceScoresResponse.success) {
      const data: any = practiceScoresResponse.data;
      //const { practiceScores } = data;
      dispatch(setPracticeScores(data));
      //dispatch(setCoreValueError({ error: "Test Error!" }));
    }
  } catch (err) {
    console.error(err);
    dispatch(setPracticeScoresError({ error: err.toString() }));
  }
};

export const getPracticeScoresListRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }
    dispatch(setIsLoading({}));
    const practiceScoresListResponse = await getPracticeScoresList(token);
    if (practiceScoresListResponse.success) {
      const data: any = practiceScoresListResponse.data;
      dispatch(setPracticeScoresList(data.practiceScoresList));
    }
  } catch (err) {
    console.error(err);
    dispatch(setPracticeScoresError({ error: err.toString() }));
  }
};

export const getPracticeDetailRequest =
  (practiceDetailRequest: PracticeDetailPayloadRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));
      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }
      const practiceDetailResponse = await getPracticeDetail(token, practiceDetailRequest);
      if (practiceDetailResponse.success) {
        const data: any = practiceDetailResponse.data;
        dispatch(setPracticeDetail(data));
      }
    } catch (err) {
      console.error(err);
      dispatch(setPracticeScoresError({ error: err.toString() }));
    }
  };
