import { FunctionComponent } from "react";

export const ErrorPage: FunctionComponent = () => {
  return (
    <div className="">
      <h1>401 error</h1>
    </div>
  );
};

export default ErrorPage;
