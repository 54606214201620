import { FunctionComponent } from "react";
import { AssessmentItem } from "./AssessmentItem";

import "../styles.scss";

const world = require("../../../../assets/graphics/world.svg").default;
const body = require("../../../../assets/graphics/body.svg").default;
const mind = require("../../../../assets/graphics/mind.svg").default;
const spirit = require("../../../../assets/graphics/spirit.svg").default;

export interface AssessmentPracticeOutcomesProps {
  navigationFunction: Function;
  assessmentImageArray: string[];
  assessmentLabelArray: string[];
  assessmentScoreArray: string[];
  assessmentTextEngArray: string[];
}

export const AssessmentPracticeOutcomes: FunctionComponent<AssessmentPracticeOutcomesProps> = ({
  navigationFunction,
  assessmentImageArray,
  assessmentLabelArray,
  assessmentScoreArray,
  assessmentTextEngArray
}) => {
  return (
    <>
      <AssessmentItem
        navigationFunction={() => {
          return navigationFunction(1);
        }}
        assessmentScore={assessmentScoreArray.slice(1, 2).toString()}
        assessmentImage={world}
        assessmentLabel={assessmentLabelArray.slice(1, 2).toString()}
        assessmentTextEng={assessmentTextEngArray.slice(1, 2).toString()}
      />
      <AssessmentItem
        navigationFunction={() => {
          return navigationFunction(2);
        }}
        assessmentScore={assessmentScoreArray.slice(2, 3).toString()}
        assessmentImage={body}
        assessmentLabel={assessmentLabelArray.slice(2, 3).toString()}
        assessmentTextEng={assessmentTextEngArray.slice(2, 3).toString()}
      />
      <AssessmentItem
        navigationFunction={() => {
          return navigationFunction(3);
        }}
        assessmentScore={assessmentScoreArray.slice(3, 4).toString()}
        assessmentImage={mind}
        assessmentLabel={assessmentLabelArray.slice(3, 4).toString()}
        assessmentTextEng={assessmentTextEngArray.slice(3, 4).toString()}
      />
      <AssessmentItem
        navigationFunction={() => {
          return navigationFunction(4);
        }}
        assessmentScore={assessmentScoreArray.slice(4, 5).toString()}
        assessmentImage={spirit}
        assessmentLabel={assessmentLabelArray.slice(4, 5).toString()}
        assessmentTextEng={assessmentTextEngArray.slice(4, 5).toString()}
      />
    </>
  );
};
