import { FunctionComponent, ReactElement, useContext, useState } from "react";
import Modal from "react-modal";
import { LanguageItem } from "../../../api/account/types/accountTypes";

import { switchLanguage } from "../../../app/utils";

import close from "../../../assets/web-icons/closeBlack.svg";
import SeityButton from "../../../_core/components/SeityButton";

import strings from "../../../_core/strings/strings";
import "./Login.scss";

type Props = {
  isOpen: boolean;
  onClose: (langID: number) => void;
  languages: Array<LanguageItem> | null;
};

const LanguagesSelectionModal: FunctionComponent<Props> = ({ isOpen, onClose, languages }): ReactElement => {
  Modal.setAppElement("#root");
  const [selectedLanguageID, setSelectedLanguageID] = useState(strings.getLanguage() === "en-US" ? 1 : 2);

  const onSave = () => {
    switchLanguage(selectedLanguageID);
    onClose(selectedLanguageID);
  };

  return (
    <Modal
      appElement={document.getElementById("#root")}
      isOpen={isOpen}
      onRequestClose={onClose}
      className="lsm-container"
      overlayClassName="lsm-overlay"
    >
      <button className="lsm-close" onClick={() => onClose(-1)}>
        <img src={close} />
      </button>
      <h2>{strings.selectLanguage}</h2>
      <div className="lsm-languages">
        {languages &&
          languages.map((lang: LanguageItem) => (
            <button className="lsm-language-item" key={lang.languageID} onClick={() => setSelectedLanguageID(lang.languageID)}>
              <label htmlFor={`langOption#${lang.languageID}`}>{lang.description}</label>
              <input
                className="radio-btn lsm-radio"
                type="radio"
                id={`langOption#${lang.languageID}`}
                name={lang.code}
                value={lang.languageID}
                readOnly
                checked={lang.languageID === selectedLanguageID}
              />
            </button>
          ))}
      </div>
      <SeityButton onClick={onSave} label={strings.saveButtonText} className="lsm-save-btn" />
    </Modal>
  );
};

export default LanguagesSelectionModal;
