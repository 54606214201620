import { FunctionComponent, MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import SeityProgressBar from "../../../../_core/components/SeityProgressBar";

import "../styles.scss";

const caretWhite = require("../../../../assets/graphics/caret_white.svg").default;
const caretBlack = require("../../../../assets/graphics/caret_black.svg").default;

export interface AssessmentItemProps {
  navigationFunction?: MouseEventHandler;
  assessmentScore: string;
  assessmentLabel: string;
  assessmentImage: string;
  assessmentTextEng: string;
  isAssessmentCompleted?: boolean;
}

export const AssessmentItem: FunctionComponent<AssessmentItemProps> = ({
  navigationFunction,
  assessmentScore,
  assessmentLabel,
  assessmentTextEng,
  assessmentImage
}: AssessmentItemProps) => {
  const { assessmentComplete } = useSelector((state: RootState) => {
    return state.characterization.characterization;
  });

  const wrapClassName = `ao-ai-bubble ao-ai-bubble-${assessmentTextEng.toLowerCase().replace(/\s+/g, "")}`;

  let caretType = caretWhite;
  if (assessmentTextEng === "Mind" || assessmentTextEng === "Spirit") {
    caretType = caretBlack;
  }

  return (
    <div className="ao-ai" onClick={navigationFunction}>
      {assessmentComplete && (
        <div
          className="ao-ai-score"
          style={{
            visibility: assessmentScore === "-1" ? "hidden" : "visible"
          }}
        >
          <h2>{assessmentScore}%</h2>
          <SeityProgressBar
            progress={Number(assessmentScore)}
            trackColor="rgba(52, 59, 67, 1)"
            color="rgba(255, 248, 235, 1)"
            height={7}
            containerStyle="ao-ai-score-progress-bar"
          />
        </div>
      )}
      <div className={wrapClassName}>
        <img className="ao-ai-bubble-logo" src={assessmentImage} alt={assessmentLabel} />
        <h2>{assessmentLabel}</h2>
        <img className="ao-ai-bubble-caret" src={caretType} alt="caret" />
      </div>
    </div>
  );
};
