export const HIDE_HEADER_ROUTES = [
  "/login",
  "/changeEmail",
  "/emailVerification",
  "/temporary-password",
  "/register/registerLangSelect",
  "/register/registerOne",
  "/register/registerTwo",
  "/register/registerThree",
  "/register/registerFour",
  "/register/registerFive",
  "/register/registerSix",
  "/register/registerSeven",
  "/lockedAccount",
  "/plotline",
  "/phoneVerification",
  "/confirmation",
  "/forgot-password-username"
];

export const HIDE_HAMBURGER = [
  "/register/registerLangSelect",
  "/register/registerOne",
  "/register/registerTwo",
  "/register/registerThree",
  "/register/registerFour",
  "/register/registerFive",
  "/register/registerSix",
  "/register/registerSeven",
  "/eula"
];

export const HIDE_BACK_CARET = [
  "/myStory",
  "/",
  "/challenge/content",
  "/challenge/notAvailable",
  "/challenge/intro",
  "/challenge/complete",
  "/eula"
];

export const HIDE_BACK_BUTTON = ["/register/registerFive","/register/registerSix"];

export enum DragonType {
  RangingDragons = "ragingDragons",
  AwakeDragons = "awakeDragons",
  TameDragons = "tameDragons"
}

export const LearnSeityCategory = {
  categoryId: 1,
  description:
    // eslint-disable-next-line max-len
    "Welcome! This is a great place to learn more about the Seity Health paradigm and how to put it into practice. You’ll learn how to see yourself as a whole person and why this is key to crafting your best life. ",
  name: "Learn Seity",
  parentCategory: null
};

export const LOCKED_ACCOUNT_ID = 137;
