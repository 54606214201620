import { AppContentLine } from "../../../api/policy/types/AppContent";
import { GuideItem } from "../../../api/referenceGuide/referenceGuideTypes";
import strings from "../../../_core/strings/strings";

export const normalizeDragonGuide = (appContentLines: AppContentLine[]): GuideItem => {
  return {
    outcomeCategory: {
      outcomeCategoryID: 0,
      category: strings.dragons,
      description: appContentLines[0].contentText
    },
    subcategories: [
      {
        subcategory: strings.tameDragon,
        description: appContentLines[1].contentText
      },
      {
        subcategory: strings.awakeDragon,
        description: appContentLines[2].contentText
      },
      {
        subcategory: strings.ragingDragon,
        description: appContentLines[3].contentText
      }
    ]
  };
};
