import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styled from "styled-components";

import { RootState } from "./rootReducer";
import { getSavedToken } from "../features/auth/authSlice";

import { SeityAppContext } from "./SeityAppContext";

import { SeityHeader } from "../_core/components/SeityHeader";

import strings from "../_core/strings/strings";
import "./App.scss";

import { Profile } from "../features/account/Profile";
import ChangePassword from "../features/account/maintenance/ChangePassword/index";
import { EULA } from "../features/account/EULA";

import { AssessmentMainIntro } from "../features/assessment/base/AssessmentMainIntro";
import { BreathingExercise } from "../features/assessment/base/BreathingExercise";
import { AssessCoreValues } from "../features/assessment/AssessCoreValues";
import { AssessPractices } from "../features/assessment/AssessPractices";
import { AssessOutcomes } from "../features/assessment/AssessOutcomes";

import { MyStory } from "../features/myStory/MyStory";
import { MyCoreValues } from "../features/coreValues/MyCoreValues";
import { MyPractices } from "../features/practices/MyPractices";
import { PracticeDetail } from "../features/practices/PracticeDetail";
import { MyOutcomes } from "../features/outcomes/MyOutcomes";

import { CheckIn } from "../features/wellbeingCheckIn/CheckIn/CheckinMain";
import { Incentives } from "../features/wellbeingCheckIn/Incentives";
import { Triage } from "../features/wellbeingCheckIn/Triage";
import { ResourceLanding } from "../features/resources/ResourceLanding";
import { Resources } from "../features/resources/ResourcesContainer";
import { SurveySelection } from "../features/survey";
import { Learn } from "../features/grow/learn/Learn";

import { MySyncStoriesList } from "../features/grow/StoriesSyncs/MySyncStoriesList";
import { SyncStoriesDetail } from "../features/grow/StoriesSyncs/SyncStoriesDetail";

import { SecurityAndProtection } from "../features/securityAndProtection";
import { UserAgreement } from "../features/policy/UserAgreement";
import { Privacy } from "../features/policy/Privacy";
import { Coppa } from "../features/policy/Coppa";
import { California } from "../features/policy/California";

import { Challenge } from "../features/challenges/ChallengeContent";
import ChallengeAcceptance from "../features/challenges/ChallengeAcceptance/index";
import { ChallengeUnavailable } from "../features/challenges/ChallengeUnavailable/index";
import ChallengeComplete from "../features/challenges/ChallengeComplete/index";
import { DragonList } from "../features/practices/DragonList";
import { PracticeDetail01 } from "../features/practices/PracticeDetail01";
import { DragonLearnMore } from "../features/practices/DragonLearnMore";
import Grow from "../features/grow/Grow";
import { Settings } from "../features/account/Settings";
import { Authentication } from "../features/account/Authentication";
import { Language } from "../features/account/Language";
import { LearnPost } from "../features/grow/learn/pages/PostDetail";
import { GuidesList } from "../features/grow/learn/pages/AllGuides";
import { SeriesList } from "../features/grow/learn/pages/AllSeries";
import { LearnGuide } from "../features/grow/learn/pages/GuideDetail";
import { TSAssetDetail } from "../features/grow/learn/pages/TSAssetDetail";

import JournalEntry from "../features/journals/JournalEntry";
import Journals from "../features/journals/Journals";

import IntentionList from "../features/intentions/IntentionList";
import ChooseIntention from "../features/intentions/ChooseIntention";
import IntentionSelected from "../features/intentions/IntentionSelected";
import IntentionCard from "../features/intentions/IntentionCard";
import CoreValuesOutroModal from "../features/assessment/coreValues/CoreValuesOutroModal";

import PlotlineTemplate from "../features/plotline/PlotlineTemplate";

import Today from "../features/today/Today";
import ChooseAffirmation from "../features/today/ChooseAffirmation";
import { switchLanguage } from "./utils";
import { sendGetAccountInfoRequest, sendMessageCenterListRequest, setSupportEmail } from "../features/account/accountSlice";
import { getTodayRequest } from "../features/today/todaySlice";
import { getUserAppSettingsRequest } from "../slices/userAppSettingsSlice";
import { SCAssetList } from "../features/grow/learn/pages/AllSCAssets";
import { SCAssetDetail } from "../features/grow/learn/pages/SCAssetDetail";
import ErrorPage from "./ErrorPage";
import { clearCommonError } from "../slices/commonAlertSlice";
import SeityAlert from "../_core/components/SeityAlert";
import { getPlotlineInjections } from "../features/plotline/plotlineSlice";
import { isPlotlineInjectionCompleted } from "../features/plotline/helpers";
import SeityLoader from "../_core/components/SeityLoader";
import { Directory } from "../features/directory";
import { getToken } from "../features/auth/UseSessionStorageToken";
import FSGroups from "../features/familySharing/FSGroups";
import FSGroupDetail from "../features/familySharing/FSGroupDetail";
import { FSInviteForm } from "../features/familySharing/FSInviteForm";
import { WellbeingOnly } from "../features/wellbeing";
import { HIDE_HEADER_ROUTES } from "../consts";
import { AUTH_ROUTES } from "../consts/routeConfig";
import EmailVerification from "../features/auth/emailVerification";
import { CoreValues } from "../features/coreValues";
import { WholeHealth } from "../features/wholeHealth";
import { getAppContentByLanguageRequest } from "../features/policy/appContentSlice";
import CVList from "../features/cvDirectory/CVList";
import PersonalListDetail from "../features/cvDirectory/PersonalListDetail";
import { PlotlineInjectionPointID } from "../api/plotlineTypes";
import ModifyAuthentication from "../features/account/maintenance/ModifyAuthentication";
import { Support } from "../features/account/Support";
import { CoreValueDownloads } from "../features/coreValues/CoreValueDownloads";
import { CoreValueHistory } from "../features/coreValues/CoreValueHistory";
import Permission from '../features/permissions';
import CoreValueDirectoryPermission from "../features/permissions/CoreValueDirectoryPermission";

const Main = styled.main`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

const PLOTLINE_ROUTES = [
  "/assessment/intro",
  "assessment/breathingExercise",
  "/assessment/coreValues",
  "/today/affirmation",
  "/intentions/choose",
  "intentionSelected",
  "intentions/card",
  "/plotline/"
];

const PERMISSIONS_ROUTE = "/permissions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const { firstIncompletePLInjectionId } = useSelector((state: RootState) => {
    return state.plotline;
  });

  const validatePlotlineRoute = (path: string) => {
    const r = PLOTLINE_ROUTES.filter((p) => path.includes(p));
    return r && r.length > 0;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const path = props.location.pathname;

        if (shouldRedirectToLogin) {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
        if (userAppSettings.forceNewPassword) {
          return path !== "/temporary-password" ? <Redirect to="/temporary-password" /> : <Component {...props} />;
        }
        if (userAppSettings.eulaNeeded || userAppSettings.revisedEULANeedToNotify) {
          return path !== "/eula" ? (
            <Redirect to={{ pathname: "/eula", state: { isRevised: userAppSettings.revisedEULANeedToNotify } }} />
          ) : (
            <Component {...props} />
          );
        }
        if (userAppSettings.emailVerificationNeeded && path !== "/authentication/changeCredential") {
          return path !== "/emailVerification" ? <Redirect to="/emailVerification" /> : <Component {...props} />;
        }
        if (
          firstIncompletePLInjectionId !== null &&
          firstIncompletePLInjectionId === PlotlineInjectionPointID.AfterLogin &&
          !validatePlotlineRoute(path)
        ) {
          return <Redirect to={`/plotline/${firstIncompletePLInjectionId}`} />;
        }
        if ((path === "/grow" && !userAppSettings.growEnabled) || (path === "/checkIn/resources" && !userAppSettings.getHelpEnabled)) {
          return <Redirect to={{ pathname: "/" }} />;
        }
        if (path === "/today" && !userAppSettings.todayPageEnabled) {
          return <Redirect to={{ pathname: "/coreValues"}} />
        }

        if (!userAppSettings.coreValueDirectoryEnabled && path === PERMISSIONS_ROUTE) {
          return <Redirect to={{ pathname: "/" }} />;
        }
        // commented this block out to accommodate for onDemand plotlines
        // if (firstIncompletePLInjectionId === null && path.includes("/plotline/")) {
        //   return <Redirect to={{ pathname: "/" }} />;
        // }
        return <Component {...props} />;
      }}
    />
  );
};

export const CommonAlertProvider: React.FC = ({ children }) => {
  const error = useSelector((state: RootState) => state.commonAlertSlice.error);
  const dispatch = useDispatch();
  return (
    <>
      <SeityAlert
        title={strings.oops}
        visible={error !== null}
        onToggle={() => {
          dispatch(clearCommonError());
        }}
        subTitle={error ?? ""}
      />
      {children}
    </>
  );
};

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-icon {
    width: 40px;
  }
`;

const ToastErrorContainer = styled(ToastContainer)`
  .Toastify__toast-theme--light {
    color: #343b43;
  }
`;

export const SeityRoute: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const appContext = useContext(SeityAppContext);

  const checkAuthRoute = () => location.pathname !== "/" && AUTH_ROUTES.some((r) => r.name.includes(location.pathname));

  const [isLoading, setLoading] = useState(true);
  const [isAuthRoute, setAuthRoute] = useState(checkAuthRoute());

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const { today } = useSelector((state: RootState) => {
    return state.today;
  });

  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  const { token } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { firstIncompletePLInjectionId } = useSelector((state: RootState) => {
    return state.plotline;
  });

  useEffect(() => {
    if (userAppSettings.languageID) {
      switchLanguage(userAppSettings.languageID);
    }
  }, [userAppSettings.languageID]);

  useEffect(() => {
    setAuthRoute(checkAuthRoute());
  }, [location.pathname]);

  useEffect(() => {
    const runApis = async () => {
      setLoading(true);
      // get token from cooke
      const cookieToken = await getToken();
      if (token || cookieToken) {
        try {
          console.log("startup api start");
          await dispatch(sendGetAccountInfoRequest());
          await dispatch(sendMessageCenterListRequest());
          await dispatch(getUserAppSettingsRequest());
          await dispatch(getPlotlineInjections());
          await dispatch(getTodayRequest());
          console.log("startup api end");
        } catch {
          // token expiry or api errors occured
          console.log("startup api fails");
          history.push("/login");
        }
      } else {
        await dispatch(getSavedToken(() => {}));
      }
      setLoading(false);
    };

    if (!isAuthRoute) {
      runApis();
    } else {
      setLoading(false);
    }

    dispatch(
      getAppContentByLanguageRequest(18, userAppSettings.languageID, (data) => {
        dispatch(setSupportEmail({ supportEmail: data[0].contentText }));
      })
    );
  }, [token, isAuthRoute]);

  useEffect(() => {
    const hide = HIDE_HEADER_ROUTES.some((r) => location.pathname.includes(r));
    // hide nav bar when there're routes during plotline flows like cvAssessment, intention, cvAffirmation, etc
    if (hide || (today.checkInDue && !isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.Wellbeing))) {
      appContext.setShowNavbar(false);
    } else {
      appContext.setShowNavbar(true);
    }
  }, [location.pathname, isLoading, today.checkInDue, plotlineInjections]);

  // this is to prevent routing while startup api runs
  if (isLoading) {
    return <SeityLoader hideBackgroundContent />;
  }

  return (
    <AppWrapper className="App">
      <StyledToastContainer enableMultiContainer containerId="cv-noti" />
      <ToastErrorContainer enableMultiContainer containerId="general-noti" style={{ width: "568px" }} />

      {appContext.showNavbar && <SeityHeader />}
      <Main className={`seity-main ${appContext.showNavbar ? "showNavbar" : "hideNavbar"}`}>
        <Switch>
          {AUTH_ROUTES.map((route, index) => {
            return <Route key={index} path={route.name} component={route.component} />;
          })}

          <PrivateRoute path="/eula" component={EULA} />
          <PrivateRoute path="/changeEmail" component={EmailVerification} />
          <PrivateRoute path="/changePassword" component={ChangePassword} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/authentication/addCredential" component={ModifyAuthentication} />
          <PrivateRoute path="/authentication/changeCredential" component={ModifyAuthentication} />
          <PrivateRoute path="/authentication" component={Authentication} />
          <PrivateRoute path="/language" component={Language} />
          <PrivateRoute path="/support" component={Support} />
          <PrivateRoute path="/phoneVerification" component={EmailVerification} />

          <PrivateRoute path="/myStory" component={MyStory} />
          <PrivateRoute path="/coreValues/myCoreValues" component={MyCoreValues} />
          <PrivateRoute path="/mySyncs/:objectiveID" component={SyncStoriesDetail} />
          <PrivateRoute path="/mySyncs" component={MySyncStoriesList} />
          <PrivateRoute path="/myStories/:objectiveID" component={SyncStoriesDetail} />
          <PrivateRoute path="/myStories" component={MySyncStoriesList} />
          <PrivateRoute path="/assessment/intro" component={AssessmentMainIntro} />
          <PrivateRoute path="/assessment/breathingExercise" component={BreathingExercise} />
          <PrivateRoute path="/assessment/coreValues" component={AssessCoreValues} />
          <PrivateRoute path="/assessment/coreValuesOutro" component={CoreValuesOutroModal} />
          <PrivateRoute path="/assessment/Practices" component={AssessPractices} />
          <PrivateRoute path="/assessment/Outcomes" component={AssessOutcomes} />
          <PrivateRoute path="/practices/myPractices/:outcomeCategoryID/:outcomeSubcategoryID" component={PracticeDetail} />
          <PrivateRoute path="/practices/myPractices/:outcomeCategoryID/:outcomeSubcategoryID" component={PracticeDetail} />
          <PrivateRoute path="/practices/myPractices/:outcomeCategoryID" component={MyPractices} />
          <PrivateRoute path="/dragonList/:dragonType/:subcategoryID/:categoryID" component={PracticeDetail01} />
          <PrivateRoute path="/DragonLearnMore" component={DragonLearnMore} />
          <PrivateRoute path="/dragonList/:dragonType" component={DragonList} />

          <PrivateRoute path="/checkIn/resourceLanding" component={ResourceLanding} />
          <PrivateRoute path="/checkIn/triage" component={Triage} />
          <PrivateRoute path="/checkIn/resources" component={Resources} />
          <PrivateRoute path="/checkin/wellbeingCheckin" component={CheckIn} />
          <PrivateRoute path="/checkIn/incentives" component={Incentives} />

          <PrivateRoute path="/outcomes/wholeHealthOutcomes" component={MyOutcomes} />

          <PrivateRoute path="/survey/:messageKeyID" component={SurveySelection} />
          <PrivateRoute path="/learn/guides" component={GuidesList} />
          <PrivateRoute path="/learn/series" component={SeriesList} />
          <PrivateRoute path="/learn/guide/:categoryID" component={LearnGuide} />
          <PrivateRoute path="/learn/post/:cmsID" component={LearnPost} />
          <PrivateRoute path="/learn/teach/:assetID" component={TSAssetDetail} />
          <PrivateRoute path="/learn/sciences" component={SCAssetList} />
          <PrivateRoute path="/learn/science/:assetID" component={SCAssetDetail} />
          <PrivateRoute path="/asset/:assetID" component={SCAssetDetail} />
          <PrivateRoute path="/learn" component={Learn} />

          <PrivateRoute path="/grow" component={Grow} />
          <PrivateRoute path="/securityAndProtection" component={SecurityAndProtection} />
          <PrivateRoute path="/policy/privacy" component={Privacy} />
          <PrivateRoute path="/policy/coppa" component={Coppa} />
          <PrivateRoute path="/policy/california" component={California} />
          <PrivateRoute path="/policy/userAgreement" component={UserAgreement} />
          <PrivateRoute path="/challenge/content" component={Challenge} />
          <PrivateRoute path="/challenge/acceptance" component={ChallengeAcceptance} />
          <PrivateRoute path="/challenge/notAvailable" component={ChallengeUnavailable} />
          <PrivateRoute path="/challenge/complete" component={ChallengeComplete} />

          {/* Journals routing */}
          <PrivateRoute path="/journals/entry" component={JournalEntry} />
          <PrivateRoute path="/journals" component={Journals} />

          {/* Intentions routing */}
          <PrivateRoute path="/intentions/choose" component={ChooseIntention} />
          <PrivateRoute path="/intentions/card" component={IntentionCard} />
          <PrivateRoute path="/intentions" component={IntentionList} />
          <PrivateRoute path="/intentionSelected" component={IntentionSelected} />

          {/* CV Directory */}
          <PrivateRoute path="/directory" component={Directory} />

          {/* Plotline */}
          <PrivateRoute path="/plotline/:plotlineInjectionID" component={PlotlineTemplate} />

          {/* Family Sharing */}
          <PrivateRoute path="/familyGroups/:groupID" component={FSGroupDetail} />
          <PrivateRoute path="/familyGroups" component={FSGroups} />
          <PrivateRoute path="/familySharing/invite" component={FSInviteForm} />

          <PrivateRoute path="/today/affirmation" component={ChooseAffirmation} />
          <PrivateRoute path="/today" component={Today} />
          <PrivateRoute path="/wellbeing" component={WellbeingOnly} />
          <PrivateRoute path="/coreValues/downloads" component={CoreValueDownloads} />
          <PrivateRoute path="/coreValues/history" component={CoreValueHistory} />
          <PrivateRoute path="/coreValues" component={CoreValues} />
          <PrivateRoute path="/wholeHealth" component={WholeHealth} />

          <PrivateRoute path="/cvdList" component={CVList} />
          <PrivateRoute path="/personalList/:listID" component={PersonalListDetail} />

          {/* Permissions */}
          <PrivateRoute path="/permissions/coreValueDirectory" component={CoreValueDirectoryPermission} />
          <PrivateRoute path="/permissions" component={Permission} />

          <Route path="/error-page" component={ErrorPage} />
          {userAppSettings.isWellbeingOnly ? (
            <PrivateRoute path="/" component={WellbeingOnly} />
          ) :
          !userAppSettings.todayPageEnabled ?
          (
            <PrivateRoute path="/" component={CoreValues} />
          ):
          (
            <PrivateRoute path="/" component={Today} />
          )}
        </Switch>
      </Main>
    </AppWrapper>
  );
};

export default SeityRoute;