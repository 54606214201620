import { FunctionComponent, ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import SeityCVImage from "../../../_core/components/SeityCVImage";

export type AffirmationItemProps = {
  coreValueID: number;
  coreValue: string;
  isSelected: boolean;
  onClickItem: () => void;
};

export const AffirmationItem: FunctionComponent<AffirmationItemProps> = (props): ReactElement => {
  const { isSelected, coreValue, coreValueID, onClickItem } = props;
  const history = useHistory();

  return (
    <div className={`affirmation-item w-100 flex flex-column ${isSelected ? "selected" : ""}`} key={coreValueID} onClick={onClickItem}>
      <div className="flex w-100">
        <SeityCVImage cvId={coreValueID} bgColor={isSelected ? "#f75a3b" : "#939393"} size={56} />
        <img className="ml-auto" src={require("../../../assets/graphics/today/ca-check.png").default} width={24} height={23} />
      </div>
      <h3>{coreValue}</h3>
    </div>
  );
};

export default AffirmationItem;
