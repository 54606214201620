import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { StoryDetail } from "../../../api/story/types/storyTypes";
import { getStoryDetail, putStory } from "../../../api/story/seityHealthAPI-Story";
import { formatImagePath } from "../../../api/utils";
import { IMAGE_URL } from "../../../api/apiConstants";

export interface StoryListState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly storyDetail: StoryDetail;
}

const initStoryDetail = () => {
  return {
    objectiveID: 0,
    title: "",
    subHeader: "",
    author: "",
    topEmotionsImages: [],
    totalEmotionSelectionCount: 0,
    image: "",
    imageAlt: "",
    status: "",
    content: "",
    knowledgeCheckCompleted: false,
    userSelectedEmotions: [],
    journal: null
  };
};

const setInitialState = {
  error: null,
  isLoading: false,
  storyDetail: [initStoryDetail()]
} as unknown as StoryListState;

const storySlice = createSlice({
  name: "storydetail-storydetail",
  initialState: setInitialState,
  reducers: {
    setStoryDetail(state, action: PayloadAction<StoryDetail>) {
      state.storyDetail = action.payload;
      state.isLoading = false;
    },
    setStoryDetailError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    resetStoryDetailSlice: () => setInitialState
  }
});

export const { setStoryDetail, setStoryDetailError, clearApiError, setIsLoading, resetStoryDetailSlice } = storySlice.actions;
export default storySlice.reducer;

export const getStoryDetailRequest =
  (objectiveID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      dispatch(setIsLoading({}));
      const storyDetailResponse = await getStoryDetail(token, objectiveID);
      if (storyDetailResponse.success) {
        const data: any = storyDetailResponse.data;
        data.image = formatImagePath(data.image, "~/images/", IMAGE_URL);
        dispatch(setStoryDetail(data));
        dispatch(setIsLoading({ isLoading: false }));
      }
    } catch (err) {
      console.error(err);
      dispatch(setStoryDetailError({ error: err.toString() }));
    }
  };

export const putStoryRequest =
  (
    storyID: number,
    isCompleted: boolean | null,
    selectedEmotions: Array<{ emotionID: number }> | null,
    callback?: (bool: boolean) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      dispatch(setIsLoading({}));
      const response = await putStory(token, {
        storyID,
        isCompleted,
        selectedEmotions
      });
      if (response.success) {
        dispatch(setIsLoading({ isLoading: false }));
      }
      if (callback) {
        callback(response.success);
      }
    } catch (err) {
      console.error(err);
      dispatch(setStoryDetailError({ error: err.toString() }));
    }
  };
