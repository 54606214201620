/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState, useContext, FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import { apiError, clearApiError, sendForgotUsernameRequest, sendTemporaryPasswordRequest2 } from "../authSlice";
import { SeityButton } from "../../../_core/components/SeityButton";
import { SeityAppContext } from "../../../app/SeityAppContext";
import strings from "../../../_core/strings/strings";

import "./styles.scss";
import { SeityFormTextInput } from "../../../_core/components/SeityTextInput/SeityFormTextInput";
import { SeityFormSelectBox } from "../../../_core/components/SeitySelectBox/SeityFormSelectBox";
import InputMask from "react-input-mask";
import { SeityDatePickerDropdown } from "../../../_core/components/SeityDatePickerDropdown";
import { sendGetPhoneCountryCodes } from "../../register/registerSlice";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";
import isEmail from "validator/es/lib/isEmail";
import { capitalize } from "../../../_core/utils/stringUtils/formats";
import isMobilePhone from "validator/lib/isMobilePhone";
import moment from "moment";

const US_CC_ID = 229;
const DEFAULT_DOB = "01/01/2000";

type ForgotProps = {
  flow: string;
};

export const ForgotPasswordUsername: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appContext = useContext(SeityAppContext);

  const { authError } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { registerError, emailRequired, phoneCountryCodes, current, emailValidationRequired } = useSelector((state: RootState) => {
    return state.register;
  });

  const { pathname, state } = useLocation<ForgotProps>();

  const [email, setEmail] = useState(current?.emailAddress ?? "");
  const [countryCodeID, setCountryCodeID] = useState(0);
  const [cellPhone, setCellPhone] = useState(current?.cellPhone ?? "");
  const [countryCodeFieldDisplay, setCountryCodeFieldDisplay] = useState<string | null>("");
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [validationError, setValidationError] = useState("");
  const [submitDisabled, setSubmitDisable] = useState(true);

  const getCountryCodeById = (phoneCountryCodeID, phoneCountryCodesList) => {
    // Iterate through the array to find the matching ID
    const matchingCode = phoneCountryCodesList.find((code) => code.phoneCountryCodeID === phoneCountryCodeID);

    // If a match is found, return the countryCode, otherwise return a message or null
    return matchingCode ? `+${matchingCode.countryCode}` : null;
  };

  const validatePhone = useCallback(() => {
    if (cellPhone == "" || !isMobilePhone(cellPhone)) {
      setValidationError(strings.cellPhoneInvalid);
      return false;
    }
    return true;
  }, [cellPhone]);

  const validateDOB = useCallback(() => {
    if (!dateOfBirth) {
      setValidationError("DOB invalid");
      return false;
    }
    return true;
  }, [dateOfBirth]);

  const validateEmail = useCallback(() => {
    if (email == "" || !isEmail(email)) {
      setValidationError(strings.emailInvalid);
      return false;
    }
    return true;
  }, [email]);

  const selectCommunication = () => {
    if (validateDOB() && validatePhone() && !validateEmail()) {
      return "sms";
    } else {
      return "email";
    }
  };

  useEffect(() => {
    dispatch(sendGetPhoneCountryCodes());
  }, []);

  useEffect(() => {
    if (phoneCountryCodes) {
      if (current.phoneCountryCodeID) {
        setCountryCodeID(current.phoneCountryCodeID);
        setCountryCodeFieldDisplay(getCountryCodeById(current.phoneCountryCodeID, phoneCountryCodes));
      } else {
        setCountryCodeID(US_CC_ID);
        setCountryCodeFieldDisplay("+1");
      }
    }
  }, [phoneCountryCodes]);

  // Clears any message when leaving the
  // component
  useEffect(() => {
    appContext.setShowNavMenuItems(false);
    appContext.setShowTodo(false);
    appContext.setNavigationMenuButtonText("< Back");
    return () => {
      dispatch(clearApiError({}));

      appContext.setShowNavMenuItems(true);
      appContext.setShowTodo(true);
      appContext.setNavigationMenuButtonText(strings.finishLater);
    };
  }, []);

  const onCountryCodeChange = (setStatusHandler: (statusChange: number) => void, statusChange: number) => {
    setStatusHandler(statusChange);
  };

  const onCellPhoneChanged = (cellPhone: string) => {
    setCellPhone(cellPhone.replace(/[-\s\(\)]/g, ""));
  };

  const onEmailChange = (email) => {
    setEmail(email);
  };

  const redirectToConfirmation = () => {
    history.push({
      pathname: "/confirmation",
      state: { communication: selectCommunication(), flow: state.flow }
    });
  };

  const onFormSubmit = () => {
    if (state.flow == "password") {
      dispatch(sendTemporaryPasswordRequest2(email, cellPhone, moment(dateOfBirth).format("L") ?? DEFAULT_DOB, redirectToConfirmation));
    }
    if (state.flow == "username") {
      dispatch(sendForgotUsernameRequest(email, cellPhone, moment(dateOfBirth).format("L") ?? DEFAULT_DOB, redirectToConfirmation));
    }
  };

  useEffect(() => {
    if ((validateDOB() && validateEmail()) || (validateDOB() && validatePhone())) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [dateOfBirth, email, cellPhone]);

  return (
    <>
      <div className="fp-body">
        <div className="fp-body-top">
          <div className="fp-navigation" id="navigation-arrow">
            <SeityBackButton wrapperClassName="fb-back-button" />
          </div>
          <div className="fp-form-container" id="form">
            <h1>{state.flow == "username" ? strings.authForgotUsername : strings.authForgotPassword }</h1>

            <div className="fp-form-content">
              <div>
                <label className="dob-label-container">
                  <span>{strings.dateOfBirthLabel}</span>
                  <span className="requirement-text">({strings.required})</span>
                </label>
                <SeityDatePickerDropdown retriveDate={setDateOfBirth} date={dateOfBirth} className="placeholder-text" placeholder={strings.dateOfBirthAcronym} />
              </div>

              <div className="form-instruction-text">
                {strings.forgotFormInstruction} <span className="requirement-text">({strings.required})</span>
              </div>
              <div className="email-phone-container">
                <div>
                  <SeityFormTextInput
                    inputLabelText={strings.emailLabel}
                    placeholder={strings.authenticationEmail}
                    controlId="email"
                    type="email"
                    onChange={(e) => {
                      return onEmailChange(e.target.value);
                    }}
                    value={email}
                    isInvalid={false}
                    isRequired={false}
                    formStyle={{ margin: 0 }}
                  />
                </div>
                <div className="divider">
                  <div className="half-divider"></div>
                  <div className="divider-text">OR</div>
                  <div className="half-divider"></div>
                </div>
                <div>
                  <div className="country-code-phone-number">
                    <SeityFormSelectBox
                      inputLabelText={strings.cellPhoneLabel}
                      onChange={(e) => {
                        setCountryCodeFieldDisplay(getCountryCodeById(e.target.value, phoneCountryCodes));
                        return onCountryCodeChange(setCountryCodeID, parseInt(e.target.value));
                      }}
                      value={countryCodeFieldDisplay}
                    >
                      <ul>
                        {phoneCountryCodes?.map((item) => {
                          return (
                            <li key={item.phoneCountryCodeID} value={item.phoneCountryCodeID}>
                              {item.countryName} (+{item.countryCode})
                            </li>
                          );
                        })}
                      </ul>
                    </SeityFormSelectBox>
                    <InputMask
                      mask="(999)-999-9999"
                      onChange={(e) => {
                        return onCellPhoneChanged(e.target.value);
                      }}
                      value={cellPhone}
                    >
                      <SeityFormTextInput inputLabelText={""} controlId="cell-phone" placeholder={strings.authenticationPhoneNumber} />
                    </InputMask>
                  </div>
                </div>
                {authError && <div style={{ color: "red", marginBottom: "1rem" }}>Error: {authError}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="fp-body-bottom">
          <div className="next-button-container" id="next-button">
            <SeityButton
              onClick={onFormSubmit}
              type="next"
              disabled={submitDisabled}
              label={strings.nextButtonText}
              className="next-button"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordUsername;
