import { FunctionComponent, ReactElement, useEffect } from "react";
import PermissionCardItem from "./PermissionCardItem";
import strings from "../../_core/strings/strings";
import "./styles.scss";
import PermissionHeader from "./PermissionHeader";

const Permissions: FunctionComponent = () => {
  const PERMISSION_OPTIONS = [
    {
      icon: "coreValueDirectory.png",
      text: strings.coreValueDirectory,
      route: "/permissions/coreValueDirectory"
    }]

  return (
    <div className="sap-wrapper">
      <PermissionHeader />
      {PERMISSION_OPTIONS.map((item) => (
        <PermissionCardItem key={item.text} text={item.text} icon={item.icon} route={item.route}/>
      ))}
    </div>
  );
}

export default Permissions;

