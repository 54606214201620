import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getOutcomeScores,
  getOutcomeSelection,
  outcomeMarkCompleteRequest,
  outcomeSelectionUpdateRequest,
  practiceMarkCompleteRequest
} from "../../api/assessment/seityHealthAPI-Assessment";
import {
  AssessmentQuestion,
  QuestionGenericUpdate,
  OutcomeSelectionUpdate,
  OutcomeSelection
} from "../../api/assessment/types/questionTypes";
import { SeityAuthenticationError } from "../../api/authTypes";
import { SeityOutcomeCategory } from "../../api/baseTypes";
import { AppThunk } from "../../app/store";
import { setRedirectToLogin } from "../auth/authSlice";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";

export interface OutcomeSelectionState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly questions: AssessmentQuestion[];
  readonly header: string | null;
  readonly energyScore: number;
  readonly directionScore: number;
  readonly joyScore: number;
  readonly belongingScore: number;
  readonly wholeHealthScore: number;
  readonly wholeHealthCategory: string | null;
}

const outcomeSelectionsSlice = createSlice({
  name: "ASSESSMENT_OUTCOMES",
  initialState: {
    error: null,
    isLoading: false,
    level: 0,
    questions: [],
    header: null,
    energyScore: 0,
    directionScore: 0,
    joyScore: 0,
    belongingScore: 0,
    wholeHealthScore: 0,
    wholeHealthCategory: null
  } as OutcomeSelectionState,
  reducers: {
    questionsRequestSuccess(state, action) {
      const { payload } = action.payload;
      state.questions = payload;
      state.isLoading = false;
    },
    questionAnswered(state, action) {
      const { id, answerLevel } = action.payload;
      let question = state.questions.find((question) => question.id === id);
      if (question) {
        question.answerLevel = answerLevel;
      }
    },
    setHeaders(state, action) {
      state.header = action.payload;
    },
    setOutcomesScore(state, action) {
      const { energyScore, directionScore, joyScore, belongingScore, wholeHealthScore } = action.payload;
      state.energyScore = energyScore;
      state.directionScore = directionScore;
      state.joyScore = joyScore;
      state.belongingScore = belongingScore;
      state.wholeHealthScore = wholeHealthScore;
    },
    setOutcomesCategories(state, action) {
      const { wholeHealthCategory } = action.payload;
      state.wholeHealthCategory = wholeHealthCategory;
    },
    apiError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export const {
  questionsRequestSuccess,
  questionAnswered,
  setHeaders,
  setOutcomesScore,
  setOutcomesCategories,
  apiError,
  clearApiError,
  setIsLoading
} = outcomeSelectionsSlice.actions;
export default outcomeSelectionsSlice.reducer;

export const sendOutcomeQuestionsRequest =
  (level: number, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearApiError({}));

      console.log("Getting outcome questions...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const outcomeSelectionResponse = await getOutcomeSelection(token, level);

      const data: any = outcomeSelectionResponse.data;
      const { outcomeSelections } = data;
      const questions = outcomeSelections.map((item: OutcomeSelection) => ({
        id: item.outcomeSelectionID,
        questionTypeId: item.questionTypeID,
        questionText: item.outcomeText,
        answerLevel: item.answerLevel,
        answerSelections: item.answerSelections
      }));

      const payload = { payload: questions };
      const header = outcomeSelections[0].category;

      dispatch(questionsRequestSuccess(payload));
      dispatch(setHeaders(header));
      dispatch(setIsLoading(false));

      console.log("Recieved outcome questions");
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
      throw err;
    }
  };

export const sendOutcomeScoresRequest =
  (callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearApiError({}));

      console.log("Getting outcome scores...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const outcomeScoresResponse = await getOutcomeScores(token);
      if (outcomeScoresResponse.success) {
        const scoresPayload = {
          energyScore: outcomeScoresResponse.data.energy,
          directionScore: outcomeScoresResponse.data.direction,
          joyScore: outcomeScoresResponse.data.joy,
          belongingScore: outcomeScoresResponse.data.belonging,
          wholeHealthScore: outcomeScoresResponse.data.wholeHealth
        };

        const categoriesPayload = {
          wholeHealthCategory: outcomeScoresResponse.data.wholeHealthCategory
        };

        dispatch(setOutcomesScore(scoresPayload));
        dispatch(setOutcomesCategories(categoriesPayload));
        dispatch(setIsLoading(false));

        console.log("Recieved outcome scores");

        if (callback) {
          callback();
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
      throw err;
    }
  };

export const sendOutcomeSelectionUpdateRequest =
  (outcomeSelectionUpdate: OutcomeSelectionUpdate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError({}));

      console.log("Updating outcome questions...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const practiceSelectionUpdateResponse = await outcomeSelectionUpdateRequest(token, outcomeSelectionUpdate);
      if (practiceSelectionUpdateResponse.success) {
        const questionGenericUpdate = new QuestionGenericUpdate(
          outcomeSelectionUpdate.outcomeSelectionID,
          outcomeSelectionUpdate.answerLevel
        );

        dispatch(questionAnswered({ id: questionGenericUpdate.id, answerLevel: questionGenericUpdate.answerLevel }));

        console.log("Update outcome questions complete");
      }
    } catch (err) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendOutcomeMarkCompleteRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    dispatch(setIsLoading(true));
    console.log("Sending outcome mark comeplete request...");

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const outcomeMarkCompleteResponse = await outcomeMarkCompleteRequest(token);
    if (outcomeMarkCompleteResponse.success) {
      console.log("Outcome mark request complete");
    }

    dispatch(setIsLoading(false));
  } catch (err) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

export const outcomeSelectionLevels: number[] = [5, 6, 7, 8];
