import { useDispatch, useSelector } from "react-redux";
import { SeityAuthenticationError } from "../../api/authTypes";
import { getNewToken } from "../../api/seityHealthAPI-Auth";
import { RootState } from "../../app/rootReducer";

export const TOKEN_KEY = "token";
export const REFRESH_KEY = "refresh";
export const EXPIRATION_KEY = "expiration";

export default function useSessionStorageToken() {
  const dispatch = useDispatch();
  const { token, refreshKey, expirationTime } = useSelector((state: RootState) => state.auth);

  const saveToken = async (token, expirationTime, refreshKey) => {
    cacheToken(token);
    saveRefreshKey(refreshKey);
    saveExpiration(expirationTime);

    dispatch({
      type: 'auth#setTokens',
      payload: { token, expirationTime, refreshKey }
    });
  };

  const refreshAndSaveToken = async () => {
    if (!token || await isTokenExpired()) {
      const { token: newToken, refreshKey: newRefreshKey, expirationTime: newExpirationTime } = await getRefreshedToken();
      await saveToken(newToken, newExpirationTime, newRefreshKey);
      return { token: newToken, refreshKey: newRefreshKey, expirationTime: newExpirationTime };
    }
    return { token, refreshKey, expirationTime };
  };

  return refreshAndSaveToken;
}

// Token Management Utilities
export const getRefreshedToken = async (token: string | null = null, refreshKey: string | null = null): Promise<{ token: string|null, refreshKey: string|null, expirationTime: number|null }> => {
  token = token ?? await getToken();
  refreshKey = refreshKey ?? await getRefreshKey();

  if (!await isTokenExpired()) {
    const expirationTime = await getExpirationTime();
    return { token, refreshKey, expirationTime };
  }

  console.log("Attempting to refresh token...");
  return await refreshAuthToken();
};

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const getExpirationTime = () => {
  const expiration = sessionStorage.getItem(EXPIRATION_KEY);
  return expiration ? parseInt(expiration, 10) : null;
};

export const getRefreshKey = () => sessionStorage.getItem(REFRESH_KEY);

export const cacheToken = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const saveExpiration = (expirationTime) => {
  sessionStorage.setItem(EXPIRATION_KEY, expirationTime.toString());
};

export const saveRefreshKey = (refreshKey) => {
  sessionStorage.setItem(REFRESH_KEY, refreshKey);
};

export const isTokenExpired = async () => {
  const expirationTime = await getExpirationTime();
  if (!expirationTime) {
    return true;
  }
  const expirationDate = new Date(expirationTime * 1000);
  return new Date() > expirationDate;
};

export const refreshAuthToken = async () => {
  const refreshKey = await getRefreshKey();
  const token = await getToken();
  if (!refreshKey || !token) {
    return {
      token: null,
      refreshKey: null,
      expirationTime: null
    };
  }
  try {
    const response = await getNewToken(refreshKey, token);
    if (!response.success || !response.data || !response.data.token || !response.data.refreshKey) {
      return {
        token: null,
        refreshKey: null,
        expirationTime: null
      };
    }
    cacheToken(response.data.token);
    saveRefreshKey(response.data.refreshKey);
    saveExpiration(response.data.expirationTime);
    return {
      token: response.data.token,
      refreshKey: response.data.refreshKey,
      expirationTime: response.data.expirationTime
    };
  } catch (err:any) {
    console.error('Error refreshing token:', err);
    throw new SeityAuthenticationError();
  }
};

export const removeTokens = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_KEY);
  sessionStorage.removeItem(EXPIRATION_KEY);
};
