/* eslint-disable jsx-a11y/alt-text */
import React, { FunctionComponent, ReactElement } from "react";
import { useLocation, useHistory } from "react-router-dom";

import BackCaret from "../../../assets/graphics/back_btn.png";
import { SeityBackButtonProps } from "./types";
import { HIDE_HEADER_ROUTES, HIDE_BACK_BUTTON } from "../../../consts";

import "./styles.scss";

export const SeityBackButton: FunctionComponent<SeityBackButtonProps> = (props): ReactElement | null => {
  const location = useLocation();
  const history = useHistory();

  const goBackonCaretClick = () => {
    history.goBack();
  };

  if (HIDE_BACK_BUTTON.includes(location.pathname)) {
    return null;
  }

  return (
    <button
      className={`back-button-container ${HIDE_HEADER_ROUTES.includes(location.pathname) ? "" : "back-button-container-header"} ${
        props.wrapperClassName || ""
      }`}
      onClick={goBackonCaretClick}
    >
      <img src={BackCaret} />
    </button>
  );
};
