import { FunctionComponent, ReactElement } from "react";
import Modal from "react-modal";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";

import animationData from "./completed.json";
import strings from "../../../_core/strings/strings";

import "./styles.scss";
import { RootState } from "../../../app/rootReducer";

type CompleteSuccessModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const CompleteSuccessModal: FunctionComponent<CompleteSuccessModalProps> = ({ isOpen, onClose }): ReactElement => {
  const { completeMsg } = useSelector((state: RootState) => {
    return state.intentions;
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  Modal.setAppElement("#root");

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="intention-complete-modal" overlayClassName="intention-complete-modal-overlay">
      <Lottie options={defaultOptions} height={262} width={262} />
      <div className="mt-xl flex-col flex-ai-c">
        <h3>{completeMsg ? completeMsg.header : strings.completeSuccessHeader}</h3>
        <p className="intention-complete-modal__title">{completeMsg ? completeMsg.contentText : strings.completeSuccessDesc}</p>
      </div>
    </Modal>
  );
};

export default CompleteSuccessModal;
