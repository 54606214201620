import { FunctionComponent, ReactElement } from "react";

export type DragonItemProps = {
  image: string;
  heading: string;
  description: string;
  detail: string;
  className: string;
};

export const DragonItem: FunctionComponent<DragonItemProps> = ({ image, heading, description, detail, className }): ReactElement => {
  return (
    <div className={`dragon-item-learn ${className}`}>
      <div className="dragon-header">
        <img src={image} alt="Dragon icon" />
        <div>
          <span className="dragon-header-title">{heading}</span>
          <span className="dragon-header-description">{description}</span>
        </div>
      </div>
      <span className="dragon-item-learn-detail">{detail}</span>
    </div>
  );
};
