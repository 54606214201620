import { SeityResponse } from "../../api/baseTypes";
import { AnswerSelection } from "../assessment/types/questionTypes";
/**
 * Challenge State
 */
export class SeityChallengeStateResponse extends SeityResponse {
  data?: SeityChallengeState;

  constructor(success: boolean, message: string | null, data?: SeityChallengeState) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeState {
  objectiveID: number;
  objectiveChallengeID: number;
  challengeState: SeityChallengeStateIndex;
  objectiveTypeID: number;

  constructor(objectiveID: number, objectiveChallengeID: number, challengeState: SeityChallengeStateIndex, objectiveTypeID: number) {
    this.objectiveID = objectiveID;
    this.objectiveChallengeID = objectiveChallengeID;
    this.challengeState = challengeState;
    this.objectiveTypeID = objectiveTypeID;
  }
}

export enum SeityChallengeStateIndex {
  NOT_ACTIVE = 0,
  BONUS = 1,
  CONTINUE_CHALLENGE = 2,
  START = 3,
  FUTURE = 4
}
/**
 * Challenge Introduction
 */
export class SeityChallengeIntroductionResponse extends SeityResponse {
  data?: SeityChallengeIntroduction;

  constructor(success: boolean, message: string | null, data?: SeityChallengeIntroduction) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeIntroduction {
  objectiveID: number;
  header: string;
  startIntroduction: string;
  pictureFileName: string;
  videoFileName: string;

  constructor(objectiveID: number, header: string, startIntroduction: string, pictureFileName: string, videoFileName: string) {
    this.objectiveID = objectiveID;
    this.header = header;
    this.startIntroduction = startIntroduction;
    this.pictureFileName = pictureFileName;
    this.videoFileName = videoFileName;
  }
}
/**
 * Challenge Content
 */
export class SeityChallengeContentListResponse extends SeityResponse {
  data?: SeityChallengeContentList;

  constructor(success: boolean, message: string | null, data?: SeityChallengeContentList) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeContentList {
  questionID: number;
  questionText: string;
  questionTypeID: number;
  answerGroupID: number;
  responseID: number;
  selectedAnswerListID: number;
  answerList: SeityChallengeAnswerItem[];
  contentList: SeityChallengeContentItem[];
  answerSelections: AnswerSelection[];

  constructor(
    questionID: number,
    questionText: string,
    questionTypeID: number,
    answerGroupID: number,
    responseID: number,
    selectedAnswerListID: number,
    answerList: SeityChallengeAnswerItem[],
    contentList: SeityChallengeContentItem[],
    answerSelections: AnswerSelection[]
  ) {
    this.questionID = questionID;
    this.questionText = questionText;
    this.questionTypeID = questionTypeID;
    this.answerGroupID = answerGroupID;
    this.responseID = responseID;
    this.selectedAnswerListID = selectedAnswerListID;
    this.answerList = answerList;
    this.contentList = contentList;
    this.answerSelections = answerSelections;
  }
}

export class SeityChallengeContentItem {
  sequenceNumber: number;
  indent: number;
  bulletPoint: boolean;
  blankLinesAfer: number;
  content: string;
  header: string;
  subHeader: string;
  pictureFileName: string;
  videoFileName: string;
  objectiveTrackingID: number;
  beginDate: string;
  completionDate: string;
  updateDays: number;
  waitComplete: boolean;
  questionID: number;
  printPDFButtonText: string;
  printPDFFileName: string;

  constructor(
    sequenceNumber: number,
    indent: number,
    bulletPoint: boolean,
    blankLinesAfer: number,
    content: string,
    header: string,
    subHeader: string,
    pictureFileName: string,
    videoFileName: string,
    objectiveTrackingID: number,
    beginDate: string,
    completionDate: string,
    updateDays: number,
    waitComplete: boolean,
    questionID: number,
    printPDFButtonText: string,
    printPDFFileName: string
  ) {
    this.sequenceNumber = sequenceNumber;
    this.indent = indent;
    this.bulletPoint = bulletPoint;
    this.blankLinesAfer = blankLinesAfer;
    this.content = content;
    this.header = header;
    this.subHeader = subHeader;
    this.pictureFileName = pictureFileName;
    this.videoFileName = videoFileName;
    this.objectiveTrackingID = objectiveTrackingID;
    this.beginDate = beginDate;
    this.completionDate = completionDate;
    this.updateDays = updateDays;
    this.waitComplete = waitComplete;
    this.questionID = questionID;
    this.printPDFButtonText = printPDFButtonText;
    this.printPDFFileName = printPDFFileName;
  }
}

export class SeityChallengeAnswerItem {
  answerListID: number;
  answerText: string;

  constructor(answerListID: number, answerText: string) {
    this.answerListID = answerListID;
    this.answerText = answerText;
  }
}

/**
 * Challenge Start
 */
export class SeityChallengeStartResponse extends SeityResponse {
  data?: SeityChallengeStart;

  constructor(success: boolean, message: string | null, data?: SeityChallengeStart) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeStart {
  objectiveID: number;

  constructor(objectiveID: number) {
    this.objectiveID = objectiveID;
  }
}

/**
 * Challenge Next Step
 */
export class SeityChallengeNextStepResponse extends SeityResponse {
  data?: SeityChallengeNextStep;

  constructor(success: boolean, message: string | null, data?: SeityChallengeNextStep) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeNextStep {
  objectiveID: number;

  constructor(objectiveID: number) {
    this.objectiveID = objectiveID;
  }
}

export class SeityChallengeCompleteResponse extends SeityResponse {
  data?: SeityChallengeComplete;

  constructor(success: boolean, message: string | null, data?: SeityChallengeComplete) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeComplete {
  objectiveChallengeID: number;
  header: string;
  line01: string;
  line02: string;
  line03: string;

  constructor(objectiveChallengeID: number, header: string, line01: string, line02: string, line03: string) {
    this.objectiveChallengeID = objectiveChallengeID;
    this.header = header;
    this.line01 = line01;
    this.line02 = line02;
    this.line03 = line03;
  }
}

export class SeityChallengeUpdateQuestionResponse extends SeityResponse {
  data?: SeityChallengeUpdateQuestion;

  constructor(success: boolean, message: string | null, data?: SeityChallengeUpdateQuestion) {
    super(success, message);
    this.data = data;
  }
}

export class SeityChallengeUpdateQuestion {
  result: boolean;

  constructor(result: boolean) {
    this.result = result;
  }
}
