import classnames from "classnames";
import { FunctionComponent, ReactElement, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CoreValue } from "../../../../api/referenceGuide/referenceGuideTypes";
import { RootState } from "../../../../app/rootReducer";
import SeityLoader from "../../../../_core/components/SeityLoader";
import SeityModal from "../../../../_core/components/SeityModal";
import { SeityTextInput } from "../../../../_core/components/SeityTextInput/SeityTextInput";
import SeityCVImage from "../../../../_core/components/SeityCVImage";

import strings from "../../../../_core/strings/strings";
import { alphabet } from "./data";

import "./styles.scss";
import { CoreValuesListProps } from "./types";
import SeityButton from "../../../../_core/components/SeityButton";
import { AlphabeticalSeekBar } from "./AlphabeticalSeekBar";

export const CoreValuesList: FunctionComponent<CoreValuesListProps> = ({
  filtersMode,
  filtersCloseFunc,
  setFiltersList,
  filtersList,
  containerId
}): ReactElement => {
  const { isLoading, coreValues } = useSelector((state: RootState) => state.guide);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeChar, setActiveChar] = useState<string>("");
  const [activeCoreValue, setActiveCoreValue] = useState<CoreValue>();
  const [index, setIndex] = useState<number>(0);
  const [selectedCVs, setSelectedCVs] = useState<Array<number>>(filtersList ?? []);

  const groupedValues = useMemo(() => {
    if (coreValues) {
      let v = [];
      const searchedCoreValues = coreValues.filter((val) => val.coreValue.toLowerCase().startsWith(searchValue.toLowerCase()));
      alphabet.forEach((char) => {
        let arr = searchedCoreValues.filter((val) => val.coreValue.startsWith(char));
        arr = arr.sort((a, b) => a.coreValue.localeCompare(b.coreValue));
        v[char] = arr;
      });
      return v;
    }
    return [];
  }, [coreValues, searchValue]);

  const toggleSelect = (id) => {
    if (selectedCVs.includes(id)) {
      // Deselect
      const index = selectedCVs.indexOf(id);
      let tempArr = [...selectedCVs];
      tempArr.splice(index, 1);
      setSelectedCVs(tempArr);
    } else {
      setSelectedCVs([...selectedCVs, id]);
    }
  };

  const showCoreValueModal = (value, coreValues) => {
    setActiveCoreValue(value);
    setIndex(coreValues.findIndex((el) => el.coreValueID === value.coreValueID));
    setShowModal(true);
  };

  if (isLoading || !coreValues) return <SeityLoader />;
  return (
    <div className="core-values-container">
      <div className={filtersMode ? "sticky-header-2" : "sticky-header"}>
        {filtersMode && (
          <div className="filters-header">
            <p>{strings.filters}</p>
            <button className="ml-auto mb-xl" onClick={filtersCloseFunc}>
              <img src={require("../../../../assets/graphics/today/close.png").default} width={24} height={24} />
            </button>
          </div>
        )}
        <SeityTextInput
          type="text"
          className="search-input"
          containerClassname="search-input-container"
          leftIconClassname="search-input-icon"
          onChange={(e) => {
            return setSearchValue(e.target.value);
          }}
          value={searchValue}
          leftIcon={require("../../../../assets/icons/search.png").default}
          placeholder={filtersMode ? "Search" : "Core Value name"}
        />
        <AlphabeticalSeekBar
          activeChar={activeChar}
          setActiveChar={setActiveChar}
          groupedValues={groupedValues}
          containerId={containerId}
          categorySuffix="core-values-list"
        />

        {!filtersMode && <div className="top-devider" />}
      </div>

      <div className={"core-values-list" + (filtersMode ? " padding-horizontal" : "")}>
        {alphabet.map((char, index) => {
          return (
            !!groupedValues[char].length && (
              <div id={`${char}-core-values-list`} key={`val-${index}`} className="core-values-list-char-group">
                <h2>{char}</h2>
                <div className="char-devider" />
                {groupedValues[char].map((value, chldIdx) => {
                  return (
                    <div
                      key={`child-${chldIdx}`}
                      onClick={() => {
                        filtersMode ? toggleSelect(value.coreValueID) : showCoreValueModal(value, coreValues);
                      }}
                      className={selectedCVs.includes(value.coreValueID) ? "core-values-list-item selected" : "core-values-list-item"}
                    >
                      <SeityCVImage cvId={value.coreValueID} size={40} />
                      <h4>{value.coreValue}</h4>
                    </div>
                  );
                })}
              </div>
            )
          );
        })}
      </div>

      {filtersMode && (
        <div className="filters-ctas">
          <div className="button-container">
            <SeityButton label={strings.clearAll} type="cancel" onClick={() => setSelectedCVs([])} />
            <SeityButton
              label={strings.doneButtonTitle}
              type="next"
              onClick={() => {
                //@ts-ignore
                setFiltersList(selectedCVs);
                //@ts-ignore
                filtersCloseFunc();
              }}
              style={{ width: "8.5rem" }}
            />
          </div>
        </div>
      )}

      <SeityModal
        wrapperClassName="core-value-modal-wrapper"
        isOpen={showModal}
        onClose={() => {
          return setShowModal(false);
        }}
        showCloseBtn
      >
        <>
          <img
            className={classnames("core-value-modal-button", "core-value-modal-button-prev", {
              "core-value-modal-button-disabled": index == 0
            })}
            onClick={() => {
              setActiveCoreValue(coreValues[index - 1]);
              setIndex(index - 1);
            }}
            src={require("../../../../assets/graphics/back-teal-arrow.png").default}
          />
          <img
            className={classnames("core-value-modal-button", "core-value-modal-button-next", {
              "core-value-modal-button-disabled": index == coreValues.length - 1
            })}
            onClick={() => {
              setActiveCoreValue(coreValues[index + 1]);
              setIndex(index + 1);
            }}
            src={require("../../../../assets/graphics/forward-teal-arrow.png").default}
          />
          {activeCoreValue && (
            <div className="core-value-modal">
              <div className="core-value-modal-video">
                {activeCoreValue?.video ? (
                  <video autoPlay loop muted controls key={activeCoreValue.video}>
                    <source src={activeCoreValue.video} type="video/mp4" />
                  </video>
                ) : null}
              </div>
              <div className="core-value-modal-desc">
                <div className="core-value-modal-desc-header">
                  <SeityCVImage classname="cv-img" size={48} cvId={activeCoreValue.coreValueID} />
                  <h1>{activeCoreValue.coreValue}</h1>
                </div>
                <h4>{strings.definition}</h4>
                <p>{activeCoreValue.definition}</p>
                <div className="core-value-modal-desc-divider" />
                <h4>{strings.explanation}</h4>
                <p>{activeCoreValue.description}</p>
                <h4>{strings.quote}</h4>
                <p>{activeCoreValue.quote}</p>
              </div>
            </div>
          )}
        </>
      </SeityModal>
    </div>
  );
};
