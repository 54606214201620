import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SeityAuthenticationError } from "../../api/authTypes";
import { AppThunk } from "../../app/store";
import { setRedirectToLogin } from "../auth/authSlice";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";
import { getSurveyWizardQuestions, sendSurveyWizardSelectionUpdateRequest } from "../../api/surveyWizard/seityHealthAPI-SurveyWizard";
import { QuestionAndAnswers, SurveyWizardSelectionUpdate } from "../../api/surveyWizard/types/surveyWizardTypes";

export interface SurveyState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly title: string | null;
  readonly questionAndAnswers: QuestionAndAnswers[];
  readonly answeredSurveySelections: number[];
}

const SurveySlice = createSlice({
  name: "survey",
  initialState: {
    error: null,
    isLoading: false,
    title: null,
    questionAndAnswers: [],
    answeredSurveySelections: []
  } as SurveyState,
  reducers: {
    setQuestionsAndAnswers(state, action: PayloadAction<QuestionAndAnswers[]>) {
      state.questionAndAnswers = action.payload;
      state.isLoading = false;
    },
    pushToAnsweredSurveySelections(state, action: PayloadAction<number>) {
      if (state.answeredSurveySelections.indexOf(action.payload) === -1) {
        state.answeredSurveySelections.push(action.payload);
      }
    },
    setQuestionAnswered(state, action) {
      const { id, answerLevel } = action.payload;
      let question = state.questionAndAnswers.find((question) => question.surveyWizardSelectionID === id);
      if (question) {
        question.selectedAnswerLevel = answerLevel;
      }
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    clearQuestions(state) {
      state.isLoading = false;
      state.answeredSurveySelections = [];
      state.questionAndAnswers = [];
    },
    apiError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state) {
      state.isLoading = true;
    }
  }
});

export const {
  setQuestionsAndAnswers,
  setTitle,
  pushToAnsweredSurveySelections,
  setQuestionAnswered,
  clearQuestions,
  apiError,
  clearApiError,
  setIsLoading
} = SurveySlice.actions;
export default SurveySlice.reducer;

export const sendSurveyWizardQuestionsRequest =
  (surveyWizardTrackingID: number, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(clearApiError());

      console.log("Getting survey wizard questions...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const surveySelectionResponse = await getSurveyWizardQuestions(token, surveyWizardTrackingID);

      dispatch(setQuestionsAndAnswers(surveySelectionResponse.data.questionsAndAnswers));
      dispatch(setTitle(surveySelectionResponse.data.title));
      console.log("got survey wizard questions");

      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
      throw err;
    }
  };

export const sendSurveyWizardUpdateRequest =
  (surveySelectionUpdate: SurveyWizardSelectionUpdate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError());

      console.log("Updating survey wizard question...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const surveySelectionUpdateResponse = await sendSurveyWizardSelectionUpdateRequest(token, surveySelectionUpdate);

      if (surveySelectionUpdateResponse.success) {
        console.log("Update survey wizard questions complete");
      } else {
        dispatch(apiError({ error: "Error updating survey selection" })); // put in strings.ts
        console.log("Error updating survey selection. \nMessage: " + surveySelectionUpdateResponse.message);
      }
    } catch (err) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const clearSurveyQuestions = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError());

    dispatch(setIsLoading());

    dispatch(clearQuestions());
  } catch (err) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

export const surveySelectionLevels: number[] = [5, 6, 7, 8];
