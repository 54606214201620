import { useMemo } from "react";
import { useSelector } from "react-redux";
import { SeityAccountListsItemResponse, SeityAccountListType } from "../api/account/types/maintenanceTypes";
import { RootState } from "../app/rootReducer";

export const useAccountLists = () => {
  const { accountLists } = useSelector((state: RootState) => state.account);

  return useMemo(() => {
    let occupationList: SeityAccountListsItemResponse[] = [];
    let maritalList: SeityAccountListsItemResponse[] = [];
    let genderList: SeityAccountListsItemResponse[] = [];
    let accountStatusList: SeityAccountListsItemResponse[] = [];
    let statusList: SeityAccountListsItemResponse[] = [];
    let educationList: SeityAccountListsItemResponse[] = [];

    accountLists?.forEach((list) => {
      switch (list.accountListsType) {
        case SeityAccountListType.OCCUPATION:
          occupationList.push(list);
          break;
        case SeityAccountListType.MARITAL_STATUS:
          maritalList.push(list);
          break;
        case SeityAccountListType.GENDER:
          genderList.push(list);
          break;
        case SeityAccountListType.ACCOUNT_STATUS:
          accountStatusList.push(list);
          break;
        case SeityAccountListType.STATUS:
          statusList.push(list);
          break;
        case SeityAccountListType.EDUCATION:
          educationList.push(list);
          break;
        default:
          break;
      }
    });

    return {
      occupationList,
      maritalList,
      genderList,
      accountStatusList,
      statusList,
      educationList
    };
  }, [accountLists]);
};
