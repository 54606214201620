/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { DragonItem } from "./DragonItem";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";
import strings from "../../../_core/strings/strings";
import "./styles.scss";

export const DragonLearnMore: FunctionComponent = (): ReactElement => {
  const history = useHistory();

  return (
    <div className="dragon-container">
      <img
        className="dragon-container-back-caret"
        src={require("../../../assets/graphics/back_btn.png").default}
        alt="back caret"
        onClick={() => {
          return history.goBack();
        }}
      />
      <div className="dragon-wrapper-learn">
        <h1>{strings.tameYourDragons}</h1>
        <h4>{strings.tameYourDragonsDescription}</h4>
        <div className="dragon-sections-container">
          <DragonItem
            image={RequireSeityImage("dragonraging01").default}
            heading={strings.ragingDragon}
            description={` ${strings.lowScore} (0 - 49%)`}
            detail={strings.dragonRagingDesc}
            className="ragin-dragon"
          />
          <DragonItem
            image={RequireSeityImage("dragonawake01").default}
            heading={strings.awakeDragon}
            description={` ${strings.midScore} (50-69%)`}
            detail={strings.dragonAwakeDesc}
            className="awake-dragon"
          />
          <DragonItem
            image={RequireSeityImage("dragontame02").default}
            heading={strings.tameDragon}
            description={`${strings.highSCore} (70-100%)`}
            detail={strings.dragonTameDesc}
            className="tame-dragon"
          />
        </div>
      </div>
    </div>
  );
};
