import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ASSET1_IMG_URL } from "../../../../../api/apiConstants";

import "./styles.scss";
import SeityButton from "../../../../../_core/components/SeityButton";
import strings from "../../../../../_core/strings/strings";
import { useDispatch, useSelector } from "react-redux";
import { sendGetTeachSeityAssetDetail } from "../../guideSlice";
import { RootState } from "../../../../../app/rootReducer";
import SeityLoader from "../../../../../_core/components/SeityLoader";
import { TeachSeityAsset } from "../../../../../api/referenceGuide/referenceGuideTypes";
import { useWindowSize } from "../../../../../hooks/Layout";

type paramTypes = {
  assetID: string;
};

export const TSAssetDetail: FunctionComponent = () => {
  const { assetID } = useParams<paramTypes>();
  const dispatch = useDispatch();

  const [assetData, setAssetData] = useState<TeachSeityAsset>();
  const [imgHeight, setImgHeight] = useState(488);
  const [isLoading, setIsLoading] = useState(true);

  const windowSize = useWindowSize();

  useEffect(() => {
    dispatch(
      sendGetTeachSeityAssetDetail(parseInt(assetID), (data) => {
        setAssetData(data);
        setIsLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    if (isLoading) return;
    const img = new Image();
    img.src = `${ASSET1_IMG_URL + assetData?.imageName}`;
    img.onload = () => {
      if (windowSize.width > 0) {
        const height = img.height * (windowSize.width / img.width);
        setImgHeight(height > 326 ? 326 : height);
      }
    };
  }, [windowSize, isLoading]);

  if (isLoading) {
    return <SeityLoader />;
  }

  return (
    <div className="ts-detail">
      {imgHeight && <img src={`${ASSET1_IMG_URL + assetData?.imageName}`} alt="TeachSeity Cover Image" height={imgHeight} />}
      <div className="ts-detail__content flex flex-column">
        <h1>{assetData?.name}</h1>
        <SeityButton
          label={strings.startTeaching}
          onClick={() => {
            window.open(assetData?.url, "_blank");
          }}
          type="next"
          className="mt-xl"
        />
        <h3 className="mt-xl">{strings.summary}</h3>
        <div>{assetData?.description}</div>
      </div>
    </div>
  );
};
