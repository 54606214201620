import { combineReducers } from "@reduxjs/toolkit";
import assessmentProgressSlice from "./assessmentProgressSlice";
import coreValueCategorySlice from "./coreValuesSlice";
import outcomeSelectionsSlice from "./outcomeSelectionsSlice";
import practiceSelectionsSlice from "./practiceSelectionsSlice";

export default combineReducers({
  coreValues: coreValueCategorySlice,
  outcomes: outcomeSelectionsSlice,
  assessmentProgress: assessmentProgressSlice,
  practiceSelections: practiceSelectionsSlice
});
