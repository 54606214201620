import { createSlice } from "@reduxjs/toolkit";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { getLearnSeityByCategoryParent, getPost, getPostByCategory, getSeityCategories } from "../../../api/learn/seityHealthAPI-Learn";
import { LearnSeityItem, LearnSeityListItem, SeityCategory } from "../../../api/learn/types/learnTypes";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";

export interface PostState {
  readonly isLoading: boolean;
  readonly postError: string | null;
  readonly post: LearnSeityItem | null;
  readonly seityCategories: SeityCategory[];
  readonly learnSeityCategoriesByParent: SeityCategory[];
  readonly postByCategory: LearnSeityListItem[];
}

const PostSlice = createSlice({
  name: "learn",
  initialState: {
    isLoading: false,
    postError: null,
    post: null,
    seityCategories: [],
    postByCategory: [],
    learnSeityCategoriesByParent: []
  } as PostState,
  reducers: {
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setPostError(state, action) {
      state.postError = action.payload.postError;
      state.isLoading = false;
    },
    setPost(state, action) {
      state.post = action.payload;
      state.isLoading = false;
    },
    setSeityCategories(state, action) {
      state.seityCategories = action.payload;
      state.isLoading = false;
    },
    setSeityCategoriesByParent(state, action) {
      state.learnSeityCategoriesByParent = action.payload;
      state.isLoading = false;
    },
    setPostByCategory(state, action) {
      state.postByCategory = action.payload;
      state.isLoading = false;
    }
  }
});

export const { setIsLoading, setPostError, setPost, setSeityCategories, setPostByCategory, setSeityCategoriesByParent } = PostSlice.actions;

export default PostSlice.reducer;

export const sendGetPost = (cmsID: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getPost(cmsID, token);

    dispatch(setPost(response.data));
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetSeityCategoriesRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getSeityCategories(token);

    dispatch(setSeityCategories(response.data));
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetPostByCategoryRequest = (category: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getPostByCategory(category, token);
    dispatch(setPostByCategory(response.data));
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetSeityCategoriesByParentRequest = (parentCategoryID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getLearnSeityByCategoryParent(parentCategoryID, token);
    dispatch(setSeityCategoriesByParent(response.data));
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};
function handleError(err: Error, dispatch: any) {
  console.error(err);
  dispatch(setPostError({ postError: err.toString() }));
}
