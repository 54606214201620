import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/rootReducer";
import { getAppContentRequest } from "../features/policy/appContentSlice";

export function useAppContent(appContentTypeID: number) {
  const { appContentLines } = useSelector((state: RootState) => state.appContent);

  const dispatch = useDispatch();

  function printContentText(lineNumber: number) {
    return appContentLines[lineNumber]?.contentText || "";
  }

  useEffect(() => {
    dispatch(getAppContentRequest(appContentTypeID));
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    appContentLines,
    printContentText
  };
}
