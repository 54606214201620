import { Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { RootState } from "./rootReducer";

import history from "./App.history";
import SeityAppContextProvider from "./SeityAppContext";

import strings from "../_core/strings/strings";
import "./App.scss";

import { clearCommonError } from "../slices/commonAlertSlice";
import SeityAlert from "../_core/components/SeityAlert";
import SeityRoute from "./SeityRoute";

export const CommonAlertProvider: React.FC = ({ children }) => {
  const error = useSelector((state: RootState) => state.commonAlertSlice.error);
  const dispatch = useDispatch();
  console.log("error:", error);
  return (
    <>
      <SeityAlert
        title={strings.oops}
        visible={error !== null}
        onToggle={() => {
          dispatch(clearCommonError());
        }}
        subTitle={error ?? ""}
      />
      {children}
    </>
  );
};

function App() {
  return (
    <Router history={history}>
      <SeityAppContextProvider>
        {/* Enable it when the common api error message needs to appear as modal instead of toast */}
        {/* <CommonAlertProvider> */}
        <SeityRoute />
        {/* </CommonAlertProvider> */}
      </SeityAppContextProvider>
    </Router>
  );
}

export default App;
