import { FunctionComponent, useEffect, useState } from "react";
import "./styles.scss";
import strings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";
import { WellbeingProgressBar } from "../WellbeingProgressBar";
import { Wellbeing } from "../types";
import { WellbeingInfoModal } from "../WellbeingInfoModal";
import SeityModal from "../../../_core/components/SeityModal";
import { useHistory } from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/rootReducer";

type LatestCheckinProps = {
  wellbeing: Wellbeing;
  checkInDue: boolean;
};

export const LatestCheckin: FunctionComponent<LatestCheckinProps> = ({ wellbeing, checkInDue }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const history = useHistory();

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const handleClose = () => {
    setShowInfoModal(false);
  };

  const markers = [
    {
      img: "energy",
      label: strings.itemTitleEnergy,
      level: wellbeing?.energyScore
    },
    {
      img: "direction",
      label: strings.itemTitleDirection,
      level: wellbeing?.directionScore
    },
    {
      img: "belonging",
      label: strings.itemTitleBelonging,
      level: wellbeing?.belongingScore
    },
    {
      img: "joy",
      label: strings.itemTitleJoy,
      level: wellbeing?.joyScore
    }
  ];
  return (
    <>
      <div className="latest-check-in-container">
        <div className="content">
          <div className="heading">
            <h3>{wellbeing?.lastCheckInDate ? strings.latestCheckIn : strings.checkInNowWellbeing}</h3>
            <img
              className="more-info"
              src={require("../../../assets/graphics/more-info.png").default}
              onClick={() => setShowInfoModal(true)}
            />
          </div>
          {wellbeing?.lastCheckInDate && <h5>{wellbeing?.lastCheckInDate}</h5>}
          {(checkInDue || userAppSettings.multipleWellbeingCheckInEnabled) && (
            <SeityButton
              type={checkInDue ? 'button' : 'outline'}
              label={checkInDue ? strings.checkInNow : strings.checkInAgain}
              className="check-in-button"
              onClick={() => history.push("/checkin/wellbeingCheckIn")}
            />
          )}
        </div>
        <WellbeingProgressBar progressBarPercentage={wellbeing?.wellbeingScore} />
        <div className="details">
          {markers.map((item, index) => (
            <div key={index}>
              <img src={require(`../../../assets/graphics/wellbeing/${item.img}.svg`).default} />
              <p className="label">{item.label}</p>
              <p className="level">{`${item.level}%`}</p>
            </div>
          ))}
        </div>
      </div>
      <SeityModal isOpen={showInfoModal}>
        <WellbeingInfoModal handleClose={handleClose} />
      </SeityModal>
    </>
  );
};
