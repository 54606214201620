import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import SeityButton from "../../../../_core/components/SeityButton";
import strings from "../../../../_core/strings/strings";

import "../styles.scss";

export interface NoAssessmentProps {
  assessmentStarted: boolean;
}

export const NoAssessment: FunctionComponent<NoAssessmentProps> = ({ assessmentStarted }) => {
  const history = useHistory();

  const sendToAssessment = () => {
    history.push("/assessment/intro");
  };

  return (
    <div className="na-container">
      <div className="na-no-assessment-wrapper">
        <h1>{strings.wholeHealthAssessment}</h1>
        <p>{strings.NoAssessmentParagraph}</p>
        <SeityButton
          className="na-no-assessment-wrapper-button"
          label={assessmentStarted ? strings.continueAssessment : strings.startAssessment}
          onClick={sendToAssessment}
        />
      </div>
    </div>
  );
};
