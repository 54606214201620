import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { RootState } from "../../../app/rootReducer";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";

import { getOutcomeScoresRequest } from "../outcomesSlice";
import { getCharacterizationRequest } from "../../myStory/myStorySlice";

import SeityProgressBar from "../../../_core/components/SeityProgressBar";
import SeityLoader from "../../../_core/components/SeityLoader";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";

import { MyOutcomesProps } from "./types";
import strings from "../../../_core/strings/strings";
import "./styles.scss";

export const MyOutcomes: FunctionComponent<MyOutcomesProps> = () => {
  const dispatch = useDispatch();

  const { outcomeScores } = useSelector((state: RootState) => {
    return state.outcomeScores;
  });

  const { characterization, isLoading } = useSelector((state: RootState) => {
    return state.characterization;
  });

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    dispatch(getOutcomeScoresRequest());
    dispatch(getCharacterizationRequest());
  }, []);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  const logoImage = RequireSeityImage(undefined !== "wholehealtha" ? "wholehealtha" : "").default;

  // @ts-ignore
  const { wholeHealthDescriptionText } = characterization;
  // @ts-ignore
  const { wholeHealthDragonText } = characterization;

  if (isLoading) {
    return <SeityLoader showBackgroundMask />;
  }
  return (
    <div className="mo-main-container">
      <SeityBackButton wrapperClassName="mo-back-button" />
      <h1>{strings.outcomesIntroHeader}</h1>
      <img src={logoImage} alt={outcomeScores.wholeHealthCategory} className="mo-main-container-image" />
      <div className="mo-background">
        <div className="mo-background-score">
          <h3>{outcomeScores.wholeHealth}%</h3>
          <SeityProgressBar
            progress={outcomeScores.wholeHealth}
            trackColor="rgba(49, 73, 71, 1)"
            color="rgba(255, 248, 235, 1)"
            height={10}
            containerStyle="mo-background-progress-bar"
          />
        </div>
        <div className="mo-foreground">
          <h2>{wholeHealthDescriptionText}</h2>
          <h6>{wholeHealthDragonText}</h6>
        </div>
      </div>
    </div>
  );
};
