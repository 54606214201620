import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SeityLoader from "../../../_core/components/SeityLoader";
import SeityButton from "../../../_core/components/SeityButton";
import strings from "../../../_core/strings/strings";
import virtualBackgroundImage from "../../../assets/graphics/cv-page-virtual-background.png";
import deskKitImage from "../../../assets/graphics/cv-page-desk-kit.png";
import coreValuesDeskKitImage from "../../../assets/graphics/coreValuesDeskKit.png";
import cvPageDeskKitImage from "../../../assets/graphics/cv-page-desk-kit.png";

import "./styles.scss";
import { sendFetchCVBackgroundImageRequest, sendDownloadPDFInstructionsRequest } from "../coreValuesSlice";

type paramTypes = {
  assetID: string;
};

export const CoreValueDownloads: FunctionComponent = () => {
  const { assetID } = useParams<paramTypes>();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const showVirtualBackgroundView = queryParam.get("virtualBackground");
  const showDeskKitView = queryParam.get("deskKit");

  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const items = [
    {
      id: "1",
      name: strings.virtualbackground,
      instructions: strings.virtualBackgroundInstructions,
      image: virtualBackgroundImage,
      downloadName: "CoreValueBackground01.png",
      queryParam: "virtualBackground"
    },
    {
      id: "2",
      name: strings.corevaluesdeskkit,
      instructions: strings.coreValuesDeskKitInstructions,
      image: cvPageDeskKitImage,
      downloadName: "CoreValueDeskKit.pdf",
      queryParam: "deskKit"
    }
  ];

  useEffect(() => {
    const fetchImage = async () => {
      if (showVirtualBackgroundView) {
        setIsLoading(true);
        try {
          const blobUrl: any = await dispatch(sendFetchCVBackgroundImageRequest());
          setImageSrc(blobUrl as string);
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchImage();
  }, [showVirtualBackgroundView, dispatch]);

  const handleDownloadPDFInstructions = async () => {
    dispatch(sendDownloadPDFInstructionsRequest());
  };

  if (isLoading) {
    return <SeityLoader />;
  }

  return (
    <div className="seity-main-fullwidth downloads">
      {showVirtualBackgroundView ? (
        <div className="downloads__section">
          <h1>{strings.virtualbackground}</h1>
          <h5 className="downloads__instructions">{strings.virtualBackgroundInstructions}</h5>
          {imageSrc && <img className="downloads__image" src={imageSrc} alt={strings.virtualBackgroundImage} />}
          <SeityButton
            label={strings.download}
            onClick={() => {
              if (imageSrc) {
                const link = document.createElement("a");
                link.href = imageSrc;
                link.download = "CoreValueBackground01.png";
                link.click();
              }
            }}
            type="next"
            className="downloads__button"
          />
          <SeityButton
            label={strings.viewInstructions}
            onClick={handleDownloadPDFInstructions}
            type="outline"
            className="downloads__button"
          />
        </div>
      ) : showDeskKitView ? (
        <div className="downloads__section">
          <h1>{strings.corevaluesdeskkit}</h1>
          <h5 className="downloads__instructions">{strings.coreValuesDeskKitInstructions}</h5>
          <img className="downloads__image" src={coreValuesDeskKitImage} alt={strings.coreValuesDeskKitImage} />
          <SeityButton
            label={strings.download}
            onClick={() => {
              if (imageSrc) {
                const link = document.createElement("a");
                link.href = imageSrc;
                link.download = "CoreValueDeskKit.pdf";
                link.click();
              }
            }}
            type="next"
            className="downloads__button"
          />
        </div>
      ) : (
        <>
          <h1>{strings.downloads}</h1>
          <div className="downloads__content">
            {items.map((item) => (
              <div
                key={item.id}
                className="downloads__item"
                onClick={() => {
                  history.push(`/coreValues/downloads?${item.queryParam}=true`);
                }}
              >
                <div className="downloads__item-text">
                  <h3>{item.name}</h3>
                  <h5>{item.instructions}</h5>
                </div>
                <img className="downloads__item-image" src={item.image} alt={item.name} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CoreValueDownloads;
