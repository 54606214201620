/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent } from "react";

import SeityModal from "../../../../_core/components/SeityModal";
import SeityButton from "../../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";
import { PrivacyPolicyModalProps } from "./types";

import strings from "../../../../_core/strings/strings";

import "./styles.scss";

export const PrivacyPolicyModal: FunctionComponent<PrivacyPolicyModalProps> = ({ visible = false, onToggle, onNext, onFinish }) => {
  return (
    <SeityModal isOpen={visible} onClose={onToggle} wrapperClassName="privacy-modal">
      <div className="privacy-modal-wrapper">
        <h3>{strings.privacyPolicy}</h3>
        <img src={require("../../../../assets/graphics/seity_lock@2x.png").default} width="80px" />
        <p className="privacy-alert__subtitle questions">
          {strings.answerPersonalQuestions} {strings.noOneEver}
          <br />({strings.unlessGiverPermission})
        </p>
        <div className="privacy-modal-buttons">
          <SeityButton
            label={strings.continue}
            onClick={() => {
              onToggle();
              onNext();
            }}
            className="privacy-modal-button"
          />
          <SeityHyperText type="discard" title={strings.finishLater} onClick={onFinish} />
        </div>
      </div>
    </SeityModal>
  );
};
