/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "../../../../../app/rootReducer";
import { clearAccountError, sendValidatePasswordRequest } from "../../../../account/accountSlice";

import { SeityFormTextInput } from "../../../../../_core/components/SeityTextInput/SeityFormTextInput";
import SeityButton from "../../../../../_core/components/SeityButton";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";
import { ContentProps, ContentType } from "../../types";

const changeEmailGraphic = require("../../../../../assets/graphics/changeEmail.svg").default;
const lockIcon = require("../../../../../assets/icons/lockIcon.svg").default;
const lockIconError = require("../../../../../assets/icons/lockIconError.svg").default;

export const CheckPassword = ({ setContentType }: ContentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");

  const { accountInfo, accountError } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(clearAccountError());
  }, []);

  const onSubmitHandler = () => {
    dispatch(sendValidatePasswordRequest(accountInfo.eMailAddress, password, handleSubmitResponse));
  };

  const handleSubmitResponse = (response: boolean) => {
    if (response) {
      dispatch(clearAccountError());
      setContentType(ContentType.UpdateEmail);
    }
  };

  const onCloseHandler = () => {
    dispatch(clearAccountError());
    setPassword("");
    if (location.pathname === "/changeEmail") {
      history.goBack();
    } else {
      setContentType(ContentType.CodeVerify);
    }
  };

  return (
    <div className="cp-wrapper">
      <div className="cp-body">
        <div className="cp-title">{strings.evChangeEmail}</div>
        <img className="cp-graphic" src={changeEmailGraphic} alt="change email" />
        <div className="cp-description">{strings.evCheckPasswordDescription}</div>
        <SeityFormTextInput
          inputLabelText={strings.passwordLabel}
          placeholder={strings.evPasswordPlaceHolder}
          onChange={(e) => {
            return setPassword(e.target.value);
          }}
          value={password}
          type="password"
          leftIcon={accountError !== null ? lockIconError : lockIcon}
          isInvalid={accountError !== null}
          className="cp-input"
          containerClassname="cp-inputContainer"
          leftIconClassname="cp-inputIcon"
          rightIconClassname="cp-inputPasswordIcon"
        />
      </div>
      <div className="cp-wrapper-bottom">
        <div className="cp-error">{accountError}</div>
        <SeityButton className="cp-submit" onClick={onSubmitHandler} label={strings.submit} disabled={password.length === 0} />
        <SeityButton type="link" onClick={onCloseHandler} label={strings.cancelButtonLabel} />
      </div>
    </div>
  );
};

export default CheckPassword;
