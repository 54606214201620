export enum FSInviteStatus {
  Pending = 1,
  Accept,
  Decline,
  Remove
}

export type FSMemberCoreValue = {
  coreValueID: number;
  coreValue: string;
  sortOrder: string;
};

export type FSGroup = {
  familySharingID: number;
  accountID: number; // organizer account id
  name: string;
  profilePicture: string | null;
  profilePictureFileName: string | null;
  isOrganizer: boolean;
  organizer: FSGroupAccount;
};

export type FSGroupDetail = {
  familySharingID: number;
  accountID: number;
  name: string;
  profilePicture: string | null;
  profilePictureFileName: string | null;
  isOrganizer: boolean;
  organizer: FSGroupAccount;
  willLoseSeityAccess: boolean;
};

export type FSGroupAccount = {
  accountID: number;
  firstName: string;
  lastName: string;
  profilePicture: string;
  dob: string;
  email: string;
  coreValues: Array<FSMemberCoreValue> | null;
  occupation?: string;
  relationship?: number;
};

export type FSGroupMember = FSGroupAccount & {
  familySharingMemberID: number;
  familySharingID: number;
  relationship: number;
  familySharingStatusID: number;
  willLoseSeityAccess: boolean;
};

export type FSStatus = {
  familySharingStatusID: number;
  description: string;
};

export type FSRelationship = {
  relationshipID: number;
  description: string;
};
