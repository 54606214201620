/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { RootState } from "../../../../app/rootReducer";
import { getSyncListRequest } from "../syncListSlice";
import { getStoryListRequest } from "../storyListSlice";

import { SeityLoader } from "../../../../_core/components/SeityLoader";
import { SeityBackButton } from "../../../../_core/components/SeityBackButton";

import { SyncStoriesItem } from "./SyncStoriesItem";
import { MySyncStoriesListProps } from "./types";

import strings from "../../../../_core/strings/strings";
import "./styles.scss";
import JournalEntryModal from "../../../journals/JournalEntryModal";
import { toastSuccess } from "../../../../app/utils";
import SeityModal from "../../../../_core/components/SeityModal";
import SeityButton from "../../../../_core/components/SeityButton";

export const MySyncStoriesList: FunctionComponent<MySyncStoriesListProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [showJournalEntry, setShowJournalEntry] = useState({
    show: false,
    title: "",
    objectiveID: 0
  });

  const isStories = useMemo(() => {
    return location.pathname === "/myStories";
  }, [location.pathname]);

  const { isLoading: isLoadingSync, syncList } = useSelector((state: RootState) => {
    return state.syncList;
  });

  const { isLoading: isLoadingStories, storyList } = useSelector((state: RootState) => {
    return state.storyList;
  });
  const isLoading = isLoadingSync || isLoadingStories;

  useEffect(() => {
    if (isStories) {
      dispatch(getStoryListRequest());
    } else {
      dispatch(getSyncListRequest());
    }
    window.scrollTo(0, 0);
  }, [isStories]);

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { header, list, listTitlesField } = useMemo(() => {
    const header = isStories ? strings.Stories : strings.Syncs;
    const list = isStories ? storyList : syncList;
    const listTitlesField = isStories ? "journalTitles" : "syncTitles";

    return {
      header,
      list,
      listTitlesField
    };
  }, [isStories, storyList, syncList]);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  const goToDetail = (objectiveID: number) => {
    history.push(`/${isStories ? "myStories" : "mySyncs"}/${objectiveID}`);
  };

  if ((isStories ? storyList !== null : syncList !== null) && !isLoading) {
    return (
      <div className="msl-container">
        <SeityBackButton wrapperClassName="msl-back-button" />
        <div className="msl-heading">
          <h1>{header}</h1>
        </div>
        <div className={`msl-items-wrapper ${list.length === 1 ? "msl-items-wrapper-flex" : ""}`}>
          {list.map((item) => {
            return (
              <SyncStoriesItem
                header={item.title}
                author={item.author}
                startDate={item.availableStartDate}
                imageUrl={item.image}
                topEmos={item.topEmotionsImages}
                totalEmoCount={item.totalEmotionSelectionCount}
                objectiveID={item.objectiveID}
                objectiveStatusID={item.objectiveStatusID}
                status={item.status}
                isCompleted={item.isCompleted}
                onPress={goToDetail}
                onPressJournal={() => {
                  setShowJournalEntry({ show: true, title: item.title, objectiveID: item.objectiveID });
                }}
                isStories={isStories}
                key={`${isStories ? "story-" : "sync-"}${item.objectiveID}`}
              />
            );
          })}
        </div>
        {showJournalEntry.show && (
          <div className="journal-entry-modal-overlay">
            <div className="journal-entry-modal-container">
              <JournalEntryModal
                type={isStories ? "story" : "sync"}
                title={showJournalEntry.title}
                objectiveID={showJournalEntry.objectiveID}
                onClose={(success) => {
                  setShowJournalEntry({ show: false, title: "", objectiveID: 0 });
                  if (success) {
                    toastSuccess(strings.successNewEntryCreated);
                  }
                }}
              />
            </div>
          </div>
        )}
        <SeityModal isOpen={isStories ? storyList.length < 1 : syncList.length < 1} wrapperClassName="seity-syncs-story-modal-wrapper">
          <div className="seity-syncs-story-modal">
            <img src={require("../../../../assets/graphics/error_content.png").default} alt="header icon" />
            <h1>{strings.syncAndStoryErrorHeader}</h1>
            <h3>{strings.formatString(strings.syncAndStoryErrorSummaryP1, isStories ? strings.stories : strings.Syncs)} </h3>
            <h3>{strings.syncAndStoryErrorSummaryP2}</h3>
            <h4>{strings.syncAndStoryErrorCheckBack}</h4>
            <SeityButton
              className="seity-syncs-story-button"
              label={strings.okay}
              onClick={() => {
                history.goBack();
              }}
            />
          </div>
        </SeityModal>
      </div>
    );
  }
  return <SeityLoader showBackgroundMask />;
};
