import React from 'react';
import {MoreInfoIcon} from "../SeityInfoIcon/InfoIcon";
import {SeityTextareaInput, SeityTextareaInputProps} from "./SeityTextareaInput";
import './styles.scss';

interface SeityFormTextareaInputProps extends SeityTextareaInputProps {
  inputLabelText: string;
  moreInfoText?: string;
  formStyle?: React.CSSProperties;
  isRequired?: boolean;
}

export const SeityFormTextareaInput: React.FC<SeityFormTextareaInputProps> =
  ({
     inputLabelText,
     moreInfoText,
     formStyle,
     isRequired,
     ...textareaProps
   }) => {
    return (
      <div className="seity-textarea-with-label" style={formStyle}>
        <label>
          {inputLabelText} {isRequired && <span>(required)</span>}
        </label>
        {moreInfoText && (
          <MoreInfoIcon
            text={moreInfoText}
            imageStyle={{
              marginLeft: "5px",
              paddingBottom: "8px",
            }}
          />
        )}
        <SeityTextareaInput {...textareaProps} />
      </div>
    );
  };
