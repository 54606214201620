import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PracticeSelection,
  PracticeSelectionUpdate,
  AssessmentQuestion,
  QuestionGenericUpdate,
  PracticeScores
} from "../../api/assessment/types/questionTypes";
import {
  getPracticeScores,
  getPracticeSelection,
  practiceMarkCompleteRequest,
  practiceSelectionUpdateRequest
} from "../../api/assessment/seityHealthAPI-Assessment";
import { AppThunk } from "../../app/store";
import { SeityAuthenticationError } from "../../api/authTypes";
import { OutcomeCategory } from "../../api/assessment/types/outcomeCategories";
import { setRedirectToLogin } from "../auth/authSlice";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";

export interface PracticeSelectionState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly questions: AssessmentQuestion[];
  readonly header: string | null;
  readonly subHeader: string | null;
  readonly practiceScore: number;
  readonly worldScore: number;
  readonly bodyScore: number;
  readonly mindScore: number;
  readonly spiritScore: number;
  readonly worldCategory: string | null;
  readonly bodyCategory: string | null;
  readonly mindCategory: string | null;
  readonly spiritCategory: string | null;
}

const practiceSelectionSlice = createSlice({
  name: "assessment-practice-questions",
  initialState: {
    error: null,
    isLoading: false,
    questions: [],
    header: null,
    subHeader: null,
    practiceScore: 0,
    worldScore: 0,
    bodyScore: 0,
    mindScore: 0,
    spiritScore: 0,
    worldCategory: null,
    bodyCategory: null,
    mindCategory: null,
    spiritCategory: null
  } as PracticeSelectionState,
  reducers: {
    setQuestions(state, action) {
      const { payload } = action.payload;
      state.questions = payload;
    },
    questionAnswered(state, action) {
      const { id, answerLevel } = action.payload;
      let question = state.questions.find((question) => question.id === id);
      if (question) {
        question.answerLevel = answerLevel;
      }
    },
    setHeaders(state, action) {
      const { header, subHeader } = action.payload;
      state.header = header;
      state.subHeader = subHeader;
    },
    setScore(state, action: PayloadAction<number>) {
      state.practiceScore = action.payload;
    },
    setAllScores(
      state,
      action: PayloadAction<{
        worldScore: number;
        bodyScore: number;
        mindScore: number;
        spiritScore: number;
      }>
    ) {
      state.worldScore = action.payload.worldScore;
      state.bodyScore = action.payload.bodyScore;
      state.mindScore = action.payload.mindScore;
      state.spiritScore = action.payload.spiritScore;
    },
    setCategories(
      state,
      action: PayloadAction<{
        worldCategory: string;
        bodyCategory: string;
        mindCategory: string;
        spiritCategory: string;
      }>
    ) {
      state.worldCategory = action.payload.worldCategory;
      state.bodyCategory = action.payload.bodyCategory;
      state.mindCategory = action.payload.mindCategory;
      state.spiritCategory = action.payload.spiritCategory;
    },
    apiError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export const { setQuestions, questionAnswered, setHeaders, setScore, setAllScores, setCategories, apiError, clearApiError, setIsLoading } =
  practiceSelectionSlice.actions;
export default practiceSelectionSlice.reducer;

export const sendPracticeQuestionsRequest =
  (outcomeCategoryID: number, sortOrder: number, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearApiError());

      console.log("Getting practice questions...");

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      const practiceSelectionResponse = await getPracticeSelection(token, outcomeCategoryID, sortOrder);

      const data: any = practiceSelectionResponse.data;
      const { practiceSelections } = data;
      const questions = practiceSelections.map((item: PracticeSelection) => ({
        id: item.practiceSelectionID,
        questionTypeId: item.questionTypeID,
        questionText: item.practiceText,
        answerLevel: item.answerLevel,
        answerSelections: item.answerSelections
      }));

      dispatch(setIsLoading(false));

      const payload = { payload: questions };

      const header = practiceSelections.length > 0 ? practiceSelections[0].category : "";
      const subHeader = practiceSelections.length > 0 ? practiceSelections[0].subcategory : "";

      const headers = { header, subHeader };

      console.log("Recieved practice questions");

      dispatch(setQuestions(payload));
      dispatch(setHeaders(headers));

      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      dispatch(setIsLoading(false));
      dispatch(apiError({ error: err.toString() }));
      throw err;
    }
  };

export const sendPracticeSelectionUpdateRequest =
  (practiceSelectionUpdate: PracticeSelectionUpdate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError());

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }

      console.log("Updated practice question...");

      const practiceSelectionUpdateResponse = await practiceSelectionUpdateRequest(token, practiceSelectionUpdate);
      if (practiceSelectionUpdateResponse.success) {
        const questionGenericUpdate = new QuestionGenericUpdate(
          practiceSelectionUpdate.practiceSelectionID,
          practiceSelectionUpdate.answerLevel
        );
        dispatch(questionAnswered({ id: questionGenericUpdate.id, answerLevel: questionGenericUpdate.answerLevel }));

        console.log("Completed updating practice question");
      } else {
        dispatch(apiError({ error: "Error updating practice selection" })); // put in strings.ts
        console.log("Error updating practice selection. \nMessage: " + practiceSelectionUpdateResponse.message);
      }
    } catch (err) {
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendPracticeScoresRequest =
  (outcomeCategoryID: number, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearApiError());
      dispatch(setIsLoading(true));

      const { token } = await getRefreshedToken();
      if (token === null) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        throw new SeityAuthenticationError();
      }
      console.log("Getting pracitce scores...");
      const practiceScoreResponse = await getPracticeScores(token);

      if (practiceScoreResponse.success) {
        const score = getScore(outcomeCategoryID, practiceScoreResponse.data);
        dispatch(setScore(score));

        dispatch(
          setAllScores({
            worldScore: practiceScoreResponse.data.world,
            bodyScore: practiceScoreResponse.data.body,
            mindScore: practiceScoreResponse.data.mind,
            spiritScore: practiceScoreResponse.data.spirit
          })
        );

        dispatch(
          setCategories({
            worldCategory: practiceScoreResponse.data.worldCategory,
            bodyCategory: practiceScoreResponse.data.bodyCategory,
            mindCategory: practiceScoreResponse.data.mindCategory,
            spiritCategory: practiceScoreResponse.data.spiritCategory
          })
        );

        console.log("Recieved pracitce scores");
        dispatch(setIsLoading(false));

        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      dispatch(apiError({ error: err.toString() }));
    }
  };

export const sendPracticeMarkCompleteRequestRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError());

    console.log("Sending practice mark comeplete request...");

    const { token } = await getRefreshedToken();
    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      throw new SeityAuthenticationError();
    }

    const outcomeMarkCompleteResponse = await practiceMarkCompleteRequest(token);
    if (outcomeMarkCompleteResponse.success) {
      console.log("practice mark request complete");
    }
  } catch (err) {
    console.error(err);
    dispatch(apiError({ error: err.toString() }));
  }
};

function getScore(outcomeCategoryID: OutcomeCategory, practiceScores: PracticeScores): number {
  switch (outcomeCategoryID) {
    case OutcomeCategory.World:
      return practiceScores.world;
    case OutcomeCategory.Body:
      return practiceScores.body;
    case OutcomeCategory.Mind:
      return practiceScores.mind;
    case OutcomeCategory.Spirit:
      return practiceScores.spirit;
    default:
      return 0;
  }
}
