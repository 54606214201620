import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./styles.scss";
import SeityLoader from "../../../_core/components/SeityLoader";
import { PlotlineGoToActionID, PlotlineInjectionReponse, PlotlineTemplateData, PlotlineTemplateID } from "../../../api/plotlineTypes";
import { getQuestAnswersApiData, getRouteGoToAction, getWellBeingScores, getWellbeingIdx } from "../helpers";
import { useParams } from "react-router";
import apiClientWithAuth from "../../../api/apiClient";
import { toastError } from "../../../app/utils";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPlotlineInjections } from "../plotlineSlice";

import { Wizard } from "react-use-wizard";
import PlotlineTemplateStep from "./PlotlineTemplateStep";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import AnimatedStep from "./AnimatedStep";

type paramTypes = {
  plotlineInjectionID: string;
};

const PlotlineTemplate: FunctionComponent = (): ReactElement => {
  const { plotlineInjectionID } = useParams<paramTypes>();

  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [quizAns, setQuizAns] = useState<Array<number>>([]);
  const [wbLevels, setWbLevels] = useState([-1, -1, -1, -1]);
  const [checkinScore, setCheckinScore] = useState(0);

  const [plSteps, setPlSteps] = useState<Array<PlotlineTemplateData>>([]);
  const [plData, setPlData] = useState<{
    gotoActionID: PlotlineGoToActionID;
    plotlineScheduleID: number;
  }>();

  const history = useHistory();
  const location = useLocation();
  //@ts-ignore
  const isOnDemand = location?.state?.onDemand;
  const dispatch = useDispatch();

  const previousStep = useRef<number>(0);

  const fetchSteps = async (plotlineInjectionID: number) => {
    setLoading(true);
    const firstTimeURL = `/Plotline/plotlineInjection/${plotlineInjectionID}`;
    const defaultURL = `/Plotline/plotlineInjections/${plotlineInjectionID}/default`;
    const res = await apiClientWithAuth.get<PlotlineInjectionReponse>(isOnDemand ? defaultURL : firstTimeURL);

    if (res.data.success) {
      if (res.data.data.completed) {
        history.push({
          pathname: getRouteGoToAction(res.data.data.plotlineGoToActionID)
        });
      } else if (res.data.data.steps === null) {
        toastError();
        return;
      }
      setPlSteps(res.data.data.steps);
      setPlData({
        gotoActionID: res.data.data.plotlineGoToActionID,
        plotlineScheduleID: res.data.data.plotlineScheduleID
      });
      setStep(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSteps(parseInt(plotlineInjectionID)).catch(console.error);
  }, [plotlineInjectionID]);

  const onClickNext = useCallback(
    async (nextStep) => {
      if (plSteps.length > 0 && step < plSteps.length - 1) {
        if (plSteps[step].plotlineTemplateID === PlotlineTemplateID.Questionnaire01) {
          if (quizAns?.length > 0) {
            setLoading(true);
            const param = getQuestAnswersApiData(plSteps[step].question!, quizAns);
            const res = await apiClientWithAuth.post<PlotlineInjectionReponse>(`/Plotline/questionAnswers`, param);
            if (!res.data.success) {
              toastError();
            }
            setLoading(false);
          } else if (plSteps[step].question?.isQuestionRequired) {
            toastError("You must select an answer");
            return;
          }
        } else if (plSteps[step].plotlineTemplateID === PlotlineTemplateID.WellbeingCheckIn01) {
          const idx = getWellbeingIdx(plSteps[step].outcomeCategoryID!);
          const level = wbLevels[idx];
          if (level === -1) {
            toastError((plSteps[step].title2 || "").length > 0 ? plSteps[step].title2! : "You must select an emoji level");
            return;
          }
          if (idx === 3) {
            // call wellbeing api
            setLoading(true);
            const scores = getWellBeingScores(wbLevels);
            const res = await apiClientWithAuth.post("CheckIn", {
              energy: scores[0],
              direction: scores[1],
              belonging: scores[2],
              joy: scores[3]
            });
            if (res.data.success) {
              const { checkInScore } = res.data.data.incentiveInformation;
              setCheckinScore(checkInScore ?? 0);
            } else {
              toastError();
            }
            setLoading(false);
          }
        }
        setStep(step + 1);
        nextStep(); // Call StepWizard func
      } else if (step === plSteps.length - 1) {
        if (isOnDemand) {
          history.push({
            pathname: getRouteGoToAction(plData?.gotoActionID!),
            state: plData?.gotoActionID === PlotlineGoToActionID.CVOnlyAssessment && { fromPlotline: true }
          });
          return;
        }
        // call complete api
        setLoading(true);
        const res = await apiClientWithAuth.post("Plotline/complete", {
          plotlineID: plSteps[step].plotlineID,
          plotlineScheduleID: plData?.plotlineScheduleID
        });
        if (!res.data.success) {
          toastError();
        }

        dispatch(
          getPlotlineInjections((isCompleted) => {
            setLoading(false);
            if (isCompleted) {
              // Router will redirect you to Today page. So don't need any further plotline actions
              return;
            }

            if (plData?.gotoActionID) {
              history.push({
                pathname: getRouteGoToAction(plData?.gotoActionID!),
                state: plData?.gotoActionID === PlotlineGoToActionID.CVOnlyAssessment && { fromPlotline: true }
              });
            } else {
              fetchSteps(parseInt(plotlineInjectionID)).catch(console.error);
            }
          })
        );

        /* Disable until it's needed as it breaks the flow sometimes
        // Check if plotlineID - 1, 2, 3 are finished. if finished, navigate to plotlineInjection 4
        const isFinished = [1, 2, 3].every((c) => res1.data.data.completedPlotlineIDs.includes(c));
        if (isFinished && plData?.gotoActionID !== PlotlineGoToActionID.TodayPage) {
          history.push("/plotline/4");
        }
        */
      }
    },
    [plSteps, step, quizAns, wbLevels, plData]
  );

  const onClickBack = (previousStep) => {
    if (step > 0) {
      setStep(step - 1);
      previousStep(); // Call StepWizard func
    }
  };

  if (!(plSteps.length > 0) || step > plSteps.length - 1) {
    return <SeityLoader />;
  }

  return (
    <>
      {isLoading && <SeityLoader showBackgroundMask />}
      <Wizard wrapper={<AnimatePresence initial={false} exitBeforeEnter />}>
        {plSteps.map((stepData, index) => {
          return (
            <AnimatedStep key={index} previousStep={previousStep}>
              <PlotlineTemplateStep
                stepData={stepData}
                checkinScore={checkinScore}
                wbLevels={wbLevels}
                quizAns={quizAns}
                showBackBtn={step !== 0}
                setWbLevels={setWbLevels}
                setQuizAns={setQuizAns}
                onNext={(nextStep) => {
                  onClickNext(nextStep);
                }}
                onBack={(previousStep) => {
                  onClickBack(previousStep);
                }}
              />
            </AnimatedStep>
          );
        })}
      </Wizard>
    </>
  );
};

export default PlotlineTemplate;
