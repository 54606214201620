import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import SeityModal from "../../../_core/components/SeityModal";
import SeityButton from "../../../_core/components/SeityButton";
import strings from "../../../_core/strings/strings";

import "./styles.scss";

type FinishCheckInLaterModalProps = {
  visible: boolean;
  onToggle: () => void;
  onFallBack?: (isSubmit: boolean) => void;
};

export const FinishCheckInLaterModal: FunctionComponent<FinishCheckInLaterModalProps> = ({
  visible = false,
  onToggle = () => {},
  onFallBack
}) => {
  const history = useHistory();

  return (
    <SeityModal
      isOpen={visible}
      onClose={onToggle}
      wrapperClassName="finish-checkin-later-wrapper"
      overlayClassName="finish-checkin-later-overlay"
    >
      <div className="finish-checkin-later">
        <p className="finish-checkin-later-title">{strings.finishCheckInLaterText}</p>
        <div className="finish-checkin-later-container">
          <SeityButton className="finish-checkin-later-button-stay" label={strings.stay} onClick={onToggle} />
          <SeityButton
            className="finish-checkin-later-button-leave"
            label={strings.leaveCheckIn}
            onClick={() => {
              if (onFallBack) {
                onFallBack(false);
              } else {
                history.push("/");
              }
            }}
            type="dark"
          />
        </div>
      </div>
    </SeityModal>
  );
};

export default FinishCheckInLaterModal;
