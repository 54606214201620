import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../app/rootReducer";
import { switchLanguage } from "../../../../app/utils";
import { getLanguagesRequest } from "../../../account/accountSlice";
import { LanguageItem } from "../../../../api/account/types/accountTypes";

import RegisterWrapper from "../../RegisterWrapper";
import SeityButton from "../../../../_core/components/SeityButton";

import strings from "../../../../_core/strings/strings";
import "./style.scss";
import { setCurrentInfo } from "../../registerSlice";
import { setLanguageID } from "../../../../slices/userAppSettingsSlice";

export const RegisterLangSelect = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedLanguageID, setSelectedLanguageID] = useState(strings.getLanguage() === "en-US" ? 1 : 2);
  const { languagesList } = useSelector((state: RootState) => state.account);

  const nextPressed = () => {
    history.push("/register/registerOne");
  };

  const setLanguage = (id: number) => {
    dispatch(
      setLanguageID({
        languageID: id
      })
    );

    dispatch(
      setCurrentInfo({
        languageID: id
      })
    );
  };

  const onLangSelect = (lang: LanguageItem) => {
    setSelectedLanguageID(lang.languageID);
    switchLanguage(lang.languageID);
    setLanguage(lang.languageID);
  };

  useEffect(() => {
    const getLanguages = async () => {
      await dispatch(getLanguagesRequest());
    };
    getLanguages();
  }, []);

  useEffect(() => {
    setLanguage(selectedLanguageID);
  }, [selectedLanguageID]);

  return (
    <RegisterWrapper>
      <div className="rls-container">
        <h1>{strings.selectLanguage}</h1>
        <div className="rls-languages">
          {languagesList &&
            languagesList.map((lang: LanguageItem) => (
              <button
                className={`rls-language-item ${selectedLanguageID === lang.languageID ? "rls-language-item-select" : ""}`}
                onClick={() => onLangSelect(lang)}
                id={`language${lang.languageID}Button`}
              >
                {lang.description}
              </button>
            ))}
        </div>
        <div className="rls-container-bottom">
          <SeityButton onClick={nextPressed} type="next" label={strings.nextButtonText} className="rls-next-button" />
        </div>
      </div>
    </RegisterWrapper>
  );
};
