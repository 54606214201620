import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IntentionSelected } from "../../../api/dailyIntention/intentionTypes";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";
import SeityLoader from "../../../_core/components/SeityLoader";
import CoreStrings from "../../../_core/strings/strings";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { deleteIntentionRequest, getIntentionSelListRequest } from "../intentionsSlice";
import ListItem from "./ListItem";

import "./styles.scss";

const COUNT_OF_CHUNK = 5;

const IntentionList: FunctionComponent = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDelModal, setShowDelModal] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [intentionList, setIntentionList] = useState<Array<IntentionSelected>>();
  const [delIdx, setDelIdx] = useState(-1);
  const [loading, setLoading] = useState(true);

  const getIntentions = (iList: Array<IntentionSelected> | null) => {
    const pos = iList?.length ?? 0;
    const range = {
      start: pos,
      end: pos + COUNT_OF_CHUNK - 1
    };
    setLoading(true);
    dispatch(
      getIntentionSelListRequest(range, (intentions) => {
        const all = [...(iList ?? []), ...intentions];
        setIntentionList(all);
        setLoading(false);
      })
    );
  };

  const handleScroll = () => {
    const pos = window.pageYOffset;
    if (loading) {
      setScrollPos(pos);
      return;
    }

    if (pos > scrollPos && document.body.scrollHeight - pos - window.innerHeight < 50) {
      getIntentions(intentionList ?? null);
    }
    setScrollPos(pos);
  };

  useEffect(() => {
    getIntentions(intentionList ?? []);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [intentionList, scrollPos]);

  return (
    <div className="intentions flex-col flex-ai-c w-100 mt-xl">
      <div className="intentions__header flex-col flex-center">
        <div className="header-wrap flex-col flex-ai-c">
          <div className="flex-ai-c w-100">
            <SeityBackButton wrapperClassName="header-back-button" />
            <h3 className="">{CoreStrings.intentions}</h3>
          </div>
        </div>
      </div>
      <ul className="intentions__list">
        {intentionList && intentionList?.length > 0
          ? intentionList?.map((item, idx) => {
              return (
                <ListItem
                  key={`${item.dailyIntentionID}-${idx}-listitem`}
                  intention={item}
                  onDelete={() => {
                    setDelIdx(idx);
                    setShowDelModal(true);
                  }}
                  onComplete={() => {}}
                />
              );
            })
          : !loading && <p className="no-intentions">{CoreStrings.emptyIntention}</p>}
      </ul>
      <button
        className="intentions__plus-button"
        onClick={() => {
          history.push("/intentions/choose");
        }}
      >
        <img src={require("../../../assets/graphics/intentions/intentions-plus.png").default} alt="Individual" width={24} height={24} />
      </button>

      <ConfirmDeleteModal
        isOpen={showDelModal}
        onDelete={() => {
          const intention = intentionList![delIdx];
          setLoading(true);
          dispatch(
            deleteIntentionRequest(intention.dailyIntentionAccountID, () => {
              setLoading(false);
              const temp = [...intentionList!];
              temp.splice(delIdx, 1);
              setIntentionList(temp);
              setShowDelModal(false);
            })
          );
        }}
        onClose={() => {
          setShowDelModal(false);
        }}
        onCancel={() => {
          setShowDelModal(false);
        }}
      />
      {loading && <SeityLoader />}
    </div>
  );
};

export default IntentionList;
