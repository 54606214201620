/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect, FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AssessWrap from "../AssessWrap";
import { SeityLoader } from "../../../_core/components/SeityLoader";
import { RootState } from "../../../app/rootReducer";
import { sendOutcomeMarkCompleteRequest, sendOutcomeQuestionsRequest, sendOutcomeSelectionUpdateRequest } from "../outcomeSelectionsSlice";
import useAssessOutcomesSteps, { AssessOutcomesSteps } from "./data";
import { pushToAnsweredPracticeSelections, sendProgressDenominatorRequest } from "../assessmentProgressSlice";

import SeityQuestion from "../../../_core/components/SeityQuestion";
import SeityAlert from "../../../_core/components/SeityAlert";
import SeityButton from "../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../_core/components/SeityHyperText";
import AssessmentQuestionProgress from "../base/AssessmentQuestionProgress";
import AssessmentCategoryScoreCard from "../base/AssessmentCategoryScoreCard";
import { OutcomeCategory } from "../../../api/assessment/types/outcomeCategories";
import { AssessmentSectionIntro } from "../base/AssessmentSectionIntro";

import AssessmentCompleteImage from "../../../assets/graphics/img-complete-web.png";

import { AssessOutcomesProps } from "./types";
import strings from "../../../_core/strings/strings";

import "./styles.scss";

export const AssessOutcomes: FunctionComponent<AssessOutcomesProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ASSESS_OUTCOMES_STEPS = useAssessOutcomesSteps();
  const [stepId, setStepId] = useState(-1);
  const [needsToBeAnswered, setNeedsToBeAnswered] = useState(false);
  const [showFinishLater, setShowFinishLater] = useState(false);
  const [showUnansweredQuestionsModal, setShowUnansweredQuestionsModal] = useState(false);
  const [outcomesStep, setOutcomesStep] = useState<AssessOutcomesSteps>(ASSESS_OUTCOMES_STEPS[0]);

  const { isLoading, questions, header } = useSelector((state: RootState) => {
    return state.assessment.outcomes;
  });
  const { denominator, answeredPracticeSelections, answeredOutcomeSelections } = useSelector((state: RootState) => {
    return state.assessment.assessmentProgress;
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _outcomesStep = getCurrentOutcomesStep();
    if (_outcomesStep) {
      setOutcomesStep(_outcomesStep);
    }
  }, [stepId]);

  useEffect(() => {
    if (outcomesStep && !!outcomesStep.outcomeCateID) {
      dispatch(sendOutcomeQuestionsRequest(outcomesStep.outcomeCateID));
      dispatch(sendProgressDenominatorRequest());
      setNeedsToBeAnswered(false);
    }
  }, [outcomesStep]);

  const assessHeader = document.getElementsByClassName("aw-header")[0];
  const seityHeader = document.getElementsByClassName("seity-header")[0];
  const allSelected = !questions.some((i) => {
    return i.answerLevel === 0;
  });
  const answeredQuestions = questions.filter((q) => {
    return q.answerLevel !== 0;
  });

  answeredQuestions.forEach((q) => {
    return dispatch(pushToAnsweredPracticeSelections(q.id));
  });

  const getCurrentOutcomesStep = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _outcomesStep = [...ASSESS_OUTCOMES_STEPS].find((c) => {
      return c.id == stepId;
    });
    return _outcomesStep;
  };

  const onPressOption = (option) => {
    const { value, type } = option.action;
    switch (type) {
      case "next":
        if (outcomesStep?.id >= 0 && !allSelected) {
          setShowUnansweredQuestionsModal(true);
          return;
        }

        window.scrollTo(0, 0);

        setStepId(value);
        break;
      case "back":
        return setStepId(value);
      case "finishLater":
        return setShowFinishLater(true);
      case "navigate":
        dispatch(sendOutcomeMarkCompleteRequest());
        return history.push(value);
      default:
        return () => {};
    }
  };

  const onQuestionChange = (id: number, answerLevel: number) => {
    dispatch(
      sendOutcomeSelectionUpdateRequest({
        outcomeSelectionID: id,
        answerLevel
      })
    );
    scrollToNextQuestion(id);
  };

  const scrollToNextQuestion = (id: number) => {
    const nextQuestionIndex =
      questions.findIndex((question) => {
        return question.id === id;
      }) + 1;
    const nextQuestion = questions[nextQuestionIndex];
    if (nextQuestion) {
      const nextQuestionElement = document.getElementById(nextQuestion.id.toString());
      if (nextQuestionElement) {
        const topOfNextQuestion = nextQuestionElement.offsetTop - seityHeader.clientHeight - assessHeader.clientHeight - 4;
        window.scroll({ top: topOfNextQuestion, behavior: "smooth" });
      }
    }
  };

  const scrollToUnansweredQuestion = () => {
    const firstNotAnsweredIndex = questions.findIndex((i) => {
      return i.answerLevel === 0;
    });
    const firstNotAnswered = questions[firstNotAnsweredIndex];
    if (firstNotAnswered) {
      const nextQuestionElement = document.getElementById(firstNotAnswered.id.toString());
      if (nextQuestionElement) {
        const topOfNextQuestion = nextQuestionElement.offsetTop - seityHeader.clientHeight - assessHeader.clientHeight - 4;
        window.scroll({ top: topOfNextQuestion, behavior: "smooth" });
      }
    }
  };

  const outcomesHeader = (outcomesStep: AssessOutcomesSteps, onPressOption) => {
    if (outcomesStep.outcomeCateID) {
      return (
        <div className="assess-outcomes-header-wrapper">
          <div className="assess-outcomes-header">
            <div className="assess-outcomes-header-button">
              <SeityHyperText
                onClick={() => {
                  return onPressOption({ action: { type: "finishLater", value: "0" } });
                }}
                title={strings.finishLater}
              />
            </div>
            <AssessmentQuestionProgress
              denominator={denominator}
              answeredOutcomeSelections={answeredOutcomeSelections}
              answeredPracticeSelections={answeredPracticeSelections}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const outcomesFooter = (outcomesStep: AssessOutcomesSteps, onPressOption) => {
    return (
      <div className="assess-outcomes-footer">
        <div className={`assess-outcomes-footer__options ${outcomesStep?.buttonsDirectionRow && "assess-outcomes-footer__options-row"}`}>
          {outcomesStep.options.map((option, index) => {
            return (
              <SeityButton
                key={index}
                className={`outcomes-action-button ${option?.buttonType === "discard" && "outcomes-assess-green-title"}`}
                onClick={() => {
                  return onPressOption(option);
                }}
                label={option.value}
                type={option?.buttonType}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const getContent = (stepId: number) => {
    if (stepId === -1) {
      return <AssessmentSectionIntro mode="outcomes" />;
    }
    if (stepId > -1 && stepId < 4) {
      return (
        <div className="question-section-container">
          {questions.map((question, index) => {
            return (
              <SeityQuestion
                id={question.id}
                needsToBeAnswered={needsToBeAnswered}
                questionTypeId={question.questionTypeId}
                questionText={`${index + 1}. ${question.questionText}`}
                answerLevel={question.answerLevel}
                handleSelect={onQuestionChange}
                key={question.id.toString()}
                answerSelections={question.answerSelections}
              />
            );
          })}
        </div>
      );
    }
    if (stepId === 4) {
      return <AssessmentCategoryScoreCard mode="outcomes" outcomeCategory={OutcomeCategory.WholeHealth} />;
    }
    if (stepId === 5) {
      return (
        <div className="outcomes-complete-wrapper">
          <h2>{strings.youAreComplete}</h2>
          <img className="img-assessment-complete" src={AssessmentCompleteImage} alt={strings.assessmentCompleteHeader} />
        </div>
      );
    }
  };

  return (
    <AssessWrap
      stepNum={outcomesStep.id === -1 ? undefined : 3}
      subHeader={outcomesHeader(outcomesStep, header)}
      label={strings.outcomesSubHeader}
      showFinishLater={showFinishLater}
      setShowFinishLater={setShowFinishLater}
    >
      {outcomesStep && (
        <>
          {isLoading ? <SeityLoader showBackgroundMask /> : null}
          {getContent(outcomesStep.id)}
          {outcomesFooter(outcomesStep, onPressOption)}
          <SeityAlert
            title={strings.oops}
            visible={showUnansweredQuestionsModal}
            onToggle={() => {
              scrollToUnansweredQuestion();
              setNeedsToBeAnswered(true);
              setShowUnansweredQuestionsModal(false);
            }}
            subTitle={strings.unansweredQuestionsAlert}
          />
        </>
      )}
    </AssessWrap>
  );
};
