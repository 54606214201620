import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResourceResponse, ServiceResourceGroup } from "../../api/resource/resourceTypes";
import { AppThunk } from "../../app/store";
import apiClientWithAuth from "../../api/apiClient";

export interface ResourcesState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly serviceResourceGroups: ServiceResourceGroup[];
}

const setInitialState = {
  error: null,
  isLoading: false,
  serviceResourceGroups: []
} as ResourcesState;

const resourcesSlice = createSlice({
  name: "resources",
  initialState: setInitialState,
  reducers: {
    setResources(state, action: PayloadAction<ServiceResourceGroup[]>) {
      state.isLoading = false;
      state.serviceResourceGroups = action.payload;
    },
    setResourcesError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    resetCoreValuesSlice: () => setInitialState
  }
});

export const { setResourcesError, clearApiError, setIsLoading, setResources, resetCoreValuesSlice } = resourcesSlice.actions;
export default resourcesSlice.reducer;

export const getResourcesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const res = await apiClientWithAuth.get<ResourceResponse>("/CheckIn/ServiceResource");
    if (res.data.success) {
      const data: any = res.data.data;
      const { serviceResourceGroup } = data;
      dispatch(setResources(serviceResourceGroup));
    }
  } catch (err) {
    console.error(err);
    dispatch(setResourcesError({ error: err.toString() }));
  }
};
