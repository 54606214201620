import { useState, useEffect, useContext } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../../../app/rootReducer";
import { clearApiError } from "../../authSlice";
import { SeityButton } from "../../../../_core/components/SeityButton";

import EmailSent from "../../../../assets/web-icons/email_sent.png";
import copyright from "../../../../assets/web-icons/copyright.svg";

import strings from "../../../../_core/strings/strings";
import "./styles.scss";
import { SeityAppContext } from "../../../../app/SeityAppContext";

type ConfirmationProps = {
  communication: string;
  flow: string;
};

export const Confirmation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { pathname, state } = useLocation<ConfirmationProps>();
  console.log({ pathname, state });

  const appContext = useContext(SeityAppContext);

  const { shouldRedirectToLockedAccount } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { supportEmail } = useSelector((state: RootState) => state.account);

  let confirmationHeader = strings.confirmationHeaderEmail;
  let confirmationBody = strings.confirmationBodyEmailUsername;
  if (state.flow == "password") {
    confirmationBody = strings.confirmationBodyEmailPassword;
  }
  let confirmationLinkText = strings.confirmationLinkTextEmail;

  if (state?.communication == "sms") {
    confirmationHeader = strings.confirmationHeaderSms;
    confirmationBody = strings.confirmationBodySmsUsername;
    if (state.flow == "password") {
      confirmationBody = strings.confirmationBodySmsPassword;
    }
    confirmationLinkText = strings.confirmationLinkTextSms;
  }

  useEffect(() => {
    appContext.setShowNavMenuItems(false);
    appContext.setShowTodo(false);
    appContext.setNavigationMenuButtonText("< Back");
    return () => {
      dispatch(clearApiError({}));

      appContext.setShowNavMenuItems(true);
      appContext.setShowTodo(true);
      appContext.setNavigationMenuButtonText(strings.finishLater);
    };
  }, []);

  const loginClicked = (e: any) => {
    e.preventDefault();
    history.push("/login");
  };

  const changeEmailOrPhoneClicked = () => {
    history.push({
      pathname: "/forgot-password-username",
      state: { communication: state.communication, flow: state.flow }
    });
  };

  if (shouldRedirectToLockedAccount) {
    return <Redirect to="/lockedAccount" />;
  }

  return (
    <div className="confirmation-container">
      <div className="confirmation-bubble-wrap">
        <div className="confirmation-inner-container">
          <img src={EmailSent} alt="email-sent-graphics" className="confirmation-bubble-logo" />
          <div className="confirmation-content-header">{confirmationHeader}</div>
          <div className="confirmation-content-text">{confirmationBody}</div>

          <div className="confirmation-bubble-button">
            <SeityButton
              className="confirmation-bubble-button-width"
              type="submit"
              disabled={false}
              label={strings.login}
              onClick={loginClicked}
              testId="loginButton"
            />

            <a className="confirmation-change-email" onClick={changeEmailOrPhoneClicked}>
              {confirmationLinkText}
            </a>
          </div>
        </div>
      </div>
      <div className="confirmation-footer-wrapper">
        <div>
          {strings.didNotReceive} {state.communication}?{" "}
          <a type="button" href={`mailto:${supportEmail}`}>
            <u> {strings.contactSupport2}</u>
          </a>
        </div>
        <div>
          <img height={12} width={12} src={copyright} alt="copyright icon" />
          <span className="login-footer-copyright">
            {new Date().getFullYear()} {strings.SeityCopyright}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
