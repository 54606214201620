import React, {FunctionComponent, ReactElement} from "react";
import {SeityGoogleLoginFailedAlertProps} from "./types";
import SeityModal from "../SeityModal";
import strings from "../../strings/strings";
import iconAlert from "../../../assets/graphics/icon-alert.png";
import iconClose from "../../../assets/graphics/close_black.png";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";

const SeityGoogleLoginFailedAlert: FunctionComponent<SeityGoogleLoginFailedAlertProps> =
  ({
     visible = false,
     onClose
   }): ReactElement => {
    const { supportEmail } = useSelector((state: RootState) => {
      return state.account;
    });
    return (
      <SeityModal isOpen={visible} onClose={onClose} wrapperClassName="seity-google-login-failed-alert-modal">

        <div className="d-flex flex-column mt-4">

          <div className={"d-flex align-items-center"}>
            <img className={'seity-google-login-failed-alert-icon ml-2'} src={iconAlert} alt="alert icon"/>
            <h3 className={"m-0 pl-3"}>{strings.googleLoginFailedAlertTitle}</h3>
          </div>

          <div className={"pl-5 pt-3"}>
            <p className="font-weight-bold">{strings.googleLoginFailedAlertSubTitle}</p>

            <ul className={"pl-4 py-3"}>
              <li>{strings.googleLoginFailedAlertStep1}</li>
              <li>{strings.googleLoginFailedAlertStep2}</li>
              <li>{strings.googleLoginFailedAlertStep3}</li>
            </ul>
          </div>

          <div className="pl-5">
            <span className="font-weight-bold">{strings.needHelp} </span>
            <span>
                <a type="button" href={`mailto:${supportEmail}`}><span className="seity-google-login-failed-alert-support-text">{strings.contactSupport}</span></a>
              </span>
          </div>

          <img onClick={onClose} className={'seity-google-login-failed-alert-close-icon'} src={iconClose} alt="alert icon"/>

        </div>

      </SeityModal>
    );

  };

export default SeityGoogleLoginFailedAlert;