/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { RootState } from "../../../../app/rootReducer";
import { sendPostRegistration02 } from "../../registerSlice";
import { validPassword } from "../../../../_core/utils/accountUtils/accountUtils";

import { SeityFormTextInput } from "../../../../_core/components/SeityTextInput/SeityFormTextInput";
import RegisterWrapper from "../../RegisterWrapper";
import { SeityIndicator } from "../../../../_core/components/SeityIndicator";
import SeityButton from "../../../../_core/components/SeityButton";

import strings from "../../../../_core/strings/strings";
import "./style.scss";

export const RegisterFive = () => {
  const dispatch = useDispatch();

  const { registerSuccess, registerError, current, emailValidationRequired } = useSelector((state: RootState) => {
    return state.register;
  });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const nextPressed = () => {
    if (!validPassword(password)) {
      setValidationError(strings.passwordCriteria);
      return;
    }
    if (password !== confirmPassword) {
      setValidationError(strings.passwordMatchError);
      return;
    }
    setValidationError("");
    dispatch(
      sendPostRegistration02(
        current.firstName,
        current.lastName,
        current.emailAddress,
        password,
        current.groupActivationCode,
        current.languageID,
        current.dateOfBirth,
        current.cellPhone,
        current.phoneCountryCodeID,
        current.userId
      )
    );
  };

  const onPasswordChange = (password) => {
    setPassword(password);
    if (validPassword(password)) {
      setPasswordInvalid(false);
    } else {
      setPasswordInvalid(true);
    }
  };

  const onConfirmPasswordChange = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
  };

  if (registerSuccess) {
    const redirectUrl = emailValidationRequired ? "/emailVerification" : "/phoneVerification";
    return (
      <Redirect
        to={{
          pathname: redirectUrl,
          state: {
            isFromRegistration: true
          }
        }}
      />
    );
  }

  return (
    <RegisterWrapper>
      <div className="register-four-container">
        <div>
          <h1>{strings.heading}</h1>
          <SeityIndicator currentStep={4} numberOfSteps={4} />
          <h5>{strings.stepFiveSubHeading}</h5>
          <form className="textbox-form">
            <SeityFormTextInput
              inputLabelText={strings.createPassword}
              controlId="password"
              type="password"
              onChange={(e) => {
                return onPasswordChange(e.target.value);
              }}
              isInvalid={passwordInvalid}
              value={password}
              autoComplete="off"
            />
            <SeityFormTextInput
              inputLabelText={strings.confirmPassword}
              controlId="confirm-password"
              type="password"
              onChange={(e) => {
                return onConfirmPasswordChange(e.target.value);
              }}
              value={confirmPassword}
              autoComplete="off"
            />
          </form>
          <div className="register-four-container-error">{registerError || validationError}</div>
        </div>
        <div className="register-two-container-bottom">
          <SeityButton
            onClick={nextPressed}
            type="next"
            disabled={false}
            label={strings.nextButtonText}
            className="register-five-next-button"
          />
        </div>
      </div>
    </RegisterWrapper>
  );
};
