/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { RootState } from "../../../../../app/rootReducer";
import {
  clearAccountError,
  sendSmsValidateVerificationCodeRequest,
  sendSmsVerificationCodeRequest,
  sendValidateVerificationCodeRequest,
  sendVerificationCodeRequest
} from "../../../../account/accountSlice";
import { setEmailVerificationNeeded, setSmsVerificationNeeded } from "../../../../../slices/userAppSettingsSlice";
import formatPhoneNumber from "../../../../../_core/utils/countryCode/formatPhoneNumber";
import strings from "../../../../../_core/strings/strings";
import { ContentProps, ContentType, LocationStateProps } from "../../types";
import "./styles.scss";
import { sendGetPhoneCountryCodes, setEmailValidationRequired, setSmsValidationRequired } from "../../../../register/registerSlice";
import { CredentialsType } from "../../../../account/maintenance/ModifyAuthentication";

import { SeityTextInput } from "../../../../../_core/components/SeityTextInput/SeityTextInput";
import SeityButton from "../../../../../_core/components/SeityButton";

const lockIcon = require("../../../../../assets/icons/lockIcon.svg").default;
const lockIconError = require("../../../../../assets/icons/lockIconError.svg").default;

type VerificationStateProps = LocationStateProps & {
  isPhoneVerification: boolean;
};

export const EmailVerificationContent = ({ setContentType }: ContentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<VerificationStateProps>();
  const { pathname } = location;

  const { shouldRedirectToLockedAccount } = useSelector((state: RootState) => {
    return state.auth;
  });
  const { emailValidationRequired, smsValidationRequired, current, phoneCountryCodes } = useSelector((state: RootState) => {
    return state.register;
  });
  const { codeVerifyError, isPasswordValidated, accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  const { eMailAddress, cellPhone, phoneCountryCodeID } = accountInfo;

  const [code, setCode] = useState("");
  const [isPhoneVerification, setIsPhoneVerification] = useState(pathname === "/phoneVerification");

  const isFromRegistration = location.state && "isFromRegistration" in location.state ? location.state.isFromRegistration : false;
  const isFromSettings = location.state && "isFromSettings" in location.state ? location.state.isFromSettings : false;

  const emailToVerify = isFromRegistration ? current.emailAddress : eMailAddress;
  const phoneToVerify = isFromRegistration ? current.cellPhone : cellPhone;
  const phoneCountryCodeIDToVerify = isFromRegistration ? current.phoneCountryCodeID : phoneCountryCodeID;

  const sendVerificationCode = () => {
    if (isPhoneVerification) {
      dispatch(sendSmsVerificationCodeRequest());
    } else {
      dispatch(sendVerificationCodeRequest());
    }
  };

  const onResendCodeHandler = () => {
    sendVerificationCode();
    dispatch(clearAccountError());
    setCode("");
  };

  const sendValidateCode = () => {
    if (isPhoneVerification) {
      dispatch(sendSmsValidateVerificationCodeRequest(code, submitResponseHandler));
    } else {
      dispatch(sendValidateVerificationCodeRequest(code, submitResponseHandler));
    }
  };

  useEffect(() => {
    if (!phoneCountryCodes) {
      dispatch(sendGetPhoneCountryCodes());
    }

    dispatch(clearAccountError());
    // if (location.pathname === "/phoneVerification") {
    //   setIsPhoneVerification(true);
    // }
    /**
     * TODO: remove when APIs return updated data without delay
     * Unless the following requests are delayed, those requests do not return the latest data.
     */
  }, []);

  const textBoxIcon = () => {
    if (codeVerifyError !== null) {
      return lockIconError;
    }
    return lockIcon;
  };

  const onUpdateEmailLinkHandler = () => {
    if (isFromRegistration) {
      history.push("register/registerFour");
      return;
    }
    if (isFromSettings || (!isFromRegistration && !isFromSettings)) {
      if (pathname.includes("phoneVerification")) {
        history.push({
          pathname: "/authentication/changeCredential",
          state: { credentialsType: CredentialsType.PhoneNumber, checkPassword: false, updateCreds: true }
        });
      } else if (pathname.includes("emailVerification")) {
        history.push({
          pathname: "/authentication/changeCredential",
          state: { credentialsType: CredentialsType.Email, checkPassword: false, updateCreds: true }
        });
      } else {
        history.push("/authentication");
      }
    }
    if (isPasswordValidated) {
      setContentType(ContentType.UpdateEmail);
    } else {
      setContentType(ContentType.PwdValidate);
    }
  };

  const submitResponseHandler = () => {
    if (isFromRegistration) {
      if (isPhoneVerification) {
        dispatch(setSmsValidationRequired({ smsValidationRequired: false }));

        if (emailValidationRequired) {
          history.push({
            pathname: "/emailVerification",
            state: { isFromRegistration: true }
          });
        } else {
          history.push("register/registerSix");
        }
      } else {
        dispatch(setEmailValidationRequired({ emailValidationRequired: false }));
        if (smsValidationRequired) {
          history.push({
            pathname: "/phoneVerification",
            state: { isFromRegistration: true }
          });
        } else {
          history.push("register/registerSix");
        }
      }
    } else if (isFromSettings) {
      if (isPhoneVerification) {
        dispatch(setSmsValidationRequired({ smsValidationRequired: false }));
        dispatch(setSmsVerificationNeeded());
      } else {
        dispatch(setEmailValidationRequired({ emailValidationRequired: false }));
        dispatch(setEmailVerificationNeeded());
      }
      history.push("/authentication");
    } else {
      // Startup only check email validation required
      dispatch(setEmailValidationRequired({ emailValidationRequired: false }));
      dispatch(setEmailVerificationNeeded());
      history.push({ pathname: "/" });
    }
  };

  if (shouldRedirectToLockedAccount) {
    return <Redirect to="/lockedAccount" />;
  }

  const verificationItem = isPhoneVerification
    ? formatPhoneNumber(phoneToVerify, phoneCountryCodeIDToVerify, phoneCountryCodes)
    : emailToVerify;

  return (
    <div className="ev-wrapper">
      <div className="ev-title">{isPhoneVerification ? strings.pvTitle : strings.evTitle}</div>
      <div className="ev-subtitle">{isPhoneVerification ? strings.pvSubtitle : strings.evSubtitle}</div>
      <div className="ev-codeSent">{strings.evCodeSent}</div>
      <div className="ev-email">
        <span>{verificationItem}</span>
      </div>
      <div className="ev-codeExpires">{strings.evCodeExpires}</div>
      <SeityTextInput
        value={code}
        onChange={(e) => {
          return setCode(e.target.value);
        }}
        className="ev-codeInput"
        containerClassname="ev-codeInputContainer"
        leftIconClassname="ev-codeInputIcon"
        type="number"
        leftIcon={textBoxIcon()}
        isInvalid={codeVerifyError !== null}
      />
      <div className="ev-recivedCode">{strings.evReceivedCode}</div>
      <SeityButton onClick={onResendCodeHandler} type="link" label={strings.evResendCode} />
      <div className="ev-codeError">{codeVerifyError}</div>
      <SeityButton className="ev-submit" type="submit" label={strings.submit} onClick={sendValidateCode} />
      <div className="ev-notCorrectAddress">
        <span>{isPhoneVerification ? strings.wrongNumber : strings.wrongEmail}</span>
        <span className="ml-2" onClick={onUpdateEmailLinkHandler}>
          <u>{isPhoneVerification ? strings.pvUpdateNumber : strings.evUpdateEmailAddress}</u>
        </span>
      </div>
    </div>
  );
};

export default EmailVerificationContent;
