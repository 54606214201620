export class AnswerSelection {
  readonly answerLevel: number;
  readonly answerText: string;

  constructor(answerLevel: number, answerText: string) {
    this.answerLevel = answerLevel;
    this.answerText = answerText;
  }
}
export class AssessmentQuestion {
  id: number;
  questionTypeId: number;
  questionText: string | null;
  answerLevel: number;
  answerSelections: AnswerSelection[] | undefined;

  constructor(
    id: number,
    questionTypeId: number,
    questionText: string | null,
    answerLevel: number,
    answerSelections: AnswerSelection[] | undefined
  ) {
    this.id = id;
    this.questionTypeId = questionTypeId;
    this.questionText = questionText;
    this.answerLevel = answerLevel;
    this.answerSelections = answerSelections;
  }
}

export class PracticeSelectionResponse {
  success: boolean;
  message: string | null;
  data: PracticeSelection[];

  constructor(success: boolean, message: string | null, data: PracticeSelection[]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class PracticeSelection {
  practiceSelectionID: string;
  questionTypeID: number;
  practiceText: string;
  outcomeCategoryID: number;
  outcomeSubcategoryID: number;
  sortOrder: number;
  category: string;
  categoryLogoURL: string;
  subcategory: string;
  subcategoryLogoURL: string;
  answerLevel: number;
  scoreID: number;
  topBox: number;
  worldEcosystemCount: number;
  worldSafetyCount: number;
  worldWorkCount: number;
  worldSocialTiesCount: number;
  worldRelationshipsCount: number;
  worldFinancesCount: number;
  bodyHealthcareCount: number;
  bodyAddictionPreventionCount: number;
  bodyExerciseCount: number;
  bodyNutritionCount: number;
  bodySleepCount: number;
  mindThoughtsCount: number;
  mindFeelingsCount: number;
  mindMemoriesCount: number;
  mindKnowledgeCount: number;
  spiritCoreValueConnectionsCount: number;
  spiritSpiritualConnectionsCount: number;
  spiritSpiritualTransactionsCount: number;
  outcomeQuestionCount: number;
  answerSelections: AnswerSelection[] | undefined;
  constructor(
    practiceSelectionID: string,
    questionTypeID: number,
    practiceText: string,
    outcomeCategoryID: number,
    outcomeSubcategoryID: number,
    sortOrder: number,
    category: string,
    categoryLogoURL: string,
    subcategory: string,
    subcategoryLogoURL: string,
    answerLevel: number,
    scoreID: number,
    topBox: number,
    worldEcosystemCount: number,
    worldSafetyCount: number,
    worldWorkCount: number,
    worldSocialTiesCount: number,
    worldRelationshipsCount: number,
    worldFinancesCount: number,
    bodyHealthcareCount: number,
    bodyAddictionPreventionCount: number,
    bodyExerciseCount: number,
    bodyNutritionCount: number,
    bodySleepCount: number,
    mindThoughtsCount: number,
    mindFeelingsCount: number,
    mindMemoriesCount: number,
    mindKnowledgeCount: number,
    spiritCoreValueConnectionsCount: number,
    spiritSpiritualConnectionsCount: number,
    spiritSpiritualTransactionsCount: number,
    outcomeQuestionCount: number,
    answerSelections: AnswerSelection[] | undefined
  ) {
    this.practiceSelectionID = practiceSelectionID;
    this.questionTypeID = questionTypeID;
    this.practiceText = practiceText;
    this.outcomeCategoryID = outcomeCategoryID;
    this.outcomeSubcategoryID = outcomeSubcategoryID;
    this.sortOrder = sortOrder;
    this.category = category;
    this.categoryLogoURL = categoryLogoURL;
    this.subcategory = subcategory;
    this.subcategoryLogoURL = subcategoryLogoURL;
    this.answerLevel = answerLevel;
    this.scoreID = scoreID;
    this.topBox = topBox;
    this.worldEcosystemCount = worldEcosystemCount;
    this.worldSafetyCount = worldSafetyCount;
    this.worldWorkCount = worldWorkCount;
    this.worldSocialTiesCount = worldSocialTiesCount;
    this.worldRelationshipsCount = worldRelationshipsCount;
    this.worldFinancesCount = worldFinancesCount;
    this.bodyHealthcareCount = bodyHealthcareCount;
    this.bodyAddictionPreventionCount = bodyAddictionPreventionCount;
    this.bodyExerciseCount = bodyExerciseCount;
    this.bodyNutritionCount = bodyNutritionCount;
    this.bodySleepCount = bodySleepCount;
    this.mindThoughtsCount = mindThoughtsCount;
    this.mindFeelingsCount = mindFeelingsCount;
    this.mindMemoriesCount = mindMemoriesCount;
    this.mindKnowledgeCount = mindKnowledgeCount;
    this.spiritCoreValueConnectionsCount = spiritCoreValueConnectionsCount;
    this.spiritSpiritualConnectionsCount = spiritSpiritualConnectionsCount;
    this.spiritSpiritualTransactionsCount = spiritSpiritualTransactionsCount;
    this.outcomeQuestionCount = outcomeQuestionCount;
    this.answerSelections = answerSelections;
  }
}

export class PracticeSelectionUpdate {
  practiceSelectionID: number;
  answerLevel: number;

  constructor(practiceSelectionID: number, answerLevel: number) {
    this.practiceSelectionID = practiceSelectionID;
    this.answerLevel = answerLevel;
  }
}

export class OutcomeSelection {
  outcomeSelectionID: number;
  questionTypeID: number;
  outcomeText: string;
  outcomeCategoryID: number;
  category: string;
  logoURL: string;
  answerLevel: number;
  scoreID: number;
  topBox: number;
  practiceQuestionCount: number;
  energyQuestionCount: number;
  directionQuestionCount: number;
  belongingQuestionCount: number;
  joyQuestionCount: number;
  answerSelections: AnswerSelection[] | undefined;

  constructor(
    outcomeSelectionID: number,
    questionTypeID: number,
    outcomeText: string,
    outcomeCategoryID: number,
    category: string,
    logoURL: string,
    answerLevel: number,
    scoreID: number,
    topBox: number,
    practiceQuestionCount: number,
    energyQuestionCount: number,
    directionQuestionCount: number,
    belongingQuestionCount: number,
    joyQuestionCount: number,
    answerSelections: AnswerSelection[] | undefined
  ) {
    this.outcomeSelectionID = outcomeSelectionID;
    this.questionTypeID = questionTypeID;
    this.outcomeText = outcomeText;
    this.outcomeCategoryID = outcomeCategoryID;
    this.category = category;
    this.logoURL = logoURL;
    this.answerLevel = answerLevel;
    this.scoreID = scoreID;
    this.topBox = topBox;
    this.practiceQuestionCount = practiceQuestionCount;
    this.energyQuestionCount = energyQuestionCount;
    this.directionQuestionCount = directionQuestionCount;
    this.belongingQuestionCount = belongingQuestionCount;
    this.joyQuestionCount = joyQuestionCount;
    this.answerSelections = answerSelections;
  }
}

export class OutcomeSelectionResponse {
  success: boolean;
  message: string | null;
  data: OutcomeSelection[];

  constructor(success: boolean, message: string | null, data: OutcomeSelection[]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class OutcomeScores {
  energy: number;
  energyCategory: string;
  energyLogoURL: string;
  energyText: string;
  direction: number;
  directionCategory: string;
  directionLogoURL: string;
  directionText: string;
  belonging: number;
  belongingCategory: string;
  belongingLogoURL: string;
  belongingText: string;
  joy: number;
  joyCategory: string;
  joyLogoURL: string;
  joyText: string;
  wholeHealth: number;
  wholeHealthCategory: string;
  wholeHealthLogoURL: string;
  wholeHealthText: string;

  constructor(
    energy: number,
    energyCategory: string,
    energyLogoURL: string,
    energyText: string,
    direction: number,
    directionCategory: string,
    directionLogoURL: string,
    directionText: string,
    belonging: number,
    belongingCategory: string,
    belongingLogoURL: string,
    belongingText: string,
    joy: number,
    joyCategory: string,
    joyLogoURL: string,
    joyText: string,
    wholeHealth: number,
    wholeHealthCategory: string,
    wholeHealthLogoURL: string,
    wholeHealthText: string
  ) {
    this.energy = energy;
    this.energyCategory = energyCategory;
    this.energyLogoURL = energyLogoURL;
    this.energyText = energyText;
    this.direction = direction;
    this.directionCategory = directionCategory;
    this.directionLogoURL = directionLogoURL;
    this.directionText = directionText;
    this.belonging = belonging;
    this.belongingCategory = belongingCategory;
    this.belongingLogoURL = belongingLogoURL;
    this.belongingText = belongingText;
    this.joy = joy;
    this.joyCategory = joyCategory;
    this.joyLogoURL = joyLogoURL;
    this.joyText = joyText;
    this.wholeHealth = wholeHealth;
    this.wholeHealthCategory = wholeHealthCategory;
    this.wholeHealthLogoURL = wholeHealthLogoURL;
    this.wholeHealthText = wholeHealthText;
  }
}

export class OutcomeScoresResponse {
  success: boolean;
  message: string | null;
  data: OutcomeScores;

  constructor(success: boolean, message: string | null, data: OutcomeScores) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class OutcomeSelectionUpdate {
  outcomeSelectionID: number;
  answerLevel: number;

  constructor(outcomeSelectionID: number, answerLevel: number) {
    this.outcomeSelectionID = outcomeSelectionID;
    this.answerLevel = answerLevel;
  }
}

export class PracticeScores {
  firstName: string;
  lastAssessmentDate: string;
  worldEcosystem: number;
  worldEcosystemCategory: string;
  worldEcosystemLogoURL: string;
  worldEcosystemPracticeDescription: string;
  worldEcosystemDragonText: string;
  worldEcosystemDragonLogo: string;
  worldEcosystemDragonLabel: string;
  worldEcosystemDragonDescription: string;
  worldSafety: number;
  worldSafetyCategory: string;
  worldSafetyLogoURL: string;
  worldSafetyPracticeDescription: string;
  worldSafetyDragonText: string;
  worldSafetyDragonLogo: string;
  worldSafetyDragonLabel: string;
  worldSafetyDragonDescription: string;
  worldWork: number;
  worldWorkCategory: string;
  worldWorkLogoURL: string;
  worldWorkPracticeDescription: string;
  worldWorkDragonText: string;
  worldWorkDragonLogo: string;
  worldWorkDragonLabel: string;
  worldWorkDragonDescription: string;
  worldSocialTies: number;
  worldSocialTiesCategory: string;
  worldSocialTiesLogoURL: string;
  worldSocialTiesPracticeDescription: string;
  worldSocialTiesDragonText: string;
  worldSocialTiesDragonLogo: string;
  worldSocialTiesDragonLabel: string;
  worldSocialTiesDragonDescription: string;
  worldRelationships: number;
  worldRelationshipsCategory: string;
  worldRelationshipsLogoURL: string;
  worldRelationshipsPracticeDescription: string;
  worldRelationshipsDragonText: string;
  worldRelationshipsDragonLogo: string;
  worldRelationshipsDragonLabel: string;
  worldRelationshipsDragonDescription: string;
  worldFinances: number;
  worldFinancesCategory: string;
  worldFinancesLogoURL: string;
  worldFinancesPracticeDescription: string;
  worldFinancesDragonText: string;
  worldFinancesDragonLogo: string;
  worldFinancesDragonLabel: string;
  worldFinancesDragonDescription: string;
  world: number;
  worldCategory: string;
  worldLogoURL: string;
  bodyHealthcare: number;
  bodyHealthcareCategory: string;
  bodyHealthcareLogoURL: string;
  bodyHealthcarePracticeDescription: string;
  bodyHealthcareDragonText: string;
  bodyHealthcareDragonLogo: string;
  bodyHealthcareDragonLabel: string;
  bodyHealthcareDragonDescription: string;
  bodyAddictionPrevention: number;
  bodyAddictionPreventionCategory: string;
  bodyAddictionPreventionLogoURL: string;
  bodyAddictionPreventionPracticeDescription: string;
  bodyAddictionPreventionDragonText: string;
  bodyAddictionPreventionDragonLogo: string;
  bodyAddictionPreventionDragonLabel: string;
  bodyAddictionPreventionDragonDescription: string;
  bodyExercise: number;
  bodyExerciseCategory: string;
  bodyExerciseLogoURL: string;
  bodyExercisePracticeDescription: string;
  bodyExerciseDragonText: string;
  bodyExerciseDragonLogo: string;
  bodyExerciseDragonLabel: string;
  bodyExerciseDragonDescription: string;
  bodyNutrition: number;
  bodyNutritionCategory: string;
  bodyNutritionLogoURL: string;
  bodyNutritionPracticeDescription: string;
  bodyNutritionDragonText: string;
  bodyNutritionDragonLogo: string;
  bodyNutritionDragonLabel: string;
  bodyNutritionDragonDescription: string;
  bodySleep: number;
  bodySleepCategory: string;
  bodySleepLogoURL: string;
  bodySleepPracticeDescription: string;
  bodySleepDragonText: string;
  bodySleepDragonLogo: string;
  bodySleepDragonLabel: string;
  bodySleepDragonDescription: string;
  body: number;
  bodyCategory: string;
  bodyLogoURL: string;
  mindThoughts: number;
  mindThoughtsCategory: string;
  mindThoughtsLogoURL: string;
  mindThoughtsPracticeDescription: string;
  mindThoughtsDragonText: string;
  mindThoughtsDragonLogo: string;
  mindThoughtsDragonLabel: string;
  mindThoughtsDragonDescription: string;
  mindFeelings: number;
  mindFeelingsCategory: string;
  mindFeelingsLogoURL: string;
  mindFeelingsPracticeDescription: string;
  mindFeelingsDragonText: string;
  mindFeelingsDragonLogo: string;
  mindFeelingsDragonLabel: string;
  mindFeelingsDragonDescription: string;
  mindMemories: number;
  mindMemoriesCategory: string;
  mindMemoriesLogoURL: string;
  mindMemoriesPracticeDescription: string;
  mindMemoriesDragonText: string;
  mindMemoriesDragonLogo: string;
  mindMemoriesDragonLabel: string;
  mindMemoriesDragonDescription: string;
  mindKnowledge: number;
  mindKnowledgeCategory: string;
  mindKnowledgeLogoURL: string;
  mindKnowledgePracticeDescription: string;
  mindKnowledgeDragonText: string;
  mindKnowledgeDragonLogo: string;
  mindKnowledgeDragonLabel: string;
  mindKnowledgeDragonDescription: string;
  mind: number;
  mindCategory: string;
  mindLogoURL: string;
  spiritCoreValueConnections: number;
  spiritCoreValueConnectionsCategory: string;
  spiritCoreValueConnectionsLogoURL: string;
  spiritCoreValueConnectionsPracticeDescription: string;
  spiritCoreValueConnectionsDragonText: string;
  spiritCoreValueConnectionsDragonLogo: string;
  spiritCoreValueConnectionsDragonLabel: string;
  spiritCoreValueConnectionsDragonDescription: string;
  spiritSpiritualConnections: number;
  spiritSpiritualConnectionsCategory: string;
  spiritSpiritualConnectionsLogoURL: string;
  spiritSpiritualConnectionsPracticeDescription: string;
  spiritSpiritualConnectionsDragonText: string;
  spiritSpiritualConnectionsDragonLogo: string;
  spiritSpiritualConnectionsDragonLabel: string;
  spiritSpiritualConnectionsDragonDescription: string;
  spiritSpiritualTransactions: number;
  spiritSpiritualTransactionsCategory: string;
  spiritSpiritualTransactionsLogoURL: string;
  spiritSpiritualTransactionsPracticeDescription: string;
  spiritSpiritualTransactionsDragonText: string;
  spiritSpiritualTransactionsDragonLogo: string;
  spiritSpiritualTransactionsDragonLabel: string;
  spiritSpiritualTransactionsDragonDescription: string;
  spirit: number;
  spiritCategory: string;
  spiritLogoURL: string;

  constructor(
    firstName: string,
    lastAssessmentDate: string,
    worldEcosystem: number,
    worldEcosystemCategory: string,
    worldEcosystemLogoURL: string,
    worldEcosystemPracticeDescription: string,
    worldEcosystemDragonText: string,
    worldEcosystemDragonLogo: string,
    worldEcosystemDragonLabel: string,
    worldEcosystemDragonDescription: string,
    worldSafety: number,
    worldSafetyCategory: string,
    worldSafetyLogoURL: string,
    worldSafetyPracticeDescription: string,
    worldSafetyDragonText: string,
    worldSafetyDragonLogo: string,
    worldSafetyDragonLabel: string,
    worldSafetyDragonDescription: string,
    worldWork: number,
    worldWorkCategory: string,
    worldWorkLogoURL: string,
    worldWorkPracticeDescription: string,
    worldWorkDragonText: string,
    worldWorkDragonLogo: string,
    worldWorkDragonLabel: string,
    worldWorkDragonDescription: string,
    worldSocialTies: number,
    worldSocialTiesCategory: string,
    worldSocialTiesLogoURL: string,
    worldSocialTiesPracticeDescription: string,
    worldSocialTiesDragonText: string,
    worldSocialTiesDragonLogo: string,
    worldSocialTiesDragonLabel: string,
    worldSocialTiesDragonDescription: string,
    worldRelationships: number,
    worldRelationshipsCategory: string,
    worldRelationshipsLogoURL: string,
    worldRelationshipsPracticeDescription: string,
    worldRelationshipsDragonText: string,
    worldRelationshipsDragonLogo: string,
    worldRelationshipsDragonLabel: string,
    worldRelationshipsDragonDescription: string,
    worldFinances: number,
    worldFinancesCategory: string,
    worldFinancesLogoURL: string,
    worldFinancesPracticeDescription: string,
    worldFinancesDragonText: string,
    worldFinancesDragonLogo: string,
    worldFinancesDragonLabel: string,
    worldFinancesDragonDescription: string,
    world: number,
    worldCategory: string,
    worldLogoURL: string,
    bodyHealthcare: number,
    bodyHealthcareCategory: string,
    bodyHealthcareLogoURL: string,
    bodyHealthcarePracticeDescription: string,
    bodyHealthcareDragonText: string,
    bodyHealthcareDragonLogo: string,
    bodyHealthcareDragonLabel: string,
    bodyHealthcareDragonDescription: string,
    bodyAddictionPrevention: number,
    bodyAddictionPreventionCategory: string,
    bodyAddictionPreventionLogoURL: string,
    bodyAddictionPreventionPracticeDescription: string,
    bodyAddictionPreventionDragonText: string,
    bodyAddictionPreventionDragonLogo: string,
    bodyAddictionPreventionDragonLabel: string,
    bodyAddictionPreventionDragonDescription: string,
    bodyExercise: number,
    bodyExerciseCategory: string,
    bodyExerciseLogoURL: string,
    bodyExercisePracticeDescription: string,
    bodyExerciseDragonText: string,
    bodyExerciseDragonLogo: string,
    bodyExerciseDragonLabel: string,
    bodyExerciseDragonDescription: string,
    bodyNutrition: number,
    bodyNutritionCategory: string,
    bodyNutritionLogoURL: string,
    bodyNutritionPracticeDescription: string,
    bodyNutritionDragonText: string,
    bodyNutritionDragonLogo: string,
    bodyNutritionDragonLabel: string,
    bodyNutritionDragonDescription: string,
    bodySleep: number,
    bodySleepCategory: string,
    bodySleepLogoURL: string,
    bodySleepPracticeDescription: string,
    bodySleepDragonText: string,
    bodySleepDragonLogo: string,
    bodySleepDragonLabel: string,
    bodySleepDragonDescription: string,
    body: number,
    bodyCategory: string,
    bodyLogoURL: string,
    mindThoughts: number,
    mindThoughtsCategory: string,
    mindThoughtsLogoURL: string,
    mindThoughtsPracticeDescription: string,
    mindThoughtsDragonText: string,
    mindThoughtsDragonLogo: string,
    mindThoughtsDragonLabel: string,
    mindThoughtsDragonDescription: string,
    mindFeelings: number,
    mindFeelingsCategory: string,
    mindFeelingsLogoURL: string,
    mindFeelingsPracticeDescription: string,
    mindFeelingsDragonText: string,
    mindFeelingsDragonLogo: string,
    mindFeelingsDragonLabel: string,
    mindFeelingsDragonDescription: string,
    mindMemories: number,
    mindMemoriesCategory: string,
    mindMemoriesLogoURL: string,
    mindMemoriesPracticeDescription: string,
    mindMemoriesDragonText: string,
    mindMemoriesDragonLogo: string,
    mindMemoriesDragonLabel: string,
    mindMemoriesDragonDescription: string,
    mindKnowledge: number,
    mindKnowledgeCategory: string,
    mindKnowledgeLogoURL: string,
    mindKnowledgePracticeDescription: string,
    mindKnowledgeDragonText: string,
    mindKnowledgeDragonLogo: string,
    mindKnowledgeDragonLabel: string,
    mindKnowledgeDragonDescription: string,
    mind: number,
    mindCategory: string,
    mindLogoURL: string,
    spiritCoreValueConnections: number,
    spiritCoreValueConnectionsCategory: string,
    spiritCoreValueConnectionsLogoURL: string,
    spiritCoreValueConnectionsPracticeDescription: string,
    spiritCoreValueConnectionsDragonText: string,
    spiritCoreValueConnectionsDragonLogo: string,
    spiritCoreValueConnectionsDragonLabel: string,
    spiritCoreValueConnectionsDragonDescription: string,
    spiritSpiritualConnections: number,
    spiritSpiritualConnectionsCategory: string,
    spiritSpiritualConnectionsLogoURL: string,
    spiritSpiritualConnectionsPracticeDescription: string,
    spiritSpiritualConnectionsDragonText: string,
    spiritSpiritualConnectionsDragonLogo: string,
    spiritSpiritualConnectionsDragonLabel: string,
    spiritSpiritualConnectionsDragonDescription: string,
    spiritSpiritualTransactions: number,
    spiritSpiritualTransactionsCategory: string,
    spiritSpiritualTransactionsLogoURL: string,
    spiritSpiritualTransactionsPracticeDescription: string,
    spiritSpiritualTransactionsDragonText: string,
    spiritSpiritualTransactionsDragonLogo: string,
    spiritSpiritualTransactionsDragonLabel: string,
    spiritSpiritualTransactionsDragonDescription: string,
    spirit: number,
    spiritCategory: string,
    spiritLogoURL: string
  ) {
    this.firstName = firstName;
    this.lastAssessmentDate = lastAssessmentDate;
    this.worldEcosystem = worldEcosystem;
    this.worldEcosystemCategory = worldEcosystemCategory;
    this.worldEcosystemLogoURL = worldEcosystemLogoURL;
    this.worldEcosystemPracticeDescription = worldEcosystemPracticeDescription;
    this.worldEcosystemDragonText = worldEcosystemDragonText;
    this.worldEcosystemDragonLogo = worldEcosystemDragonLogo;
    this.worldEcosystemDragonLabel = worldEcosystemDragonLabel;
    this.worldEcosystemDragonDescription = worldEcosystemDragonDescription;
    this.worldSafety = worldSafety;
    this.worldSafetyCategory = worldSafetyCategory;
    this.worldSafetyLogoURL = worldSafetyLogoURL;
    this.worldSafetyPracticeDescription = worldSafetyPracticeDescription;
    this.worldSafetyDragonText = worldSafetyDragonText;
    this.worldSafetyDragonLogo = worldSafetyDragonLogo;
    this.worldSafetyDragonLabel = worldSafetyDragonLabel;
    this.worldSafetyDragonDescription = worldSafetyDragonDescription;
    this.worldWork = worldWork;
    this.worldWorkCategory = worldWorkCategory;
    this.worldWorkLogoURL = worldWorkLogoURL;
    this.worldWorkPracticeDescription = worldWorkPracticeDescription;
    this.worldWorkDragonText = worldWorkDragonText;
    this.worldWorkDragonLogo = worldWorkDragonLogo;
    this.worldWorkDragonLabel = worldWorkDragonLabel;
    this.worldWorkDragonDescription = worldWorkDragonDescription;
    this.worldSocialTies = worldSocialTies;
    this.worldSocialTiesCategory = worldSocialTiesCategory;
    this.worldSocialTiesLogoURL = worldSocialTiesLogoURL;
    this.worldSocialTiesPracticeDescription = worldSocialTiesPracticeDescription;
    this.worldSocialTiesDragonText = worldSocialTiesDragonText;
    this.worldSocialTiesDragonLogo = worldSocialTiesDragonLogo;
    this.worldSocialTiesDragonLabel = worldSocialTiesDragonLabel;
    this.worldSocialTiesDragonDescription = worldSocialTiesDragonDescription;
    this.worldRelationships = worldRelationships;
    this.worldRelationshipsCategory = worldRelationshipsCategory;
    this.worldRelationshipsLogoURL = worldRelationshipsLogoURL;
    this.worldRelationshipsPracticeDescription = worldRelationshipsPracticeDescription;
    this.worldRelationshipsDragonText = worldRelationshipsDragonText;
    this.worldRelationshipsDragonLogo = worldRelationshipsDragonLogo;
    this.worldRelationshipsDragonLabel = worldRelationshipsDragonLabel;
    this.worldRelationshipsDragonDescription = worldRelationshipsDragonDescription;
    this.worldFinances = worldFinances;
    this.worldFinancesCategory = worldFinancesCategory;
    this.worldFinancesLogoURL = worldFinancesLogoURL;
    this.worldFinancesPracticeDescription = worldFinancesPracticeDescription;
    this.worldFinancesDragonText = worldFinancesDragonText;
    this.worldFinancesDragonLogo = worldFinancesDragonLogo;
    this.worldFinancesDragonLabel = worldFinancesDragonLabel;
    this.worldFinancesDragonDescription = worldFinancesDragonDescription;
    this.world = world;
    this.worldCategory = worldCategory;
    this.worldLogoURL = worldLogoURL;
    this.bodyHealthcare = bodyHealthcare;
    this.bodyHealthcareCategory = bodyHealthcareCategory;
    this.bodyHealthcareLogoURL = bodyHealthcareLogoURL;
    this.bodyHealthcarePracticeDescription = bodyHealthcarePracticeDescription;
    this.bodyHealthcareDragonText = bodyHealthcareDragonText;
    this.bodyHealthcareDragonLogo = bodyHealthcareDragonLogo;
    this.bodyHealthcareDragonLabel = bodyHealthcareDragonLabel;
    this.bodyHealthcareDragonDescription = bodyHealthcareDragonDescription;
    this.bodyAddictionPrevention = bodyAddictionPrevention;
    this.bodyAddictionPreventionCategory = bodyAddictionPreventionCategory;
    this.bodyAddictionPreventionLogoURL = bodyAddictionPreventionLogoURL;
    this.bodyAddictionPreventionPracticeDescription = bodyAddictionPreventionPracticeDescription;
    this.bodyAddictionPreventionDragonText = bodyAddictionPreventionDragonText;
    this.bodyAddictionPreventionDragonLogo = bodyAddictionPreventionDragonLogo;
    this.bodyAddictionPreventionDragonLabel = bodyAddictionPreventionDragonLabel;
    this.bodyAddictionPreventionDragonDescription = bodyAddictionPreventionDragonDescription;
    this.bodyExercise = bodyExercise;
    this.bodyExerciseCategory = bodyExerciseCategory;
    this.bodyExerciseLogoURL = bodyExerciseLogoURL;
    this.bodyExercisePracticeDescription = bodyExercisePracticeDescription;
    this.bodyExerciseDragonText = bodyExerciseDragonText;
    this.bodyExerciseDragonLogo = bodyExerciseDragonLogo;
    this.bodyExerciseDragonLabel = bodyExerciseDragonLabel;
    this.bodyExerciseDragonDescription = bodyExerciseDragonDescription;
    this.bodyNutrition = bodyNutrition;
    this.bodyNutritionCategory = bodyNutritionCategory;
    this.bodyNutritionLogoURL = bodyNutritionLogoURL;
    this.bodyNutritionPracticeDescription = bodyNutritionPracticeDescription;
    this.bodyNutritionDragonText = bodyNutritionDragonText;
    this.bodyNutritionDragonLogo = bodyNutritionDragonLogo;
    this.bodyNutritionDragonLabel = bodyNutritionDragonLabel;
    this.bodyNutritionDragonDescription = bodyNutritionDragonDescription;
    this.bodySleep = bodySleep;
    this.bodySleepCategory = bodySleepCategory;
    this.bodySleepLogoURL = bodySleepLogoURL;
    this.bodySleepPracticeDescription = bodySleepPracticeDescription;
    this.bodySleepDragonText = bodySleepDragonText;
    this.bodySleepDragonLogo = bodySleepDragonLogo;
    this.bodySleepDragonLabel = bodySleepDragonLabel;
    this.bodySleepDragonDescription = bodySleepDragonDescription;
    this.body = body;
    this.bodyCategory = bodyCategory;
    this.bodyLogoURL = bodyLogoURL;
    this.mindThoughts = mindThoughts;
    this.mindThoughtsCategory = mindThoughtsCategory;
    this.mindThoughtsLogoURL = mindThoughtsLogoURL;
    this.mindThoughtsPracticeDescription = mindThoughtsPracticeDescription;
    this.mindThoughtsDragonText = mindThoughtsDragonText;
    this.mindThoughtsDragonLogo = mindThoughtsDragonLogo;
    this.mindThoughtsDragonLabel = mindThoughtsDragonLabel;
    this.mindThoughtsDragonDescription = mindThoughtsDragonDescription;
    this.mindFeelings = mindFeelings;
    this.mindFeelingsCategory = mindFeelingsCategory;
    this.mindFeelingsLogoURL = mindFeelingsLogoURL;
    this.mindFeelingsPracticeDescription = mindFeelingsPracticeDescription;
    this.mindFeelingsDragonText = mindFeelingsDragonText;
    this.mindFeelingsDragonLogo = mindFeelingsDragonLogo;
    this.mindFeelingsDragonLabel = mindFeelingsDragonLabel;
    this.mindFeelingsDragonDescription = mindFeelingsDragonDescription;
    this.mindMemories = mindMemories;
    this.mindMemoriesCategory = mindMemoriesCategory;
    this.mindMemoriesLogoURL = mindMemoriesLogoURL;
    this.mindMemoriesPracticeDescription = mindMemoriesPracticeDescription;
    this.mindMemoriesDragonText = mindMemoriesDragonText;
    this.mindMemoriesDragonLogo = mindMemoriesDragonLogo;
    this.mindMemoriesDragonLabel = mindMemoriesDragonLabel;
    this.mindMemoriesDragonDescription = mindMemoriesDragonDescription;
    this.mindKnowledge = mindKnowledge;
    this.mindKnowledgeCategory = mindKnowledgeCategory;
    this.mindKnowledgeLogoURL = mindKnowledgeLogoURL;
    this.mindKnowledgePracticeDescription = mindKnowledgePracticeDescription;
    this.mindKnowledgeDragonText = mindKnowledgeDragonText;
    this.mindKnowledgeDragonLogo = mindKnowledgeDragonLogo;
    this.mindKnowledgeDragonLabel = mindKnowledgeDragonLabel;
    this.mindKnowledgeDragonDescription = mindKnowledgeDragonDescription;
    this.mind = mind;
    this.mindCategory = mindCategory;
    this.mindLogoURL = mindLogoURL;
    this.spiritCoreValueConnections = spiritCoreValueConnections;
    this.spiritCoreValueConnectionsCategory = spiritCoreValueConnectionsCategory;
    this.spiritCoreValueConnectionsLogoURL = spiritCoreValueConnectionsLogoURL;
    this.spiritCoreValueConnectionsPracticeDescription = spiritCoreValueConnectionsPracticeDescription;
    this.spiritCoreValueConnectionsDragonText = spiritCoreValueConnectionsDragonText;
    this.spiritCoreValueConnectionsDragonLogo = spiritCoreValueConnectionsDragonLogo;
    this.spiritCoreValueConnectionsDragonLabel = spiritCoreValueConnectionsDragonLabel;
    this.spiritCoreValueConnectionsDragonDescription = spiritCoreValueConnectionsDragonDescription;
    this.spiritSpiritualConnections = spiritSpiritualConnections;
    this.spiritSpiritualConnectionsCategory = spiritSpiritualConnectionsCategory;
    this.spiritSpiritualConnectionsLogoURL = spiritSpiritualConnectionsLogoURL;
    this.spiritSpiritualConnectionsPracticeDescription = spiritSpiritualConnectionsPracticeDescription;
    this.spiritSpiritualConnectionsDragonText = spiritSpiritualConnectionsDragonText;
    this.spiritSpiritualConnectionsDragonLogo = spiritSpiritualConnectionsDragonLogo;
    this.spiritSpiritualConnectionsDragonLabel = spiritSpiritualConnectionsDragonLabel;
    this.spiritSpiritualConnectionsDragonDescription = spiritSpiritualConnectionsDragonDescription;
    this.spiritSpiritualTransactions = spiritSpiritualTransactions;
    this.spiritSpiritualTransactionsCategory = spiritSpiritualTransactionsCategory;
    this.spiritSpiritualTransactionsLogoURL = spiritSpiritualTransactionsLogoURL;
    this.spiritSpiritualTransactionsPracticeDescription = spiritSpiritualTransactionsPracticeDescription;
    this.spiritSpiritualTransactionsDragonText = spiritSpiritualTransactionsDragonText;
    this.spiritSpiritualTransactionsDragonLogo = spiritSpiritualTransactionsDragonLogo;
    this.spiritSpiritualTransactionsDragonLabel = spiritSpiritualTransactionsDragonLabel;
    this.spiritSpiritualTransactionsDragonDescription = spiritSpiritualTransactionsDragonDescription;
    this.spirit = spirit;
    this.spiritCategory = spiritCategory;
    this.spiritLogoURL = spiritLogoURL;
  }
}

export class PracticeScoresResponse {
  success: boolean;
  message: string | null;
  data: PracticeScores;

  constructor(success: boolean, message: string | null, data: PracticeScores) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export class QuestionGenericUpdate {
  readonly id: number;
  readonly answerLevel: number;

  constructor(id: number, answerLevel: number) {
    this.id = id;
    this.answerLevel = answerLevel;
  }
}
