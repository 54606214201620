/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import { useAppContent } from "../../../hooks/useAppContent";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";

import "../styles.scss";

export const California: FunctionComponent = (): ReactElement => {
  const { appContentLines, printContentText } = useAppContent(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });
  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  if (appContentLines.length >= 19) {
    return (
      <div className="pol-container">
        {/* heading */}
        <div className="pol-header">
          <SeityBackButton wrapperClassName="sd-back-button" />
          <h1>{printContentText(0)}</h1>
          <h3>{printContentText(1)}</h3>
        </div>
        {/* initial blurb */}
        <div className="pol-bubble-grey">
          <div className="margin-style-7">
            <p>{printContentText(2)}</p>
          </div>
        </div>
        {/* categories of information we collect, use and disclose
        for business purposes */}
        <div className="pol-content mt-5">
          <div className="pol-bubble-blue">
            <h4 className="mt-2">{printContentText(3)}</h4>
            <p>{printContentText(4)}</p>
            <div className="pol-list-inner margin-style-6">
              <ul>
                <li>{printContentText(5)}</li>
                <li>{printContentText(6)}</li>
                <li>{printContentText(7)}</li>
                <li>{printContentText(8)}</li>
                <li>{printContentText(9)}</li>
              </ul>
            </div>
          </div>
          {/* Your rights */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(10)}</h4>
            <p>{printContentText(11)}</p>
            <div className="margin-style-3">
              <p>{printContentText(12)}</p>
            </div>
          </div>
          {/* Shine the light act */}
          <div className="pol-bubble-blue">
            <h4>{printContentText(13)}</h4>
            <p>{printContentText(14)}</p>
            <div className="margin-style-3">
              <p className="mb-4">{printContentText(15)}</p>
            </div>
            <div className="margin-style-6">
              <p>{printContentText(16)}</p>
            </div>
            <p className="mb-2">{printContentText(17)}</p>
            <div className="margin-style-6">
              <p className="mb-2">{printContentText(18)}</p>
            </div>
            <div className="margin-style-6 mb-3">
              <p className="mb-2">{printContentText(19)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
