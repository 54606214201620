/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import "./styles.scss";

export interface SeityRatingBarProps {
  value: SeityRatingValue;
  onValueChange: (value: SeityRatingValue) => void;
  containerStyle?: {};
  itemStyle?: {};
}

export type SeityRatingValue = 0 | 1 | 2 | 3 | 4 | 5;

export const SeityRatingBar = ({ value, onValueChange, containerStyle, itemStyle }: SeityRatingBarProps) => {
  return (
    <div className="srb-container" style={containerStyle}>
      <div
        onClick={() => {
          onValueChange(1);
        }}
      >
        <img
          style={itemStyle}
          src={
            value > 0
              ? require("../../../assets/graphics/filled_in_star.png").default
              : require("../../../assets/graphics/unfilled_star.png").default
          }
        />
      </div>
      <div
        onClick={() => {
          onValueChange(2);
        }}
      >
        <img
          style={itemStyle}
          src={
            value > 1
              ? require("../../../assets/graphics/filled_in_star.png").default
              : require("../../../assets/graphics/unfilled_star.png").default
          }
        />
      </div>
      <div
        onClick={() => {
          onValueChange(3);
        }}
      >
        <img
          style={itemStyle}
          src={
            value > 2
              ? require("../../../assets/graphics/filled_in_star.png").default
              : require("../../../assets/graphics/unfilled_star.png").default
          }
        />
      </div>
      <div
        onClick={() => {
          onValueChange(4);
        }}
      >
        <img
          style={itemStyle}
          src={
            value > 3
              ? require("../../../assets/graphics/filled_in_star.png").default
              : require("../../../assets/graphics/unfilled_star.png").default
          }
        />
      </div>
      <div
        onClick={() => {
          onValueChange(5);
        }}
      >
        <img
          style={itemStyle}
          src={
            value > 4
              ? require("../../../assets/graphics/filled_in_star.png").default
              : require("../../../assets/graphics/unfilled_star.png").default
          }
        />
      </div>
    </div>
  );
};

export default SeityRatingBar;
