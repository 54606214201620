import { createSlice } from "@reduxjs/toolkit";
import {
  SeityCheckInCrisisInformationPayloadResponse,
  SeityCheckInCrisisInformationResponse,
  SeityCheckInDuePayloadResponse,
  SeityCheckInDueResponse,
  SeityCheckInIncentivePayloadResponse,
  SeityCheckInIncentiveResponse,
  SeityCheckInPostPayloadResponse,
  SeityCheckInPostResponse
} from "../../api/checkInTypes";
import apiClientWithAuth from "../../api/apiClient";

export interface CheckInState {
  readonly isLoading: boolean;
  readonly checkInError: string | null;
  readonly dueResponse: SeityCheckInDuePayloadResponse | null;
  readonly insertResponse: SeityCheckInPostResponse | null;
  readonly incentiveResponse: SeityCheckInIncentivePayloadResponse | null;
  readonly crisisInfoResponse: SeityCheckInCrisisInformationPayloadResponse | null;
}

const setInitialState = {
  isLoading: false,
  checkInError: null,
  dueResponse: null,
  insertResponse: null,
  incentiveResponse: null,
  crisisInfoResponse: null
} as CheckInState;

const checkInSlice = createSlice({
  name: "checkIn",
  initialState: setInitialState,
  reducers: {
    setCheckInDueResponse(state, action) {
      state.dueResponse = action.payload.checkInDueResponse;
      state.isLoading = false;
    },
    setCheckInInsertResponse(state, action) {
      state.insertResponse = action.payload.checkInInsertResponse;
      state.isLoading = false;
    },
    setCheckInIncentiveResponse(state, action) {
      state.incentiveResponse = action.payload.checkInIncentiveResponse;
      state.isLoading = false;
    },
    setCheckInCrisisResponse(state, action) {
      state.crisisInfoResponse = action.payload.checkInCrisisInfoResponse;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    setCheckInError(state, action) {
      state.checkInError = action.payload.checkInError;
      state.isLoading = false;
    },
    resetCheckInSlice: () => setInitialState
  }
});

export const {
  setIsLoading,
  setCheckInError,
  setCheckInDueResponse,
  setCheckInInsertResponse,
  setCheckInIncentiveResponse,
  setCheckInCrisisResponse,
  resetCheckInSlice
} = checkInSlice.actions;
export default checkInSlice.reducer;

export const sendCheckInDueRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    console.log("Getting check in due response...");
    const response = await apiClientWithAuth.get<SeityCheckInDueResponse>("CheckIn/Due");
    dispatch(setCheckInDueResponse({ checkInDueResponse: response.data.data }));
  } catch (err) {
    console.error(err);
    handleError(err, dispatch);
  }
};

export const sendCheckInResults =
  (
    energyValue: number,
    directionValue: number,
    belongingValue: number,
    joyValue: number,
    callback?: (checkInInsertResponse: SeityCheckInPostPayloadResponse | null) => void
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      const response = await apiClientWithAuth.post<SeityCheckInPostResponse>("CheckIn", {
        energy: energyValue,
        direction: directionValue,
        belonging: belongingValue,
        joy: joyValue
      });
      if (response.data.success) {
        dispatch(setCheckInInsertResponse({ checkInInsertResponse: response.data.data }));
      }
      if (callback) {
        callback(response.data.data);
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };

export const sendIncentiveInfoRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    console.log("Getting Check In Incentive Info...");
    const response = await apiClientWithAuth.get<SeityCheckInIncentiveResponse>("CheckIn/IncentiveInformation");
    console.log("Received check in incentive info response.");
    if (response.data.success) {
      dispatch(
        setCheckInIncentiveResponse({
          checkInIncentiveResponse: response.data.data
        })
      );
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendCrisisInfoRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    console.log("Getting Check In Crisis Info...");
    const response = await apiClientWithAuth.get<SeityCheckInCrisisInformationResponse>("CheckIn/CrisisInformation");
    console.log("Received Check In incentive info response.");
    dispatch(
      setCheckInCrisisResponse({
        checkInCrisisInfoResponse: response.data.data
      })
    );
  } catch (err) {
    handleError(err, dispatch);
  }
};

function handleError(err: Error, dispatch: any) {
  console.error(err);
  dispatch(setCheckInError({ checkInError: err.toString() }));
}
