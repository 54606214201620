import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Characterization } from "../../api/myStory/types/myStoryTypes";
import { AppThunk } from "../../app/store";
import apiClientWithAuth from "../../api/apiClient";

export interface CharacterizationState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly characterization: Characterization;
}

const initCharacterization = () => {
  return {
    fourCoreValuesShort: [],
    outcomes: [],
    wellbeingGraph: [],
    energyGraph: [],
    directionGraph: [],
    belongingGraph: [],
    joyGraph: [],
    assessmentComplete: false,
    assessmentDue: false,
    assessmentSummary: [],
    information: "",
    syncsEnabled: false,
    syncsDescription: "",
    journalsEnabled: false,
    journalsDescription: "",
    intentionsEnabled: false,
    intentionsDescription: "",
    storiesEnabled: false,
    storiesDescription: "",
    assessmentLevelID: 0,
    displayWellbeingData: false,
    assessmentDate: "",
    assessmentStarted: false,
    dateTimeGraph: [],
    assessmentState: 0,
    checkInDue: false,
    eulaNeeded: false,
    forceNewPassword: false,
    emailVerificationNeeded: false,
    languageID: 1,
    isCVOnly: false,
    translationEnabled: false
  };
};

const setInitialState = {
  error: null,
  isLoading: false,
  characterization: initCharacterization()
} as CharacterizationState;

const storySlice = createSlice({
  name: "characterization-characterization",
  initialState: setInitialState,
  reducers: {
    setCharacterization(state, action: PayloadAction<Characterization>) {
      state.characterization = {
        ...action.payload
      };
    },
    setLanguageID(state, action) {
      const { langID } = action.payload;
      state.characterization.languageID = langID;
    },
    setCharacterizationError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setEULANeeded(state) {
      state.characterization.eulaNeeded = false;
    },
    setForceNewPasswordFalse(state) {
      state.characterization.forceNewPassword = false;
    },
    resetCharacterizationSlice: () => setInitialState
  }
});

export const {
  setCharacterization,
  setLanguageID,
  setCharacterizationError,
  clearApiError,
  setIsLoading,
  setEULANeeded,
  setForceNewPasswordFalse,
  resetCharacterizationSlice,
} = storySlice.actions;
export default storySlice.reducer;

export const getCharacterizationRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(clearApiError({}));
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("Story/Characterization");
    if (res.data.success) {
      const data: any = res.data.data;
      dispatch(setCharacterization(data));
    }
    dispatch(setIsLoading(false));
  } catch (err) {
    console.error(err);
    dispatch(setCharacterizationError({ error: err.toString() }));
  }
};
