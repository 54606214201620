export class SeityResponse {
  success: boolean;
  message: string | null;

  constructor(success: boolean, message: string | null) {
    this.success = success;
    this.message = message;
  }
}

export function getErrorMessageFromResponse(success: boolean, message: string | null): string | null {
  if (success === false && !message) {
    return "Error message from API contains no error message content but contains an error.";
  }
  if (success === false && message) {
    return message;
  }
  return null;
}

export class SeityCoreValue {
  id: number;
  name: string;
  imageFilename: string;
  text: string;
  mainDescription: string;
  altDescription: string;
  quote: string;
  index: number; // Not 0-based; starts at 1

  constructor(
    id: number,
    name: string,
    imageFilename: string,
    text: string,
    mainDescription: string,
    altDescription: string,
    quote: string,
    index: number
  ) {
    this.id = id;
    this.name = name;
    this.imageFilename = imageFilename;
    this.text = text;
    this.mainDescription = mainDescription;
    this.altDescription = altDescription;
    this.quote = quote;
    this.index = index;
  }
}

export enum SeityOutcomeCategory {
  Belonging,
  Energy,
  Joy,
  Direction
}

export function OutcomeCategoryToString(category: SeityOutcomeCategory): string {
  switch (category) {
    case SeityOutcomeCategory.Belonging:
      return "Belonging";
    case SeityOutcomeCategory.Direction:
      return "Direction";
    case SeityOutcomeCategory.Energy:
      return "Energy";
    case SeityOutcomeCategory.Joy:
      return "Joy";
  }
}
