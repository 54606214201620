/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { MoreInfoIcon } from "../SeityInfoIcon/InfoIcon";
import { SeitySelectBox, SeitySelectBoxProps } from "./SeitySelectBox";

import "./styles.scss";

interface SeityFormSelectBoxProps {
  controlId?: string;
  inputLabelText: string;
  moreInfoText?: string;
  isInvalid?: boolean;
  className?: string;
}

export const SeityFormSelectBox = (props: SeityFormSelectBoxProps & SeitySelectBoxProps) => {
  const { onChange, value, children, inputLabelText, moreInfoText, isInvalid, inputStyle, className = "", id } = props;

  return (
    <div className={`seity-select-box-form-group ${className}`}>
      <label>{inputLabelText}</label>
      {moreInfoText && <MoreInfoIcon text={moreInfoText} imageStyle={{ paddingBottom: "8px", marginLeft: "5px" }} />}
      <SeitySelectBox isInvalid={isInvalid} onChange={onChange} id={id} inputStyle={inputStyle} value={value}>
        {children}
      </SeitySelectBox>
    </div>
  );
};
