import { FunctionComponent } from "react";
import SeityCVImage from "../../../../_core/components/SeityCVImage";

export type ResultItemProps = {
  cvId: number;
  text: string;
  isSelected: boolean;
};

export const ResultItem: FunctionComponent<ResultItemProps> = (props) => {
  const { text, cvId, isSelected } = props;

  if (cvId === 0) {
    return <div className="dv-values-placeholder" />;
  }
  return (
    <div className={`dv-values-item ${isSelected && "dv-values-item-selected"}`}>
      <SeityCVImage cvId={cvId} size={60} classname="dv-values-item__cvImg" />
      <h4>{text}</h4>
    </div>
  );
};
