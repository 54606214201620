import { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { SeityBackButton } from "../../../../../_core/components/SeityBackButton";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";
import { sendGetSeityAssets } from "../../guideSlice";
import { SEITY_SCIENCE_ASSET_TYPE } from "../../Learn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/rootReducer";
import SeityLoader from "../../../../../_core/components/SeityLoader";
import { TSAssetItem } from "../../Slider/TSAssetItem";
import { SeityScienceAsset } from "../../../../../api/referenceGuide/referenceGuideTypes";

export const SCAssetList: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, seityScienceAssets } = useSelector((state: RootState) => {
    return state.guide;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(sendGetSeityAssets(SEITY_SCIENCE_ASSET_TYPE));
  }, []);

  if (isLoading) return <SeityLoader />;

  return (
    <div className="see-all-container-wrapper seity-main-fullwidth">
      <SeityBackButton wrapperClassName="msl-back-button" />
      <h1>{strings.seityScience}</h1>
      <div className="sciences-gallery-wrapper">
        {seityScienceAssets.length > 0 &&
          seityScienceAssets.map((item, index) => {
            return (
              <TSAssetItem
                key={index}
                item={item as SeityScienceAsset}
                onClick={() => {
                  history.push({
                    pathname: `/learn/science/${item.assetID}`,
                    state: { item: item }
                  });
                }}
              />
            );
          })}
      </div>
    </div>
  );
};
