/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { SeityButton } from "../SeityButton";
import "./styles.scss";

interface SeityPrimaryCancelButtonsProps {
  cancelClassName?: string;
  cancelAction?: () => void | null;
  cancelTitle?: string;
  cancelButtonType?: "cancel" | "discard" | "black";
  primaryClassName?: string;
  primaryAction?: () => void;
  primaryTitle?: string;
  primaryButtonType?: string;
  errorMessage?: string | null;
  primaryDisabled?: boolean;
  showCancel: boolean;
  isVertical?: boolean;
}

export const SeityPrimaryCancelButtons = (props: SeityPrimaryCancelButtonsProps) => {
  return (
    <div className="seity-primary-cancel-nav-container">
      <div className="seity-primary-cancel-nav-container-error" style={{ display: props.errorMessage ? "block" : "none" }}>
        {props.errorMessage !== null ? props.errorMessage : ""}
      </div>
      <div className="seity-primary-cancel-nav-container-buttons" style={{ flexDirection: props.isVertical ? "column-reverse" : "row" }}>
        {props.showCancel && (
          <SeityButton
            className={`${props.cancelClassName}`}
            onClick={props.cancelAction}
            type={props.cancelButtonType || "cancel"}
            disabled={false}
            label={props.cancelTitle}
            testId="backButton"
          />
        )}
        <SeityButton
          className={`${props.primaryClassName}`}
          onClick={props.primaryAction}
          type="next"
          disabled={props.primaryDisabled}
          label={props.primaryTitle}
          testId="nextButton"
        />
      </div>
    </div>
  );
};

export default SeityPrimaryCancelButtons;
