import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { SyncDetail } from "../../../api/sync/types/syncTypes";
import { getSyncDetail, putSync } from "../../../api/sync/seityHealthAPI-Sync";
import { formatImagePath } from "../../../api/utils";
import { IMAGE_URL } from "../../../api/apiConstants";

export interface SyncListState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly syncDetail: SyncDetail;
}

const initSyncDetail = () => {
  return {
    objectiveID: 0,
    title: "",
    subHeader: "",
    author: "",
    topEmotionsImages: [],
    totalEmotionSelectionCount: 0,
    image: "",
    imageAlt: "",
    status: "",
    content: "",
    knowledgeCheckCompleted: false,
    userSelectedEmotions: [],
    journal: null
  };
};

const setInitialState = {
  error: null,
  isLoading: false,
  syncDetail: initSyncDetail()
} as unknown as SyncListState;

const storySlice = createSlice({
  name: "syncdetail-syncdetail",
  initialState: setInitialState,
  reducers: {
    setSyncDetail(state, action: PayloadAction<SyncDetail>) {
      state.syncDetail = action.payload;
      state.isLoading = false;
    },
    setSyncDetailError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    resetSyncDetailSlice: () => setInitialState
  }
});

export const { setSyncDetail, setSyncDetailError, clearApiError, setIsLoading, resetSyncDetailSlice } = storySlice.actions;
export default storySlice.reducer;

export const getSyncDetailRequest =
  (objectiveID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      dispatch(setIsLoading({}));
      const syncDetailResponse = await getSyncDetail(token, objectiveID);
      if (syncDetailResponse.success) {
        const data: any = syncDetailResponse.data;
        data.image = formatImagePath(data.image, "~/images/", IMAGE_URL);
        dispatch(setSyncDetail(data));
        dispatch(setIsLoading({ isLoading: false }));
      }
    } catch (err) {
      console.error(err);
      dispatch(setSyncDetailError({ error: err.toString() }));
    }
  };

export const putSyncRequest =
  (
    syncID: number,
    isCompleted: boolean | null,
    objectiveStatus: number | null,
    selectedEmotions: Array<{ emotionID: number }> | null,
    callback?: (bool: boolean) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));

      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }
      dispatch(setIsLoading({}));
      const response = await putSync(token, {
        syncID,
        isCompleted,
        objectiveStatus,
        selectedEmotions
      });
      if (response.success) {
        dispatch(setIsLoading({ isLoading: false }));
      }
      if (callback) {
        callback(response.success);
      }
    } catch (err) {
      console.error(err);
      dispatch(setSyncDetailError({ error: err.toString() }));
    }
  };
