import React, {FunctionComponent, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import strings from "../../../_core/strings/strings";
import "./styles.scss";
import "moment/locale/es";
import {SeityFormTextInput} from "../../../_core/components/SeityTextInput/SeityFormTextInput";
import {SeityFormTextareaInput} from "../../../_core/components/SeityTextareaInput/SeityFormTextareaInput";
import SeityButton from "../../../_core/components/SeityButton";
import {sendCreateSupportTicketRequest} from "../accountSlice";
import {RootState} from "../../../app/rootReducer";
import maxLimitIcon from "../../../assets/graphics/journals/icon-max-limit.png";

export const Support: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: RootState) => {
    return state.account;
  });

  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [subjectMaxLengthReached, setSubjectMaxLengthReached] = useState<boolean>(false);
  const [descriptionMaxLengthReached, setDescriptionMaxLengthReached] = useState<boolean>(false);


  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const onSuccess = () => {
      setSubject("");
      setDescription("");
    };

    dispatch(sendCreateSupportTicketRequest({subject, description}, onSuccess));
  };

  const isFormValid = subject.trim().length > 0 && description.trim().length > 0;

  return (
    <div className="support-container">

      <div className="support-container__header">
        <h1>{strings.needSupport}</h1>
        <h2>{strings.submitRequestGuidance}</h2>
      </div>

      <form>
        <SeityFormTextInput
          inputLabelText={strings.subject}
          type="text"
          inputStyle={{color: "#314947"}}
          onChange={(e) => {
            setSubject(e.target.value);
            setSubjectMaxLengthReached(e.target.value.length >= 200);
          }}
          value={subject}
          isInvalid={subject ? subject.length === 0 : false}
          maxLength={200}
          isMaxLimitReached={subjectMaxLengthReached}
        />

        <SeityFormTextareaInput
          inputLabelText={strings.describeTheIssue}
          inputStyle={{color: "#314947"}}
          onChange={(e) => {
            setDescription(e.target.value);
            setDescriptionMaxLengthReached(e.target.value.length >= 1000);
          }}
          value={description}
          isInvalid={description ? description.length === 0 : false}
          rows={10}
          showCounter={true}
          maxLength={1000}
          placeholder={strings.tapToAddText}
          isMaxLimitReached={descriptionMaxLengthReached}
        />

        <SeityButton
          className="support-container__submit-btn"
          label={strings.submit}
          onClick={handleSubmit}
          disabled={!isFormValid || isLoading}
        />
      </form>
    </div>
  );
};
