import { ExploreItemProps } from "./ExploreItem";
import { LearnSeityCategory } from "../../consts";
import strings from "../../_core/strings/strings";

export const ExploreItemsList = (characterization: any): Omit<ExploreItemProps, "onClick">[] => {
  return [
    {
      visible: characterization.intentionsEnabled,
      image: require("../../assets/graphics/intentions/icon-intentions.png").default,
      heading: strings.intentions,
      description: characterization.intentionDescription,
      className: "sj-item-syncs",
      navigationPath: "/intentions",
      contentKey: "intentionsDescription"
    },
    {
      visible: true,
      image: require("../../assets/graphics/stories.png").default,
      heading: strings.journals,
      description: strings.individualJournalDescription,
      className: "sj-item-journals",
      navigationPath: "/journals",
      contentKey: "journalsDescription"
    },
    {
      visible: characterization.syncsEnabled,
      image: require("../../assets/graphics/syncs_icon.png").default,
      heading: strings.Syncs,
      description: characterization.syncsDescription,
      className: "sj-item-syncs",
      navigationPath: "/mySyncs",
      contentKey: "syncsDescription"
    },
    {
      visible: true, //characterization.storiesEnabled,
      image: require("../../assets/graphics/group.png").default,
      heading: strings.stories,
      description: strings.StoriesDescription,
      className: "sj-item-story",
      navigationPath: "/myStories",
      contentKey: "storiesDescription"
    },
    {
      visible: true,
      image: require("../../assets/graphics/syncs03.png").default,
      heading: strings.learnSeity,
      description: strings.findYourPath,
      className: "sj-item-learnSeity",
      navigationPath: "/learn",
      contentKey: "learnSeityDescription"
    }
  ];
};
