import { FunctionComponent, useState } from "react";
import { ASSET_IMG_URL, IMAGE_URL } from "../../../../api/apiConstants";
import { SyncStoriesItemProps } from "./types";

import strings from "../../../../_core/strings/strings";
import CompleteSuccessModal from "./CompleteSuccessModal";
import { putSyncRequest } from "../syncDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import SeityLoader from "../../../../_core/components/SeityLoader";
import moment from "moment";

export const SyncStoriesItem: FunctionComponent<SyncStoriesItemProps> = ({
  header,
  author,
  startDate,
  imageUrl,
  topEmos,
  totalEmoCount,
  objectiveID,
  objectiveStatusID,
  status,
  isCompleted,
  onPress,
  isStories,
  onPressJournal
}) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: RootState) => {
    return state.syncDetail;
  });

  const [complete, setComplete] = useState(isCompleted);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  // const  = moment(startDate, 'MM-DD-Y').local().format('MMMM DD, Y');
  return (
    <div className={`msl-sync-item flex`} style={{ flexDirection: isStories ? "row-reverse" : "row" }}>
      <CompleteSuccessModal isOpen={showCompleteModal} />
      {isLoading && <SeityLoader />}
      <div className="msl-sync-item-img">
        <img src={imageUrl} className="msl-sync-image" />
        {(status === "New" || status === "Read") && <span className={`${status}`}>{status}</span>}
        {complete && <img className="check-icon" src={require("../../../../assets/graphics/intentions/check.png").default} width={16} />}
        <div className="msl-sync-item-img__overlay">
          {!isStories &&
            objectiveStatusID === 3 && ( // commited status & sync item
              // <button className="btn-check">
              <img
                className={`check-icon ${!complete ? "incompleted" : ""}`}
                src={require("../../../../assets/graphics/intentions/check.png").default}
                alt="check icon"
                width={16}
                onClick={() => {
                  dispatch(putSyncRequest(objectiveID, !complete, null, null));
                  if (!complete) {
                    setShowCompleteModal(true);
                    setTimeout(() => {
                      setShowCompleteModal(false);
                    }, 2000);
                  }
                  setComplete(!complete);
                }}
              />
              // </button>
            )}
          <button
            className="btn-journal"
            onClick={() => {
              onPressJournal();
            }}
          >
            <img src={require("../../../../assets/graphics/stories.png").default} width={10.7} height={16} />
          </button>
        </div>
      </div>
      <div
        className="msl-sync-item-info pr-md pl-md"
        onClick={() => {
          onPress(objectiveID);
        }}
      >
        <h2>{header}</h2>
        <h3>{moment(startDate, "MM-DD-Y").local().format("MMMM DD, Y")}</h3>
        <div className="msl-arrow-item-reactions">
          {topEmos.map((i, index) => {
            return <img key={`${i}-${index}`} src={`${ASSET_IMG_URL}${i.replace("01", "selected01")}`} alt="emotion" />;
          })}
          {totalEmoCount > 0 && <span>{totalEmoCount}</span>}
        </div>
      </div>
    </div>
  );
};
