import { useEffect, useState } from "react";
import apiClientWithAuth from "../../api/apiClient";
import { FSGroupMember, FSGroup, FSGroupDetail } from "./types";
import { FSGroupDetailReponse, FSGroupReponse, FSMemberListReponse } from "../../api/familySharingTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import { getFSCoppa, getFSDisclosure } from "./familySharingSlice";

export const useFSGroupList = () => {
  const [groupList, setGroupList] = useState<Array<FSGroup>>([]);
  const [isLoading, setLoading] = useState(true);

  const fetchGroupList = async () => {
    setLoading(true);
    const res = await apiClientWithAuth.get<FSGroupReponse>("/FamilySharing");

    if (res.data.success) {
      setGroupList(res.data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchGroupList();
  }, []);

  return {
    isLoading,
    groupList,
    fetchGroupList
  };
};

export const useFSHtmlContent = () => {
  const dispatch = useDispatch();

  const { disclosure: disc, coppa: cpa, isLoading } = useSelector((state: RootState) => state.familySharingSlice);

  const [disclosure, setDisclosure] = useState(disc);
  const [coppa, setCoppa] = useState(cpa);

  useEffect(() => {
    if (disc) {
      dispatch(
        getFSDisclosure((content) => {
          setDisclosure(content);
        })
      );
    }
    if (cpa) {
      dispatch(
        getFSCoppa((content) => {
          setCoppa(content);
        })
      );
    }
  }, []);

  return {
    isLoading,
    disclosure,
    coppa
  };
};

export const useFSMemberList = (groupID: number) => {
  const [memberList, setMemberList] = useState<Array<FSGroupMember>>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchMemberList = async () => {
    setLoading(true);
    const res = await apiClientWithAuth.get<FSMemberListReponse>(`/FamilySharing/${groupID}/FamilyMembers`);

    if (res.data.success) {
      setMemberList(res.data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchMemberList();
  }, []);

  return {
    isLoading,
    memberList,
    fetchMemberList
  };
};

export const useFSGroupDetail = (groupID: number) => {
  const [groupData, setGroupData] = useState<FSGroupDetail>({
    familySharingID: 0,
    accountID: 0,
    name: "",
    profilePicture: null,
    profilePictureFileName: null,
    isOrganizer: false,
    organizer: {
      accountID: 0,
      firstName: "",
      lastName: "",
      profilePicture: "",
      dob: "",
      email: "",
      coreValues: null
    },
    willLoseSeityAccess: false
  });
  const [isLoading, setLoading] = useState(false);

  const fetchGroupDetail = async () => {
    setLoading(true);
    const res = await apiClientWithAuth.get<FSGroupDetailReponse>(`/FamilySharing/${groupID}`);

    if (res.data.success) {
      setGroupData(res.data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchGroupDetail();
  }, []);

  return {
    isLoading,
    groupData,
    fetchGroupDetail
  };
};
