/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import strings from "../../strings/strings";

import "./styles.scss";

export interface SeitySelectBoxProps {
  children: JSX.Element | JSX.Element[];
  id?: string;
  onChange?: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  value?: any;
  className?: string;
  isInvalid?: boolean;
  inputStyle?: {};
}

export const SeitySelectBox = (props: SeitySelectBoxProps) => {
  const { children, onChange, value, isInvalid, inputStyle, id } = props;

  const [showOptions, setShowOptions] = useState(false);
  const ref = React.createRef<HTMLDivElement>();
  const [currentOption, setCurrentOption] = useState(null);
  const selectedOption = useMemo(() => {
    return value || currentOption || strings.choose;
  }, [value, currentOption]);

  // listener that hides options if user clicks outside of select box
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showOptions && ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showOptions]);

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  const correctImg = () => {
    if (showOptions) {
      return require("../../../assets/graphics/checkmark-green.png").default;
    }
    return require("../../../assets/web-icons/dropdown-caret.png").default;
  };

  const handleSelectOption = (e) => {
    setShowOptions(false);
    setCurrentOption(e.target.innerText);
    handleOnChange(e);
  };

  const selectBoxTriggerClasses = classNames({
    "seity-select-box-trigger": true,
    "seity-select-box-trigger-error": isInvalid,
    "seity-select-box-trigger-options": showOptions
  });

  return (
    <div className="seity-select-box-wrapper" ref={ref} id={id}>
      <div
        style={inputStyle}
        className="seity-select-box"
        onChange={(e) => {
          return handleOnChange(e);
        }}
      >
        <div
          className={selectBoxTriggerClasses}
          onClick={() => {
            setShowOptions(!showOptions);
          }}
        >
          <div>{selectedOption}</div>
          <img src={correctImg()} className={!showOptions ? "seity-select-box-icon" : undefined} />
        </div>
        {showOptions && (
          <div
            onClick={(e) => {
              return handleSelectOption(e);
            }}
            className="seity-select-box-options"
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
