import React, {FunctionComponent} from 'react';
import classNames from 'classnames';
import './styles.scss';
import maxLimitIcon from '../../../assets/graphics/journals/icon-max-limit.png';
import MaxLimitError from "../SeityMaxLimitError";

export interface SeityTextareaInputProps {
  testID?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  inputStyle?: React.CSSProperties;
  containerClassname?: string;
  value: string;
  className?: string;
  isInvalid?: boolean;
  id?: string;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  maxLength?: number;
  rows?: number;
  showCounter?: boolean;
  isMaxLimitReached?: boolean;
}

export const SeityTextareaInput: FunctionComponent<SeityTextareaInputProps> =
  ({
     onChange,
     placeholder,
     inputStyle,
     value,
     className = '',
     containerClassname = '',
     isInvalid = false,
     id,
     onBlur,
     onFocus,
     autoFocus,
     maxLength,
     rows = 3,
     showCounter = false,
     isMaxLimitReached,
     testID
   }) => {
    const inputClasses = classNames('seity-textarea', className, {
      'seity-textarea-error': isInvalid,
    });

    const isReachedLimit = maxLength ? value.length === maxLength : false;

    return (
      <div className={`seity-textarea-wrapper ${containerClassname}`}>
      <textarea
        autoFocus={autoFocus}
        id={id || testID}
        value={value}
        placeholder={placeholder}
        className={inputClasses}
        onChange={onChange}
        style={inputStyle}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={maxLength}
        rows={rows}
      />

        <div className="flex-row flex-jc-sb mt-sm">

          <div className="error-content">
            <MaxLimitError maxLength={maxLength} isVisible={isMaxLimitReached} />
          </div>

          {showCounter && maxLength && (
            <div className="counter">
              <span className={value.length >= maxLength ? "max-limit" : ""}>
                {value.length}/{maxLength}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
