import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  PracticeScoresResponse,
  OutcomeScoresResponse,
  LanguageResponse,
  SeityReportDownloadResponse
} from "../account/types/accountTypes";
import { SeityAccountStatusListResponse, SeityFullAccountUpdateResponse } from "./types/maintenanceTypes";
import { PracticeScoresListResponse, PracticeDetailPayloadRequest, PracticeDetailResponse } from "./types/practiceScoresTypes";
import { SeityProfilePictureResponse } from "./types/profileTypes";

const SECTION = "/Account/";
const AUTH_SECTION = "/Auth/";
const REPORT_SECTION = "/AccountReport/";
const CV_IMAGE_SECTION = "/CoreValueImage"

export async function getAccountStatusList(token: string): Promise<SeityAccountStatusListResponse> {
  const url = BASE_URL + SECTION + "AccountStatusList";
  try {
    const accountStatusResponse = await axios.get<SeityAccountStatusListResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return accountStatusResponse.data;
  } catch (err) {
    throw err;
  }
}

// Strings and numbers should be a value other
// than "" and -1 otherwise the API will ignore
// them
export async function sendFullAccountUpdate(
  token: string,
  accountStatusId?: number,
  dateOfBirth?: string,
  statusId?: number,
  educationId?: number,
  occupationId?: number,
  genderId?: number,
  maritalStatusId?: number,
  zipCode?: string,
  cellPhone?: string
): Promise<SeityFullAccountUpdateResponse> {
  const url = BASE_URL + SECTION + "AccountUpdate01";
  try {
    const updateResponse = await axios.post<SeityFullAccountUpdateResponse>(
      url,
      {
        accountStatusId,
        dateOfBirth,
        statusId,
        educationId,
        occupationId,
        genderId,
        maritalStatusId,
        zipCode,
        cellPhone
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return updateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getOutcomeScores(token: string): Promise<OutcomeScoresResponse> {
  const url = BASE_URL + SECTION + "OutcomeScores";
  try {
    const outcomeScoresResponse = await axios.get<OutcomeScoresResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return outcomeScoresResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeScores(token: string): Promise<PracticeScoresResponse> {
  const url = BASE_URL + SECTION + "PracticeScores";
  try {
    const practiceScoresResponse = await axios.get<PracticeScoresResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return practiceScoresResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeScoresList(token: string): Promise<PracticeScoresListResponse> {
  const url = BASE_URL + SECTION + "PracticeScoresList";
  try {
    const practiceScoresResponse = await axios.get<PracticeScoresListResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    // console.log('PracticeScoresListResponse: ', JSON.stringify(practiceScoresResponse.data));
    return practiceScoresResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeDetail(token: string, practiceDetailData: PracticeDetailPayloadRequest): Promise<PracticeDetailResponse> {
  const url = BASE_URL + SECTION + "/PracticeDetail";
  try {
    const listResponse = await axios.post<PracticeDetailResponse>(url, practiceDetailData, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return listResponse.data;
  } catch (err) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    console.log(err.response);
    throw err;
  }
}

async function downloadReport(url: string, token: string) : Promise<SeityReportDownloadResponse> {
  try {
    const downloadPDFResponse = await axios.get<SeityReportDownloadResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return downloadPDFResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function downloadCoreValuesPDF(token: string): Promise<SeityReportDownloadResponse> {
  const url = BASE_URL + REPORT_SECTION + "CoreValuesCubePdfStream";
  return await downloadReport(url, token);
}

export async function downloadCoreValuesBackground(token: string): Promise<SeityReportDownloadResponse> {
  const url = BASE_URL + CV_IMAGE_SECTION;
  return await downloadReport(url, token);
}

export async function downloadCoreValueBackgroundInstructions(token:string): Promise<SeityReportDownloadResponse> {
  const url = BASE_URL + CV_IMAGE_SECTION + "/Instructions";
  return await downloadReport(url, token); 
}

export async function getLanguages(): Promise<LanguageResponse> {
  const url = `${BASE_URL}/Languages`;
  try {
    const response = await axios.get<LanguageResponse>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function postProfilePicture(fileName: string, base64String: string, token: string): Promise<SeityProfilePictureResponse> {
  const url = BASE_URL + "/Profile/profilePicture";
  try {
    const profilePicResponse = await axios.post<SeityProfilePictureResponse>(
      url,
      {
        fileName,
        base64String
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return profilePicResponse.data;
  } catch (err) {
    throw err;
  }
}
