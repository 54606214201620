import { createSlice } from "@reduxjs/toolkit";
import apiClientWithAuth from "../../api/apiClient";
import { PersonalGroupDetails, PersonalListAccountDetails, PersonalListAccountsArray } from "./types";

export interface PersonalListState {
  personalList: Array<PersonalGroupDetails>;
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly personalListAccounts: Array<PersonalListAccountDetails>;
  readonly personalListAccountsSearchResults: Array<PersonalListAccountDetails>;
}

const setInitalState: PersonalListState = {
  personalList: [],
  personalListAccounts: [],
  personalListAccountsSearchResults: [],
  error: null,
  isLoading: false
};

const personalListSlice = createSlice({
  name: "personal-list",
  initialState: setInitalState,
  reducers: {
    setPersonalList(state, action) {
      state.personalList = action.payload;
    },
    setPersonalListAccounts(state, action) {
      state.personalListAccounts = action.payload;
    },
    setPersonalListAccountsSearchResults(state, action) {
      state.personalListAccountsSearchResults = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    resetPersonalListSlice: () => setInitalState
  }
});

export const {
  setPersonalListAccountsSearchResults,
  setPersonalList,
  setPersonalListAccounts,
  clearError,
  setIsLoading,
  setError,
  resetPersonalListSlice
} = personalListSlice.actions;

export default personalListSlice.reducer;

const handleError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setError({ error: err.toString() }));
};

export const postPersonalListCreate = (personalListName: string, callback: (listID: number) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.post("/PersonalList", {
      personalListName
    });
    dispatch(setIsLoading(false));
    if (res.data.success) {
      dispatch(getPersonalList());
      if (callback) {
        callback(res.data.data.personalListID);
      }
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getPersonalList = (callback?: (personalList: Array<PersonalGroupDetails>) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("/PersonalList");

    if (res.data.success) {
      dispatch(setPersonalList(res.data.data)); // {"personalListID": 11,"personalListName": "HR","image": null,"totalMembers": 0}
    }
    if (callback) {
      callback(res.data.data ?? []);
    }

    dispatch(setIsLoading(false));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const deletePersonalList = (personalListID: number, callback?: (success: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete(`/PersonalList/${personalListID}`);
    dispatch(setIsLoading(false));

    if (res.data.success) {
      dispatch(removePersonalList(personalListID));
    }
    
    if (callback) {
      callback(res.data.success);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const putPersonalListNameChange = (personalListName: string, personalListId: number, callback?: () => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.put(`/PersonalList/${personalListId}`, {
      personalListName
    });
    dispatch(setIsLoading(false));
    if (res.data.success) {
      dispatch(getPersonalList());
    }
    if (callback) callback();
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const putPersonalListImageChange =
  (image: string, personalListId: number, callback?: (success: boolean) => void) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const res = await apiClientWithAuth.put(`/PersonalList/${personalListId}/Image`, {
        image
      });
      dispatch(setIsLoading(false));
      if (callback && res.data.success) {
        callback(res.data.success);
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

export const deletePersonalListImage = (personalListId: number, callback?: (success: boolean) => void) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete(`/PersonalList/${personalListId}/Image`);
    dispatch(setIsLoading(false));
    if (callback && res.data.success) {
      callback(res.data.success);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getPersonalListAccounts =
  (personalListId: number, page?: number, resultsPerPage?: number, callback?: (accounts: []) => void) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const url = page
        ? `/PersonalList/${personalListId}/Accounts?Page=${page}&ResultsPerPage=${resultsPerPage}`
        : `/PersonalList/${personalListId}/Accounts`;
      const res = await apiClientWithAuth.get(url);
      dispatch(setIsLoading(false));

      if (res.data.success) {
        dispatch(setPersonalListAccounts(res.data.data)); //check data shape
      }

      if (callback && res.data.data) {
        callback(res.data.data);
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

export const postPersonalListAddAccounts =
  (accountIDs: Array<number>, personalListId: number, callback?: () => void) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const res = await apiClientWithAuth.post(`/PersonalList/${personalListId}/Accounts`, {
        accountIDs
      });
      dispatch(setIsLoading(false));
      if (res.data.success) {
        getPersonalListAccounts(personalListId);
        if (callback) {
          callback();
        }
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

export const getPersonalAccountsSearch =
  (personalListId: number, alpha: string, search: string, page: number, numberPerPage: number, callback?: (accounts: []) => void) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const res = await apiClientWithAuth.get(
        `/PersonalList/${personalListId}/Accounts/Available?Alpha=${alpha}&Search=${search}&Page=${page}&NumberPerPage=${numberPerPage}`
      );
      dispatch(setIsLoading(false));

      if (res.data.success) {
        dispatch(setPersonalListAccounts(res.data.data)); //check data shape
      }

      if (callback && res.data.data) {
        callback(res.data.data);
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

export const deletePersonalListAccount =
  (personalListId: number, personalListAccountID: number, callback?: () => void) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const res = await apiClientWithAuth.delete(`/PersonalList/${personalListId}/Accounts/${personalListAccountID}`);
      dispatch(setIsLoading(false));
      if (callback && res.data.success) {
        callback();
      }
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

  export const removePersonalList = (personalListID: number) => (dispatch, getState) => {
    const { personalList } = getState().personalListSlice;
    const updatedList = personalList.filter((list) => list.personalListID !== personalListID);
    dispatch(setPersonalList(updatedList));
  };