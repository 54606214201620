import { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import classNames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { RootState } from "../../../../../app/rootReducer";
import { SeityBackButton } from "../../../../../_core/components/SeityBackButton";
import SeityLoader from "../../../../../_core/components/SeityLoader";
import { CoreValuesList } from "../../CoreValuesList";
import {
  sendGetCoreValuesCategorieDetails,
  sendGetSeityCoreValues,
  sendGetSeityGuideCategorieDetails,
  sendGetSeityWellbeingCategorieDetails,
  sendGetDragonsGuideDetails
} from "../../guideSlice";
import { outcomeCategoriesIds, welbeingCategoriesIds } from "../../data";
import { LearnGiudeCategoryProps, paramTypes } from "./types";
import RequireSeityImage from "../../../../../_core/utils/graphicUtils/localGraphic";

import strings from "../../../../../_core/strings/strings";
import "./styles.scss";

export const LearnGuide: FunctionComponent<LearnGiudeCategoryProps> = () => {
  const dispatch = useDispatch();
  const { isLoading, guide } = useSelector((state: RootState) => state.guide);
  const { categoryID } = useParams<paramTypes>();

  useEffect(() => {
    if (categoryID == "0") {
      dispatch(sendGetCoreValuesCategorieDetails());
      dispatch(sendGetSeityCoreValues());
    }
    if (outcomeCategoriesIds.includes(categoryID)) {
      dispatch(sendGetSeityGuideCategorieDetails(categoryID));
    }
    if (welbeingCategoriesIds.includes(categoryID)) {
      dispatch(sendGetSeityWellbeingCategorieDetails(categoryID));
    }
    if (categoryID == "7") {
      dispatch(sendGetDragonsGuideDetails());
    }
  }, [categoryID, dispatch]);

  const getSubheaderEndText = useMemo(() => {
    let text = "";

    switch (Number(categoryID)) {
      case 0:
        text = guide?.outcomeCategory.category + strings.factors;
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        text = guide?.outcomeCategory.category + strings.lifestyleElements;
        break;
      case 5:
        text = strings.theFourDimentionsWellbeing;
        break;
      case 6:
        text = guide?.outcomeCategory.category + strings.factors;
        break;
      case 7:
        text = guide?.outcomeCategory.category + strings.status;
        break;
      default:
        break;
    }
    return text;
  }, [guide, categoryID]);

  if (isLoading || !guide) return <SeityLoader />;

  return (
    <div className="guide-container">
      <div
        className={classNames("guide-container-header", {
          "guide-container-header-highlited": categoryID == "0"
        })}
      >
        <SeityBackButton wrapperClassName="learn-wrapper-back-button" />
        <h1>{guide.outcomeCategory.category}</h1>
      </div>
      <Tabs className="tabs">
        {categoryID == "0" && (
          <TabList className="tabs-buttons-container">
            <Tab>{strings.about}</Tab>
            <Tab>{strings.coreValueList}</Tab>
          </TabList>
        )}
        <TabPanel>
          <div className="guide-content">
            <img src={RequireSeityImage(`${guide.outcomeCategory.category}-guide`).default} />
            <h2>{`${guide.outcomeCategory.category} ${strings.definition.toLowerCase()}`}</h2>
            <h4>{guide.outcomeCategory.description}</h4>

            {guide.subcategories?.length > 0 && Number(categoryID) !== 6 && (
              <>
                <div className="guide-content-devider" />
                <h2>{getSubheaderEndText}</h2>
                <div className="guide-content-subcategories">
                  {guide.subcategories.map((subcategory, index) => {
                    let image = subcategory.outcomeSubcategoryImageName ? `${subcategory.outcomeSubcategoryImageName}` : `outcome${index}`;
                    return (
                      <div className="subcategory" key={index}>
                        <div className="subcategory-header">
                          <img src={RequireSeityImage(image).default} />
                          <h2>{subcategory.subcategory}</h2>
                        </div>
                        <h4>{subcategory.description}</h4>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel className="core-values-subpage">
          <CoreValuesList />
        </TabPanel>
      </Tabs>
    </div>
  );
};
