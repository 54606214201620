import { FunctionComponent, ReactElement, useEffect } from "react";

import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import { useLocation, useParams } from "react-router-dom";
import FSGroupDetailPrimary from "./FSGroupDetailPrimary";
import FSGroupDetailMember from "./FSGroupDetailMember";
import { useFSGroupDetail } from "../hooks";
import { getFSRelationshipList, getFSStatuses } from "../familySharingSlice";

type FSGroupDetailStateProps = {
  showInvite: boolean;
  isOrganizer: boolean;
};

export type FSGroupDetailRouteType = {
  groupID: string;
};

const FSGroupDetail: FunctionComponent = (): ReactElement => {
  const params = useParams<FSGroupDetailRouteType>();
  const location = useLocation<FSGroupDetailStateProps>();
  const dispatch = useDispatch();
  
  const showInvite = location?.state?.showInvite ?? false;
  const isOrganizer = location?.state?.isOrganizer ?? false;
  const groupID: number = parseInt(params.groupID);

  const { isLoading: groupDetailLoading, groupData, fetchGroupDetail } = useFSGroupDetail(groupID);
  const { fsStatusList, fsRelationshipList } = useSelector((state: RootState) => state.familySharingSlice);

  const { hasFamilySharingFullAccess } = useSelector((state: RootState) => state.userAppSettings.userAppSettings);

  useEffect(() => {
    !fsStatusList.length && dispatch(getFSStatuses());
    !fsRelationshipList.length && dispatch(getFSRelationshipList());
  }, []);

  if (hasFamilySharingFullAccess && isOrganizer) {
    return <FSGroupDetailPrimary groupData={groupData} showInvite={showInvite} groupID={groupID} fetchGroupDetail={fetchGroupDetail} />;
  } else {
    return <FSGroupDetailMember groupID={groupID} />;
  }
};

export default FSGroupDetail;
