import { FunctionComponent, ReactElement } from "react";
import Lottie from "react-lottie";
import animationData from "./flatloading.json";

import "./styles.scss";

const LoadingIndicator: FunctionComponent = (): ReactElement => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="carousel-loading-indicator">
      <Lottie options={defaultOptions} height={56} width={56} />
    </div>
  );
};

export default LoadingIndicator;
