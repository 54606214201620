/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";

import classNames from "classnames";
import ShowEye from "../../../assets/web-icons/eye_show.png";
import CloseEye from "../../../assets/web-icons/eye_hide.png";

import "./styles.scss";

export interface SeityTextInputProps {
  testID?: string;
  type?: "password" | "text" | "number" | "date" | "email" | "tel";
  onChange?: (e: any) => void;
  placeholder?: string;
  inputStyle?: {};
  containerClassname?: string;
  leftIconClassname?: string;
  rightIconClassname?: string;
  value?: string | number;
  autoComplete?: "on" | "off";
  className?: string;
  leftIcon?: string;
  isInvalid?: boolean;
  id?: string;
}

export const SeityTextInput = (props: SeityTextInputProps) => {
  const {
    onChange,
    placeholder,
    inputStyle,
    value,
    className,
    leftIcon,
    autoComplete,
    containerClassname,
    isInvalid,
    id,
    leftIconClassname,
    rightIconClassname
  } = props;
  const { type } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState({ type });

  const handleEyeClick = () => {
    if (!showPassword) {
      setInputType({ type: "text" });
    } else {
      setInputType({ type: "password" });
    }
    setShowPassword(!showPassword);
  };

  const hasRightIcon = type === "password";
  const hasLeftIcon = leftIcon;

  const inputClasses = classNames({
    "seity-input-password": true,
    "seity-input-padding-left": hasLeftIcon,
    "seity-input-padding-right": hasRightIcon,
    "seity-input-error": isInvalid
  });

  return (
    <div className={`${"seity-input-wrapper" + " "}${containerClassname}`}>
      {hasLeftIcon && <img className={`${"seity-input-left-icon" + " "}${leftIconClassname}`} src={leftIcon} />}
      <input
        id={id}
        value={value}
        autoComplete={autoComplete === "off" ? "new-password" : "on"}
        autoCapitalize={type === "email" || type === "password" ? "off" : "sentences"}
        placeholder={placeholder}
        className={`${className} ${inputClasses}`}
        type={inputType.type}
        onChange={onChange}
        style={inputStyle}
      />
      {hasRightIcon && (
        <img className={`seity-input-right-icon ${rightIconClassname}`} onClick={handleEyeClick} src={showPassword ? CloseEye : ShowEye} />
      )}
    </div>
  );
};
