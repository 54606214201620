/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { RootState } from "../../../app/rootReducer";
import { getFourCoreValuesRequest, sendDownloadPDFRequest } from "../coreValuesSlice";
import { useWindowSize } from "../../../hooks/Layout";
import SeityButton from "../../../_core/components/SeityButton";
import YourCoreValue from "./YourCoreValue";

import strings from "../../../_core/strings/strings";
import "./styles.scss";

export const MyCoreValues = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const size = useWindowSize();

  const { fourCoreValues } = useSelector((state: RootState) => {
    return state.fourCoreValues;
  });

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  const printButtonText = useMemo(() => {
    if (size.width < 1152) {
      return strings.print;
    }
    return strings.printCoreValues;
  }, [size.width]);

  useEffect(() => {
    dispatch(getFourCoreValuesRequest());
  }, []);

  const downloadPDF = () => {
    dispatch(sendDownloadPDFRequest());
  };

  const goBackOnCaretClick = () => {
    history.goBack();
  };

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }
  return (
    <div className="dashboard-mcv-container">
      <div className="dashboard-mcv-header-wrapper">
        <img
          className="dashboard-mcv-container-back-caret"
          src={require("../../../assets/graphics/back_btn.png").default}
          alt="back caret"
          onClick={goBackOnCaretClick}
        />
        <h1>{strings.myCoreValues}</h1>
        <SeityButton className="dashboard-mcv-container-button" label={printButtonText} onClick={downloadPDF} />
      </div>

      <div className="dashboard-mcv-container-values">
        {fourCoreValues.length === 4 &&
          fourCoreValues.map((value, index) => {
            const { coreValueID, coreValue, coreValueText, quote } = value;
            return (
              <YourCoreValue key={index} coreValueID={coreValueID} coreValue={coreValue} descriptionText={coreValueText} quote={quote} />
            );
          })}
      </div>
    </div>
  );
};
