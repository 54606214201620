/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { FinishAssessmentLaterModalProps } from "./types";
import SeityModal from "../../../../_core/components/SeityModal";
import SeityButton from "../../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";

import strings from "../../../../_core/strings/strings";

import "./styles.scss";

export const FinishAssessmentLaterModal: FunctionComponent<FinishAssessmentLaterModalProps> = ({
  visible = false,
  onToggle = () => {}
}) => {
  const history = useHistory();

  return (
    <SeityModal isOpen={visible} onClose={onToggle} wrapperClassName="seity-modal-assessment">
      <div className="finish-assessment-later d-flex flex-column align-items-center">
        <p className="finish-assessment-later__title">{strings.finishAsessmentLaterText}</p>
        <div className="finish-assessment-buttons">
          <SeityButton
            label={strings.leave}
            onClick={() => {
              history.push("/");
            }}
            className="finish-assessment-button"
          />
          <SeityHyperText title={strings.cancelButtonText} onClick={onToggle} />
        </div>
      </div>
    </SeityModal>
  );
};

export default FinishAssessmentLaterModal;
