import { SeityResponse } from "../../baseTypes";

export const DRAGON_ID_RAGING = 3;
export const DRAGON_ID_AWAKE = 2;
export const DRAGON_ID_TAME = 1;

export class PracticeScoreSubCategory {
  subcategoryID: number;
  categoryID: number;
  subcategoryName: string;
  subcategoryScore: number;
  subcategoryLogoURL: string;
  practiceDescription: string;
  dragonID: number;
  dragonText: string;
  dragonLogoURL: string;
  dragonDescription: string;

  constructor(
    subcategoryID: number,
    categoryID: number,
    subcategoryName: string,
    subcategoryScore: number,
    subcategoryLogoURL: string,
    practiceDescription: string,
    dragonID: number,
    dragonText: string,
    dragonLogoURL: string,
    dragonDescription: string
  ) {
    this.subcategoryID = subcategoryID;
    this.categoryID = categoryID;
    this.subcategoryName = subcategoryName;
    this.subcategoryScore = subcategoryScore;
    this.subcategoryLogoURL = subcategoryLogoURL;
    this.practiceDescription = practiceDescription;
    this.dragonID = dragonID;
    this.dragonText = dragonText;
    this.dragonLogoURL = dragonLogoURL;
    this.dragonDescription = dragonDescription;
  }
}

export class PracticeScoreCategory {
  categoryID: number;
  categoryName: string;
  categoryScore: number;
  categoryLogoURL: string;
  subcategories: [PracticeScoreSubCategory];

  constructor(
    categoryID: number,
    categoryName: string,
    categoryScore: number,
    categoryLogoURL: string,
    subcategories: [PracticeScoreSubCategory]
  ) {
    this.categoryID = categoryID;
    this.categoryName = categoryName;
    this.categoryScore = categoryScore;
    this.categoryLogoURL = categoryLogoURL;
    this.subcategories = subcategories;
  }
}

export class PracticeScoresListResponse {
  success: boolean;
  message: string | null;
  data: [PracticeScoreCategory];

  constructor(success: boolean, message: string | null, data: [PracticeScoreCategory]) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export enum DragonText {
  RAGING = "RAGING",
  AWAKE = "AWAKE",
  TAME = "TAME"
}

export class PracticeDetailPayloadRequest {
  outcomeCategoryID: number;
  outcomeSubcategoryID: number;

  constructor(outcomeCategoryID: number, outcomeSubcategoryID: number) {
    this.outcomeCategoryID = outcomeCategoryID;
    this.outcomeSubcategoryID = outcomeSubcategoryID;
  }
}

export class PracticeDetail {
  firstName: string;
  lastAssessmentDate: string;
  score: number;
  category: string;
  subcategory: string;
  logoURL: string;
  practiceDescription: string;
  dragonText: string;
  dragonLogo: string;
  dragonHeader: string;
  dragonLabel: string;
  dragonDescription: string;

  constructor(
    firstName: string,
    lastAssessmentDate: string,
    score: number,
    category: string,
    subcategory: string,
    logoURL: string,
    practiceDescription: string,
    dragonText: string,
    dragonLogo: string,
    dragonHeader: string,
    dragonLabel: string,
    dragonDescription: string
  ) {
    this.firstName = firstName;
    this.lastAssessmentDate = lastAssessmentDate;
    this.score = score;
    this.category = category;
    this.subcategory = subcategory;
    this.logoURL = logoURL;
    this.practiceDescription = practiceDescription;
    this.dragonText = dragonText;
    this.dragonLogo = dragonLogo;
    this.dragonHeader = dragonHeader;
    this.dragonLabel = dragonLabel;
    this.dragonDescription = dragonDescription;
  }
}

export class PracticeDetailResponse extends SeityResponse {
  data: PracticeDetail | null;

  constructor(success: boolean, message: string | null, data: PracticeDetail | null) {
    super(success, message);
    this.data = data;
  }
}
