import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { Story } from "../../../api/story/types/storyTypes";
import { getStoryList } from "../../../api/story/seityHealthAPI-Story";
import { IMAGE_URL } from "../../../api/apiConstants";
import { formatImagePath } from "../../../api/utils";

export interface StoryListState {
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly storyList: Array<Story>;
}

const setInitalState = {
  error: null,
  isLoading: false,
  storyList: []
} as StoryListState;

const storySlice = createSlice({
  name: "storylist-storylist",
  initialState: setInitalState,
  reducers: {
    setStoryList(state, action: PayloadAction<Array<Story>>) {
      state.storyList = action.payload;
      state.isLoading = false;
    },
    setStoryListError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    clearApiError(state, action) {
      state.error = null;
    },
    // If a value is not passed, it's assumed
    // to be true
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    resetStoryListSlice: () => setInitalState
  }
});

export const { setStoryList, setStoryListError, clearApiError, setIsLoading, resetStoryListSlice } = storySlice.actions;
export default storySlice.reducer;

export const getStoryListRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const storyListResponse = await getStoryList(token);
    if (storyListResponse.success) {
      const ret = storyListResponse.data?.map((i) => {
        i.image = formatImagePath(i.image, "~/images/", IMAGE_URL);

        return i;
      });
      dispatch(setStoryList(ret));
      dispatch(setIsLoading({ isLoading: false }));
    }
  } catch (err) {
    console.error(err);
    dispatch(setStoryListError({ error: err.toString() }));
  }
};
