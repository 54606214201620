import { FunctionComponent } from "react";
import SeityProgressBar from "../../../../_core/components/SeityProgressBar";

import { PracticeScoreProps } from "./types";

export const PracticeScore: FunctionComponent<PracticeScoreProps> = ({ image, heading, score, category }) => {
  return (
    <div className={`ayp-practice-item ${category}`}>
      <img src={image} alt="practice category icon" />
      <h3>{heading}</h3>
      <div>
        <h1>{score}%</h1>
        <SeityProgressBar
          progress={score}
          trackColor="rgba(49, 73, 71, 1)"
          color="rgba(255, 248, 235, 1)"
          height={7}
          containerStyle="ayp-progress-bar"
        />
      </div>
    </div>
  );
};
