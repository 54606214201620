import { FunctionComponent } from "react";
import './styles.scss';

type SeityCheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

const SeityCheckbox: FunctionComponent<SeityCheckboxProps> = ({
  checked,
  onChange,
  label
}) => {
  return (
    <label className="seity-checkbox-label">
      <input
        type='checkbox'
        className="seity-checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      {label && <span className="seity-checkbox-text">{label}</span>}
    </label>
  )
}

export default SeityCheckbox;
