import { FunctionComponent } from "react";
import { Characterization, AssessmentLevel, AssessmentCompletionState } from "../../../api/myStory/types/myStoryTypes";

import { ArtOfYouSimple } from "./components/ArtOfYouSimple";
import { NoAssessment } from "./components/NoAssessment";

import "./styles.scss";

interface ArtOfYouProps {
  characterization: Characterization;
  accountInfo: any;
}

export const ArtOfYou: FunctionComponent<ArtOfYouProps> = ({ characterization, accountInfo }) => {
  if (
    characterization.assessmentState === AssessmentCompletionState.NoAssessment &&
    characterization.assessmentLevelID !== AssessmentLevel.NoCoreValuesOrAssessment
  ) {
    //  has assessment but not completed
    return <NoAssessment assessmentStarted={characterization.assessmentStarted} />;
  }
  if (characterization.assessmentLevelID === AssessmentLevel.NoCoreValuesOrAssessment) {
    // no assessment
    return <ArtOfYouSimple firstName={accountInfo.firstName} />;
  }
  // has assessment and core values
  return <></>;
};
