import { useHistory } from "react-router-dom";
import { Characterization, CoreValue } from "../../../../api/myStory/types/myStoryTypes";
import { ArtOfYouCoreValue } from "./ArtOfYouCoreValue";
import SeityButton from "../../../../_core/components/SeityButton";

import strings from "../../../../_core/strings/strings";
import "../styles.scss";

export interface YourCoreValuesProps {
  fourCoreValues: CoreValue[];
}

export const YourCoreValues = ({ fourCoreValues }: YourCoreValuesProps) => {
  const history = useHistory();

  const coreValueImageArray: string[] = [];
  const coreValueLabelArray: string[] = [];

  const goToCoreValues = () => {
    history.push("/coreValues/myCoreValues");
  };

  if (fourCoreValues !== null && fourCoreValues.length === 4) {
    coreValueImageArray.splice(0, 0, fourCoreValues[0].coreValueImage !== undefined ? fourCoreValues[0].coreValueImage : "");
    coreValueLabelArray.splice(0, 0, fourCoreValues[0].coreValue !== undefined ? fourCoreValues[0].coreValue : "");
    coreValueImageArray.splice(1, 0, fourCoreValues[1].coreValueImage !== undefined ? fourCoreValues[1].coreValueImage : "");
    coreValueLabelArray.splice(1, 0, fourCoreValues[1].coreValue !== undefined ? fourCoreValues[1].coreValue : "");
    coreValueImageArray.splice(2, 0, fourCoreValues[2].coreValueImage !== undefined ? fourCoreValues[2].coreValueImage : "");
    coreValueLabelArray.splice(2, 0, fourCoreValues[2].coreValue !== undefined ? fourCoreValues[2].coreValue : "");
    coreValueImageArray.splice(3, 0, fourCoreValues[3].coreValueImage !== undefined ? fourCoreValues[3].coreValueImage : "");
    coreValueLabelArray.splice(3, 0, fourCoreValues[3].coreValue !== undefined ? fourCoreValues[3].coreValue : "");
  } else {
    coreValueImageArray.splice(0, 0, "corevalue01.png");
    coreValueLabelArray.splice(0, 0, "Core Value");
    coreValueImageArray.splice(1, 0, "corevalue01.png");
    coreValueLabelArray.splice(1, 0, "Core Value");
    coreValueImageArray.splice(2, 0, "corevalue01.png");
    coreValueLabelArray.splice(2, 0, "Core Value");
    coreValueImageArray.splice(3, 0, "corevalue01.png");
    coreValueLabelArray.splice(3, 0, "Core Value");
  }

  if (fourCoreValues.length === 4) {
    return (
      <div className="aoy-core-values-container">
        <div className="aoy-core-values-text">{strings.myCoreValues}</div>
        <div className="aoy-core-values-content">
          {fourCoreValues.map((coreValue) => {
            return <ArtOfYouCoreValue key={coreValue.coreValueID} coreValue={coreValue.coreValue} coreValueID={coreValue.coreValueID} />;
          })}
        </div>
        <SeityButton onClick={goToCoreValues} label={strings.exploreCoreValues} className="aoy-core-values-container-button" />
      </div>
    );
  }
  return <></>;
};
