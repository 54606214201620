export default function RequireSeityImage(imageName: string) {
  let formattedString: string = String(imageName).toLowerCase();
  let returnValue;
  if (formattedString.length > 4) {
    if (formattedString.includes(".png")) {
      formattedString = formattedString.substring(0, formattedString.length - 4);
    }
  }
  switch (formattedString) {
    //outcomes and daily check in
    case "energy-a":
    case "outcome0":
    case "energy":
      returnValue = require("../../../assets/graphics/energy.png");
      break;
    case "direction-a":
    case "outcome1":
    case "direction":
      returnValue = require("../../../assets/graphics/direction.png");
      break;
    case "belonging-a":
    case "outcome2":
    case "outcomebelonging":
      returnValue = require("../../../assets/graphics/outcomebelonging.png");
      break;
    case "joy-a":
    case "outcome3":
    case "joy":
      returnValue = require("../../../assets/graphics/joy.png");
      break;
    case "wellbeing01":
      returnValue = require("../../../assets/graphics/wellbeing02.png");
      break;
    //outcome categories
    case "outcomecategoryid-1":
      returnValue = require("../../../assets/graphics/world.svg");
      break;
    case "outcomecategoryid-2":
      returnValue = require("../../../assets/graphics/body.svg");
      break;
    case "outcomecategoryid-3":
      returnValue = require("../../../assets/graphics/mind.svg");
      break;
    case "outcomecategoryid-4":
      returnValue = require("../../../assets/graphics/spirit.svg");
      break;
    //practice
    case "wholehealtha":
      returnValue = require("../../../assets/graphics/wholehealtha.png");
      break;
    case "worlda":
      returnValue = require("../../../assets/graphics/worlda.png");
      break;
    case "relationships-a":
    case "world01a":
      returnValue = require("../../../assets/graphics/world01a.png");
      break;
    case "ecosystem-a":
    case "world02a":
      returnValue = require("../../../assets/graphics/world02a.png");
      break;
    case "finances-a":
    case "world03a":
      returnValue = require("../../../assets/graphics/world03a.png");
      break;
    case "safety-a":
    case "world04a":
      returnValue = require("../../../assets/graphics/world04a.png");
      break;
    case "social network-a":
    case "world05a":
      returnValue = require("../../../assets/graphics/world05a.png");
      break;
    case "occupation-a":
    case "world06a":
      returnValue = require("../../../assets/graphics/world06a.png");
      break;
    case "world07a":
      returnValue = require("../../../assets/graphics/world07a.png");
      break;
    case "world08a":
      returnValue = require("../../../assets/graphics/world08a.png");
      break;
    case "world09a":
      returnValue = require("../../../assets/graphics/world09a.png");
      break;
    case "world10a":
      returnValue = require("../../../assets/graphics/world10a.png");
      break;
    case "world11a":
      returnValue = require("../../../assets/graphics/world11a.png");
      break;
    case "worlda-t":
      returnValue = require("../../../assets/graphics/worlda-t.png");
      break;
    case "world01a-t":
      returnValue = require("../../../assets/graphics/world01a-t.png");
      break;
    case "world02a-t":
      returnValue = require("../../../assets/graphics/world02a-t.png");
      break;
    case "world03a-t":
      returnValue = require("../../../assets/graphics/world03a-t.png");
      break;
    case "world04a-t":
      returnValue = require("../../../assets/graphics/world04a-t.png");
      break;
    case "world05a-t":
      returnValue = require("../../../assets/graphics/world05a-t.png");
      break;
    case "world06a-t":
      returnValue = require("../../../assets/graphics/world06a-t.png");
      break;
    case "world07a-t":
      returnValue = require("../../../assets/graphics/world07a-t.png");
      break;
    case "world08a-t":
      returnValue = require("../../../assets/graphics/world08a-t.png");
      break;
    case "world09a-t":
      returnValue = require("../../../assets/graphics/world09a-t.png");
      break;
    case "world10a-t":
      returnValue = require("../../../assets/graphics/world10a-t.png");
      break;
    case "world11a-t":
      returnValue = require("../../../assets/graphics/world11a-t.png");
      break;
    case "bodya":
      returnValue = require("../../../assets/graphics/bodya.png");
      break;
    case "nutrition-a":
    case "body01a":
      returnValue = require("../../../assets/graphics/body01a.png");
      break;
    case "addiction prevention-a":
    case "body02a":
      returnValue = require("../../../assets/graphics/body02a.png");
      break;
    case "exercise-a":
    case "body03a":
      returnValue = require("../../../assets/graphics/body03a.png");
      break;
    case "health care-a":
    case "body04a":
      returnValue = require("../../../assets/graphics/body04a.png");
      break;
    case "sleep-a":
    case "body05a":
      returnValue = require("../../../assets/graphics/body05a.png");
      break;
    case "body06a":
      returnValue = require("../../../assets/graphics/body06a.png");
      break;
    case "bodya-t":
      returnValue = require("../../../assets/graphics/bodya-t.png");
      break;
    case "body01a-t":
      returnValue = require("../../../assets/graphics/body01a-t.png");
      break;
    case "body02a-t":
      returnValue = require("../../../assets/graphics/body02a-t.png");
      break;
    case "body03a-t":
      returnValue = require("../../../assets/graphics/body03a-t.png");
      break;
    case "body04a-t":
      returnValue = require("../../../assets/graphics/body04a-t.png");
      break;
    case "body05a-t":
      returnValue = require("../../../assets/graphics/body05a-t.png");
      break;
    case "body06a-t":
      returnValue = require("../../../assets/graphics/body06a-t.png");
      break;
    case "minda":
      returnValue = require("../../../assets/graphics/minda.png");
      break;
    case "feelings-a":
    case "mind01a":
      returnValue = require("../../../assets/graphics/mind01a.png");
      break;
    case "thoughts-a":
    case "mind02a":
      returnValue = require("../../../assets/graphics/mind02a.png");
      break;
    case "memories-a":
    case "mind03a":
      returnValue = require("../../../assets/graphics/mind03a.png");
      break;
    case "knowledge-a":
    case "mind04a":
      returnValue = require("../../../assets/graphics/mind04a.png");
      break;
    case "minda-t":
      returnValue = require("../../../assets/graphics/minda-t.png");
      break;
    case "mind01a-t":
      returnValue = require("../../../assets/graphics/mind01a-t.png");
      break;
    case "mind02a-t":
      returnValue = require("../../../assets/graphics/mind02a-t.png");
      break;
    case "mind03a-t":
      returnValue = require("../../../assets/graphics/mind03a-t.png");
      break;
    case "mind04a-t":
      returnValue = require("../../../assets/graphics/mind04a-t.png");
      break;
    case "spirita":
      returnValue = require("../../../assets/graphics/spirita.png");
      break;
    case "spiritual connections-a":
    case "spirit01a":
      returnValue = require("../../../assets/graphics/spirit01a.png");
      break;
    case "spiritual transactions-a":
    case "spirit02a":
      returnValue = require("../../../assets/graphics/spirit02a.png");
      break;
    case "core value connections-a":
    case "spirit03a":
      returnValue = require("../../../assets/graphics/spirit03a.png");
      break;
    case "spirita-t":
      returnValue = require("../../../assets/graphics/spirita-t.png");
      break;
    case "spirit01a-t":
      returnValue = require("../../../assets/graphics/spirit01a-t.png");
      break;
    case "spirit02a-t":
      returnValue = require("../../../assets/graphics/spirit02a-t.png");
      break;
    case "spirit03a-t":
      returnValue = require("../../../assets/graphics/spirit03a-t.png");
      break;
    case "outcome4":
    case "wellbeing-a": {
      returnValue = require("../../../assets/graphics/wellbeing.png");
      break;
    }

    //dragons
    case "raging dragons-a":
    case "dragonraging01":
      returnValue = require("../../../assets/graphics/dragonraging01.png");
      break;
    case "awake dragons-a":
    case "dragonawake01":
      returnValue = require("../../../assets/graphics/dragonawake01.png");
      break;
    case "dragontame01":
      returnValue = require("../../../assets/graphics/dragontame01.png");
      break;
    case "tame dragons-a":
    case "dragontame02":
      returnValue = require("../../../assets/graphics/dragontame01.png");
      break;

    //guides
    case "core values-guide":
    case "valores fundamentales-guide":
      returnValue = require("../../../assets/graphics/guides/CoreValues.png");
      break;
    case "body-guide":
    case "cuerpo-guide":
      returnValue = require("../../../assets/graphics/guides/Body.png");
      break;
    case "dragons-guide":
    case "dragones-guide":
      returnValue = require("../../../assets/graphics/guides/Dragons.png");
      break;
    case "mind-guide":
    case "mente-guide":
      returnValue = require("../../../assets/graphics/guides/Mind.png");
      break;
    case "spirit-guide":
    case "espíritu-guide":
      returnValue = require("../../../assets/graphics/guides/Spirit.png");
      break;
    case "wellbeing-guide":
    case "bienestar-guide":
      returnValue = require("../../../assets/graphics/guides/Wellbeing.png");
      break;
    case "whole health-guide":
    case "salud integral-guide":
      returnValue = require("../../../assets/graphics/guides/WholeHealth.png");
      break;
    case "world-guide":
    case "mundo-guide":
      returnValue = require("../../../assets/graphics/guides/World.png");
      break;

    //settings
    case "profile":
      returnValue = require("../../../assets/icons/profile.png");
      break;
    case "language":
      returnValue = require("../../../assets/icons/icon-lang.png");
      break;
    case "privacy":
      returnValue = require("../../../assets/icons/privacy.png");
      break;
    case "logout":
      returnValue = require("../../../assets/icons/icon-logout.png");
      break;
    case "support":
      returnValue = require("../../../assets/icons/support.png");
      break;
    case "key":
      returnValue = require("../../../assets/icons/key.png");
      break;
    case "mail-icon":
      returnValue = require("../../../assets/icons/mailIcon.svg");
      break;
    case "familysharing":
      returnValue = require("../../../assets/icons/family-sharing.svg");
      break;
    case "familyprofile":
      returnValue = require("../../../assets/icons/family-profile.svg");
      break;
    case "phone":
      returnValue = require("../../../assets/icons/phone.png");
      break;
    case "permissions":
      returnValue = require("../../../assets/icons/permissions.png");
      break;
    default:
      returnValue = require("../../../assets/graphics/corevalue02.png");
      break;
  }
  return returnValue;
}
