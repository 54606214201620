import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import { sendPracticeScoresRequest } from "../../practiceSelectionsSlice";
import { OutcomeCategory } from "../../../../api/assessment/types/outcomeCategories";
import { PracticeScore } from "./PracticeScore";

import strings from "../../../../_core/strings/strings";
import { YourPracticeScoresProps } from "./types";

import "./styles.scss";

const YourPracticeScores: FunctionComponent<YourPracticeScoresProps> = () => {
  const dispatch = useDispatch();

  const { worldScore, bodyScore, mindScore, spiritScore, worldCategory, bodyCategory, mindCategory, spiritCategory } = useSelector(
    (state: RootState) => {
      return state.assessment.practiceSelections;
    }
  );

  useEffect(() => {
    dispatch(sendPracticeScoresRequest(OutcomeCategory.World));
  }, [dispatch]);

  return (
    <div className="ayp-container">
      <h2>{strings.yourLifestylePracticeResults}</h2>
      <PracticeScore
        image={require("../../../../assets/graphics/world.png").default}
        heading={strings.world}
        score={worldScore}
        category="ayp-world"
      />
      <PracticeScore
        image={require("../../../../assets/graphics/body.png").default}
        heading={strings.body}
        score={bodyScore}
        category="ayp-body"
      />
      <PracticeScore
        image={require("../../../../assets/graphics/mind.png").default}
        heading={strings.mind}
        score={mindScore}
        category="ayp-mind"
      />
      <PracticeScore
        image={require("../../../../assets/graphics/spirit.png").default}
        heading={strings.spirit}
        score={spiritScore}
        category="ayp-spirit"
      />
    </div>
  );
};

export default YourPracticeScores;
