import { FunctionComponent, ReactElement } from "react";
import RequireCVImage from "../../utils/graphicUtils/coreValues";

const CVCATE_TO_CVID = {
  28: 128,
  22: 102,
  35: 35, // Aliveness
  25: 115,
  37: 163,
  17: 83,
  24: 111,
  31: 152,
  33: 147,
  20: 94,
  30: 135,
  26: 168,
  29: 131,
  27: 27, // Plans
  23: 23, // Results
  19: 90,
  36: 36, // Self-Control
  18: 87,
  32: 142,
  34: 150
};

type SeityCVImageProps = {
  cvId?: number;
  name?: string;
  size?: number;
  bgColor?: string;
  classname?: string;
  keyId?: string;
};

const SeityCVImage: FunctionComponent<SeityCVImageProps> = ({
  cvId,
  name,
  size = 64,
  bgColor = "#f0422e",
  classname = "",
  keyId
}): ReactElement => {
  let img = require(`../../../assets/corevalues/82_off.png`).default; // This is to prevent crashes due to not existing core value resources
  try {
    if (cvId) {
      const id = CVCATE_TO_CVID[cvId] ?? cvId;
      img = require(`../../../assets/corevalues/${id}_off.png`).default;
    } else if (name) {
      img = RequireCVImage(name);
    }
  } catch (err) {
    console.log("invalid cv id");
    return <></>;
  }

  return (
    <div style={{ width: size, height: size, borderRadius: size / 2, backgroundColor: bgColor }} className={classname}>
      <img
        key={keyId}
        style={{ width: "100%", height: "100%" }}
        width={size - 5}
        src={img}
        alt={name}
      />
    </div>
  );
};

export default SeityCVImage;
