/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import { FunctionComponent, ReactElement } from "react";
import { ASSET_IMG_URL } from "../../../api/apiConstants";
import { JournalEmotion } from "../../../api/individualJournal/journalTypes";
import strings from "../../../_core/strings/strings";

import "./styles.scss";

type SeityEmotionsPopupProps = {
  emotions: Array<JournalEmotion>;
  fullScreen: boolean;
  handleEmotionSel: (idx: number, selected: boolean) => void;
};

const SeityEmotionsPopup: FunctionComponent<SeityEmotionsPopupProps> = (props): ReactElement => {
  const { emotions, fullScreen, handleEmotionSel } = props;

  return (
    <div className={`seity-emotion-popup ${fullScreen ? "fullscreen" : "modalscreen"} flex-col`}>
      <h5>{strings.howMakeYouFeel}</h5>
      <ul>
        {emotions.map((e, index) => {
          return (
            <li key={`${e.emotionID} - ${e.emotionCategoryID}`}>
              <button
                className="flex-col-center"
                onClick={() => {
                  handleEmotionSel(index, !e.selected);
                }}
              >
                <img src={`${ASSET_IMG_URL}${e.selected ? e.icon02 : e.icon01}`} alt="emotion" />
                <span>{e.name}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SeityEmotionsPopup;
