import { createSlice } from "@reduxjs/toolkit";
import { SeityAuthenticationError } from "../../api/authTypes";
import {
  SeityChallengeComplete,
  SeityChallengeContentItem,
  SeityChallengeContentList,
  SeityChallengeContentListResponse,
  SeityChallengeIntroduction,
  SeityChallengeNextStep,
  SeityChallengeStart,
  SeityChallengeState,
  SeityChallengeStateIndex,
  SeityChallengeUpdateQuestion
} from "../../api/challenge/challengeTypes";
import {
  completeChallenge,
  getChallengeContent,
  getChallengeIntroduction,
  getChallengeState,
  postChallengeStart,
  setNextChallengeStep,
  updateChallengeQuestionAnswer,
  updateChallengeRating
} from "../../api/challenge/seityHealthAPI-Challenge";
import { SeityRatingValue } from "../../_core/components/SeityRatingBar";
import { getRefreshedToken } from "../auth/UseSessionStorageToken";

import strings from "../../_core/strings/strings";

export interface ChallengeState {
  readonly isLoading: boolean;
  readonly challengeError: string | null;
  readonly challengeState: SeityChallengeState | null;
  readonly challengeIntroduction: SeityChallengeIntroduction | null;
  readonly challengeContent: SeityChallengeContentList | null;
  readonly challengeStart: SeityChallengeStart | null;
  readonly challengeNextStepResult: SeityChallengeNextStep | null;
  readonly challengeComplete: SeityChallengeComplete | null;
  readonly challengeUpdateQuestionResult: SeityChallengeUpdateQuestion | null;
  readonly challengeRatingResult: SeityChallengeUpdateQuestion | null;
  readonly currentObjectiveID: number | null;
  readonly nextObjectiveID: number | null;
  readonly loadedChallengeContent: SeityChallengeKeyedContentList[];
}

export interface SeityChallengeKeyedContentList {
  content: SeityChallengeContentItem;
  objectiveID: number;
}

const setInitialState = {
  isLoading: false,
  challengeError: null,
  challengeState: null,
  challengeIntroduction: null,
  challengeContent: null,
  challengeStart: null,
  challengeNextStepResult: null,
  challengeComplete: null,
  challengeUpdateQuestionResult: null,
  challengeRatingResult: null,
  currentObjectiveID: null,
  nextObjectiveID: null,
  loadedChallengeContent: []
} as ChallengeState;

const challengeSlice = createSlice({
  name: "challenge",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setChallengeError(state, action) {
      state.challengeError = action.payload.challengeError;
      state.isLoading = false;
    },
    setChallengeState(state, action) {
      state.challengeState = action.payload.challengeState;
      if (state.challengeState?.objectiveID) {
        state.currentObjectiveID = state.challengeState.objectiveID ?? null;
      }
      state.isLoading = false;
    },
    setChallengeIntroduction(state, action) {
      state.challengeIntroduction = action.payload.challengeIntroduction;
      state.isLoading = false;
    },
    setChallengeContent(state, action) {
      state.challengeContent = action.payload.challengeContent;
      state.loadedChallengeContent = [];
      if (
        action.payload.challengeContent?.contentList &&
        action.payload.challengeContent.contentList.length > 0 &&
        state.currentObjectiveID !== null
      ) {
        for (let i = 0; i < action.payload.challengeContent.contentList.length; i++) {
          state.loadedChallengeContent.push({
            objectiveID: state.currentObjectiveID,
            content: action.payload.challengeContent.contentList[i]
          });
        }
      }
      state.isLoading = false;
    },
    setChallengeStart(state, action) {
      state.challengeStart = action.payload.challengeStart;
      state.isLoading = false;
    },
    setChallengeNextStepResult(state, action) {
      state.challengeNextStepResult = action.payload.challengeNextStepResult;
      state.nextObjectiveID = action.payload.challengeNextStepResult.objectiveID;
      state.isLoading = false;
    },
    setChallengeComplete(state, action) {
      state.challengeComplete = action.payload.challengeComplete;
      state.isLoading = false;
    },
    setChallengeUpdateQuestionResult(state, action) {
      state.challengeUpdateQuestionResult = action.payload.challengeUpdateQuestionResult;
      state.isLoading = false;
    },
    setChallengeRatingResult(state, action) {
      state.challengeRatingResult = action.payload.challengeRatingResult;
      state.isLoading = false;
    },
    resetChallengeValues(state, action) {
      state.isLoading = false;
      state.challengeError = null;
      state.challengeState = null;
      state.challengeIntroduction = null;
      state.challengeContent = null;
      state.challengeStart = null;
      state.challengeNextStepResult = null;
      state.challengeComplete = null;
      state.challengeUpdateQuestionResult = null;
      state.challengeRatingResult = null;
      state.currentObjectiveID = null;
      state.nextObjectiveID = null;
      state.loadedChallengeContent = [];
    },
    resetChallengeSlice: () => setInitialState
  }
});

export const {
  setIsLoading,
  setChallengeError,
  setChallengeState,
  setChallengeIntroduction,
  setChallengeContent,
  setChallengeStart,
  setChallengeNextStepResult,
  setChallengeComplete,
  setChallengeUpdateQuestionResult,
  setChallengeRatingResult,
  resetChallengeValues,
  resetChallengeSlice
} = challengeSlice.actions;

export default challengeSlice.reducer;

export const sendGetChallengeState = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Getting challenge state...");
    const challengeResponse = await getChallengeState(token);
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      // console.log("received challenge state: " + JSON.stringify(challengeResponse.data));
      dispatch(setChallengeState({ challengeState: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendGetChallengeIntroduction = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Getting challenge introduction...");
    const challengeResponse = await getChallengeIntroduction(token);
    console.log("Challenge introduction received (success): ");
    if (!challengeResponse.success || challengeResponse.message || !challengeResponse.data) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeIntroduction({ challengeIntroduction: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendGetChallengeContent = (objectiveID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Getting challenge content...");
    const challengeResponse = await getChallengeContent(objectiveID, token);
    console.log("Challenge content received (success): ");
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeContent({ challengeContent: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendPostChallengeStart = (objectiveID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Getting challenge start...");
    const challengeResponse = await postChallengeStart(objectiveID, token);
    console.log("Challenge start received (success): ");
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeStart({ challengeStart: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendSetNextChallengeStep = (objectiveID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Setting next challenge step...");
    const challengeResponse = await setNextChallengeStep(objectiveID, token);
    console.log("Challenge next step received (success): ");
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeNextStepResult({ challengeNextStepResult: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendCompleteChallenge = (objectiveID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Setting complete challenge...");
    const challengeResponse = await completeChallenge(objectiveID, token);
    console.log("Challenge complete received (success): ");
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeComplete({ challengeComplete: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendUpdateChallengeQuestionAnswer = (responseID: number, answerListID: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Sending update challenge question answer...");
    const challengeResponse = await updateChallengeQuestionAnswer(responseID, answerListID, token);
    console.log("Challenge update question answer (success): ");
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeUpdateQuestionResult({ challengeUpdateQuestionResult: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendUpdateChallengeRating = (objectiveID: number, rating: SeityRatingValue) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    console.log("Sending challenge rating update...");
    const challengeResponse = await updateChallengeRating(objectiveID, rating, token);
    console.log("Challenge rating update received (success): ");
    if (!challengeResponse.success || challengeResponse.message) {
      dispatch(setChallengeError({ challengeError: challengeResponse.message ?? strings.challengeAPIGeneralError }));
    } else {
      dispatch(setChallengeRatingResult({ challengeRatingResult: challengeResponse.data }));
    }
  } catch (err) {
    handleError(err, dispatch);
  }
};

function handleError(err: Error, dispatch: any) {
  console.error(err);
  dispatch(setChallengeError({ challengeError: err.toString() }));
}
