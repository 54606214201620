import { phoneCountryCode } from "../../../features/register/registerSlice";
import getCountryCodeById from "./getCountryCodeById";

const formatPhoneNumber = (number: string, id: number, phoneCountryCodes: phoneCountryCode[] | null) => {
  // Convert the number to a string to use string manipulation methods
  const strNumber = number.toString();
  const areaCode = strNumber.substring(0, 3);
  const firstThree = strNumber.substring(3, 6);
  const lastFour = strNumber.substring(6);

  if (!phoneCountryCodes) {
    return `(${areaCode}) ${firstThree} ${lastFour}`;
  }

  // Extract area code, first three digits, and last four digits
  const countryCode = getCountryCodeById(id, phoneCountryCodes);

  // Check if the input number is exactly 10 digits
  if (strNumber.length !== 10) {
    return `${countryCode} ${strNumber}`;
  }

  // Format and return the phone number
  return `${countryCode} (${areaCode}) ${firstThree} ${lastFour}`;
};

export default formatPhoneNumber;
