import { createSlice } from "@reduxjs/toolkit";
import {
  SeityCheckCredentialsPostResponse,
  SeityCompanyCodePayloadResponse,
  SeityPhoneCCodesGetResponse,
  SeityRegisterPayloadPutResponse,
  SeityRegisterPostResponse,
  SeityRegisterPutResponse,
  SeityRegisterResponse,
  SeityValidateRegistrationResponse
} from "../../api/register/registerTypes";
import { cacheToken, saveExpiration, saveRefreshKey } from "../auth/UseSessionStorageToken";
import strings from "../../_core/strings/strings";
import apiClientWithAuth, { apiClient } from "../../api/apiClient";
import { DateTime } from "luxon";
import { PLATFORM_ID } from "../../api/apiConstants";
import { setTokens } from "../auth/authSlice";
import { setEmailVerificationNeeded } from "../../slices/userAppSettingsSlice";
import { toastError } from "../../app/utils";
import { setAccountInfoValues } from "../account/accountSlice";

export interface RegistrationSubmissionInfo {
  invitationCode: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth: string;
  password: string;
  groupActivationCode: string;
  languageID: number;
  companyName: string;
  departmentID?: number;
  jobPositionID?: number;
  accountStatusId: number;
  statusId: number;
  educationID: number;
  occupationID: number;
  genderID: number;
  maritalStatusID: number;
  cellPhone: string;
  phoneCountryCodeID: number;
  userId: string;
}

export interface phoneCountryCode {
  phoneCountryCodeID: number;
  countryName: string;
  countryCode: string;
}

export interface credentialsAvailability {
  userNameAvailable: boolean;
  emailAvailable: boolean;
}

export interface RegistrationState {
  readonly isLoading: boolean;
  readonly registerError: string | null;
  readonly current: RegistrationSubmissionInfo;
  readonly companyCodeResponse: SeityCompanyCodePayloadResponse | null;
  readonly registerSuccess: boolean;
  readonly emailValidationRequired: boolean;
  readonly smsValidationRequired: boolean;
  readonly accountID: number | null;
  readonly emailRequired: boolean;
  readonly phoneCountryCodes: Array<phoneCountryCode> | null;
  readonly credentialsAvailable: credentialsAvailability | null;
}

const getDefaultSubmissionInfo = () => {
  return {
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    groupActivationCode: "",
    companyName: "",
    dateOfBirth: "",
    cellPhone: "",
    phoneCountryCodeID: 0,
    userId: ""
  };
};

const setInitialState = {
  isLoading: false,
  registerError: null,
  current: getDefaultSubmissionInfo(),
  companyCodeResponse: null,
  registerSuccess: false,
  emailValidationRequired: true,
  smsValidationRequired: true,
  accountID: null,
  emailRequired: true,
  phoneCountryCodes: null,
  credentialsAvailable: null
} as RegistrationState;

const registerSlice = createSlice({
  name: "register",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    setRegisterSuccess(state, action) {
      let payload = action.payload as boolean;
      state.registerSuccess = payload;
      state.isLoading = false;
    },
    setRegisterError(state, action) {
      state.registerError = action.payload.registerError;
      state.isLoading = false;
    },
    setCompanyCodeResponse(state, action) {
      state.companyCodeResponse = action.payload.companyCodeResponse;
      if (state.companyCodeResponse?.companyCode) {
        state.current.groupActivationCode = state.companyCodeResponse.companyCode;
        state.current.companyName = state.companyCodeResponse.companyName;
      }
      state.isLoading = false;
    },
    setCurrentInfo(state, action) {
      let payload = action.payload as RegistrationSubmissionInfo;

      state.current = {
        ...state.current,
        ...payload
      };
      // Group activation is automatically set once validated with backend
    },
    clearCompanyCodeResponse(state, action) {
      state.companyCodeResponse = null;
    },
    clearError(state, action) {
      state.registerError = null;
    },
    resetRegisterSlice: () => setInitialState,
    setEmailValidationRequired(state, action) {
      state.emailValidationRequired = action.payload.emailValidationRequired;
      state.isLoading = false;
    },
    setSmsValidationRequired(state, action) {
      state.smsValidationRequired = action.payload.smsValidationRequired;
      state.isLoading = false;
    },
    setAccountID(state, action) {
      state.registerError = action.payload.accountID;
      state.isLoading = false;
    },
    setEmailRequired(state, action) {
      state.emailRequired = action.payload.emailRequired;
      state.isLoading = false;
    },
    setPhoneCountryCodes(state, action) {
      state.phoneCountryCodes = action.payload.phoneCountryCodes;
      state.isLoading = false;
    },
    setCredentialsAvailable(state, action) {
      state.credentialsAvailable = action.payload.credentialsAvailable;
      state.isLoading = false;
    }
  }
});

export const {
  setIsLoading,
  setRegisterSuccess,
  setRegisterError,
  setCompanyCodeResponse,
  setCurrentInfo,
  clearError,
  clearCompanyCodeResponse,
  resetRegisterSlice,
  setEmailValidationRequired,
  setSmsValidationRequired,
  setAccountID,
  setEmailRequired,
  setPhoneCountryCodes,
  setCredentialsAvailable
} = registerSlice.actions;

export default registerSlice.reducer;

export const sendValidateCompanyCodeRequest =
  (invitationCode: string, dateOfBirth: string, firstName: string, lastName: string, callback?: () => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      console.log("Sending validate company code...");
      //TODO create error codes in API
      const res = await apiClient.get("/Register/ValidateCompanyCode?CompanyCode=" + encodeURIComponent(invitationCode));
      if (res.data.success === false || res.data.message || !res.data.data) {
        dispatch(
          setRegisterError({
            registerError: res.data.message || "There was an error validating your invitation code."
          })
        );
        return;
      }
      console.log("Received validate company code response.");
      dispatch(setCompanyCodeResponse({ companyCodeResponse: res.data.data }));
      dispatch(setCurrentInfo({ dateOfBirth, firstName, lastName }));
      if (callback) {
        callback();
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };

export const sendValidateRegistrationRequest =
  (invitationCode: string, dateOfBirth: string, firstName?: string, lastName?: string, callback?: () => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      const url =
        "/Register/ValidateRegistration?CompanyCode=" +
        encodeURIComponent(invitationCode) +
        "&DateOfBirth=" +
        encodeURIComponent(dateOfBirth);
      const res = await apiClient.get<SeityValidateRegistrationResponse>(url);
      if (res.data.success === false || res.data.message || !res.data.data) {
        dispatch(
          setRegisterError({
            registerError: res.data.message || "There was an error validating your invitation code."
          })
        );
        return;
      }
      dispatch(
        setCompanyCodeResponse({
          companyCodeResponse: res.data.data
        })
      );
      dispatch(setCurrentInfo({ dateOfBirth, firstName, lastName }));
      dispatch(setEmailRequired({ emailRequired: res.data.data.emailRequired }));
      if (callback) {
        callback();
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };

export const sendEmailAddressRequest =
  (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    groupActivationCode: string,
    languageID: number,
    dateOfBirth: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      const clientTimeOffset = DateTime.local().offset / 60;
      const res = await apiClient.post<SeityRegisterResponse>("Register/Register", {
        devicePlatformId: PLATFORM_ID,
        firstName,
        lastName,
        eMailAddress: email,
        password,
        groupActivationCode,
        languageID,
        clientTimeOffset,
        dateOfBirth
      });
      switch (res.data.data.returnCode) {
        case 0: {
          dispatch(setRegisterSuccess(true));

          if (res.data.data.token != null && res.data.data.expirationTime != null && res.data.data.refreshKey != null) {
            console.log("Register email success...");
            cacheToken(res.data.data.token);
            saveRefreshKey(res.data.data.refreshKey);
            saveExpiration(res.data.data.expirationTime);
            dispatch(setCurrentInfo({ emailAddress: email }));
            dispatch(setTokens(res.data.data));
            break;
          } else {
            dispatch(setRegisterError({ registerError: strings.registrationError }));
            break;
          }
        }
        case 1: {
          dispatch(setRegisterError({ registerError: strings.emailAlreadyRegistered }));
          break;
        }
        case 2: {
          dispatch(setRegisterError({ registerError: strings.invalidInvitationCode }));
          break;
        }
        default: {
          dispatch(setRegisterError({ registerError: strings.registrationError }));
          break;
        }
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };

export const sendPostRegistration02 =
  (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    groupActivationCode: string,
    languageID: number,
    dateOfBirth: string,
    cellPhone: string,
    phoneCountryCodeID: number,
    userId: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      const clientTimeOffset = DateTime.local().offset / 60;
      const res = await apiClient.post<SeityRegisterPostResponse>("Register/Register02", {
        devicePlatformId: PLATFORM_ID,
        firstName,
        lastName,
        eMailAddress: email,
        cellPhone,
        phoneCountryCodeID,
        userId,
        password,
        groupActivationCode,
        languageID,
        clientTimeOffset,
        dateOfBirth
      });
      switch (res.data.data.returnCode) {
        case 0: {
          if (res.data.data.token != null && res.data.data.expirationTime != null && res.data.data.refreshKey != null) {
            console.log("Register email success...");
            cacheToken(res.data.data.token);
            saveRefreshKey(res.data.data.refreshKey);
            saveExpiration(res.data.data.expirationTime);
            dispatch(setCurrentInfo({ emailAddress: email, cellPhone, phoneCountryCodeID, userId }));
            dispatch(setTokens(res.data.data));
            dispatch(setEmailValidationRequired({ emailValidationRequired: res.data.data.emailValidationRequired }));
            dispatch(setSmsValidationRequired({ smsValidationRequired: res.data.data.smsValidationRequired }));
            dispatch(setAccountID({ accountID: res.data.data.accountID }));
            dispatch(setRegisterSuccess(true));
            break;
          } else {
            dispatch(setRegisterError({ registerError: strings.registrationError }));
            break;
          }
        }
        case 1: {
          dispatch(setRegisterError({ registerError: strings.emailAlreadyRegistered }));
          break;
        }
        case 2: {
          dispatch(setRegisterError({ registerError: strings.invalidInvitationCode }));
          break;
        }
        default: {
          dispatch(setRegisterError({ registerError: strings.registrationError }));
          break;
        }
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };

export const sendPutRegistration02 =
  (
    email: string,
    cellPhone: string,
    phoneCountryCodeID: number,
    userId: string,
    callback?: (success?: boolean, responseData?: SeityRegisterPayloadPutResponse) => void
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      const res = await apiClientWithAuth.put<SeityRegisterPutResponse>("Register/Register02", {
        eMailAddress: email,
        cellPhone,
        phoneCountryCodeID,
        userId
      });
      if (!res.data.success) {
        dispatch(
          setRegisterError({
            registerError: res.data.message || strings.registrationError
          })
        );
        return;
      }
      dispatch(setEmailValidationRequired({ emailValidationRequired: res.data.data.emailValidationRequired }));
      dispatch(setSmsValidationRequired({ smsValidationRequired: res.data.data.smsValidationRequired }));
      dispatch(setAccountID({ accountID: res.data.data.accountID }));
      dispatch(setRegisterSuccess(true));
      if (callback) {
        callback(res.data.success, res.data.data);
      }
    } catch (err) {
      handleError(err, dispatch);
      console.error(err);
    }
  };

export const sendGetPhoneCountryCodes = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearError({}));
    const res = await apiClient.get<SeityPhoneCCodesGetResponse>("PhoneCountryCode");
    if (!res.data.success) {
      dispatch(
        setRegisterError({
          registerError: res.data.message || strings.registrationError
        })
      );
      return;
    }
    dispatch(setPhoneCountryCodes({ phoneCountryCodes: res.data.data }));
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendPostCheckCredentialsAvailable =
  (email: string = "", userName: string = "", callback: () => void) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      const res = await apiClient.post<SeityCheckCredentialsPostResponse>("Register/CredentialsAvailable", {
        email,
        userName
      });
      if (!res.data.success) {
        dispatch(
          setRegisterError({
            registerError: res.data.message || strings.registrationError
          })
        );
        return;
      }
      dispatch(setCredentialsAvailable({ credentialAvailable: res.data.data }));
      if (!res.data.data.emailAvailable && email !== "") {
        toastError(strings.registerEmailAvailableError);
        return;
      }
      if (!res.data.data.userNameAvailable && userName !== "") {
        toastError(strings.registerUsernameAvailableError);
        return;
      }
      callback();
    } catch (err) {
      handleError(err, dispatch);
    }
  };

function handleError(err: any, dispatch: any) {
  const errMsg = err.response.data.message
    ? err.response.data.message
    : err.response.data.userMessage
    ? err.response.data.userMessage
    : err.message;
  dispatch(setRegisterError({ registerError: errMsg }));
}
