import { useState, useEffect, FunctionComponent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../app/rootReducer";
import AssessWrap from "../AssessWrap";
import { AssessmentSectionIntro } from "../base/AssessmentSectionIntro";
import { CoreValuesBubbleList } from "../coreValues/CoreValuesBubbleList";
import { CoreValuesDragValues } from "../coreValues/CoreValuesDragValues";
import { CoreValuesYourCoreValues } from "../coreValues/CoreValuesYourCoreValues";
import { YourFourCoreValues } from "../coreValues/FourCoreValues";
import { AssessCoreValsSteps, AssessCoreValuesProps } from "./data";
import SeityButton from "../../../_core/components/SeityButton";
import { SeityIndicator } from "../../../_core/components/SeityIndicator";
import { SeityHyperText } from "../../../_core/components/SeityHyperText";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import { sendResetCoreValuesRequest } from "../coreValuesSlice";
import { isPlotlineInjectionCompleted } from "../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";

type StateProps = {
  fromPlotline: boolean;
  isRetake: boolean;
};

export const AssessCoreValues: FunctionComponent<AssessCoreValuesProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<StateProps>();

  const fromPlotline = location?.state?.fromPlotline ?? false;
  const isRetake = location?.state?.isRetake ?? false;

  let { isCVOnly } = useSelector((state: RootState) => {
    return state.userAppSettings.userAppSettings;
  });

  const isNotCVOnlyAndFromPlotline = !isCVOnly && fromPlotline;

  if (fromPlotline || isRetake) {
    isCVOnly = true;
  }

  // needs to be in tsx file for translation
  const useAssessCoreValsSteps = (): Array<AssessCoreValsSteps> => {
    return [
      {
        id: 0,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: null
        },
        options: [
          {
            value: strings.findYourCoreValue,
            action: { type: "next", value: "1" },
            buttonType: "next"
          },
          {
            value: strings.backButtonText,
            action: {
              type: "navigate",
              value: "/assessment/breathingExercise"
            },
            buttonType: "black"
          },
          {
            value: strings.finishLater,
            action: { type: "finishLater", value: "0" },
            buttonType: "discard"
          }
        ]
      },
      {
        id: 1,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: strings.choose4From10
        },
        options: [{ value: strings.continue, action: { type: "next", value: "2" } }]
      },
      {
        id: 2,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: strings.choose4From10
        },
        options: [
          {
            value: strings.backButtonText,
            action: { type: "back", value: "1" }
          },
          { value: strings.continue, action: { type: "next", value: "3" } }
        ]
      },
      {
        id: 3,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: strings.choose4From8
        },
        options: [
          {
            value: strings.backButtonText,
            action: { type: "back", value: "2" }
          },
          { value: strings.continue, action: { type: "next", value: "4" } }
        ]
      },
      {
        id: 4,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: strings.choose4From8
        },
        options: [
          {
            value: strings.backButtonText,
            action: { type: "back", value: "3" }
          },
          { value: strings.continue, action: { type: "next", value: "5" } }
        ]
      },
      {
        id: 5,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: strings.choose4From8
        },
        options: [
          {
            value: strings.backButtonText,
            action: { type: "back", value: "4" }
          },
          { value: strings.continue, action: { type: "next", value: "6" } }
        ]
      },
      {
        id: 6,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: strings.choose4From8
        },
        options: [
          {
            value: strings.backButtonText,
            action: { type: "back", value: "5" }
          },
          { value: strings.continue, action: { type: "next", value: "7" } }
        ]
      },
      {
        id: 7,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: null
        },
        options: [
          { value: "No, Start Over", action: { type: "reset", value: "1" } },
          { value: "Yes, Continue", action: { type: "next", value: "8" } }
        ]
      },
      {
        id: 8,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: null
        },
        options: [
          {
            value: strings.backButtonText,
            action: { type: "back", value: "7" }
          },
          { value: strings.continue, action: { type: "next", value: "9" } }
        ]
      },
      {
        id: 9,
        header: {
          title: strings.coreValuesIntroHeader,
          subTitle: null
        },
        options: [
          {
            value: strings.nextButtonText,
            action: { type: "navigate", value: "/assessment/Practices" }
          }
        ]
      }
    ];
  };

  const [stepId, setStepId] = useState(isCVOnly ? 1 : 0);
  const ASSESS_COREVALS_STEPS = useAssessCoreValsSteps();

  const [coreValsStep, setCoreValsStep] = useState<AssessCoreValsSteps>();
  const [showFinishLater, setShowFinishLater] = useState(false);

  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  useEffect(() => {
    if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.CoreValues)) {
      history.push("/plotline/" + PlotlineInjectionPointID.CoreValues);
    }
  }, []);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [stepId]);

  const coreValsHeader = (coreValsStep: AssessCoreValsSteps) => {
    let boldStr = "";
    let { subTitle } = coreValsStep.header;
    if (coreValsStep.id > 0 && coreValsStep.id < 7 && subTitle) {
      boldStr = subTitle.slice(0, 8);
      subTitle = subTitle.slice(9);
    }

    return (
      <div className="core-value-header">
        <div className="coreVals-header">
          <div className="core-value-header-button">
            <SeityHyperText
              onClick={() => {
                return onPressOption({
                  action: { type: "finishLater", value: "0" }
                });
              }}
              title={strings.finishLater}
            />
          </div>
          {coreValsStep.id > 0 && coreValsStep.id < 7 && <SeityIndicator numberOfSteps={6} currentStep={coreValsStep.id} />}
          <div className="core-value-header-button hide-block-mobile" />
        </div>
        <p className="core-value-header-title">
          <b>{boldStr}</b> {subTitle}
        </p>
      </div>
    );
  };

  const coreValsFooter = (coreValsStep: AssessCoreValsSteps, onPressOption) => {
    return (
      <div className="coreVals-footer">
        {coreValsStep.options.map((option, index) => {
          return (
            <SeityButton
              key={index}
              className={`core-value-action-button ${option?.buttonType === "discard" && "core-value-green-title"}`}
              onClick={() => {
                return onPressOption(option);
              }}
              label={option.value}
              type={option?.buttonType}
            />
          );
        })}
      </div>
    );
  };

  const loadData = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _coreValsStep = getCurrentCoreValsStep();
    setCoreValsStep(_coreValsStep);
  };

  const getCurrentCoreValsStep = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _coreValsStep = [...ASSESS_COREVALS_STEPS].find((c) => {
      return c.id == stepId;
    });
    return _coreValsStep;
  };

  const onPressOption = (option) => {
    const { value, type } = option.action;
    switch (type) {
      case "next":
      case "back":
        return setStepId(value);
      case "finishLater":
        return setShowFinishLater(true);
      case "navigate":
        return history.push(value);
      default:
        return () => {};
    }
  };

  const onNextClicked = () => {
    const nextAction = getCurrentCoreValsStep()?.options.find((a) => {
      return a.action.type === "next";
    });

    if (nextAction) {
      const sId = nextAction.action.value as unknown as number;
      if (sId === 9 && isCVOnly) {
        history.push("/assessment/coreValues/outro");
      } else {
        setStepId(sId);
      }
    }
  };

  const onBackClicked = () => {
    const backAction = getCurrentCoreValsStep()?.options.find((a) => {
      return a.action.type === "back";
    });
    const resetAction = getCurrentCoreValsStep()?.options.find((a) => {
      return a.action.type === "reset";
    });

    if (backAction) {
      setStepId(backAction.action.value as unknown as number);
    }
    if (resetAction) {
      dispatch(sendResetCoreValuesRequest());
      setStepId(resetAction.action.value as unknown as number);
    }
  };

  return (
    <AssessWrap
      stepNum={coreValsStep?.id === 0 ? undefined : 1}
      showFinishLater={showFinishLater}
      isCVOnly={isCVOnly}
      setShowFinishLater={setShowFinishLater}
      label={coreValsStep?.header.title}
      subHeader={coreValsStep && coreValsStep.id < 7 && coreValsHeader(coreValsStep)}
    >
      {coreValsStep && (
        <>
          {coreValsStep.id === 0 && (
            <div className="assess-core-value-container">
              <AssessmentSectionIntro mode="coreValue" />
              {coreValsFooter(coreValsStep, onPressOption)}
            </div>
          )}
          {coreValsStep.id > 0 && coreValsStep.id < 7 && (
            <CoreValuesBubbleList stepId={coreValsStep.id} isRetake={isRetake} onNextClicked={onNextClicked} onBackClicked={onBackClicked} />
          )}
          {coreValsStep.id === 7 && (
            <YourFourCoreValues
              stepId={coreValsStep.id}
              isCVOnly={isCVOnly}
              onNextClicked={onNextClicked}
              onBackClicked={() => {
                return onBackClicked();
              }}
            />
          )}
          {coreValsStep.id === 8 && (
            <CoreValuesDragValues
              stepId={coreValsStep.id}
              isCVOnly={isCVOnly}
              isRetake={isRetake}
              onNextClicked={onNextClicked}
              onBackClicked={onBackClicked}
            />
          )}
          {coreValsStep.id === 9 && (
            <CoreValuesYourCoreValues
              stepId={coreValsStep.id}
              isCVOnly={isCVOnly}
              onNextClicked={() => {
                if (isCVOnly && !isNotCVOnlyAndFromPlotline) {
                  history.push({
                    pathname: "/assessment/coreValuesOutro",
                    state: { fromPlotline: fromPlotline }
                  });
                } else {
                  history.push("/assessment/Practices");
                }
              }}
            />
          )}
        </>
      )}
    </AssessWrap>
  );
};
