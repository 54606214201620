import { useLayoutEffect, useState, useEffect } from "react";

export const useLockBodyScroll = () => {
  // useLaoutEffect callback return type is "() => void" type
  useLayoutEffect((): (() => void) => {
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: -1,
    height: -1
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      return window.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowSize;
};

export default useLockBodyScroll;
