import { FunctionComponent } from "react";
import "./styles.scss";
import strings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";

type WellbeingInfoModalProps = {
  handleClose: () => void;
};

export const WellbeingInfoModal: FunctionComponent<WellbeingInfoModalProps> = ({ handleClose }) => {
  const markers = [
    {
      img: "energy",
      title: strings.itemTitleEnergy,
      subtitle: strings.energyDescription
    },
    {
      img: "direction",
      title: strings.itemTitleDirection,
      subtitle: strings.directionDescription
    },
    {
      img: "belonging",
      title: strings.itemTitleBelonging,
      subtitle: strings.belongingDescription
    },
    {
      img: "joy",
      title: strings.itemTitleJoy,
      subtitle: strings.joyDescription
    }
  ];
  return (
    <div className="wellbeing-info-modal-container">
      <img className="close-icon" src={require("../../../assets/graphics/journals/close.png").default} onClick={() => handleClose()} />
      <div className="header">
        <img src={require("../../../assets/graphics/wellbeing/atom.png").default} />
        <div className="content">
          <h1>{strings.wellBeing}</h1>
          <p>{strings.wellbeingDescription}</p>
        </div>
      </div>
      <div className="body">
        <h2>{strings.theFourDimentionsWellbeing}</h2>
        <div className="markers">
          {markers.map((item, index) => (
            <div key={index}>
              <img src={require(`../../../assets/graphics/wellbeing/${item.img}.svg`).default} />
              <div>
                <p className="title">{item.title}</p>
                <p className="subtitle">{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SeityButton onClick={() => handleClose()} label={strings.close} className="close-button" />
    </div>
  );
};
