import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { SeityResponse } from "../baseTypes";
import { SurveyWizardQuestionAndAnswersResponse, SurveyWizardSelectionUpdate } from "./types/surveyWizardTypes";

const SECTION = "/SurveyWizard/";

export async function sendSurveyWizardSelectionUpdateRequest(
  token: string,
  surveyWizardSelectionUpdate: SurveyWizardSelectionUpdate
): Promise<SeityResponse> {
  const url =
    BASE_URL +
    SECTION +
    `UpdateSurveyWizardSelection?SurveyWizardSelectionID=${surveyWizardSelectionUpdate.surveyWizardSelectionID}&AnswerLevel=${surveyWizardSelectionUpdate.answerLevel}`;
  try {
    const surveySelectionUpdateResponse = await axios.put<SeityResponse>(url, surveyWizardSelectionUpdate, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return surveySelectionUpdateResponse.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        console.log(err.response.data); // => the response payload
      } else {
        console.log("No error response");
        console.log(err);
      }
    }
    throw err;
  }
}

export async function getSurveyWizardQuestions(
  token: string,
  surveyWizardTrackingID: number
): Promise<SurveyWizardQuestionAndAnswersResponse> {
  const url = BASE_URL + SECTION + "GetSurveyWizardQuestions?SurveyWizardTrackingID=" + surveyWizardTrackingID.toString();
  try {
    const surveySelectionResponse = await axios.get<SurveyWizardQuestionAndAnswersResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveySelectionResponse.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        console.log(err.response.data); // => the response payload
      } else {
        console.log("No error response");
        console.log(err);
      }
    }
    throw err;
  }
}
