import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { compressImageFileToBase64 } from "../../../../_core/utils/fileUtils/byteArrayProcessing";
import { toastError } from "../../../../app/utils";
import strings from "../../../../_core/strings/strings";
import "./styles.scss";

export type FSGroupAavatarProps = {
  imgStr: string | null;
  readonly?: boolean;
  onUpdate?: (imgData: string) => void;
  isPersonalList?: boolean;
  onRemove?: () => void;
};

const FSGroupAavatar: FunctionComponent<FSGroupAavatarProps> = ({
  imgStr,
  readonly = false,
  onUpdate,
  isPersonalList = false,
  onRemove
}): ReactElement => {
  const [imgData, setImgData] = useState<string>();
  const [showPhotoActions, setShowPhotoActions] = useState<boolean>(false);

  useEffect(() => {
    if (imgStr !== null && imgStr?.length > 0) {
      setImgData(`data:image/png;base64,${imgStr}`);
    }
    if (imgStr?.includes("https")) {
      setImgData(imgStr);
    }
  }, [imgStr]);

  const removePhoto = () => {
    setImgData("");
    if (onRemove) {
      onRemove();
    } else if (onUpdate) {
      onUpdate("");
    }
    setShowPhotoActions(false);
  };

  return (
    <div className="fsGroup-avatar">
      {imgData && imgData.length > 0 ? (
        <img src={imgData} alt="attach" />
      ) : (
        <img
          src={
            require(isPersonalList
              ? "../../../../assets/graphics/img-pl-avatar.png"
              : "../../../../assets/graphics/familysharing/img-fsgroup-avatar.png").default
          }
        />
      )}
      {!readonly && (
        <div className="photo-action-button" onClick={() => setShowPhotoActions(!showPhotoActions)}>
          <img src={require("../../../../assets/icons/camera.png").default} />
        </div>
      )}
      {showPhotoActions && (
        <div className="photo-action-drawer">
          <div
            onClick={() => {
              // @ts-ignore
              document.getElementById("photo-upload-input").click();
            }}
          >
            <img src={require("../../../../assets/icons/upload.png").default} />
            <p>{strings.uploadPhoto}</p>
            <input
              type="file"
              id="photo-upload-input"
              accept="image/png, image/gif, image/jpeg"
              onChange={(event) => {
                if (event.target.files && event.target.files[0]) {
                  compressImageFileToBase64(event.target.files[0])
                    .then((result) => {
                      setImgData(result as string);
                      if (onUpdate) {
                        onUpdate(result as string);
                      }
                    })
                    .catch((err) => {
                      toastError();
                    });
                }
              }}
            />
          </div>
          {imgData && imgData.length > 0 && (
            <div onClick={removePhoto}>
              <img src={require("../../../../assets/icons/delete.png").default} />
              <p>{strings.removePhoto}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FSGroupAavatar;
