/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FunctionComponent, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import DomPurify from "dompurify";

import { RootState } from "../../../app/rootReducer";
import { getAppContentRequest } from "../appContentSlice";

import SeityLoader from "../../../_core/components/SeityLoader";
import { SeityBackButton } from "../../../_core/components/SeityBackButton";

import strings from "../../../_core/strings/strings";
import "../styles.scss";

export const UserAgreement: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();

  const { isLoading, appContentLines } = useSelector((state: RootState) => {
    return state.appContent;
  });

  const { shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAppContentRequest(5));
  }, []);

  if (shouldRedirectToLogin) {
    return <Redirect to={{ pathname: "/login", state: { from: "/" } }} />;
  }

  const EULAContent = appContentLines[0] ? DomPurify.sanitize(appContentLines[0].contentText) : "<h1>Unable to load EULA</h1>";

  return (
    <div className="pol-container">
      <SeityBackButton wrapperClassName="sd-back-button" />
      <div className="pol-header">
        <h1>{strings.userPolicy}</h1>
      </div>
      <div className="pol-content">
        <div className="pol-bubble-blue">
          <section>
            <div
              dangerouslySetInnerHTML={{
                __html: EULAContent
              }}
            />
          </section>
        </div>
      </div>
      {isLoading && <SeityLoader hideBackgroundContent />}
    </div>
  );
};
