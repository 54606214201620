import { PlotlineGoToActionID, PlotlineInjectionInfo, PlotlineQuestionData, PlotlineTemplateID } from "../../api/plotlineTypes";

export const getLayoutProps = (templateId: PlotlineTemplateID | null) => {
  let displayTag = false;
  let displayIcon = false;
  let iconSize = "large";

  // takes care of layout changes based on template id
  switch (templateId) {
    case PlotlineTemplateID.MultiConetntHalfPageImage01:
    case PlotlineTemplateID.MultiContentHalfPageVideo01:
      displayTag = true;
      break;
    case PlotlineTemplateID.FullPageContentTopAlignedImage01:
    case PlotlineTemplateID.FullPageContentTopAlignedVideo01:
    case PlotlineTemplateID.FullPageContentBottomAlignedVideo01:
      displayIcon = true;
      break;
    case PlotlineTemplateID.CircularGraph01:
    case PlotlineTemplateID.WellbeingCheckIn01:
    case PlotlineTemplateID.Questionnaire01:
      displayIcon = true;
      iconSize = "medium";
      break;
  }

  return { displayTag, displayIcon, iconSize };
};

export const getTemplateLayout = (templateID: PlotlineTemplateID | null) => {
  switch (templateID) {
    case PlotlineTemplateID.MultiConetntHalfPageImage01:
    case PlotlineTemplateID.MultiContentHalfPageVideo01:
    case PlotlineTemplateID.CircularGraph01:
      return { isHeaderTop: false, fullPage: false };
    case PlotlineTemplateID.FullPageContentBottomAlignedImage01:
    case PlotlineTemplateID.FullPageContentBottomAlignedVideo01:
      return { isHeaderTop: false, fullPage: true };
    case PlotlineTemplateID.FullPageContentTopAlignedImage01:
    case PlotlineTemplateID.FullPageContentTopAlignedVideo01:
      return { isHeaderTop: true, fullPage: true };
    case PlotlineTemplateID.WellbeingCheckIn01:
    case PlotlineTemplateID.Questionnaire01:
      return { isHeaderTop: true, fullPage: false };
    default:
      return { isHeaderTop: false, fullPage: false };
  }
};

export const getQuestAnswersApiData = (question: PlotlineQuestionData, selAnswers: Array<number>) => {
  const ret = question.answers.map((a) => {
    return {
      answerID: a.answerID,
      isSelected: selAnswers.includes(a.answerID)
    };
  });

  return {
    questionID: question?.questionID,
    answers: ret
  };
};

export const updateWellbeingLevels = (outcomeCategoryID: number, wbScores: Array<number>, level: number) => {
  const arrIdx = getWellbeingIdx(outcomeCategoryID);
  const tmp = [...wbScores];
  tmp[arrIdx] = level;

  return tmp;
};

export const getWellbeingIdx = (outcomeCategoryID: number) => {
  let arrIdx = 0;
  switch (outcomeCategoryID) {
    case 10:
      arrIdx = 0;
      break;
    case 11:
      arrIdx = 1;
      break;
    case 12:
      arrIdx = 2;
      break;
    case 13:
      arrIdx = 3;
      break;
  }

  return arrIdx;
};

export const getWellBeingScores = (wbLevels: Array<number>) => {
  const vals = [0, 0.25, 0.5, 0.75, 1];
  const ret = wbLevels.map((w) => {
    return vals[w] * 100;
  });

  return ret;
};

export const getRouteGoToAction = (actionID: PlotlineGoToActionID) => {
  switch (actionID) {
    case PlotlineGoToActionID.InitialLanding:
      return "/";
    case PlotlineGoToActionID.CVOnlyAssessment:
      return "/assessment/intro";
    case PlotlineGoToActionID.DailyAffirmations:
      return "/today/affirmation";
    case PlotlineGoToActionID.DailyIntentions:
      return "/intentions/choose";
    case PlotlineGoToActionID.TodayPage:
      return "/today";
    case PlotlineGoToActionID.Wellbeing:
      return "/wellbeing";
    case PlotlineGoToActionID.CoreValuesHome:
      return "/coreValues";
    case PlotlineGoToActionID.CVDirectory:
      return "/cvdList";
    default:
      return "/";
  }
};

export const isPlotlineInjectionCompleted = (data: Array<PlotlineInjectionInfo>, plotlineInjectionID: number) => {
  const ret = data.filter((d) => d.plotlineInjectionID === plotlineInjectionID);
  return ret.length > 0 ? ret[0].completed : true;
};

export const getFirstPlotlineInjectionId = (data: Array<PlotlineInjectionInfo>) => {
  const ret = data.filter((d) => d.completed === false);
  return ret.length > 0 ? ret[0].plotlineInjectionID : null;
};
