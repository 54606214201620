import { createSlice } from "@reduxjs/toolkit";
import { SeityAuthenticationError } from "../../../api/authTypes";
import { getAppContent } from "../../../api/policy/seityHealthAPI-Privacy";
import { GuideItem, CoreValue, GoodLifeEpisode, TeachSeityAsset, SeityScienceAsset } from "../../../api/referenceGuide/referenceGuideTypes";
import {
  getGoodLifeSeries,
  getLearnSeityGuideByCategoryId,
  getSeityAssets,
  getSeityCoreValues,
  getTeachSeityAssetDetail,
  getTeachSeityAssets,
  getWellBeingCheckInCategories
} from "../../../api/referenceGuide/seityHealthAPI-ReferenceGuide";
import { getRefreshedToken } from "../../auth/UseSessionStorageToken";
import { coreValueCategory, wellbeingCategories } from "./data";
import { normalizeDragonGuide } from "./guideUtils";

export interface GuideState {
  readonly isLoading: boolean;
  readonly guideError: string | null;
  readonly guide: GuideItem | null;
  readonly coreValues: Array<CoreValue> | null;
  readonly goodLifeSeries: Array<GoodLifeEpisode>;
  readonly teachSeityAssets: Array<TeachSeityAsset>;
  readonly seityScienceAssets: Array<SeityScienceAsset>;
}

const GuideSlice = createSlice({
  name: "guide",
  initialState: {
    isLoading: false,
    guideError: null,
    guide: null,
    coreValues: [],
    goodLifeSeries: [],
    teachSeityAssets: [],
    seityScienceAssets: []
  } as GuideState,
  reducers: {
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setGuideError(state, action) {
      state.guideError = action.payload.postError;
      state.isLoading = false;
    },
    setGuide(state, action) {
      state.guide = action.payload;
      state.isLoading = false;
    },
    setCoreValues(state, action) {
      state.coreValues = action.payload;
      state.isLoading = false;
    },
    setGoodLifeSeries(state, action) {
      state.goodLifeSeries = action.payload;
      state.isLoading = false;
    },
    setTeachSeityAssets(state, action) {
      state.teachSeityAssets = action.payload;
      state.isLoading = false;
    },
    setSeityScienceAssets(state, action) {
      state.seityScienceAssets = action.payload;
      state.isLoading = false;
    }
  }
});

export const { setIsLoading, setGuideError, setGuide, setCoreValues, setGoodLifeSeries, setTeachSeityAssets, setSeityScienceAssets } =
  GuideSlice.actions;

export default GuideSlice.reducer;

export const sendGetDragonsGuideDetails = () => async (dispatch: any) => {
  dispatch(setIsLoading({}));
  const { token } = await getRefreshedToken();
  if (token === null) {
    throw new SeityAuthenticationError();
  }
  const response = await getAppContent(11, token);
  dispatch(setIsLoading({ isLoading: false }));
  if (response.success) {
    dispatch(setGuide(normalizeDragonGuide(response.data.appContentLines)));
  }
};

export const sendGetSeityWellbeingCategorieDetails = (categoryId: string) => async (dispatch: any) => {
  dispatch(setIsLoading({}));
  const { token } = await getRefreshedToken();
  if (token === null) {
    throw new SeityAuthenticationError();
  }
  const response = await getWellBeingCheckInCategories(token);
  dispatch(setIsLoading({ isLoading: false }));
  const outcomeCategory = wellbeingCategories()[categoryId].outcomeCategory;
  if (response.success) {
    dispatch(setGuide({ outcomeCategory, subcategories: response.data }));
  }
};

export const sendGetCoreValuesCategorieDetails = () => async (dispatch: any) => {
  dispatch(setGuide(coreValueCategory()));
};

export const sendGetSeityGuideCategorieDetails = (categoryId: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }
    const response = await getLearnSeityGuideByCategoryId(categoryId, token);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success) {
      dispatch(setGuide(response.data));
    }
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetSeityCoreValues = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getSeityCoreValues(token);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success) {
      dispatch(setCoreValues(response.data));
    }
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetGoodLifeSeriesInfo = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getGoodLifeSeries(token);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success) {
      dispatch(setGoodLifeSeries(response.data));
    }
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetTeachSeityAssets = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getTeachSeityAssets(token);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success) {
      dispatch(setTeachSeityAssets(response.data));
    }
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

export const sendGetTeachSeityAssetDetail =
  (assetId: number, callback: (data?: TeachSeityAsset | SeityScienceAsset) => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      const { token } = await getRefreshedToken();
      if (token === null) {
        throw new SeityAuthenticationError();
      }

      const response = await getTeachSeityAssetDetail(token, assetId);
      dispatch(setIsLoading({ isLoading: false }));
      callback(response.data);
    } catch (err) {
      console.log(err.response);
      handleError(err, dispatch);
    }
  };

export const sendGetSeityAssets = (assetCategoryID: number, assetTypeID?: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    const { token } = await getRefreshedToken();
    if (token === null) {
      throw new SeityAuthenticationError();
    }

    const response = await getSeityAssets(token, assetCategoryID, assetTypeID);
    dispatch(setIsLoading({ isLoading: false }));
    if (response.success) {
      dispatch(setSeityScienceAssets(response.data));
    }
  } catch (err) {
    console.log(err.response);
    handleError(err, dispatch);
  }
};

function handleError(err: Error, dispatch: any) {
  console.error(err);
  dispatch(setGuideError({ guideError: err.toString() }));
}
