import { createSlice } from "@reduxjs/toolkit";
import {
  Journal,
  JournalEmotion,
  JournalEmotionResponse,
  JournalEntry,
  JournalListResponse,
  JournalPostResponse,
  Weather,
  WeatherResponse
} from "../../api/individualJournal/journalTypes";
import apiClientWithAuth from "../../api/apiClient";

export interface JournalsState {
  readonly error: string | null;
  readonly isLoading: boolean;
  journalList: Array<Journal>;
  weatherData: Weather | null;
  emotionsData: Array<JournalEmotion>;
}

const setInitalState = {
  error: null,
  isLoading: false,
  journalList: [],
  weatherData: null,
  emotionsData: []
} as JournalsState;

const journalsSlice = createSlice({
  name: "practices-practices",
  initialState: setInitalState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setJournalList(state, action) {
      if (action.payload.isReload) {
        state.journalList = action.payload.list;
      } else {
        state.journalList = [...state.journalList, ...action.payload.list];
      }
    },
    setWeatherData(state, action) {
      state.weatherData = action.payload;
    },
    setEmotionData(state, action) {
      state.emotionsData = action.payload;
    },
    replaceJournal(state, action) {
      const temp = [...state.journalList];
      const journal = action.payload;
      const idx = temp.findIndex((t) => {
        return t.individualJournalID === journal.journalId;
      });
      temp[idx] = journal;
      state.journalList[idx] = journal;
    },
    resetJournalsSlice: () => setInitalState
  }
});

export const { clearError, setIsLoading, setError, setJournalList, replaceJournal, setWeatherData, setEmotionData } = journalsSlice.actions;

export default journalsSlice.reducer;

const handleError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setError({ error: err.toString() }));
};

export const postIndividualJournalRequest = (journalEntry: JournalEntry, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.post<JournalPostResponse>("IndividualJournal", journalEntry);
    dispatch(setIsLoading(false));
    if (!res.data.success) {
      handleError("Journal create failed", dispatch);
    }
    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const putIndividualJournalRequest = (journal: Journal, callback?: () => void) => async (dispatch: any) => {
  // The image field on putIndividual api is `images`. Not a `individualJournalImages`. THe images is missing only when click on favourite on journals page.
  if (!journal?.images || journal?.images?.length === 0) {
    journal.images = [...journal.individualJournalImages];
  }
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.put<JournalPostResponse>("IndividualJournal", journal);
    dispatch(setIsLoading(false));
    if (!res.data.success) {
      handleError("Journal update failed", dispatch);
    }
    // Update journalList with the updated one to make update-to-date journals on journals page
    dispatch(replaceJournal(journal));
    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const deleteIndividualJournalRequest = (individualJournalID: number, callback?: () => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete<JournalPostResponse>("IndividualJournal?individualJournalID=" + individualJournalID);
    dispatch(setIsLoading(false));
    if (!res.data.success) {
      handleError("Journal delete failed", dispatch);
    }
    if (callback) {
      callback();
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getJournalListRequest =
  (range: { start: number; end: number }, callback: (data: Array<Journal>) => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const res = await apiClientWithAuth.get<JournalListResponse>(
        `IndividualJournal?individualJournalID=?start=${range.start}&end=${range.end}`
      );
      dispatch(setIsLoading(false));
      if (res.data.success) {
        dispatch(setJournalList({ list: res.data.data, isReload: range.start === 0 }));
      }
      callback(res?.data?.data ?? []);
    } catch (err: any) {
      handleError(err, dispatch);
    }
  };

export const getJournalEmotionsRequest = (callback?: (data: Array<JournalEmotion>) => void) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get<JournalEmotionResponse>("IndividualJournal/Emotions");
    dispatch(setIsLoading(false));
    if (res.data.success) {
      dispatch(setEmotionData(res.data.data));
    }
    if (callback) {
      callback(res?.data?.data ?? []);
    }
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const getWeatherRequest = (lat: number, lng: number) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get<WeatherResponse>(`/Weather?latitude=${lat}&longitude=${lng}`);
    dispatch(setIsLoading(false));
    dispatch(setWeatherData(res.data.data));
  } catch (err: any) {
    handleError(err, dispatch);
  }
};
