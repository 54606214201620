export class CoreValue {
  coreValue: string;
  coreValueImage: string;
  coreValueID: number;
  coreValueText: string;

  constructor(coreValue: string, coreValueImage: string, coreValueID: number, coreValueText: string) {
    this.coreValue = coreValue;
    this.coreValueImage = coreValueImage;
    this.coreValueID = coreValueID;
    this.coreValueText = coreValueText;
  }
}

export class Outcome {
  outcome: string;
  outcomeImage: string;

  constructor(outcome: string, outcomeImage: string) {
    this.outcome = outcome;
    this.outcomeImage = outcomeImage;
  }
}

export class AssessmentSummary {
  assessmentText: string;
  assessmentImage: string;
  assessmentScore: number;
  assessmentTextEnglish: string;

  constructor(assessmentText: string, assessmentImage: string, assessmentScore: number, assessmentTextEnglish: string) {
    this.assessmentText = assessmentText;
    this.assessmentImage = assessmentImage;
    this.assessmentScore = assessmentScore;
    this.assessmentTextEnglish = assessmentTextEnglish;
  }
}

export class Characterization {
  fourCoreValuesShort: CoreValue[];
  outcomes: Outcome[];
  wellbeingGraph: number[];
  energyGraph: number[];
  directionGraph: number[];
  belongingGraph: number[];
  joyGraph: number[];
  assessmentComplete: boolean;
  assessmentDue: boolean;
  assessmentSummary: AssessmentSummary[];
  information: string;
  journalsEnabled: boolean;
  journalsDescription: string;
  intentionsEnabled: boolean;
  intentionsDescription: string;
  storiesEnabled: boolean;
  storiesDescription: string;
  syncsEnabled: boolean;
  syncsDescription: string;
  assessmentLevelID: number;
  displayWellbeingData: boolean;
  assessmentDate: string;
  assessmentStarted: boolean;
  dateTimeGraph: string[];
  assessmentState: number;
  checkInDue: boolean;
  eulaNeeded: boolean;
  forceNewPassword: boolean;
  emailVerificationNeeded: boolean;
  languageID: number;
  translationEnabled: boolean;

  constructor(
    fourCoreValuesShort: CoreValue[],
    outcomes: Outcome[],
    wellbeingGraph: number[],
    energyGraph: number[],
    directionGraph: number[],
    belongingGraph: number[],
    joyGraph: number[],
    assessmentComplete: boolean,
    assessmentDue: boolean,
    assessmentSummary: AssessmentSummary[],
    information: string,
    journalsEnabled: boolean,
    journalsDescription: string,
    intentionsEnabled: boolean,
    intentionsDescription: string,
    storiesEnabled: boolean,
    storiesDescription: string,
    syncsEnabled: boolean,
    syncsDescription: string,
    assessmentLevelID: number,
    displayWellbeingData: boolean,
    assessmentDate: string,
    assessmentStarted: boolean,
    dateTimeGraph: string[],
    assessmentState: number,
    checkInDue: boolean,
    eulaNeeded: boolean,
    forceNewPassword: boolean,
    emailVerificationNeeded: boolean,
    languageID: number,
    translationEnabled: boolean
  ) {
    this.fourCoreValuesShort = fourCoreValuesShort;
    this.outcomes = outcomes;
    this.wellbeingGraph = wellbeingGraph;
    this.energyGraph = energyGraph;
    this.directionGraph = directionGraph;
    this.belongingGraph = belongingGraph;
    this.joyGraph = joyGraph;
    this.assessmentComplete = assessmentComplete;
    this.assessmentDue = assessmentDue;
    this.assessmentSummary = assessmentSummary;
    this.information = information;
    this.syncsEnabled = syncsEnabled;
    this.syncsDescription = syncsDescription;
    this.journalsEnabled = journalsEnabled;
    this.journalsDescription = journalsDescription;
    this.intentionsEnabled = intentionsEnabled;
    this.intentionsDescription = intentionsDescription;
    this.storiesEnabled = storiesEnabled;
    this.storiesDescription = storiesDescription;
    this.assessmentLevelID = assessmentLevelID;
    this.displayWellbeingData = displayWellbeingData;
    this.assessmentDate = assessmentDate;
    this.assessmentStarted = assessmentStarted;
    this.dateTimeGraph = dateTimeGraph;
    this.assessmentState = assessmentState;
    this.checkInDue = checkInDue;
    this.eulaNeeded = eulaNeeded;
    this.forceNewPassword = forceNewPassword;
    this.emailVerificationNeeded = emailVerificationNeeded;
    this.languageID = languageID;
    this.translationEnabled = translationEnabled;
  }
}

export class CharacterizationResponse {
  success: boolean;
  message: string | null;
  data: Characterization;

  constructor(success: boolean, message: string | null, data: Characterization) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

export enum AssessmentCompletionState {
  NoAssessment = 1,
  CompletedToFourCoreValues = 2,
  CompletedToPracitceQuestions = 3,
  AssessmentComplete = 4
}

export enum AssessmentLevel {
  FullAssessment = 1,
  CoreValuesOnlyAssessment = 2,
  NoCoreValuesOrAssessment = 3,
  WellbeingOnly = 4
}
