import { FunctionComponent, ReactElement, useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";

import { useDispatch, useSelector } from "react-redux";
import { Intention } from "../../../api/dailyIntention/intentionTypes";
import SeityMoreInfoModal from "../../../_core/components/SeityMoreInfo";

import { toastError } from "../../../app/utils";
import { RootState } from "../../../app/rootReducer";
import { getIntentionListRequest } from "../intentionsSlice";
import strings from "../../../_core/strings/strings";

import ListItem from "./ListItem";
import LoadingIndicator from "../LoadingIndicator";
import NoIntention from "./NoIntention";
import SeityLoader from "../../../_core/components/SeityLoader";

import "./styles.scss";
import { SeityCloseButton } from "../../../_core/components/SeityCloseButton";
import { isPlotlineInjectionCompleted } from "../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";
import { useHistory } from "react-router-dom";

const ChooseIntention: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => {
    return state.intentions;
  });
  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });
  const history = useHistory();

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intentions, setIntentions] = useState<Array<Intention>>([]);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNoIntention, setShowNoIntention] = useState(false);
  const ref = useRef<any>(null);

  const loadIntentions = () => {
    dispatch(
      getIntentionListRequest((ins: Array<Intention>) => {
        setLoading(false);

        if (ins.length === 0) {
          if (intentions.length === 0) {
            setShowNoIntention(true);
          } else {
            toastError("Error: Unable to load more Intentions. Try again at a later time.");
          }
        } else {
          setIntentions(ins);
          ref?.current.goTo(0);
        }
      })
    );
  };

  useEffect(() => {
    if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.OnDailyIntention)) {
      history.push("/plotline/" + PlotlineInjectionPointID.OnDailyIntention);
    }
    if (intentions?.length === 0) {
      loadIntentions();
    }
  }, []);

  return (
    <div className="choose-intention mb-fs-layout flex-col flex-ai-c w-100 mt-xxl">
      {showNoIntention ? (
        <NoIntention />
      ) : (
        <>
          <div className="flex-column w-100">
            <div className="mobile-header">
              <button
                className="info-button"
                onClick={() => {
                  setShowMoreInfo(true);
                }}
              >
                <img
                  src={require("../../../assets/graphics/intentions/intentions-info.png").default}
                  alt="Individual"
                  width={24}
                  height={24}
                />
              </button>
              <SeityCloseButton />
            </div>
            <h1 className="mb-xl">
              {strings.chooseAnIntention}
              <img
                className="more-info"
                src={require("../../../assets/graphics/more-info.png").default}
                onClick={() =>
                  history.push({
                    pathname: "/plotline/" + PlotlineInjectionPointID.OnDailyIntention,
                    state: {
                      onDemand: true
                    }
                  })
                }
              />
            </h1>
          </div>

          <Carousel
            ref={ref}
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 500, itemsToShow: 2, itemsToScroll: 2 },
              { width: 850, itemsToShow: 3 },
              { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
              { width: 1450, itemsToShow: 5 },
              { width: 1750, itemsToShow: 6 }
            ]}
            className={`choose-intention-carousel ${loading ? "loading" : ""}`}
            itemsToShow={4}
            pagination={false}
            itemPadding={[0, 0]}
            isRTL={false}
            onChange={(_currentItemObject, currentPageIndex) => {
              setShowPrevArrow(currentPageIndex !== 0);
            }}
            renderArrow={({ type, onClick, isEdge }) => {
              const isLoadingIndi = isEdge && type === "NEXT";
              if (type === "PREV" && !showPrevArrow) {
                return <></>;
              }
              return (
                <>
                  <button
                    className={`${type === "NEXT" ? "forward" : "backward"}-button flex-center ${loading ? "loading" : ""}`}
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      setLoading(isLoadingIndi);
                      if (isLoadingIndi) {
                        loadIntentions();
                      }
                      onClick();
                    }}
                  >
                    <img
                      src={require(`../../../assets/graphics/intentions/icon-${type === "NEXT" ? "forward" : "backward"}.png`).default}
                      width={20}
                      alt="forward btn"
                    />
                  </button>
                  {loading && <LoadingIndicator />}
                </>
              );
            }}
          >
            {intentions?.map((item) => {
              return <ListItem key={`${item.dailyIntentionID}-listitem`} intention={item} />;
            })}
          </Carousel>
          {showMoreInfo && (
            <SeityMoreInfoModal
              isOpen={showMoreInfo}
              onClose={() => {
                setShowMoreInfo(false);
              }}
            />
          )}
        </>
      )}
      {isLoading && <SeityLoader />}
    </div>
  );
};

export default ChooseIntention;
