import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router";

import { SeityButton } from "../../../../_core/components/SeityButton";
import { SeityHyperText } from "../../../../_core/components/SeityHyperText";
import AssessWrap from "../../AssessWrap";

import strings from "../../../../_core/strings/strings";
import { BreathingExerciseProps } from "./types";

import "./styles.scss";
import { useLocation } from "react-router-dom";

type StateProps = {
  fromPlotline: boolean;
};

export const BreathingExercise: FunctionComponent<BreathingExerciseProps> = () => {
  const history = useHistory();
  const location = useLocation<StateProps>();
  const [showFinishLater, setShowFinishLater] = useState(false);

  const fromPlotline = location?.state?.fromPlotline ?? false;

  const nextPressed = () => {
    history.push({
      pathname: "/assessment/coreValues",
      state: { fromPlotline }
    });
  };

  return (
    <AssessWrap showFinishLater={showFinishLater} setShowFinishLater={setShowFinishLater}>
      <div className="breathing-exercise">
        <div className="breathing-exercise-wrapper">
          <h1 className="breathing-all">{strings.breathingExercise}</h1>
          <div className="breathing-exercise-text-wrapper">
            <p className="breathing-01 breathing-all">{strings.breathing01}</p>
            <p className="breathing-02 breathing-all">{strings.breathing02}</p>
            <p className="breathing-03 breathing-all">{strings.breathing03}</p>
          </div>
        </div>
        <div className="breathing-exercise-buttons">
          <SeityButton onClick={nextPressed} label={strings.start} className="breathing-exercise-button" />
          <SeityHyperText
            title={strings.finishLater}
            onClick={() => {
              return setShowFinishLater(true);
            }}
          />
        </div>
      </div>
    </AssessWrap>
  );
};
