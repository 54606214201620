/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { MoreInfoIcon } from "../SeityInfoIcon/InfoIcon";
import { SeityTextInput, SeityTextInputProps } from "./SeityTextInput";
import "./styles.scss";

interface SeityFormTextInputProps {
  controlId?: string;
  inputLabelText: string;
  moreInfoText?: string;
  formStyle?: {};
}

export const SeityFormTextInput = (props: SeityFormTextInputProps & SeityTextInputProps) => {
  const {
    type,
    onChange,
    placeholder,
    inputStyle,
    value,
    className,
    autoComplete,
    inputLabelText,
    moreInfoText,
    isInvalid,
    leftIcon,
    formStyle,
    leftIconClassname,
    containerClassname,
    rightIconClassname
  } = props;

  return (
    <div className="seity-input-password-with-label" style={formStyle}>
      <label>{inputLabelText}</label>
      {moreInfoText && (
        <MoreInfoIcon
          text={moreInfoText}
          imageStyle={{
            marginLeft: "5px",
            paddingBottom: "8px"
          }}
        />
      )}
      <SeityTextInput
        type={type}
        placeholder={placeholder}
        inputStyle={inputStyle}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        className={className}
        isInvalid={isInvalid}
        leftIcon={leftIcon}
        leftIconClassname={leftIconClassname}
        containerClassname={containerClassname}
        rightIconClassname={rightIconClassname}
      />
    </div>
  );
};
