import { FunctionComponent } from "react";
import { AssessmentCompletionState, Characterization } from "../../../api/myStory/types/myStoryTypes";
import { PracticeScoreSubCategory } from "../../../api/account/types/practiceScoresTypes";
import { DragonItem } from "./components/DragonItem";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";

import SeityColors from "../../../_core/styles/SeityColors";
import strings from "../../../_core/strings/strings";

import "./styles.scss";

interface TameYourDragonsProps {
  characterization: Characterization;
  ragingDragons: PracticeScoreSubCategory[] | [];
  awakeDragons: PracticeScoreSubCategory[] | [];
  tameDragons: PracticeScoreSubCategory[] | [];
}

export const TameYourDragons: FunctionComponent<TameYourDragonsProps> = ({
  characterization,
  ragingDragons,
  awakeDragons,
  tameDragons
}) => {
  if (characterization.assessmentState >= AssessmentCompletionState.CompletedToPracitceQuestions) {
    // has assessment completed up til practice questions
    return (
      <div className="tyd-container">
        <div className="tyd-wrapper">
          <h1>{strings.tameYourDragons}</h1>
          <h4>{strings.tameYourDragonsDescription}</h4>
          <DragonItem
            dragon={ragingDragons}
            dragonImage={RequireSeityImage("dragonraging01").default}
            dragonName={strings.ragingDragon}
            backgroundColor={SeityColors.dragonLightGrey}
            dragonCount={ragingDragons.length}
            dragonDescription={` ${strings.lowScore} (0 - 49%)`}
            navigationPath="/dragonList/ragingDragons"
          />
          <DragonItem
            dragon={awakeDragons}
            dragonImage={RequireSeityImage("dragonawake01").default}
            dragonName={strings.awakeDragon}
            backgroundColor={SeityColors.dragonGrey}
            dragonCount={awakeDragons.length}
            dragonDescription={` ${strings.midScore} (50-69%)`}
            navigationPath="/dragonList/awakeDragons"
          />
          <DragonItem
            dragon={tameDragons}
            dragonImage={RequireSeityImage("dragontame02").default}
            dragonName={strings.tameDragon}
            backgroundColor={SeityColors.dragonDarkGrey}
            dragonCount={tameDragons.length}
            dragonDescription={` ${strings.highSCore} (70-100%)`}
            navigationPath="/dragonList/tameDragons"
          />
        </div>
      </div>
    );
  }
  // no assessment or has not completed to practice questions
  return <></>;
};
