import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import FSMemberProfile from "../FSMemberProfile";
import { FSGroupAccount } from "../types";
import CoreStrings from "../../../_core/strings/strings";

type FSMemberSidebarProps = {
  memberData: FSGroupAccount;
  organizerID?: number;
  isOrganizer?: boolean;
  isCurrentUserOrganizer?: boolean;
  onClose: () => void;
  isDirectoryMode?: boolean;
  removeMemberFunction?: any;
  removeInviteFunction?: any;
  isAcceptedMember?: boolean;
};

const FSMemberSidebar: FunctionComponent<FSMemberSidebarProps> = ({
  memberData,
  isOrganizer = false,
  isCurrentUserOrganizer = false,
  onClose,
  isDirectoryMode = false,
  removeMemberFunction,
  isAcceptedMember = false,
  removeInviteFunction
}): ReactElement => {
  let title;
  if (isOrganizer) {
    title = CoreStrings.organizerProfile;
  } else if (isDirectoryMode) {
    title = CoreStrings.coreCard;
  } else {
    title = CoreStrings.memberProfile;
  }

  const removeText = isAcceptedMember ? CoreStrings.removeMember : CoreStrings.deleteInvite;

  return (
    <div className="fs-member-sidebar-container">
      <div className="header">
        <p>{title}</p>
        <button onClick={onClose}>
          <img src={require("../../../assets/graphics/today/close.png").default} />
        </button>
      </div>
      <FSMemberProfile memberData={memberData} isDirectoryMode={isDirectoryMode} />
      {isCurrentUserOrganizer && !isDirectoryMode &&  !isOrganizer && <button className="fs-delete-icon-row" onClick={() => { 
        if(isAcceptedMember) {
          removeMemberFunction(true);
        } else {
          removeInviteFunction(true);
        }
      }}>
        <img src={require("../../../assets/icons/icon-recycle.svg").default} width={16} />
        <span>{' ' + removeText}</span>
      </button>}
    </div>
  );
};

export default FSMemberSidebar;
